
export function idDetailComponent(idParam: string, queryAction: string) {
  return function(constructor: Function) {
    const originalOnInit = constructor.prototype.ngOnInit;
    const originalOnDestroy = constructor.prototype.ngOnDestroy;

    constructor.prototype.ngOnInit = function() {
      this.detailId$ = this.route.params
        .map((params): number => parseInt(params[idParam]));

      this.detailIdSub = this.detailId$
        .subscribe((id: number) => {
          this.store.dispatch({
            type: queryAction,
            payload: id,
          });
        });

      if (typeof originalOnInit === 'function') {
        originalOnInit.apply(this);
      }
    }

    constructor.prototype.ngOnDestroy = function() {
      if (this.detailIdSub && typeof this.detailIdSub.unsubscribe === 'function') {
        this.detailIdSub.unsubscribe();
      }

      if (typeof originalOnDestroy === 'function') {
        originalOnDestroy.apply(this);
      }
    }
  }
}
