import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Subject } from 'rxjs/Subject';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { User } from 'app/store/user';
import { BakeryProduct } from 'app/store/bakery-product';
import { OrderItem } from 'app/store/order-item';
import { Observable } from 'rxjs/Observable';
import { Store } from '@ngrx/store';
import { AlertService } from 'app/shared/components/alerts/alert.service';
import { LocalStorageService } from 'ngx-webstorage';
import { AppState } from 'app/store/app-state';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions } from 'app/store/actions';
import { Actions as NgRxActions } from '@ngrx/effects';
import { BakeryCustProductsEffects } from 'app/store/effects/bakery-cust/bakery-cust-products.effects';
import { EntitiesState } from 'app/store/entities-state';
import { UsersEffects } from 'app/store/effects/users.effects';
import { CartState } from 'app/store/bakery-mgmt-ui-state';
import { denormalize } from 'normalizr';
import { bakeryProductSchema } from 'app/store/schema/default-schemata';
import { BakeryOrder } from 'app/store/bakery-order';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { futureDateValidator, twoDaysDateValidator } from 'app/shared/forms/date-validators';
import { phoneNumberValidator } from 'app/shared/forms/form-number-validator';
import * as selectors from 'app/store/selectors';
import { CheckoutBakeryOrderFormCust } from 'app/bakery-cust/checkout/checkout-bakery-order-form';
import * as originalMoment from 'moment/moment';
import { extendMoment } from 'moment-range';
import { WholesalerOrg } from 'app/store/wholesaler-org';
import { Bakery } from 'app/store/bakery';
import { WholesalerGroup } from 'app/store/wholesaler-group';
import { validatePositiveInteger } from 'app/shared/forms/positive-integer-validator';
import { validateNoWhitespaces } from 'app/shared/forms/no-whitespaces-validator';
import { isWithinAvailabilityRange } from 'app/isWithinAvailabilityRange';
import { UnsafeAction } from 'app/store/effects/unsafe-action';
import * as RemoteData from 'app/remote-data';

const moment = extendMoment(originalMoment);

export interface UiState {
  isLoading: boolean;
  orderItems?: OrderItem[];
  selectedCustomer?: User;
}
@Component({
  template: require('./create-order.component.html'),
})
export class CreateOrderComponent implements OnInit, OnDestroy {
  alertSub: Subscription;
  filterProductSub: Subscription;
  uiState$: Observable<{ isLoading: boolean; }>;
  bakeryProducts$: Observable<RemoteData.RemoteData<any[]>>;
  checkout = false;
  wholesaler = true;
  form: FormGroup;
  private filterProductText$ = new BehaviorSubject<string>('');

  private orderValuesSub: Subscription;
  private submitCheckoutSuccessSub: Subscription;
  private validatorsSub: Subscription;
  private formValuesSub: Subscription;
  private confirmOrderSub: Subscription;
  private dateValidatorSub: Subscription;
  private cartTotalSub: Subscription;
  private storeType: string[] = ['wholesale_only', 'retail_wholesale'];

  validFulfillmentDate = false;
  dateValidationErrors: Object[];
  order$: Observable<BakeryOrder>;
  formValid$: Observable<boolean>;
  confirmOrder$ = new Subject<any>();
  createToken$ = new Subject<any>();
  stripeToken$ = new Subject<any>();
  cardValid$ = new Subject<boolean>();
  triggerValidation$ = new Subject<boolean>();
  isSubmitting = false;
  selectedCustomer: User;
  bakery: Bakery;
  selectedWholesalerOrg: WholesalerOrg;
  selectedWholesalerGroup: WholesalerGroup;

  constructor(
    private bakeryCustProductsEffects: BakeryCustProductsEffects,
    private store: Store<AppState>,
    private alertService: AlertService,
    private localStore: LocalStorageService,
    private actions$: NgRxActions,
    private route: ActivatedRoute,
    private usersEffects: UsersEffects,
    private router: Router,
    private fb: FormBuilder,
  ) {
    this.form = this.fb.group(
      {
        fulfillment_type: [null, [Validators.required]],
        fulfillment_date: [null, [Validators.required, futureDateValidator, twoDaysDateValidator]],
        fulfillment_at: [null, [Validators.required]],
        fulfillment_location_id: [null, [Validators.required]],
        street: [null],
        unit: [null],
        city: [null],
        state: [null],
        zip: [null],
        order_customer_id: [null],
        order_customer_notes: [null],
        attached_images_attributes: this.fb.array([]),
        payment_type: ['credit_card', [Validators.required]],
        amount: [{value: 0, disabled: true}, [validatePositiveInteger]],
        notes: [null],
        payment_card_selection: [null],
        order_type: ['once', [Validators.required]],
        schedule_interval: [{value: 'none', disabled: true}, [Validators.required]],
        payment_amount_type: ['full', [Validators.required]]
      },
    );
  }

  onChangeFilterProductText(filterProductText: string) {
    this.filterProductText$.next(filterProductText);
    this.bakeryCustProductsEffects.requestGetBakeryProducts(filterProductText);
  }

  ngOnInit() {
    this.wholesaler = true;
    this.bakeryCustProductsEffects.requestGetProductCategories();
    this.store.dispatch({type: Actions.LOAD_STORE_PRODUCT_CATEGORIES});
    this.store.dispatch({type: Actions.INIT_WHOLESALE_BAKERY_ORDER});
    this.order$ = this.store.select(selectors.getWholesaleOrder);

    const wholesaleCartId: string = this.localStore.retrieve('wholesaleCartId');
    this.store.dispatch({
      type: Actions.REQUEST_GET_WHOLESALE_CART,
      payload: wholesaleCartId,
    });

    this.bakeryProducts$ = this.store
      .select(selectors.selectBakeryProducts)
      .map((bakeryProducts: RemoteData.RemoteData<any[]>) => {

       const mapper = product => {
            const bakery_product_templates = product.bakery_product_templates.map(template => {
                const template_join =
                    product.bakery_product_product_templates.filter(x => x.bakery_product_template_id === template.id)[0];
                const condition =
                    template_join.wholesaler_product_overrides &&
                    template_join.wholesaler_product_overrides
                    .some(override => override.wholesaler_group_id === this.selectedCustomer.wholesaler_group.id);
                if (condition) {
                    const wholesaler_product_override =
                        template_join
                    .wholesaler_product_overrides
                    .filter(override => override.wholesaler_group_id === this.selectedCustomer.wholesaler_group.id)[0];
                    return {
                        ...template,
                        order_minimum: wholesaler_product_override.order_minimum,
                        unit_price: wholesaler_product_override.unit_price,
                    };
                } else {
                    const defaultDiscount = (100 - this.selectedCustomer.wholesaler_group.default_discount) / 100;
                    return {...template, unit_price: template.unit_price * defaultDiscount};
                }
            });

            return {...product, bakery_product_templates: bakery_product_templates};
        }
       return RemoteData.map(xs => {
        return xs
          .filter(bakeryProduct => this.storeType.includes(bakeryProduct.store_type))
          .filter(bakeryProduct => !bakeryProduct.archived)
          .map(mapper);
       }, bakeryProducts);
      });

    this.uiState$ = Observable
    .combineLatest(
      this.usersEffects.currentUser$,
      this.store.select('bakeryWholesaleUiState', 'cartState'),
      this.store.select(selectors.getCustomerOrder),
      this.store.select('entitiesState'),
      this.filterProductText$,
      (currentUser, cartState, checkoutBakeryOrderForm, entitiesState) => ({currentUser, cartState, checkoutBakeryOrderForm, entitiesState})
    )
    .map((combined) => {
      const checkoutBakeryOrderForm = combined.checkoutBakeryOrderForm;
      const entitiesState = <EntitiesState>combined.entitiesState;
      const cartState = <CartState>combined.cartState;
      this.selectedCustomer = <User> combined.currentUser;
      this.selectedWholesalerOrg = <WholesalerOrg> combined.currentUser.wholesaler_org;
      this.selectedWholesalerGroup = <WholesalerGroup> combined.currentUser.wholesaler_group;
      this.bakery = entitiesState.bakeries[(<any>combined.currentUser).bakery_id];
      const isLoading = cartState.state === 'loading' || !checkoutBakeryOrderForm
      if (isLoading) {
        return {
          isLoading: true,
          orderItems: []
        };
      }

      let orderItems = [];
      const wholesaleCartId: string = this.localStore.retrieve('wholesaleCartId');
      if (entitiesState.bakery_carts[wholesaleCartId]) {
        orderItems = (<any[]>entitiesState.bakery_carts[wholesaleCartId].order_items).map(item => {
          return entitiesState.order_items[item];
        });
      }

      return {
        isLoading: false,
        orderItems: orderItems,
        selectedCustomer: this.selectedCustomer
      };
    })
    .startWith({isLoading: true})
    .shareReplay(1);

    this.dateValidatorSub = Observable.combineLatest(
      this.form.get('fulfillment_date').valueChanges,
      this.order$.filter(x => !!x).map(order => order.order_items).distinctUntilChanged()
    )
    .subscribe(([date, orderItems]) => {
      this.dateValidationErrors = (orderItems as any[]).map((item) => {
        const errors = [];
        if (item.bakery_product.available_day_specific) {
          const day = moment(date).format('dddd');

          if (!item.bakery_product.available_days.includes(day)) {
            errors.push({name: item.bakery_product.name, error: `not sold on ${day}`})
          }
        }

        if (item.bakery_product.available_range_specific) {
          const {
            available_range_start_day,
            available_range_start_month,
            available_range_end_day,
            available_range_end_month
          } = item.bakery_product;
          const availabilityRange = {
            available_range_start_day,
            available_range_start_month,
            available_range_end_day,
            available_range_end_month
          };
          if (!isWithinAvailabilityRange(date, availabilityRange)) {
            errors.push({name: item.bakery_product.name, error: 'not sold on this date'});
          }
        }

        return errors;
      }).filter(arr => arr.length !== 0);

      if (this.dateValidationErrors.length > 0) {
        const messageType = 'warning';
        const messageContent = `The following products are not sold on this day (${this.dateValidationErrors.map(errors => errors[0].name).join(', ')})`;
        this.alertService[messageType](messageContent);
      }

      this.validFulfillmentDate = this.dateValidationErrors.length > 0 ? false : true;
    });

    this.orderValuesSub = Observable.combineLatest(this.order$, (order: CheckoutBakeryOrderFormCust) => (order))
      .filter((order) => order != null)
      .subscribe((order) => {
        if (!order.form_fulfillment_data) return;
        this.form.patchValue(
          {
            fulfillment_type: order.form_fulfillment_data.fulfillment_type,
            fulfillment_at: order.form_fulfillment_data.fulfillment_at,
            fulfillment_location_id: order.form_fulfillment_data.fulfillment_location_id,
            street: order.form_fulfillment_data.fulfillment_address.street,
            unit: order.form_fulfillment_data.fulfillment_address.unit,
            city: order.form_fulfillment_data.fulfillment_address.city,
            state: order.form_fulfillment_data.fulfillment_address.state,
            zip: order.form_fulfillment_data.fulfillment_address.zip
          },
          {
            emitEvent: false,
          }
        );
        // Since `emitEvent` is set to `false` above, validation needs to be manually triggered.
        // `emitEvent` is set to false because otherwise there's an infinite loop when the form
        // is updated by filling a field.
        // Fixes the following bug:
        // - click on "New Order"
        // - select "Retail Order"
        // - add a product
        // - go to checkout
        // - select "pickup" as fulfilment method
        // - select a fulfilment date in the future
        // - select a pickup location
        // - fill in customer info
        // - select no payment
        // - notice the create order button is enabled
        // - select"delivery" as fulfilment method
        // - notice that the delivery address is blank but the create order button is enabled
        //   (the create order button should be disabled instead)
        this.triggerValidation$.next(true);
      });

    this.validatorsSub = this.form.valueChanges
      .withLatestFrom(
        (formValue) => ({formValue})
      )
      .subscribe(({formValue}) => {
        const isAddressRequired = formValue.fulfillment_location_id === 'add_new';

        const streetCtrl = this.form.get('street'),
              cityCtrl = this.form.get('city'),
              stateCtrl = this.form.get('state'),
              zipCtrl = this.form.get('zip');

        if (isAddressRequired) {
          streetCtrl.setValidators([Validators.required, validateNoWhitespaces]);
          cityCtrl.setValidators([Validators.required, validateNoWhitespaces]);
          stateCtrl.setValidators([Validators.required, validateNoWhitespaces]);
          zipCtrl.setValidators([Validators.required]);
        } else {
          streetCtrl.setValidators([]);
          cityCtrl.setValidators([]);
          stateCtrl.setValidators([]);
          zipCtrl.setValidators([]);
        }
      });

    this.formValuesSub = this.form.valueChanges
      .withLatestFrom(
        this.order$,
        this.usersEffects.currentUser$,
        (formValue, checkoutBakeryOrderForm, currentUser) => ({formValue, checkoutBakeryOrderForm, currentUser})
      )
      .debounce(() => Observable.timer(500))
      .distinctUntilChanged((x, y) => JSON.stringify(x) === JSON.stringify(y))
      .subscribe(({formValue, checkoutBakeryOrderForm, currentUser}: {formValue: any, checkoutBakeryOrderForm: CheckoutBakeryOrderFormCust, currentUser: User}) => {
        const fulfillmentLocationType =
          formValue.fulfillment_type === 'pickup'
          ? 'BakeryLocation'
          : formValue.fulfillment_type === 'shipping' || formValue.fulfillment_type === 'delivery'
          ? 'Address'
          : null;

        const fulfillmentLocationId =
            formValue.fulfillment_type === checkoutBakeryOrderForm.form_fulfillment_data.fulfillment_type
            ? formValue.fulfillment_location_id
            : null;

        const fulfillmentDateChanged =
          formValue.fulfillment_date !== checkoutBakeryOrderForm.form_fulfillment_data.fulfillment_date;

        const fulfillment = {
          fulfillment_type: fulfillmentDateChanged ? null : formValue.fulfillment_type,
          fulfillment_date: formValue.fulfillment_date,
          fulfillment_at: fulfillmentDateChanged ? null : formValue.fulfillment_at,
          fulfillment_location_type: fulfillmentDateChanged ? null : fulfillmentLocationType,
          fulfillment_location_id: fulfillmentDateChanged ? null : fulfillmentLocationId,
        };

        this.store.dispatch({
          type: Actions.UPDATE_WHOLESALE_BAKERY_ORDER,
          payload: {
            order_type: formValue.order_type,
            schedule_interval: formValue.schedule_interval,
            order_customer_id: currentUser.wholesaler_org.id,
            order_customer_name: currentUser.wholesaler_org.name,
            order_customer_email: currentUser.wholesaler_org.email,
            order_customer_notes: formValue.order_customer_notes,
            order_customer_phone_number: currentUser.wholesaler_org.phone_number,
            attached_images_attributes: formValue.attached_images_attributes.map(attachedImage => attachedImage.image),
            form_fulfillment_data: {
              ...checkoutBakeryOrderForm.form_fulfillment_data,
              ...fulfillment,
              fulfillment_address: {
                street: formValue.street,
                unit: formValue.unit,
                city: formValue.city,
                state: formValue.state,
                zip: formValue.zip,
              }
            },
            payment_type: formValue.payment_type,
            payment_card_selection: formValue.payment_card_selection,
            amount: formValue.amount,
            notes: formValue.notes,
          },
        })
      });

    this.submitCheckoutSuccessSub = this.actions$
      .ofType(Actions.REQUEST_CREATE_WHOLESALE_BAKERY_ORDER_SUCCESS)
      .subscribe((action: UnsafeAction) => {
        // forward to the order detail view.
        this.router.navigate([`/store/wholesaler/orders/${action.payload}`]);
      });

    this.filterProductSub = this.filterProductText$
      .subscribe(searchTextValue => {
        this.store.dispatch({
          type: Actions.SET_STORE_BAKERY_PRODUCTS_SEARCH_TEXT,
          payload: searchTextValue,
        })
      });

    this.alertSub = this.actions$
      .subscribe((action: UnsafeAction) => {
        let messageType = null,
            messageContent = null;

        switch (action.type) {
          case Actions.REQUEST_GET_WHOLESALE_CART_ERROR:
            this.isSubmitting = false;
            this.store.dispatch({
              type: Actions.ADD_PRODUCT_TEMPLATE_TO_WHOLESALE_CART,
              payload: {
                wholesaler_order: true,
                order_items_attributes: [],
              }
            })
          case Actions.REQUEST_DELETE_WHOLESALE_CART_SUCCESS:
            messageType = 'success';
            messageContent = `Got it. We have deleted the current order.`;
            break;
          case Actions.REQUEST_UPDATE_WHOLESALE_CART_SUCCESS:
          case Actions.REQUEST_CREATE_WHOLESALE_CART_SUCCESS:
            messageType = 'success';
            messageContent = `Got it. We have updated the current order.`;
            break;
          case Actions.REQUEST_CREATE_WHOLESALE_BAKERY_ORDER_ERROR:
            messageType = 'warning';
            messageContent = action.payload.error || 'Something went wrong!';
            break;
        }

        if (messageType != null && messageContent != null) {
          this.alertService[messageType](messageContent);
        }
      });

    this.form.get('order_type').valueChanges
      .debounce(() => Observable.timer(200))
      .subscribe((orderType: string) => {
        if (orderType === 'once') {
          this.form.patchValue({schedule_interval: 'none'}, {emitEvent: false});
          this.form.get('schedule_interval').disable();
        } else {
          this.form.patchValue({schedule_interval: null}, {emitEvent: false});
          this.form.get('schedule_interval').enable();
        }
      });

    this.formValid$ = Observable
      .combineLatest(
        this.form.valueChanges,
        this.cardValid$.startWith(false),
        this.store.select('bakeryWholesaleUiState', 'cartState'),
        this.triggerValidation$,
        (formValue, isCardValid, cart, _) => ({formValue, isCardValid, cart})
      )
      .map(({formValue, isCardValid, cart}: {formValue: any, isCardValid: boolean, cart: CartState}) => {
        const validCart = cart.result && cart.result.order_items.length > 0;
        if (!this.form.valid || !this.validFulfillmentDate || !validCart) return false;

        if (formValue.payment_type === 'none') return true;

        if (formValue.payment_card_selection !== 'add_new' && formValue.payment_card_selection != null) return true;

        return isCardValid;
      });

    this.confirmOrderSub = this.confirmOrder$
      .skipUntil(this.formValid$)
      .skipUntil(Observable.of(!this.isSubmitting))
      .switchMap(() => this.store.select(selectors.getCustomerOrder).take(1))
      .switchMap((orderForm) => {
        this.isSubmitting = true;
        if (orderForm.payment_type === 'credit_card' && (orderForm.payment_card_selection === 'add_new' || orderForm.payment_card_selection == null)) {
          // Request a new token be created.
          this.createToken$.next();

          return this.stripeToken$
            .switchMap((tokenResponse) => {
              // Catch a token error, and quit.
              if (tokenResponse.error != null) {
                let errorMessage = 'Something went wrong!';

                if (!!tokenResponse.error && !!tokenResponse.error.message) {
                  errorMessage = tokenResponse.error.message;
                }

                this.alertService.warning(errorMessage);

                return Observable.empty();
              }
              // Dispatch two actions: update order and submit order
              return Observable.from([
                {
                  type: Actions.UPDATE_WHOLESALE_BAKERY_ORDER,
                  payload: {
                    stripe_token: tokenResponse.token.id,
                  }
                },
                {
                  type: Actions.REQUEST_CREATE_WHOLESALE_BAKERY_ORDER,
                  payload: {
                    wholesaler: true,
                  }
                },
              ]);
            });
        } else {
          return Observable.of({
            type: Actions.REQUEST_CREATE_WHOLESALE_BAKERY_ORDER,
            payload: {
              wholesaler: true,
            }
          });
        }
      })
      .subscribe((action: any) => {
        if (action != null && !!action.type) {
          this.store.dispatch(action)
        }
      });
  }

  ngOnDestroy() {
    this.alertSub.unsubscribe();
    this.filterProductSub.unsubscribe();
    this.submitCheckoutSuccessSub.unsubscribe();
    this.orderValuesSub.unsubscribe();
    this.validatorsSub.unsubscribe();
    this.formValuesSub.unsubscribe();
    this.confirmOrderSub.unsubscribe();
  }

  onClickConfirmOrder() {
    this.confirmOrder$.next();
  }

  onClickCancelOrder() {
    this.store.dispatch({
      type: Actions.REQUEST_DELETE_WHOLESALE_CART,
    });
    this.router.navigate([`/store/wholesaler/management/schedule`]);
  }

  get isWholesalerManualInvoiceInterval(): boolean {
    return this.selectedWholesalerOrg && this.selectedWholesalerOrg.invoice_interval === 'manual';
  }
}
