import {
  Component,
  OnInit,
  OnDestroy,
  EventEmitter,
  ViewChild
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { Subject } from 'rxjs/Subject';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Actions as NgRxActions } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { AppState } from 'app/store/app-state';
import { AlertService } from 'app/shared/components/alerts/alert.service';
import { ModalComponent } from 'app/shared/components/modals/modal.component';
import { Actions } from 'app/store/actions';
import { BakeryMgmtProductAttributesEffects } from 'app/store/effects/bakery-mgmt/bakery-mgmt-bakery-product-attributes.effects';
import { BakeryAttribute } from 'app/store/bakery-attribute';
import { BakeryMgmtUiState } from 'app/store/bakery-mgmt-ui-state';
import { EntitiesState } from 'app/store/entities-state';
import { denormalize as denormalizeProdAtt } from 'app/store/bakery-product-attributes/utils';
import {
  SimpleFormState,
  SubmittableFormState
} from 'app/shared/forms/form-states';
import { formArrayNameProvider } from '@angular/forms/src/directives/reactive_directives/form_group_name';

export interface UiState {
  isLoading: boolean;
  bakeryAttribute?: BakeryAttribute;
}

@Component({
  template: require('./detail.component.html')
})
export class BakeryMgmtProductAttributeDetailComponent
  implements OnInit, OnDestroy {
  @ViewChild(ModalComponent) deleteModal: ModalComponent;

  uiState$: Observable<UiState>;
  detailsForm: FormGroup;
  updateSuccess$: Observable<any>;
  bakeryAttribute$: Observable<BakeryAttribute>;

  public isDirty = false;
  public isValid = false;

  private attributeId$: Observable<number>;
  private attributeIdSub: Subscription;
  private updateDetails$ = new Subject<any>();
  private updateDetailsSub: Subscription;
  private confirmDelete$ = new Subject<any>();
  private confirmDeleteSub: Subscription;
  private deleteSuccessSub: Subscription;
  private alertsSub: Subscription;
  private formSubmitSub: Subscription;
  public formState$: Observable<SubmittableFormState>;
  public detailsFormState$ = new Subject<SimpleFormState>();
  public associationsFormState$ = new Subject<SimpleFormState>();
  public formSubmit$ = new Subject<any>();
  public formReset$ = new Subject<any>();
  public editCautionVisible$: Observable<boolean>;

  constructor(
    private bakeryMgmtProductAttributesEffects: BakeryMgmtProductAttributesEffects,
    private router: Router,
    private route: ActivatedRoute,
    private actions$: NgRxActions,
    private store: Store<AppState>,
    private alertService: AlertService
  ) {
    this.bakeryMgmtProductAttributesEffects.requestGetBakeryAttributes();
    this.attributeId$ = this.route.params.map((params): number =>
      parseInt(params['attribute_id'])
    );

    this.attributeIdSub = this.attributeId$.subscribe((id: number) =>
      this.bakeryMgmtProductAttributesEffects.requestGetBakeryAttributeDetails(
        id
      )
    );

    this.uiState$ = Observable.combineLatest(
      this.store.select('bakeryMgmtUiState'),
      this.store.select('entitiesState'),
      this.attributeId$,
      (uiState, entities, attributeId) => ({ uiState, entities, attributeId })
    )
      .map(combined => {
        const bakeryMgmtUiState = <BakeryMgmtUiState>combined.uiState;
        const entitiesState = <EntitiesState>combined.entities;
        const attributeId = <number>combined.attributeId;

        if (
          bakeryMgmtUiState.bakeryAttributesUiState.isDetailLoading ||
          entitiesState.bakery_attributes[attributeId] == null
        ) {
          return {
            isLoading: true
          };
        }

        const bakeryAttribute = denormalizeProdAtt(
          Object.assign({}, entitiesState.bakery_attributes[attributeId]),
          entitiesState
        );

        return {
          isLoading: false,
          bakeryAttribute: bakeryAttribute
        };
      })
      .shareReplay(1);

    this.bakeryAttribute$ = this.uiState$
      .filter((uiState: UiState) => !uiState.isLoading)
      .map((uiState: UiState): BakeryAttribute => uiState.bakeryAttribute);

    this.updateDetailsSub = this.updateDetails$
      .combineLatest(
        this.bakeryAttribute$.take(1),
        (updateDetailsPayload, bakeryAttribute) => ({
          updateDetailsPayload,
          bakeryAttribute
        })
      )
      .subscribe(({ updateDetailsPayload, bakeryAttribute }) => {
        this.bakeryMgmtProductAttributesEffects.requestUpdateBakeryAttribute(
          bakeryAttribute,
          updateDetailsPayload
        );
      });

    this.confirmDeleteSub = this.confirmDelete$
      .switchMap(() => this.bakeryAttribute$.take(1))
      .subscribe(bakeryAttribute => {
        this.bakeryMgmtProductAttributesEffects.requestDeleteBakeryAttribute(
          bakeryAttribute
        );
      });

    this.deleteSuccessSub = this.actions$
      .ofType(Actions.REQUEST_DELETE_BAKERY_ATTRIBUTE_SUCCESS)
      .delay(1500)
      .subscribe(() =>
        this.router.navigate(['/bakery_mgmt/product_mgmt/main/attributes'])
      );

    this.updateSuccess$ = this.bakeryMgmtProductAttributesEffects.updateBakeryAttributeEffects$.filter(
      action => action.type === Actions.REQUEST_UPDATE_BAKERY_ATTRIBUTE_SUCCESS
    );

    this.alertsSub = this.actions$.subscribe((action: UnsafeAction) => {
      let messageType = null,
        messageContent = null;

      switch (action.type) {
        case Actions.REQUEST_UPDATE_BAKERY_ATTRIBUTE_SUCCESS:
          messageType = 'success';
          messageContent = `The attribute has been updated.`;
          break;

        case Actions.REQUEST_DELETE_BAKERY_ATTRIBUTE_SUCCESS:
          messageType = 'success';
          messageContent = `The attribute has been deleted.`;
          break;

        case Actions.REQUEST_UPDATE_BAKERY_ATTRIBUTE_ERROR:
        case Actions.REQUEST_DELETE_BAKERY_ATTRIBUTE_ERROR:
          messageType = 'warning';
          messageContent = 'Something went wrong!';
          break;
      }

      if (messageType != null && messageContent != null) {
        this.alertService[messageType](messageContent);
      }
    });
  }

  ngOnInit() {
    this.formState$ = Observable.combineLatest(
      this.detailsFormState$,
      this.associationsFormState$,
      this.bakeryAttribute$,
      (
        detailsFormState,
        associationsFormState,
        bakeryAttribute
      ): SubmittableFormState => {
        const isDirty =
          detailsFormState.isDirty || associationsFormState.isDirty;
        const isValid =
          detailsFormState.isValid && associationsFormState.isValid;

        const associationsVariants =
          associationsFormState.value.bakery_attribute_variants_attributes;

        const newAssociationState = associationsVariants
          .filter(variant => !variant._destroy)
          .concat(
            (<any>bakeryAttribute.bakery_attribute_variants).filter(
              variant =>
                associationsVariants
                  .filter(variant => variant._destroy)
                  .map(variant => variant.id)
                  .indexOf(variant.id) === -1
            )
          );

        return {
          isValid: isValid,
          isDirty: isDirty,
          isSubmittable: isDirty && isValid && newAssociationState.length > 0,
          value: Object.assign(
            {},
            detailsFormState.value,
            associationsFormState.value
          )
        };
      }
    )
      .startWith({
        isDirty: false,
        isValid: false,
        isSubmittable: false,
        value: {}
      })
      .shareReplay(1);

    this.editCautionVisible$ = this.formState$.map(
      (formState: SubmittableFormState) => formState.isDirty
    );

    this.formSubmitSub = this.formSubmit$
      .switchMap(() => this.formState$.take(1))
      .withLatestFrom(this.uiState$, (formState, uiState) => ({
        formState,
        uiState
      }))
      .subscribe(({ formState, uiState }) => {
        if (!formState.isSubmittable) return null;
        this.bakeryMgmtProductAttributesEffects.requestUpdateBakeryAttribute(
          uiState.bakeryAttribute,
          {
            ...formState.value
          }
        );
      });
  }

  ngOnDestroy() {
    this.attributeIdSub.unsubscribe();
    this.updateDetailsSub.unsubscribe();
    this.confirmDeleteSub.unsubscribe();
    this.deleteSuccessSub.unsubscribe();
    this.alertsSub.unsubscribe();
  }

  onClickFormSubmit() {
    this.formSubmit$.next();
  }

  onClickFormReset() {
    this.formReset$.next();
  }

  onSubmitDetailsForm(detailsUpdatePayload: any) {
    this.updateDetails$.next(detailsUpdatePayload);
  }

  onClickDelete() {
    this.deleteModal.openModal();
  }

  onConfirmDelete() {
    this.confirmDelete$.next();
  }

  onChangeDetailsFormState(formState: SimpleFormState) {
    this.detailsFormState$.next(formState);
  }

  onChangeAssociationsFormState(formState: SimpleFormState) {
    this.associationsFormState$.next(formState);
  }
}
