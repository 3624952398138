import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs/Observable';

@Component({
  selector: 'popout-list-header',
  template: `
    <li class="popout__list-header">
      <p class="popout__list-header-title">{{ title }}</p>
    </li>
  `,
})
export class PopoutListHeaderComponent {
  @Input('title') title: string;

  constructor() { }

}
