import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs/Observable';

import { User } from 'app/store/user';
import { validateNoWhitespaces } from 'app/shared/forms/no-whitespaces-validator';

export interface UserDetailsForm {
  first_name: string;
  last_name: string;
  email: string;
}

@Component({
  selector: 'account-settings-edit-user-details',
  template: `
    <form
      [formGroup]="form"
      (ngSubmit)="onSubmitForm()"
    >
      <div class="row">
        <div class="columns-4__s columns-6__l">
          <label>Update First Name</label>
          <input
            type="text"
            placeholder="John"
            formControlName="first_name"
          />
        </div>
        <div class="columns-4__s columns-6__l">
          <label>Update Last Name</label>
          <input
            type="text"
            placeholder="Smith"
            formControlName="last_name"
          />
        </div>
      </div>
      <div class="row">
        <div class="columns-4__s columns-6__l">
          <label>Update Email</label>
          <input
            type="email"
            placeholder="user@OrderNova.com"
            formControlName="email"
          />
        </div>
      </div>
      <div class="row">
        <div class="columns-9__m columns-12__l margin-bottom--delta float--right">
          <a (click)="onClickResetForm()" class="link--secondary margin-right--delta" [ngClass]="{'link--disabled': !form.dirty, 'clickable': form.dirty }">
            Cancel
          </a>
          <button type="submit" class="button--primary button--input label--offset" [ngClass]="{'button--disabled': !form.valid || !form.dirty}">
            Save
          </button>
        </div>
      </div>
    </form>
  `
})
export class AccountSettingsEditUserDetailsComponent implements OnInit {
  @Input('user$') user$: Observable<User>;
  @Input('success$') success$: Observable<any>;
  @Output('submitForm') submitForm = new EventEmitter<UserDetailsForm>();
  form: FormGroup;
  private latestUser: User;

  ngOnInit() {
    this.form = new FormGroup({
      'first_name': new FormControl(null, [Validators.required, validateNoWhitespaces]),
      'last_name': new FormControl(null, [Validators.required, validateNoWhitespaces]),
      'email': new FormControl(null, [Validators.required, Validators.email]),
    });

    this.user$
      .filter(user => user != null)
      .forEach((user: User) => {
        this.latestUser = user;

        this.form.setValue({
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
        });
      });

    this.success$
      .subscribe(
        val => this.resetUserDetailsForm()
      )
  }

  onClickResetForm() {
    this.resetUserDetailsForm();
  }

  onSubmitForm() {
    this.submitForm.next({
      first_name: this.form.value.first_name,
      last_name: this.form.value.last_name,
      email: this.form.value.email,
    });
  }

  private resetUserDetailsForm() {
    this.form.reset({
      first_name: this.latestUser.first_name,
      last_name: this.latestUser.last_name,
      email: this.latestUser.email,
    });
  }
}
