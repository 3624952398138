import { Component, OnInit, Output } from '@angular/core';

import { BakeryMgmtPromoCodesEffects } from 'app/store/effects/bakery-mgmt/bakery-mgmt-promo-codes.effects';
import { PromoCode } from 'app/store/promo-code';
import { BehaviorSubject } from 'rxjs';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'bakery-mgmt-promo-codes-master',
  template: `
    <bakery-mgmt-page-header title="Manage Promotion Codes"></bakery-mgmt-page-header>
    <bakery-mgmt-promo-codes-list></bakery-mgmt-promo-codes-list>
  `
})
export class BakeryMgmtPromoCodesMasterComponent implements OnInit {
  editablePromoCodeSub: Subscription;
  private editablePromoCode$ = new BehaviorSubject<PromoCode>(null);
  constructor(
    private bakeryMgmtPromoCodesEffects: BakeryMgmtPromoCodesEffects,
  ) {}

  ngOnInit() {
    this.bakeryMgmtPromoCodesEffects.requestGetPromoCodes();
  }
}
