import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/app-state';
import { Base64FileDetails } from 'app/shared/components/file-upload-trigger/file-upload-trigger.component';

@Component({
  selector: 'bakery-mgmt-new-order-checkout-notes-edit',
  template: require('./notes-edit.component.html'),
})
export class BakeryMgmtNewOrderCheckoutNotesEditComponent implements OnInit, OnDestroy {
  @Input() parent: FormGroup;
  @Input() canAddNotes = true;

  constructor(
    private store: Store<AppState>,
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
  }


  ngOnDestroy() {
  }

  removeImage(index, image) {
    (this.parent.get('attached_images_attributes') as FormArray).removeAt(index);
  }

  get imageControls(): FormGroup[] {
    return (this.parent.get('attached_images_attributes') as FormArray).controls as FormGroup[];
  }

  onAddImageFile($event: Base64FileDetails) {
    console.log(`Added file:`, $event);
    const attachedImagesCtrlArray = this.parent.get('attached_images_attributes') as FormArray;
    const imageFormGroup = this.fb.group({
      image: new FormControl({
        image: `data:${$event.mimeType};base64,${$event.encoded}`,
      })
    });

    attachedImagesCtrlArray.push(imageFormGroup);
    imageFormGroup.markAsDirty();
  }
}
