import { Injectable } from '@angular/core';
import * as moment from 'moment/moment';
import { Moment } from 'moment/moment';

const defaultStartDate = () =>
  persistedDateOr('searchStartDate', moment().startOf('day'));

const defaultEndDate = () =>
  persistedDateOr('searchEndDate', moment().endOf('day').add(7, 'day'));

const persistedDateOr = (key, default_) =>
  persistedDate(key).isValid() ? persistedDate(key) : default_;

const persistedDate = key =>
  moment(parseInt(sessionStorage.getItem(key), 10));

@Injectable()
export class OrderItemsDateRangeService {
  defaultStartDate: Moment = defaultStartDate();
  defaultEndDate: Moment = defaultEndDate();
}
