import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

import { AlertService } from 'app/shared/components/alerts/alert.service';

@Component({
  selector: 'edit-caution-alert',
  template: `
    <ng-content></ng-content>
  `
})
export class EditCautionAlertComponent implements OnInit, OnDestroy {
  @Input('visible$') visible$: Observable<boolean>;
  @Input('message') message: string;

  private visibleSub: Subscription;
  private alertId: string;
  private _isVisible = false;

  constructor(
    private alertService: AlertService,
  ) { }

  ngOnInit() {
    // Conditionally subscribe -- observable may not be present.
    if (this.visible$) {
      this.visibleSub = this.visible$
        .subscribe((isVisible: boolean) => {
          this.isVisible = isVisible;
        });
    }
  }

  ngOnDestroy() {
    if (this.visibleSub) {
      this.visibleSub.unsubscribe();
    }

    this.removeAlert();
  }

  get isAlertPresented(): boolean {
    return this.alertId != null;
  }

  @Input('visible')
  set isVisible(isVisible: boolean) {
    this._isVisible = isVisible;

    this.updateAlertVisibility();
  }

  get isVisible(): boolean {
    return this._isVisible;
  }

  private updateAlertVisibility() {
    if (this.isVisible && !this.isAlertPresented) {
      // Show alert
      this.showAlert();
    } else if (!this.isVisible && this.isAlertPresented) {
      // Remove alert
      this.removeAlert();
    }
  }

  private showAlert() {
    const alert = this.alertService.editCaution(this.message);
    this.alertId = alert.id;
  }

  private removeAlert() {
    if (this.alertId != null) {
      this.alertService.remove(this.alertId);
      this.alertId = null;
    }
  }
}
