import { AbstractControl } from '@angular/forms';

export function phoneNumberValidator(control: AbstractControl): {[key: string]: boolean} {
  const cleanedValue = control.value != null ? control.value.replace(/[^0-9]/gi, '') : '';

  if (cleanedValue.length === 0) {
    // If the string is empty, don't log a validation error.
    // That allows this validator to be used on optional fields.
    return null;
  }

  if (cleanedValue.length !== 10) {
    return {
      invalid_phone_number: true,
    };
  }

  return null;
}
