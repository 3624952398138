import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

import { BakeryOrder } from 'app/store/bakery-order';

@Component({
  selector: 'bkry-mgmt-order-balance',
  template: `
    <ng-container *ngIf="bakeryOrder.invoice">{{ bakeryOrder.invoice.balance | centsObservable | async }}</ng-container>
    <ng-container *ngIf="!bakeryOrder.invoice">{{ bakeryOrder.total_price | centsObservable | async }}</ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderBalanceComponent {
  @Input() bakeryOrder: BakeryOrder;
}
