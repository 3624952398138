import { Component, OnInit, OnDestroy, Input, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { Subject } from 'rxjs/Subject';

import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { Actions } from 'app/store/actions';
import { BakeryProduct } from 'app/store/bakery-product';
import { BakeryMgmtProductsEffects } from 'app/store/effects/bakery-mgmt/bakery-mgmt-products.effects';
import { DetailUiState } from 'app/bakery-mgmt/product-mgmt/products/detail-ui-state';
import { ModalComponent } from 'app/shared/components/modals/modal.component';

@Component({
  selector: 'product-detail-forms-edit-delete-button',
  template: `
    <button *ngIf="(isButtonVisible$|async)" (click)="onClickDelete()" class="button--danger button--input button--full">
      Delete
    </button>
    <modal
      #deleteModal
      title="Are you sure about this?"
      intro="You are about to delete this product. Once the product is deleted, it will no longer be available in the system. This action cannot be undone. Please be sure this is an action you want to take."
      confirmText="Delete"
      cancelText="Cancel"
      (confirm)="click$.next()"
    ></modal>
  `
})
export class ProductDetailFormsEditDeleteButtonComponent implements OnInit, OnDestroy {
  @Input('uiState$') uiState$: Observable<DetailUiState>;
  @ViewChild(ModalComponent) deleteModal: ModalComponent;

  safeProduct$: Observable<BakeryProduct>;
  isButtonVisible$: Observable<boolean>;

  click$ = new Subject<any>();
  clickSub: Subscription;
  deleteResultSub: Subscription;

  constructor(
    private bakeryMgmtProductsEffects: BakeryMgmtProductsEffects,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.safeProduct$ = this.uiState$
      .filter((uiState: DetailUiState) => !uiState.isLoading)
      .map((uiState: DetailUiState) => uiState.bakeryProduct);

    this.isButtonVisible$ = this.safeProduct$
      .map((bakeryProduct: BakeryProduct) => !!bakeryProduct.id && bakeryProduct.archived)
      .startWith(false);

    this.clickSub = this.click$
      .switchMap(() => this.safeProduct$.take(1))
      .subscribe((bakeryProduct: BakeryProduct) => {
        this.bakeryMgmtProductsEffects.requestDeleteBakeryProduct(bakeryProduct);
      });

    this.deleteResultSub = this.bakeryMgmtProductsEffects.deleteBakeryProductEffects$
      .subscribe((action: UnsafeAction) => {
        switch (action.type) {
          case Actions.REQUEST_DELETE_BAKERY_PRODUCT_SUCCESS:
            this.router.navigate(['/bakery_mgmt/product_mgmt/main/active_products']);
            break;

          case Actions.REQUEST_DELETE_BAKERY_PRODUCT_ERROR:
            // TODO: Alert!
            break;
        }
      });
  }

  ngOnDestroy() {
    this.clickSub.unsubscribe();
  }

  onClickDelete() {
    this.deleteModal.openModal();
  }
}
