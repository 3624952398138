import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Angular2TokenService } from 'app/angular2-token/angular2-token.service';
import { Store } from '@ngrx/store';
import * as Rx from 'rxjs';

import { AppState } from 'app/store/app-state';
import { AlertService } from 'app/shared/components/alerts/alert.service';
import { BakeryCustEffects } from 'app/store/effects/bakery-cust/bakery-cust.effects';
import { UsersEffects } from 'app/store/effects/users.effects';
import { Actions } from 'app/store/actions';
import { User } from 'app/store/user';
import { hasRole } from 'app/store/users/utils';
import { Role } from 'app/store/role';
import { Bakery } from 'app/store/bakery';
import { MasterState } from 'app/store/reducers/master-state-reducer';
import { UiState } from 'app/shared/components/reset-password/reset-password.component';
import { passwordMatchValidatorBuilder } from 'app/shared/forms/password-match-validator';

@Component({
  template: require('./account-confirmation.component.html'),
})
export class BakeryCustAccountConfirmationComponent implements OnInit {
  uiState$: Rx.Observable<UiState>;

  constructor(
    private tokenService: Angular2TokenService,
    private route: ActivatedRoute,
    private router: Router,
    private bakeryCustEffects: BakeryCustEffects,
    private store: Store<AppState>,
  ) { }

  ngOnInit() {
    this.uiState$ = Rx.Observable
      .combineLatest(
        this.store.select('masterState'),
        this.bakeryCustEffects.currentBakery$.startWith(null),
        (masterState, currentBakery) => ({masterState, currentBakery})
      )
      .map(combined => {
        const masterState = <MasterState>combined.masterState;
        const currentBakery = <Bakery>combined.currentBakery;

        if (masterState.type == null) {
          return {isLoading: true};
        }

        if (masterState.type === 'custom_bakery') {
          return {
            isLoading: currentBakery == null,
            isBakery: true,
            bakery: currentBakery,
          };
        }

        // Shouldn't reach this point...
        return {
          isLoading: true,
        };
      })
  }

  onSubmit() {
  }
}
