import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as Rx from 'rxjs';
import { Actions as NgRxActions } from '@ngrx/effects';

import { AppState } from 'app/store/app-state';
import { EntitiesState } from 'app/store/entities-state';
import { AlertService } from 'app/shared/components/alerts/alert.service';
import { BakeryCustEffects } from 'app/store/effects/bakery-cust/bakery-cust.effects';
import { UsersEffects } from 'app/store/effects/users.effects';
import { Actions } from 'app/store/actions';
import { User } from 'app/store/user';
import { hasRole } from 'app/store/users/utils';
import { Role } from 'app/store/role';
import { Bakery } from 'app/store/bakery';
import { MasterState } from 'app/store/reducers/master-state-reducer';
import { BakeryCart } from 'app/store/bakery-cart';
import { OrderItem } from 'app/store/order-item';
import { CartState } from 'app/store/reducers/bakery-cust-ui-state-reducer';
import * as selectors from 'app/store/selectors';

interface UiState {
  isLoading: boolean;
  order_items: OrderItem[];
  total_price: number;
}

@Component({
  template: require('./cart.component.html'),
})
export class BakeryCustCartComponent implements OnInit, OnDestroy {
  uiState$: Rx.Observable<UiState>;

  constructor(
    private router: Router,
    private alertService: AlertService,
    private bakeryCustEffects: BakeryCustEffects,
    private actions$: NgRxActions,
    private store: Store<AppState>,
  ) {  }

  ngOnInit() {
    this.uiState$ = Rx.Observable
      .combineLatest(
        this.store.select('bakeryCustUiState', 'cartState'),
        this.store.select(selectors.getCustomerCart),
        (cartState, currentCart) => ({cartState, currentCart})
      )
      .map(combined => {
        const bakeryCart = <BakeryCart>combined.currentCart;
        const cartState = <CartState>combined.cartState;

        if (cartState.state == 'loading') {
          return {
            isLoading: true,
          };
        }

        if (cartState.state == 'error' || bakeryCart == null) {
          return {
            isLoading: false,
            order_items: [],
            total_price: 0,
          };
        }

        return {
          isLoading: false,
          order_items: bakeryCart.order_items,
          total_price: bakeryCart.total_price,
        };
      })
      .catch(error => {
        console.error(error);

        return Rx.Observable.throw(error);
      })
      .shareReplay(1);
  }

  ngOnDestroy() {
  }

  onClickRemoveOrderItem(orderItem: OrderItem) {
    this.store.dispatch({
      type: Actions.REQUEST_UPDATE_CUST_CART,
      payload: {
        order_items_attributes: [{
          id: orderItem.id,
          _destroy: true,
        }]
      }
    });
  }

  onChangeOrderItemQuantity(orderItem: OrderItem) {
    this.store.dispatch({
      type: Actions.REQUEST_UPDATE_CUST_CART,
      payload: {
        order_items_attributes: [{
          id: orderItem.id,
          quantity: orderItem.quantity,
        }]
      }
    });
  }

  onClickCheckout() {
    this.router.navigate(['/store/checkout']);
  }

  getAttributes(item) {
    const attributes = item.meta.template_variants.map(variant => (
      `${variant.bakery_product_attribute.visible_name}: <strong>${variant.bakery_attribute_variant.name}</strong>`
    )).concat(item.meta.attribute_variants.map(variant => (
      `${variant.bakery_product_attribute.visible_name}: <strong>${variant.bakery_attribute_variant.name}</strong>`
    )));

    return attributes.join(', ');
  }
}

