import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';

import { OrderSchedule } from 'app/store/order-schedule';
import { AppState } from 'app/store/app-state';
import { PutAction, PutActionPayload } from 'app/store/actions/request.actions';
import { AlertAction } from 'app/shared/components/alerts/actions';
import { orderScheduleSchema } from 'app/store/schema/default-schemata';

const opKey = 'orderScheduleChangeActiveStatus',
      schema = orderScheduleSchema;

@Component({
  selector: 'bkry-mgmt-order-schedule-change-active-status-button',
  template: `
    <a
      (click)="onClick()"
      class="clickable"
    >
      <ng-content></ng-content>
    </a>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderScheduleChangeActiveStatusButtonComponent {
  @Input() orderSchedule: OrderSchedule;
  @Input() newActiveStatus: string;

  constructor(
    private store: Store<AppState>
  ) { }

  onClick() {
    const actionPayload: PutActionPayload = {
      url: `/api/order_schedules/${this.orderSchedule.id}/change_active_status`,
      opKey: opKey,
      schema: schema,
      body: {
        active_status: this.newActiveStatus
      },
      successChainAction: [
        AlertAction.buildSuccess(`The order schedule has been updated.`),
        // {type: Actions.REQUEST_GET_BKRY_MGMT_WHOLESALERS}
      ]
    }
    this.store.dispatch(new PutAction(actionPayload));
  }
}
