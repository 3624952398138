import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';

import { AppState } from 'app/store/app-state';
import * as selectors from 'app/store/selectors';

const compSelector = 'bkry-mgmt-order-details-overview-prez';

function isStringEmpty(text): boolean {
  return text == null
    ? true
    : !text.trim();
}

@Component({
  template: `
    <ng-template [ngIf]="(isLoading$|async)">
      <loading-indicator></loading-indicator>
    </ng-template>
    <ng-template [ngIf]="!(isLoading$|async)">
      <${compSelector}
        [bakeryOrder]="bakeryOrder$ | async"
        [notesVisibility]="notesVisibility$ | async"
      ></${compSelector}>
    </ng-template>
  `,
})
export class OverviewComponent {
  bakeryOrder$ = this.store.select(selectors.getBkryMgmtOrderMgmtDetailResult);
  isLoading$ = this.store.select(selectors.getBkryMgmtOrderMgmtDetailIsLoading);
  isLoadingState$ = this.store.select(selectors.getBkryMgmtOrderMgmtDetailIsLoading);
  notesVisibility$ = this.bakeryOrder$
    .withLatestFrom(
      this.store.select(selectors.getBkryMgmtOrderMgmtDetailState),
      (bakeryOrder, detailState) => ({bakeryOrder, detailState})
    )
    .map(({bakeryOrder, detailState}) => {
      return {
        isBakeryNotesVisible: detailState.notesVisibility === 'bakery' || detailState.notesVisibility === 'both',
        hasBakeryNotes: !isStringEmpty(bakeryOrder.bakery_notes),
        isCustomerNotesVisible: detailState.notesVisibility === 'customer' || detailState.notesVisibility === 'both',
        hasCustomerNotes: !isStringEmpty(bakeryOrder.customer_notes),
      };
    });

  constructor(
    private store: Store<AppState>,
  ) { }
}

@Component({
  selector: compSelector,
  template: require('./overview.component.html'),
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverviewPrezComponent {
  @Input() bakeryOrder: any;
  @Input() notesVisibility: any;

  getAttributes(item) {
    const attributes = item.meta.template_variants.map(variant => (
      `${variant.bakery_product_attribute.visible_name}: <strong>${variant.bakery_attribute_variant.name}</strong><br>`
    )).concat(item.meta.attribute_variants.map(variant => (
      `${variant.bakery_product_attribute.visible_name}: <strong>${variant.bakery_attribute_variant.name}</strong><br>`
    )));

    return attributes.join('');
  }
}
