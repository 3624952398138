import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';
import { Actions as NgRxActions } from '@ngrx/effects';

import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { Actions } from 'app/store/actions';
import { AlertService } from 'app/shared/components/alerts/alert.service';
import { UsersEffects } from 'app/store/effects/users.effects';


@Component({
  selector: 'app-forgot-password',
  template: require('./forgot-password.component.html'),
})
export class ForgotPasswordComponent implements OnInit {
  form: FormGroup;

  private alertsSub: Subscription;

  constructor(
    private alertService: AlertService,
    private actions$: NgRxActions,
    private usersEffects: UsersEffects,
  ) { }

  ngOnInit() {
    this.form = new FormGroup({
      'email': new FormControl(null, [Validators.required, Validators.email]),
    });

    this.alertsSub = this.actions$
      .subscribe((action: UnsafeAction) => {
        let messageType = null,
            messageContent = null;

        switch (action.type) {
          case Actions.REQUEST_USER_FORGOT_PASSWORD_SUCCESS:
            messageType = 'success';
            messageContent = `An email has been sent to ${action.payload.email} containing instructions on how to reset your password.`;
            break;

          case Actions.REQUEST_USER_FORGOT_PASSWORD_ERROR:
            messageType = 'warning';
            messageContent = `System error: Could not reset your password.`;

            if (action.payload.error === 'invalid_email_address') {
              messageContent = `The e-mail address provided does not match a user in our system. Please check your input and try again.`;
            }
            break;
        }

        if (messageType != null && messageContent != null) {
          this.alertService[messageType](messageContent);
        }
      });
  }

  onSubmit() {
    this.usersEffects.requestUserForgotPassword(this.form.value.email);
    this.form.reset();
  }

  ngOnDestroy() {
    this.alertsSub.unsubscribe();
  }
}
