import {
  Component,
  Input,
  Output,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
  EventEmitter,
  ChangeDetectorRef
} from '@angular/core';
import {
  AbstractControl,
  FormGroup,
  Validators,
  FormBuilder
} from '@angular/forms';
import { Subscription, Subject } from 'rxjs';

import { BakeryOrderInvoice } from 'app/store/bakery-order-invoice';
import { PaymentCard } from 'app/store/payment-card';

export interface PaymentData {
  amount: number;
  notes: string;
  payment_type: string;
  payment_card_selection: string;
  payment_card_stripe_token?: string;
}

@Component({
  selector: 'invoice-add-payment-form',
  template: require('./invoice-add-payment-form.component.html'),
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvoiceAddPaymentFormComponent implements OnInit, OnDestroy {
  @Input() isLoading = false;
  @Input() paymentCards: PaymentCard[];
  @Output('onAddPayment') onAddPayment$ = new EventEmitter<PaymentData>();
  @Output('onCancel') onCancel$ = new EventEmitter<undefined>();

  _isManualAllowed = false;
  @Input()
  set isManualAllowed(isAllowed: boolean) {
    this._isManualAllowed = isAllowed;

    this.resetForm();
  }

  get isManualAllowed(): boolean {
    return this._isManualAllowed;
  }

  form: FormGroup;
  cardFormIsValid = false;

  createToken$ = new Subject<undefined>();
  stripeToken$ = new Subject<any>();

  private stripeTokenSub: Subscription;

  constructor(
    private fb: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.form = fb.group(
      {
        payment_type: fb.control(null, [Validators.required]),
        payment_card_selection: fb.control(null, []),
        amount: fb.control(null, [Validators.required]),
        notes: fb.control(null, []),
      },
      paymentFormValidator
    );
  }

  ngOnInit() {
    this.stripeTokenSub = this.stripeToken$
      .subscribe((stripeTokenResult) => {
        this.emitPaymentData({
          ...this.form.value,
          payment_card_stripe_token: stripeTokenResult.token.id
        });
      });
  }

  ngOnDestroy() {
    this.stripeTokenSub.unsubscribe();
  }

  get isCreditCardSelectorVisible(): boolean {
    return this.form.value.payment_type === 'credit_card';
  }

  get isCreditCardFormVisible(): boolean {
    return this.form.value.payment_card_selection === 'add_new';
  }

  get isSubmittable(): boolean {
    const isFormValid = this.form.valid && (this.form.value.payment_card_selection === 'add_new' ? this.cardFormIsValid : true);

    return !this.isLoading && isFormValid;
  }

  get isCancelable(): boolean {
    return !this.isLoading && this.form.dirty;
  }

  onFormCancel() {
    this.resetForm();

    this.onCancel$.emit();
  }

  onFormSubmit() {
    if (this.isCreditCardFormVisible) {
      this.requestCreditCardToken();
    } else {
      this.emitPaymentData(this.form.value);
    }
  }

  resetForm() {
    this.form.reset({
      payment_type: this.isManualAllowed ? null : 'credit_card'
    });

    this.changeDetectorRef.markForCheck();
  }

  private emitPaymentData(paymentData: PaymentData) {
    this.onAddPayment$.emit(paymentData);
  }

  private requestCreditCardToken() {
    this.isLoading = true;
    this.createToken$.next();
  }

  onChangeCardFormIsValid(isValid: boolean) {
    this.cardFormIsValid = isValid;
  }
}

function paymentFormValidator(group: AbstractControl): {[key: string]: boolean} {
  const paymentTypeVal = group.get('payment_type').value,
        paymentCardSelectionVal = group.get('payment_card_selection').value;

  if (paymentTypeVal === 'credit_card' && paymentCardSelectionVal == null) {
    return {payment_card_selection_required: true};
  }

  return null;
}
