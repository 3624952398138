import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs/Observable';

import { RoleRouteGuard, CombinedAuthState } from './role-route-guard.service';

@Injectable()
export class WholesalerCustomerRouteGuard extends RoleRouteGuard {
  protected onCanActivateRequest(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.comboAuth$
      .filter((comboAuthState: CombinedAuthState) => comboAuthState.isAuthenticationComplete)
      .switchMap((comboAuthState: CombinedAuthState) => {
        let isAllowed = false;
        const wholesalerOrg = comboAuthState.currentUser == null ? null : comboAuthState.currentUser.wholesaler_org;

        if (wholesalerOrg != null) {
          isAllowed = !wholesalerOrg.archived;
        }

        if (!isAllowed) {
          let wholesalerRedirectUrl = '/store/account';

          if (route.data.wholesalerRedirectUrl) {
            wholesalerRedirectUrl = route.data.wholesalerRedirectUrl;
          }

          this.router.navigate([wholesalerRedirectUrl]);
        }

        return Observable.of(isAllowed);
      })
      .first();
  }
}
