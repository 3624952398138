import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';

import { AppState } from 'app/store/app-state';
import { BakeryOrder } from 'app/store/bakery-order';
import { OrderItem } from 'app/store/order-item';

@Component({
  selector: 'bakery-mgmt-new-order-checkout-review-order',
  template: `
    <bakery-mgmt-new-order-order-list-item
      [checkout]="checkout"
      [changeToCheckout]="changeToCheckout"
      [orderItems]="orderItems"
      [employee]="true"
    ></bakery-mgmt-new-order-order-list-item>
  `
})
export class BakeryMgmtNewOrderCheckoutReviewOrderComponent {
  @Input() orderItems: OrderItem[];
  @Input() changeToCheckout: Function;
  @Input() checkout: boolean;
  constructor(
    private store: Store<AppState>,
  ) {
  }
}
