import { combineReducers } from '@ngrx/store';
import * as detail from './detail.reducer';
import * as master from './master.reducer';

export interface State {
  detail: detail.State;
  master: master.State;
}

export const initialState: State = {
  detail: detail.initialState,
  master: master.initialState,
};

export const reducer = combineReducers({
  detail: detail.reducer,
  master: master.reducer,
});
