import { Component, OnInit, OnDestroy, Input, Output } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Angular2TokenService } from 'app/angular2-token/angular2-token.service';
import * as Rx from 'rxjs';

import { passwordMatchValidatorBuilder } from 'app/shared/forms/password-match-validator';
import { Bakery } from 'app/store/bakery';
import { validateNoWhitespaces } from 'app/shared/forms/no-whitespaces-validator';

export interface UiState {
  isLoading: boolean;
  isBakesmart?: boolean;
  isBakery?: boolean;
  bakery?: Bakery;
}

@Component({
  selector: 'shared-account-confirmation',
  template: require('./account-confirmation.component.html'),
})
export class SharedAccountConfirmationComponent implements OnInit, OnDestroy {
  @Input('signInLink') signInLink: string;
  @Input('uiState') uiState$: Rx.Observable<UiState>;
  token = '';
  form: FormGroup;
  isLoading = false;

  constructor(
    private tokenService: Angular2TokenService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.token = this.route.snapshot.queryParams['token'];

    this.form = new FormGroup({
      'password': new FormControl(null, [Validators.required, Validators.minLength(8), validateNoWhitespaces]),
      'confirmation': new FormControl(null, [Validators.required, Validators.minLength(8), validateNoWhitespaces]),
    }, passwordMatchValidatorBuilder('password', 'confirmation'));
  }

  ngOnDestroy() { }

  get disabled(): boolean {
    return !this.form.valid || this.isLoading;
  }

  onSubmit() {
    if (this.isLoading) return;

    this.isLoading = true;

    const password: string = this.form.value.password,
          confirmation: string = this.form.value.confirmation;

    const update$ = this.tokenService.put(
      `/api/auth/confirmation`,
      JSON.stringify({
        password: password,
        token: this.token,
      })
    );

    update$.subscribe(
        res => {
          this.router.navigate([this.signInLink]);
        },
        error => {
          this.isLoading = false;

          console.log(error)
        }
    );
  }
}
