import { Component } from '@angular/core';

@Component({
  selector: 'card-list-tooltip-container',
  template: `
    <div class="tooltip__item tooltip__item--block">
      <ng-content></ng-content>
    </div>
  `,
})
export class TooltipContainerComponent { }
