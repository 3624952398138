import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

import { BakeryOrderInvoice } from 'app/store/bakery-order-invoice';

@Component({
  selector: 'bkry-mgmt-wholesaler-invoices-card',
  template: require('./wholesaler-invoices-card.component.html'),
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WholesalerInvoicesCardComponent {
  @Input() isLoading = true;
  @Input() showCreateLink = false;
  @Input() invoices: BakeryOrderInvoice[] = [];
  @Input() detailsLink: string;
}
