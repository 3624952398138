import { Component, OnInit, OnDestroy, Input, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { Subject } from 'rxjs/Subject';

import { BakeryProduct } from 'app/store/bakery-product';
import { BakeryMgmtProductsEffects } from 'app/store/effects/bakery-mgmt/bakery-mgmt-products.effects';
import { DetailUiState } from 'app/bakery-mgmt/product-mgmt/products/detail-ui-state';

@Component({
  selector: 'product-detail-forms-edit-archive-button',
  template: `
    <button *ngIf="(isButtonVisible$|async)" (click)="click$.next()" class="button--primary button--input button--full margin-bottom--delta">
      <ng-template [ngIf]="!(isArchived$|async)">Archive</ng-template>
      <ng-template [ngIf]="(isArchived$|async)">Unarchive</ng-template>
    </button>
  `
})
export class ProductDetailFormsEditArchiveButtonComponent implements OnInit, OnDestroy {
  @Input('uiState$') uiState$: Observable<DetailUiState>;

  safeProduct$: Observable<BakeryProduct>;
  isButtonVisible$: Observable<boolean>;
  isArchived$: Observable<boolean>;

  click$ = new Subject<any>();
  clickSub: Subscription;

  constructor(
    private bakeryMgmtProductsEffects: BakeryMgmtProductsEffects,
  ) {
  }

  ngOnInit() {
    this.safeProduct$ = this.uiState$
      .filter((uiState: DetailUiState) => !uiState.isLoading)
      .map((uiState: DetailUiState) => uiState.bakeryProduct);

    this.isButtonVisible$ = this.safeProduct$
      .map((bakeryProduct: BakeryProduct) => !!bakeryProduct.id)
      .startWith(false);

    this.isArchived$ = this.safeProduct$
      .map((bakeryProduct: BakeryProduct) => !!bakeryProduct.archived)
      .startWith(false);

    this.clickSub = this.click$
      .switchMap(() => this.safeProduct$.take(1))
      .subscribe((bakeryProduct: BakeryProduct) => {
        this.bakeryMgmtProductsEffects.requestUpdateBakeryProduct(
          bakeryProduct,
          {
            archived: !bakeryProduct.archived,
          }
        );
      });
  }

  ngOnDestroy() {
    this.clickSub.unsubscribe();
  }
}
