import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs/Observable';

import { User } from 'app/store/user';
import { Role } from 'app/store/role';
import { validateNoWhitespaces } from 'app/shared/forms/no-whitespaces-validator';

export interface UserDetailsFormValues {
  first_name: string;
  last_name: string;
  email: string;
  role: string;
}

@Component({
  selector: 'bakery-mgmt-edit-employee-details',
  template: `
    <form
      [formGroup]="form"
      (ngSubmit)="onSubmitForm()"
    >
      <div gridRow>
        <div gridCol s="4" m="9" l="4">
          <label>First Name</label>
          <input
            type="text"
            placeholder="John"
            formControlName="first_name"
          />
        </div>
        <div gridCol s="4" m="9" l="4">
          <label>Last Name</label>
          <input
            type="text"
            placeholder="Smith"
            formControlName="last_name"
          />
        </div>
        <div gridCol s="4" m="9" l="4">
          <label>Email Address</label>
          <input
            type="email"
            placeholder="user@OrderNova.com"
            formControlName="email"
          />
        </div>
      </div>
      <div gridRow>
        <div gridCol s="4" m="9" l="12">
          <label>Employee's Role</label>
          <select
            *ngIf="!isUserBakeryOwner"
            formControlName="role">
            <option disabled value="">Select One</option>
            <option value="bakery_manager">Bakery Manager</option>
            <option value="bakery_employee">Bakery Employee</option>
          </select>
          <select
            *ngIf="isUserBakeryOwner"
            disabled>
            <option value="bakery_owner" selected>Bakery Owner</option>
          </select>
        </div>
      </div>
      <div gridRow>
        <div gridCol s="4" m="9" l="12" class="margin-bottom--delta float--right">
          <a (click)="onClickResetForm()" class="link--secondary margin-right--delta" [ngClass]="{'link--disabled': !form.dirty, 'clickable': form.dirty }">
            Cancel
          </a>
          <button type="submit" class="button--primary button--input label--offset" [ngClass]="{'button--disabled': !form.valid || !form.dirty}">
            Update Employee
          </button>
        </div>
      </div>
    </form>
  `
})
export class BakeryMgmtEditEmployeeDetailsComponent implements OnInit {
  @Input('user$') user$: Observable<User>;
  @Input('success$') success$: Observable<any>;
  @Output('submitForm') submitForm = new EventEmitter<UserDetailsFormValues>();
  form: FormGroup;
  private latestUser: User;

  ngOnInit() {
    this.form = new FormGroup({
      'first_name': new FormControl(null, [Validators.required, validateNoWhitespaces]),
      'last_name': new FormControl(null, [Validators.required, validateNoWhitespaces]),
      'email': new FormControl(null, [Validators.required, Validators.email]),
      'role': new FormControl('', [Validators.required]),
    });

    this.user$
      .forEach((user: User) => {
        this.latestUser = user;

        this.form.setValue({
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
          role: user.primary_role.name,
        });
      });

    this.success$
      .subscribe(
        val => this.resetUserDetailsForm()
      )
  }

  get isUserBakeryOwner(): boolean {
    const firstRole: Role = this.latestUser.roles[0] as Role;

    return firstRole.name === 'bakery_owner';
  }

  onClickResetForm() {
    this.resetUserDetailsForm();
  }

  onSubmitForm() {
    this.submitForm.next({
      first_name: this.form.value.first_name,
      last_name: this.form.value.last_name,
      email: this.form.value.email,
      role: this.form.value.role,
    });
  }

  private resetUserDetailsForm() {
    this.form.reset({
      first_name: this.latestUser.first_name,
      last_name: this.latestUser.last_name,
      email: this.latestUser.email,
      role: this.latestUser.primary_role.name,
    });
  }
}
