import { Component, OnInit, Input, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { Store } from '@ngrx/store';

import { BakeryProduct } from 'app/store/bakery-product';
import { AppState } from 'app/store/app-state';
import { AuthState } from 'app/store/auth-state';

@Component({
  selector: 'bakery-mgmt-prod-mgmt-products-master-list-item',
  template: require('./list-item.component.html'),
})
export class MasterListItemComponent implements OnInit {
  @Input('bakeryProduct') bakeryProduct: BakeryProduct;
  @Output('showDetails') showDetails$ = new Subject<BakeryProduct>();
  @Output('changeProductArchived') changeProductArchived$ = new Subject<BakeryProduct>();

  constructor(
    private router: Router,
    private store: Store<AppState>,
  ) { }

  ngOnInit() {
  }

  onClickDetails() {
    this.showDetails$.next(this.bakeryProduct);
  }

  onClickArchive($event: Event) {
    this.changeProductArchived$.next(this.bakeryProduct);
  }
}
