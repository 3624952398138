/*
 TODO: Not entirely sure how the final invoice list/table presentation is
  going to look. Currently, invoices-table is broken out of
  wholesaler-invoices-card in order to support reuse of the table outside of
  a card presentation. If that's never needed, this should be reconsolidated
  back into WholesalerInvoicesCard component.
*/

import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

import { BakeryOrderInvoice } from 'app/store/bakery-order-invoice';

@Component({
  selector: 'invoices-table',
  template: require('./invoices-table.component.html'),
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvoicesTableComponent {
  @Input() isLoading = true;
  @Input() invoices: BakeryOrderInvoice[] = [];
  @Input() detailsLink: string;

  formatOrdersAmount(invoice): string {
    if (invoice.bakery_orders.length === 0) { return 'N/A'; }
    return `${invoice.bakery_orders.length} ${invoice.bakery_orders.length > 1 ? 'Orders' : 'Order' }`
  }
}
