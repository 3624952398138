import { Component, OnInit, OnDestroy, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { Subject } from 'rxjs/Subject';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Actions as NgRxActions } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { AppState } from 'app/store/app-state';
import { AlertService } from 'app/shared/components/alerts/alert.service';
import { Actions } from 'app/store/actions';
import { BakeryMgmtProductsEffects } from 'app/store/effects/bakery-mgmt/bakery-mgmt-products.effects';
import { BakeryProduct } from 'app/store/bakery-product';
import { BakeryMgmtUiState } from 'app/store/bakery-mgmt-ui-state';
import { EntitiesState } from 'app/store/entities-state';
import { BakeryMgmtProductTemplatesEffects } from 'app/store/effects/bakery-mgmt/bakery-mgmt-bakery-product-templates.effects';
import { BakeryMgmtProductAttributesEffects } from 'app/store/effects/bakery-mgmt/bakery-mgmt-bakery-product-attributes.effects';
import { BakeryProductTemplate } from 'app/store/bakery-product-template';
import { BakeryAttribute } from 'app/store/bakery-attribute';
import { ProductCategory } from 'app/store/product-category';
import { denormalize } from 'normalizr';
import { bakerySubscriptionStatusSchema } from 'app/store/schema/default-schemata';

export interface UiState {
  isLoading: boolean;
  bakeryProduct?: BakeryProduct;
  bakeryProductTemplates?: BakeryProductTemplate[],
  bakeryAttributes?: BakeryAttribute[],
  productCategories?: ProductCategory[],
}

@Component({
  template: require('./add.component.html'),
})
export class BakeryMgmtProductProductAddComponent implements OnInit, OnDestroy {
  uiState$: Observable<UiState>;
  detailsForm: FormGroup;
  updateSuccess$: Observable<any>;
  bakeryProduct$: Observable<BakeryProduct>;
  submitForm$ = new Subject<any>();
  advanced$ = new BehaviorSubject<any>(undefined);
  alertsSub: Subscription;
  createSuccessSub: Subscription;
  submitSub: Subscription;

  constructor(
    private bakeryMgmtProductsEffects: BakeryMgmtProductsEffects,
    private bakeryMgmtProductTemplatesEffects: BakeryMgmtProductTemplatesEffects,
    private bakeryMgmtProductAttributesEffects: BakeryMgmtProductAttributesEffects,
    private router: Router,
    private actions$: NgRxActions,
    private store: Store<AppState>,
    private alertService: AlertService,
  ) {
    this.uiState$ = Observable
      .combineLatest(
        this.store.select('bakeryMgmtUiState'),
        this.store.select('entitiesState'),
        (uiState, entities, advanced) => ({uiState, entities})
      )
      .map((combined) => {
        const bakeryMgmtUiState = <BakeryMgmtUiState> combined.uiState;
        const entitiesState = <EntitiesState> combined.entities;

        if (bakeryMgmtUiState.productsUiState.isDetailLoading) {
          return {
            isLoading: true,
          };
        }

        const advanced_products = entitiesState.bakeries[bakeryMgmtUiState.bakeryId].advanced_products;
        if (advanced_products === false) {
          this.advanced$.next(false)
        }

        const bakeryProduct = {
          id: null,
          name: '',
          description: '',
          archived: false,
          main_photo: null,
          store_type: 'retail_wholesale',
          store_sort_priority: 5,
          product_categories: [],
          advanced: false,
          fulfillment_option_delivery: false,
          fulfillment_option_pickup: false,
          fulfillment_option_ship: false,
          available_range_specific: false,
          available_range_start_day: null,
          available_range_start_month: null,
          available_range_end_day: null,
          available_range_end_month: null,
          available_day_specific: false,
          available_days: [],
          bakery_product_product_categories: [],
          bakery_product_product_templates: [],
          bakery_product_attributes: [],
          bakery_product_templates: [],
          store_featured: false,
          allow_inscription: false,
          taxable: true,
          sample: false,
          sold_out: false,
        };

        const bakeryProductTemplates = Object.keys(
          entitiesState.bakery_product_templates
        ).map(key =>
          ({...entitiesState.bakery_product_templates[key]})
        );

        const bakeryAttributes = Object.keys(
          entitiesState.bakery_attributes
        ).map(key => Object.assign({}, entitiesState.bakery_attributes[key]));

        const productCategories = Object.keys(entitiesState.product_categories)
          .map(key => Object.assign({}, entitiesState.product_categories[key]));

        return {
          isLoading: false,
          bakeryProduct: bakeryProduct,
          bakeryProductTemplates: bakeryProductTemplates,
          bakeryAttributes: bakeryAttributes,
          productCategories: productCategories,
        };
      })
      .shareReplay(1);
  }

  ngOnInit() {
    this.bakeryMgmtProductsEffects.requestGetBakeryProduct();
    this.bakeryMgmtProductAttributesEffects.requestGetBakeryAttributes();
    this.bakeryMgmtProductTemplatesEffects.requestGetBakeryProductTemplates();

    this.submitSub = this.submitForm$
      .withLatestFrom(
        this.uiState$,
        (formValue, uiState) => ({formValue, uiState})
      )
      .subscribe(({formValue, uiState}) => {
        this.bakeryMgmtProductsEffects.requestCreateBakeryProduct(
          {
            ...formValue,
            advanced: this.advanced$.value
          }
        );
      });

    this.createSuccessSub = this.actions$
      .filter((action: UnsafeAction) => action.type === Actions.REQUEST_CREATE_BAKERY_PRODUCT_SUCCESS)
      .subscribe((action: UnsafeAction) => {
        const newBkryProdId = Object.keys(action.payload.bakery_products).shift();

        this.router.navigate(['/bakery_mgmt/product_mgmt/products', newBkryProdId]);
      })

    this.alertsSub = this.actions$
      .subscribe((action: UnsafeAction) => {
        let messageType = null,
            messageContent = null;

        switch (action.type) {
          case Actions.REQUEST_CREATE_BAKERY_PRODUCT_SUCCESS:
            messageType = 'success';
            messageContent = `The product has been created.`;
            break;

          case Actions.REQUEST_CREATE_BAKERY_PRODUCT_ERROR:
            messageType = 'warning';
            messageContent = 'Something went wrong!';
            break;
        }

        if (messageType != null && messageContent != null) {
          this.alertService[messageType](messageContent);
        }
      });
  }

  ngOnDestroy() {
    this.alertsSub.unsubscribe();
    this.createSuccessSub.unsubscribe();
    this.submitSub.unsubscribe();
  }

  onSubmitForm(formValue: any) {
    this.submitForm$.next(formValue);
  }

  onResetForm() {
    this.router.navigate(['/bakery_mgmt/product_mgmt/main/active_products']);
  }
}
