interface NotAsked { status: 'notAsked'; }
interface Loading { status: 'loading'; }
interface Error { status: 'error'; message: string; }
interface Success<T> { status: 'success'; value: T; }

export const NOT_ASKED: NotAsked = { status: 'notAsked' };
export const LOADING: Loading = { status: 'loading' };
export const fromError = (message): Error => ({ status: 'error', message });
export const fromSuccess = <T>(value: T): Success<T> => ({ status: 'success', value });

export type RemoteData<T> = NotAsked | Loading | Error | Success<T>;

export const map = <T, U>(f: (_: T) => U, remoteData: RemoteData<T>): RemoteData<U> => {
  switch (remoteData.status) {
    case 'success': return fromSuccess(f(remoteData.value));
    default: return remoteData;
  }
};

export const withDefault = <T>(defaultValue: T, remoteData: RemoteData<T>): T => {
  switch (remoteData.status) {
    case 'success': return remoteData.value;
    default: return defaultValue;
  }
}
