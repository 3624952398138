import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ChangeDetectionStrategy
} from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { AppState } from 'app/store/app-state';
import * as selectors from 'app/store/selectors';
import { BakeryCustEffects } from 'app/store/effects/bakery-cust/bakery-cust.effects';
import { UsersEffects } from 'app/store/effects/users.effects';
import { Actions } from 'app/store/actions';
import { Bakery } from 'app/store/bakery';
import { MasterState } from 'app/store/reducers/master-state-reducer';
import { BakeryOrder } from 'app/store/bakery-order';
import { OrderHistoryDetailState } from 'app/store/reducers/bakery-cust-ui-state/order-history-state.reducer';
import { isCustomerCancelable } from 'app/bakery-cust/order-history/detail/bakery-order-projections';
import { bakeryOrderSchema } from 'app/store/schema/default-schemata';
import { GetAction } from 'app/store/actions/request.actions';

interface ExtendedBakeryOrder extends BakeryOrder {
  isFulfillPickup: boolean;
  isFulfillShippingDelivery: boolean;
  isCancelable: boolean;
}

function mapForView(bakeryOrder: BakeryOrder): ExtendedBakeryOrder {
  return {
    ...bakeryOrder,
    isFulfillShippingDelivery: (
      bakeryOrder.fulfillment_type === 'shipping' ||
      bakeryOrder.fulfillment_type === 'delivery'
    ),
    isFulfillPickup: bakeryOrder.fulfillment_type === 'pickup',
    isCancelable: isCustomerCancelable(bakeryOrder),
  };
}

const opKey = 'bakeryOrderDetail',
      schema = bakeryOrderSchema;

@Component({
  selector: 'bkry-cust-order-detail',
  template: `
    <div class="container">
      <alert-container></alert-container>
      <bakery-cust-page-header
        class="print--hide"
        title="Order #{{ (bakeryOrder$|async)?.order_number }}"
        [backLink]="backLink"
        [backLinkText]="backLinkText"
      ></bakery-cust-page-header>
      <ng-template [ngIf]="(isLoading$|async)">
        <loading-indicator></loading-indicator>
      </ng-template>
      <ng-template [ngIf]="!(isLoading$|async)">
        <bkry-cust-order-detail-prez
          [bakeryOrder]="bakeryOrder$ | async"
          [bakery]="bakery$ | async"
        ></bkry-cust-order-detail-prez>
      </ng-template>
    </div>
  `,
})
export class BakeryCustOrderDetailComponent {
  @Input()
  set orderId(orderId: number) {
    const actionPayload = {
      url: `/api/me/orders/${orderId}`,
      opKey: opKey,
      schema: schema
    };
    this.store.dispatch(new GetAction(actionPayload));
  }
  @Input() backLink: string;
  @Input() backLinkText: string;

  bakery$: Observable<Bakery> = this.store
    .select(selectors.getCurrentCustBakery);

  isLoading$: Observable<boolean> = this.store
    .select(selectors.getRequestIsLoading(opKey));

  bakeryOrder$: Observable<BakeryOrder> = this.store
    .select(selectors.getRequestResult(opKey, schema))
    .filter(val => !!val)
    .map(mapForView);

  constructor(
    private store: Store<AppState>,
  ) { }
}

@Component({
  selector: 'bkry-cust-order-detail-prez',
  template: require('./bkry-cust-order-detail.component.html'),
})
export class BakeryCustOrderDetailPrezComponent {
  @Input() bakeryOrder: any;
  @Input() bakery: any;

  getAttributes(item) {
    const attributes = item.meta.template_variants.map(variant => (
      `${variant.bakery_product_attribute.visible_name}: <strong>${variant.bakery_attribute_variant.name}</strong><br>`
    )).concat(item.meta.attribute_variants.map(variant => (
      `${variant.bakery_product_attribute.visible_name}: <strong>${variant.bakery_attribute_variant.name}</strong><br>`
    )));

    return attributes.join('');
  }
}
