import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { Actions } from 'app/store/actions';
import { BakeryOrder } from 'app/store/bakery-order';
import { NormalizedResourceRequestState } from 'app/store/reducers/request-states';

export interface State extends NormalizedResourceRequestState<BakeryOrder[], number[]> { }

export const initialState: State = {
  state: null,
  result: [],
}

export function reducer(
  state: State = initialState,
  action: UnsafeAction
): State {
  switch (action.type) {
    case Actions.REQUEST_GET_BKRY_MGMT_STORE_PAYMENT_CONFIG:
      return {
        ...state,
        state: 'loading',
        result: null,
      };

    case Actions.REQUEST_GET_BKRY_MGMT_STORE_PAYMENT_CONFIG_SUCCESS:
      return {
        ...state,
        state: 'success',
        result: action.payload,
      };

    default:
      return state;
  }
}
