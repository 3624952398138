import {
  Component,
  OnInit,
  OnDestroy,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { Subject } from 'rxjs/Subject';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { BakeryProductTemplate } from 'app/store/bakery-product-template';
import { BakeryProduct } from 'app/store/bakery-product';
import { DetailUiState } from 'app/bakery-mgmt/product-mgmt/products/detail-ui-state';
import { SimpleFormState } from 'app/shared/forms/form-states';
import uuidv4 from 'uuid/v4';
import { validatePositiveInteger } from 'app/shared/forms/positive-integer-validator';
import { validateNoWhitespaces } from 'app/shared/forms/no-whitespaces-validator';

const DEFAULT_FORM_VALUES = {
  name: '',
  description: '',
  template_type: '',
  unit_price: null,
  unit_quantity: 1,
  order_limit: 0,
};

@Component({
  selector: 'product-detail-forms-edit-templates',
  template: require('./edit-templates.component.html')
})
export class ProductDetailFormsEditTemplatesComponent
  implements OnInit, OnDestroy {
  @Input('uiState$') uiState$: Observable<DetailUiState>;
  @Input('success$') success$: Observable<any>;
  @Input('reset$') reset$: Observable<any>;
  @Output('formState') formState$ = new BehaviorSubject<SimpleFormState>({isDirty: false, isValid: false, value: null});

  form: FormGroup;
  selectedAssociations$ = new BehaviorSubject<BakeryProductTemplate[]>([]);

  public formSubmit$ = new Subject<any>();
  private filteredProdAtt$: Observable<BakeryProductTemplate>;
  private formStateSub: Subscription;
  private formSubmitSub: Subscription;
  private selectedAssociationsSub: Subscription;

  constructor() {
    this.form = new FormGroup({
      name: new FormControl(null, [Validators.required, validateNoWhitespaces]),
      description: new FormControl(null, [Validators.required, validateNoWhitespaces]),
      template_type: new FormControl(null, [Validators.required]),
      unit_price: new FormControl(null, [Validators.required]),
      unit_quantity: new FormControl(null, [
        Validators.required,
        Validators.pattern('[0-9]{1,3}'),
        validatePositiveInteger(1)
      ]),
      order_limit: new FormControl(null, [
        Validators.required,
        Validators.pattern('[0-9]{1,3}'),
        validatePositiveInteger(0)
      ])
    });
    this.form.reset(DEFAULT_FORM_VALUES);
  }

  ngOnInit() {
    this.selectedAssociationsSub = Observable.merge(
      this.uiState$,
      this.reset$.switchMap(() => this.uiState$.take(1))
    )
      .filter((uiState: DetailUiState) => !uiState.isLoading)
      .subscribe((uiState: DetailUiState) => {
        this.selectedAssociations$.next(<BakeryProductTemplate[]>uiState
          .bakeryProduct.bakery_product_templates);
      });

    this.formSubmitSub = this.formSubmit$
      .withLatestFrom(
        this.form.valueChanges,
        this.uiState$.filter((uiState: DetailUiState) => !uiState.isLoading),
        (_, formValue, uiState) => ({ _, formValue, uiState })
      )
      .subscribe(({ formValue, uiState }) => {
        this.form.reset(DEFAULT_FORM_VALUES);
        let bakeryProductTemplate = {
          temp_id: uuidv4(),
          ...formValue
        };

        bakeryProductTemplate = this.setUnitQuantity(bakeryProductTemplate);

        const associations = <BakeryProductTemplate[]>[
          ...this.selectedAssociations$.value,
          bakeryProductTemplate
        ];
        this.selectedAssociations$.next(associations);
      });

    this.formStateSub = Observable.combineLatest(
      this.selectedAssociations$,
      this.uiState$.filter((uiState: DetailUiState) => !uiState.isLoading),
      (selectedAssociations, uiState) => ({ selectedAssociations, uiState })
    )
      .map(({ selectedAssociations, uiState }) => {
        const existingAssociations = <BakeryProductTemplate[]>uiState
          .bakeryProduct.bakery_product_templates;

        const newAssociations = selectedAssociations.filter(selectedAssoc => {
          const match = existingAssociations.find(existingAssoc => {
            return (
              (<BakeryProductTemplate>existingAssoc).id === (<BakeryProductTemplate>selectedAssoc).id
            );
          });

          return match == null;
        });

        const removedAssociations = existingAssociations.filter(
          existingAssoc => {
            const match = selectedAssociations.find(selectedAssoc => {
              return (
                (<BakeryProductTemplate>existingAssoc).id === (<BakeryProductTemplate>selectedAssoc).id
              );
            });

            return match == null;
          }
        );

        const allCurrentAssocations = selectedAssociations.filter(assoc => !removedAssociations.includes(assoc))

        return { allCurrentAssocations, newAssociations, removedAssociations };
      })
      .map(({ allCurrentAssocations, newAssociations, removedAssociations }) => {
        const preppedRemovedAssocs = removedAssociations.map(assoc => {
          return Object.assign({}, assoc, { _destroy: true });
        });

        const attributesValueArray = [
          ...newAssociations,
          ...preppedRemovedAssocs
        ];

        return {
          isValid: allCurrentAssocations.length > 0,
          isDirty: !!newAssociations.length || !!removedAssociations.length,
          value: {
            bakery_product_templates_attributes: attributesValueArray
          }
        };
      })
      .subscribe(val => this.formState$.next(val));
  }

  onClickRemoveAssociation(bakeryProductTemplateId: number, bakeryProductTemplateTempId: number) {
    const filterId = bakeryProductTemplateTempId || bakeryProductTemplateId;

    const selectedAssociations = this.selectedAssociations$.value;
    this.selectedAssociations$.next(
      selectedAssociations.filter(bakeryProductTemplate => {
        return !(
          bakeryProductTemplate.id === filterId ||
          (<any>bakeryProductTemplate).temp_id === filterId
        );
      })
    );
  }

  isNotQuantityTemplateType() {
    return this.form.controls.template_type.value !== 'quantity'
  }

  setUnitQuantity(bakeryProductTemplate) {
    if (bakeryProductTemplate.template_type === 'quantity') {
      return bakeryProductTemplate;
    } else {
      return Object.assign(bakeryProductTemplate, { unit_quantity: 1 });
    }
  }

  onClickFormSubmit() {
    this.formSubmit$.next();
  }

  ngOnDestroy() {
    this.formStateSub.unsubscribe();
    this.formSubmitSub.unsubscribe();
    this.selectedAssociationsSub.unsubscribe();
  }
}
