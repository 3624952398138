export function zeroPad(num: number, width: number = 5, fallback: number = 0) {
  if (num == null && fallback != null) {
    num = fallback;
  }

  let asStr = num + '';

  while (asStr.length < width) {
    asStr = '0' + asStr;
  }

  return asStr;
}

export function compareStrings(strA: string, strB: string): number {
  if (strA > strB) return 1;
  if (strA < strB) return -1;
  return 0;
}
