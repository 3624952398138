import { Component, OnInit, OnDestroy, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as Rx from 'rxjs';

import { User } from 'app/store/user';
import { PaymentCard } from 'app/store/payment-card';
import { UsersEffects } from 'app/store/effects/users.effects';

@Component({
  selector: 'bakery-mgmt-customer-credit-card',
  template: `
    <user-credit-card
      [paymentCards]="paymentCards$ | async"
      [reset$]="reset$"
      (addCard$)="addCard$.next($event)"
      (removeCard$)="removeCard$.next($event)"
    ></user-credit-card>
  `,
})
export class BakeryMgmtCustomerCreditCardComponent implements OnInit, OnDestroy {
  @Input('user$') user$: Rx.Observable<User>;

  // TODO: This formReset is never used.
  formReset$ = new Rx.Subject<any>();
  paymentCards$: Rx.Observable<PaymentCard[]>;

  reset$ = new Rx.Subject<any>();
  addCard$ = new Rx.Subject<PaymentCard>();
  removeCard$ = new Rx.Subject<PaymentCard>();

  constructor(
    private usersEffects: UsersEffects,
  ) {
  }

  ngOnInit() {
    this.paymentCards$ = this.user$
      .map((user: User): PaymentCard[] => user.payment_cards);

    Rx.Observable
      .merge(
        this.removeCard$,
        this.addCard$,
      )
      .withLatestFrom(
        this.user$,
        (card, user) => ({card, user})
      )
      .subscribe(({card, user}) => {
        this.usersEffects.requestUserDetailsUpdate({
          id: user.id,
          payment_cards_attributes: [card],
        })
      });
  }

  ngOnDestroy() {

  }
}
