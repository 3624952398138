import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { PopoutService } from 'app/shared/components/popouts/popout.service';

@Component({
  selector: 'popout-items-list',
  template: `
    <figure class="popout" [ngClass]="{'is-active': (popoutActive$|async), 'popout--inverse': popoutInverse}">
      <ul class="popout__list">
        <ng-content></ng-content>
      </ul>
    </figure>
  `,
})
export class PopoutItemsListComponent {
  @Input('popoutInverse') popoutInverse: boolean;

  constructor(
    private popoutService: PopoutService,
  ) { }

  get popoutActive$(): Observable<boolean> {
    return this.popoutService.popoutActive$;
  }
}
