import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class HeaderStateService {
  private isExpanded$ = new BehaviorSubject<boolean>(false);

  updateIsExpanded(isExpanded: boolean) {
    this.isExpanded$.next(isExpanded);
  }

  get isExpanded(): Observable<boolean> {
    return this.isExpanded$.asObservable();
  }
}
