
export function shallowPruneEmpty(params) {
  const cloneParams = {...params};

  Object.keys(cloneParams)
    .forEach(key => {
      if (cloneParams[key] == null) {
        delete cloneParams[key];
      }
    });

  return cloneParams;
}

export function nullIfEmpty(params) {
  const cloneParams = {...params};

  Object.keys(cloneParams)
    .forEach(key => {
      let value = cloneParams[key];

      if (!!value && typeof value.trim === 'function') {
        value = value.trim();

        if (value.length === 0) {
          value = null;
        }
      }

      cloneParams[key] = value;
    })

  return cloneParams;
}
