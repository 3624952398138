import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as Rx from 'rxjs';

@Component({
  selector: 'bakery-mgmt-dashboard-order-list-item',
  template: require('./order-list-item.component.html'),
})
export class OrderListItemComponent {
  @Input() bakeryOrder: any;

  constructor(
    private router: Router,
  ) { }

  onClickDetails() {
    this.router.navigate(['/bakery_mgmt/order_mgmt/orders/', this.bakeryOrder.id]);
  }
}
