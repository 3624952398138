import {
  Component,
  Input,
  Output,
  OnInit,
  ChangeDetectionStrategy
} from '@angular/core';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import * as Rx from 'rxjs';

import { AppState } from 'app/store/app-state';
import { Actions } from 'app/store/actions';
import * as selectors from 'app/store/selectors';

const compSelector = 'bkry-mgmt-wholesaler-list-prez';

@Component({
  template: `
    <${compSelector}
      [wholesalerOrgs]="wholesalerOrgs$ | async"
      [isLoading]="isLoading$ | async"
    ></${compSelector}>
  `,
})
export class WholesalerListComponent implements OnInit {
  wholesalerOrgs$ = this.store
    .select(selectors.bkryMgmtWholesaler.getBkryMgmtWholesalerMasterResults);
  isLoading$ = this.store
    .select(selectors.bkryMgmtWholesaler.getMasterIsLoading);

  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    const routeData = this.route.snapshot.data as {archived: boolean};
    this.store.dispatch({
      type: Actions.CHANGE_BKRY_MGMT_WHOLESALERS_ORDERS_FILTERS,
      payload: {
        // TODO: Use real data.
        archived: routeData.archived,
      },
    });
  }
}

@Component({
  selector: compSelector,
  template: require('./wholesaler-list.component.html'),
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WholesalerListPrezComponent {
  @Input() wholesalerOrgs: any[];
  @Input() isLoading: boolean;

  get hasResults(): boolean {
    return !!this.wholesalerOrgs && this.wholesalerOrgs.length > 0;
  }
}
