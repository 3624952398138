import { Component, Output, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import * as moment from 'moment/moment';
import { Moment } from 'moment/moment';

import { dateValidator, searchDateRangeValidatorBuilder } from 'app/shared/forms/date-validators';
import { OrderItemsDateRangeService } from 'app/shared/services/order-items-date-range.service';

export interface DateRange {
  searchStartDate: Moment;
  searchEndDate: Moment;
}

@Component({
  selector: 'date-range-search-form-2',
  providers: [OrderItemsDateRangeService],
  template: `
    <form
      [formGroup]="form"
      class="center__parent"
    >
      <mat-form-field>
        <input
          matInput
          [matDatepicker]="searchStartDate"
          formControlName="searchStartDate"
          class="input__date margin-reset--bottom"
        >
          <mat-datepicker-toggle
            matSuffix
            [for]="searchStartDate"
          ></mat-datepicker-toggle>
        <mat-datepicker #searchStartDate></mat-datepicker>
      </mat-form-field>
      <label class="padding-left--delta padding-right--delta margin-top--echo text--grey-dark text--lowercase">to</label>
      <mat-form-field>
        <input
          matInput
          [matDatepicker]="searchEndDate"
          formControlName="searchEndDate"
          class="input__date margin-reset--bottom"
        >
        <mat-datepicker-toggle
          matSuffix
          [for]="searchEndDate"
        ></mat-datepicker-toggle>
        <mat-datepicker #searchEndDate></mat-datepicker>
      </mat-form-field>
    </form>
  `
})
export class DateRangeSearchForm2Component {
  @Output() onChangeSearchOptions$: Observable<DateRange>;

  form: FormGroup;

  constructor(
    private dateRangeService: OrderItemsDateRangeService
  ) {
    this.form = new FormGroup(
      {
        searchStartDate: new FormControl(this.dateRangeService.defaultStartDate.toDate(), [dateValidator]),
        searchEndDate: new FormControl(this.dateRangeService.defaultEndDate.toDate(), [dateValidator]),
      },
      searchDateRangeValidatorBuilder('searchStartDate', 'searchEndDate')
    );

    this.onChangeSearchOptions$ = this.form.valueChanges
      .filter(val => this.form.valid)
      .map(formValue => {
        const searchStartDate = moment(formValue.searchStartDate).startOf('day');
        const searchEndDate = moment(formValue.searchEndDate).endOf('day');
        sessionStorage.setItem('searchStartDate', String(searchStartDate.valueOf()));
        sessionStorage.setItem('searchEndDate', String(searchEndDate.valueOf()));
        return { searchStartDate, searchEndDate };
      })
      .startWith({
        searchStartDate: this.dateRangeService.defaultStartDate,
        searchEndDate: this.dateRangeService.defaultEndDate
      });
  }
}
