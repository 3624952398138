import { UnsafeAction } from 'app/store/effects/unsafe-action';
import {
  GetAction,
  RequestSuccessAction,
  PutAction,
  PostAction
} from 'app/store/actions/request.actions';

export interface State {
  [key: string]: any;
}

export function requestStateReducer(
  state: State = {},
  action: UnsafeAction
): State {
  let existingOpKeyState;

  switch (action.type) {
    case GetAction.type:
      const getAction = action as GetAction;
      existingOpKeyState = state[getAction.payload.opKey] || {};

      return {
        ...state,
        [getAction.payload.opKey]: {
          ...existingOpKeyState,
          isLoading: true,
          result: null,
          queryParams: {
            ...existingOpKeyState.queryParams,
            ...getAction.payload.queryParams
          }
        }
      }

    // Currenlty, PostAction and PutAction are identical, only differing in the
    // type value and subsequent request method they trigger.
    case PutAction.type:
    case PostAction.type:
      const putAction = action as PutAction;
      existingOpKeyState = state[putAction.payload.opKey] || {};

      return {
        ...state,
        [putAction.payload.opKey]: {
          ...existingOpKeyState,
          isLoading: true,
          result: null,
        }
      }

    case RequestSuccessAction.type:
      const requestSuccessAction = action as RequestSuccessAction;

      return {
        ...state,
        [requestSuccessAction.payload.opKey]: {
          ...state[requestSuccessAction.payload.opKey],
          isLoading: false,
          result: requestSuccessAction.payload.result
        }
      }

    default:
      return state;
  }
}
