import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

import { BakeryInvoicePayment } from 'app/store/bakery-invoice-payment';

@Component({
  selector: 'invoice-payment-summary-text',
  template: `
    {{ payment.amount_paid | centsObservable | async }} {{ payment.payment_direction }} on {{ payment.created_at | date:'M/d/y' }} - {{ payment.notes }}
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvoicePaymentSummaryTextComponent {
  @Input() payment: BakeryInvoicePayment;
}
