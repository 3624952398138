import { Component, OnInit, OnDestroy, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { Actions as NgRxActions } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { AlertService } from 'app/shared/components/alerts/alert.service';
import { BakeryLocation } from 'app/store/bakery-location';
import { Bakery } from 'app/store/bakery';
import { ModalComponent } from 'app/shared/components/modals/modal.component';
import { BakeryMgmtLocationsEffects, DetailUiState } from 'app/store/effects/bakery-mgmt-locations.effects';
import { Actions } from 'app/store/actions';
import { AppState } from 'app/store/app-state';
import { getCurrentBkryMgmtBakery } from 'app/store/selectors';

@Component({
  selector: 'bakery-mgmt-store-location-detail',
  template: require('./location-detail.component.html'),
})
export class BakeryMgmtStoreLocationDetailComponent implements OnInit, OnDestroy {
  @ViewChild(ModalComponent) deleteModal: ModalComponent;

  detailsForm: FormGroup;
  updateSuccess$: Observable<any>;
  bakery$: Observable<Bakery>;

  private confirmDeleteEvents = new EventEmitter<any>();
  private resetFormEvents = new EventEmitter<any>();
  private detailsFormSubmitEvents = new EventEmitter<any>();
  private updateImageEvents = new EventEmitter<any>();
  private confirmDeleteEventSub: Subscription;
  private deleteSuccessSub: Subscription;
  private alertsSub: Subscription;
  private detailsFormSubmitSub: Subscription;
  private updateImageSub: Subscription;

  constructor(
    private bakeryMgmtLocationEffects: BakeryMgmtLocationsEffects,
    private router: Router,
    private route: ActivatedRoute,
    private actions$: NgRxActions,
    private alertService: AlertService,
    private store: Store<AppState>
  ) {
    this.route.params
      .map(params => params['location_id'])
      .forEach((idParam: string) => {
        const id = parseInt(idParam);

        this.bakeryMgmtLocationEffects.requestGetLocation(id);
      });
  }

  get uiStates$(): Observable<DetailUiState> {
    return this.bakeryMgmtLocationEffects.detailUiStates$;
  }

  get location$(): Observable<BakeryLocation> {
    return this.uiStates$.pluck('location');
  }

  ngOnInit() {
    this.alertsSub = this.actions$
      .subscribe((action: UnsafeAction) => {
        let messageType = null,
            messageContent = null;

        switch (action.type) {
          case Actions.REQUEST_DESTROY_BAKERY_LOCATION_SUCCESS:
            messageType = 'success';
            messageContent = `The location has been deleted.`;
            break;

          case Actions.REQUEST_UPDATE_BAKERY_LOCATION_SUCCESS:
            messageType = 'success';
            messageContent = `The location has been updated.`;
            break;

          case Actions.REQUEST_DESTROY_BAKERY_LOCATION_ERROR:
          case Actions.REQUEST_UPDATE_BAKERY_LOCATION_ERROR:
            messageType = 'warning';
            messageContent = 'Something went wrong!';
            break;
        }

        if (messageType != null && messageContent != null) {
          this.alertService[messageType](messageContent);
        }
      });

    this.confirmDeleteEventSub = this.confirmDeleteEvents
      .switchMap(() => {
        return this.uiStates$
          .filter(uiState => !uiState.isLoading)
          .take(1)
      })
      .subscribe((uiState: DetailUiState) => {
        this.bakeryMgmtLocationEffects.requestDestroyLocation(uiState.location);
      });

    this.deleteSuccessSub = this.bakeryMgmtLocationEffects.destroyBakeryLocationEffects$
      .filter(action => action.type === Actions.REQUEST_DESTROY_BAKERY_LOCATION_SUCCESS)
      .delay(1500)
      .subscribe(() => this.router.navigate(['/bakery_mgmt/store_mgmt/main/locations']));

    this.updateSuccess$ = this.bakeryMgmtLocationEffects.updateBakeryLocationEffects$
      .filter(action => action.type === Actions.REQUEST_UPDATE_BAKERY_LOCATION_SUCCESS);

    this.detailsFormSubmitSub = this.detailsFormSubmitEvents
      .withLatestFrom(
        this.uiStates$
          .filter(uiState => !uiState.isLoading)
          .take(1),
        (updatePayload, uiState) => ({updatePayload, location: uiState.location})
      )
      .subscribe((combined) => {
        const location = <BakeryLocation> combined.location;
        const updatePayload = <any> combined.updatePayload;

        this.bakeryMgmtLocationEffects.requestUpdateLocation(
          location,
          updatePayload,
        );
      });

    this.updateImageSub = this.updateImageEvents
      .withLatestFrom(
        this.uiStates$
          .filter(uiState => !uiState.isLoading)
          .take(1),
        (updatePayload, uiState) => ({updatePayload, location: uiState.location})
      )
      .subscribe((combined) => {
        const location = <BakeryLocation> combined.location;
        const updatePayload = <any> combined.updatePayload;

        this.bakeryMgmtLocationEffects.requestUpdateLocation(
          location,
          updatePayload,
        );
      });

    this.bakery$ = this.store.select(getCurrentBkryMgmtBakery).skipWhile(x => !x);
  }

  ngOnDestroy() {
    this.confirmDeleteEventSub.unsubscribe();
    this.deleteSuccessSub.unsubscribe();
    this.alertsSub.unsubscribe();
    this.detailsFormSubmitSub.unsubscribe();
    this.updateImageSub.unsubscribe();
  }

  onClickDelete() {
    this.deleteModal.openModal();
  }

  onConfirmDelete() {
    this.confirmDeleteEvents.next();
  }

  onClickResetForm() {
    this.resetFormEvents.next();
  }

  onSubmitDetailsForm(updatePayload: any) {
    this.detailsFormSubmitEvents.next(updatePayload);
    window.scrollTo(0, 0); //NOTE: Might want to add animation for smoother transition later.
  }

  onSaveImage(imageDataUri: string) {
    this.updateImageEvents.next({location_photo: imageDataUri});
  }
}
