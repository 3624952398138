import { Component, OnInit } from '@angular/core';

import { User } from 'app/store/user';
import { UsersEffects } from 'app/store/effects/users.effects';
import { BakeryMgmtEffects } from 'app/store/effects/bakery-mgmt.effects';

@Component({
  selector: 'app-bakery-mgmt',
  template: require('./bakery-mgmt.component.html'),
})
export class BakeryMgmtComponent implements OnInit {

  constructor(
    private usersEffects: UsersEffects,
    private bakeryMgmtEffects: BakeryMgmtEffects,
  ) { }

  get currentUser$() {
    return this.usersEffects.currentUser$;
  }

  get currentBakery$() {
    return this.bakeryMgmtEffects.currentBakery$;
  }

  ngOnInit() {
    this.bakeryMgmtEffects.requestGetOwnBakery();
  }
}
