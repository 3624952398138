import { Component, Input } from '@angular/core';

@Component({
  selector: 'card-list-tooltip-list-item',
  template: `
    <li>
      <ng-content></ng-content>
    </li>
  `,
})
export class TooltipListItemComponent { }
