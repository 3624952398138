import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Actions as NgRxActions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subscription } from 'rxjs/Subscription';

import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { AlertService } from 'app/shared/components/alerts/alert.service';
import { AppState } from 'app/store/app-state';
import { Actions } from 'app/store/actions';
import { BakeryMgmtProductsEffects } from 'app/store/effects/bakery-mgmt/bakery-mgmt-products.effects';
import { BakeryProduct } from 'app/store/bakery-product';
import { denormalize } from 'normalizr';
import { bakeryProductSchema } from 'app/store/schema/default-schemata';

export interface UiState {
  isLoading: boolean;
  hasBakeryProducts: boolean;
  bakeryProducts: BakeryProduct[];
  currentUserId: number;
}

@Component({
  template: require('./master.component.html'),
})
export class BakeryMgmtProductProductsMasterComponent implements OnInit, OnDestroy {
  uiState$: Observable<UiState>;

  private archiveFilter$: Observable<boolean>;
  private filterText$ = new BehaviorSubject<string>('');
  private alertsSub: Subscription;
  private archiveFilterSub: Subscription;

  constructor(
    private bkryMgmtProductsEffects: BakeryMgmtProductsEffects,
    private actions$: NgRxActions,
    private store: Store<AppState>,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
  ) {
    this.archiveFilter$ = this.route.data
      .map((data: any): boolean => data.archive_filter);


    this.uiState$ = Observable
      .combineLatest(
        this.store.map(x => x.bakeryMgmtUiState),
        this.store.map(x => x.entitiesState),
        this.archiveFilter$,
        this.filterText$,
        this.store.map(x => x.authState.currentUser.id).distinctUntilChanged()
      )
      .map(([bakeryMgmtUiState, entitiesState, archiveFilter, filterText, currentUserId]) => {
        if (bakeryMgmtUiState.productsUiState.isMasterLoading) {
          return {
            isLoading: true,
            hasBakeryProducts: false,
            bakeryProducts: [],
            currentUserId
          };
        }

        const bakeryProducts = Object.keys(entitiesState.bakery_products)
          .map(key => denormalize(key, bakeryProductSchema, entitiesState))
          .filter(bakeryProduct => bakeryProduct.archived === archiveFilter)
          .filter(bakeryProduct => bakeryProduct.name.toLowerCase().includes(filterText.toLowerCase()))
          .filter(bakeryProduct => {
            if (entitiesState.bakeries[bakeryMgmtUiState.bakeryId].advanced_products) {
              return bakeryProduct;
            } else {
              return bakeryProduct.advanced === false;
            }
          });

        return {
          isLoading: false,
          hasBakeryProducts: bakeryProducts.length > 0,
          bakeryProducts: bakeryProducts,
          currentUserId
        };
      })
      .shareReplay(1);

    this.alertsSub = this.actions$
      .subscribe((action: UnsafeAction) => {
        let messageType = null,
            messageContent = null;

        switch (action.type) {
          case Actions.REQUEST_UPDATE_BAKERY_PRODUCT_SUCCESS:
            messageType = 'success';
            messageContent = `The product has been updated.`;
            break;

          case Actions.REQUEST_UPDATE_BAKERY_PRODUCT_ERROR:
            messageType = 'warning';
            messageContent = 'Something went wrong!';
            break;
        }

        if (messageType != null && messageContent != null) {
          this.alertService[messageType](messageContent);
        }
      });
  }

  ngOnInit() {
    this.archiveFilterSub = this.archiveFilter$
      .subscribe((archiveQueryParam: boolean) => {
        this.bkryMgmtProductsEffects.requestGetBakeryProducts(archiveQueryParam);
      });
  }

  ngOnDestroy() {
    this.alertsSub.unsubscribe();
    this.archiveFilterSub.unsubscribe();
  }

  onChangeFilterText(filterText: string) {
    this.filterText$.next(filterText);
  }

  onAddProduct(bakeryProductDetails: any) {
    this.bkryMgmtProductsEffects.requestCreateBakeryProduct(bakeryProductDetails);
  }

  onChangeProductArchived(bakeryProduct: BakeryProduct) {
    this.bkryMgmtProductsEffects.requestUpdateBakeryProduct(
      bakeryProduct,
      {
        archived: !bakeryProduct.archived
      }
    );
  }

  onShowDetails(bakeryProduct: BakeryProduct) {
    this.router.navigate([
      '/bakery_mgmt/product_mgmt/products/',
      bakeryProduct.id,
    ]);
  }
}
