import { Component, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as Rx from 'rxjs';
import { Actions as NgRxActions } from '@ngrx/effects';

import { Actions } from 'app/store/actions';
import { AppState } from 'app/store/app-state';
import { BakeryOrder } from 'app/store/bakery-order';
import * as selectors from 'app/store/selectors';

@Component({
  selector: 'refund-negative-balance',
  template: `
    <titled-card *ngIf="(isBalanceNegative$|async)" title="Refund">
      <div data-card-body>
        <p class="margin--reset"><strong>The actual refund must be processed outside of OrderNova.</strong></p>
        <p>Once the refund has been processed you can bring the balance to {{ 0 | centsObservable | async }} here.</p>
        <form [formGroup]="form">
          <div>
            <label for="refund-notes">Notes</label>
            <input id="refund-notes" type="text" formControlName="notes" placeholder="Refund details">
          </div>
          <form-submit-cancel-control
            [isSubmittable]="(isRefundable$|async)"
            [isCancelable]="form.dirty"
            [isLoading]="(isLoading$|async)"
            submitText="Mark Refunded"
            submittingText="Marking"
            (onSubmit)="onSubmit(form.value)"
            (onCancel)="onCancel()"
          ></form-submit-cancel-control>
        </form>
      </div>
    </titled-card>
  `
})
export class RefundNegativeBalanceComponent implements OnDestroy {
  bakeryOrder$ = this.store.select(selectors.getBkryMgmtOrderMgmtDetailResult);
  refundStatus$ = this.store.select(selectors.selectRefundRequest);
  isLoading$: Rx.Observable<boolean> = this.refundStatus$.map(refundStatus => refundStatus === 'loading');
  isBalanceNegative$: Rx.Observable<boolean> = this.bakeryOrder$.map(bakeryOrder => {
    return bakeryOrder.invoice && bakeryOrder.invoice.balance < 0;
  });
  isRefundable$: Rx.Observable<boolean> =
    this.isLoading$.combineLatest(this.isBalanceNegative$, (isLoading, isBalanceNegative) => {
      return !isLoading && isBalanceNegative;
    });
  form: FormGroup;
  private subscription;

  constructor(
    private store: Store<AppState>,
    private formBuilder: FormBuilder,
    private actions$: NgRxActions,
  ) {
    this.form = this.formBuilder.group({ notes: '' });
    this.subscription = this.actions$.ofType(Actions.BAKERY_ORDER_REFUND_SUCCEEDED).subscribe(() => {
      this.form.reset();
    });
  }

  onSubmit(formData) {
    this.store.dispatch({ type: Actions.BAKERY_ORDER_REFUND_REQUESTED, payload: formData });
  }

  onCancel() {
    this.form.reset();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
