import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { Subject } from 'rxjs/Subject';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Store } from '@ngrx/store';

import { AppState } from 'app/store/app-state';
import { Actions } from 'app/store/actions';
import * as selectors from 'app/store/selectors';
import { DateRange } from 'app/shared/components/date-range-search-form/date-range-search-form-2.component';
import { Moment } from 'moment/moment';
import * as OrderingArrowComponent from 'app/shared/components/ordering-arrow.component';

enum OrderingDirection {
  Asc,
  Desc
}

enum OrderingField {
  OrderNumber,
  FulfillmentOn,
  LastName
}

interface Ordering {
  field: OrderingField;
  direction: OrderingDirection;
}

const orderingLabel = field => ordering => {
  if (ordering.field !== field) { return OrderingArrowComponent.Direction.None; }
  switch (ordering.direction) {
    case OrderingDirection.Asc: return OrderingArrowComponent.Direction.Asc;
    case OrderingDirection.Desc: return OrderingArrowComponent.Direction.Desc;
  }
};

const serializeOrderingField = ordering => {
  switch (ordering.field) {
    case OrderingField.OrderNumber: return 'order_number';
    case OrderingField.LastName: return 'order_customer_name';
    case OrderingField.FulfillmentOn: return 'fulfillment_on';
  }
};

const serializeOrderingDirection = ordering => {
  switch (ordering.direction) {
    case OrderingDirection.Asc: return 'asc';
    case OrderingDirection.Desc: return 'desc';
  }
};

@Component({
  template: require('./production-check-list.component.html'),
})
export class BakeryMgmtReportingProductionCheckListComponent implements OnInit, OnDestroy {
  OrderingFieldType = OrderingField;
  orderingDirection$ = new Subject<OrderingField>();
  private ordering$ = Observable
    .from(this.orderingDirection$)
    .scan((acc, field) => {
      if (acc.field !== field) {
        return { field, direction: OrderingDirection.Asc };
      }
      switch (acc.direction) {
        case OrderingDirection.Asc: return { field, direction: OrderingDirection.Desc };
        case OrderingDirection.Desc: return { field, direction: OrderingDirection.Asc };
      }
    }, { field: OrderingField.FulfillmentOn, direction: OrderingDirection.Asc })
    .startWith({ field: OrderingField.FulfillmentOn, direction: OrderingDirection.Asc });
  orderNumberOrderDirection$ = this.ordering$.map(orderingLabel(OrderingField.OrderNumber));
  lastNameOrderDirection$ = this.ordering$.map(orderingLabel(OrderingField.LastName));
  fulfillmentOnOrderDirection$ = this.ordering$.map(orderingLabel(OrderingField.FulfillmentOn));

  filterForm: FormGroup;
  locationForm: FormGroup;
  locationControls$;

  onChangeSearchOptions$ = new ReplaySubject<DateRange>();
  private searchOptions$ = this.onChangeSearchOptions$
    .do(() => this.pageNumber = 0);
  searchStartDate$ = this.searchOptions$.pluck('searchStartDate').map((x: Moment) => x.toISOString());
  searchEndDate$ = this.searchOptions$.pluck('searchEndDate').map((x: Moment) => x.toISOString());

  private pageNumber$ = new BehaviorSubject<number>(0);
  pageNumber = 0;
  private subscription: Subscription;

  productionChecklist$ = this.store.select(selectors.selectProductionChecklist);
  currentPage$ = this.productionChecklist$.pluck('data');
  resultsCount$ = this.productionChecklist$.pluck('total');
  bottom$ = this.productionChecklist$.pluck('bottom');
  top$ = this.productionChecklist$.pluck('top');
  printableProductionChecklist$ = this.productionChecklist$.pluck('printableData');
  selectedFulfillments$: Observable<string>;
  bakeryLocations$: Observable<{ id: number, name: string }[]> = this.store
    .select(selectors.selectBakeryLocations)
    .pluck('bakeryLocations');

  constructor(
    private store: Store<AppState>
  ) {
    this.filterForm = new FormGroup({
      delivery: new FormControl(true),
      shipping: new FormControl(true),
    });
    this.locationForm = new FormGroup({});
  }

  ngOnInit() {
    this.store.dispatch({ type: Actions.GET_BAKERY_LOCATIONS });

    this.locationControls$ = this.bakeryLocations$
      .skipWhile((xs: any[]) => xs.length === 0)
      .take(1)
      .do((locations: any[]) => {
        locations.forEach(location => {
          this.locationForm.addControl(location.id.toString(), new FormControl(true));
        });
      })
      .map((locations: any[]) => {
        return locations.map(location => (
          { controlName: location.id.toString(), controlLabel: location.name + (location.deleted ? ' (deleted)' : '') }
        ));
      });

    const locationFormValueChanges$ = this.locationControls$
      // Emit only after locationControls$ has completed
      .concatMap(() => this.locationForm.valueChanges.startWith(this.locationForm.value))
      // Emit only when values changed
      .distinctUntilChanged((x, y) => JSON.stringify(x) === JSON.stringify(y));
      // The above is needed because addControl emits so
      // - we want to wait for the controls to be there
      // - we want changes to be distinct

    const locationFormSelectedFilters$ = locationFormValueChanges$
      .do(() => this.pageNumber = 0)
      .map(x => [this.getSelectedFiltersFor(x)]);

    const filterFormSelectedFilters$ = this.filterForm
      .valueChanges
      .startWith(this.filterForm.value)
      .do(() => this.pageNumber = 0)
      .map(x => [this.getSelectedFiltersFor(x)]);

    const page$ = this.pageNumber$.do(offset => this.pageNumber += offset);

    this.subscription = Observable.combineLatest(
      page$.timestamp(),
      this.searchOptions$.timestamp(),
      filterFormSelectedFilters$.timestamp(),
      locationFormSelectedFilters$.timestamp(),
      this.ordering$.timestamp() as any
    ).subscribe(([page, searchOptions, filterForm, locationForm, ordering]) => {
      const payload = {
        search_start_date: searchOptions.value.searchStartDate,
        search_end_date: searchOptions.value.searchEndDate,
        fulfillment_types: filterForm.value,
        locations: locationForm.value,
        order_by: serializeOrderingField(ordering.value),
        order_direction: serializeOrderingDirection(ordering.value),
      };

      this.store.dispatch({
        type: Actions.REQUEST_PRODUCTION_CHECKLIST,
        payload: { ...payload, page: this.pageNumber }
      });
      if (searchOptions.timestamp >= page.timestamp || filterForm.timestamp >= page.timestamp || locationForm.timestamp >= page.timestamp || ordering.timestamp >= page.timestamp) {
        this.store.dispatch({ type: Actions.REQUEST_PRINTABLE_PRODUCTION_CHECKLIST, payload });
      }
    });

    const selectedBakeryLocationNames$ = Observable.combineLatest(
      locationFormValueChanges$,
      this.bakeryLocations$
    )
    .map(([locationForm, bakeryLocations]) => {
      const isSelected = (form, id) =>
        this.getSelectedFiltersFor(form).includes(String(id));

      return bakeryLocations
        .filter(location => isSelected(locationForm, location.id))
        .map(location => `pickup at ${location.name}`);
    })
    .startWith([]);

    const selectedFulfillmentTypes$ = this.filterForm.valueChanges
      .startWith(this.filterForm.value)
      .map(filterForm => this.getSelectedFiltersFor(filterForm));

    this.selectedFulfillments$ = Observable.combineLatest(
      selectedBakeryLocationNames$,
      selectedFulfillmentTypes$
    )
    .map(([bakeryLocations, fulfillmentTypes]) => bakeryLocations.concat(fulfillmentTypes).join(', '));
  }

  getSelectedFiltersFor(filters) {
    const selectedFilters = [];
    for (const key of Object.keys(filters)) {
      if (filters[key]) {
        selectedFilters.push(key);
      }
    }
    return selectedFilters;
  }

  previousPage() {
    this.pageNumber$.next(-1);
  }

  nextPage() {
    this.pageNumber$.next(1);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getAttributes(item) {
    const attributes = item.meta.template_variants.map(variant => (
      `${variant.bakery_product_attribute.visible_name}: <strong>${variant.bakery_attribute_variant.name}</strong><br>`
    )).concat(item.meta.attribute_variants.map(variant => (
      `${variant.bakery_product_attribute.visible_name}: <strong>${variant.bakery_attribute_variant.name}</strong><br>`
    )));
    if (item.meta.inscription) {
      attributes.push(`Inscription: <strong>${item.meta.inscription}</strong>`);
    }

    return attributes.join('');
  }
}
