import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { Subject } from 'rxjs/Subject';
import { Store } from '@ngrx/store';
import { Actions as NgRxActions } from '@ngrx/effects';

import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { AlertService } from 'app/shared/components/alerts/alert.service';
import { AppState } from 'app/store/app-state';
import { EntitiesState } from 'app/store/entities-state';
import { Actions } from 'app/store/actions';
import { User } from 'app/store/user';
import { Bakery } from 'app/store/bakery';
import { FeatureModule } from 'app/store/feature-module';
import { SubscriptionPlanType } from 'app/store/subscription-plan-type';
import { BakerySubscriptionStatus } from 'app/store/bakery-subscription-status';
import { UsersEffects } from 'app/store/effects/users.effects';
import { BakeryMgmtEffects } from 'app/store/effects/bakery-mgmt.effects';
import { BakeryPricingEffects } from 'app/store/effects/bakery/bakery-pricing.effects';

declare var process: any;

interface UiState {
  isLoading: boolean;
  bakery?: Bakery;
  featureModules?: FeatureModule[];
  subPlanTypes?: SubscriptionPlanType[];
  allSubPlanTypes?: SubscriptionPlanType[];
  locationSubPlanType?: SubscriptionPlanType;
}

@Component({
  selector: 'app-subscription',
  template: require('./subscription.component.html'),
})
export class SubscriptionComponent implements OnInit {
  purchaseClick$ = new Subject<any>();
  isCardValid = false;
  isAccountPaused = false;
  uiState$: Observable<UiState>;
  uiState: UiState;
  form: FormGroup;

  perLocationCost = 0;
  wholesalerEcommPlan: any;

  private uiStateSub: Subscription;
  private subscribeSuccessSub: Subscription;
  private planTypeControlChangesSub: Subscription;
  private wholesalerFeatureModuleControlChangesSub: Subscription;
  private selectedPrimaryPlanType: SubscriptionPlanType;
  private locationCountControlSub: Subscription;
  private alertsSub: Subscription;
  private fetching: Boolean;
  private disableModuleSelection: Boolean;

  constructor(
    private alertService: AlertService,
    private usersEffects: UsersEffects,
    private bakeryMgmtEffects: BakeryMgmtEffects,
    private bakeryPricingEffects: BakeryPricingEffects,
    private router: Router,
    private actions$: NgRxActions,
    private store: Store<AppState>,
  ) {
    this.alertsSub = this.actions$
    .subscribe((action: UnsafeAction) => {
      let messageType = null,
          messageContent = null;
      switch (action.type) {
        case Actions.REQUEST_UPDATE_SUBSCRIBE_ERROR:
          this.fetching = false;
          messageType = 'warning';
          messageContent = `Something went wrong!`;
          break;
      }
      if (messageType != null && messageContent != null) {
        this.alertService[messageType](messageContent);
      }
    });
  }

  get currentUser$() {
    return this.usersEffects.currentUser$;
  }

  get currentBakery$() {
    return this.bakeryMgmtEffects.currentBakery$;
  }

  ngOnInit() {
    this.form = new FormGroup({
      'primary_plan_type': new FormControl(null),
      'online_retail_store': new FormControl(false),
      'online_wholesale_store': new FormControl(false),
      'advanced_products': new FormControl(null),
      'locations_count': new FormControl(null, [Validators.pattern('^\\d+$'), Validators.required]),
      'enable_wholesaler_ecomm': new FormControl(false),
    });

    this.uiState$ = Observable
      .merge(
        this.actions$.ofType(Actions.REQUEST_GET_SUBSCRIBE)
          .mapTo({isLoading: true}),
        this.actions$.ofType(Actions.REQUEST_GET_SUBSCRIBE_SUCCESS)
          .switchMap(() => this.store.select('entitiesState').take(1))
          .map((entitiesState: EntitiesState) => {
            const bakery = Object
              .keys(entitiesState.bakeries)
              .map(key => Object.assign({}, entitiesState.bakeries[key]))[0];

            bakery.bakery_subscription_status = Object.assign({},
              entitiesState.bakery_subscription_statuses[<number>bakery.bakery_subscription_status]
            )

            bakery.bakery_subscription_status.feature_modules = bakery.bakery_subscription_status.feature_modules
              .map((featureModuleId: number) => {
                return entitiesState.feature_modules[featureModuleId];
              })

            return {
              isLoading: false,
              bakery: bakery,
              subPlanTypes: Object
                .keys(entitiesState.subscription_plan_types)
                .map(key => entitiesState.subscription_plan_types[key])
                .filter(subPlanType => subPlanType.plan_group_type === 'primary_plan' && !subPlanType.is_trial),
              allSubPlanTypes: Object
                .keys(entitiesState.subscription_plan_types)
                .map(key => entitiesState.subscription_plan_types[key]),
              featureModules: Object
                .keys(entitiesState.feature_modules)
                .map(key => entitiesState.feature_modules[key]),
              locationSubPlanType: Object
                .keys(entitiesState.subscription_plan_types)
                .map(key => entitiesState.subscription_plan_types[key])
                .filter(subPlanType => subPlanType.type_name === 'location')[0]
            };
          })
      );

    this.uiStateSub = this.uiState$
      .subscribe((uiState: UiState) => {
        // Store UiState.
        this.uiState = uiState;

        if (!uiState.isLoading) {
          const bkrySubStatus = <BakerySubscriptionStatus>uiState.bakery.bakery_subscription_status;
          const selectedPrimaryPlanType = bkrySubStatus.primary_plan_type;

          this.isAccountPaused = uiState.bakery.account_status === 'paused';
          // In the event of a trial
          const locationsCount = uiState.bakery.bakery_locations.length > bkrySubStatus.max_allowed_locations ? uiState.bakery.bakery_locations.length : bkrySubStatus.max_allowed_locations

          // Update form values.
          const isWholesalerModuleEnabled = (<FeatureModule[]>bkrySubStatus.feature_modules).some(featureModule => featureModule.type_name === 'online_wholesale_store');
          this.form.patchValue({
            primary_plan_type: selectedPrimaryPlanType,
            online_retail_store: (<FeatureModule[]>bkrySubStatus.feature_modules).some(featureModule => featureModule.type_name === 'online_retail_store'),
            online_wholesale_store: isWholesalerModuleEnabled,
            advanced_products: (<FeatureModule[]>bkrySubStatus.feature_modules).some(featureModule => featureModule.type_name === 'advanced_products'),
            locations_count: locationsCount,
          });

          const eCommChildSub = (bkrySubStatus as any).primary_subscription.child_bakery_subscriptions.find(childSub => childSub.subscription_plan_type.type_name === 'wholesaler_ecomm');
          const isEnableWholesalerEcomm = eCommChildSub && eCommChildSub.quantity > 0;
          this.setEnableWholesalerEcomm(isWholesalerModuleEnabled, isEnableWholesalerEcomm);

          this.perLocationCost = uiState.locationSubPlanType.cost;
          const primaryPlan = uiState.subPlanTypes
            .find(planType => planType.type_name === selectedPrimaryPlanType);
          this.setSelectedPrimaryPlan(primaryPlan);

          this.wholesalerEcommPlan = uiState.allSubPlanTypes
            .find(planType => planType.type_name === 'wholesaler_ecomm');
        }
      });

    const selectedModulesControl = this.form.valueChanges.subscribe(form => {
      if (this.selectedPrimaryPlanType.max_allowed_modules === this.enabledFeatureModules.length) {
        this.disableModuleSelection = true;
      } else {
        this.disableModuleSelection = false;
      }
    })

    const primaryPlanTypeControl = this.form.get('primary_plan_type');
    this.planTypeControlChangesSub = primaryPlanTypeControl.valueChanges
      .map((planTypeName: string) => this.uiState.subPlanTypes.find(planType => planType.type_name === planTypeName))
      .subscribe(
        (planType: any) => this.setSelectedPrimaryPlan(planType)
      )

    const wholesalerFeatureModuleControl = this.form.get('online_wholesale_store');
    this.wholesalerFeatureModuleControlChangesSub = wholesalerFeatureModuleControl.valueChanges
      .subscribe((isWholesalerModuleEnabled: boolean) => {
        this.setEnableWholesalerEcomm(isWholesalerModuleEnabled, false);
      });

    const locationCountControl = this.form.get('locations_count')
    this.locationCountControlSub = locationCountControl.valueChanges
      .subscribe(
        (locationCount: any) => {
          // allow form validation to take over
          if (locationCount === '') return;

          let newLocationCount = locationCount;

          if (locationCount < this.uiState.bakery.bakery_locations.length) {
            // restrict against enter numbers below current bakery location length
            newLocationCount = this.uiState.bakery.bakery_locations.length;
          } else if (isNaN(locationCount)) {
            // remove any non numeric characters
            newLocationCount = locationCount.replace(/[^0-9]/g, '');
          }

          this.form.patchValue({
            locations_count: newLocationCount
          }, {emitEvent: false});
        }
      )

    this.bakeryPricingEffects.requestGetSubscribe();

    // hacky setting notifications after route changed
    this.subscribeSuccessSub = this.actions$
      .filter((action: UnsafeAction) => action.type === Actions.REQUEST_UPDATE_SUBSCRIBE_SUCCESS)
      .subscribe(() => {
        this.usersEffects.requestValidateToken()

        this.router
          .navigate(['/bakery_mgmt/account_settings'])
          .then(_ => setTimeout(_ => this.alertService['success']('Your subscription has been updated.')))
      })
  }

  private setEnableWholesalerEcomm(isWholesalerModuleEnabled: boolean, isEnableWholesalerEcomm: boolean) {
    if (isWholesalerModuleEnabled) {
      this.form.get('enable_wholesaler_ecomm').enable();
      this.form.patchValue({enable_wholesaler_ecomm: isEnableWholesalerEcomm});
    } else {
      this.form.get('enable_wholesaler_ecomm').disable();
      this.form.patchValue({enable_wholesaler_ecomm: false});
    }
  }

  private setSelectedPrimaryPlan(planType: SubscriptionPlanType) {
    const existingPlanType = this.selectedPrimaryPlanType;
    this.selectedPrimaryPlanType = planType;
    if (existingPlanType != null && this.enabledFeatureModules.length > planType.max_allowed_modules) {
      this.form.patchValue({
        primary_plan_type: planType.type_name,
        online_retail_store: false,
        online_wholesale_store: false,
        advanced_products: false,
        locations_count: this.uiState.bakery.bakery_locations.length,
      }, {emitEvent: false})
    }
  }

  get primaryPlanCost(): number {
    return this.selectedPrimaryPlanType.cost;
  }

  get totalCost(): number {
    const isEnableWholesalerEcomm = this.form.get('enable_wholesaler_ecomm').value === true;

    return this.primaryPlanCost + this.totalLocationCost + (isEnableWholesalerEcomm ? this.wholesalerEcommPlanCost : 0);
  }

  get wholesalerEcommPlanCost(): number {
    return (this.wholesalerEcommPlan && this.wholesalerEcommPlan.cost) || 0;
  }

  get totalLocationCost(): number {
    const locations_count = this.form.value.locations_count - 1;
    return locations_count > 0 ? locations_count * this.perLocationCost : 0;
  }

  get enabledFeatureModules(): string[] {
    return ['online_retail_store', 'online_wholesale_store', 'advanced_products'].filter(moduleTypeName => this.form.get(moduleTypeName).value);
  }

  isModuleDisabled(moduleTypeName) {
    return this.disableModuleSelection && !this.form.get(moduleTypeName).value
  }

  ngOnDestroy() {
    this.uiStateSub.unsubscribe();
    this.subscribeSuccessSub.unsubscribe();
    this.planTypeControlChangesSub.unsubscribe();
    this.locationCountControlSub.unsubscribe();
    this.wholesalerFeatureModuleControlChangesSub.unsubscribe();
  }

  onClickPurchase() {
    this.purchaseClick$.subscribe(_ => this.fetching = true)
    this.purchaseClick$.next();
  }

  onClickFeatureModule(event) {
    if (this.selectedPrimaryPlanType.max_allowed_modules <= this.enabledFeatureModules.length && event.target.checked){
      event.preventDefault();
    }
  }

  onChangeCardFormIsValid(isValid: any) {
    this.isCardValid = isValid;
  }

  get isValid() {
    return this.form.valid && this.isCardValid;
  }

  onCreateStripeToken(tokenResults: any) {
    this.bakeryPricingEffects.requestUpdateSubscribe(
      tokenResults.token.id,
      this.form.value.primary_plan_type,
      this.enabledFeatureModules,
      this.form.value.locations_count,
      this.form.value.enable_wholesaler_ecomm
    );
  }
}
