import {
  Component,
  OnInit,
  OnDestroy,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { Subject } from 'rxjs/Subject';

import { BakeryAttribute } from 'app/store/bakery-attribute';
import { SimpleFormState } from 'app/shared/forms/form-states';
import { validatePositiveInteger } from 'app/shared/forms/positive-integer-validator';
import { zeroPad } from 'app/shared/string-utils';
import { validateNoWhitespaces } from 'app/shared/forms/no-whitespaces-validator';

@Component({
  selector: 'bakery-mgmt-product-attribute-detail-edit',
  template: require('./attribute-detail-edit.component.html')
})
export class BakeryMgmtProductAttributeDetailEditComponent
  implements OnInit, OnDestroy {
  @Input('bakeryAttribute$')
  bakeryAttribute$: Observable<BakeryAttribute>;
  @Input('success$') success$: Observable<any>;
  @Input('reset$') reset$: Observable<any>;
  @Output('submitForm') submitForm$ = new Subject<any>();
  @Output('formState') formState$: Observable<SimpleFormState>;
  unitPriceMask = [/\d/, /\d/, /\d/, '.', /\d/, /\d/];

  form: FormGroup;

  private filteredProdTem$: Observable<BakeryAttribute>;
  private resetFormEvents = new EventEmitter<any>();
  private formValuesSub: Subscription;
  private resetFormSub: Subscription;

  constructor() {
    this.form = new FormGroup({
      name: new FormControl(null, [
        Validators.required,
        validateNoWhitespaces
      ])
    });

    this.formState$ = this.form.valueChanges.map((): SimpleFormState => {
      return {
        isDirty: this.form.dirty,
        isValid: this.form.valid,
        value: this.form.value
      };
    });
  }

  ngOnInit() {
    this.filteredProdTem$ = this.bakeryAttribute$.filter(
      (bakeryAttribute: BakeryAttribute) =>
        bakeryAttribute != null
    );

    this.formValuesSub = this.filteredProdTem$.subscribe(
      (bakeryAttribute: BakeryAttribute) => {
        this.setFormValues(bakeryAttribute);
      }
    );

    this.resetFormSub = this.reset$
      .switchMap(() => this.filteredProdTem$.take(1))
      .subscribe((bakeryAttribute: BakeryAttribute) =>
        this.resetForm(bakeryAttribute)
      );

    this.success$
      .switchMap(() => {
        return this.bakeryAttribute$
          .filter(bakeryAttribute => bakeryAttribute != null)
          .take(1);
      })
      .subscribe((bakeryAttribute: BakeryAttribute) =>
        this.resetForm(bakeryAttribute)
      );
  }

  ngOnDestroy() {
    this.formValuesSub.unsubscribe();
  }

  private resetForm(bakeryAttribute: BakeryAttribute) {
    this.form.reset(this.buildFormValue(bakeryAttribute));
    this.form.reset(this.buildFormValue(bakeryAttribute));
  }

  private setFormValues(bakeryAttribute: BakeryAttribute) {
    this.form.setValue(this.buildFormValue(bakeryAttribute));
  }

  private buildFormValue(bakeryAttribute: BakeryAttribute) {
    return {
      name: bakeryAttribute.name
    };
  }

  onClickResetForm() {
    this.resetFormEvents.next();
  }
}
