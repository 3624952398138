import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { Store } from '@ngrx/store';
import { Actions as NgRxActions } from '@ngrx/effects';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';

import * as selectors from 'app/store/selectors';
import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { Actions } from 'app/store/actions';
import { AppState } from 'app/store/app-state';
import { AlertService } from 'app/shared/components/alerts/alert.service';
import { AuthState } from 'app/store/auth-state';
import { User } from 'app/store/user';
import { Bakery } from 'app/store/bakery';
import { UsersEffects } from 'app/store/effects/users.effects';
import { BakeryCustEffects } from 'app/store/effects/bakery-cust/bakery-cust.effects';
import { SimpleFormState, SubmittableFormState } from 'app/shared/forms/form-states';

export interface UiState {
  isLoading: true;
  bakery?: Bakery;
  user?: User;
}

@Component({
  template: require('./account-settings.component.html'),
})
export class BakeryCustAccountSettingsComponent implements OnInit, OnDestroy {
  isClassMobileMenuActive = false;
  authState$: Observable<AuthState>;
  uiState$: Observable<any>;
  countryCode$: Observable<string>;
  safeUser$: Observable<User>;
  formSubmit$ = new Subject<any>();
  formReset$ = new Subject<any>();
  formState$: Observable<SubmittableFormState>;
  userDetailFormState$ = new Subject<SimpleFormState>();
  userPasswordFormState$ = new Subject<SimpleFormState>();
  userImageFormState$ = new Subject<SimpleFormState>();
  creditCardFormState$ = new Subject<SimpleFormState>();

  private formSubmitSub: Subscription;
  private alertsSub: Subscription;

  constructor(
    private usersEffects: UsersEffects,
    private bakeryCustEffects: BakeryCustEffects,
    private router: Router,
    private store: Store<AppState>,
    private actions$: NgRxActions,
    private alertService: AlertService,
  ) {
    this.uiState$ = Observable
      .combineLatest(
        this.usersEffects.currentUser$.startWith(null),
        this.bakeryCustEffects.currentBakery$.startWith(null),
        (currentUser, currentBakery) => ({currentUser, currentBakery})
      )
      .map(combined => {
        const currentUser = <User>combined.currentUser;
        const currentBakery = <Bakery>combined.currentBakery;

        if (currentUser == null || currentBakery == null) {
          return {isLoading: true};
        }

        return {
          isLoading: false,
          bakery: currentBakery,
          user: currentUser,
        };
      })
      .startWith({isLoading: true})
      .shareReplay(1);

    this.safeUser$ = this.uiState$
      .filter((uiState: UiState) => !uiState.isLoading)
      .map((uiState: UiState): User => uiState.user);

    this.formState$ = Observable
      .combineLatest(
        this.userDetailFormState$
          .startWith({isDirty: false, isValid: true, value: {}}),
        this.userImageFormState$
          .startWith({isDirty: false, isValid: true, value: {}}),
        this.userPasswordFormState$
          .startWith({isDirty: false, isValid: true, value: {}}),
        this.creditCardFormState$
          .startWith({isDirty: false, isValid: true, value: {}}),
      )
      .map((simpleFormStates: SimpleFormState[]): SubmittableFormState => {
          const isDirty = simpleFormStates.some(formState => formState.isDirty);
          const isValid = simpleFormStates.every(formState => formState.isValid);
          const values = simpleFormStates.map(formState => formState.value);

          return {
            isValid: isValid,
            isDirty: isDirty,
            isSubmittable: isDirty && isValid,
            value: Object.assign({}, ...values)
          };
        }
      )
      .startWith({isDirty: false, isValid: false, isSubmittable: false, value: {}})
      .do(formState => console.log(`[account-settings] formState:`, formState))
      .shareReplay(1);

    this.alertsSub = this.actions$
      .subscribe((action: UnsafeAction) => {
        let messageType = null,
            messageContent = null;

        switch (action.type) {
          case Actions.REQUEST_USER_DETAILS_UPDATE_SUCCESS:
            messageType = 'success';
            messageContent = `Your details have been updated.`;
            break;

          case Actions.REQUEST_USER_DETAILS_UPDATE_ERROR:
            messageType = 'warning';
            messageContent = `Something went wrong!`;
            break;
        }

        if (messageType != null && messageContent != null) {
          this.alertService[messageType](messageContent);
        }
      });
  }

  ngOnInit() {
    this.formSubmitSub = this.formSubmit$
      .switchMap(() => this.formState$.take(1))
      .withLatestFrom(
        this.uiState$,
        (formState, uiState) => ({formState, uiState})
      )
      .subscribe(({formState, uiState}) => {
        this.usersEffects.requestUserDetailsUpdate(
          {...formState.value, id: uiState.user.id},
        );
      });

    this.countryCode$ = this.store
      .select(selectors.getCurrentCustBakery)
      .skipWhile(x => !x)
      .pluck('country_code');
  }

  ngOnDestroy() {
    this.alertsSub.unsubscribe();
    this.formSubmitSub.unsubscribe();
  }

  onClickFormSubmit() {
    this.formSubmit$.next();
  }

  onClickFormReset() {
    this.formReset$.next();
  }
}
