import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

const compSelector = 'bkry-mgmt-order-details-customer-details';

@Component({
  selector: compSelector,
  template: require('./customer-details.component.html'),
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerDetailsComponent {
  @Input() bakeryOrder: any;

  get customer(): any {
    return this.bakeryOrder.parent;
  }

  get hasAddress(): boolean {
    return !!this.bakeryOrder.parent.default_address;
  }

  get isParentWholesaler(): boolean {
    return this.bakeryOrder.parent_type === 'WholesalerOrg';
  }

  get customerDetailsLink(): string {
    const parentId = this.bakeryOrder.parent_id;

    if (this.isParentWholesaler) {
      return `/bakery_mgmt/wholesalers/wholesaler/${parentId}`;
    }

    return `/bakery_mgmt/customers/${parentId}`;
  }

  get customerSettingsLink(): string {
    const parentId = this.bakeryOrder.parent_id;

    if (this.isParentWholesaler) {
      return `/bakery_mgmt/wholesalers/wholesaler/${parentId}/settings`;
    }

    return `/bakery_mgmt/customers/${parentId}/settings`;
  }
}
