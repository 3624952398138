import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { Store } from '@ngrx/store';
import { Angular2TokenService } from 'app/angular2-token/angular2-token.service';
import { Actions as NgRxActions, Effect } from '@ngrx/effects';
import * as Rx from 'rxjs';
import { normalize, schema } from 'normalizr';

import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { AppState } from 'app/store/app-state';
import { Actions } from 'app/store/actions';
import { EntitiesState } from 'app/store/entities-state';
import { basicJsonRequestHandler } from 'app/store/effects/helpers';
import * as selectors from 'app/store/selectors';
import { BakeryCustEffects } from 'app/store/effects/bakery-cust/bakery-cust.effects';

@Injectable()
export class BakeryMgmtDashboardEffects {
  constructor(
    private actions$: NgRxActions,
    private tokenService: Angular2TokenService,
    private store: Store<AppState>,
  ) { }

  @Effect() getBkryMgmtDashboardEffects$ = this.actions$
    .ofType(Actions.REQUEST_GET_BKRY_MGMT_DASHBOARD)
    .switchMap((action: UnsafeAction) => {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return this.tokenService.get(`/api/bakery/dashboard?timezone=${encodeURIComponent(timezone)}`)
        .map((response: Response) => response.json())
        .map((data: any) => {
          return {
            type: Actions.REQUEST_GET_BKRY_MGMT_DASHBOARD_SUCCESS,
            payload: data,
          };
        })
        .catch((error) => {
          const errorPayload = typeof error.json === 'function' ? error.json() : error;

          return Rx.Observable.throw(errorPayload);
        });
    })
    .share();
}
