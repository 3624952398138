import {
  Component,
  Input
} from '@angular/core';
import { Subject } from 'rxjs';

import { PaymentCard } from 'app/store/payment-card';
import { GenericControlValueInput } from 'app/shared/components/form/generic-control-value-input';
import { buildForwardRef } from 'app/shared/components/form/input-forward-ref-builder';

interface PaymentCardFormData extends PaymentCard {
  _destroy?: boolean;
}

@Component({
  selector: 'wholesaler-org-settings-credit-card-input',
  template: `
    <user-credit-card
      [paymentCards]="visibleCards"
      [reset$]="reset$"
      (addCard$)="onAddCard($event)"
      (removeCard$)="onRemoveCard($event)"
    ></user-credit-card>
  `,
  providers: buildForwardRef(CreditCardInputComponent)
})
export class CreditCardInputComponent extends GenericControlValueInput<PaymentCardFormData[]> {
  value: PaymentCardFormData[] = [];

  reset$ = new Subject<any>();

  get visibleCards(): PaymentCardFormData[] {
    return this.value
      .filter((paymentCardFormData: PaymentCardFormData) => !paymentCardFormData._destroy);
  }

  writeValue(val: PaymentCardFormData[]) {
    this.value = val;

    this.reset$.next();
  }

  onAddCard(cardData: PaymentCardFormData) {
    this.value.push(cardData);

    this.onChangedCallback(this.value);
  }

  onRemoveCard(removedCard: PaymentCardFormData) {
    const replacement = [],
          isOldCard = !!removedCard.id;

    const cardIndex = this.value.findIndex((card: PaymentCardFormData) => {
      if (!isOldCard && card.stripe_token != null) {
        return card.stripe_token === removedCard.stripe_token;
      }

      return card.id === removedCard.id;
    })

    if (isOldCard) {
      replacement.push({
        ...removedCard
      });
    }

    this.value = [
      ...this.value.slice(0, cardIndex),
      ...replacement,
      ...this.value.slice(cardIndex + 1)
    ];

    this.onChangedCallback(this.value);
  }
}
