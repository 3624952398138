
export const Actions = {
  NO_OP: 'NO_OP',

  // Generic entities
  LOADED_ENTITIES: 'LOADED_ENTITIES',
  REMOVE_ENTITY: 'REMOVE_ENTITY',

  SET_APP_OPERATION_MODE: 'SET_APP_OPERATION_MODE',

  // User details and auth
  REQUEST_VALIDATE_TOKEN: 'REQUEST_VALIDATE_TOKEN',
  REQUEST_VALIDATE_TOKEN_SUCCESS: 'REQUEST_VALIDATE_TOKEN_SUCCESS',
  REQUEST_VALIDATE_TOKEN_ERROR: 'REQUEST_VALIDATE_TOKEN_ERROR',
  REQUEST_USER_SIGN_IN: 'REQUEST_USER_SIGN_IN',
  REQUEST_USER_SIGN_IN_SUCCESS: 'REQUEST_USER_SIGN_IN_SUCCESS',
  REQUEST_USER_SIGN_IN_ERROR: 'REQUEST_USER_SIGN_IN_ERROR',
  REQUEST_USER_SIGN_OUT: 'REQUEST_USER_SIGN_OUT',
  REQUEST_USER_SIGN_OUT_SUCCESS: 'REQUEST_USER_SIGN_OUT_SUCCESS',
  REQUEST_USER_RESET_PASSWORD: 'REQUEST_USER_RESET_PASSWORD',
  REQUEST_USER_RESET_PASSWORD_SUCCESS: 'REQUEST_USER_RESET_PASSWORD_SUCCESS',
  REQUEST_USER_RESET_PASSWORD_ERROR: 'REQUEST_USER_RESET_PASSWORD_ERROR',
  REQUEST_USER_FORGOT_PASSWORD: 'REQUEST_USER_FORGOT_PASSWORD',
  REQUEST_USER_FORGOT_PASSWORD_SUCCESS: 'REQUEST_USER_FORGOT_PASSWORD_SUCCESS',
  REQUEST_USER_FORGOT_PASSWORD_ERROR: 'REQUEST_USER_FORGOT_PASSWORD_ERROR',
  REQUEST_USER_DETAILS_UPDATE: 'REQUEST_USER_DETAILS_UPDATE',
  REQUEST_USER_DETAILS_UPDATE_SUCCESS: 'REQUEST_USER_DETAILS_UPDATE_SUCCESS',
  REQUEST_USER_DETAILS_UPDATE_ERROR: 'REQUEST_USER_DETAILS_UPDATE_ERROR',
  REQUEST_USER_AVATAR_UPDATE: 'REQUEST_USER_AVATAR_UPDATE',
  REQUEST_USER_AVATAR_UPDATE_SUCCESS: 'REQUEST_USER_AVATAR_UPDATE_SUCCESS',
  REQUEST_USER_AVATAR_UPDATE_ERROR: 'REQUEST_USER_AVATAR_UPDATE_ERROR',
  REQUEST_USER_PASSWORD_UPDATE: 'REQUEST_USER_PASSWORD_UPDATE',
  REQUEST_USER_PASSWORD_UPDATE_SUCCESS: 'REQUEST_USER_PASSWORD_UPDATE_SUCCESS',
  REQUEST_UPDATE_ARCHIVE_STATUS: 'REQUEST_UPDATE_ARCHIVE_STATUS',
  REQUEST_UPDATE_ARCHIVE_STATUS_SUCCESS: 'REQUEST_UPDATE_ARCHIVE_STATUS_SUCCESS',
  REQUEST_UPDATE_ARCHIVE_STATUS_ERROR: 'REQUEST_UPDATE_ARCHIVE_STATUS_ERROR',
  REQUEST_USER_DESTROY: 'REQUEST_USER_DESTROY',
  REQUEST_USER_DESTROY_SUCCESS: 'REQUEST_USER_DESTROY_SUCCESS',
  REQUEST_USER_DESTROY_ERROR: 'REQUEST_USER_DESTROY_ERROR',
  REQUEST_USER_RESEND_INVITE: 'REQUEST_USER_RESEND_INVITE',
  REQUEST_USER_RESEND_INVITE_SUCCESS: 'REQUEST_USER_RESEND_INVITE_SUCCESS',
  REQUEST_USER_RESEND_INVITE_ERROR: 'REQUEST_USER_RESEND_INVITE_ERROR',
  UPDATE_CURRENT_USER: 'UPDATE_CURRENT_USER',

  REQUEST_INVITE_BAKERY: 'REQUEST_INVITE_BAKERY',
  REQUEST_INVITE_BAKERY_SUCCESS: 'REQUEST_INVITE_BAKERY_SUCCESS',
  REQUEST_INVITE_BAKERY_ERROR: 'REQUEST_INVITE_BAKERY_ERROR',

  // Bakery self-management
  REQUEST_OWN_BAKERY: 'REQUEST_OWN_BAKERY',
  REQUEST_OWN_BAKERY_SUCCESS: 'REQUEST_OWN_BAKERY_SUCCESS',
  REQUEST_DESTROY_OWN_BAKERY: 'REQUEST_DESTROY_OWN_BAKERY',
  REQUEST_DESTROY_OWN_BAKERY_SUCCESS: 'REQUEST_DESTROY_OWN_BAKERY_SUCCESS',
  REQUEST_DESTROY_OWN_BAKERY_ERROR: 'REQUEST_DESTROY_OWN_BAKERY_ERROR',
  REQUEST_UPDATE_OWN_BAKERY: 'REQUEST_UPDATE_OWN_BAKERY',
  REQUEST_UPDATE_OWN_BAKERY_SUCCESS: 'REQUEST_UPDATE_OWN_BAKERY_SUCCESS',
  REQUEST_UPDATE_OWN_BAKERY_ERROR: 'REQUEST_UPDATE_OWN_BAKERY_ERROR',
  CHANGE_BAKERY_EMPLOYEES_ARCHIVE_FILTER: 'CHANGE_BAKERY_EMPLOYEES_ARCHIVE_FILTER',
  CHANGE_BAKERY_EMPLOYEES_SEARCH_FILTER: 'CHANGE_BAKERY_EMPLOYEES_SEARCH_FILTER',
  REQUEST_BAKERY_EMPLOYEES: 'REQUEST_BAKERY_EMPLOYEES',
  REQUEST_BAKERY_EMPLOYEES_SUCCESS: 'REQUEST_BAKERY_EMPLOYEES_SUCCESS',
  REQUEST_BAKERY_EMPLOYEE: 'REQUEST_BAKERY_EMPLOYEE',
  REQUEST_BAKERY_EMPLOYEE_SUCCESS: 'REQUEST_BAKERY_EMPLOYEE_SUCCESS',
  REQUEST_BAKERY_INVITE_EMPLOYEE: 'REQUEST_BAKERY_INVITE_EMPLOYEE',
  REQUEST_BAKERY_INVITE_EMPLOYEE_SUCCESS: 'REQUEST_BAKERY_INVITE_EMPLOYEE_SUCCESS',
  REQUEST_BAKERY_INVITE_EMPLOYEE_ERROR: 'REQUEST_BAKERY_INVITE_EMPLOYEE_ERROR',
  REQUEST_EMPLOYEE_DETAILS_UPDATE: 'REQUEST_EMPLOYEE_DETAILS_UPDATE',
  REQUEST_EMPLOYEE_DETAILS_UPDATE_SUCCESS: 'REQUEST_EMPLOYEE_DETAILS_UPDATE_SUCCESS',
  REQUEST_EMPLOYEE_DETAILS_UPDATE_ERROR: 'REQUEST_EMPLOYEE_DETAILS_UPDATE_ERROR',
  REQUEST_GET_BAKERY_CUSTOMERS: 'REQUEST_GET_BAKERY_CUSTOMERS',
  REQUEST_GET_BAKERY_CUSTOMERS2: 'REQUEST_GET_BAKERY_CUSTOMERS2',
  REQUEST_GET_BAKERY_CUSTOMERS_SUCCESS: 'REQUEST_GET_BAKERY_CUSTOMERS_SUCCESS',
  REQUEST_GET_BAKERY_CUSTOMERS_SUCCESS2: 'REQUEST_GET_BAKERY_CUSTOMERS_SUCCESS2',
  REQUEST_GET_BAKERY_CUSTOMERS_ERROR: 'REQUEST_GET_BAKERY_CUSTOMERS_ERROR',
  REQUEST_GET_BAKERY_CUSTOMERS_ERROR2: 'REQUEST_GET_BAKERY_CUSTOMERS_ERROR2',
  REQUEST_GET_BAKERY_CUSTOMER: 'REQUEST_GET_BAKERY_CUSTOMER',
  REQUEST_GET_BAKERY_CUSTOMER_SUCCESS: 'REQUEST_GET_BAKERY_CUSTOMER_SUCCESS',
  REQUEST_GET_BAKERY_CUSTOMER_ERROR: 'REQUEST_GET_BAKERY_CUSTOMER_ERROR',
  REQUEST_BAKERY_INVITE_CUSTOMER: 'REQUEST_BAKERY_INVITE_CUSTOMER',
  REQUEST_BAKERY_INVITE_CUSTOMER_SUCCESS: 'REQUEST_BAKERY_INVITE_CUSTOMER_SUCCESS',
  REQUEST_BAKERY_INVITE_CUSTOMER_ERROR: 'REQUEST_BAKERY_INVITE_CUSTOMER_ERROR',
  CHANGE_BAKERY_CUSTOMERS_SEARCH_FILTER: 'CHANGE_BAKERY_CUSTOMERS_SEARCH_FILTER',
  CHANGE_BAKERY_CUSTOMERS_ARCHIVE_FILTER: 'CHANGE_BAKERY_CUSTOMERS_ARCHIVE_FILTER',
  REQUEST_BAKERY_LOCATIONS: 'REQUEST_BAKERY_LOCATIONS',
  REQUEST_BAKERY_LOCATIONS_SUCCESS: 'REQUEST_BAKERY_LOCATIONS_SUCCESS',
  REQUEST_BAKERY_LOCATION: 'REQUEST_BAKERY_LOCATION',
  REQUEST_BAKERY_LOCATION_SUCCESS: 'REQUEST_BAKERY_LOCATION_SUCCESS',
  REQUEST_DESTROY_BAKERY_LOCATION: 'REQUEST_DESTROY_BAKERY_LOCATION',
  REQUEST_DESTROY_BAKERY_LOCATION_SUCCESS: 'REQUEST_DESTROY_BAKERY_LOCATION_SUCCESS',
  REQUEST_DESTROY_BAKERY_LOCATION_ERROR: 'REQUEST_DESTROY_BAKERY_LOCATION_ERROR',
  REQUEST_UPDATE_BAKERY_LOCATION: 'REQUEST_UPDATE_BAKERY_LOCATION',
  REQUEST_UPDATE_BAKERY_LOCATION_SUCCESS: 'REQUEST_UPDATE_BAKERY_LOCATION_SUCCESS',
  REQUEST_UPDATE_BAKERY_LOCATION_ERROR: 'REQUEST_UPDATE_BAKERY_LOCATION_ERROR',
  REQUEST_CREATE_BAKERY_LOCATION: 'REQUEST_CREATE_BAKERY_LOCATION',
  REQUEST_CREATE_BAKERY_LOCATION_SUCCESS: 'REQUEST_CREATE_BAKERY_LOCATION_SUCCESS',
  REQUEST_CREATE_BAKERY_LOCATION_ERROR: 'REQUEST_CREATE_BAKERY_LOCATION_ERROR',
  REQUEST_GET_SUBSCRIBE: 'REQUEST_GET_SUBSCRIBE',
  REQUEST_GET_SUBSCRIBE_SUCCESS: 'REQUEST_GET_SUBSCRIBE_SUCCESS',
  REQUEST_GET_SUBSCRIBE_ERROR: 'REQUEST_GET_SUBSCRIBE_ERROR',
  REQUEST_UPDATE_SUBSCRIBE: 'REQUEST_UPDATE_SUBSCRIBE',
  REQUEST_UPDATE_SUBSCRIBE_SUCCESS: 'REQUEST_UPDATE_SUBSCRIBE_SUCCESS',
  REQUEST_UPDATE_SUBSCRIBE_ERROR: 'REQUEST_UPDATE_SUBSCRIBE_ERROR',
  REQUEST_TAX_REPORT: 'REQUEST_TAX_REPORT',
  REQUEST_TAX_REPORT_SUCCESS: 'REQUEST_TAX_REPORT_SUCCESS',
  REQUEST_TAX_REPORT_ERROR: 'REQUEST_TAX_REPORT_ERROR',
  REQUEST_PRINTABLE_TAX_REPORT: 'REQUEST_PRINTABLE_TAX_REPORT',
  REQUEST_PRINTABLE_TAX_REPORT_SUCCESS: 'REQUEST_PRINTABLE_TAX_REPORT_SUCCESS',
  REQUEST_PRINTABLE_TAX_REPORT_ERROR: 'REQUEST_PRINTABLE_TAX_REPORT_ERROR',
  REQUEST_PRODUCTION_CHECKLIST: 'REQUEST_PRODUCTION_CHECKLIST',
  REQUEST_PRODUCTION_CHECKLIST_SUCCESS: 'REQUEST_PRODUCTION_CHECKLIST_SUCCESS',
  REQUEST_PRODUCTION_CHECKLIST_ERROR: 'REQUEST_PRODUCTION_CHECKLIST_ERROR',
  REQUEST_PRINTABLE_PRODUCTION_CHECKLIST: 'REQUEST_PRINTABLE_PRODUCTION_CHECKLIST',
  REQUEST_PRINTABLE_PRODUCTION_CHECKLIST_SUCCESS: 'REQUEST_PRINTABLE_PRODUCTION_CHECKLIST_SUCCESS',
  REQUEST_PRINTABLE_PRODUCTION_CHECKLIST_ERROR: 'REQUEST_PRINTABLE_PRODUCTION_CHECKLIST_ERROR',
  GET_BAKERY_LOCATIONS: 'GET_BAKERY_LOCATIONS',
  GET_BAKERY_LOCATIONS_SUCCESS: 'GET_BAKERY_LOCATIONS_SUCCESS',
  GET_BAKERY_LOCATIONS_ERROR: 'GET_BAKERY_LOCATIONS_ERROR',
  REQUEST_ITEM_SUMMARY: 'REQUEST_ITEM_SUMMARY',
  REQUEST_ITEM_SUMMARY_SUCCESS: 'REQUEST_ITEM_SUMMARY_SUCCESS',
  REQUEST_ITEM_SUMMARY_ERROR: 'REQUEST_ITEM_SUMMARY_ERROR',
  REQUEST_DECORATING_REPORT: 'REQUEST_DECORATING_REPORT',
  REQUEST_DECORATING_REPORT_SUCCESS: 'REQUEST_DECORATING_REPORT_SUCCESS',
  REQUEST_DECORATING_REPORT_ERROR: 'REQUEST_DECORATING_REPORT_ERROR',
  REQUEST_ATTRIBUTE_SUMMARY: 'REQUEST_ATTRIBUTE_SUMMARY',
  REQUEST_ATTRIBUTE_SUMMARY_SUCCESS: 'REQUEST_ATTRIBUTE_SUMMARY_SUCCESS',
  REQUEST_ATTRIBUTE_SUMMARY_ERROR: 'REQUEST_ATTRIBUTE_SUMMARY_ERROR',

  // Bakery Mgmt - Promo Code Mgmt
  REQUEST_GET_PROMO_CODE: 'REQUEST_GET_PROMO_CODE',
  REQUEST_GET_PROMO_CODES: 'REQUEST_GET_PROMO_CODES',
  REQUEST_GET_PROMO_CODES_SUCCESS: 'REQUEST_GET_PROMO_CODES_SUCCESS',
  REQUEST_GET_PROMO_CODES_ERROR: 'REQUEST_GET_PROMO_CODES_ERROR',
  CHANGE_PROMO_CODES_STATUS_FILTER: 'CHANGE_PROMO_CODES_STATUS_FILTER',
  CHANGE_PROMO_CODES_SEARCH_FILTER: 'CHANGE_PROMO_CODES_SEARCH_FILTER',
  REQUEST_CREATE_PROMO_CODE: 'REQUEST_CREATE_PROMO_CODE',
  REQUEST_CREATE_PROMO_CODE_SUCCESS: 'REQUEST_CREATE_PROMO_CODE_SUCCESS',
  REQUEST_CREATE_PROMO_CODE_ERROR: 'REQUEST_CREATE_PROMO_CODE_ERROR',
  REQUEST_UPDATE_PROMO_CODE: 'REQUEST_UPDATE_PROMO_CODE',
  REQUEST_UPDATE_PROMO_CODE_SUCCESS: 'REQUEST_UPDATE_PROMO_CODE_SUCCESS',
  REQUEST_UPDATE_PROMO_CODE_ERROR: 'REQUEST_UPDATE_PROMO_CODE_ERROR',
  REQUEST_DELETE_PROMO_CODE: 'REQUEST_DELETE_PROMO_CODE',
  REQUEST_DELETE_PROMO_CODE_SUCCESS: 'REQUEST_DELETE_PROMO_CODE_SUCCESS',
  REQUEST_DELETE_PROMO_CODE_ERROR: 'REQUEST_DELETE_PROMO_CODE_ERROR',
  REQUEST_ADD_PROMO_CODE_FOR_BAKERY: 'REQUEST_ADD_PROMO_CODE_FOR_BAKERY',
  REQUEST_ADD_PROMO_CODE_FOR_BAKERY_SUCCESS: 'REQUEST_ADD_PROMO_CODE_FOR_BAKERY_SUCCESS',
  REQUEST_REMOVE_PROMO_CODE_FOR_BAKERY: 'REQUEST_REMOVE_PROMO_CODE_FOR_BAKERY',
  REQUEST_REMOVE_PROMO_CODE_FOR_BAKERY_SUCCESS: 'REQUEST_REMOVE_PROMO_CODE_FOR_BAKERY_SUCCESS',
  REQUEST_REMOVE_PROMO_CODE_FOR_BAKERY_ERROR: 'REQUEST_REMOVE_PROMO_CODE_FOR_BAKERY_ERROR',
  REQUEST_ADD_PROMO_CODE_FOR_RETAIL: 'REQUEST_ADD_PROMO_CODE_FOR_RETAIL',
  REQUEST_ADD_PROMO_CODE_FOR_RETAIL_SUCCESS: 'REQUEST_ADD_PROMO_CODE_FOR_RETAIL_SUCCESS',
  REQUEST_REMOVE_PROMO_CODE_FOR_RETAIL: 'REQUEST_REMOVE_PROMO_CODE_FOR_RETAIL',
  REQUEST_REMOVE_PROMO_CODE_FOR_RETAIL_SUCCESS: 'REQUEST_REMOVE_PROMO_CODE_FOR_RETAIL_SUCCESS',
  REQUEST_REMOVE_PROMO_CODE_FOR_RETAIL_ERROR: 'REQUEST_REMOVE_PROMO_CODE_FOR_RETAIL_ERROR',

  // Bakery Mgmt - Product Mgmt
  REQUEST_GET_BAKERY_PRODUCT: 'REQUEST_GET_BAKERY_PRODUCT',
  REQUEST_GET_BAKERY_PRODUCT_SUCCESS: 'REQUEST_GET_BAKERY_PRODUCT_SUCCESS',
  REQUEST_GET_BAKERY_PRODUCT_ERROR: 'REQUEST_GET_BAKERY_PRODUCT_ERROR',
  REQUEST_GET_BAKERY_PRODUCTS: 'REQUEST_GET_BAKERY_PRODUCTS',
  REQUEST_GET_BAKERY_PRODUCTS2: 'REQUEST_GET_BAKERY_PRODUCTS2',
  REQUEST_GET_BAKERY_PRODUCTS_FOR_WHOLESALE: 'REQUEST_GET_BAKERY_PRODUCTS_FOR_WHOLESALE',
  REQUEST_GET_BAKERY_PRODUCTS_SUCCESS: 'REQUEST_GET_BAKERY_PRODUCTS_SUCCESS',
  REQUEST_GET_BAKERY_PRODUCTS_SUCCESS2: 'REQUEST_GET_BAKERY_PRODUCTS_SUCCESS2',
  REQUEST_GET_BAKERY_PRODUCTS_ERROR2: 'REQUEST_GET_BAKERY_PRODUCTS_ERROR2',
  REQUEST_CREATE_BAKERY_PRODUCT: 'REQUEST_CREATE_BAKERY_PRODUCT',
  REQUEST_CREATE_BAKERY_PRODUCT_SUCCESS: 'REQUEST_CREATE_BAKERY_PRODUCT_SUCCESS',
  REQUEST_CREATE_BAKERY_PRODUCT_ERROR: 'REQUEST_CREATE_BAKERY_PRODUCT_ERROR',
  REQUEST_UPDATE_BAKERY_PRODUCT: 'REQUEST_UPDATE_BAKERY_PRODUCT',
  REQUEST_UPDATE_BAKERY_PRODUCT_SUCCESS: 'REQUEST_UPDATE_BAKERY_PRODUCT_SUCCESS',
  REQUEST_UPDATE_BAKERY_PRODUCT_ERROR: 'REQUEST_UPDATE_BAKERY_PRODUCT_ERROR',
  REQUEST_DELETE_BAKERY_PRODUCT: 'REQUEST_DELETE_BAKERY_PRODUCT',
  REQUEST_DELETE_BAKERY_PRODUCT_SUCCESS: 'REQUEST_DELETE_BAKERY_PRODUCT_SUCCESS',
  REQUEST_DELETE_BAKERY_PRODUCT_ERROR: 'REQUEST_DELETE_BAKERY_PRODUCT_ERROR',
  REQUEST_GET_PRODUCT_CATEGORIES: 'REQUEST_GET_PRODUCT_CATEGORIES',
  REQUEST_GET_PRODUCT_CATEGORIES_SUCCESS: 'REQUEST_GET_PRODUCT_CATEGORIES_SUCCESS',
  REQUEST_GET_PRODUCT_CATEGORIES_ERROR: 'REQUEST_GET_PRODUCT_CATEGORIES_ERROR',
  REQUEST_CREATE_PRODUCT_CATEGORY: 'REQUEST_CREATE_PRODUCT_CATEGORY',
  REQUEST_CREATE_PRODUCT_CATEGORY_SUCCESS: 'REQUEST_CREATE_PRODUCT_CATEGORY_SUCCESS',
  REQUEST_CREATE_PRODUCT_CATEGORY_ERROR: 'REQUEST_CREATE_PRODUCT_CATEGORY_ERROR',
  REQUEST_GET_PRODUCT_CATEGORY: 'REQUEST_GET_PRODUCT_CATEGORY',
  REQUEST_GET_PRODUCT_CATEGORY_SUCCESS: 'REQUEST_GET_PRODUCT_CATEGORY_SUCCESS',
  REQUEST_GET_PRODUCT_CATEGORY_ERROR: 'REQUEST_GET_PRODUCT_CATEGORY_ERROR',
  REQUEST_UPDATE_PRODUCT_CATEGORY: 'REQUEST_UPDATE_PRODUCT_CATEGORY',
  REQUEST_UPDATE_PRODUCT_CATEGORY_SUCCESS: 'REQUEST_UPDATE_PRODUCT_CATEGORY_SUCCESS',
  REQUEST_UPDATE_PRODUCT_CATEGORY_ERROR: 'REQUEST_UPDATE_PRODUCT_CATEGORY_ERROR',
  REQUEST_DELETE_PRODUCT_CATEGORY: 'REQUEST_DELETE_PRODUCT_CATEGORY',
  REQUEST_DELETE_PRODUCT_CATEGORY_SUCCESS: 'REQUEST_DELETE_PRODUCT_CATEGORY_SUCCESS',
  REQUEST_DELETE_PRODUCT_CATEGORY_ERROR: 'REQUEST_DELETE_PRODUCT_CATEGORY_ERROR',
  REQUEST_GET_BKRY_MGMT_STORE_PAYMENT_CONFIG: 'REQUEST_GET_BKRY_MGMT_STORE_PAYMENT_CONFIG',
  REQUEST_GET_BKRY_MGMT_STORE_PAYMENT_CONFIG_SUCCESS: 'REQUEST_GET_BKRY_MGMT_STORE_PAYMENT_CONFIG_SUCCESS',
  REQUEST_GET_BKRY_MGMT_STORE_PAYMENT_CONFIG_ERROR: 'REQUEST_GET_BKRY_MGMT_STORE_PAYMENT_CONFIG_ERROR',
  REQUEST_UPDATE_PAYMENT_PROVIDER_CONFIG: 'REQUEST_UPDATE_PAYMENT_PROVIDER_CONFIG',
  REQUEST_UPDATE_PAYMENT_PROVIDER_CONFIG_SUCCESS: 'REQUEST_UPDATE_PAYMENT_PROVIDER_CONFIG_SUCCESS',
  REQUEST_UPDATE_PAYMENT_PROVIDER_CONFIG_ERROR: 'REQUEST_UPDATE_PAYMENT_PROVIDER_CONFIG_ERROR',
  REQUEST_GET_BAKERY_SUBSCRIPTION_INVOICES: 'REQUEST_GET_BAKERY_SUBSCRIPTION_INVOICES',
  REQUEST_GET_BAKERY_SUBSCRIPTION_INVOICE: 'REQUEST_GET_BAKERY_SUBSCRIPTION_INVOICE',
  REQUEST_GET_BAKERY_SUBSCRIPTION_INVOICE_SUCCESS: 'REQUEST_GET_BAKERY_SUBSCRIPTION_INVOICE_SUCCESS',
  REQUEST_GET_BAKERY_SUBSCRIPTION_INVOICES_SUCCESS: 'REQUEST_GET_BAKERY_SUBSCRIPTION_INVOICES_SUCCESS',
  REQUEST_GET_BAKERY_SUBSCRIPTION_INVOICE_ERROR: 'REQUEST_GET_BAKERY_SUBSCRIPTION_INVOICE_ERROR',
  REQUEST_GET_BAKERY_SUBSCRIPTION_INVOICES_ERROR: 'REQUEST_GET_BAKERY_SUBSCRIPTION_INVOICES_ERROR',

  REQUEST_GET_PRODUCT_CATEGORY_DETAILS: 'REQUEST_GET_PRODUCT_CATEGORY_DETAILS',
  REQUEST_GET_PRODUCT_CATEGORY_DETAILS_SUCCESS: 'REQUEST_GET_PRODUCT_CATEGORY_DETAILS_SUCCESS',
  REQUEST_GET_PRODUCT_CATEGORY_DETAILS_ERROR: 'REQUEST_GET_PRODUCT_CATEGORY_DETAILS_ERROR',

  REQUEST_GET_BAKERY_PRODUCT_TEMPLATE: 'REQUEST_GET_BAKERY_PRODUCT_TEMPLATE',
  REQUEST_GET_BAKERY_PRODUCT_TEMPLATE_SUCCESS: 'REQUEST_GET_BAKERY_PRODUCT_TEMPLATE_SUCCESS',
  REQUEST_GET_BAKERY_PRODUCT_TEMPLATE_ERROR: 'REQUEST_GET_BAKERY_PRODUCT_TEMPLATE_ERROR',
  REQUEST_GET_BAKERY_PRODUCT_TEMPLATES: 'REQUEST_GET_BAKERY_PRODUCT_TEMPLATES',
  REQUEST_GET_BAKERY_PRODUCT_TEMPLATES_SUCCESS: 'REQUEST_GET_BAKERY_PRODUCT_TEMPLATES_SUCCESS',
  REQUEST_GET_BAKERY_PRODUCT_TEMPLATES_ERROR: 'REQUEST_GET_BAKERY_PRODUCT_TEMPLATES_ERROR',
  REQUEST_CREATE_BAKERY_PRODUCT_TEMPLATE: 'REQUEST_CREATE_BAKERY_PRODUCT_TEMPLATE',
  REQUEST_CREATE_BAKERY_PRODUCT_TEMPLATE_SUCCESS: 'REQUEST_CREATE_BAKERY_PRODUCT_TEMPLATE_SUCCESS',
  REQUEST_CREATE_BAKERY_PRODUCT_TEMPLATE_ERROR: 'REQUEST_CREATE_BAKERY_PRODUCT_TEMPLATE_ERROR',
  REQUEST_UPDATE_BAKERY_PRODUCT_TEMPLATE: 'REQUEST_UPDATE_BAKERY_PRODUCT_TEMPLATE',
  REQUEST_UPDATE_BAKERY_PRODUCT_TEMPLATE_SUCCESS: 'REQUEST_UPDATE_BAKERY_PRODUCT_TEMPLATE_SUCCESS',
  REQUEST_UPDATE_BAKERY_PRODUCT_TEMPLATE_ERROR: 'REQUEST_UPDATE_BAKERY_PRODUCT_TEMPLATE_ERROR',
  REQUEST_DELETE_BAKERY_PRODUCT_TEMPLATE: 'REQUEST_DELETE_BAKERY_PRODUCT_TEMPLATE',
  REQUEST_DELETE_BAKERY_PRODUCT_TEMPLATE_SUCCESS: 'REQUEST_DELETE_BAKERY_PRODUCT_TEMPLATE_SUCCESS',
  REQUEST_DELETE_BAKERY_PRODUCT_TEMPLATE_ERROR: 'REQUEST_DELETE_BAKERY_PRODUCT_TEMPLATE_ERROR',
  REQUEST_GET_BAKERY_PRODUCT_TEMPLATE_DETAILS: 'REQUEST_GET_BAKERY_PRODUCT_TEMPLATE_DETAILS',
  REQUEST_GET_BAKERY_PRODUCT_TEMPLATE_DETAILS_SUCCESS: 'REQUEST_GET_BAKERY_PRODUCT_TEMPLATE_DETAILS_SUCCESS',
  REQUEST_GET_BAKERY_PRODUCT_TEMPLATE_DETAILS_ERROR: 'REQUEST_GET_BAKERY_PRODUCT_TEMPLATE_DETAILS_ERROR',

  REQUEST_GET_BAKERY_ATTRIBUTE: 'REQUEST_GET_BAKERY_ATTRIBUTE',
  REQUEST_GET_BAKERY_ATTRIBUTE_SUCCESS: 'REQUEST_GET_BAKERY_ATTRIBUTE_SUCCESS',
  REQUEST_GET_BAKERY_ATTRIBUTE_ERROR: 'REQUEST_GET_BAKERY_ATTRIBUTE_ERROR',
  REQUEST_GET_BAKERY_ATTRIBUTES: 'REQUEST_GET_BAKERY_ATTRIBUTES',
  REQUEST_GET_BAKERY_ATTRIBUTES_SUCCESS: 'REQUEST_GET_BAKERY_ATTRIBUTES_SUCCESS',
  REQUEST_GET_BAKERY_ATTRIBUTES_ERROR: 'REQUEST_GET_BAKERY_ATTRIBUTES_ERROR',
  REQUEST_CREATE_BAKERY_ATTRIBUTE: 'REQUEST_CREATE_BAKERY_ATTRIBUTE',
  REQUEST_CREATE_BAKERY_ATTRIBUTE_SUCCESS: 'REQUEST_CREATE_BAKERY_ATTRIBUTE_SUCCESS',
  REQUEST_CREATE_BAKERY_ATTRIBUTE_ERROR: 'REQUEST_CREATE_BAKERY_ATTRIBUTE_ERROR',
  REQUEST_UPDATE_BAKERY_ATTRIBUTE: 'REQUEST_UPDATE_BAKERY_ATTRIBUTE',
  REQUEST_UPDATE_BAKERY_ATTRIBUTE_SUCCESS: 'REQUEST_UPDATE_BAKERY_ATTRIBUTE_SUCCESS',
  REQUEST_UPDATE_BAKERY_ATTRIBUTE_ERROR: 'REQUEST_UPDATE_BAKERY_ATTRIBUTE_ERROR',
  REQUEST_DELETE_BAKERY_ATTRIBUTE: 'REQUEST_DELETE_BAKERY_ATTRIBUTE',
  REQUEST_DELETE_BAKERY_ATTRIBUTE_SUCCESS: 'REQUEST_DELETE_BAKERY_ATTRIBUTE_SUCCESS',
  REQUEST_DELETE_BAKERY_ATTRIBUTE_ERROR: 'REQUEST_DELETE_BAKERY_ATTRIBUTE_ERROR',
  REQUEST_GET_BAKERY_ATTRIBUTE_DETAILS: 'REQUEST_GET_BAKERY_ATTRIBUTE_DETAILS',
  REQUEST_GET_BAKERY_ATTRIBUTE_DETAILS_SUCCESS: 'REQUEST_GET_BAKERY_ATTRIBUTE_DETAILS_SUCCESS',
  REQUEST_GET_BAKERY_ATTRIBUTE_DETAILS_ERROR: 'REQUEST_GET_BAKERY_ATTRIBUTE_DETAILS_ERROR',

  // Bakery Mgmt - Order Mgmt
  CHANGE_BKRY_MGMT_ORDER_MGMT_ORDERS_FILTERS: 'CHANGE_BKRY_MGMT_ORDER_MGMT_ORDERS_FILTERS',
  REQUEST_GET_BKRY_MGMT_ORDER_MGMT_ORDERS: 'REQUEST_GET_BKRY_MGMT_ORDER_MGMT_ORDERS',
  REQUEST_GET_BKRY_MGMT_ORDER_MGMT_ORDERS_SUCCESS: 'REQUEST_GET_BKRY_MGMT_ORDER_MGMT_ORDERS_SUCCESS',
  REQUEST_GET_BKRY_MGMT_ORDER_MGMT_ORDERS_ERROR: 'REQUEST_GET_BKRY_MGMT_ORDER_MGMT_ORDERS_ERROR',
  REQUEST_GET_BKRY_MGMT_ORDER_MGMT_ORDERS_SUCCESS2: 'REQUEST_GET_BKRY_MGMT_ORDER_MGMT_ORDERS_SUCCESS2',
  REQUEST_GET_BKRY_MGMT_ORDER_MGMT_ORDERS_ERROR2: 'REQUEST_GET_BKRY_MGMT_ORDER_MGMT_ORDERS_ERROR2',
  REQUEST_GET_BKRY_MGMT_ORDER_MGMT_ORDER: 'REQUEST_GET_BKRY_MGMT_ORDER_MGMT_ORDER',
  REQUEST_GET_BKRY_MGMT_ORDER_MGMT_ORDER_SUCCESS: 'REQUEST_GET_BKRY_MGMT_ORDER_MGMT_ORDER_SUCCESS',
  REQUEST_GET_BKRY_MGMT_ORDER_MGMT_ORDER_ERROR: 'REQUEST_GET_BKRY_MGMT_ORDER_MGMT_ORDER_ERROR',

  REQUEST_BKRY_MGMT_ORDER_MGMT_CHANGE_ORDER_STATUS: 'REQUEST_BKRY_MGMT_ORDER_MGMT_CHANGE_ORDER_STATUS',
  REQUEST_BKRY_MGMT_ORDER_MGMT_CHANGE_ORDER_STATUS_SUCCESS: 'REQUEST_BKRY_MGMT_ORDER_MGMT_CHANGE_ORDER_STATUS_SUCCESS',
  REQUEST_BKRY_MGMT_ORDER_MGMT_CHANGE_ORDER_STATUS_ERROR: 'REQUEST_BKRY_MGMT_ORDER_MGMT_CHANGE_ORDER_STATUS_ERROR',
  BKRY_MGMT_ORDER_MGMT_LIST_ORDER_STATUS_CHANGE: 'BKRY_MGMT_ORDER_MGMT_LIST_ORDER_STATUS_CHANGE',
  SET_BKRY_MGMT_ORDER_MGMT_ORDER_DETAILS_NOTES_VISIBILITY: 'SET_BKRY_MGMT_ORDER_MGMT_ORDER_DETAILS_NOTES_VISIBILITY',
  REQUEST_UPDATE_BKRY_MGMT_ORDER_MGMT_ORDER_DETAILS: 'REQUEST_UPDATE_BKRY_MGMT_ORDER_MGMT_ORDER_DETAILS',
  REQUEST_UPDATE_ORDER_ITEM: 'REQUEST_UPDATE_ORDER_ITEM',
  REQUEST_CANCEL_UPDATE_ORDER_ITEM: 'REQUEST_CANCEL_UPDATE_ORDER_ITEM',
  REQUEST_START_UPDATE_ORDER_ITEM: 'REQUEST_START_UPDATE_ORDER_ITEM',
  REQUEST_UPDATE_BKRY_MGMT_ORDER_MGMT_ORDER_DETAILS2: 'REQUEST_UPDATE_BKRY_MGMT_ORDER_MGMT_ORDER_DETAILS2',
  REQUEST_UPDATE_BKRY_MGMT_ORDER_MGMT_ORDER_DETAILS_SUCCESS: 'REQUEST_UPDATE_BKRY_MGMT_ORDER_MGMT_ORDER_DETAILS_SUCCESS',
  REQUEST_UPDATE_BKRY_MGMT_ORDER_MGMT_ORDER_DETAILS_ERROR: 'REQUEST_UPDATE_BKRY_MGMT_ORDER_MGMT_ORDER_DETAILS_ERROR',
  BAKERY_ORDER_REFUND_REQUESTED: 'BAKERY_ORDER_REFUND_REQUESTED',
  BAKERY_ORDER_REFUND_SUCCEEDED: 'BAKERY_ORDER_REFUND_SUCCEEDED',
  BAKERY_ORDER_REFUND_FAILED: 'BAKERY_ORDER_REFUND_FAILED',

  // Bakery Mgmt - Dashboard
  REQUEST_GET_BKRY_MGMT_DASHBOARD: 'REQUEST_GET_BKRY_MGMT_DASHBOARD',
  REQUEST_GET_BKRY_MGMT_DASHBOARD_SUCCESS: 'REQUEST_GET_BKRY_MGMT_DASHBOARD_SUCCESS',
  REQUEST_GET_BKRY_MGMT_DASHBOARD_ERROR: 'REQUEST_GET_BKRY_MGMT_DASHBOARD_ERROR',


  // Bakery Mgmt - Wholesalers
  CHANGE_BKRY_MGMT_WHOLESALERS_ORDERS_FILTERS: 'CHANGE_BKRY_MGMT_WHOLESALERS_ORDERS_FILTERS',
  REQUEST_GET_BKRY_MGMT_WHOLESALERS: 'REQUEST_GET_BKRY_MGMT_WHOLESALERS',
  REQUEST_GET_BKRY_MGMT_WHOLESALERS_SUCCESS: 'REQUEST_GET_BKRY_MGMT_WHOLESALERS_SUCCESS',
  REQUEST_GET_BKRY_WHOLESALER: 'REQUEST_GET_BKRY_WHOLESALER',
  REQUEST_GET_BKRY_WHOLESALER_SUCCESS: 'REQUEST_GET_BKRY_WHOLESALER_SUCCESS',
  REQUEST_INVITE_BKRY_WHOLESALER: 'REQUEST_INVITE_BKRY_WHOLESALER',
  REQUEST_INVITE_BKRY_WHOLESALER_SUCCESS: 'REQUEST_INVITE_BKRY_WHOLESALER_SUCCESS',
  REQUEST_INVITE_BKRY_WHOLESALER_ERROR: 'REQUEST_INVITE_BKRY_WHOLESALER_ERROR',

  // Bakery Mgmt - Wholesaler Groups
  CHANGE_BKRY_MGMT_WHOLESALER_GROUPS_FILTERS: 'CHANGE_BKRY_MGMT_WHOLESALER_GROUPS_FILTERS',
  REQUEST_GET_BKRY_MGMT_WHOLESALER_GROUPS: 'REQUEST_GET_BKRY_MGMT_WHOLESALER_GROUPS',
  REQUEST_GET_BKRY_MGMT_WHOLESALER_GROUPS_SUCCESS: 'REQUEST_GET_BKRY_MGMT_WHOLESALER_GROUPS_SUCCESS',
  REQUEST_CREATE_BKRY_WHOLESALER_GROUP: 'REQUEST_CREATE_BKRY_WHOLESALER_GROUP',
  REQUEST_CREATE_BKRY_WHOLESALER_GROUP_SUCCESS: 'REQUEST_CREATE_BKRY_WHOLESALER_GROUP_SUCCESS',
  REQUEST_CREATE_BKRY_WHOLESALER_GROUP_ERROR: 'REQUEST_CREATE_BKRY_WHOLESALER_GROUP_ERROR',
  REQUEST_GET_BKRY_MGMT_WHOLESALER_GROUP: 'REQUEST_GET_BKRY_MGMT_WHOLESALER_GROUP',
  REQUEST_GET_BKRY_MGMT_WHOLESALER_GROUP_SUCCESS: 'REQUEST_GET_BKRY_MGMT_WHOLESALER_GROUP_SUCCESS',

  REQUEST_UPDATE_BKRY_MGMT_WHOLESALER_GROUP: 'REQUEST_UPDATE_BKRY_MGMT_WHOLESALER_GROUP',
  REQUEST_UPDATE_BKRY_MGMT_WHOLESALER_GROUP_SUCCESS: 'REQUEST_UPDATE_BKRY_MGMT_WHOLESALER_GROUP_SUCCESS',
  REQUEST_UPDATE_BKRY_MGMT_WHOLESALER_GROUP_ERROR: 'REQUEST_UPDATE_BKRY_MGMT_WHOLESALER_GROUP_ERROR',
  REQUEST_DESTROY_BKRY_MGMT_WHOLESALER_GROUP: 'REQUEST_DESTROY_BKRY_MGMT_WHOLESALER_GROUP',
  REQUEST_DESTROY_BKRY_MGMT_WHOLESALER_GROUP_SUCCESS: 'REQUEST_DESTROY_BKRY_MGMT_WHOLESALER_GROUP_SUCCESS',
  REQUEST_DESTROY_BKRY_MGMT_WHOLESALER_GROUP_ERROR: 'REQUEST_DESTROY_BKRY_MGMT_WHOLESALER_GROUP_ERROR',
  REQUEST_GET_BKRY_MGMT_WHOLESALER_GROUP_ORGS: 'REQUEST_GET_BKRY_MGMT_WHOLESALER_GROUP_ORGS',
  REQUEST_GET_BKRY_MGMT_WHOLESALER_GROUP_ORGS_SUCCESS: 'REQUEST_GET_BKRY_MGMT_WHOLESALER_GROUP_ORGS_SUCCESS',
  REQUEST_GET_BKRY_MGMT_WHOLESALER_GROUP_ORGS_ERROR: 'REQUEST_GET_BKRY_MGMT_WHOLESALER_GROUP_ORGS_ERROR',
  REQUEST_GET_BKRY_MGMT_WHOLESALER_GROUP_PRODUCTS: 'REQUEST_GET_BKRY_MGMT_WHOLESALER_GROUP_PRODUCTS',
  REQUEST_GET_BKRY_MGMT_WHOLESALER_GROUP_PRODUCTS_SUCCESS: 'REQUEST_GET_BKRY_MGMT_WHOLESALER_GROUP_PRODUCTS_SUCCESS',
  REQUEST_GET_BKRY_MGMT_WHOLESALER_GROUP_PRODUCTS_ERROR: 'REQUEST_GET_BKRY_MGMT_WHOLESALER_GROUP_PRODUCTS_ERROR',

  // Employee New Order Form
  NEW_ORDER_STARTED: 'NEW_ORDER_STARTED',
  ADD_PRODUCT_TEMPLATE_TO_EMPLOYEE_CART: 'ADD_PRODUCT_TEMPLATE_TO_EMPLOYEE_CART',
  ADD_PRODUCT_TEMPLATE_TO_EMPLOYEE_CART_SUCCESS: 'ADD_PRODUCT_TEMPLATE_TO_EMPLOYEE_CART_SUCCESS',
  ADD_PRODUCT_TEMPLATE_TO_EMPLOYEE_CART_ERROR: 'ADD_PRODUCT_TEMPLATE_TO_EMPLOYEE_CART_ERROR',
  ADD_CUSTOMER_TO_EMPLOYEE_CART: 'ADD_CUSTOMER_TO_EMPLOYEE_CART',
  ADD_CUSTOMER_TO_EMPLOYEE_CART_SUCCESS: 'ADD_CUSTOMER_TO_EMPLOYEE_CART_SUCCESS',
  ADD_CUSTOMER_TO_EMPLOYEE_CART_ERROR: 'ADD_CUSTOMER_TO_EMPLOYEE_CART_ERROR',
  REMOVE_PRODUCT_TEMPLATE_TO_EMPLOYEE_CART: 'REMOVE_PRODUCT_TEMPLATE_TO_EMPLOYEE_CART',
  REMOVE_PRODUCT_TEMPLATE_TO_EMPLOYEE_CART_SUCCESS: 'REMOVE_PRODUCT_TEMPLATE_TO_EMPLOYEE_CART_SUCCESS',
  REMOVE_PRODUCT_TEMPLATE_TO_EMPLOYEE_CART_ERROR: 'REMOVE_PRODUCT_TEMPLATE_TO_EMPLOYEE_CART_ERROR',
  REQUEST_CREATE_EMPLOYEE_CART: 'REQUEST_CREATE_EMPLOYEE_CART',
  REQUEST_CREATE_EMPLOYEE_CART_SUCCESS: 'REQUEST_CREATE_EMPLOYEE_CART_SUCCESS',
  REQUEST_CREATE_EMPLOYEE_CART_ERROR: 'REQUEST_CREATE_EMPLOYEE_CART_ERROR',
  REQUEST_GET_EMPLOYEE_CART: 'REQUEST_GET_EMPLOYEE_CART',
  REQUEST_GET_EMPLOYEE_CART_SUCCESS: 'REQUEST_GET_EMPLOYEE_CART_SUCCESS',
  REQUEST_GET_EMPLOYEE_CART_ERROR: 'REQUEST_GET_EMPLOYEE_CART_ERROR',
  REQUEST_UPDATE_EMPLOYEE_CART: 'REQUEST_UPDATE_EMPLOYEE_CART',
  REQUEST_UPDATE_EMPLOYEE_CART_SUCCESS: 'REQUEST_UPDATE_EMPLOYEE_CART_SUCCESS',
  REQUEST_UPDATE_EMPLOYEE_CART_ERROR: 'REQUEST_UPDATE_EMPLOYEE_CART_ERROR',
  REQUEST_DELETE_EMPLOYEE_CART: 'REQUEST_DELETE_EMPLOYEE_CART',
  REQUEST_DELETE_EMPLOYEE_CART_SUCCESS: 'REQUEST_DELETE_EMPLOYEE_CART_SUCCESS',
  REQUEST_DELETE_EMPLOYEE_CART_ERROR: 'REQUEST_DELETE_EMPLOYEE_CART_ERROR',
  INIT_EMPLOYEE_BAKERY_ORDER: 'INIT_EMPLOYEE_BAKERY_ORDER',
  INIT_EMPLOYEE_BAKERY_ORDER_SUCCESS: 'INIT_EMPLOYEE_BAKERY_ORDER_SUCCESS',
  INIT_EMPLOYEE_BAKERY_ORDER_ERROR: 'INIT_EMPLOYEE_BAKERY_ORDER_ERROR',
  SET_EMPLOYEE_BAKERY_ORDER: 'SET_EMPLOYEE_BAKERY_ORDER',
  REQUEST_CREATE_EMPLOYEE_BAKERY_ORDER: 'REQUEST_CREATE_EMPLOYEE_BAKERY_ORDER',
  REQUEST_CREATE_EMPLOYEE_BAKERY_ORDER_SUCCESS: 'REQUEST_CREATE_EMPLOYEE_BAKERY_ORDER_SUCCESS',
  REQUEST_CREATE_EMPLOYEE_BAKERY_ORDER_ERROR: 'REQUEST_CREATE_EMPLOYEE_BAKERY_ORDER_ERROR',
  REQUEST_TAXES_FOR_BAKERY: 'REQUEST_TAXES_FOR_BAKERY',
  REQUEST_TAXES_FOR_BAKERY_SUCCESS: 'REQUEST_TAXES_FOR_BAKERY_SUCCESS',
  REQUEST_TAXES_FOR_BAKERY_ERROR: 'REQUEST_TAXES_FOR_BAKERY_ERROR',
  EDIT_ORDER_STARTED: 'EDIT_ORDER_STARTED',
  UPDATE_EMPLOYEE_BAKERY_ORDER: 'UPDATE_EMPLOYEE_BAKERY_ORDER',
  REQUEST_TAXES_FOR_CUSTOMER: 'REQUEST_TAXES_FOR_CUSTOMER',
  REQUEST_TAXES_FOR_CUSTOMER_SUCCESS: 'REQUEST_TAXES_FOR_CUSTOMER_SUCCESS',
  REQUEST_TAXES_FOR_CUSTOMER_ERROR: 'REQUEST_TAXES_FOR_CUSTOMER_ERROR',

  // Bakery wholesaler customer
  ADD_PRODUCT_TEMPLATE_TO_WHOLESALE_CART: 'ADD_PRODUCT_TEMPLATE_TO_WHOLESALE_CART',
  UPDATE_WHOLESALE_BAKERY_ORDER: 'UPDATE_WHOLESALE_BAKERY_ORDER',
  UPDATE_WHOLESALE_BAKERY_ORDER_SUCCESS: 'UPDATE_WHOLESALE_BAKERY_ORDER_SUCCESS',
  UPDATE_WHOLESALE_BAKERY_ORDER_ERROR: 'UPDATE_WHOLESALE_BAKERY_ORDER_ERROR',
  REQUEST_CREATE_WHOLESALE_BAKERY_ORDER: 'REQUEST_CREATE_WHOLESALE_BAKERY_ORDER',
  REQUEST_CREATE_WHOLESALE_BAKERY_ORDER_SUCCESS: 'REQUEST_CREATE_WHOLESALE_BAKERY_ORDER_SUCCESS',
  REQUEST_CREATE_WHOLESALE_BAKERY_ORDER_ERROR: 'REQUEST_CREATE_WHOLESALE_BAKERY_ORDER_ERROR',
  SET_WHOLESALE_BAKERY_ORDER: 'SET_WHOLESALE_BAKERY_ORDER',
  INIT_WHOLESALE_BAKERY_ORDER: 'INIT_WHOLESALE_BAKERY_ORDER',
  UPDATE_WHOLESALE_BAKERY_ORDER_DISCOUNT: 'UPDATE_WHOLESALE_BAKERY_ORDER_DISCOUNT',
  REQUEST_GET_WHOLESALE_CART: 'REQUEST_GET_WHOLESALE_CART',
  REQUEST_GET_WHOLESALE_CART_SUCCESS: 'REQUEST_GET_WHOLESALE_CART_SUCCESS',
  REQUEST_GET_WHOLESALE_CART_ERROR: 'REQUEST_GET_WHOLESALE_CART_ERROR',
  UPDATE_WHOLESALE_ORDER_ITEMS: 'UPDATE_WHOLESALE_ORDER_ITEMS',
  REQUEST_DELETE_WHOLESALE_CART: 'REQUEST_DELETE_WHOLESALE_CART',
  REQUEST_DELETE_WHOLESALE_CART_SUCCESS: 'REQUEST_DELETE_WHOLESALE_CART_SUCCESS',
  REQUEST_DELETE_WHOLESALE_CART_ERROR: 'REQUEST_DELETE_WHOLESALE_CART_ERROR',
  REQUEST_UPDATE_WHOLESALE_CART: 'REQUEST_UPDATE_WHOLESALE_CART',
  REQUEST_UPDATE_WHOLESALE_CART_SUCCESS: 'REQUEST_UPDATE_WHOLESALE_CART_SUCCESS',
  REQUEST_UPDATE_WHOLESALE_CART_ERROR: 'REQUEST_UPDATE_WHOLESALE_CART_ERROR',
  REQUEST_CREATE_WHOLESALE_CART: 'REQUEST_CREATE_WHOLESALE_CART',
  REQUEST_CREATE_WHOLESALE_CART_SUCCESS: 'REQUEST_CREATE_WHOLESALE_CART_SUCCESS',
  REQUEST_CREATE_WHOLESALE_CART_ERROR: 'REQUEST_CREATE_WHOLESALE_CART_ERROR',
  REQUEST_PUT_WHOLESALE_BAKERY_ORDER_CANCEL: 'REQUEST_PUT_WHOLESALE_BAKERY_ORDER_CANCEL',
  REQUEST_PUT_WHOLESALE_BAKERY_ORDER_CANCEL_SUCCESS: 'REQUEST_PUT_WHOLESALE_BAKERY_ORDER_CANCEL_SUCCESS',
  REQUEST_PUT_WHOLESALE_BAKERY_ORDER_CANCEL_ERROR: 'REQUEST_PUT_WHOLESALE_BAKERY_ORDER_CANCEL_ERROR',


  // Bakery customer
  REQUEST_GET_CUST_BAKERY: 'REQUEST_GET_CUST_BAKERY',
  REQUEST_GET_CUST_BAKERY_SUCCESS: 'REQUEST_GET_CUST_BAKERY_SUCCESS',
  REQUEST_GET_CUST_BAKERY_ERROR: 'REQUEST_GET_CUST_BAKERY_ERROR',
  REQUEST_GET_CUST_CART: 'REQUEST_GET_CUST_CART',
  REQUEST_GET_CUST_CART_SUCCESS: 'REQUEST_GET_CUST_CART_SUCCESS',
  REQUEST_GET_CUST_CART_ERROR: 'REQUEST_GET_CUST_CART_ERROR',
  REQUEST_CREATE_CUST_CART: 'REQUEST_CREATE_CUST_CART',
  REQUEST_CREATE_CUST_CART_SUCCESS: 'REQUEST_CREATE_CUST_CART_SUCCESS',
  REQUEST_CREATE_CUST_CART_ERROR: 'REQUEST_CREATE_CUST_CART_ERROR',
  REQUEST_UPDATE_CUST_CART: 'REQUEST_UPDATE_CUST_CART',
  REQUEST_UPDATE_CUST_CART_SUCCESS: 'REQUEST_UPDATE_CUST_CART_SUCCESS',
  REQUEST_UPDATE_CUST_CART_ERROR: 'REQUEST_UPDATE_CUST_CART_ERROR',
  REQUEST_DELETE_CUST_CART: 'REQUEST_DELETE_CUST_CART',
  REQUEST_DELETE_CUST_CART_SUCCESS: 'REQUEST_DELETE_CUST_CART_SUCCESS',
  REQUEST_DELETE_CUST_CART_ERROR: 'REQUEST_DELETE_CUST_CART_ERROR',
  ADD_PRODUCT_TEMPLATE_TO_CART: 'ADD_PRODUCT_TEMPLATE_TO_CART',
  REMOVE_PRODUCT_TEMPLATE_FROM_CART: 'REMOVE_PRODUCT_TEMPLATE_FROM_CART',
  SET_LOADING_STATE: 'SET_LOADING_STATE',
  INIT_CUST_BAKERY_ORDER: 'INIT_CUST_BAKERY_ORDER',
  SET_CUST_BAKERY_ORDER: 'SET_CUST_BAKERY_ORDER',
  UPDATE_CUST_BAKERY_ORDER: 'UPDATE_CUST_BAKERY_ORDER',
  UPDATE_CUST_BAKERY_ORDER_DISCOUNT: 'UPDATE_CUST_BAKERY_ORDER_DISCOUNT',
  REQUEST_CREATE_CUST_BAKERY_ORDER: 'REQUEST_CREATE_CUST_BAKERY_ORDER',
  REQUEST_CREATE_CUST_BAKERY_ORDER_SUCCESS: 'REQUEST_CREATE_CUST_BAKERY_ORDER_SUCCESS',
  REQUEST_CREATE_CUST_BAKERY_ORDER_ERROR: 'REQUEST_CREATE_CUST_BAKERY_ORDER_ERROR',

  LOAD_STORE_PRODUCT_CATEGORIES: 'LOAD_STORE_PRODUCT_CATEGORIES',
  SET_STORE_BAKERY_PRODUCTS_STORE_TYPE: 'SET_STORE_BAKERY_PRODUCTS_STORE_TYPE',
  SET_STORE_BAKERY_PRODUCTS_SEARCH_TEXT: 'SET_STORE_BAKERY_PRODUCTS_SEARCH_TEXT',
  ADD_STORE_BAKERY_PRODUCTS_CATEGORY_FILTER: 'ADD_STORE_BAKERY_PRODUCTS_CATEGORY_FILTER',
  REMOVE_STORE_BAKERY_PRODUCTS_CATEGORY_FILTER: 'REMOVE_STORE_BAKERY_PRODUCTS_CATEGORY_FILTER',
  REMOVE_STORE_BAKERY_PRODUCTS_CATEGORY_FILTERS: 'REMOVE_STORE_BAKERY_PRODUCTS_CATEGORY_FILTERS',
  REQUEST_GET_STORE_PRODUCT_CATEGORIES: 'REQUEST_GET_STORE_PRODUCT_CATEGORIES',
  REQUEST_GET_STORE_PRODUCT_CATEGORIES_SUCCESS: 'REQUEST_GET_STORE_PRODUCT_CATEGORIES_SUCCESS',
  REQUEST_GET_STORE_BAKERY_PRODUCTS: 'REQUEST_GET_STORE_BAKERY_PRODUCTS',
  REQUEST_GET_STORE_BAKERY_PRODUCTS_SUCCESS: 'REQUEST_GET_STORE_BAKERY_PRODUCTS_SUCCESS',
  REQUEST_GET_STORE_BAKERY_PRODUCT: 'REQUEST_GET_STORE_BAKERY_PRODUCT',
  REQUEST_GET_STORE_BAKERY_PRODUCT_SUCCESS: 'REQUEST_GET_STORE_BAKERY_PRODUCT_SUCCESS',
  REQUEST_GET_STORE_BAKERY_PRODUCT_ERROR: 'REQUEST_GET_STORE_BAKERY_PRODUCT_ERROR',
  REQUEST_GET_STORE_BAKERY_PRODUCT_SUGGESTIONS: 'REQUEST_GET_STORE_BAKERY_PRODUCT_SUGGESTIONS',
  REQUEST_GET_STORE_BAKERY_PRODUCT_SUGGESTIONS_SUCCESS: 'REQUEST_GET_STORE_BAKERY_PRODUCT_SUGGESTIONS_SUCCESS',
  REQUEST_GET_STORE_BAKERY_PRODUCT_SUGGESTIONS_ERROR: 'REQUEST_GET_STORE_BAKERY_PRODUCT_SUGGESTIONS_ERROR',
  REQUEST_REGISTER_BAKERY_CUSTOMER: 'REQUEST_REGISTER_BAKERY_CUSTOMER',
  REQUEST_REGISTER_BAKERY_CUSTOMER_SUCCESS: 'REQUEST_REGISTER_BAKERY_CUSTOMER_SUCCESS',
  REQUEST_REGISTER_BAKERY_CUSTOMER_ERROR: 'REQUEST_REGISTER_BAKERY_CUSTOMER_ERROR',

  REQUEST_PUT_CUST_BAKERY_ORDER_CANCEL: 'REQUEST_PUT_CUST_BAKERY_ORDER_CANCEL',
  REQUEST_PUT_CUST_BAKERY_ORDER_CANCEL_SUCCESS: 'REQUEST_PUT_CUST_BAKERY_ORDER_CANCEL_SUCCESS',
  REQUEST_PUT_CUST_BAKERY_ORDER_CANCEL_ERROR: 'REQUEST_PUT_CUST_BAKERY_ORDER_CANCEL_ERROR',

  // Generic request actions
  REQUEST_GET_GENERIC: 'REQUEST_GET_GENERIC',
  REQUEST_GET_GENERIC_SUCCESS: 'REQUEST_GET_GENERIC_SUCCESS',
  REQUEST_GET_GENERIC_ERROR: 'REQUEST_GET_GENERIC_ERROR',
};
