import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { Actions as NgRxActions } from '@ngrx/effects';

import * as selectors from 'app/store/selectors';
import { Actions } from 'app/store/actions';
import { AppState } from 'app/store/app-state';
import { CheckoutBakeryOrderForm } from 'app/bakery-mgmt/new-order/checkout/checkout-bakery-order-form';
import { CheckoutBakeryOrderFormCust } from 'app/bakery-cust/checkout/checkout-bakery-order-form';
import { Address } from 'app/store/address';
import { BakeryLocation } from 'app/store/bakery-location';

@Component({
  selector: 'bakery-mgmt-new-order-checkout-fulfillment-edit',
  template: require('./fulfillment-edit.component.html'),
})
export class BakeryMgmtNewOrderCheckoutFulfillmentEditComponent implements OnInit, OnDestroy {
  @Input() parent: FormGroup;
  @Input() addresses: Address[];
  @Input() employee: boolean;
  @Input() wholesaler: boolean;

  checkoutFormData$: Observable<CheckoutBakeryOrderForm|CheckoutBakeryOrderFormCust>;
  countryCode$: Observable<string>;
  minDate: Date | null;
  daysAtLeastOneLocationDoesFulfill: boolean[] = Array(7).fill(false);
  isDeliveryAvailable$: Observable<boolean>;
  isPickupAvailable$: Observable<boolean>;
  availablePickupLocations$: Observable<BakeryLocation[]>;
  availableFulfillmentAts$: Observable<string[]>;
  isPickupOrDelivery$: Observable<boolean>;

  subscription: Subscription;

  isAnyLocationOpen = (date: Date): boolean => {
    if (this.employee) { return true; }
    return this.daysAtLeastOneLocationDoesFulfill[date.getDay()];
  }

  dayAfterTomorrow() {
    const date = new Date();
    date.setDate(date.getDate() + 2);
    return date;
  }

  constructor(
    private store: Store<AppState>,
  ) {
  }

  ngOnInit() {
    this.minDate = this.employee ? null : this.dayAfterTomorrow();
    this.checkoutFormData$ = this.store.select(selectors.selectOrder);

    this.subscription = this.store
      .select<boolean[]>(selectors.selectDaysAtLeastOneLocationDoesFulfill)
      .subscribe(state => { this.daysAtLeastOneLocationDoesFulfill = state; });

    const fulfillmentDate$ = this.parent.get('fulfillment_date').valueChanges;
    const daysAtLeastOneLocationDoesDelivery$ =
      this.store.select<boolean[]>(selectors.selectDaysAtLeastOneLocationDoesDelivery);
    const daysAtLeastOneLocationDoesPickup$ =
      this.store.select<boolean[]>(selectors.selectDaysAtLeastOneLocationDoesPickup);

    this.isDeliveryAvailable$ =
      fulfillmentDate$.withLatestFrom(daysAtLeastOneLocationDoesDelivery$)
        .map(([date, days]) => this.employee || days[date.getDay()])
        .startWith(this.employee || false);

    this.isPickupAvailable$ =
      fulfillmentDate$.withLatestFrom(daysAtLeastOneLocationDoesPickup$)
        .map(([date, days]) => this.employee || days[date.getDay()])
        .startWith(this.employee || false);

    this.availablePickupLocations$ =
      this.store
        .select(selectors.selectAvailablePickupLocations)
        .do(locations => {
          if (locations.length !== 1) { return; }
          this.parent.patchValue({ fulfillment_location_id: locations[0].id });
        });

    this.countryCode$ = this.store
      .select(this.employee ? selectors.getCurrentBkryMgmtBakery : selectors.getCurrentCustBakery)
      .skipWhile(x => !x)
      .pluck('country_code');

    this.availableFulfillmentAts$ =
      this.store.select(selectors.selectAvailableFulfillmentAts);

      this.isPickupOrDelivery$ = this.checkoutFormData$
        .map(x => x.form_fulfillment_data.fulfillment_type)
        .map(x => ['pickup', 'delivery'].includes(x));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
