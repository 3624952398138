import { Component, OnInit, Input, Output, ChangeDetectionStrategy } from '@angular/core';
import { Subject } from 'rxjs/Subject';

import * as BakeryOrder from 'app/store/bakery-order';
import { Actions } from 'app/store/actions';

@Component({
  selector: 'bakery-mgmt-order-mgmt-master-list-item',
  template: require('./list-item.component.html'),
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BakeryMgmtOrderMasterListItemComponent implements OnInit {
  @Input() bakeryOrder: BakeryOrder.BakeryOrder;
  @Output('onClickShowDetails') onClickShowDetails$ = new Subject<BakeryOrder.BakeryOrder>();

  orderStatusAction = Actions.BKRY_MGMT_ORDER_MGMT_LIST_ORDER_STATUS_CHANGE;

  ngOnInit() {
  }

  getAttributes(item) {
    const attributes = item.meta.template_variants.map(variant => (
      `${variant.bakery_product_attribute.visible_name}: <strong>${variant.bakery_attribute_variant.name}</strong><br>`
    )).concat(item.meta.attribute_variants.map(variant => (
      `${variant.bakery_product_attribute.visible_name}: <strong>${variant.bakery_attribute_variant.name}</strong><br>`
    )));

    return attributes.join('');
  }

  onClickDetails() {
    this.onClickShowDetails$.next(this.bakeryOrder);
  }

  onClickCheckbox($event: Event) {
    $event.stopPropagation();
  }

  isActive(bakeryOrder) {
    return BakeryOrder.isActive(bakeryOrder);
  }

  isOnHold(bakeryOrder) {
    return BakeryOrder.isOnHold(bakeryOrder);
  }

  isOpen(bakeryOrder) {
    return BakeryOrder.isOpen(bakeryOrder);
  }
}
