import {
  Directive,
  ContentChildren,
  AfterContentInit,
  QueryList,
  ElementRef,
  Renderer2
} from '@angular/core';

import { GridColDirective } from './grid-col.directive';

const sMax = 4,
      mMax = 9,
      lMax = 12;

@Directive({
  selector: '[gridRow]',
})
export class GridRowDirective implements AfterContentInit {
  @ContentChildren(GridColDirective) gridCols: QueryList<GridColDirective>;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    this.renderer.addClass(this.el.nativeElement, `row`);
  }

  ngAfterContentInit() {
    let sCount = 0,
        mCount = 0,
        lCount = 0;

    this.gridCols.forEach((gridCol: GridColDirective) => {
      sCount += parseInt(gridCol.smallCols + '');
      mCount += parseInt(gridCol.mediumCols + '');
      lCount += parseInt(gridCol.largeCols + '');

      gridCol.isSmallFinal = sCount >= sMax;
      gridCol.isMediumFinal = mCount >= mMax;
      gridCol.isLargeFinal = lCount >= lMax;
    });
  }
}
