import { Component, OnInit } from '@angular/core';

@Component({
  template: require('./reporting.component.html'),
})
export class BakeryMgmtReportingComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}
