import {
  Component,
  Input,
  Output,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subject, Subscription } from 'rxjs';

import { AppState } from 'app/store/app-state';
import * as selectors from 'app/store/selectors';
import { Actions } from 'app/store/actions';
import { DetailPaneWrapperComponent } from '../detail-pane-wrapper';
import { WholesalerOrg } from 'app/store/wholesaler-org';
import { WholesalerGroup } from 'app/store/wholesaler-group';
import {
  wholesalerOrgSchema,
  wholesalerGroupSchema
} from 'app/store/schema/default-schemata';
import {
  GetAction,
  PutAction,
  PutActionPayload
} from 'app/store/actions/request.actions';
import { AlertAction } from 'app/shared/components/alerts/actions';
import { phoneNumberValidator } from 'app/shared/forms/form-number-validator';

const compSelector = 'bkry-mgmt-wholesaler-detail-settings';
const opKey = 'wholesalerDetailUpdateArchive',
      schema = wholesalerOrgSchema,
      groupsOpKey = 'wholesalerGroups',
      groupsSchema = [wholesalerGroupSchema];

@Component({
  template: `
    <${compSelector}
      [wholesalerOrg]="wholesalerOrg$ | async"
      [isLoading]="isUpdateLoading$ | async"
      [wholesalerGroups]="wholesalerGroups$ | async"
      [countryCode$]="countryCode$"
      (onFormSubmit)="onFormSubmit$.next($event)"
    ></${compSelector}>
  `,
})
export class SettingsComponent extends DetailPaneWrapperComponent implements OnInit, OnDestroy {
  countryCode$: Observable<string>;

  onFormSubmit$ = new Subject<any>();

  isUpdateLoading$: Observable<boolean> = this.store
    .select(selectors.getRequestIsLoading(opKey));

  wholesalerGroups$: Observable<WholesalerGroup[]> = this.store
    .select(selectors.getRequestResult(groupsOpKey, groupsSchema));

  private onFormSubmitSub: Subscription;

  constructor(
    store: Store<AppState>,
  ) {
    super(store);
  }

  ngOnInit() {
    // Convert form submissions into PUT actions/requests
    this.onFormSubmitSub = this.onFormSubmit$
      .withLatestFrom(
        this.wholesalerOrg$,
        (formValue, wholesalerOrg) => ({formValue, wholesalerOrg})
      )
      .subscribe(({formValue, wholesalerOrg}) => {
        const actionPayload: PutActionPayload = {
          url: `/api/wholesalers/${wholesalerOrg.id}`,
          opKey: opKey,
          schema: schema,
          body: formValue,
          successChainAction: AlertAction.buildSuccess(`The wholesale customer has been updated.`)
        };
        this.store.dispatch(new PutAction(actionPayload));
      });

    // Retrieve wholesaler groups
    const actionPayload = {
      url: `/api/wholesaler_groups`,
      opKey: groupsOpKey,
      schema: groupsSchema
    };
    this.store.dispatch(new GetAction(actionPayload));

    this.countryCode$ = this.store
      .select(selectors.getCurrentBkryMgmtBakery)
      .skipWhile(x => !x)
      .pluck('country_code');
  }

  ngOnDestroy() {
    this.onFormSubmitSub.unsubscribe();
  }
}

@Component({
  selector: compSelector,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: require('./settings.component.html'),
})
export class SettingsPrezComponent {
  private _wholesalerOrg: WholesalerOrg;
  @Input() countryCode$: Observable<string>;
  @Input()
  set wholesalerOrg(wholesalerOrg: WholesalerOrg) {
    this._wholesalerOrg = wholesalerOrg;

    this.resetForm();
  }
  get wholesalerOrg(): WholesalerOrg {
    return this._wholesalerOrg;
  }

  @Input() isLoading = false;
  @Input() wholesalerGroups: WholesalerGroup[] = [];

  @Output('onFormSubmit') onFormSubmit$ = new Subject<any>();

  form: FormGroup;

  constructor(
    private fb: FormBuilder
  ) {
    this.form = fb.group({
      // name: fb.control(null, [Validators.required]),
      // email: fb.control(null, [Validators.required, Validators.email]),
      // phone_number: fb.control(null, [phoneNumberValidator]),
      orgBasicDetails: fb.control(null, []),
      addresses_attributes: fb.control([], []),
      orgAccountInfo: fb.control(null, []),
      payment_cards_attributes: fb.control([], []),
    });
  }

  get isSubmittable(): boolean {
    return !this.isLoading && this.form.valid && this.form.dirty;
  }

  get isCancelable(): boolean {
    return !this.isLoading && this.form.dirty;
  }

  onFormCancel() {
    this.resetForm();
  }

  onFormSubmit() {
    // Flatten form value.
    const formValue = {
      ...this.form.value.orgBasicDetails,
      addresses_attributes: this.form.value.addresses_attributes,
      payment_cards_attributes: this.form.value.payment_cards_attributes,
      ...this.form.value.orgAccountInfo
    };

    this.onFormSubmit$.next(formValue);
  }

  private resetForm() {
    this.form.reset(
      {
        orgBasicDetails: {
          name: this.wholesalerOrg.name,
          email: this.wholesalerOrg.email,
          phone_number: this.wholesalerOrg.phone_number
        },
        addresses_attributes: [...this.wholesalerOrg.addresses],
        orgAccountInfo: {
          invoice_interval: this.wholesalerOrg.invoice_interval,
          wholesaler_group_id: this.wholesalerOrg.wholesaler_group_id
        },
        payment_cards_attributes: [...this.wholesalerOrg.payment_cards]
      },
      {emitEvent: false}
    );
  }
}
