import * as Rollbar from 'rollbar';

import {
  Injectable,
  Inject,
  Injector,
  InjectionToken,
  NgModule,
  ErrorHandler
} from '@angular/core';
import { AppComponent } from './app.component';

declare var process: any;


const rollbarConfig = {
  accessToken: process.env.ROLLBAR_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: process.env.ROLLBAR_ENV
  }
};

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(@Inject(RollbarService) private rollbar: Rollbar) {}

  handleError(err: any): void {
    if (rollbarConfig.accessToken) {
      this.rollbar.error(err.originalError || err);
    }
    throw err;
  }
}

export function rollbarFactory() {
  if (rollbarConfig.accessToken) {
    return new Rollbar(rollbarConfig);
  }
}
