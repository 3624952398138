import { Directive, Input, OnInit, HostBinding, ElementRef, Renderer2 } from '@angular/core';

const gridColumnFinalNamePrefix = 'grid-col-omega-';

@Directive({
  selector: '[gridCol]',
})
export class GridColDirective implements OnInit {
  @Input('s') smallCols = 4;
  @Input('m') mediumCols = 9;
  @Input('l') largeCols = 12;

  // TODO: There's a lot of redundancy in these "final" inputs/attributes.
  private _isSmallFinal = false;
  @Input('sFin')
  set isSmallFinal(isSmallFinal: boolean) {
    if (isSmallFinal) {
      this.renderer.addClass(this.el.nativeElement, `${gridColumnFinalNamePrefix}s`);
    } else {
      this.renderer.removeClass(this.el.nativeElement, `${gridColumnFinalNamePrefix}s`);
    }

    this._isSmallFinal = isSmallFinal;
  }
  get isSmallFinal(): boolean {
    return this._isSmallFinal;
  }

  private _isMediumFinal = false;
  @Input('mFin')
  set isMediumFinal(isMediumFinal: boolean) {
    if (isMediumFinal) {
      this.renderer.addClass(this.el.nativeElement, `${gridColumnFinalNamePrefix}m`);
    } else {
      this.renderer.removeClass(this.el.nativeElement, `${gridColumnFinalNamePrefix}m`);
    }

    this._isMediumFinal = isMediumFinal;
  }
  get isMediumFinal(): boolean {
    return this._isMediumFinal;
  }

  private _isLargeFinal = false;
  @Input('lFin')
  set isLargeFinal(isLargeFinal: boolean) {
    if (isLargeFinal) {
      this.renderer.addClass(this.el.nativeElement, `${gridColumnFinalNamePrefix}l`);
    } else {
      this.renderer.removeClass(this.el.nativeElement, `${gridColumnFinalNamePrefix}l`);
    }

    this._isLargeFinal = isLargeFinal;
  }
  get isLargeFinal(): boolean {
    return this._isLargeFinal;
  }

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    this.renderer.addClass(this.el.nativeElement, `columns-${this.smallCols}__s`);
    this.renderer.addClass(this.el.nativeElement, `columns-${this.mediumCols}__m`);
    this.renderer.addClass(this.el.nativeElement, `columns-${this.largeCols}__l`);
  }
}
