import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Actions as NgRxActions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { AlertService } from 'app/shared/components/alerts/alert.service';
import { AppState } from 'app/store/app-state';
import { Actions } from 'app/store/actions';
import { BakeryMgmtProductTemplatesEffects } from 'app/store/effects/bakery-mgmt/bakery-mgmt-bakery-product-templates.effects';
import { BakeryMgmtUiState } from 'app/store/bakery-mgmt-ui-state';
import { EntitiesState } from 'app/store/entities-state';
import { BakeryProductTemplate } from 'app/store/bakery-product-template';
import { denormalize as denormalizeProdTem } from 'app/store/bakery-product-templates/utils';

export interface UiState {
  isLoading: boolean;
  hasBakeryProductTemplates: boolean;
  bakeryProductTemplates: BakeryProductTemplate[];
  currentUserId: number;
}

@Component({
  template: require('./master.component.html'),
})
export class BakeryMgmtProductTemplatesMasterComponent implements OnInit, OnDestroy {
  public uiState$: Observable<UiState>;
  public addSuccess$: Observable<any>;

  private filterText$ = new BehaviorSubject<string>('');
  private alertsSub: Subscription;

  constructor(
    private bkryMgmtProductTemplatesEffects: BakeryMgmtProductTemplatesEffects,
    private actions$: NgRxActions,
    private store: Store<AppState>,
    private router: Router,
    private alertService: AlertService,
  ) {
    this.uiState$ = Observable
      .combineLatest(
        this.store.select('bakeryMgmtUiState'),
        this.store.select('entitiesState'),
        this.filterText$,
        this.store.map(x => x.authState.currentUser.id).distinctUntilChanged()
      )
      .map(([bakeryMgmtUiState, entitiesState, filterText, currentUserId]) => {
        if (bakeryMgmtUiState.bakeryProductTemplatesUiState.isMasterLoading) {
          return {
            isLoading: true,
            hasBakeryProductTemplates: false,
            bakeryProductTemplates: [],
            currentUserId,
          };
        }

        let bakeryProductTemplates = Object.keys(entitiesState.bakery_product_templates)
          .map(key => denormalizeProdTem(entitiesState.bakery_product_templates[key], entitiesState))
          .filter(bakeryProductTemplate => !bakeryProductTemplate.product_private);

        if (filterText.length >= 2) {
          bakeryProductTemplates = bakeryProductTemplates.filter(prodTem => {
            return prodTem.name.toLowerCase().includes(filterText.toLowerCase());
          });
        }

        return {
          isLoading: false,
          hasBakeryProductTemplates: bakeryProductTemplates.length > 0,
          bakeryProductTemplates: bakeryProductTemplates,
          currentUserId,
        }
      })
      .shareReplay(1);

    this.alertsSub = this.actions$
      .subscribe((action: UnsafeAction) => {
        let messageType = null,
            messageContent = null;

        switch (action.type) {
          case Actions.REQUEST_DELETE_BAKERY_PRODUCT_TEMPLATE_SUCCESS:
            messageType = 'success';
            messageContent = `The template has been deleted.`;
            break;
          case Actions.REQUEST_DELETE_BAKERY_PRODUCT_TEMPLATE_ERROR:
            messageType = 'warning';
            messageContent = 'Something went wrong!';
            break;
        }

        if (messageType != null && messageContent != null) {
          this.alertService[messageType](messageContent);
        }
      });
  }

  ngOnInit() {
    this.bkryMgmtProductTemplatesEffects.requestGetBakeryProductTemplates();
  }

  ngOnDestroy() {
    this.alertsSub.unsubscribe();
  }

  onChangeFilterText(filterText: string) {
    this.filterText$.next(filterText);
  }

  onShowDetails(bakeryProductTemplate: BakeryProductTemplate) {
    this.router.navigate([
      '/bakery_mgmt/product_mgmt/templates/',
      bakeryProductTemplate.id,
    ]);
  }

  onDeleteTemplate(bakeryProductTemplate: BakeryProductTemplate) {
    this.bkryMgmtProductTemplatesEffects.requestDeleteBakeryProductTemplate(bakeryProductTemplate);
  }
}
