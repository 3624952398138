import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { Actions } from 'app/store/actions';
import { Store } from '@ngrx/store';
import { OrderItem } from 'app/store/order-item';
import { AppState } from 'app/store/app-state';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { validatePositiveInteger } from 'app/shared/forms/positive-integer-validator';
import { BakeryMgmtProductsEffects } from 'app/store/effects/bakery-mgmt/bakery-mgmt-products.effects';
import { BakeryMgmtProductTemplatesEffects } from 'app/store/effects/bakery-mgmt/bakery-mgmt-bakery-product-templates.effects';
import { BakeryMgmtProductAttributesEffects } from 'app/store/effects/bakery-mgmt/bakery-mgmt-bakery-product-attributes.effects';
import { denormalize } from 'normalizr';
import { bakeryProductSchema } from 'app/store/schema/default-schemata';
import { EntitiesState } from 'app/store/entities-state';
import { BakeryMgmtUiState } from 'app/store/bakery-mgmt-ui-state';
import { BakeryProductProductTemplate } from 'app/store/bakery-product-product-template';
import { rebuildControls } from 'app/rebuild-controls';
import { BakeryProduct } from 'app/store/bakery-product';
import { BakeryProductAttribute } from 'app/store/bakery-product-attribute';
import { BakeryAttributeVariant } from 'app/store/bakery-attribute-variant';
import { BakeryAttribute } from 'app/store/bakery-attribute';
import { ProductCategory } from 'app/store/product-category';
import * as selectors from 'app/store/selectors';
import * as RemoteData from 'app/remote-data';

type UiState = {
  bakeryProduct: BakeryProduct,
  bakeryProductTemplates: BakeryProductProductTemplate[],
  bakeryAttributes: BakeryAttribute[],
  productCategories: ProductCategory[]
}

interface MetaBakeryProductAttribute {
  id: number,
  visible_name: string,
}

interface MetaBakeryAttributeVariant {
  id: number,
  name: string,
}

interface Meta {
  inscription: string,
  template_variants: { id: number, bakery_product_attribute: MetaBakeryProductAttribute, bakery_attribute_variant: MetaBakeryAttributeVariant }[],
  attribute_variants: { id: number, bakery_product_attribute: MetaBakeryProductAttribute, bakery_attribute_variant: MetaBakeryAttributeVariant }[],
}

@Component({
  selector: 'edit-order-item',
  template: require('./order-item.component.html'),
})
export class EditOrderItemComponent implements OnInit, OnDestroy {
  @Input() orderId: OrderItem;
  @Input() orderItem: OrderItem & { bakery_product_product_template: BakeryProductProductTemplate } & { meta: Meta };
  form: FormGroup;
  uiState$: Observable<RemoteData.RemoteData<UiState>>;
  isEditing$: Observable<boolean>;
  bakeryProductProductTemplates$: Observable<BakeryProductProductTemplate[]>;
  isAdvanced$: Observable<boolean>;
  doesAllowInscription$: Observable<boolean>;
  bakeryProductAttributes1$: Observable<BakeryProductAttribute[]>;
  bakeryProductAttributes2$: Observable<BakeryProductAttribute[]>;
  private subscription1: Subscription;
  private subscription2: Subscription;

  constructor(
    private store: Store<AppState>,
    private bakeryMgmtProductsEffects: BakeryMgmtProductsEffects,
    private bakeryMgmtProductTemplatesEffects: BakeryMgmtProductTemplatesEffects,
    private bakeryMgmtProductAttributesEffects: BakeryMgmtProductAttributesEffects,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.isEditing$ = this.store
      .select(selectors.getBkryMgmtOrderMgmtDetailState)
      .map(x => x.orderItemIdsBeingEdited)
      .filter(x => !!x)
      .map(xs => xs.has(this.orderItem.id));

    this.form = new FormGroup({
      quantity: new FormControl(
        this.orderItem.quantity,
        [
          Validators.required,
          Validators.pattern('[0-9]{1,3}'),
          validatePositiveInteger(1)
        ]
      ),
      template: new FormControl(this.orderItem.bakery_product_product_template.id, [Validators.required]),
      inscription: new FormControl(this.orderItem.meta.inscription),
      open_charge: new FormControl(this.orderItem.open_charge, [Validators.min(0)]),
      open_charge_description: new FormControl(this.orderItem.open_charge_description),
      notes: new FormControl(this.orderItem.notes),
    });

    this.uiState$ = this.store
      .select('entitiesState')
      .map((entitiesState: EntitiesState): RemoteData.RemoteData<UiState> => {
        const productId = this.orderItem.bakery_product_id;

        if (entitiesState.bakery_products[productId] == null) { return RemoteData.LOADING; }

        const bakeryProductTemplates = Object.keys(
          entitiesState.bakery_product_templates
        ).map(key =>
          ({...entitiesState.bakery_product_templates[key]})
        );
        bakeryProductTemplates.forEach(bpt => {
          bpt.bakery_product_attributes = bpt.bakery_product_attributes.map(bpaOrBpaId => {
            if (typeof bpaOrBpaId !== 'number') { return bpaOrBpaId }
            const bpa = entitiesState.bakery_product_attributes[bpaOrBpaId];
            if (typeof bpa.bakery_attribute === 'number') { bpa.bakery_attribute = entitiesState.bakery_attributes[bpa.bakery_attribute]; }
            bpa.bakery_attribute.bakery_attribute_variants = (bpa.bakery_attribute.bakery_attribute_variants as BakeryAttributeVariant[]).map(bav => {
              if (typeof bav === 'number') { return entitiesState.bakery_attribute_variants[bav] }
              return bav
            })
            return bpa
          })
        })

        const bakeryAttributes = Object
          .keys(entitiesState.bakery_attributes)
          .map(key => Object.assign({}, entitiesState.bakery_attributes[key]));

        const productCategories = Object
          .keys(entitiesState.product_categories)
          .map(key => Object.assign({}, entitiesState.product_categories[key]));

        const normalizedBakeryProduct = denormalize(productId, bakeryProductSchema, entitiesState)

        const bakeryProduct = {
          ...normalizedBakeryProduct,
          bakery_product_product_templates: (normalizedBakeryProduct.bakery_product_product_templates || [])
            .map(template => {
              return {
                ...template,
                bakery_product_template: bakeryProductTemplates.find(temp => temp.id === template.bakery_product_template_id)
              }
            })
        };

        return RemoteData.fromSuccess({
          bakeryProduct,
          bakeryProductTemplates,
          bakeryAttributes,
          productCategories
        });
      }).startWith(RemoteData.NOT_ASKED)

    const bakeryProduct$ = this.uiState$
      .map(x => RemoteData.withDefault({ bakeryProduct: null }, x).bakeryProduct)

    this.bakeryProductProductTemplates$ = bakeryProduct$
      .map(x => (x && x.bakery_product_product_templates as BakeryProductProductTemplate[]) || [])

    this.isAdvanced$ = bakeryProduct$
      .map(x => x && x.advanced)

    this.doesAllowInscription$ = bakeryProduct$
      .map(x => x && x.allow_inscription)

    this.bakeryProductAttributes2$ = bakeryProduct$
      .map(x => (x && x.bakery_product_attributes as BakeryProductAttribute[]) || [])

    this.subscription2 = this.bakeryProductAttributes2$
      .do(bpas => {
        rebuildControls(
          this.form,
          this.formBuilder,
          'attribute_variants',
          bpas,
          bpas.map(bpa => {
            const tv = this.orderItem.meta.attribute_variants.find(t => t.bakery_product_attribute.id === bpa.id);
            return `${tv.bakery_product_attribute.id}-${tv.bakery_attribute_variant.id}`;
          })
        );
      }).subscribe();

    const initiallySelectedTemplate$ = bakeryProduct$
      .filter(x => !!x)
      .map(x => x.bakery_product_product_templates || [])
      .map(bppts => bppts.find(bppt => bppt.id === this.orderItem.bakery_product_product_template.id))

    const selectedTemplate$ = this.form
      .get('template')
      .valueChanges
      .withLatestFrom(bakeryProduct$).filter(x => !!x)
      .map(([templateId, bakeryProduct]) => bakeryProduct.bakery_product_product_templates.find(bppt => bppt.id === templateId));

    const latestSelectedTemplate$ = initiallySelectedTemplate$
      .merge(selectedTemplate$)
      .filter(x => !!x);

    this.subscription1 = latestSelectedTemplate$
      .do(bppt => {
        const bpas = bppt.bakery_product_template.bakery_product_attributes as BakeryProductAttribute[];
        const selections = bpas.map(bpa => {
          const tv = this.orderItem.meta.template_variants.find(t => t.bakery_product_attribute.id === bpa.id);
          if (!tv) { return null; }
          return `${tv.bakery_product_attribute.id}-${tv.bakery_attribute_variant.id}`;
        });
        rebuildControls(
          this.form,
          this.formBuilder,
          'template_variants',
          bpas,
          selections.every(x => !!x) ? selections : null
        );
      })
      .subscribe()

    this.bakeryProductAttributes1$ = latestSelectedTemplate$
      .map(x => x.bakery_product_template.bakery_product_attributes as BakeryProductAttribute[])
  }

  getAttributes() {
    const attributes = (this.orderItem.meta.template_variants || []).map(variant => (
      `${variant.bakery_product_attribute.visible_name}: <strong>${variant.bakery_attribute_variant.name}</strong><br>`
    )).concat((this.orderItem.meta.attribute_variants || []).map(variant => (
      `${variant.bakery_product_attribute.visible_name}: <strong>${variant.bakery_attribute_variant.name}</strong><br>`
    )));

    return attributes.join('');
  }

  onClickRemoveOrderItem() {
    this.store.dispatch({
      type: Actions.REQUEST_UPDATE_BKRY_MGMT_ORDER_MGMT_ORDER_DETAILS,
      payload: {
        id: this.orderId,
        data: {
          order_items_attributes: [{
            id: this.orderItem.id,
            _destroy: true,
          }]
        },
      },
    });
  }

  onClickEditOrderItem() {
    this.bakeryMgmtProductsEffects.requestGetBakeryProduct(this.orderItem.bakery_product_id);
    this.bakeryMgmtProductAttributesEffects.requestGetBakeryAttributes();
    this.bakeryMgmtProductTemplatesEffects.requestGetBakeryProductTemplates();
    this.store.dispatch({ type: Actions.REQUEST_START_UPDATE_ORDER_ITEM, payload: this.orderItem.id });
  }

  onClickCancelEditingOrderItem() {
    this.store.dispatch({ type: Actions.REQUEST_CANCEL_UPDATE_ORDER_ITEM, payload: this.orderItem.id });
  }

  onClickUpdateOrderItem() {
    if (!this.form.valid) { return; }
    this.store.dispatch({
      type: Actions.REQUEST_UPDATE_ORDER_ITEM,
      payload: {
        orderItemId: this.orderItem.id,
        quantity: this.form.get('quantity').value,
        template: this.form.get('template').value,
        open_charge: this.form.get('open_charge').value,
        open_charge_description: this.form.get('open_charge_description').value,
        notes: this.form.get('notes').value,
        inscription: this.form.get('inscription').value,
        template_variants: (this.form.get('template_variants') || { value: [] }).value.map(bpaBpv => {
          const bv = bpaBpv.split('-');
          return { bpa: bv[0], variant: bv[1] };
        }),
        attribute_variants: (this.form.get('attribute_variants') || { value: [] }).value.map(bpaBpv => {
          const bv = bpaBpv.split('-');
          return { bpa: bv[0], variant: bv[1] };
        }),
      },
    });
  }

  ngOnDestroy() {
    this.subscription1.unsubscribe();
    this.subscription2.unsubscribe();
  }
}
