import { Injectable } from '@angular/core';
import { Angular2TokenService } from 'app/angular2-token/angular2-token.service';
import { Actions as NgRxActions, Effect } from '@ngrx/effects';
import { Response } from '@angular/http';
import { Observable } from 'rxjs/Observable';

import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { Actions } from 'app/store/actions';

@Injectable()
export class BakeryMgmtDecoratingReportEffects {

  constructor(
    private actions$: NgRxActions,
    private tokenService: Angular2TokenService,
  ) { }

  @Effect() requestDecoratingReport$ = this.actions$
    .ofType(Actions.REQUEST_DECORATING_REPORT)
    .switchMap((action: UnsafeAction) => {
      return this.tokenService
        .get(`/api/bakery/reports/decorating`, { params: action.payload })
        .map((response: Response) => response.json())
        .switchMap(response => {
          return Observable.of({ type: Actions.REQUEST_DECORATING_REPORT_SUCCESS, payload: response });
        })
        .catch(() => {
          return Observable.of({ type: Actions.REQUEST_DECORATING_REPORT_ERROR });
        });
    })
    .share();
}
