import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { Store } from '@ngrx/store';
import { Actions as NgRxActions, Effect } from '@ngrx/effects';
import { Angular2TokenService } from 'app/angular2-token/angular2-token.service';
import { normalize, schema } from 'normalizr';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { AppState } from 'app/store/app-state';
import { AuthState } from 'app/store/auth-state';
import { EntitiesState } from 'app/store/entities-state';
import { Actions } from 'app/store/actions';
import { User } from 'app/store/user';
import { Role } from 'app/store/role';

const roleSchema = new schema.Entity('roles');
const userSchema = new schema.Entity('users', {
  roles: [roleSchema],
});

@Injectable()
export class UsersEffects {
  currentUser$: Observable<User>;

  constructor(
    private actions$: NgRxActions,
    private tokenService: Angular2TokenService,
    private store: Store<AppState>,
  ) {
    this.currentUser$ = Observable
      .combineLatest(
        this.store.select('authState')
          .filter((authState: AuthState) => authState.isLoggedIn)
          .pluck('currentUser')
        ,
        this.store.select('entitiesState'),
        (currentUser, entitiesState) => ({currentUser, entitiesState})
      )
      .map(userAndEntities => {
        const entitiesState: EntitiesState = <EntitiesState> userAndEntities.entitiesState;

        if (userAndEntities.currentUser == null || entitiesState.users[(<User>userAndEntities.currentUser).id] == null) {
          return null;
        }

        const user: User = <User> {
          ...entitiesState.users[(<User>userAndEntities.currentUser).id]
        };

        user.roles = (<number[]>user.roles).map((roleId: number): Role => {
          return entitiesState.roles[roleId];
        });

        return user;
      });
  }

  requestValidateToken() {
    this.store.dispatch({
      type: Actions.REQUEST_VALIDATE_TOKEN,
    });
  }

  requestUserSignIn(email: string, password: string, bakery_id: number = null) {
    this.store.dispatch({
      type: Actions.REQUEST_USER_SIGN_IN,
      payload: {
        email,
        password,
        bakery_id,
      }
    });
  }

  requestUserSignOut() {
    this.store.dispatch({ type: Actions.REQUEST_USER_SIGN_OUT });
  }

  requestUserDetailsUpdate(detailsPayload) {
    this.store.dispatch({
      type: Actions.REQUEST_USER_DETAILS_UPDATE,
      payload: detailsPayload,
    });
  }

  requestUserPasswordUpdate(passwordPayload) {
    this.store.dispatch({
      type: Actions.REQUEST_USER_PASSWORD_UPDATE,
      payload: passwordPayload,
    });
  }

  requestUserUpdateArchiveStatus(user: User, newArchiveStatus: boolean) {
    this.store.dispatch({
      type: Actions.REQUEST_UPDATE_ARCHIVE_STATUS,
      payload: {
        user: user,
        archive_status: newArchiveStatus,
      }
    });
  }

  requestUserDestroy(user: User) {
    this.store.dispatch({
      type: Actions.REQUEST_USER_DESTROY,
      payload: {
        user: user,
      }
    });
  }

  requestUserResetPassword(email: string, password: string, token: string, bakery_id: number = null) {
    this.store.dispatch({
      type: Actions.REQUEST_USER_RESET_PASSWORD,
      payload: {
        email: email,
        password: password,
        reset_password_token: token,
        bakery_id: bakery_id,
      }
    });
  }

  requestUserForgotPassword(email: string, bakery_id: number = null) {
    this.store.dispatch({
      type: Actions.REQUEST_USER_FORGOT_PASSWORD,
      payload: {
        email: email,
        bakery_id: bakery_id,
      }
    });
  }

  requestUserAvatarUpdate(user: User, avatarDataUri: string) {
    this.store.dispatch({
      type: Actions.REQUEST_USER_AVATAR_UPDATE,
      payload: {
        user_id: user.id,
        avatar: avatarDataUri,
      }
    });
  }

  requestUserResendInvite(user: User) {
    this.store.dispatch({
      type: Actions.REQUEST_USER_RESEND_INVITE,
      payload: {
        user_id: user.id,
      },
    });
  }

  @Effect() requestValidateTokenEffects$ = this.actions$
    .ofType(Actions.REQUEST_VALIDATE_TOKEN)
    .switchMap((action: UnsafeAction) => {
      return this.tokenService.validateToken()
        .map((response: Response) => response.json().data)
        .map((data: any[]) => {
          const results = normalize(data, userSchema);

          return {
            entities: results.entities,
            currentUser: results.entities.users[results.result],
          };
        })
        .switchMap((results) => {
          console.log(`Validate token SUCCESS`);
          return Observable.from([
            {
              type: Actions.LOADED_ENTITIES,
              payload: results.entities,
            },
            {
              type: Actions.REQUEST_VALIDATE_TOKEN_SUCCESS,
              payload: results.currentUser,
            },
          ]);
        })
        .catch(() => {
          console.log(`Validate token ERROR`);
          return Observable.of({type: Actions.REQUEST_VALIDATE_TOKEN_ERROR});
        });
    })
    .share();

  @Effect() requestUserSignInEffects$ = this.actions$
    .ofType(Actions.REQUEST_USER_SIGN_IN)
    .switchMap((action: UnsafeAction) => {
      localStorage.clear();
      sessionStorage.clear();
      return this.tokenService.signIn(action.payload)
        .map((response: Response) => response.json().data)
        .map((data: any[]) => {
          const results = normalize(data, userSchema);

          return {
            entities: results.entities,
            currentUser: results.entities.users[results.result],
          };
        })
        .switchMap((results) => {
          return Observable.from([
            {
              type: Actions.LOADED_ENTITIES,
              payload: results.entities,
            },
            {
              type: Actions.REQUEST_USER_SIGN_IN_SUCCESS,
              payload: results.currentUser,
            },
          ]);
        })
        .catch((error) => {
          const errorPayload = error.json();

          return Observable.of({
            type: Actions.REQUEST_USER_SIGN_IN_ERROR,
            payload: errorPayload,
          });
        });
    })
    .share();

  @Effect() requestUserSignOutEffects$ = this.actions$
    .ofType(Actions.REQUEST_USER_SIGN_OUT)
    .switchMap(() => {
      return this.tokenService.signOut()
        .switchMap(() => {
          return Observable.of({ type: Actions.REQUEST_USER_SIGN_OUT_SUCCESS });
        })
    })
    .share();

  @Effect() authenticationSucessEffects$ = this.actions$
    .ofType('VALIDATE_TOKEN_SUCCESS', 'LOGIN_SUCCESS')
    .switchMap((action: UnsafeAction) => {
      const results = normalize(action.payload, userSchema);

      return Observable.from([
        {
          type: Actions.LOADED_ENTITIES,
          payload: results.entities,
        },
      ]);
    })
    .share();

  @Effect() requestUserDetailsUpdateEffects$ = this.actions$
    .ofType(Actions.REQUEST_USER_DETAILS_UPDATE)
    .switchMap((action: UnsafeAction) => {
      return this.tokenService.put(
        `/api/users/${action.payload.id}`,
        JSON.stringify(action.payload)
      )
        .map((response: Response) => response.json())
        .map((data: any[]) => {
          const results = normalize(data, userSchema);

          return {
            entities: results.entities,
            currentUser: results.entities.users[results.result],
          };
        })
        .switchMap((results) => {
          return Observable.from([
            {
              type: Actions.LOADED_ENTITIES,
              payload: results.entities,
            },
            {
              type: Actions.REQUEST_USER_DETAILS_UPDATE_SUCCESS,
            },
          ]);
        })
        .catch((error) => {
          const errorPayload = error.json();

          return Observable.of({
            type: Actions.REQUEST_USER_DETAILS_UPDATE_ERROR,
            payload: errorPayload,
          });
        });
    })
    .share();

  @Effect() requestUserPasswordUpdateEffects$ = this.actions$
    .ofType(Actions.REQUEST_USER_PASSWORD_UPDATE)
    .switchMap((action: UnsafeAction) => {
      return this.tokenService.put(
        `/api/users/${action.payload.id}/password`,
        JSON.stringify(action.payload)
      )
        .map((response: Response) => response.json())
        .map((data: any[]) => {
          const results = normalize(data, userSchema);

          return {
            entities: results.entities,
            currentUser: results.entities.users[results.result],
          };
        })
        .switchMap((results) => {
          return Observable.from([
            {
              type: Actions.LOADED_ENTITIES,
              payload: results.entities,
            },
            // TODO: This action is probably unnecessary...
            {
              type: Actions.UPDATE_CURRENT_USER,
              payload: results.currentUser,
            },
            {
              type: Actions.REQUEST_USER_PASSWORD_UPDATE_SUCCESS,
            },
          ]);
        });
    })
    .share();

  @Effect() requestUserUpdateArchiveStatusEffects$ = this.actions$
    .ofType(Actions.REQUEST_UPDATE_ARCHIVE_STATUS)
    .switchMap((action: UnsafeAction) => {
      return this.tokenService.put(
        `/api/users/${action.payload.user.id}/archive_status`,
        JSON.stringify({
          archive_status: action.payload.archive_status,
        })
      )
        .map((response: Response) => response.json())
        .map((data: any[]) => {
          const results = normalize(data, userSchema);

          return {
            entities: results.entities,
            currentUser: results.entities.users[results.result],
          };
        })
        .switchMap((results) => {
          return Observable.from([
            {
              type: Actions.LOADED_ENTITIES,
              payload: results.entities,
            },
            {
              type: Actions.REQUEST_UPDATE_ARCHIVE_STATUS_SUCCESS,
            },
          ]);
        })
        .catch((error) => {
          const errorPayload = error.json();

          return Observable.of({
            type: Actions.REQUEST_UPDATE_ARCHIVE_STATUS_ERROR,
            payload: errorPayload,
          });
        });
    })
    .share();

  @Effect() requestUserDestroyEffects$ = this.actions$
    .ofType(Actions.REQUEST_USER_DESTROY)
    .switchMap((action: UnsafeAction) => {
      return this.tokenService.delete(
        `/api/users/${action.payload.user.id}`
      )
        .map((response: Response) => response.json())
        .switchMap((results) => {
          return Observable.from([
            {
              type: Actions.REMOVE_ENTITY,
              payload: {
                typeKey: 'users',
                entityKey: action.payload.user.id
              },
            },
            {
              type: Actions.REQUEST_USER_DESTROY_SUCCESS,
            },
          ]);
        })
        .catch((error) => {
          const errorPayload = error.json();

          return Observable.of({
            type: Actions.REQUEST_USER_DESTROY_ERROR,
            payload: errorPayload,
          });
        });
    })
    .share();

  @Effect() requestUserResetPasswordEffects$ = this.actions$
    .ofType(Actions.REQUEST_USER_RESET_PASSWORD)
    .switchMap((action: UnsafeAction) => {
      return this.tokenService.put(
        `/api/auth/password`,
        JSON.stringify(action.payload),
      )
        .map((response: Response) => response.json())
        .switchMap((results) => {
          return Observable.from([
            {
              type: Actions.REQUEST_USER_RESET_PASSWORD_SUCCESS,
            },
          ]);
        })
        .catch((error) => {
          const errorPayload = error.json();

          return Observable.of({
            type: Actions.REQUEST_USER_RESET_PASSWORD_ERROR,
            payload: errorPayload,
          });
        });
    })
    .share();

  @Effect() requestUserForgotPasswordEffects$ = this.actions$
    .ofType(Actions.REQUEST_USER_FORGOT_PASSWORD)
    .switchMap((action: UnsafeAction) => {
      return this.tokenService.resetPassword(action.payload)
        .map((response: Response) => response.json())
        .switchMap((results) => {
          return Observable.from([
            {
              type: Actions.REQUEST_USER_FORGOT_PASSWORD_SUCCESS,
              payload: action.payload,
            },
          ]);
        })
        .catch((error) => {
          const errorPayload = error.json();

          return Observable.of({
            type: Actions.REQUEST_USER_FORGOT_PASSWORD_ERROR,
            payload: errorPayload,
          });
        });
    })
    .share();

  @Effect() requestUserAvatarUpdateEffects$ = this.actions$
    .ofType(Actions.REQUEST_USER_AVATAR_UPDATE)
    .switchMap((action: UnsafeAction) => {
      return this.tokenService.put(
        `/api/users/${action.payload.user_id}`,
        JSON.stringify({avatar: action.payload.avatar})
      )
        .map((response: Response) => response.json())
        .map((data: any[]) => {
          const results = normalize(data, userSchema);

          return {
            entities: results.entities,
            currentUser: results.entities.users[results.result],
          };
        })
        .switchMap((results) => {
          return Observable.from([
            {
              type: Actions.LOADED_ENTITIES,
              payload: results.entities,
            },
            {
              type: Actions.REQUEST_USER_AVATAR_UPDATE_SUCCESS,
            },
          ]);
        })
        .catch((error) => {
          const errorPayload = error.json();

          return Observable.of({
            type: Actions.REQUEST_USER_AVATAR_UPDATE_ERROR,
            payload: errorPayload,
          });
        });
    })
    .share();

  @Effect() requestUserResendInviteEffects$ = this.actions$
    .ofType(Actions.REQUEST_USER_RESEND_INVITE)
    .switchMap((action: UnsafeAction) => {
      return this.tokenService
        .post(
          `/api/users/${action.payload.user_id}/resend_invite`,
          ''
        )
        .map((response: Response) => response.json())
        .map((data: any[]) => {
          const results = normalize(data, userSchema);

          return {
            entities: results.entities,
          };
        })
        .switchMap((results) => {
          return Observable.from([
            {
              type: Actions.LOADED_ENTITIES,
              payload: results.entities,
            },
            {
              type: Actions.REQUEST_USER_RESEND_INVITE_SUCCESS,
            },
          ]);
        })
        .catch((error) => {
          const errorPayload = error.json();

          return Observable.of({
            type: Actions.REQUEST_USER_RESEND_INVITE_ERROR,
            payload: errorPayload,
          });
        });
    })
    .share();

  @Effect() userSignedOut$ = this.actions$
    .ofType(Actions.REQUEST_USER_SIGN_OUT_SUCCESS)
    .switchMap(() => {
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = window.location.origin;
      return Observable.of({ type: Actions.NO_OP });
    })
    .share();
}
