import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

import { BakeryOrder } from 'app/store/bakery-order';

@Component({
  selector: 'bkry-mgmt-orders-sum-tile',
  template: `
    <dashboard-tile
      bgColor="green-base"
      icon="graph"
      iconAriaLabel="Graph"
    >
      <div data-title>{{ sum | centsObservable | async }}</div>
      <div data-subtitle>Sales generated from {{ bakeryOrdersCount }} orders</div>
    </dashboard-tile>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrdersSumTileComponent {
  sum = 0;

  private _bakeryOrders: BakeryOrder[] = [];
  @Input()
  set bakeryOrders(bakeryOrders: BakeryOrder[]) {
    this._bakeryOrders = bakeryOrders;

    this.recalculateSum();
  }

  get bakeryOrders(): BakeryOrder[] {
    return this._bakeryOrders;
  }

  get bakeryOrdersCount(): number {
    return this.bakeryOrders.length;
  }

  private recalculateSum() {
    this.sum = this.bakeryOrders
      .reduce((sum: number, currBo: BakeryOrder) => {
        return sum + currBo.total_price;
      }, 0);
  }
}
