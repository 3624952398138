import {
  Component,
  Input,
  Output,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AppState } from 'app/store/app-state';
import * as selectors from 'app/store/selectors';
import {
  bakeryProductSchema,
  wholesalerGroupSchema
} from 'app/store/schema/default-schemata';
import { BakeryProductProductTemplate } from 'app/store/bakery-product-product-template';
import { WholesalerGroup } from 'app/store/wholesaler-group';
import { BakeryMgmtUiState } from 'app/store/bakery-mgmt-ui-state';
import { GetAction } from 'app/store/actions/request.actions';
import { EntitiesState } from 'app/store/entities-state';
import { PricedProductVariation } from 'app/wholesaler/priced-product-variation';
import { buildPricedProductVariations } from 'app/wholesaler/build-priced-product-variations';
import { User } from 'app/store/user';

const productsOpKey = 'bakeryProducts',
      productsOpSchema = [bakeryProductSchema],
      wholesalerGroupOpKey = 'wholesalerGroup',
      wholesalerGroupOpSchema = wholesalerGroupSchema;

@Component({
  template: `
    <h3>Price Index</h3>
    <wholesaler-price-index-table
      [isLoading]="isLoading$|async"
      [pricedProductVariations]="pricedProductVariations$|async"
    ></wholesaler-price-index-table>
  `,
})
export class PriceIndexComponent implements OnInit {
  isLoading$: Observable<boolean> = Observable
    .combineLatest(
      this.store
        .select(selectors.getRequestIsLoading(productsOpKey))
        .startWith(true),
      this.store
        .select(selectors.getRequestIsLoading(wholesalerGroupOpKey))
        .startWith(true)
    )
    .map(isLoadings => isLoadings.some(isLoading => isLoading === true));

  wholesalerGroup$: Observable<WholesalerGroup> = this.store
    .select(selectors.getRequestResult(wholesalerGroupOpKey, wholesalerGroupOpSchema));

  bakeryProducts$: Observable<WholesalerGroup> = this.store
    .select(selectors.getRequestResult(productsOpKey, productsOpSchema));

  pricedProductVariations$: Observable<PricedProductVariation[]> = this.isLoading$
    // Only continue when we're done loading
    .filter(isLoading => !isLoading)
    // Get the wholesaler group, as well as the latest copy of the entities
    .withLatestFrom(
      this.wholesalerGroup$,
      this.store.select('entitiesState'),
      (isLoading, wholesalerGroup, entitiesState) => ({entitiesState, wholesalerGroup})
    )
    // Pass that all to the PricedProductVariation builder/mapper
    .map(({entitiesState, wholesalerGroup}: {entitiesState: EntitiesState, wholesalerGroup: WholesalerGroup}): PricedProductVariation[] => {
      return buildPricedProductVariations(entitiesState, wholesalerGroup);
    });

  constructor(
    private store: Store<AppState>,
  ) { }

  ngOnInit() {
    this.store
      .select('bakeryCustUiState', 'bakeryId')
      .filter(val => !!val)
      .take(1)
      .subscribe((bakeryId: number) => {
          const actionPayload = {
            url: `/api/bakeries/${bakeryId}/bakery_products`,
            opKey: productsOpKey,
            schema: productsOpSchema,
            queryParams: {
              archived: false
            }
          };
          this.store.dispatch(new GetAction(actionPayload));
      });

    this.store
      .select(selectors.getCurrentUser)
      .filter(val => !!val)
      .map((user: User) => user.wholesaler_org.wholesaler_group_id)
      .take(1)
      .subscribe((wholesalerGroupId: number) => {
          const actionPayload = {
            url: `/api/wholesaler_groups/${wholesalerGroupId}`,
            opKey: wholesalerGroupOpKey,
            schema: wholesalerGroupOpSchema,
          };
          this.store.dispatch(new GetAction(actionPayload));
      });
  }
}
