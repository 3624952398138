import { Component, Input, OnInit } from '@angular/core';
import * as Rx from 'rxjs';

import { PopoutService } from 'app/shared/components/popouts/popout.service';

@Component({
  selector: 'popout-trigger',
  template: `
  <a class="clickable popout__trigger"
    (click)="onClickPopoutActive($event)"
    [ngClass]="classStyles$ | async">
    <ng-template [ngIf]="icon">
      <i aria-label="User Menu Icon"
        [inlineSVG]="iconPath"></i>
    </ng-template>
    <ng-template [ngIf]="text">
      {{ text }}
    </ng-template>
  </a>
  `,
})
export class PopoutTriggerComponent implements OnInit {
  @Input() icon: string;
  @Input() text: string;
  @Input() isButton = false;
  @Input() buttonSize = 'small';
  @Input() buttonStyle = 'default';

  constructor(
    private popoutService: PopoutService,
  ) { }

  classStyles$: Rx.Observable<any>;

  ngOnInit() {
    this.classStyles$ = this.popoutService.popoutActive$
      .startWith(false)
      .map((isActive: boolean) => {
        const baseStyles = {
          'is-active': isActive,
        };

        if (this.isButton) {
          baseStyles[`button--${this.buttonSize}`] = true;
          baseStyles[`button--${this.buttonStyle}`] = true;
        }

        return baseStyles;
      });
  }

  get popoutActive$(): Rx.Observable<boolean> {
    return this.popoutService.popoutActive$;
  }

  get iconPath(): string {
    return require(`angular/assets/images/icons/icons-functional/icon-${this.icon}.svg`);
  }

  onClickPopoutActive($event: Event) {
    $event.stopPropagation();

    this.popoutService.togglePopoutActive();
  }
}
