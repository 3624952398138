export interface ResourceRequestState<T> {
  state: 'loading'|'success'|'error';
  result: T;
}

export interface DetailResourceRequestState<T> extends ResourceRequestState<T> {
  detailId: number;
}

export interface NormalizedResourceRequestState<T, N> {
  state: 'loading'|'success'|'error';
  wiredResult?: T;
  result: N;
}

export interface SimplifiedResourceRequestState<N> {
  detailId?: number;
  isLoading: boolean;
  result: null|Error|N;
}

export function updateResourceLoading(state) {
  return {
    ...state,
    isLoading: true,
    result: null,
  };
}

export function updateResourceComplete(state, result) {
  return {
    ...state,
    isLoading: false,
    result: result,
  };
}
