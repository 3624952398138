import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { EntitiesState } from 'app/store/entities-state';

import { Actions } from 'app/store/actions';

export const LOADED_ENTITIES = 'LOADED_ENTITIES';
export const REMOVE_ENTITY = 'REMOVE_ENTITY';
export const UPDATE_ENTITY_PROPERTIES = 'UPDATE_ENTITY_PROPERTIES';

const INIT_STATE: EntitiesState = {
  users: {},
  roles: {},
  bakeries: {},
  bakery_locations: {},
  subscription_plan_types: {},
  subscription_plans: {},
  feature_modules: {},
  bakery_subscription_statuses: {},
  bakery_subscription_invoices: {},
  product_categories: {},
  bakery_products: {},
  bakery_attributes: {},
  bakery_attribute_variants: {},
  bakery_product_attributes: {},
  bakery_product_templates: {},
  bakery_product_product_templates: {},
  bakery_carts: {},
  order_items: {},
  bakery_orders: {},
  bakery_order_invoices: {},
  promo_codes: {},
  bakery_invoice_payments: {},
};

export function entitiesStateReducer(
  state: EntitiesState = INIT_STATE,
  action: UnsafeAction
): EntitiesState {
  switch (action.type) {
    case REMOVE_ENTITY:
      console.log(`Removing entities with: `, action.payload);
      return removeEntity(
        state,
        action.payload.typeKey,
        action.payload.entityKey
      );

    case LOADED_ENTITIES:
      console.log(`Extending entities with: `, action.payload);
      return extendEntities(state, action.payload);

    case UPDATE_ENTITY_PROPERTIES:
      return updateEntityProperties(state, action.payload);

    case Actions.BAKERY_ORDER_REFUND_SUCCEEDED:
      return {
        ...state,
        bakery_order_invoices: {
          ...state.bakery_order_invoices,
          [action.payload.bakery_order_invoice.id]: action.payload.bakery_order_invoice
        },
        bakery_invoice_payments: {
          ...state.bakery_invoice_payments,
          ...action.payload.bakery_invoice_payments
        }
      };

    default:
      return state;
  }
}

function extendEntities(state, newEntitiesArg) {
  const clonedEntities = { ...state };

  // Sometime the new entities argument is just a hash with entities, and
  // sometimes it's a normalizr-type result that has entities and result keys.
  // Use the following logic to detect which is being provided in this instance,
  // and make sure that `newEntities` points to the entities bundle.
  let newEntities;
  if (newEntitiesArg.entities != null) {
    newEntities = newEntitiesArg.entities;
  } else {
    newEntities = newEntitiesArg;
  }

  Object.keys(newEntities).forEach(typeName => {
    const newTypedEntities = newEntities[typeName];
    const existingTypedEntities =
      clonedEntities[typeName] == null ? {} : { ...clonedEntities[typeName] };

    // clonedMap[entityName] = addToMap(clonedMapValue, newEntities[entityName]);
    Object.keys(newTypedEntities).forEach(entityId => {
      if (existingTypedEntities[entityId] == null) {
        existingTypedEntities[entityId] = {};
      }

      existingTypedEntities[entityId] = {
        ...existingTypedEntities[entityId],
        ...newTypedEntities[entityId]
      };
    });

    clonedEntities[typeName] = existingTypedEntities;
  });

  return clonedEntities;
}

function removeEntity(state, typeKey, entityKey) {
  const clonedMap = Object.assign({}, state);

  clonedMap[typeKey] = removeFromMap(clonedMap[typeKey], entityKey);

  return clonedMap;
}

function updateEntityProperties(state, payload) {
  const clonedMap = Object.assign({}, state);

  clonedMap[payload.typeKey][payload.entityKey] = Object.assign(
    clonedMap[payload.typeKey][payload.entityKey],
    payload.properties
  );

  return clonedMap;
}

function addToMap(map, newEntries) {
  return Object.assign({}, map, newEntries);
}

function removeFromMap(map, key) {
  const clonedMap = Object.assign({}, map);

  delete clonedMap[key];

  return clonedMap;
}
