import { Component, OnInit, Input, Output, ChangeDetectionStrategy } from '@angular/core';
import * as Rx from 'rxjs';

@Component({
  selector: 'bakery-mgmt-wholesalers-wholesaler-list-item',
  template: require('./list-item.component.html'),
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WholesalerListItemComponent implements OnInit {
  @Input('wholesalerOrg') wholesalerOrg: any;
  @Output('onClickShowDetails') onClickShowDetails$ = new Rx.Subject<any>();

  ngOnInit() {
  }

  onClickDetails() {
    this.onClickShowDetails$.next(this.wholesalerOrg);
  }

  onClickCheckbox($event: Event) {
    $event.stopPropagation();
  }

  get status(): string {
    if (this.wholesalerOrg.archived) return 'archived';

    return this.wholesalerOrg.account_status;
  }
}
