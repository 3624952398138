import {
  Component,
  Input,
  ViewChild,
  ViewContainerRef,
  TemplateRef,
  OnInit,
} from '@angular/core';

import {
  ModalService,
  Modal,
  ModalAction,
} from 'app/shared/components/modals/modal.service';
import svg from 'angular/assets/images/icons/icons-functional/icon-close.svg';

@Component({
  selector: 'modal-container',
  template: `
    <div class="modal" [ngClass]="{'modal--active': isModalVisible}" (click)="onClickBackground()">
      <div class="modal__overlay">
        <div class="modal__container">
          <div class="modal__body {{klass}}" (click)="onClickIntercept($event)">
            <a (click)="closeModal()" class="modal__close clickable">
              <i
                aria-label="User Menu Icon"
                inlineSVG="{{ svg }}"></i>
            </a>
            <header class="modal__header">
              <h3 class="title">{{ title }}</h3>
              <p class="">{{ intro }}</p>
            </header>
            <div>
              <ng-template #modalContainer></ng-template>
              <div class="modal__actions">
                <ul class="modal__actions-list">
                  <li *ngIf="!!this.cancelText" class="modal__actions-item">
                    <button
                      (click)="onClickCancel()"
                      class="button--default button--full"
                    >
                      {{ this.cancelText }}
                    </button>
                  </li>
                  <li class="modal__actions-item">
                    <button
                      (click)="onClickConfirm()"
                      class="button--full"
                      [ngClass]="buttonStyleClass"
                    >
                      {{ this.confirmText }}
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class ModalContainerComponent implements OnInit {
  @ViewChild('modalContainer', { read: ViewContainerRef }) modalContainer: ViewContainerRef;

  private defaultCancelText = 'Cancel';
  private defaultConfirmText = 'Confirm';
  private defaultConfirmButtonStyle = 'danger';
  private defaultKlass = '';

  svg = svg;
  modal: Modal;
  title = '';
  intro = '';
  cancelText?: string;
  confirmText = this.defaultConfirmText;
  confirmButtonStyle = this.defaultConfirmButtonStyle;
  templateRef: TemplateRef<any>;
  isModalVisible = false;
  klass = this.defaultKlass;

  constructor(
    private modalService: ModalService,
  ) { }

  get buttonStyleClass(): string {
    return `button--${this.confirmButtonStyle}`;
  }

  ngOnInit() {
    this.modalService.modalActions$
      .subscribe(
        (modalAction: ModalAction) => {
          const modal = modalAction.modal;

          switch (modalAction.action) {
            case 'open':
              this.modal = modal;
              this.title = modal.title;
              this.intro = modal.intro;
              this.templateRef = modal.content;

              this.cancelText = modal.options.cancelText;
              this.confirmText = modal.options.confirmText != null ? modal.options.confirmText : this.defaultConfirmText;
              this.confirmButtonStyle = modal.options.confirmButtonStyle != null ? modal.options.confirmButtonStyle : this.defaultConfirmButtonStyle;
              this.klass = modal.options.klass ? modal.options.klass : this.defaultKlass;

              this.openModal();
              break;
            case 'close':
              this.onClickCancel();
              break;
          }
        }
      )
  }

  openModal() {
    if (this.templateRef != null) {
      this.modalContainer.createEmbeddedView(this.templateRef);
    }
    this.isModalVisible = true;

    const showTabTable = (tabTable?: HTMLElement) => {
      if (!tabTable) { return; }
      getAllTabTables().forEach(tabTable_ => tabTable_.style.display = 'none');
      tabTable.style.display = 'block';
    };
    const setActiveClass = (tabButton?: HTMLElement) => {
      if (!tabButton) { return; }
      getAllTabButtons().forEach(tabButton_ => tabButton_.classList.remove('is-active'));
      tabButton.classList.add('is-active');
    };
    const getAllTabButtons = () =>
      Array.from(document.getElementsByClassName('tabButton') as HTMLCollectionOf<HTMLElement>);
    const getAllTabTables = () =>
      Array.from(document.getElementsByClassName('tabTable') as HTMLCollectionOf<HTMLElement>);

    showTabTable(getAllTabTables()[0]);
    setActiveClass(getAllTabButtons()[0]);
    getAllTabButtons().forEach(tabButton => {
      tabButton.addEventListener('click', event => {
        setActiveClass(tabButton);
        showTabTable(document.getElementById(tabButton.dataset.tabTableId));
      });
    });
  }

  closeModal() {
    this.isModalVisible = false;
    this.modalContainer.clear();
  }

  onClickCancel() {
    this.closeModal();

    this.modal.onCancel();
    this.modal = null;
  }

  onClickConfirm() {
    this.closeModal();

    this.modal.onConfirm();
    this.modal = null;
  }

  onClickIntercept($event: Event) {
    $event.stopPropagation();
  }

  onClickBackground() {
    this.onClickCancel();
  }
}
