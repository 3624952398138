import { Component, ViewChild } from '@angular/core';

import { ModalComponent } from 'app/shared/components/modals/modal.component';

@Component({
  selector: 'products-setup-examples-popup',
  template: `
    <a class="clickable" (click)="onClickOpenModal()">See the examples</a>
    <modal
      title="Products Setup Examples"
      intro="See our examples of Standard and Advanced product setup"
      confirmText="Got it!"
      confirmButtonStyle="secondary"
      klass="modal__body-products-modal"
    >
      <div class="tabs">
        <ul class="tab__list tab__list--light margin-top--charlie">
          <li class="tab__item tab__item--big tabButton" data-tab-table-id="tabTableStandard">
            Standard Products
          </li>
          <li class="tab__item tab__item--big tabButton" data-tab-table-id="tabTableAdvance">
            Advanced Products
          </li>
        </ul>
      </div>
      <div class="table--overflow-sm table--products-examples margin-bottom--charlie">
        <table class="table tabTable" id="tabTableStandard">
          <thead>
            <tr>
              <th width="50%">Product</th>
              <th width="50%">Variation</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><p><strong>Donut</strong></p></td>
              <td>
                <p>Plain {{ 200 | centsObservable | async }}</p>
                <p>Chocolate {{ 250 | centsObservable | async }}</p>
                <p>Strawberry {{ 250 | centsObservable | async }}</p>
              </td>
            </tr>
            <tr>
              <td><p><strong>Cherry Pie</strong></p></td>
              <td>
                <p>8” Pie {{ 1000 | centsObservable | async }}</p>
                <p>12“ Pie {{ 1500 | centsObservable | async }}</p>
              </td>
            </tr>
            <tr>
              <td><p><strong>Box of donuts (no pricing variation depending on donut flavor)</strong></p></td>
              <td>
                <p>1 donut {{ 200 | centsObservable | async }}</p>
                <p>6 donuts {{ 800 | centsObservable | async }}</p>
                <p>12 donuts {{ 1400 | centsObservable | async }}</p>
              </td>
            </tr>
          </tbody>
        </table>
        <table class="table tabTable" id="tabTableAdvance">
          <thead>
            <tr>
              <th width="20%">Product</th>
              <th width="20%">Template</th>
              <th width="45%">Attribute</th>
              <th width="15%">Category</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td rowspan="3"><p><strong>Floral cake</strong></p></td>
              <td><p>Full Sheet - {{ 9500 | centsObservable | async }}</p></td>
              <td>
                <p>Full Sheet flavor</p>
                <p>Full Sheet filling</p>
                <p>Full Sheet decoration</p>
                <p>Full Sheet frosting</p>
              </td>
              <td rowspan="3"><p>Cakes</p>
                <p>Birthday</p>
                <p>Buttercream</p>
              </td>
            </tr>
            <tr>
              <td><p>Half Sheet - {{ 7500 | centsObservable | async }}</p></td>
              <td>
                <p>Half Sheet flavor</p>
                <p>Half Sheet filling</p>
                <p>Half Sheet decoration</p>
                <p>Half Sheet frosting</p>
              </td>
            </tr>
            <tr>
              <td><p>8 inch round - {{ 4500 | centsObservable | async }}</p></td>
              <td>
                <p>8 inch flavor</p>
                <p>8 inch filling</p>
                <p>8 inch decoration</p>
                <p>8 inch frosting</p>
              </td>
            </tr>
            <tr>
              <td rowspan="3"><p><strong>Wedding Cake</strong></p></td>
              <td><p>Full Sheet - {{ 9500 | centsObservable | async }}</p></td>
              <td><p>Full Sheet flavor</p>
                <p>Full Sheet filling</p>
                <p>Full Sheet decoration</p>
                <p>Full Sheet frosting</p>
              </td>
              <td rowspan="3">
                <p>Cakes</p>
                <p>Birthday</p>
                <p>Buttercream</p>
              </td>
            </tr>
            <tr>
              <td><p>Half Sheet - {{ 7500 | centsObservable | async }}</p></td>
              <td>
                <p>Half Sheet flavor</p>
                <p>Half Sheet filling</p>
                <p>Half Sheet decoration</p>
                <p>Half Sheet frosting</p>
              </td>
            </tr>
            <tr>
              <td><p>8 inch round - {{ 4500 | centsObservable | async }}</p></td>
              <td>
                <p>8 inch flavor</p>
                <p>8 inch filling</p>
                <p>8 inch decoration</p>
                <p>8 inch frosting</p>
              </td>
            </tr>
            <tr>
              <td rowspan="2"><p><strong>Banquet</strong></p></td>
              <td><p>20 people - {{ 15000 | centsObservable | async }}</p></td>
              <td>
                <p>20 people banquet flavor cake pops</p>
                <p>20 people banquet flavor muffins</p>
                <p>20 people banquet flavor cookies</p>
                <p>banquet main cake</p>
                <p>20 people dietary requirements</p>
              </td>
              <td rowspan="2"><p>Banqet</p><p>Birthday</p><p>Cakepops</p><p>Cookies</p></td>
            </tr>
            <tr>
              <td><p>60 people - {{ 20000 | centsObservable | async }}</p></td>
              <td>
                <p>60 people banquet flavor cake pops</p>
                <p>60 people banquet flavor muffins</p>
                <p>60 people banquet flavor cookies</p>
                <p>banquet main cake</p>
                <p>banquet secondary cake</p>
                <p>60 people dietary requirements</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </modal>
  `,
})
export class ProductsSetupExamplesPopupComponent {
  @ViewChild(ModalComponent) modal: ModalComponent;

  constructor() {}

  onClickOpenModal() {
    this.modal.openModal();
  }
}
