import { Component, OnInit, OnDestroy, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import { BakeryLocation } from 'app/store/bakery-location';
import { Bakery } from 'app/store/bakery';
import { phoneNumberValidator } from 'app/shared/forms/form-number-validator';
import { validateNoWhitespaces } from 'app/shared/forms/no-whitespaces-validator';
import { Constants } from 'app/constants';

@Component({
  selector: 'bakery-mgmt-store-location-detail-edit',
  template: require('./location-detail-edit.component.html'),
})
export class BakeryMgmtStoreLocationDetailEditComponent implements OnInit, OnDestroy {
  @Input() location$: Observable<BakeryLocation>;
  @Input() success$: Observable<any>;
  @Input() bakery$: Observable<Bakery>;
  @Output() submitForm = new EventEmitter<any>();

  mask = createNumberMask({ prefix: '', suffix: '%', allowDecimal: true, decimalLimit: 2 });
  detailsForm: FormGroup;
  constants = Constants;
  countryCode$: Observable<string>;
  private resetFormEvents = new EventEmitter<any>();
  private detailsFormSub: Subscription;
  private resetFormSub: Subscription;
  private locationHoursSub: Subscription;

  constructor() { }

  ngOnInit() {
    this.detailsForm = new FormGroup({
      name: new FormControl(null, [Validators.required, validateNoWhitespaces]),
      phone: new FormControl(null, [Validators.required, phoneNumberValidator]),
      street: new FormControl(null, [Validators.required, validateNoWhitespaces]),
      city: new FormControl(null, [Validators.required, validateNoWhitespaces]),
      state: new FormControl(null, [Validators.required, validateNoWhitespaces]),
      zip: new FormControl(null, [Validators.required]),
      fulfillment_type: new FormControl(null, [Validators.required]),
      monday: this.buildDayFormControl,
      tuesday: this.buildDayFormControl,
      wednesday: this.buildDayFormControl,
      thursday: this.buildDayFormControl,
      friday: this.buildDayFormControl,
      saturday: this.buildDayFormControl,
      sunday: this.buildDayFormControl,
      tax_rate: new FormControl(null, [Validators.required])
    });

    this.countryCode$ = this.bakery$.pluck('country_code');

    this.detailsFormSub = this.location$
      .filter(location => location != null)
      .subscribe((location: BakeryLocation) => {
        this.setDetailsFormValues(location);
      });

    this.resetFormSub = this.resetFormEvents
      .switchMap(() => {
        return this.location$
          .filter(location => location != null)
          .take(1)
      })
      .subscribe((location: BakeryLocation) => this.resetDetailsForm(location));

    this.locationHoursSub = this.detailsForm.valueChanges
      .subscribe((location: BakeryLocation) => {
        this.setStateOfHoursSelect(location);
      });


    this.success$
      .switchMap(() => {
        return this.location$
          .filter(location => location != null)
          .take(1)
      })
      .subscribe((location: BakeryLocation) => this.resetDetailsForm(location));
  }

  ngOnDestroy() {
    this.detailsFormSub.unsubscribe();
    this.resetFormSub.unsubscribe();
    this.locationHoursSub.unsubscribe();
  }

  private get buildDayFormControl() {
    return new FormGroup({
      open: new FormControl(null, [Validators.required]),
      open_at: new FormControl(null, [Validators.required]),
      close_at: new FormControl(null, [Validators.required]),
    })
  }

  private resetDetailsForm(location: BakeryLocation) {
    this.detailsForm.reset(this.buildFormValue(location));
    this.setStateOfHoursSelect(location);
  }

  private get daysOfTheWeek(): string[] {
    return ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
  }

  private setDetailsFormValues(location: BakeryLocation) {
    this.detailsForm.setValue(this.buildFormValue(location));
    this.setStateOfHoursSelect(location);
  }

  private setStateOfHoursSelect(location: BakeryLocation) {
    this.daysOfTheWeek.map(day => {
      if (location.hours) {
        !location.hours[day].open ? this.disableHoursSelect(day) : this.enableHoursSelect(day);
      } else if (location[day]) {
        !location[day].open ? this.disableHoursSelect(day) : this.enableHoursSelect(day);
      }
    });
  }

  private buildFormValue(location: BakeryLocation) {
    return {
      name: location.name,
      phone: location.phone,
      street: location.street,
      city: location.city,
      state: location.state || null,
      zip: location.zip,
      fulfillment_type: location.fulfillment_type,
      monday: {
        open: location.hours.monday.open,
        open_at: location.hours.monday.open_at,
        close_at: location.hours.monday.close_at,
      },
      tuesday: {
        open: location.hours.tuesday.open,
        open_at: location.hours.tuesday.open_at,
        close_at: location.hours.tuesday.close_at,
      },
      wednesday: {
        open: location.hours.wednesday.open,
        open_at: location.hours.wednesday.open_at,
        close_at: location.hours.wednesday.close_at,
      },
      thursday: {
        open: location.hours.thursday.open,
        open_at: location.hours.thursday.open_at,
        close_at: location.hours.thursday.close_at,
      },
      friday: {
        open: location.hours.friday.open,
        open_at: location.hours.friday.open_at,
        close_at: location.hours.friday.close_at,
      },
      saturday: {
        open: location.hours.saturday.open,
        open_at: location.hours.saturday.open_at,
        close_at: location.hours.saturday.close_at,
      },
      sunday: {
        open: location.hours.sunday.open,
        open_at: location.hours.sunday.open_at,
        close_at: location.hours.sunday.close_at,
      },
      tax_rate: String(location.tax_rate),
    };
  }

  onClickResetForm() {
    this.resetFormEvents.next();
  }

  private disableHoursSelect(day) {
    this.detailsForm.get(day).get('open_at').disable({ onlySelf: true });
    this.detailsForm.get(day).get('close_at').disable({ onlySelf: true });
  }

  private enableHoursSelect(day) {
    this.detailsForm.get(day).get('open_at').enable({ onlySelf: true });
    this.detailsForm.get(day).get('close_at').enable({ onlySelf: true });
  }

  onSubmitForm() {
    // this.detailsFormSubmitEvents.next();

    const updatePayload = {
      name: this.detailsForm.value.name,
      phone: this.detailsForm.value.phone,
      street: this.detailsForm.value.street,
      city: this.detailsForm.value.city,
      state: this.detailsForm.value.state,
      zip: this.detailsForm.value.zip,
      fulfillment_type: this.detailsForm.value.fulfillment_type,
      hours: {
        monday: this.detailsForm.value.monday,
        tuesday: this.detailsForm.value.tuesday,
        wednesday: this.detailsForm.value.wednesday,
        thursday: this.detailsForm.value.thursday,
        friday: this.detailsForm.value.friday,
        saturday: this.detailsForm.value.saturday,
        sunday: this.detailsForm.value.sunday,
      },
      tax_rate: this.detailsForm.value.tax_rate,
    };

    // TODO: emit update
    this.submitForm.next(updatePayload);
  }
}
