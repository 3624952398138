import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import * as Rx from 'rxjs';

@Component({
  selector: 'bakery-mgmt-dashboard-order-stats',
  template: require('./order-stats.component.html'),
})
export class OrderStatsComponent {
  @Input() orderStats: any;
}
