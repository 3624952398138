import {
  Component,
  Input,
  Output,
  ChangeDetectionStrategy,
  OnInit
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AppState } from 'app/store/app-state';
import * as selectors from 'app/store/selectors';
import { GetAction } from 'app/store/actions/request.actions';
import { DetailPaneWrapperComponent } from '../detail-pane-wrapper';
import {
  BakeryOrder,
  allOrderStatuses,
  orderFulfillmentDateDescSorter
} from 'app/store/bakery-order';
import { WholesalerOrg } from 'app/store/wholesaler-org';
import { OrderSchedule } from 'app/store/order-schedule';
import {
  bakeryOrderSchema,
  orderScheduleSchema
} from 'app/store/schema/default-schemata';
import { cloneSort } from 'app/store/entity-utils';

const ordersOpKey = 'wholesalerOrderHistory',
      ordersSchema = [bakeryOrderSchema],
      orderSchedulesOpKey = 'wholesalerOrderSchedules',
      orderSchedulesSchema = [orderScheduleSchema];

@Component({
  template: `
    <!-- Status tiles -->
    <div gridRow>
      <bkry-mgmt-orders-sum-tile
        gridCol s="4" m="5" l="6"
        [bakeryOrders]="bakeryOrders$ | async"
      ></bkry-mgmt-orders-sum-tile>
      <bkry-mgmt-next-order-date-tile
        gridCol s="4" m="4" l="6"
        [bakeryOrders]="bakeryOrders$ | async"
      ></bkry-mgmt-next-order-date-tile>
    </div>
    <bkry-mgmt-wholesaler-recurring-orders-card
      [isLoading]="orderSchedulesIsLoading$ | async"
      [orderSchedules]="orderSchedules$ | async"
    ></bkry-mgmt-wholesaler-recurring-orders-card>
    <!-- Order history table -->
    <bkry-mgmt-orders-history-card
      [isLoading]="ordersIsLoading$ | async"
      [bakeryOrders]="bakeryOrders$ | async"
    ></bkry-mgmt-orders-history-card>
  `,
})
export class OrdersComponent extends DetailPaneWrapperComponent implements OnInit {
  ordersIsLoading$: Observable<boolean> = this.store
    .select(selectors.getRequestIsLoading(ordersOpKey));

  bakeryOrders$: Observable<BakeryOrder[]> = this.store
    .select(selectors.getRequestResult(ordersOpKey, ordersSchema))
    .filter(val => !!val)
    // Sort by fulfillment_date, descending
    .map((bakeryOrders: BakeryOrder[]) => cloneSort(bakeryOrders, orderFulfillmentDateDescSorter))
    .startWith([]);

  orderSchedulesIsLoading$: Observable<boolean> = this.store
    .select(selectors.getRequestIsLoading(orderSchedulesOpKey));

  orderSchedules$: Observable<OrderSchedule[]> = this.store
    .select(selectors.getRequestResult(orderSchedulesOpKey, orderSchedulesSchema))
    .filter(val => !!val)
    .startWith([]);

  constructor(
    store: Store<AppState>,
  ) {
    super(store);
  }

  ngOnInit() {
    this.wholesalerOrg$
      .filter(val => !!val)
      .take(1)
      .subscribe((wholesalerOrg: WholesalerOrg) => {
          const ordersActionPayload = {
            url: `/api/bakeries/${wholesalerOrg.bakery_id}/orders`,
            opKey: ordersOpKey,
            schema: ordersSchema,
            queryParams: {
              parent_type: 'WholesalerOrg',
              parent_id: wholesalerOrg.id,
              order_status: allOrderStatuses
            }
          };
          this.store.dispatch(new GetAction(ordersActionPayload));

          const orderSchedulesActionPayload = {
            url: `/api/order_schedules`,
            opKey: orderSchedulesOpKey,
            schema: orderSchedulesSchema,
            queryParams: {
              wholesaler_org_id: wholesalerOrg.id,
              active_status: 'active,hold'
            }
          };
          this.store.dispatch(new GetAction(orderSchedulesActionPayload));
      });
  }
}
