import { Component, OnInit, OnDestroy, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as Rx from 'rxjs';

import { User } from 'app/store/user';
import { PaymentCard } from 'app/store/payment-card';
import { SimpleFormState } from 'app/shared/forms/form-states';

const reducer = (state: PaymentCard[], action): PaymentCard[] => {
  switch (action.type) {
    case 'LOAD':
      return [...action.payload];

    case 'ADD_CARD':
      return [...state, action.payload];

    case 'REMOVE_CARD':
      if (!!action.payload.stripe_token) {
        const matchingCardIndex = state
          .findIndex(card => card.stripe_token === action.payload.stripe_token);

        const newState = [...state];
        newState.splice(matchingCardIndex, 1);

        return newState;
      } else {
        const matchingCardIndex = state.findIndex(card => card.id === action.payload.id);
        const updatedCard = {...action.payload, _destroy: true};

        return [...state.slice(0, matchingCardIndex), updatedCard, ...state.slice(matchingCardIndex + 1)];
      }
  }

  return state;
};

@Component({
  selector: 'bakery-cust-account-setting-user-credit-card',
  template: `
    <user-credit-card
      [paymentCards]="enabledPaymentCards$ | async"
      [reset$]="reset$"
      (addCard$)="addCard$.next($event)"
      (removeCard$)="removeCard$.next($event)"
    ></user-credit-card>
  `,
})
export class BakeryCustAccountSettingsUserCreditCardComponent implements OnInit, OnDestroy {
  @Input('user$') user$: Rx.Observable<User>;
  @Input('reset$') reset$: Rx.Observable<any>;
  @Output('formState') formState$ = new Rx.BehaviorSubject<SimpleFormState>({isDirty: false, isValid: true, value: {}});

  addCard$ = new Rx.Subject<PaymentCard>();
  removeCard$ = new Rx.Subject<PaymentCard>();
  paymentCards$: Rx.Observable<PaymentCard[]>;
  enabledPaymentCards$: Rx.Observable<PaymentCard[]>;

  private userValuesSub: Rx.Subscription;
  private action$ = new Rx.Subject<{type: string, payload: any}>();

  constructor(
  ) {
    this.paymentCards$ = this.action$
      .do(val => console.log(`inline action:`, val))
      .scan(reducer, [])
      .startWith([])
      .shareReplay(1);

    this.enabledPaymentCards$ = this.paymentCards$
      .map((paymentCards: PaymentCard[]) => {
        return paymentCards.filter(card => !card._destroy);
      })
      .startWith([]);

    this.paymentCards$
      .subscribe((paymentCards: PaymentCard[]) => {
        const modifiedPaymentCards = paymentCards.filter(card => !card.id || !!card._destroy);

        this.formState$.next({
          isDirty: modifiedPaymentCards.length > 0,
          isValid: true,
          value: {
            payment_cards_attributes: modifiedPaymentCards,
          },
        });
      });

    // this.action$.subscribe(action => console.log(`action$ sub:`, action))
    // this.paymentCards$.subscribe(paymentCard => console.log(`paymentCard$ sub:`, paymentCard))
  }

  ngOnInit() {
    this.userValuesSub = Rx.Observable
      .merge(
        this.user$,
        this.reset$.switchMap(() => this.user$.take(1))
      )
      .subscribe((user: User) => {
        this.action$.next({
          type: 'LOAD',
          payload: user.payment_cards,
        });
      });

    // FIXME: these need subs and corresponding unsubscribes
    this.addCard$
      .withLatestFrom(
        this.user$,
        (card, user) => ({card, user})
      )
      .subscribe(({card, user}) => {
        card.owner_type = 'User';
        card.owner_id = user.id;

        this.action$.next({
          type: 'ADD_CARD',
          payload: card,
        });
      });
    this.removeCard$
      .subscribe((card) => {
        this.action$.next({
          type: 'REMOVE_CARD',
          payload: card,
        });
      });
  }

  ngOnDestroy() {
    this.userValuesSub.unsubscribe();
  }

  onClickRemoveCard(card: any) {
    this.action$.next({
      type: 'REMOVE_CARD',
      payload: card,
    });
  }
}
