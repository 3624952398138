import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'orderCustomerType'})
export class OrderCustomerTypePipe implements PipeTransform {
  public transform(orderCustomerType: string) {
    let result = '???';

    if (orderCustomerType == null || typeof orderCustomerType.trim !== 'function') {
      return result;
    }

    switch (orderCustomerType) {
      case 'retail':
        result = 'Retail';
        break;
      case 'wholesale':
        result = 'Wholesale';
        break;
    }

    return result;
  }
}
