import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { Store } from '@ngrx/store';
import { Angular2TokenService } from 'app/angular2-token/angular2-token.service';
import { Actions as NgRxActions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';
import { normalize, schema } from 'normalizr';

import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { AppState } from 'app/store/app-state';
import { Actions } from 'app/store/actions';
import { BakeryMgmtEffects } from 'app/store/effects/bakery-mgmt.effects';
import { Bakery } from 'app/store/bakery';
import { User } from 'app/store/user';
import { BakeryMgmtUiState } from 'app/store/bakery-mgmt-ui-state';
import { EntitiesState } from 'app/store/entities-state';
import { basicRequestHandler, addEntityRequestHandler } from 'app/store/effects/helpers';
import { userSchema } from 'app/store/schema/default-schemata';
import * as selectors from 'app/store/selectors';

@Injectable()
export class BakeryMgmtCustomersEffects {

  constructor(
    private actions$: NgRxActions,
    private tokenService: Angular2TokenService,
    private store: Store<AppState>,
    private bakeryMgmtEffects: BakeryMgmtEffects,
  ) { }

  requestGetCustomers(payload = {}) {
    this.store.dispatch({
      type: Actions.REQUEST_GET_BAKERY_CUSTOMERS,
      payload,
    });
  }

  requestGetCustomers2(isWholesale: boolean, query?: string) {
    this.store.dispatch({
      type: Actions.REQUEST_GET_BAKERY_CUSTOMERS2,
      payload: { query, isWholesale }
    });
  }

  requestGetCustomer(id: number) {
    this.store.dispatch({
      type: Actions.REQUEST_GET_BAKERY_CUSTOMER,
      payload: id,
    });
  }

  requestBakeryInviteCustomer(inviteDetails) {
    this.store.dispatch({
      type: Actions.REQUEST_BAKERY_INVITE_CUSTOMER,
      payload: inviteDetails,
    });
  }

  changeArchiveFilter(newArchivedFilterValue: boolean) {
    this.store.dispatch({
      type: Actions.CHANGE_BAKERY_CUSTOMERS_ARCHIVE_FILTER,
      payload: newArchivedFilterValue,
    });
  }

  changeUserFilter(filterText: string) {
    this.store.dispatch({
      type: Actions.CHANGE_BAKERY_CUSTOMERS_SEARCH_FILTER,
      payload: filterText,
    });
  }

  private currentBakeryLatestMap(action: UnsafeAction): Observable<{action: UnsafeAction, bakery: Bakery}> {
    return this.bakeryMgmtEffects.currentBakery$
      .filter(bakery => bakery != null)
      .map(bakery => ({action, bakery}))
      .take(1);
  }

  @Effect() getBakeryCustomers$ = this.actions$
    .ofType(Actions.REQUEST_GET_BAKERY_CUSTOMERS)
    .switchMap(this.currentBakeryLatestMap.bind(this))
    .switchMap(({action, bakery}) => {
      return this.tokenService.get(`/api/bakeries/${bakery.id}/customers`, { params: action.payload })
        .map((response: Response) => response.json())
        .switchMap(response => {
          return Observable.of({ type: Actions.REQUEST_GET_BAKERY_CUSTOMERS_SUCCESS, payload: response });
        })
        .catch(() => {
          return Observable.of({ type: Actions.REQUEST_GET_BAKERY_CUSTOMERS_ERROR });
        });
    })
    .share();

  @Effect() getBakeryCustomers2$ = this.actions$
    .ofType(Actions.REQUEST_GET_BAKERY_CUSTOMERS2)
    .combineLatest(
      this.store.select(selectors.getBakeryMgmtCurrentBakeryId),
      (action: UnsafeAction, bakeryId) => ({ action, bakeryId })
    )
    .skipWhile(({ bakeryId }) => !bakeryId)
    .switchMap(({ action, bakeryId }) => {
      const query = [
        `type=${action.payload.isWholesale ? 'wholesale' : 'retail'}`,
        action.payload.query ? `query=${encodeURIComponent(action.payload.query)}` : ''
      ].filter(x => x.length > 0).join('&');
      return this.tokenService.get(`/api/bakeries/${bakeryId}/customers2?${query}`)
        .map((response: Response) => response.json().data)
        .switchMap(response => {
          return Observable.of({ type: Actions.REQUEST_GET_BAKERY_CUSTOMERS_SUCCESS2, payload: response });
        })
        .catch(() => {
          return Observable.of({ type: Actions.REQUEST_GET_BAKERY_CUSTOMERS_ERROR2 });
        });
    })
    .share();

  @Effect() getBakeryCustomer$ = this.actions$
    .ofType(Actions.REQUEST_GET_BAKERY_CUSTOMER)
    .switchMap(this.currentBakeryLatestMap.bind(this))
    .switchMap(({action, bakery}) => {
      return addEntityRequestHandler(
        this.tokenService.get(`/api/bakeries/${bakery.id}/customers/${action.payload}`),
        userSchema,
        Actions.REQUEST_GET_BAKERY_CUSTOMER,
      );
    })
    .share();

    @Effect() bakeryInviteCustomer$ = this.actions$
      .ofType(Actions.REQUEST_BAKERY_INVITE_CUSTOMER)
      .switchMap(this.currentBakeryLatestMap.bind(this))
      .switchMap(({action, bakery}) => {
        return addEntityRequestHandler(
          this.tokenService.post(
            `/api/bakeries/${bakery.id}/customers/invites`,
            JSON.stringify(action.payload)
          ),
          userSchema,
          Actions.REQUEST_BAKERY_INVITE_CUSTOMER,
        );
      })
      .share();
}
