import { BakeryAttribute } from '../bakery-attribute';
import { BakeryProductAttribute } from '../bakery-product-attribute';
import { BakeryProduct } from '../bakery-product';
import { EntitiesState } from '../entities-state';
import { bakeryAttributeSchema } from 'app/store/schema/default-schemata';
import { denormalize as denormalizr } from 'normalizr';

export function denormalize(
  bakeryAttribute: BakeryAttribute,
  entitiesState: EntitiesState
): BakeryAttribute {
  const bakery_attribute = denormalizr(
    bakeryAttribute,
    bakeryAttributeSchema,
    entitiesState
  );
  return {
    ...bakery_attribute,
    bakery_products: getUniqueBakeryProducts(
      bakery_attribute.bakery_product_attributes
    )
  };
}

const getUniqueBakeryProducts = bakery_product_attributes =>
  bakery_product_attributes
    .map(attribute => {
      let bakery_products = attribute.bakery_products;
      if (!Array.isArray(attribute.bakery_products)) {
        bakery_products = [bakery_products];
      }
     return bakery_products.map(bakery_product => bakery_product);
    })
    .reduce((acc, x) => acc.concat(x), [])
    .filter((x, i, xs) => xs.map(_ => _.id).indexOf(x.id) === i);
