import { Component, Input, Output, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { Subject } from 'rxjs/Subject';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Actions as NgRxActions } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { AppState } from 'app/store/app-state';
import { AlertService } from 'app/shared/components/alerts/alert.service';
import { Actions } from 'app/store/actions';
import { BakeryProduct } from 'app/store/bakery-product';
import { DetailUiState } from 'app/bakery-mgmt/product-mgmt/products/detail-ui-state';
import { SimpleFormState, SubmittableFormState } from 'app/shared/forms/form-states';

@Component({
  selector: 'product-detail-form',
  template: require('./detail-form.component.html'),
})
export class BakeryMgmtProductProductDetailFormComponent implements OnInit, OnDestroy {
  @Input('uiState$') uiState$: Observable<DetailUiState>;
  @Input() persisted: boolean;
  @Output('submitForm') submitFormValues$ = new Subject<any>();
  @Output('resetForm') resetForm$ = new Subject<any>();
  @Input('advanced$') advanced$;
  @Input('setAdvancedProduct') setAdvancedProduct: Function;
  formState$: Observable<SubmittableFormState>;
  detailsForm: FormGroup;
  updateSuccess$: Observable<any>;

  private submitFormSub: Subscription;

  constructor(
  ) {
  }

  submitForm$ = new Subject<any>();
  imageFormState$ = new Subject<SimpleFormState>();
  descriptionFormState$ = new Subject<SimpleFormState>();
  templatesFormState$ = new BehaviorSubject<SimpleFormState>({isDirty: false, isValid: false, value: null});
  attributesFormState$ = new BehaviorSubject<SimpleFormState>({isDirty: false, isValid: true, value: null});
  storePresentationFormState$ = new Subject<SimpleFormState>();
  availabilityFormState$ = new Subject<SimpleFormState>();
  categoriesFormState$ = new Subject<SimpleFormState>();
  taxesFormState$ = new Subject<SimpleFormState>();
  editCautionVisible$: Observable<boolean>;

  ngOnInit() {
    this.formState$ = Observable
      .combineLatest(
        this.imageFormState$,
        this.descriptionFormState$,
        this.templatesFormState$,
        this.attributesFormState$,
        this.storePresentationFormState$,
        this.availabilityFormState$,
        this.categoriesFormState$,
        this.taxesFormState$
      )
      .map((simpleFormStates: SimpleFormState[]): SubmittableFormState => {
          const isDirty = simpleFormStates.some(formState => formState.isDirty);
          const isValid = simpleFormStates.every(formState => formState.isValid);
          const values = simpleFormStates.map(formState => formState.value);

          return {
            isValid: isValid,
            isDirty: isDirty,
            isSubmittable: isDirty && isValid,
            value: Object.assign({}, ...values)
          };
        }
      )
      .startWith({isDirty: false, isValid: false, isSubmittable: false, value: {}})
      .do(formState => console.log(`[detail-form] formState:`, formState))
      .shareReplay(1);

    this.editCautionVisible$ = this.formState$
      .map((formState: SubmittableFormState) => formState.isDirty);

    this.submitFormSub = this.submitForm$
      .switchMap(() => this.formState$.take(1))
      .withLatestFrom(
        this.uiState$,
        (formState, uiState) => ({formState, uiState})
      )
      .subscribe(({formState, uiState}) => {
        if (formState.isSubmittable) {
          this.submitFormValues$.next(formState.value);
        }
      });
  }

  ngOnDestroy() {
    this.submitFormSub.unsubscribe();
  }

  onClickFormReset() {
    this.resetForm$.next();
  }

  onClickFormSubmit() {
    this.submitForm$.next();
  }
}
