import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { Actions } from 'app/store/actions';

export interface MasterState {
  type: 'bakesmart'|'custom_bakery';
  bakeryDomain: string;
}

const INIT_STATE: MasterState = {
  type: null,
  bakeryDomain: null,
};

export function masterStateReducer(state: MasterState = INIT_STATE, action: UnsafeAction): MasterState {
  switch (action.type) {
    case Actions.SET_APP_OPERATION_MODE:
      return {
        ...state,
        type: action.payload.type,
        bakeryDomain: action.payload.bakeryDomain,
      };

    default:
      return state;
  }
}
