import { Component, Input, OnInit } from '@angular/core';
import * as Rx from 'rxjs';

@Component({
  selector: 'toggle-item',
  template: `
    <div
      class="toggle-item"
      [ngClass]="{'active': isExpanded}"
      (click)="onClickHeader()">
      <ng-content
        select=".toggle-item-header"
      ></ng-content>
    </div>
    <ng-content
      select=".toggle-item-body"
      *ngIf="isExpanded"
    ></ng-content>
  `,
})
export class ToggleItemComponent implements OnInit {
  private _isExpanded = false;

  ngOnInit() {
  }

  get isExpanded(): boolean {
    return this._isExpanded;
  }

  onClickHeader() {
    this.toggleIsExpanded();
  }

  toggleIsExpanded() {
    this._isExpanded = !this.isExpanded;
  }
}
