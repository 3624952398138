import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as Rx from 'rxjs';
import { Actions as NgRxActions } from '@ngrx/effects';

import { AppState } from 'app/store/app-state';
import { Actions } from 'app/store/actions';
import { PaymentProviderConfig } from 'app/store/payment-provider-config';
import * as selectors from 'app/store/selectors';

declare var process: any;

@Component({
  selector: 'bkry-mgmt-store-payments-stripe-connect-provider',
  template: require('./stripe-connected.component.html'),
})
export class BakeryMgmtStoreMainPaymentsStripeConnectProviderComponent implements OnInit {
  stripeConnectOauthUrl: string;
  params$: Rx.Observable<any>;
  stripeOauthCode$: Rx.Observable<string>;
  stripeProviderConfig$: Rx.Observable<PaymentProviderConfig>;
  disconnectStripeAccount$ = new EventEmitter();

  private _paymentProviderConfig: PaymentProviderConfig;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<AppState>,
    private actions$: NgRxActions,
  ) {
    const clientId = process.env.STRIPE_CONNECT_CLIENT_ID;
    const redirectUri = encodeURIComponent(
      process.env.STRIPE_CONNECT_REDIRECT_URL
    );
    this.stripeConnectOauthUrl = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${clientId}&scope=read_write&redirect_uri=${redirectUri}`;

    this.stripeProviderConfig$ = this.store.select(
      selectors.getPaymentProviderConfigByTypeKey('stripe')
    );
  }

  ngOnInit() {
    this.params$ = this.route.queryParams;
    this.stripeOauthCode$ = this.params$.map((params: any): string => params['code']);

    Rx.Observable
      .combineLatest(
        this.stripeOauthCode$
          .filter(val => val != null),
        this.stripeProviderConfig$
          .filter(val => val != null),
        (oauthCode, providerConfig) => ({oauthCode, providerConfig})
      )
      .take(1)
      .subscribe(({oauthCode, providerConfig}) => {
        this.store.dispatch({
          type: Actions.REQUEST_UPDATE_PAYMENT_PROVIDER_CONFIG,
          payload: {
            id: providerConfig.id,
            action_name: 'connect',
            action_params: {
              connect_oauth_code: oauthCode,
            },
          },
        });
      });

    this.actions$
      .ofType(Actions.REQUEST_UPDATE_PAYMENT_PROVIDER_CONFIG_ERROR, Actions.REQUEST_UPDATE_PAYMENT_PROVIDER_CONFIG_SUCCESS)
      .subscribe(action => {
        // Reset URL to wipe query params.
        this.router.navigate(['/bakery_mgmt/store_mgmt/main/payments']);
      });

    this.disconnectStripeAccount$
      .switchMap(() => this.stripeProviderConfig$.take(1))
      .subscribe(providerConfig => {
        this.store.dispatch({
          type: Actions.REQUEST_UPDATE_PAYMENT_PROVIDER_CONFIG,
          payload: {
            id: providerConfig.id,
            action_name: 'disconnect',
            action_params: {},
          },
        });
      })
  }
}
