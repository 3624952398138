import { schema } from 'normalizr';

import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { TypedAction, namespacedType } from 'app/store/actions/typed-action';

const namespace = 'Request';
const partialNamespace = (label: string) => namespacedType(namespace, label);

interface GenericSchemaEntityPayload {
  opKey: string;
  schema: schema.Entity|schema.Entity[];
  successChainAction?: UnsafeAction|UnsafeAction[];
}

export interface GetActionPayload extends GenericSchemaEntityPayload {
  url: string;
  queryParams?: {[key: string]: any};
}

export class GetAction extends TypedAction {
  static type = partialNamespace('Get');

  constructor(public payload: GetActionPayload) { super(payload) }
}

export interface ApiSuccessActionPayload extends GenericSchemaEntityPayload {
  result: number|number[];
}

export class RequestSuccessAction extends TypedAction {
  static type = partialNamespace('Request Success');

  constructor(public payload: ApiSuccessActionPayload) { super(payload) }
}

export interface PutActionPayload extends GenericSchemaEntityPayload {
  url: string;
  body: {[key: string]: any};
}

export class PutAction extends TypedAction {
  static type = partialNamespace('Put');

  constructor(public payload: PutActionPayload) { super(payload) }
}

export class PostAction extends TypedAction {
  static type = partialNamespace('Post');

  constructor(public payload: PutActionPayload) { super(payload) }
}
