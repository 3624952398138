import { UnsafeAction } from 'app/store/effects/unsafe-action';
import {
  BakeryMgmtUiState,
  CustomersUiState,
  EmployeesUiState,
  LocationsUiState,
  MasterDetailUiState,
  PromoCodesUiState,
} from 'app/store/bakery-mgmt-ui-state';
import { Actions } from 'app/store/actions';
import * as storePayments from 'app/store/reducers/bakery-mgmt-ui-state/store-payments-state.reducer';
import * as orderMgmt from 'app/store/reducers/bakery-mgmt-ui-state/order-mgmt-state';
import * as dashboard from 'app/store/reducers/bakery-mgmt-ui-state/dashboard-state.reducer';
import * as wholesaler from 'app/store/reducers/bakery-mgmt-ui-state/wholesaler-state';
import * as wholesalerGroup from 'app/store/reducers/bakery-mgmt-ui-state/wholesaler-group-state';
import * as bakeryOrderState from 'app/store/reducers/bakery-mgmt-ui-state/bakery-employee-order-state.reducer';

const INIT_EMPLOYEES_STATE: EmployeesUiState = {
  isEmployeesLoading: false,
  archivedStatusFilter: false,
  searchFilter: '',
  isEmployeeLoading: false,
  detailId: null,
};

export function employeesUiStateReducer(
  state: EmployeesUiState = INIT_EMPLOYEES_STATE,
  action: UnsafeAction
): EmployeesUiState {
  switch (action.type) {
    case Actions.REQUEST_BAKERY_EMPLOYEES:
      return Object.assign({}, state, {isEmployeesLoading: true});

    case Actions.REQUEST_BAKERY_EMPLOYEES_SUCCESS:
      return Object.assign({}, state, {isEmployeesLoading: false});

    case Actions.REQUEST_BAKERY_EMPLOYEE:
      return Object.assign({}, state, {isEmployeeLoading: true, detailId: action.payload});

    case Actions.REQUEST_BAKERY_EMPLOYEE_SUCCESS:
      return Object.assign({}, state, {isEmployeeLoading: false});

    case Actions.CHANGE_BAKERY_EMPLOYEES_ARCHIVE_FILTER:
      return Object.assign({}, state, {archivedStatusFilter: action.payload});

    case Actions.CHANGE_BAKERY_EMPLOYEES_SEARCH_FILTER:
      return Object.assign({}, state, {searchFilter: action.payload});
  }

  return state;
}

const INIT_PROMO_CODES_STATE: PromoCodesUiState = {
  isLoading: false,
  statusFilter: 'active',
  filterText: '',
};

export function promoCodesUiStateReducer(
  state: PromoCodesUiState = INIT_PROMO_CODES_STATE,
  action: UnsafeAction
): PromoCodesUiState {
  switch (action.type) {
    case Actions.REQUEST_GET_PROMO_CODES:
      return Object.assign({}, state, {isLoading: true});

    case Actions.REQUEST_GET_PROMO_CODES_SUCCESS:
      return Object.assign({}, state, {isLoading: false});

    case Actions.CHANGE_PROMO_CODES_STATUS_FILTER:
      return Object.assign({}, state, {statusFilter: action.payload});

    case Actions.CHANGE_PROMO_CODES_SEARCH_FILTER:
      return Object.assign({}, state, {filterText: action.payload});
  }

  return state;
}

const INIT_CUSTOMERS_STATE: CustomersUiState = {
  masterState: {
    state: null,
    result: null,
    filterText: '',
    archivedStatusFilter: false,
  },
  detailState: {
    state: null,
    result: null,
    detailId: null,
  },
};

export function customersUiStateReducer(
  state: CustomersUiState = INIT_CUSTOMERS_STATE,
  action: UnsafeAction
): CustomersUiState {
  let users;
  switch (action.type) {
    case Actions.REQUEST_GET_BAKERY_CUSTOMERS:
      return {
        ...state,
        masterState: {
          ...state.masterState,
          state: 'loading',
          result: []
        },
      };

    case Actions.REQUEST_GET_BAKERY_CUSTOMERS_SUCCESS:
      users = action.payload.users != null ?
        Object.keys(action.payload.users).map(key => action.payload.users[key])
        : [];

      return {
        ...state,
        masterState: {
          ...state.masterState,
          state: 'success',
          result: users,
        },
      };

    case Actions.CHANGE_BAKERY_CUSTOMERS_SEARCH_FILTER:
      return {
        ...state,
        masterState: {
          ...state.masterState,
          filterText: action.payload,
        },
      };

    case Actions.CHANGE_BAKERY_CUSTOMERS_ARCHIVE_FILTER:
      return {
        ...state,
        masterState: {
          ...state.masterState,
          archivedStatusFilter: action.payload,
        },
      };

    case Actions.REQUEST_GET_BAKERY_CUSTOMER:
      return {
        ...state,
        detailState: {
          ...state.detailState,
          state: 'loading',
          result: null,
          detailId: action.payload,
        },
      };

    case Actions.REQUEST_GET_BAKERY_CUSTOMER_SUCCESS:
      users = action.payload.users != null ?
        Object.keys(action.payload.users).map(key => action.payload.users[key])
        : [];

      return {
        ...state,
        detailState: {
          ...state.detailState,
          state: 'success',
          result: users[0],
        },
      };
  }

  return state;
}

const INIT_LOCATIONS_STATE: LocationsUiState = {
  isLocationsLoading: false,

  isLocationLoading: false,
  detailId: null,
};

export function locationsUiStateReducer(
  state: LocationsUiState = INIT_LOCATIONS_STATE,
  action: UnsafeAction
): LocationsUiState {
  switch (action.type) {
    case Actions.REQUEST_BAKERY_LOCATIONS:
      return Object.assign({}, state, {isLocationsLoading: true});

    case Actions.REQUEST_BAKERY_LOCATIONS_SUCCESS:
      return Object.assign({}, state, {isLocationsLoading: false});

    case Actions.REQUEST_BAKERY_LOCATION:
      return Object.assign({}, state, {isLocationLoading: true, detailId: action.payload});

    case Actions.REQUEST_BAKERY_LOCATION_SUCCESS:
      return Object.assign({}, state, {isLocationLoading: false});
  }

  return state;
}

const INIT_PRODUCT_CATEGORIES_STATE: MasterDetailUiState = {
  isMasterLoading: false,
  detailId: null,
  isDetailLoading: false,
};

export function productCategoriesUiStateReducer(
  state: MasterDetailUiState = INIT_PRODUCT_CATEGORIES_STATE,
  action: UnsafeAction
): MasterDetailUiState {
  switch (action.type) {
    case Actions.REQUEST_GET_PRODUCT_CATEGORIES:
      return Object.assign({}, state, {isMasterLoading: true});

    case Actions.REQUEST_GET_PRODUCT_CATEGORIES_SUCCESS:
      return Object.assign({}, state, {isMasterLoading: false});

    case Actions.REQUEST_GET_PRODUCT_CATEGORY:
    case Actions.REQUEST_GET_PRODUCT_CATEGORY_DETAILS:
      return Object.assign({}, state, {isDetailLoading: true, detailId: action.payload});

    case Actions.REQUEST_GET_PRODUCT_CATEGORY_SUCCESS:
    case Actions.REQUEST_GET_PRODUCT_CATEGORY_DETAILS_SUCCESS:
      return Object.assign({}, state, {isDetailLoading: false});
  }

  return state;
}

const INIT_BAKERY_PRODUCT_TEMPLATES_STATE: MasterDetailUiState = {
  isMasterLoading: false,
  detailId: null,
  isDetailLoading: false,
};

export function bakeryProductTemplatesUiStateReducer(
  state: MasterDetailUiState = INIT_BAKERY_PRODUCT_TEMPLATES_STATE,
  action: UnsafeAction
): MasterDetailUiState {
  switch (action.type) {
    case Actions.REQUEST_GET_BAKERY_PRODUCT_TEMPLATES:
      return Object.assign({}, state, {isMasterLoading: true});

    case Actions.REQUEST_GET_BAKERY_PRODUCT_TEMPLATES_SUCCESS:
      return Object.assign({}, state, {isMasterLoading: false});

    case Actions.REQUEST_GET_BAKERY_PRODUCT_TEMPLATE:
    case Actions.REQUEST_GET_BAKERY_PRODUCT_TEMPLATE_DETAILS:
      return Object.assign({}, state, {isDetailLoading: true, detailId: action.payload});

    case Actions.REQUEST_GET_BAKERY_PRODUCT_TEMPLATE_SUCCESS:
    case Actions.REQUEST_GET_BAKERY_PRODUCT_TEMPLATE_DETAILS_SUCCESS:
      return Object.assign({}, state, {isDetailLoading: false});
  }

  return state;
}

const INIT_BAKERY_ATTRIBUTES_STATE: MasterDetailUiState = {
  isMasterLoading: false,
  detailId: null,
  isDetailLoading: false,
};

export function bakeryAttributesUiStateReducer(
  state: MasterDetailUiState = INIT_BAKERY_ATTRIBUTES_STATE,
  action: UnsafeAction
): MasterDetailUiState {
  switch (action.type) {
    case Actions.REQUEST_GET_BAKERY_ATTRIBUTES:
      return Object.assign({}, state, {isMasterLoading: true});

    case Actions.REQUEST_GET_BAKERY_ATTRIBUTES_SUCCESS:
      return Object.assign({}, state, {isMasterLoading: false});

    case Actions.REQUEST_GET_BAKERY_ATTRIBUTE:
    case Actions.REQUEST_GET_BAKERY_ATTRIBUTE_DETAILS:
      return Object.assign({}, state, {isDetailLoading: true, detailId: action.payload});

    case Actions.REQUEST_GET_BAKERY_ATTRIBUTE_SUCCESS:
    case Actions.REQUEST_GET_BAKERY_ATTRIBUTE_DETAILS_SUCCESS:
      return Object.assign({}, state, {isDetailLoading: false});
  }

  return state;
}

const INIT_PRODUCTS_STATE: MasterDetailUiState = {
  isMasterLoading: false,
  detailId: null,
  isDetailLoading: false,
};

export function productsUiStateReducer(
  state: MasterDetailUiState = INIT_PRODUCTS_STATE,
  action: UnsafeAction
): MasterDetailUiState {
  switch (action.type) {
    case Actions.REQUEST_GET_BAKERY_PRODUCTS:
      return Object.assign({}, state, {isMasterLoading: true});

    case Actions.REQUEST_GET_BAKERY_PRODUCTS_SUCCESS:
      return Object.assign({}, state, {isMasterLoading: false});

    case Actions.REQUEST_GET_BAKERY_PRODUCT:
      return Object.assign({}, state, {isDetailLoading: true, detailId: action.payload});

    case Actions.REQUEST_GET_BAKERY_PRODUCT_SUCCESS:
      return Object.assign({}, state, {isDetailLoading: false});
  }

  return state;
}

const INIT_BAKERY_MGMT_STATE: BakeryMgmtUiState = {
  bakeryId: null,
  employeesUiState: undefined,
  locationsUiState: undefined,
  productsUiState: undefined,
  productCategoriesUiState: undefined,
  bakeryProductTemplatesUiState: undefined,
  bakeryAttributesUiState: undefined,
  storePaymentsState: storePayments.initialState,
  orderMgmtState: orderMgmt.initialState,
  dashboardState: dashboard.initialState,
  wholesalerState: wholesaler.initialState,
  wholesalerGroupState: wholesalerGroup.initialState,
  bakeryOrderState: bakeryOrderState.initialState,
  cartState: {
    detailId: null,
    state: null,
    result: null,
  },
};

export function bakeryMgmtUiStateReducer(
  state: BakeryMgmtUiState = INIT_BAKERY_MGMT_STATE,
  action: UnsafeAction
): BakeryMgmtUiState {
  state = Object.assign({}, state, {
    employeesUiState: employeesUiStateReducer(state.employeesUiState, action),
    customersUiState: customersUiStateReducer(state.customersUiState, action),
    promoCodesUiState: promoCodesUiStateReducer(state.promoCodesUiState, action),
    locationsUiState: locationsUiStateReducer(state.locationsUiState, action),
    productCategoriesUiState: productCategoriesUiStateReducer(state.productCategoriesUiState, action),
    bakeryProductTemplatesUiState: bakeryProductTemplatesUiStateReducer(state.bakeryProductTemplatesUiState, action),
    bakeryAttributesUiState: bakeryAttributesUiStateReducer(state.bakeryAttributesUiState, action),
    productsUiState: productsUiStateReducer(state.productsUiState, action),
    storePaymentsState: storePayments.reducer(state.storePaymentsState, action),
    bakeryOrderState: bakeryOrderState.reducer(state.bakeryOrderState, action),
    orderMgmtState: orderMgmt.reducer(state.orderMgmtState, action),
    dashboardState: dashboard.reducer(state.dashboardState, action),
    wholesalerState: wholesaler.reducer(state.wholesalerState, action),
    wholesalerGroupState: wholesalerGroup.reducer(state.wholesalerGroupState, action),
  });

  switch (action.type) {
    case Actions.REQUEST_OWN_BAKERY_SUCCESS:
      return {...state, bakeryId: action.payload};

    case Actions.REQUEST_GET_EMPLOYEE_CART:
      return {
        ...state,
        cartState: {
          ...state.cartState,
          state: 'loading',
        }
      };

    case Actions.REQUEST_GET_EMPLOYEE_CART_ERROR:
      return {
        ...state,
        cartState: {
          ...state.cartState,
          state: 'error',
          detailId: null,
        }
      };

    case Actions.REQUEST_GET_EMPLOYEE_CART_SUCCESS:
    case Actions.REQUEST_UPDATE_EMPLOYEE_CART_SUCCESS:
    case Actions.REQUEST_CREATE_EMPLOYEE_CART_SUCCESS:
    case Actions.ADD_CUSTOMER_TO_EMPLOYEE_CART_SUCCESS:
      const successBakeryCart = action.payload.bakery_carts != null ?
        Object.keys(action.payload.bakery_carts).map(key => action.payload.bakery_carts[key])[0]
        : null;
      return {
        ...state,
        cartState: {
          ...state.cartState,
          state: 'success',
          result: successBakeryCart,
          detailId: successBakeryCart != null ? successBakeryCart.id : null,
        },
      };

    case Actions.REQUEST_DELETE_EMPLOYEE_CART_SUCCESS:
      return {
        ...state,
        cartState: {
          ...state.cartState,
          state: null,
          result: null,
          detailId: null,
        },
      };

    default:
      return state;
  }
}
