import {
  Component,
  forwardRef,
  HostBinding,
  Input,
  ElementRef,
  ViewChild,
  OnInit,
  ChangeDetectionStrategy
} from '@angular/core';
import {
  FormGroup,
  Validators,
  FormBuilder
} from '@angular/forms';

import { WholesalerGroup } from 'app/store/wholesaler-group';
import { GenericControlValueInput } from 'app/shared/components/form/generic-control-value-input';
import { buildForwardRef } from 'app/shared/components/form/input-forward-ref-builder';

interface FormData {
  invoice_interval: string;
  wholesaler_group_id: number;
}

@Component({
  selector: 'wholesaler-org-settings-org-account-input',
  template: `
    <form
      [formGroup]="form"
      (submit)="onSubmitForm()"
    >
      <div gridRow>
        <label>Wholesale Group</label>
        <select formControlName="wholesaler_group_id">
          <option disabled [ngValue]="null">Please Select One</option>
          <option
            *ngFor="let wholesalerGroup of wholesalerGroups"
            [ngValue]="wholesalerGroup.id"
          >
            {{ wholesalerGroup.name }}
          </option>
        </select>
      </div>
      <div gridRow>
        <label>Wholesale Invoice Interval</label>
        <select formControlName="invoice_interval">
          <option disabled [ngValue]="null">Select One</option>
          <option value="automatic">Automatic</option>
          <option value="manual">Manual</option>
        </select>
      </div>
    </form>
  `,
  providers: buildForwardRef(OrgAccountInputComponent),
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrgAccountInputComponent extends GenericControlValueInput<FormData> implements OnInit {
  @Input() wholesalerGroups: WholesalerGroup[] = [];

  value: FormData = {invoice_interval: null, wholesaler_group_id: null};

  form: FormGroup;

  constructor(
    private fb: FormBuilder
  ) {
    super();

    this.form = fb.group({
      invoice_interval: fb.control(null, [Validators.required]),
      wholesaler_group_id: fb.control(null, [Validators.required]),
    });
  }

  ngOnInit() {
    this.form.valueChanges
      .subscribe((formValue) => {
        this.onChangedCallback(formValue);
      })
  }

  onSubmitForm() {
    this.form.reset();
  }

  writeValue(val: FormData) {
    this.value = val;

    this.resetForm();
  }

  private resetForm() {
    this.form.reset(
      {
        invoice_interval: this.value.invoice_interval,
        wholesaler_group_id: this.value.wholesaler_group_id,
      },
      {emitEvent: false}
    )
  }
}
