import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { AuthState } from 'app/store/auth-state';
import { Actions } from 'app/store/actions';

const INIT_STATE: AuthState = {
  isAuthenticationComplete: false,
  isLoggedIn: false,
};

export function authStateReducer(state: AuthState = INIT_STATE, action: UnsafeAction): AuthState {
  switch (action.type) {
    case Actions.REQUEST_VALIDATE_TOKEN:
      return Object.assign({}, state, {isAuthenticationComplete: false});

    case Actions.REQUEST_VALIDATE_TOKEN_SUCCESS:
    case Actions.REQUEST_USER_SIGN_IN_SUCCESS:
      return Object.assign({}, state, {
        isLoggedIn: true,
        currentUser: action.payload,
        isAuthenticationComplete: true,
      });

    case Actions.REQUEST_VALIDATE_TOKEN_ERROR:
      return Object.assign({}, state, {
        isLoggedIn: false,
        isAuthenticationComplete: true,
      });

    case Actions.UPDATE_CURRENT_USER:
      return Object.assign({}, state, {
        currentUser: action.payload,
      });

    default:
      return state;
  }
}
