import { Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable()
export class PopoutService {
  popoutActive$ = new BehaviorSubject<boolean>(false);

  constructor() { }

  togglePopoutActive() {
    this.popoutActive$.next(!this.popoutActive$.value);
  }

  closePopout() {
    this.popoutActive$.next(false);
  }
}
