import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { Store } from '@ngrx/store';
import { Angular2TokenService } from 'app/angular2-token/angular2-token.service';
import { Actions as NgRxActions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';
import { normalize, schema } from 'normalizr';

import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { AppState } from 'app/store/app-state';
import { Actions } from 'app/store/actions';
import { Bakery } from 'app/store/bakery';
import { BakeryMgmtUiState } from 'app/store/bakery-mgmt-ui-state';
import { EntitiesState } from 'app/store/entities-state';
import { deepBakerySchema } from 'app/store/schema/bakery';
import { basicRequestHandler, addEntityRequestHandler } from 'app/store/effects/helpers';

@Injectable()
export class BakeryMgmtEffects {
  currentBakery$: Observable<Bakery>;

  constructor(
    private actions$: NgRxActions,
    private tokenService: Angular2TokenService,
    private store: Store<AppState>,
  ) {
    this.currentBakery$ = Observable
      .combineLatest(
        this.store.select('entitiesState')
          .filter(entities => entities != null),
        this.store.select('bakeryMgmtUiState')
          .filter((uiState: BakeryMgmtUiState) => uiState.bakeryId != null),
        (entities, bakeryMgmtUiState) => ({entities, bakeryMgmtUiState})
      )
      .map((combined) => {
        const entities = <EntitiesState> combined.entities;
        const bakeryId = (<BakeryMgmtUiState>combined.bakeryMgmtUiState).bakeryId;

        // Check to see if the bakery has loaded yet. If not, return null.
        if (entities.bakeries[bakeryId] == null) {
          return null;
        }

        // Hydrate/de-normalize the Bakery
        const bakery = Object.assign({}, entities.bakeries[bakeryId]);

        bakery.bakery_subscription_status = Object.assign({},
          entities.bakery_subscription_statuses[<number>bakery.bakery_subscription_status]
        )

        bakery.bakery_subscription_status.feature_modules = (<number[]>bakery.bakery_subscription_status.feature_modules)
          .map((featureModuleId: number) => {
            return entities.feature_modules[featureModuleId];
          })

        return bakery;
      })
      .shareReplay(1);
  }

  requestGetOwnBakery() {
    this.store.dispatch({
      type: Actions.REQUEST_OWN_BAKERY,
    });
  }

  requestDestroyOwnBakery() {
    this.store.dispatch({
      type: Actions.REQUEST_DESTROY_OWN_BAKERY,
    });
  }

  requestUpdateOwnBakery(updatePayload: any) {
    this.store.dispatch({
      type: Actions.REQUEST_UPDATE_OWN_BAKERY,
      payload: updatePayload,
    })
  }

  private currentBakeryLatestMap(action: UnsafeAction): Observable<{action: UnsafeAction, bakery: Bakery}> {
    return this.currentBakery$
      .filter(bakery => bakery != null)
      .map(bakery => ({action, bakery}))
      .take(1);
  }

  // FIXME: No error handler here--or error action type, either.
  @Effect() getOwnBakery$ = this.actions$
    .ofType(Actions.REQUEST_OWN_BAKERY)
    .switchMap((action: UnsafeAction) => {
      return this.tokenService.get(`api/bakery`)
        .map((response: Response) => response.json())
        .map((data: any[]) => {
          const results = normalize(data, deepBakerySchema);

          return {
            entities: results.entities,
            bakeryId: results.result,
          };
        })
        .switchMap((results) => {
          return Observable.from([
            {
              type: Actions.LOADED_ENTITIES,
              payload: results.entities,
            },
            {
              type: Actions.REQUEST_OWN_BAKERY_SUCCESS,
              payload: results.bakeryId,
            },
          ]);
        })
    })
    .share();

  @Effect() destroyOwnBakeryEffects$ = this.actions$
    .ofType(Actions.REQUEST_DESTROY_OWN_BAKERY)
    .switchMap((action: UnsafeAction) => {
      return this.tokenService.delete(`api/bakery`)
        .map((response: Response) => response.json())
        .switchMap((results) => {
          return Observable.from([
            {
              type: Actions.REQUEST_DESTROY_OWN_BAKERY_SUCCESS,
            },
            {
              type: Actions.REQUEST_USER_SIGN_OUT_SUCCESS,
            },
          ]);
        })
    })
    .share();

  @Effect() updateOwnBakeryEffects$ = this.actions$
    .ofType(Actions.REQUEST_UPDATE_OWN_BAKERY)
    .switchMap(this.currentBakeryLatestMap.bind(this))
    .switchMap(({action, bakery}) => {
      return addEntityRequestHandler(
        this.tokenService.put(
          `/api/bakery`,
          JSON.stringify(action.payload)
        ),
        deepBakerySchema,
        Actions.REQUEST_UPDATE_OWN_BAKERY,
      );
    })
    .share();
}
