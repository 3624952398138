import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as Rx from 'rxjs';

import { AppState } from 'app/store/app-state';
import { Actions } from 'app/store/actions';
import { PaymentProviderConfig } from 'app/store/payment-provider-config';
import * as selectors from 'app/store/selectors';

@Component({
  selector: 'bkry-mgmt-store-payments-cash-provider',
  template: `
  <form [formGroup]="form">
    <div class="card padding--reset margin-bottom--charlie">
      <header class="padding-top--delta padding-left--delta padding-right--delta">
        <h6 class="margin-reset--bottom">Manual Payments</h6>
      </header>
      <hr />
      <div class="padding-left--delta padding-right--delta">
        <div class="row">
          <div class="columns-8__s columns-12__l">
            <p>Do you want to allow your customers to make payments outside of the system? If so please indicate below. This will allow customers to select this method upon checkout.</p>
          </div>
        </div>
        <div class="row">
          <div class="columns-8__s columns-9__m columns-12__l">
            <label>Manual Payments</label>
            <select formControlName="enabled">
              <option disabled [ngValue]="null">Select One</option>
              <option [ngValue]="true">Allow customers to pay manually</option>
              <option [ngValue]="false">Do not allow customers to pay manually</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </form>
  `
})
export class BakeryMgmtStoreMainPaymentsCashProviderComponent implements OnInit {
  form: FormGroup;

  private _paymentProviderConfig: PaymentProviderConfig;

  constructor(
    private store: Store<AppState>,
  ) {
    this.form = new FormGroup({
      enabled: new FormControl(null, []),
    });
  }

  @Input('paymentProviderConfig')
  set paymentProviderConfig(paymentProviderConfig: PaymentProviderConfig) {
    this._paymentProviderConfig = paymentProviderConfig;

    this.form.patchValue(
      {
        enabled: paymentProviderConfig.enabled,
      },
      {emitEvent: false}
    );
  }

  get paymentProviderConfig(): PaymentProviderConfig {
    return this._paymentProviderConfig;
  }

  ngOnInit() {
    this.form.valueChanges
      .subscribe((formValues: any) => {
        this.store.dispatch({
          type: Actions.REQUEST_UPDATE_PAYMENT_PROVIDER_CONFIG,
          payload: {
            id: this.paymentProviderConfig.id,
            enabled: formValues.enabled,
          },
        })
      })
  }
}
