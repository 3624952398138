import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { Actions } from 'app/store/actions';
import {
  SimplifiedResourceRequestState,
  updateResourceLoading,
  updateResourceComplete
} from 'app/store/reducers/request-states';

export interface State extends SimplifiedResourceRequestState<number> {
  update: SimplifiedResourceRequestState<number>;
  destroy: SimplifiedResourceRequestState<number>;
  orgs: SimplifiedResourceRequestState<number[]>;
  products: SimplifiedResourceRequestState<number[]>;
}

export const initialState: State = {
  detailId: null,
  isLoading: false,
  result: null,
  update: {
    isLoading: false,
    result: null,
  },
  destroy: {
    isLoading: false,
    result: null,
  },
  orgs: {
    isLoading: false,
    result: null,
  },
  products: {
    isLoading: false,
    result: null,
  }
};

export function reducer(
  state: State = initialState,
  action: UnsafeAction
): State {
  switch (action.type) {
    case Actions.REQUEST_GET_BKRY_MGMT_WHOLESALER_GROUP:
      return {
        ...updateResourceLoading(state),
        detailId: action.payload,
        update: initialState.update,
      };

    case Actions.REQUEST_GET_BKRY_MGMT_WHOLESALER_GROUP_SUCCESS:
      return updateResourceComplete(state, action.payload);

    case Actions.REQUEST_UPDATE_BKRY_MGMT_WHOLESALER_GROUP:
      return {
        ...state,
        update: updateResourceLoading(state.update),
      };

    case Actions.REQUEST_UPDATE_BKRY_MGMT_WHOLESALER_GROUP_SUCCESS:
      return {
        ...state,
        update: updateResourceComplete(state.update, action.payload),
      };

    case Actions.REQUEST_DESTROY_BKRY_MGMT_WHOLESALER_GROUP:
      return {
        ...state,
        destroy: updateResourceLoading(state.destroy),
      };

    case Actions.REQUEST_DESTROY_BKRY_MGMT_WHOLESALER_GROUP_SUCCESS:
      return {
        ...state,
        destroy: updateResourceComplete(state.destroy, action.payload),
      };

    case Actions.REQUEST_GET_BKRY_MGMT_WHOLESALER_GROUP_ORGS:
      return {
        ...state,
        orgs: updateResourceLoading(state.orgs),
      };

    case Actions.REQUEST_GET_BKRY_MGMT_WHOLESALER_GROUP_ORGS_SUCCESS:
      return {
        ...state,
        orgs: updateResourceComplete(state.orgs, action.payload),
      };

    case Actions.REQUEST_GET_BKRY_MGMT_WHOLESALER_GROUP_PRODUCTS:
      return {
        ...state,
        products: updateResourceLoading(state.products),
      };

    case Actions.REQUEST_GET_BKRY_MGMT_WHOLESALER_GROUP_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: updateResourceComplete(state.products, action.payload),
      };

    default:
      return state;
  }
}
