import { Component, Input, OnInit, OnDestroy, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import { Actions as NgRxActions } from '@ngrx/effects';

import { AppState } from 'app/store/app-state';
import { BakeryLocation } from 'app/store/bakery-location';
import { UsersEffects } from 'app/store/effects/users.effects';
import { Actions } from 'app/store/actions';

interface Base64FileDetails {
  encoded: string;
  mimeType: string;
}

@Component({
  selector: 'location-photo-upload',
  template: require('./location-photo-upload.component.html'),
})
export class LocationPhotoUploadComponent implements OnInit, OnDestroy {
  @Input('location') location: BakeryLocation;
  @Input('reset$') reset$: Observable<any>;
  @ViewChild('fileForm') fileFormElement: ElementRef;
  @Output('saveImage') saveImageEvents = new EventEmitter<string>();

  private currLocation: BakeryLocation;
  private resetSub: Subscription;
  private tempImage: Base64FileDetails;

  constructor(
    private actions$: NgRxActions,
  ) { }

  ngOnInit() {
    this.resetSub = this.reset$.subscribe(() => this.reset());
  }

  ngOnDestroy() {
    this.resetSub.unsubscribe();
  }

  get hasModelImage(): boolean {
    return this.location.location_photo != null;
  }

  get hasTempImage(): boolean {
    return this.tempImage != null;
  }

  get hasImage(): boolean {
    return this.hasModelImage || this.hasTempImage;
  }

  get imageUrl(): string {
    if (this.hasTempImage) {
      return `data:${this.tempImage.mimeType};base64,${this.tempImage.encoded}`;
    }

    if (this.hasModelImage) {
      return this.location.location_photo;
    }

    return '';
  }

  get uploadInputId(): string {
    return `photoUpload_id${this.location.id}`;
  }

  onClickCancel() {
    this.reset();
  }

  onClickSave() {
    // this.usersEffects.requestUserAvatarUpdate(
    //   this.user,
    //   `data:${this.tempAvatar.mimeType};base64,${this.tempAvatar.encoded}`
    // );

    this.saveImageEvents.next(this.imageUrl);
  }

  onClickRemoveImage() {
    // this.usersEffects.requestUserAvatarUpdate(
    //   this.user,
    //   null
    // );

    this.saveImageEvents.next(null);
  }

  onChangeFileUpload($event) {
    const file = $event.target.files[0];
    const fileReader = new FileReader();
    let fileBinResults = null;
    fileReader.onload = (readerEvent) => {
      fileBinResults = (<any>readerEvent.target).result;

      this.tempImage = {
        encoded: btoa(fileBinResults),
        mimeType: file.type,
      };
    };

    fileReader.readAsBinaryString(file);
  }

  private resetFileForm() {
    this.fileFormElement.nativeElement.reset();
  }

  private reset() {
    this.tempImage = null;
    this.resetFileForm();
  }
}
