import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as Rx from 'rxjs';

import { AppState } from 'app/store/app-state';
import { Actions } from 'app/store/actions';
import { PaymentProviderConfig } from 'app/store/payment-provider-config';
import * as selectors from 'app/store/selectors';

@Component({
  template: require('./payments.component.html'),
})
export class BakeryMgmtStoreMainPaymentsComponent implements OnInit {
  uiState$: Rx.Observable<any>;
  stripeProviderConfig$: Rx.Observable<PaymentProviderConfig>;

  constructor(
    private store: Store<AppState>,
  ) {
    this.uiState$ = this.store.select(
      selectors.getWiredBakeryMgmtStorePaymentsState
    );
    this.stripeProviderConfig$ = this.store.select(
      selectors.getPaymentProviderConfigByTypeKey('stripe')
    );
  }

  ngOnInit() {
    this.store.dispatch({
      type: Actions.REQUEST_GET_BKRY_MGMT_STORE_PAYMENT_CONFIG,
    })
  }
}
