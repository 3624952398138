import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AppState } from 'app/store/app-state';
import * as selectors from 'app/store/selectors';
import { BakeryOrder } from 'app/store/bakery-order';
import { bakeryOrderSchema } from 'app/store/schema/default-schemata';
import { GetAction } from 'app/store/actions/request.actions';

const opKey = 'customerOrderHistory',
      schema = [bakeryOrderSchema];

@Component({
  template: require('./master.component.html'),
})
export class BakeryCustOrderHistoryMasterComponent implements OnInit {
  isLoading$: Observable<boolean> = this.store
    .select(selectors.getRequestIsLoading(opKey));

  bakeryOrders$: Observable<BakeryOrder[]> = this.store
    .select(selectors.getRequestResult(opKey, schema))
    .startWith([]);

  constructor(
    private store: Store<AppState>,
  ) { }

  ngOnInit() {
    const actionPayload = {
      url: `/api/me/orders/`,
      opKey: opKey,
      schema: schema
    };
    this.store.dispatch(new GetAction(actionPayload));
  }
}
