import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import iconTrash from 'angular/assets/images/icons/icons-functional/icon-trash.svg';

export const VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ImageControlComponent),
  multi: true,
};

@Component({
  selector: 'image-control',
  providers: [VALUE_ACCESSOR],
  template: `
    <div class="image-preview__container">
      <img
        *ngIf="imageData?.image"
        [src]="imageData.image"
        class="image-preview__img" />
      <button
        class="image-preview__action"
        (click)="onClickRemoveImage()">
        <i aria-label="Delete" [inlineSVG]="iconTrash"></i>
      </button>
      <p *ngIf="isDestroyed">DESTROYDED!</p>
    </div>
  `,
})
export class ImageControlComponent implements ControlValueAccessor {
  imageData: any;
  iconTrash = iconTrash;

  private onChangedCallback;
  private onTouchedCallback;

  writeValue(value: any) {
    this.imageData = value;
  }

  registerOnChange(onChangedCallback: any) {
    this.onChangedCallback = onChangedCallback;
  }

  registerOnTouched(onTouchedCallback: any) {
    this.onTouchedCallback = onTouchedCallback;
  }

  get isDestroyed(): boolean {
    return this.imageData && this.imageData._destroy === true;
  }

  onClickRemoveImage() {
    const updatedImageData = {
      ...this.imageData,
      _destroy: true,
    };

    this.imageData = updatedImageData;

    this.onChangedCallback(updatedImageData);
  }
}
