import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { Store } from '@ngrx/store';
import { Angular2TokenService } from 'app/angular2-token/angular2-token.service';
import { Actions as NgRxActions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';
import { normalize, schema } from 'normalizr';

import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { AppState } from 'app/store/app-state';
import { AuthState } from 'app/store/auth-state';
import { Actions } from 'app/store/actions';
import { Bakery } from 'app/store/bakery';
import { BakeryCart } from 'app/store/bakery-cart';
import { OrderItem } from 'app/store/order-item';
import { BakeryCustomerUiState } from 'app/store/reducers/bakery-cust-ui-state-reducer';
import { EntitiesState } from 'app/store/entities-state';
import { deepBakerySchema } from 'app/store/schema/bakery';
import { bakeryCartSchema, userSchema } from 'app/store/schema/default-schemata';
import { addEntityRequestHandler } from 'app/store/effects/helpers';
import { CartState } from 'app/store/reducers/bakery-cust-ui-state-reducer';
import { User } from 'app/store/user';
import { UsersEffects } from 'app/store/effects/users.effects';
import * as selectors from 'app/store/selectors';

export interface CustomerOverview {
  bakery: Bakery;
  isLoggedIn: boolean;
  user: User;
  cartQuantity: number;
}

@Injectable()
export class BakeryCustEffects {
  currentBakery$: Observable<Bakery>;
  customerOverview$: Observable<CustomerOverview>;

  constructor(
    private actions$: NgRxActions,
    private tokenService: Angular2TokenService,
    private store: Store<AppState>,
    private usersEffects: UsersEffects,
  ) {
    // FIXME: These long observable declarations should be replaced by
    // reusable selectors.
    this.currentBakery$ = Observable
      .combineLatest(
        this.store.select('entitiesState')
          .filter(entities => entities != null),
        this.store.select('bakeryCustUiState')
          .filter((uiState: BakeryCustomerUiState) => uiState.bakeryId != null),
        (entities, bakeryCustUiState) => ({entities, bakeryCustUiState})
      )
      .map((combined) => {
        const entities = <EntitiesState> combined.entities;
        const bakeryId = (<BakeryCustomerUiState>combined.bakeryCustUiState).bakeryId;

        // Check to see if the bakery has loaded yet. If not, return null.
        if (entities.bakeries[bakeryId] == null) {
          return null;
        }

        // Hydrate/de-normalize the Bakery
        const bakery = Object.assign({}, entities.bakeries[bakeryId]);

        bakery.bakery_locations = (<number[]>bakery.bakery_locations)
          .map(locId => Object.assign({}, entities.bakery_locations[locId]));

        return bakery;
      })
      .catch(error => {
        console.error(`currentBakery error:`, error);

        return Observable.of(error);
      })
      .startWith(null)
      .shareReplay(1);

    this.customerOverview$ = Observable
      .combineLatest(
        this.currentBakery$.filter(bakery => bakery != null),
        this.usersEffects.currentUser$.startWith(null),
        this.store.select(selectors.getCustomerCart),
        this.store.select('authState'),
        this.store.select('entitiesState'),
        (bakery, currentUser, cart, authState, entitiesState) => ({bakery, currentUser, cart, authState, entitiesState})
      )
      .map(combined => {
        console.log(`firing customerOverview`);

        const bakery = <Bakery>combined.bakery;
        const currentUser = <User>combined.currentUser;
        const cart = <BakeryCart>combined.cart;
        const authState = <AuthState>combined.authState;
        const entitiesState = <EntitiesState>combined.entitiesState;

        let cartQuantity = 0;
        if (cart != null) {
          cartQuantity = (<OrderItem[]>cart.order_items).reduce((sum, orderItem) => sum + orderItem.quantity, 0);
        }

        return {
          bakery: bakery,
          isLoggedIn: authState.isLoggedIn,
          user: currentUser,
          cartQuantity: cartQuantity,
        };
      })
      .catch(error => {
        console.log(error)
        return Observable.throw(error);
      })
      .startWith({isLoggedIn: false})
      .shareReplay(1);
  }

  requestGetCustBakery(domainName: string) {
    this.store.dispatch({
      type: Actions.REQUEST_GET_CUST_BAKERY,
      payload: {
        domainName: domainName,
      }
    });
  }

  requestGetCustCart() {
    this.store.dispatch({
      type: Actions.REQUEST_GET_CUST_CART,
    })
  }

  requestRegisterCustomer(first_name: string, last_name: string, email: string) {
    this.store.dispatch({
      type: Actions.REQUEST_REGISTER_BAKERY_CUSTOMER,
      payload: {
        first_name,
        last_name,
        email,
      },
    });
  }

  private currentBakeryLatestMap(action: UnsafeAction): Observable<{action: UnsafeAction, bakery: Bakery}> {
    return this.currentBakery$
      .filter(bakery => bakery != null)
      .map(bakery => ({action, bakery}))
      .take(1);
  }

  @Effect() getCustCart$ = this.actions$
    .ofType(Actions.REQUEST_GET_CUST_CART)
    .withLatestFrom(
      this.store.select(selectors.getCurrentCustBakery)
        .filter(val => val != null),
      (action: UnsafeAction, bakery) => ({action, bakery})
    )
    .switchMap(({action, bakery}) => {
      if (action.payload != null) {
        return addEntityRequestHandler(
          this.tokenService.get(
            `/api/bakeries/${bakery.id}/carts/${action.payload}`
          ),
          bakeryCartSchema,
          Actions.REQUEST_GET_CUST_CART
        );
      } else {
        return addEntityRequestHandler(
          this.tokenService.get(
            `/api/me/cart`
          ),
          bakeryCartSchema,
          Actions.REQUEST_GET_CUST_CART
        );
      }
    })
    .share();

  @Effect() getWholesaleCart$ = this.actions$
    .ofType(Actions.REQUEST_GET_WHOLESALE_CART)
    .withLatestFrom(
      this.store.select(selectors.getCurrentCustBakery)
        .filter(val => val != null),
      (action: UnsafeAction, bakery) => ({action, bakery})
    )
    .switchMap(({action, bakery}) => {
      if (action.payload != null) {
        return addEntityRequestHandler(
          this.tokenService.get(
            `/api/bakeries/${bakery.id}/carts/${action.payload}`
          ),
          bakeryCartSchema,
          Actions.REQUEST_GET_WHOLESALE_CART
        );
      } else {
        return addEntityRequestHandler(
          this.tokenService.get(
            `/api/wholesalers/cart`
          ),
          bakeryCartSchema,
          Actions.REQUEST_GET_WHOLESALE_CART
        );
      }
    })
    .share();

  @Effect() setCustBakeryDomainName$ = this.actions$
    .ofType(Actions.SET_APP_OPERATION_MODE)
    .filter((action: UnsafeAction) => action.payload.type === 'custom_bakery')
    .switchMap((action: UnsafeAction) => {
      return Observable.of({
        type: Actions.REQUEST_GET_CUST_BAKERY,
        payload: action.payload.bakeryDomain,
      });
    })
    .share();

  // FIXME: No error handler here--or error action type, either.
  @Effect() getCustBakery$ = this.actions$
    .ofType(Actions.REQUEST_GET_CUST_BAKERY)
    .switchMap((action: UnsafeAction) => {
      return this.tokenService.get(`api/bakery_home?domain=${action.payload}`)
        .map((response: Response) => response.json())
        .map((data: any[]) => {
          const results = normalize(data, deepBakerySchema);

          return {
            entities: results.entities,
            bakeryId: results.result,
          };
        })
        .switchMap((results) => {
          const actions = [
            {
              type: Actions.LOADED_ENTITIES,
              payload: results.entities,
            },
            {
              type: Actions.REQUEST_GET_CUST_BAKERY_SUCCESS,
              payload: results.bakeryId,
            },
          ];

          const bakery = results.entities.bakeries[results.bakeryId],
                defaultCatId = bakery.default_product_category_id;

          if (defaultCatId != null) {
            actions.push({
              type: Actions.ADD_STORE_BAKERY_PRODUCTS_CATEGORY_FILTER,
              payload: defaultCatId,
            });
          }

          return Observable.from(actions);
        })
        .catch((error) => {
          const errorPayload = typeof error.json === 'function' ? error.json() : error;

          return Observable.of({
            type: Actions.REQUEST_GET_CUST_BAKERY_ERROR,
            payload: errorPayload,
          });
        });
    })
    .share();

  @Effect() registerCustomerEffects$ = this.actions$
    .ofType(Actions.REQUEST_REGISTER_BAKERY_CUSTOMER)
    .switchMap(this.currentBakeryLatestMap.bind(this))
    .switchMap(({action, bakery}) => {
      return this.tokenService
        .post(
          `/api/bakeries/${bakery.id}/customers/registrations`,
          JSON.stringify(action.payload),
        )
        .map((response: Response) => response.json())
        .map((data: any[]) => {
          const results = normalize(data, userSchema);

          return {
            entities: results.entities,
            currentUser: results.entities.users[results.result],
          };
        })
        .switchMap((results) => {
          const actions = [
            {
              type: Actions.LOADED_ENTITIES,
              payload: results.entities,
            },
            {
              type: Actions.REQUEST_REGISTER_BAKERY_CUSTOMER_SUCCESS,
              payload: results,
            },
            {
              type: Actions.REQUEST_USER_SIGN_IN_SUCCESS,
              payload: results.currentUser,
            },
          ];

          return Observable.from(actions);
        })
        .catch((error) => {
          const errorPayload = typeof error.json === 'function' ? error.json() : error;

          return Observable.of({
            type: Actions.REQUEST_REGISTER_BAKERY_CUSTOMER_ERROR,
            payload: errorPayload,
          });
        })
    })
    .share();
}
