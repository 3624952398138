import { Component, OnInit, OnDestroy, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions as NgRxActions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as Rx from 'rxjs';

import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { AppState } from 'app/store/app-state';
import { AlertService } from 'app/shared/components/alerts/alert.service';
import { Actions } from 'app/store/actions';
import { BakeryMgmtProductsEffects } from 'app/store/effects/bakery-mgmt/bakery-mgmt-products.effects';
import * as selectors from 'app/store/selectors';
import { idDetailComponent } from 'app/shared/decorators/id-detail-component';

@idDetailComponent('wholesaler_id', Actions.REQUEST_GET_BKRY_WHOLESALER)
@Component({
  template: require('./detail.component.html'),
})
export class DetailComponent implements OnInit, OnDestroy {
  private alertsSub: Rx.Subscription;

  isLoading$ = this.store
    .select(selectors.bkryMgmtWholesaler.getBkryMgmtWholesalerStateDetail)
    .pluck('isLoading');
  wholesalerOrg$ = this.store
    .select(selectors.bkryMgmtWholesaler.getBkryMgmtWholesalerDetailResult);

  constructor(
    private route: ActivatedRoute,
    private actions$: NgRxActions,
    private store: Store<AppState>,
    private alertService: AlertService,
  ) { }

  ngOnInit() {
    this.alertsSub = this.actions$
      .subscribe((action: UnsafeAction) => {
        let messageType = null,
            messageContent = null;

        switch (action.type) {
          case Actions.REQUEST_BKRY_MGMT_ORDER_MGMT_CHANGE_ORDER_STATUS_SUCCESS:
            messageType = 'success';
            messageContent = `The order status has been updated.`;
            break;

          case Actions.REQUEST_BKRY_MGMT_ORDER_MGMT_CHANGE_ORDER_STATUS_ERROR:
            messageType = 'warning';
            messageContent = action.payload.error || 'Something went wrong!';
            break;
        }

        if (messageType != null && messageContent != null) {
          this.alertService[messageType](messageContent);
        }
      });
  }

  ngOnDestroy() {
    this.alertsSub.unsubscribe();
  }
}
