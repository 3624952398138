import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { Actions } from 'app/store/actions';

export interface State {
  bakeryLocations: any[];
}

const INITIAL_STATE = {
  bakeryLocations: []
};

export function reducer(state: State = INITIAL_STATE, action: UnsafeAction): State {
  switch (action.type) {
    case Actions.GET_BAKERY_LOCATIONS_SUCCESS:
      return {
        ...state,
        bakeryLocations: action.payload
      };

    default:
      return state;
  }
}
