import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { Store } from '@ngrx/store';
import { Angular2TokenService } from 'app/angular2-token/angular2-token.service';
import { Actions as NgRxActions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';
import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { AppState } from 'app/store/app-state';
import { EntitiesState } from 'app/store/entities-state';
import { Actions } from 'app/store/actions';
import {
  bakerySubscriptionInvoiceSchema
 } from 'app/store/schema/default-schemata';
import { addEntityRequestHandler, basicRequestHandler } from 'app/store/effects/helpers';

export interface MasterUiState {
  isLoading: boolean;
}

@Injectable()
export class BakeryMgmtSubscriptionInvoicesEffects {
  masterUiStates$: Observable<MasterUiState>;

  constructor(
    private actions$: NgRxActions,
    private tokenService: Angular2TokenService,
    private store: Store<AppState>,
  ) { }

  requestGetSubscriptionInvoices() {
    this.store.dispatch({
      type: Actions.REQUEST_GET_BAKERY_SUBSCRIPTION_INVOICES
    });
  }

  requestGetSubscriptionInvoice(id?: number) {
    this.store.dispatch({
      type: Actions.REQUEST_GET_BAKERY_SUBSCRIPTION_INVOICE,
      payload: {id},
    });
  }

  @Effect() getSubscriptionInvoicesEffects$ = this.actions$
    .ofType(Actions.REQUEST_GET_BAKERY_SUBSCRIPTION_INVOICES)
    .switchMap(() => {
      return addEntityRequestHandler(
        this.tokenService.get(
          `/api/bakery/subscription_invoices`
        ),
        [bakerySubscriptionInvoiceSchema],
        Actions.REQUEST_GET_BAKERY_SUBSCRIPTION_INVOICES
      );
    })
    .take(1)
    .share();


  @Effect() getSubscriptionInvoiceEffects$ = this.actions$
    .ofType(Actions.REQUEST_GET_BAKERY_SUBSCRIPTION_INVOICE)
    .switchMap((action: UnsafeAction) => {
      return addEntityRequestHandler(
        this.tokenService.get(
          `/api/bakery/subscription_invoices/${action.payload.id}`
        ),
        bakerySubscriptionInvoiceSchema,
        Actions.REQUEST_GET_BAKERY_SUBSCRIPTION_INVOICE
      );
    })
    .take(1)
    .share();
}
