import { Component } from '@angular/core';

@Component({
  selector: 'card-list-tooltip-label',
  template: `
    <p class="tooltip__label margin-reset--bottom">
      <ng-content></ng-content>
    </p>
  `,
})
export class TooltipLabelComponent { }
