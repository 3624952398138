import { Component, Input } from '@angular/core';

@Component({
  selector: 'product-detail-forms-edit-advanced-options',
  template: `
    <titled-card title="Product Type">
      <ng-container data-card-body>
        <div class="row">
          <p>Select one of the two options below to get started. Note, a product must contain at least one template or one variation.</p>
        </div>
        <div gridRow>
          <div gridCol s="6" m="4" l="6">
            <button
              (click)="setAdvanced(false)"
              class="button--secondary-outline button--product-type button--full margin-bottom--delta clickable"
              [ngClass]="{'button--product-type--selected': (advanced$|async) === false}"
            >
              Standard Product
              <span class="button--description">Offered in styles & quantities</span>
            </button>
          </div>
          <div gridCol s="6" m="4" l="6">
            <button
              (click)="setAdvanced(true)"
              class="button--secondary-outline button--product-type button--full margin-bottom--delta clickable"
              [ngClass]="{'button--product-type--selected': (advanced$|async) === true}"
            >
              Advanced Product
              <span class="button--description">Uses templates & attributes</span>
            </button>
          </div>
        </div>
      </ng-container>
    </titled-card>
  `
})
export class ProductDetailFormsEditAdvancedOptionComponent {
  @Input('advanced$') advanced$;

  setAdvanced(isAdvanced) {
    this.advanced$.next(isAdvanced);
  }
}
