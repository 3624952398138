import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { OrderItem } from 'app/store/order-item';
import { BakeryProduct } from 'app/store/bakery-product';
import { BakeryProductTemplate } from 'app/store/bakery-product-template';
import { BakeryOrder } from 'app/store/bakery-order';

interface NamedOrderItem extends OrderItem {
  name: string;
}

function flatOrderItemMapper(orderItem: OrderItem): NamedOrderItem {
  const bakeryProduct = orderItem.bakery_product as BakeryProduct,
        bakeryProductTemplate = orderItem.bakery_product_template as BakeryProductTemplate;

  return {
    ...orderItem,
    name: `${bakeryProduct.name} - ${bakeryProductTemplate.name}`
  };
}

export class BaseOrderItemsListComponent {
  private _bakeryOrder: BakeryOrder;
  private _orderItems: NamedOrderItem[];

  @Input()
  public set bakeryOrder(bakeryOrder: BakeryOrder) {
    this._bakeryOrder = bakeryOrder;

    this.mapOrderItems();
  }

  public get bakeryOrder(): BakeryOrder {
    return this._bakeryOrder;
  }

  public get orderItems(): NamedOrderItem[] {
    return this._orderItems;
  }

  private mapOrderItems() {
    this._orderItems = (this.bakeryOrder.order_items as OrderItem[]).map(flatOrderItemMapper);
  }
}

@Component({
  selector: 'tooltip-order-items-list',
  template: `
    <tooltip-association-list
      [list]="orderItems"
      label="Order Items"
      countLabel="items"
    ></tooltip-association-list>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipOrderItemsListComponent extends BaseOrderItemsListComponent { }

@Component({
  selector: 'mobile-order-items-list',
  template: `
    <mobile-association-list
      [list]="orderItems"
      label="Order Items"
      countLabel="items"
    ></mobile-association-list>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileOrderItemsListComponent extends BaseOrderItemsListComponent { }
