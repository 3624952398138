import { ChangeDetectionStrategy, Component, Input, Output, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import { denormalize } from 'normalizr';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';

import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { Actions } from 'app/store/actions';
import { AppState } from 'app/store/app-state';
import * as selectors from 'app/store/selectors';
import { BakeryMgmtOrderMgmtEffects } from 'app/store/effects/bakery-mgmt/bakery-mgmt-order-mgmt.effects';
import { BakeryMgmtProductsEffects } from 'app/store/effects/bakery-mgmt';
import { bakeryProductSchema } from 'app/store/schema/default-schemata';
import { EntitiesState } from 'app/store/entities-state';
import * as RemoteData from 'app/remote-data';
import * as Hour from 'app/hour';
import { futureDateValidator } from 'app/shared/forms/date-validators';
import { BakeryLocation } from 'app/store/bakery-location';
import { Address } from 'app/store/address';
import { BakeryProduct } from 'app/store/bakery-product';
import { validateNoWhitespaces } from 'app/shared/forms/no-whitespaces-validator';

@Component({
  template: require('./edit.component.html'),
})
export class EditComponent implements OnInit, OnDestroy {
  ADD_NEW = 'add_new'

  addresses$: Observable<Address[]>;
  availableFulfillmentAts$ = Observable.of(Hour.ALL_HOURS);
  availableFulfillmentLocations$: Observable<{ value: number, label: string }[]>;
  availableFulfillmentMethods$: Observable<{ value: string, label: string }[]>;
  availablePickupLocations$: Observable<BakeryLocation[]>;
  bakeryOrder$ = this.store.select(selectors.getBkryMgmtOrderMgmtDetailResult).skipWhile(x => !x);
  bakeryProducts$: Observable<BakeryProduct[]>;
  countryCode$: Observable<string> = this.countryCode$ = this.store.select(selectors.selectCountryCode);
  daysAtLeastOneLocationDoesFulfill$: Observable<boolean[]> = Observable.of(Array(7).fill(true));
  isEmployee$ = Observable.of(true);
  form: FormGroup;
  formData$ = this.store.select(selectors.getEditFormData).skipWhile(x => !x);
  fulfillmentLocationLabel$: Observable<string>;
  isAnyLocationOpen = (_date: Date) => true;
  isDeliveryAvailable$: Observable<boolean> = Observable.of(true);
  isFulfillmentLocationNewAddress$: Observable<boolean>;
  isFulfillmentLocationTypeAddress$: Observable<boolean>;
  isSubmitting$: Observable<boolean> = this.store.select(selectors.getBkryMgmtOrderMgmtDetailState).pluck('update', 'isLoading');
  isPickupAvailable$: Observable<boolean> = Observable.of(true);
  isPickupOrDelivery$: Observable<boolean>;
  isRecurringOrder$: Observable<boolean>;
  isRecurringOrderSelected$: Observable<boolean>;
  minDate$: Observable<Date|null>;
  onClickFormReset$ = new Subject<null>();
  onFormSubmit$ = new Subject<null>();
  isWholesaler$ = this.bakeryOrder$.pluck('order_customer_type').map(orderCustomerType => orderCustomerType === 'wholesale');
  isFormDisabled$: Observable<boolean>;

  private subscription1$: Subscription;
  private subscription2$: Subscription;
  private subscription3$: Subscription;
  private subscription4$: Subscription;
  private subscription5$: Subscription;
  private subscription6$: Subscription;
  private subscription7$: Subscription;
  private formSubmit$: Subscription;

  constructor(
    private store: Store<AppState>,
    private orderEffects: BakeryMgmtOrderMgmtEffects,
    private formBuilder: FormBuilder,
    private bakeryMgmtProductsEffects: BakeryMgmtProductsEffects
  ) {
    this.form = this.formBuilder.group({
      fulfillment_date: [null, [Validators.required, futureDateValidator]],
      fulfillment_at: new FormControl('', []),
      fulfillment_type: new FormControl('', [Validators.required]),
      order_type: new FormControl(null, [Validators.required]),
      schedule_interval: new FormControl(null, [Validators.required]),
      fulfillment_location_id: new FormControl(null, [Validators.required]),
      address: this.formBuilder.group({
        street: new FormControl(null, []),
        unit: new FormControl(null, []),
        city: new FormControl(null, []),
        state: new FormControl(null, []),
        zip: new FormControl(null, []),
      }),
    });
  }

  ngOnInit() {
    this.isPickupOrDelivery$ = this.formData$
      .pluck('fulfillment_type')
      .merge(this.form.get('fulfillment_type').valueChanges)
      .map(fulfillmentType => ['pickup', 'delivery'].includes(fulfillmentType))
      .do(isNotShipping => {
        const fulfillmentAtControl = this.form.get('fulfillment_at');

        if (isNotShipping) {
          fulfillmentAtControl.setValidators([Validators.required]);
          fulfillmentAtControl.updateValueAndValidity()
        } else {
          fulfillmentAtControl.clearValidators();
          fulfillmentAtControl.updateValueAndValidity()
        }
      })

    this.store.dispatch({ type: Actions.EDIT_ORDER_STARTED });

    this.availableFulfillmentMethods$ =
      Observable.combineLatest(
        this.isDeliveryAvailable$,
        this.isPickupAvailable$,
        this.isEmployee$
      )
      .map(([isDeliveryAvailable, isPickupAvailable, isEmployee]) => {
        const options = [];
        if (isDeliveryAvailable) { options.push({ value: 'delivery', label: 'Delivery' }) }
        if (isPickupAvailable) { options.push({ value: 'pickup', label: 'Pickup' }) }
        if (isEmployee) { options.push({ value: 'shipping', label: 'Shipping' }) }
        return options;
      });

    this.isRecurringOrder$ = this.formData$
      .pluck('order_type')
      .map(orderType => orderType === 'recurring');

    this.isRecurringOrderSelected$ = this.isRecurringOrder$
      .merge(
        this.form.get('order_type').valueChanges.map(orderType => orderType === 'recurring')
      );

    this.isFulfillmentLocationTypeAddress$ = this.formData$
      .pluck('fulfillment_type')
      .merge(this.form.get('fulfillment_type').valueChanges)
      .map(fulfillmentType => fulfillmentType !== 'pickup');

    this.isFulfillmentLocationNewAddress$ = this.formData$
      .pluck('fulfillment_location_id')
      .merge(this.form.get('fulfillment_location_id').valueChanges)
      .map(fulfillmentLocationId => fulfillmentLocationId === this.ADD_NEW)
      .do(isFulfillmentLocationNewAddress => {
        const add = this.form.get('address');
        const streetCtrl = add.get('street');
        const cityCtrl = add.get('city');
        const stateCtrl = add.get('state');
        const zipCtrl = add.get('zip');

        if (isFulfillmentLocationNewAddress) {
          streetCtrl.setValidators([Validators.required, validateNoWhitespaces]);
          streetCtrl.updateValueAndValidity()
          cityCtrl.setValidators([Validators.required, validateNoWhitespaces]);
          cityCtrl.updateValueAndValidity()
          stateCtrl.setValidators([Validators.required, validateNoWhitespaces]);
          stateCtrl.updateValueAndValidity()
          zipCtrl.setValidators([Validators.required]);
          zipCtrl.updateValueAndValidity()
        } else {
          streetCtrl.clearValidators();
          streetCtrl.updateValueAndValidity()
          cityCtrl.clearValidators();
          cityCtrl.updateValueAndValidity()
          stateCtrl.clearValidators();
          stateCtrl.updateValueAndValidity()
          zipCtrl.clearValidators();
          zipCtrl.updateValueAndValidity()
        }
      });

    this.fulfillmentLocationLabel$ =
      this.isFulfillmentLocationTypeAddress$.map(isAddress => isAddress ? 'Select Delivery Address' : 'Select Pickup Location');

    this.subscription1$ =
      Observable.combineLatest(
        this.isEmployee$,
        this.daysAtLeastOneLocationDoesFulfill$
      )
      .do(([isEmployee, daysAtLeastOneLocationDoesFulfill]) => {
        this.isAnyLocationOpen = (date: Date): boolean => {
          if (isEmployee) { return true; }
          return daysAtLeastOneLocationDoesFulfill[date.getDay()];
        }
      })
      .subscribe();

    this.minDate$ = this.isEmployee$.map(isEmployee => isEmployee ? null : this.dayAfterTomorrow());

    this.subscription2$ = this.isWholesaler$
      .do(isWholesaler => this.form.reset({ order_type: isWholesaler ? null : 'once' }))
      .subscribe()

    const updateScheduleIntervalControl = (orderType, scheduleInterval) => {
      this.form.patchValue({schedule_interval: this.scheduleIntervalFor(orderType, scheduleInterval) }, {emitEvent: false});
      this.form.get('schedule_interval')[orderType === 'once' ? 'disable' : 'enable']();
    }

    this.subscription3$ = this.formData$
      .take(1)
      .do((formData) => updateScheduleIntervalControl(formData.order_type, formData.schedule_interval))
      .subscribe();

    this.subscription7$ = Observable.combineLatest(
      this.form.get('order_type').valueChanges,
      this.formData$
    )
      .do(([orderType, formData]) => updateScheduleIntervalControl(orderType, formData.schedule_interval))
      .subscribe();

    this.subscription4$ = this.formData$
      .do(formData => this.resetForm(formData))
      .subscribe();

    this.subscription5$ = this.onClickFormReset$
      .withLatestFrom(this.formData$)
      .do(([_, formData]) => this.resetForm(formData))
      .subscribe();

    this.bakeryProducts$ = Observable
      .combineLatest(
        this.store.select(selectors.selectBakeryProducts),
        this.bakeryOrder$,
      )
      .map(([bakeryProducts, bakeryOrder]) => {
        const allowedStoreTypes = bakeryOrder && bakeryOrder.order_customer_type === 'wholesale' ?
          ['internal_only', 'wholesale_only', 'retail_wholesale'] :
          ['internal_only', 'retail_only', 'retail_wholesale'];
        return [
          RemoteData.map(xs => {
            return xs
              .filter(bakeryProduct => !bakeryProduct.archived)
              .filter(bakeryProduct => allowedStoreTypes.includes(bakeryProduct.store_type));
          }, bakeryProducts),
          bakeryOrder
        ];
      }).map(([bakeryProducts, _bakeryOrder]) => bakeryProducts);

    this.isFormDisabled$ = Observable.combineLatest(
      this.form.valueChanges,
      this.isSubmitting$
    ).map(([_, isSubmitting]) => {
      return !this.form.dirty || isSubmitting || !this.form.valid;
    })

    this.formSubmit$ = this.onFormSubmit$
      .withLatestFrom(this.bakeryOrder$, this.isFormDisabled$)
      .subscribe(([_, bakeryOrder, isFormDisabled]) => {
        if (isFormDisabled) { return; }
        const formData = this.form.value;
        const payload =  {
          id: bakeryOrder.id,
          data: {
            fulfillment_date: formData.fulfillment_date,
            fulfillment_on: [
              formData.fulfillment_date.getFullYear(),
              formData.fulfillment_date.getMonth() + 1,
              formData.fulfillment_date.getDate()
            ].join('-'),
            fulfillment_at: formData.fulfillment_at,
            fulfillment_type: formData.fulfillment_type,
            order_type: formData.order_type,
            schedule_interval: formData.schedule_interval,
            fulfillment_location_id: formData.fulfillment_location_id,
            address: {
              street: formData.address.street,
              unit: formData.address.unit,
              city: formData.address.city,
              state: formData.address.state,
              zip: formData.address.zip,
            },
          }
        };

        this.orderEffects.requestOrderDetailsUpdate2(payload);
      });

    this.availablePickupLocations$ =
      Observable.combineLatest(
        this.store.select(selectors.selectAvailablePickupLocations2),
        this.isFulfillmentLocationTypeAddress$
      )
      .map(([locations, isAddress]) => isAddress ? [] : locations);

    this.availableFulfillmentLocations$ = this.availablePickupLocations$
      .map(locations => locations.map(location => ({ value: location.id, label: location.name })));

    this.addresses$ = this.bakeryOrder$
      .withLatestFrom(this.isWholesaler$)
      .map(([bakeryOrder, isWholesaler]) => {
        const fromParent = isWholesaler ? bakeryOrder.parent.addresses :
          (bakeryOrder.parent.default_address ? [bakeryOrder.parent.default_address] : []);
        const fromOrder = bakeryOrder.fulfillment_location_type === 'Address' ? [bakeryOrder.fulfillment_location] : [];

        const sameAddress = (x, y) =>
          x.state === y.state && x.city === y.city && x.zip === y.zip && x.street === y.street;

        return fromOrder.concat(fromParent).reduce((acc, x) => {
          if (acc.find(y => y.id === x.id)) { return acc; }
          if (acc.find(y => sameAddress(x, y))) { return acc; }
          return [...acc, x];
        }, []);
      })

    this.subscription6$ = this.form
      .get('fulfillment_type')
      .valueChanges
      .withLatestFrom(
        this.isFulfillmentLocationTypeAddress$,
        this.store.select(selectors.selectAvailablePickupLocations2),
        this.addresses$
      )
      .do(([_fulfillmentType, isAddress, pickupLocations, addresses])=> {
        if (isAddress) {
          if (addresses.length !== 1) {
            this.form.patchValue({ fulfillment_location_id: null }, { emitEvent: false });
          } else {
            this.form.patchValue({ fulfillment_location_id: addresses[0].id }, { emitEvent: false });
          }
        } else {
          if (pickupLocations.length !== 1) {
            this.form.patchValue({ fulfillment_location_id: null }, { emitEvent: false });
          } else {
            this.form.patchValue({ fulfillment_location_id: pickupLocations[0].id }, { emitEvent: false });
          }
        }
      }).subscribe();
  }

  private dayAfterTomorrow() {
    const date = new Date();
    date.setDate(date.getDate() + 2);
    return date;
  }

  ngOnDestroy() {
    this.subscription1$.unsubscribe();
    this.subscription2$.unsubscribe();
    this.subscription3$.unsubscribe();
    this.subscription4$.unsubscribe();
    this.subscription5$.unsubscribe();
    this.subscription6$.unsubscribe();
    this.subscription7$.unsubscribe();
    this.formSubmit$.unsubscribe();
  }

  onFormSubmit() {
    this.onFormSubmit$.next();
  }

  onChangeFilterProductText(filterProductText: string) {
    this.bakeryMgmtProductsEffects.requestGetBakeryProducts2(filterProductText);
  }

  private resetForm(formData) {
    this.form.markAsPristine();
    this.form.markAsUntouched();
    this.form.reset({
      fulfillment_date: new Date(formData.fulfillment_date),
      fulfillment_at: formData.fulfillment_at,
      fulfillment_type: formData.fulfillment_type,
      order_type: formData.order_type,
      schedule_interval: this.scheduleIntervalFor(formData.order_type, formData.schedule_interval),
      fulfillment_location_id: formData.fulfillment_location_id,
      address: {
        street: null,
        unit: null,
        city: null,
        state: null,
        zip: null,
      },
    });
  }

  private scheduleIntervalFor(orderType, scheduleInterval) {
    return orderType === 'once' ? 'none' : scheduleInterval;
  }
}
