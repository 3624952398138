import { Component, OnInit, OnDestroy, Input, Output, ViewChild, ElementRef } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import * as Rx from 'rxjs';

import { Bakery } from 'app/store/bakery';
import { SimpleFormState } from 'app/shared/forms/form-states';
import { UiState } from 'app/bakery-mgmt/store-mgmt/settings/settings.component';
import iconPicture from 'angular/assets/images/icons/icons-functional/icon-picture.svg';

interface ImageUiState {
  imageUrl: string;
  hasImage: boolean;
  isChanged: boolean;
}

const initialImageUiState: ImageUiState = {
  imageUrl: null,
  hasImage: false,
  isChanged: false,
};

@Component({
  selector: 'bakery-store-edit-image',
  template: `
    <div class="card padding--reset margin-bottom--charlie">
      <header class="padding-top--delta padding-left--delta padding-right--delta">
        <h6 class="margin-reset--bottom">Store Logo</h6>
      </header>
      <hr />
      <div class="text--center padding-bottom--delta">
        <ng-template [ngIf]="!(imageState$|async).hasImage">
          <div class="chip__container chip__center">
            <div class="chip__avatar chip__avatar--xlg float--none margin-reset--right margin-bottom--delta" [chipColor]="(name$|async)">
              <i aria-label="Picture Icon"
                class="chip__avatar-text chip__avatar-icon"
                [inlineSVG]="iconPicture"></i>
            </div>
          </div>
        </ng-template>
        <ng-template [ngIf]="(imageState$|async).hasImage">
          <div class="chip__container chip__center">
            <div class="chip__avatar chip__avatar--xlg float--none margin-reset--right margin-bottom--delta grey-base">
              <img [src]="(imageState$|async).imageUrl" class="centered" />
            </div>
          </div>
        </ng-template>
        <label [for]="uploadInputId" class="button--secondary button--small margin-bottom--delta clickable">Upload Image</label>
        <button *ngIf="(imageState$|async).hasImage" (click)="onClickRemoveImage()" class="button--danger button--small margin-bottom--delta clickable">Remove Image</button>
        <p class="margin-reset--bottom padding-right--delta padding-left--delta"><em>Note: Square images with transparent or white backgrounds will display best.</em></p>
        <form #fileForm>
          <input
            style="display: none;"
            [id]="uploadInputId"
            type="file"
            (change)="onChangeFileUpload($event)">
        </form>
      </div>
    </div>
  `
})
export class BakeryMgmtStoreSettingsEditImageComponent implements OnInit, OnDestroy {
  iconPicture = iconPicture;

  @Input('uiState$') uiState$: Rx.Observable<UiState>;
  @Input('reset$') reset$: Rx.Observable<any>;
  @Output('formState') formState$ = new Rx.BehaviorSubject<SimpleFormState>({isDirty: false, isValid: true, value: {}});
  @ViewChild('fileForm') fileFormElement: ElementRef;

  bakeryName$: Rx.Observable<string>;
  imageState$ = new Rx.BehaviorSubject<ImageUiState>(initialImageUiState);

  private resetSub: Rx.Subscription;
  private imageStateSub: Rx.Subscription;
  private formStateSub: Rx.Subscription;
  private tempImageUpdateSub: Rx.Subscription;
  private randomInputId: string;

  constructor(
  ) {
    this.randomInputId = (new Date()).getTime() + '';
  }

  ngOnInit() {
    this.resetSub = this.reset$.subscribe(() => this.reset());

    this.tempImageUpdateSub = this.uiState$
      .subscribe((uiState: UiState) => this.reset());

    this.bakeryName$ = this.uiState$
      .map((uiState: UiState) => {
        if (uiState == null || uiState.bakery == null) return '???';

        return uiState.bakery.name;
      });

    this.imageStateSub = this.uiState$
      .subscribe((uiState: UiState) => {
        if (uiState.bakery != null) {
          this.imageState$.next({
            hasImage: uiState.bakery.store_logo != null,
            imageUrl: uiState.bakery.store_logo,
            isChanged: false,
          })
        }
      });

    this.formStateSub = this.imageState$
      .subscribe((imageState: ImageUiState) => {
        const formValue: any = {};

        if (imageState.isChanged) {
          formValue.store_logo = imageState.imageUrl;
        }

        const newFormState = {
          isDirty: imageState.isChanged,
          isValid: true,
          value: formValue,
        };

        this.formState$.next(newFormState);
      }, (error) => console.error(error))

  }

  ngOnDestroy() {
    this.resetSub.unsubscribe();
    this.imageStateSub.unsubscribe();
    this.formStateSub.unsubscribe();
    this.tempImageUpdateSub.unsubscribe();
  }

  get uploadInputId(): string {
    return `photoUpload_id${this.randomInputId}`;
  }

  onChangeFileUpload($event) {
    const file = $event.target.files[0];
    const fileReader = new FileReader();
    let fileBinResults = null;
    fileReader.onload = (readerEvent) => {
      fileBinResults = (<any>readerEvent.target).result;

      this.imageState$.next({
        hasImage: true,
        imageUrl: `data:${file.type};base64,${btoa(fileBinResults)}`,
        isChanged: true,
      });
    };

    fileReader.readAsBinaryString(file);
  }

  onClickRemoveImage() {
    this.imageState$.next({
      hasImage: false,
      imageUrl: null,
      isChanged: true,
    });
  }

  private resetFileForm() {
    this.fileFormElement.nativeElement.reset();
  }

  private reset() {
    this.resetFileForm();
  }
}
