import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';
import { Actions as NgRxActions } from '@ngrx/effects';

import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { AlertService } from 'app/shared/components/alerts/alert.service';
import { Actions } from 'app/store/actions';
import { AdminCustomersEffects } from 'app/store/effects/admin-customers.effects';
import { validateNoWhitespaces } from 'app/shared/forms/no-whitespaces-validator';
import { phoneNumberValidator } from 'app/shared/forms/form-number-validator';

@Component({
  selector: 'marketing-bakery-owner-self-invite',
  template: require('./bakery-owner-self-invite.component.html'),
})
export class BakeryOwnerSelfInviteComponent implements OnInit, OnDestroy {
  token = '';
  form: FormGroup;

  private alertsSub: Subscription;

  constructor(
    private alertService: AlertService,
    private adminCustomersEffects: AdminCustomersEffects,
    private actions$: NgRxActions,
  ) { }

  ngOnInit() {
    this.form = new FormGroup({
      'bakeryName': new FormControl(null, [Validators.required, validateNoWhitespaces]),
      'ownerFirstName': new FormControl(null, [Validators.required, validateNoWhitespaces]),
      'ownerLastName': new FormControl(null, [Validators.required, validateNoWhitespaces]),
      'ownerEmail': new FormControl(null, [Validators.required, Validators.email]),
      'ownerPhoneNumber': new FormControl(null, [Validators.required, validateNoWhitespaces, phoneNumberValidator]),
    });

    this.alertsSub = this.actions$
      .subscribe((action: UnsafeAction) => {
        let messageType = null,
            messageContent = null;

        switch (action.type) {
          case Actions.REQUEST_INVITE_BAKERY_SUCCESS:
            messageType = 'success';
            messageContent = `An email has been sent to ${action.payload.ownerEmail} containing instructions on how to confirm your new account.`;
            break;

          case Actions.REQUEST_INVITE_BAKERY_ERROR:
            messageType = 'warning';
            messageContent = `Something went wrong! Please try again later.`;

            if (action.payload.error === 'duplicate_email_address') {
              messageContent = `That email address has already been taken.`;
            }
            break;
        }

        if (messageType != null && messageContent != null) {
          this.alertService[messageType](messageContent);
        }
      });
  }

  ngOnDestroy() {
    this.alertsSub.unsubscribe();
  }

  onSubmit() {
    this.adminCustomersEffects.requestBakeryInvite({
      bakery_name: this.form.value.bakeryName,
      owner_first_name: this.form.value.ownerFirstName,
      owner_last_name: this.form.value.ownerLastName,
      owner_email: this.form.value.ownerEmail,
      owner_phone_number: this.form.value.ownerPhoneNumber,
    });

    this.form.reset();
  }
}
