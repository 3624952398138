import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as Rx from 'rxjs';
import { Store } from '@ngrx/store';

import { Actions } from 'app/store/actions';
import { EntitiesState } from 'app/store/entities-state';
import { AppState } from 'app/store/app-state';
import { Bakery } from 'app/store/bakery';
import { BakeryProduct } from 'app/store/bakery-product';
import { ProductCategory } from 'app/store/product-category';
import { BakeryCustEffects } from 'app/store/effects/bakery-cust/bakery-cust.effects';
import { BakeryCustProductsEffects } from 'app/store/effects/bakery-cust/bakery-cust-products.effects';
import { BakeryCustomerUiState } from 'app/store/reducers/bakery-cust-ui-state-reducer';
import { stringPropSorter } from 'app/store/entity-utils';

// TODO: sort function
const nameSorter = stringPropSorter();
function sortProducts(a: BakeryProduct, b: BakeryProduct): number {
  if (a.store_sort_priority !== b.store_sort_priority) {
    return a.store_sort_priority - b.store_sort_priority;
  }

  return nameSorter(a, b);
}

export interface UiState {
  isLoading: boolean;
  isProductsLoading: boolean;
  bakery?: Bakery;
  allBakeryProducts?: BakeryProduct[];
  featuredBakeryProducts?: BakeryProduct[];
  hasFeaturedBakeryProducts?: boolean;
  regularBakeryProducts?: BakeryProduct[];
  hasRegularBakeryProducts?: boolean;
  productCategories?: ProductCategory[];
  selectedCategories?: ProductCategory[];
}

@Component({
  template: require('./master.component.html'),
})
export class BakeryCustProductMasterComponent implements OnInit, OnDestroy {
  uiState$: Rx.Observable<UiState>;
  form: FormGroup;

  private custUiStateSub: Rx.Subscription;
  private searchTextSub: Rx.Subscription;
  private categoryFilterSub: Rx.Subscription;
  private storeType: string[] = ['retail_only', 'retail_wholesale'];

  constructor(
    private store: Store<AppState>,
    private bakeryCustEffects: BakeryCustEffects,
    private bakeryCustProductsEffects: BakeryCustProductsEffects,
  ) {
    this.uiState$ = Rx.Observable
      .combineLatest(
        this.currentBakery$,
        this.store.select('bakeryCustUiState'),
        this.store.select('entitiesState'),
        (currentBakery, bakeryCustUiState, entitiesState) => ({currentBakery, bakeryCustUiState, entitiesState})
      )
      .map((combined) => {
        const currentBakery = <Bakery>combined.currentBakery;
        const productsMasterState = (<BakeryCustomerUiState>combined.bakeryCustUiState).bakeryProductsMasterState;
        const entitiesState = <EntitiesState>combined.entitiesState;

        const isLoading = (
          currentBakery == null ||
          productsMasterState.productCategoriesState.state == null ||
          productsMasterState.productCategoriesState.state === 'loading'
        )

        // Still loading? Quit early.
        if (isLoading) {
          return {
            isLoading: true,
            isProductsLoading: true,
          };
        }

        const bkryProdResults = (<any>productsMasterState.bakeryProductsState.result).bakery_products;
        const prodCatResults = (<any>productsMasterState.productCategoriesState.result).product_categories;

        let productCategories = [];
        if (prodCatResults != null) {
          productCategories = Object.keys(prodCatResults)
            .map(key => entitiesState.product_categories[key]);
        }

        let bakeryProducts = [];
        if (bkryProdResults != null) {
          bakeryProducts = Object.keys(bkryProdResults)
            .map(key => {
              const clone = {...entitiesState.bakery_products[key]};

              clone.bakery_product_templates.sort((varA, varB) => {
                return varA.unit_price - varB.unit_price;
              });

              return clone;
            })
            .filter(bakeryProduct => this.storeType.includes(bakeryProduct.store_type))
        }

        const featuredBakeryProducts = bakeryProducts
          .filter(bkryProd => bkryProd.store_featured)
          .sort(sortProducts);
        const regularBakeryProducts = bakeryProducts
          .filter(bkryProd => !bkryProd.store_featured)
          .sort(sortProducts);

        const selectedCategories = (<any>productsMasterState).categoryFilters
          .map(prodCatId => ({...entitiesState.product_categories[prodCatId]}))

        const selectableProductCategories = productCategories
          .filter(prodCat => !selectedCategories.find(selectedProdCat => selectedProdCat.id === prodCat.id));

        return {
          isLoading: false,
          isProductsLoading: productsMasterState.bakeryProductsState.state == null || productsMasterState.bakeryProductsState.state === 'loading',
          bakery: currentBakery,
          selectableProductCategories: selectableProductCategories,
          selectedCategories: selectedCategories,
          allBakeryProducts: bakeryProducts,
          featuredBakeryProducts: featuredBakeryProducts,
          firstFeaturedBakeryProduct: featuredBakeryProducts[0],
          remainingFeaturedBakeryProducts: featuredBakeryProducts.slice(1),
          hasFeaturedBakeryProducts: featuredBakeryProducts.length > 0,
          regularBakeryProducts: regularBakeryProducts,
          hasRegularBakeryProducts: regularBakeryProducts.length > 0,
        };
      })
      .startWith({isLoading: true, isProductsLoading: true})
      .shareReplay(1)

    this.form = new FormGroup({
      'searchText': new FormControl(null, [Validators.required]),
      'categoryFilter': new FormControl(null),
    });
  }

  get currentBakery$() {
    return this.bakeryCustEffects.currentBakery$;
  }

  ngOnInit() {
    this.bakeryCustProductsEffects.requestGetProductCategories();

    this.store.dispatch({
      type: Actions.SET_STORE_BAKERY_PRODUCTS_STORE_TYPE,
      payload: this.storeType,
    })

    this.store.dispatch({type: Actions.LOAD_STORE_PRODUCT_CATEGORIES});

    this.custUiStateSub = this.store.select('bakeryCustUiState')
      .subscribe(bakeryCustUiState => {
        const productsMasterState = (<BakeryCustomerUiState>bakeryCustUiState).bakeryProductsMasterState;

        this.form.patchValue(
          {
            searchText: productsMasterState.searchText,
          },
          {
            emitEvent: false,
          }
        );
      });

    this.searchTextSub = this.form.get('searchText').valueChanges
      .debounce(() => Rx.Observable.timer(500))
      .subscribe(searchTextValue => {
        this.store.dispatch({
          type: Actions.SET_STORE_BAKERY_PRODUCTS_SEARCH_TEXT,
          payload: searchTextValue,
        })
      });

    this.categoryFilterSub = this.form.get('categoryFilter').valueChanges
      .subscribe(categoryId => {
        this.store.dispatch({
          type: Actions.ADD_STORE_BAKERY_PRODUCTS_CATEGORY_FILTER,
          payload: categoryId,
        });

        this.form.patchValue(
          {
            categoryFilter: null,
          },
          {
            emitEvent: false,
          }
        );
      });
  }

  ngOnDestroy() {
    this.custUiStateSub.unsubscribe();
    this.searchTextSub.unsubscribe();
    this.categoryFilterSub.unsubscribe();
  }

  onClickRemoveCategoryFilter(prodCatId: number) {
    this.store.dispatch({
      type: Actions.REMOVE_STORE_BAKERY_PRODUCTS_CATEGORY_FILTER,
      payload: prodCatId,
    });
  }

  onClickRemoveAllCategoryFilters(prodCatId: number) {
    this.store.dispatch({
      type: Actions.REMOVE_STORE_BAKERY_PRODUCTS_CATEGORY_FILTERS,
    });
  }
}
