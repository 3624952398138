import { Component, OnInit, OnDestroy, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subject } from 'rxjs/Subject';

import { BakeryProduct } from 'app/store/bakery-product';
import { SimpleFormState } from 'app/shared/forms/form-states';
import { DetailUiState } from 'app/bakery-mgmt/product-mgmt/products/detail-ui-state';
import { zeroPad } from 'app/shared/string-utils';

interface LocalUiState {
  isRangePickerVisible: boolean;
  isDayPickerVisible: boolean;
}

@Component({
  selector: 'product-detail-forms-edit-availability',
  template: require('./edit-availability.component.html'),
})
export class ProductDetailFormsEditAvailabilityComponent implements OnInit, OnDestroy {
  @Input('uiState$') uiState$: Observable<DetailUiState>;
  @Input('reset$') reset$: Observable<any>;
  @Output('formState') formState$ = new Subject<SimpleFormState>();

  selectedDays$ = new BehaviorSubject<string[]>([]);
  selectableDays$: Observable<string[]>;
  days: number[];
  localUiState$: Observable<LocalUiState>;
  form: FormGroup;

  DAYS_OF_WEEK = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];

  private formValueSub: Subscription;
  private formStateSub: Subscription;

  constructor(
    private fb: FormBuilder,
  ) {
    this.form = this.fb.group({
      sold_out: new FormControl(null, [Validators.required]),
      date_range: this.fb.group(
        {
          available_range_specific: new FormControl('', [Validators.required]),
          available_range_start_month: new FormControl(null),
          available_range_start_day: new FormControl(null),
          available_range_end_month: new FormControl(null),
          available_range_end_day: new FormControl(null)
        },
        {
          validator: dateRangeGroupValidator
        }
      ),
      available_day_specific: new FormControl('', [Validators.required]),
      day_of_week: new FormControl(null),
    });
  }

  ngOnInit() {
    this.days = Array(31).fill(0).map((e, i) => i + 1);
    this.formStateSub = Observable
      .combineLatest(
        this.form.valueChanges,
        this.selectedDays$,
        this.uiState$.filter(uiState => !uiState.isLoading),
        (formValues, selectedDays, uiState) => ({formValues, selectedDays, uiState})
      )
      .map(({formValues, selectedDays, uiState}): SimpleFormState => {
        const isSelectedDaysValid = !formValues.available_day_specific ? true : selectedDays.length > 0;

        const form = this.form;
        if (!isSelectedDaysValid || !this.form.valid) {
          // Form isn't valid, just return a basic form state signaling that
          // it's not valid.
          return {
            isDirty: true,
            isValid: false,
            value: {},
          };
        }

        // Beyond here, the form is assumed to be valid.

        const formStateValue = {
          sold_out: formValues.sold_out,
          available_range_specific: formValues.date_range.available_range_specific,
          available_range_start_day: null,
          available_range_start_month: null,
          available_range_end_day: null,
          available_range_end_month: null,

          available_day_specific: formValues.available_day_specific,
          available_days: selectedDays,
        };

        // Calculate the new form value.
        if (formValues.date_range.available_range_specific) {
          formStateValue.available_range_start_day = formValues.date_range.available_range_start_day;
          formStateValue.available_range_start_month = formValues.date_range.available_range_start_month;

          formStateValue.available_range_end_day = formValues.date_range.available_range_end_day;
          formStateValue.available_range_end_month = formValues.date_range.available_range_end_month;
        }

        // TODO: Find differences
        const isSelectedDaysMatch = selectedDays.every(selectedDayOfWeek => {
          const existingMatch = uiState.bakeryProduct.available_days.find(existingDayOfWeek => existingDayOfWeek.toLowerCase() === selectedDayOfWeek.toLowerCase());

          return existingMatch != null;
        });

        const formState = {
          isDirty: !isSelectedDaysMatch || this.form.dirty,
          isValid: true,
          value: formStateValue,
        };

        return formState;
      })
      .subscribe(formState => {
        this.formState$.next(formState);
      })

    this.formValueSub = Observable
      .merge(
        this.uiState$.filter((uiState: DetailUiState) => !uiState.isLoading),
        this.reset$.switchMap(() => this.uiState$.filter((uiState: DetailUiState) => !uiState.isLoading).take(1))
      )
      .map((uiState: DetailUiState) => uiState.bakeryProduct)
      .do(bakeryProduct => console.log(`resetting with:`, bakeryProduct))
      .subscribe((bakeryProduct: BakeryProduct) => {
        // Set selected days
        this.selectedDays$.next(
          this.sortSelectedDays(bakeryProduct.available_days)
        );

        // FIXME: For some reason, a true form reset requires calling #reset twice...
        this.resetForm(bakeryProduct);
        this.resetForm(bakeryProduct);
      })

    this.localUiState$ = Observable
      .merge(
        this.form.valueChanges
          .map((values) => {
            return {
              isRangePickerVisible: values.date_range.available_range_specific,
              isDayPickerVisible: values.available_day_specific,
            }
          }),
        this.uiState$
          .filter(uiState => !uiState.isLoading)
          .map((uiState) => {
            return {
              isRangePickerVisible: uiState.bakeryProduct.available_range_specific,
              isDayPickerVisible: uiState.bakeryProduct.available_day_specific,
            }
          })
      )
      .startWith({isRangePickerVisible: false, isDayPickerVisible: false})

    this.selectableDays$ = this.selectedDays$
      .map((selectedDays: string[]) => {
        return this.DAYS_OF_WEEK.filter(dayOfWeek => selectedDays.indexOf(dayOfWeek) === -1);
      });

    this.form.get('day_of_week').valueChanges
      .withLatestFrom(
        this.selectedDays$,
        (dayOfWeekSelection, selectedDays) => ({dayOfWeekSelection, selectedDays})
      )
      .subscribe(({dayOfWeekSelection, selectedDays}) => {
        if (dayOfWeekSelection && dayOfWeekSelection.trim().length !== 0) {
          this.selectedDays$.next(
            this.sortSelectedDays([...selectedDays, dayOfWeekSelection])
          );
          this.form.patchValue({day_of_week: null});
        }
      });

    this.form.valueChanges
      .subscribe(val => console.log(`[availability] Form values:`, val));
  }

  ngOnDestroy() {
    this.formValueSub.unsubscribe();
    this.formStateSub.unsubscribe();
  }

  private resetForm(bakeryProduct: BakeryProduct) {
    this.form.reset({
      sold_out: bakeryProduct.sold_out,
      date_range: {
        available_range_specific: bakeryProduct.available_range_specific,
        available_range_start_month: bakeryProduct.available_range_start_month,
        available_range_start_day: bakeryProduct.available_range_start_day,
        available_range_end_month: bakeryProduct.available_range_end_month,
        available_range_end_day: bakeryProduct.available_range_end_day,
      },
      available_day_specific: bakeryProduct.available_day_specific,
      day_of_week: null,
    },
    {
      emitEvent: true,
    });
  }

  onClickRemoveDay(dayOfWeek: string) {
    const selectedDays = [...this.selectedDays$.value];
    selectedDays.splice(
      selectedDays.indexOf(dayOfWeek),
      1
    );
    this.selectedDays$.next(this.sortSelectedDays(selectedDays));
  }

  sortSelectedDays(selectedDays: string[]): string[] {
    const selectedDaysClone = [...selectedDays];

    selectedDaysClone.sort((dayA, dayB) => {
      const indexA = this.DAYS_OF_WEEK.indexOf(dayA),
            indexB = this.DAYS_OF_WEEK.indexOf(dayB);

      return indexA - indexB;
    })

    return selectedDaysClone;
  }
}

function dateRangeGroupValidator(dateRangeGroup: AbstractControl): {[key: string]: boolean} {
  if (dateRangeGroup.get('available_range_specific').value) {
    const startValidationResult =
      dateInputValidator(dateRangeGroup.get('available_range_start_month'), dateRangeGroup.get('available_range_start_day'));
    const endValidationResult =
      dateInputValidator(dateRangeGroup.get('available_range_end_month'), dateRangeGroup.get('available_range_end_day'));

    if (startValidationResult !== null || endValidationResult !== null) {
      return {invalid_date_range: true};
    }
  }

  return null;
}

function dateInputValidator(dateInputMonthCtrl: AbstractControl, dateInputDayCtrl: AbstractControl): {[key: string]: boolean} {
  const month = parseInt(dateInputMonthCtrl.value, 10) || null;
  const day = parseInt(dateInputDayCtrl.value, 10) || null;

  if (month === null && day === null) {
    return {invalid_date_range: true};
  }

  if (
    day < 1 || day > 31 ||
    month < 1 || month > 12
  ) {
    return {invalid_date_range: true};
  }

  return null;
}
