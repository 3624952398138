import { Component, OnInit, OnDestroy, Input, Output } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { Subject } from 'rxjs/Subject';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Store } from '@ngrx/store';

import { AppState } from 'app/store/app-state';
import { ProductCategory } from 'app/store/product-category';
import { BakeryProductProductCategory } from 'app/store/bakery-product-product-category';
import { SimpleFormState } from 'app/shared/forms/form-states';
import { DetailUiState } from 'app/bakery-mgmt/product-mgmt/products/detail-ui-state';
import { getPrimaryCategoryId, getProductCategoryEntities } from 'app/store/selectors/entities';

@Component({
  selector: 'product-detail-forms-edit-categories',
  template: `
    <form [formGroup]="form">
      <titled-card title="Product Categories">
        <ng-container data-card-body>
          <div class="row">
            <div class="columns-8__s columns-12__l">
              <label>Add Category</label>
              <select formControlName="category" class="select--placeholder">
                <option disabled value="">Select One</option>
                <option *ngFor="let productCategory of selectableProductCategories$ | async" [value]="productCategory.id">
                  {{ productCategory.name }}
                </option>
              </select>
              <div *ngIf="(selectedAssociations$ | async).length !== 0">
                <label>Primary Category</label>
                <p>
                  The Primary Category is the category that will be used for this product when you run reports that summarize
                   or filter by category.
                  If no category is selected as the Primary Category, the first of the added categories will be used in the reports.
                </p>
                <select formControlName="primaryCategory">
                  <option value="" disabled>No Primary Category</option>
                  <option *ngFor="let association of selectedAssociations$ | async" [value]="association.product_category_id">
                    {{ association.product_category.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="card card--md padding--reset background--grey-light">
              <ul class="list__header row padding-right--delta padding-left--delta padding-top--delta padding-bottom--delta">
                <li class="columns-3__s columns-7__m columns-10__l form__label margin-reset--bottom">Category Title</li>
              </ul>
              <hr class="margin--reset" />
              <ul class="list__items padding-right--delta padding-left--delta">
                <li class="list__item row" *ngIf="(selectedAssociations$ | async).length === 0">
                  <span class="columns-4__s columns-9__m columns-12__l">No associated categories</span>
                </li>
                <li class="list__item list__item--dark row" *ngFor="let bppc of selectedAssociations$| async">
                  <span class="columns-3__s columns-7__m columns-10__l">{{ bppc.product_category.name }}</span>
                  <span class="columns-1__s columns-2__m columns-2__l text--right">
                    <a (click)="onClickRemoveAssociation(bppc.bakery_product_id, bppc.product_category_id)" class="clickable link--danger">
                      Remove
                    </a>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </ng-container>
      </titled-card>
    </form>
  `
})
export class ProductDetailFormsEditCategoriesComponent implements OnInit, OnDestroy {
  @Input('uiState$') uiState$: Observable<DetailUiState>;
  @Input('reset$') reset$: Observable<any>;
  @Output('formState') formState$ = new Subject<SimpleFormState>();

  form: FormGroup;
  selectedAssociations$ = new BehaviorSubject<BakeryProductProductCategory[]>([]);
  selectableProductCategories$: Observable<ProductCategory[]>;
  categoriesFormState$ = new Subject<SimpleFormState>();
  primaryCategoryFormState$ = new Subject<SimpleFormState>();
  initialPrimaryCategoryId$: Observable<string>;
  initialPrimaryCategoryId: string;

  private formValueSub: Subscription;
  private categoriesFormValueSub: Subscription;
  private selectedAssociationsSub: Subscription;
  private initialPrimaryCategoryIdSub: Subscription;

  constructor(
    private store: Store<AppState>,
  ) {
    this.form = new FormGroup({
      category: new FormControl(''),
      primaryCategory: new FormControl(''),
    });
    this.initialPrimaryCategoryId$ = this.store.select(getPrimaryCategoryId);
  }

  ngOnInit() {
    this.initialPrimaryCategoryIdSub = this.initialPrimaryCategoryId$
      .subscribe(id => {
        this.initialPrimaryCategoryId = id;
        this.form.controls.primaryCategory.setValue(id, { emitEvent: false });
      });

    this.selectedAssociationsSub = Observable
      .merge(
        this.uiState$,
        this.reset$.switchMap(() => this.uiState$.take(1)),
      )
      .filter((uiState: DetailUiState) => !uiState.isLoading)
      .withLatestFrom(
        this.store.select(getProductCategoryEntities),
        (uiState, productCategories) => ({ uiState, productCategories })
      )
      .subscribe(({ uiState, productCategories }) => {
        const bppcs = uiState.bakeryProduct.bakery_product_product_categories
          .map((bppc) => {
            bppc = Object.assign({}, bppc);

            bppc.product_category = Object.keys(productCategories)
              .map(key => productCategories[key])
              .find(prodCat => prodCat.id === bppc.product_category_id);

            return bppc;
          });

        this.selectedAssociations$.next(bppcs);
      });

    this.selectableProductCategories$ = this.selectedAssociations$
      .withLatestFrom(
        this.uiState$.filter((uiState: DetailUiState) => !uiState.isLoading),
        (selectedAssociations, uiState) => ({ selectedAssociations, uiState })
      )
      .map(({ selectedAssociations, uiState }) => {
        const selectableProductCategories = uiState.productCategories
          .filter(productCategory => !selectedAssociations.some(bppc => bppc.product_category_id === productCategory.id));

        return selectableProductCategories;
      });

    this.form.controls.category.valueChanges
      .withLatestFrom(
        this.uiState$.filter((uiState: DetailUiState) => !uiState.isLoading),
        (categoryInputValue, uiState) => ({ categoryInputValue, uiState })
      )
      .subscribe(({ categoryInputValue, uiState }) => {
        const categoryId = parseInt(categoryInputValue, 10);

        if (!isNaN(categoryId)) {
          this.form.controls.category.reset('', { onlySelf: true });

          const bppc = {
            product_category_id: categoryId,
            bakery_product_id: uiState.bakeryProduct.id,
            product_category: uiState.productCategories.find(prodCat => prodCat.id === categoryId),
          };

          const associations = <BakeryProductProductCategory[]>[...this.selectedAssociations$.value, bppc];
          this.selectedAssociations$.next(associations);
        }
      });

    this.form.controls.primaryCategory.valueChanges
      .subscribe(primaryCategoryId => {
        const value = {
          isValid: true,
          isDirty: this.initialPrimaryCategoryId !== primaryCategoryId,
          value: {
            primary_category_id: primaryCategoryId,
          },
        };

        this.primaryCategoryFormState$.next(value);
      });

    this.categoriesFormValueSub = Observable
      .combineLatest(
        this.selectedAssociations$,
        this.uiState$.filter((uiState: DetailUiState) => !uiState.isLoading),
        (selectedAssociations, uiState) => ({ selectedAssociations, uiState })
      )
      .map(({ selectedAssociations, uiState }) => {
        const existingAssociations = uiState.bakeryProduct.bakery_product_product_categories;

        const newAssociations = selectedAssociations
          .filter(selectedAssociation => {
            const match = existingAssociations.find(existingAssociation => (
              existingAssociation.bakery_product_id === selectedAssociation.bakery_product_id
              && existingAssociation.product_category_id === selectedAssociation.product_category_id
            ));

            return match == null;
          });

        const removedAssociations = existingAssociations
          .filter(existingAssociation => {
            const match = selectedAssociations.find(selectedAssociation => (
              existingAssociation.bakery_product_id === selectedAssociation.bakery_product_id
              && existingAssociation.product_category_id === selectedAssociation.product_category_id
            ));

            return match == null;
          });

        return { newAssociations, removedAssociations };
      })
      .map(({ newAssociations, removedAssociations }) => {
        const preppedRemovedAssocs = removedAssociations
          .map(association => {
            return Object.assign({}, association, {_destroy: true});
          });

        const attributesValueArray = [
          ...newAssociations,
          ...preppedRemovedAssocs
        ];

        return {
          isValid: true,
          isDirty: !!newAssociations.length || !!removedAssociations.length,
          value: {
            bakery_product_product_categories_attributes: attributesValueArray,
          },
        };
      })
      .subscribe(val => this.categoriesFormState$.next(val));

    this.formValueSub = Observable
      .combineLatest(
        this.categoriesFormState$.startWith({ isDirty: false, isValid: true, value: {} }),
        this.primaryCategoryFormState$.startWith({ isDirty: false, isValid: true, value: {} })
      )
      .map((simpleFormStates: SimpleFormState[]): SimpleFormState => {
        const isDirty = simpleFormStates.some(formState => formState.isDirty);
        const isValid = simpleFormStates.every(formState => formState.isValid);
        const values = simpleFormStates.map(formState => formState.value);

        return {
          isValid: isValid,
          isDirty: isDirty,
          value: Object.assign({}, ...values)
        };
      })
      .subscribe(val => this.formState$.next(val));

    this.reset$.subscribe(() => {
      this.form.controls.primaryCategory.reset(this.initialPrimaryCategoryId);
    });
  }

  ngOnDestroy() {
    this.formValueSub.unsubscribe();
    this.categoriesFormValueSub.unsubscribe();
    this.selectedAssociationsSub.unsubscribe();
    this.initialPrimaryCategoryIdSub.unsubscribe();
  }

  onClickRemoveAssociation(bakeryProductId: number, productCategoryId: number) {
    const selectedAssociations = this.selectedAssociations$.value;

    this.selectedAssociations$.next(
      selectedAssociations.filter(bppc => !(bppc.bakery_product_id === bakeryProductId && bppc.product_category_id === productCategoryId))
    );

    if (this.form.controls.primaryCategory.value === productCategoryId.toString()) {
      this.form.controls.primaryCategory.setValue('');
    }
  }
}
