import { Component, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { AbstractControl, FormGroup, FormControl, Validators } from '@angular/forms';
import * as Rx from 'rxjs';
import { Store } from '@ngrx/store';
import { nullIfEmpty } from 'app/store/effects/params-helper';

import { AppState } from 'app/store/app-state';

@Component({
  selector: 'list-search-form',
  template: `
    <form
      *ngIf="!isSmall"
      [formGroup]="form"
      class="float--left visibility--small-hide"
    >
      <input
        type="search"
        [placeholder]="placeholder"
        formControlName="name"
      />
    </form>
    <form
      *ngIf="isSmall"
      [formGroup]="form"
      class="visibility--small-show"
    >
      <input
        type="search"
        [placeholder]="placeholder"
        class="margin-reset--bottom columns-4__s"
        formControlName="name"
      />
    </form>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListSearchFormComponent implements OnInit {
  @Input() changeFilterAction: string;
  @Input() placeholder = 'Search';
  @Input() debounce = 500;
  @Input() isSmall = false;

  form: FormGroup;

  private onChangeSearchOptions$: Rx.Observable<any>;

  constructor(
    private store: Store<AppState>
  ) {
    this.form = new FormGroup({
      name: new FormControl(null, []),
    });
  }

  ngOnInit() {
    this.onChangeSearchOptions$ = this.form.valueChanges
      // Only fire when form is valid
      .filter(val => this.form.valid)
      // Prune empty values
      .map(formValue => nullIfEmpty(formValue))

    this.onChangeSearchOptions$
      .debounce(() => Rx.Observable.timer(this.debounce))
      .subscribe(searchOptions => {
        this.store.dispatch({
          type: this.changeFilterAction,
          payload: {...searchOptions}
        })
      });
  }
}
