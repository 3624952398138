import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { Store } from '@ngrx/store';
import { Angular2TokenService } from 'app/angular2-token/angular2-token.service';
import { Actions as NgRxActions, Effect } from '@ngrx/effects';
import * as Rx from 'rxjs';
import { normalize, schema } from 'normalizr';

import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { AppState } from 'app/store/app-state';
import { Actions } from 'app/store/actions';
import { EntitiesState } from 'app/store/entities-state';
import { storePaymentConfigDeepSchema, paymentProviderConfigDeepSchema } from 'app/store/schema/default-schemata';
import { addEntityRequestHandler, addResultEntityRequestHandler } from 'app/store/effects/helpers';
import { UsersEffects } from 'app/store/effects/users.effects';
import * as selectors from 'app/store/selectors';
import { BakeryCustEffects } from 'app/store/effects/bakery-cust/bakery-cust.effects';

@Injectable()
export class BakeryMgmtStorePaymentsEffects {
  constructor(
    private actions$: NgRxActions,
    private tokenService: Angular2TokenService,
    private store: Store<AppState>,
    private usersEffects: UsersEffects,
  ) { }

  @Effect() getBkryMgmtStorePaymentConfigEffects$ = this.actions$
    .ofType(Actions.REQUEST_GET_BKRY_MGMT_STORE_PAYMENT_CONFIG)
    .switchMap((action: UnsafeAction) => {
      return addResultEntityRequestHandler(
        this.tokenService.get(
          `/api/bakery/store_payment_config`,
        ),
        storePaymentConfigDeepSchema,
        Actions.REQUEST_GET_BKRY_MGMT_STORE_PAYMENT_CONFIG,
      );
    })
    .share();

  @Effect() updatePaymentProviderConfigEffects$ = this.actions$
    .ofType(Actions.REQUEST_UPDATE_PAYMENT_PROVIDER_CONFIG)
    .switchMap((action: UnsafeAction) => {
      return addResultEntityRequestHandler(
        this.tokenService.put(
          `/api/bakery/payment_provider_configs/${action.payload.id}`,
          JSON.stringify(action.payload),
        ),
        paymentProviderConfigDeepSchema,
        Actions.REQUEST_UPDATE_PAYMENT_PROVIDER_CONFIG,
      );
    })
    .share();
}
