import { User } from 'app/store/user';

const orderedRoles = ['bakery_owner', 'bakery_manager', 'bakery_employee'];

export function sortUsersByRoleName(userA: User, userB: User): number {
  // Sort by role first.
  const userARoleIndex = orderedRoles.indexOf(userA.primary_role.name),
      userBRoleIndex = orderedRoles.indexOf(userB.primary_role.name);

  if (userARoleIndex !== userBRoleIndex) {
    return userARoleIndex - userBRoleIndex;
  }

  // Still equal? Sort by name.
  return sortUsersByName(userA, userB);
}

export function sortUsersByName(userA: User, userB: User): number {
  const userANameNormalized = userA.name.toLowerCase(),
    userBNameNormalized = userB.name.toLowerCase();

  if (userANameNormalized < userBNameNormalized) { return -1; }
  if (userANameNormalized > userBNameNormalized) { return 1; }

  return 0;
}
