import {
  Component,
  AfterViewInit,
  OnInit,
  ElementRef,
  Renderer2,
} from '@angular/core';
import { Router, ActivatedRoute, Event, NavigationEnd } from '@angular/router';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { Angulartics2GoogleTagManager} from 'angulartics2/gtm';
import { Angular2TokenService } from 'app/angular2-token/angular2-token.service';
import { Subscription } from 'rxjs/Subscription';
import { Store } from '@ngrx/store';
import { Actions as NgRxActions } from '@ngrx/effects';

import { AppState } from 'app/store/app-state';
import { UsersEffects } from 'app/store/effects/users.effects';
import { Actions } from 'app/store/actions';

import { routes as bakeryCustRoutes } from 'app/bakery-cust/routes';
import * as spinner from 'app/spinner';


declare var process: any;
declare var window: any;

@Component({
  selector: 'body',
  template: `
    <main role="main">
      <modal-container></modal-container>
      <router-outlet></router-outlet>
    </main>
  `
})
export class AppComponent implements AfterViewInit, OnInit {
  private lastStyleClass: string;
  private bodyStyleSub: Subscription;

  constructor(
    private tokenService: Angular2TokenService,
    private usersEffects: UsersEffects,
    private router: Router,
    private el: ElementRef,
    private renderer: Renderer2,
    private activatedRoute: ActivatedRoute,
    private store: Store<AppState>,
    private actions$: NgRxActions,
    // Although this service is never used, apparently it must be injected
    // to bootstrap Anglartics: https://github.com/angulartics/angulartics2#include-it-in-your-application
    angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
  ) {
    this.tokenService.init({
      apiBase: process.env.API_URL,
      resetPasswordCallback: `${process.env.API_URL}/reset_password/`,
      updatePasswordPath: `api/auth/password`
    });

    const hostnameTestRegex = new RegExp(`(.*)\.${process.env.WEB_DOMAIN.replace(/app\./i, '')}$`);

    const hostnameParseResults = hostnameTestRegex.exec(window.location.hostname);
    if (hostnameParseResults != null && hostnameParseResults[1] != 'www' && hostnameParseResults[1] != 'app') {
      const subdomainName = hostnameParseResults[1];

      // router.config.shift();
      router.config = [
        ...bakeryCustRoutes,
        ...router.config
      ];

      this.store.dispatch({
        type: Actions.SET_APP_OPERATION_MODE,
        payload: {
          type: 'custom_bakery',
          bakeryDomain: subdomainName,
        },
      });
    } else {
      this.store.dispatch({
        type: Actions.SET_APP_OPERATION_MODE,
        payload: {
          type: 'bakesmart',
          bakeryDomain: null,
        },
      });
    }
  }

  ngAfterViewInit() {
    this.bodyStyleSub = this.router.events
      .filter(event => event instanceof NavigationEnd)
      .mapTo(this.activatedRoute)
      .map(route => {
        // Try to find the last firstChild.
        while (route.firstChild) {
          route = route.firstChild;
        }

        return route;
      })
      .filter(route => route.outlet === 'primary')
      .flatMap(route => route.data)
      .subscribe(
        routeData => {
          const newStyleClass = routeData.bodyClass;

          if (this.lastStyleClass === newStyleClass) {
            // Don't adjust style class, just quit.
            return;
          }

          // Otherwise, there's been a change in the desired body class.

          // If there was a last style class, then remove it.
          if (this.lastStyleClass) {
            this.renderer.removeClass(this.el.nativeElement, this.lastStyleClass);
          }

          // Add the new style class, if it's present.
          if (newStyleClass) {
            this.renderer.addClass(this.el.nativeElement, newStyleClass);
          }

          // And store the new style class as the last style class.
          this.lastStyleClass = newStyleClass;
        }
      );
  }

  ngOnInit() {
    this.usersEffects.requestValidateToken();

    this.actions$.subscribe(action => console.debug(`action$`, action));

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
      spinner.hide();
    });
  }
}
