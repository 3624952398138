import { Component, OnInit, OnDestroy, Input } from '@angular/core';

@Component({
  selector: 'chip-text-small',
  template: `
    <div class="chip__avatar" [chipColor]="name">
      <h5 class="chip__avatar-text">{{ name | chipName }}</h5>
    </div>
  `
})
export class ChipTextSmallComponent implements OnInit, OnDestroy {
  @Input('name') name: string;

  constructor(
  ) { }

  ngOnInit() { }

  ngOnDestroy() { }
}
