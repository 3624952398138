import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { Actions as NgRxActions } from '@ngrx/effects';

import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { AlertService } from 'app/shared/components/alerts/alert.service';
import { ModalService } from 'app/shared/components/modals/modal.service';
import { BakeryLocation } from 'app/store/bakery-location';
import { BakerySubscriptionStatus } from 'app/store/bakery-subscription-status';
import { BakeryMgmtLocationsEffects, MasterUiState } from 'app/store/effects/bakery-mgmt-locations.effects';
import { Actions } from 'app/store/actions';
import { BakeryMgmtEffects } from 'app/store/effects/bakery-mgmt.effects';
import { Constants } from 'app/constants';

@Component({
  selector: 'bakery-mgmt-store-locations',
  template: require('./locations.component.html'),
})
export class BakeryMgmtStoreMainLocationsComponent implements OnInit, OnDestroy {
  selectedLocation: BakeryLocation;

  private alertsSub: Subscription;
  canAddLocationSub: Subscription;
  canAddLocation: boolean;
  constants = Constants;

  constructor(
    private bakeryMgmtLocationEffects: BakeryMgmtLocationsEffects,
    private bakeryMgmtEffects: BakeryMgmtEffects,
    private router: Router,
    private modalService: ModalService,
    private actions$: NgRxActions,
    private alertService: AlertService,
  ) { }

  ngOnInit() {
    this.alertsSub = this.actions$
      .subscribe((action: UnsafeAction) => {
        let messageType = null,
            messageContent = null;

        switch (action.type) {
          case Actions.REQUEST_DESTROY_BAKERY_LOCATION_SUCCESS:
            messageType = 'success';
            messageContent = `The location has been deleted.`;
            break;

          case Actions.REQUEST_DESTROY_BAKERY_LOCATION_ERROR:
            messageType = 'warning';
            messageContent = 'Something went wrong!';
            break;
        }

        if (messageType != null && messageContent != null) {
          this.alertService[messageType](messageContent);
        }
      });

    this.bakeryMgmtLocationEffects.requestGetLocations();

    this.canAddLocationSub = Observable.combineLatest(this.bakeryMgmtLocationEffects.masterUiStates$, this.bakeryMgmtEffects.currentBakery$).subscribe(([uiState, bakery]) => {
      const bakery_subscription_status: any = bakery.bakery_subscription_status;
      if (bakery_subscription_status.status === 'trialing' || uiState.locations.length < bakery_subscription_status.max_allowed_locations) {
        this.canAddLocation = true;
      } else {
        this.canAddLocation = false;
      }
    })
  }

  ngOnDestroy() {
    this.alertsSub.unsubscribe();
    this.canAddLocationSub.unsubscribe();
  }

  get uiStates$(): Observable<MasterUiState> {
    return this.bakeryMgmtLocationEffects.masterUiStates$;
  }

  onClickDetails(location: BakeryLocation) {
    this.router.navigate(['/bakery_mgmt/store_mgmt/locations/', location.id]);
  }

  onClickDelete(location: BakeryLocation) {
    this.modalService.openModal(
      `Are you sure about this?`,
      `Are you sure about removing the location "${location.name}"? If you remove this location customers will no longer be able to select this location when checking out. Additionally, all orders associated with this location will no longer be associated to a location.`,
      {
        confirmText: `Delete`,
        onConfirm: () => {
          this.bakeryMgmtLocationEffects.requestDestroyLocation(location);
        },
      }
    );
  }
}
