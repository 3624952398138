import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

import { BakeryOrder } from 'app/store/bakery-order';

@Component({
  selector: 'bkry-mgmt-orders-history-card',
  template: require('./order-history-card.component.html'),
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderHistoryCardComponent {
  @Input() isLoading = true;
  @Input() bakeryOrders: BakeryOrder[] = [];
}
