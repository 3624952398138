import { Directive, ElementRef, Input, OnChanges, SimpleChange, SimpleChanges, Renderer2 } from '@angular/core';

const COLORS = [
  'blue-base',
  'green-base',
  'purple-base',
  'pink-base',
  'yellow-base',
];

@Directive({
  selector: '[chipColor]',
})
export class ChipColorDirective implements OnChanges {
  @Input('chipColor') name: string;
  @Input('colors') colors: string[] = COLORS;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    const change: SimpleChange = changes.name;

    if (!change.isFirstChange) {
      const oldColorName = this.getColorNameForText(change.previousValue);

      this.renderer.removeClass(this.el.nativeElement, oldColorName);
    }

    const chipValueSource = change.currentValue || '';
    const newColorName = this.getColorNameForText(chipValueSource);
    this.renderer.addClass(this.el.nativeElement, newColorName);
  }

  private getColorNameForText(text: string): string {
    const nameValue = text
      .split('')
      .map(l => l.charCodeAt(0))
      .reduce((sum, curr) => sum + curr, 0);

    return this.colors[nameValue % this.colors.length];
  }
}
