import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { Store } from '@ngrx/store';
import { Angular2TokenService } from 'app/angular2-token/angular2-token.service';
import { Actions as NgRxActions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';
import { normalize, schema } from 'normalizr';

import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { AppState } from 'app/store/app-state';
import { EntitiesState } from 'app/store/entities-state';
import { Actions } from 'app/store/actions';
import { subscriptionPlanSchema } from 'app/store/schema/subscription-plan';
import { SubscriptionPlanType } from 'app/store/subscription-plan-type';
import { SubscriptionPlan } from 'app/store/subscription-plan';
import {
  bakeryLocationSchema,
  bakerySubscriptionStatusSchema,
  featureModulesSchema,
  subscriptionPlanTypeSchema,
  userSchema,
 } from 'app/store/schema/default-schemata';

 const subscribeSchema = new schema.Object({
   // TODO: Probably want to externalize this deep Bakery schema declaration
   bakery: new schema.Entity('bakeries', {
     bakery_locations: [ bakeryLocationSchema ],
     owner: userSchema,
     bakery_subscription_status: bakerySubscriptionStatusSchema,
   }),
   subscription_plan_types: [ subscriptionPlanTypeSchema ],
   feature_modules: [ featureModulesSchema ],
 });

export interface MasterUiState {
  isLoading: boolean;
  primaryPlanTypes: SubscriptionPlanType[];
  addOnPlanTypes: SubscriptionPlanType[];
  analyticsTypes: SubscriptionPlanType[];
  totalCustomers: number;
  monthlyRevenue: number;
}

@Injectable()
export class BakeryPricingEffects {
  masterUiStates$: Observable<MasterUiState>;

  constructor(
    private actions$: NgRxActions,
    private tokenService: Angular2TokenService,
    private store: Store<AppState>,
  ) {
  }

  requestGetSubscribe() {
    this.store.dispatch({
      type: Actions.REQUEST_GET_SUBSCRIBE,
    });
  }

  requestUpdateSubscribe(
    token: string,
    primary_plan_type: string,
    feature_modules: string[],
    locations_count: number,
    enable_wholesaler_ecomm: boolean = false
  ) {
    this.store.dispatch({
      type: Actions.REQUEST_UPDATE_SUBSCRIBE,
      payload: {
        token,
        primary_plan_type,
        feature_modules,
        locations_count,
        enable_wholesaler_ecomm
      }
    });
  }

  @Effect() getSubscribeEffects$ = this.actions$
    .ofType(Actions.REQUEST_GET_SUBSCRIBE)
    .switchMap(action => {
      return this.tokenService.get(`/api/bakery/subscription`)
        .map((response: Response) => response.json())
        .map((data: any[]) => {
          const results = normalize(data, subscribeSchema);

          return results.entities;
        })
        .switchMap((entities) => {
          return Observable.from([
            {
              type: Actions.LOADED_ENTITIES,
              payload: entities,
            },
            {
              type: Actions.REQUEST_GET_SUBSCRIBE_SUCCESS,
            },
          ]);
        })
        .catch((error) => {
          const errorPayload = error.json();

          return Observable.of({
            type: Actions.REQUEST_GET_SUBSCRIBE_ERROR,
            payload: errorPayload,
          });
        });
    })
    .share();

  @Effect() updateSubscribeEffects$ = this.actions$
    .ofType(Actions.REQUEST_UPDATE_SUBSCRIBE)
    .switchMap((action: UnsafeAction) => {
      return this.tokenService.put(
        `/api/bakery/subscription`,
        JSON.stringify(action.payload),
      )
        .map((response: Response) => response.json())
        .map((data: any[]) => {
          const results = normalize(data, subscribeSchema);

          return results.entities;
        })
        .switchMap((entities) => {
          return Observable.from([
            {
              type: Actions.LOADED_ENTITIES,
              payload: entities,
            },
            {
              type: Actions.REQUEST_UPDATE_SUBSCRIBE_SUCCESS,
            },
          ]);
        })
        .catch((error) => {
          const errorPayload = error.json();

          return Observable.of({
            type: Actions.REQUEST_UPDATE_SUBSCRIBE_ERROR,
            payload: errorPayload,
          });
        });
    })
    .share();
}
