import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { Store } from '@ngrx/store';

import { User } from 'app/store/user';
import { UsersEffects } from 'app/store/effects/users.effects';
import { AppState } from 'app/store/app-state';
import { AuthState } from 'app/store/auth-state';

interface ActionState {
  isRendered: boolean;
  isEnabled: boolean;
}

interface ExtraActionsState {
  resendInvite: ActionState;
  archiveEmployee: ActionState;
  unarchiveEmployee: ActionState;
  deleteEmployee: ActionState;
}

@Component({
  selector: 'bakery-mgmt-customers-master-list-item',
  template: require('./list-item.component.html'),
})
export class BakeryMgmtCustomersMasterListItemComponent implements OnInit {
  @Input() user: User;
  extraActionsStates$: Observable<ExtraActionsState>;

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private usersEffects: UsersEffects,
  ) { }

  ngOnInit() {
    // FIXME: This should be based of UsersEffects#currentUser$, not authState
    this.extraActionsStates$ = Observable
      .of({
          resendInvite: {isRendered: false, isEnabled: false},
          archiveEmployee: {isRendered: false, isEnabled: false},
          unarchiveEmployee: {isRendered: false, isEnabled: false},
          deleteEmployee: {isRendered: false, isEnabled: false},
      })
      .merge(
        this.store.select('authState')
          .filter((authState: AuthState) => authState.isLoggedIn)
          .pluck('currentUser')
          .map((currentUser: User) => ({
            authenticatedUser: currentUser,
            user: this.user,
          }))
          .map((mappedUsers) => {
            const user: User = mappedUsers.user;
            const authenticatedUser: User = mappedUsers.authenticatedUser;

            return {
              resendInvite: {
                isRendered: !user.archive_status,
                isEnabled: !user.confirmed_status,
              },
              archiveEmployee: {
                isRendered: !user.archive_status,
                isEnabled: !user.archive_status && user.id !== authenticatedUser.id,
              },
              unarchiveEmployee: {
                isRendered: user.archive_status,
                isEnabled: user.archive_status,
              },
              deleteEmployee: {
                isRendered: user.archive_status,
                isEnabled: user.archive_status,
              },
            };
          })
      )
  }

  onClickArchive() {
    this.usersEffects.requestUserUpdateArchiveStatus(this.user, true);
  }

  onClickUnarchive() {
    this.usersEffects.requestUserUpdateArchiveStatus(this.user, false);
  }

  onConfirmDelete() {
    this.usersEffects.requestUserDestroy(this.user);
  }

  onClickDetails() {
    this.router.navigate(['/bakery_mgmt/customers/', this.user.id]);
  }

  onClickResendInvite() {
    if (this.user.confirmed_status) { return; }

    this.usersEffects.requestUserResendInvite(this.user);
  }
}
