import {
  Component,
  OnInit,
  ChangeDetectionStrategy
} from '@angular/core';
import {
  FormGroup,
  Validators,
  FormBuilder
} from '@angular/forms';

import { GenericControlValueInput } from 'app/shared/components/form/generic-control-value-input';
import { buildForwardRef } from 'app/shared/components/form/input-forward-ref-builder';

interface FormData {
  name: string;
  email: string;
  phone_number: string;
}

@Component({
  selector: 'wholesaler-org-settings-basic-details-input',
  template: `
    <form
      [formGroup]="form"
      (submit)="onSubmitForm()"
    >
      <label>Wholesale Organization Name</label>
      <input
        type="text"
        placeholder="Organization Name"
        formControlName="name"
      />
      <div gridRow>
        <div gridCol s="4" m="9" l="6">
          <label>
            <tooltip
              label="Wholesale Organization Email"
              title="Email Address Use"
              text="This email is used to communicate information to the wholesale organization. Invoices and questions will be sent to this address.">
            </tooltip>
          </label>
          <input
            type="email"
            placeholder="petesdeli@email.com"
            formControlName="email"
          />
        </div>
        <div gridCol s="4" m="9" l="6">
          <label>Wholesale Organization Phone</label>
          <phone-input formControlName="phone_number"></phone-input>
        </div>
      </div>
    </form>
  `,
  providers: buildForwardRef(BasicDetailsInputComponent),
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BasicDetailsInputComponent extends GenericControlValueInput<FormData> implements OnInit {
  value: FormData = {name: null, email: null, phone_number: null};

  form: FormGroup;

  constructor(
    private fb: FormBuilder
  ) {
    super();

    this.form = fb.group({
      name: fb.control(null, []),
      email: fb.control(null, []),
      phone_number: fb.control(null, []),
    });
  }

  ngOnInit() {
    this.form.valueChanges
      .subscribe((formValue) => {
        this.onChangedCallback(formValue);
      })
  }

  onSubmitForm() {
    this.form.reset();
  }

  writeValue(val: FormData) {
    this.value = val;

    this.resetForm();
  }

  private resetForm() {
    this.form.reset(
      {...this.value},
      {emitEvent: false}
    )
  }
}
