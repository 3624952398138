import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import { AppState } from 'app/store/app-state';
import { EntitiesState } from 'app/store/entities-state';
import { BakeryLocation } from 'app/store/bakery-location';

export const entityMapToArray = (entityMap): any[] => {
  return Object.keys(entityMap)
    .map(key => entityMap[key]);
};

export const getEntities = (state: AppState): EntitiesState => state.entitiesState;
const buildEntitiesSelector = (entityName: string) => createSelector(getEntities, (entities) => entities[entityName]);
export const getUserEntities = buildEntitiesSelector('users');
export const getBakeryEntities = buildEntitiesSelector('bakeries');
export const getBakeryCartEntities = buildEntitiesSelector('bakery_carts');
export const getBakeryLocationEntities = buildEntitiesSelector('bakery_locations');
export const getBakeryOrderEntities = buildEntitiesSelector('bakery_orders');
export const getSubscriptionInvoiceEntities = buildEntitiesSelector('bakery_subscription_invoices');
export const getPaymentProviderConfigEntities = buildEntitiesSelector('payment_provider_configs');
export const getProductCategoryEntities = buildEntitiesSelector('product_categories');
export const getBakeryProductProductCategoryEntities = buildEntitiesSelector('bakery_product_product_categories');

export const getPrimaryCategoryId = createSelector(
  getBakeryProductProductCategoryEntities,
  (bppcsEntities) => {
    if (bppcsEntities === undefined) return '';
    const primaryBppc = entityMapToArray(bppcsEntities).find(bppc => bppc.primary);
    if (primaryBppc === undefined) return '';
    return primaryBppc.product_category_id.toString();
  }
);

export const getBakeryLocationEntitiesArray = createSelector(
  getBakeryLocationEntities,
  (bakeryLocationEntities) => {
    if (bakeryLocationEntities == null) return [];
    let locations: BakeryLocation[] = entityMapToArray(bakeryLocationEntities);
    return locations;
  }
);
