import { Component, Input, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';

import { AppState } from 'app/store/app-state';
import { Actions } from 'app/store/actions';
import { ModalComponent } from 'app/shared/components/modals/modal.component';

@Component({
  selector: 'bkry-mgmt-order-mgmt-order-status-action-button',
  template: `
    <span
      (click)="onClickOpenConfirmationModal()"
    >
      <ng-content></ng-content>
    </span>
    <modal
      title="Are you sure?"
      intro="You are about to change the status of Order #{{ bakeryOrder.order_number }}."
      confirmText="{{newStatus}} Order"
      cancelText="Cancel"
      (confirm)="onClickChangeOrderStatus()">
    </modal>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderStatusActionButtonComponent {
  @Input() bakeryOrder: any;
  @Input() newStatus: string;
  @Input() actionType: string = Actions.REQUEST_BKRY_MGMT_ORDER_MGMT_CHANGE_ORDER_STATUS;
  @ViewChild(ModalComponent) modal: ModalComponent;

  constructor(
    private store: Store<AppState>,
  ) { }

  onClickOpenConfirmationModal() {
    this.modal.openModal();
  }

  onClickChangeOrderStatus() {
    this.store.dispatch({
      type: this.actionType,
      payload: {
        id: this.bakeryOrder.id,
        newStatus: this.newStatus,
      },
    });
  }
}
