import { Component, Input, Output, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as Rx from 'rxjs';
import { Actions as NgRxActions } from '@ngrx/effects';

import * as selectors from 'app/store/selectors';
import { Actions } from 'app/store/actions';
import { AppState } from 'app/store/app-state';
import { BakeryOrder } from 'app/store/bakery-order';
import { User } from 'app/store/user';
import { PaymentCard } from 'app/store/payment-card';
import { phoneNumberValidator } from 'app/shared/forms/form-number-validator';
import { CheckoutBakeryOrderFormCust } from 'app/bakery-cust/checkout/checkout-bakery-order-form';

@Component({
  selector: 'bkry-cust-checkout-payment-edit',
  template: require('./payment-edit.component.html'),
})
export class BakeryCustCheckoutPaymentEditComponent implements OnInit, OnDestroy {
  @Input() parent: FormGroup;
  @Input('createToken$') createToken$: Rx.Observable<any>;
  @Output('stripeToken') stripeToken$ = new Rx.Subject<any>();
  @Output('valid') valid$ = new Rx.Subject<boolean>();

  paymentCards$: Rx.Observable<PaymentCard[]>;
  cardFormVisible$: Rx.Observable<boolean>;

  constructor(
    private store: Store<AppState>,
  ) { }

  ngOnInit() {
    this.paymentCards$ = this.store.select(selectors.getCurrentUserPaymentCards);

    this.cardFormVisible$ = this.store.select(selectors.isCheckoutCreditCardFormVisible)
      .startWith(false);
  }

  ngOnDestroy() {
  }
}
