import { Component, OnInit, OnDestroy, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as Rx from 'rxjs';

import { OrderItem } from 'app/store/order-item';
import { User } from 'app/store/user';
import { SimpleFormState } from 'app/shared/forms/form-states';
import { BakeryProductTemplate } from 'app/store/bakery-product-template';
import { BakeryProduct } from 'app/store/bakery-product';
import { AlertService } from 'app/shared/components/alerts/alert.service';

@Component({
  selector: 'bakery-cust-cart-order-item-quantity-edit',
  template: `
    <form [formGroup]="form">
      <input
        type="text"
        placeholder="1"
        class="margin-reset--bottom"
        formControlName="quantity"
        [textMask]="{mask: mask, guide: false}"
        (blur)="onBlur()"
      />
    </form>
  `
})
export class BakeryCustCartOrderItemQuantityEditComponent implements OnInit, OnDestroy {
  @Output('quantityUpdate$') quantityUpdate$ = new Rx.Subject<OrderItem>()

  form: FormGroup;
  mask = [/\d/, /\d/, /\d/, /\d/, /\d/, ];

  private _orderItem: OrderItem;
  private formValuesSub: Rx.Subscription;
  private resetFormSub: Rx.Subscription;

  constructor(
    private alertService: AlertService,
  ) {
    this.form = new FormGroup(
      {
        quantity: new FormControl(null, []),
      },
    );
  }

  get orderItem(): OrderItem {
    return this._orderItem;
  }

  @Input('orderItem')
  set orderItem(newOrderItem: OrderItem) {
    this._orderItem = newOrderItem;

    this.form.reset({quantity: this.orderItem.quantity});
  }

  onBlur() {
    const newQuantityValue = parseInt(this.form.value.quantity);

    const orderLimit = (<BakeryProductTemplate>this.orderItem.bakery_product_template).order_limit;

    if (orderLimit < newQuantityValue && orderLimit !== 0) {
      const messageType = 'warning';
      const messageContent = `There is a quantity limit for ${(<BakeryProduct>this.orderItem.bakery_product).name} of ${orderLimit}`;
      this.alertService[messageType](messageContent);
      this.form.reset({quantity: this.orderItem.quantity});
    } else if (newQuantityValue > 0) {
      // Valid quantity, start event.
      this.quantityUpdate$.next({
        ...this.orderItem,
        quantity: newQuantityValue,
      });
    } else {
      // Invalid quantity, reset form.
      this.form.reset({quantity: this.orderItem.quantity});
    }
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    // this.formValuesSub.unsubscribe();
    // this.resetFormSub.unsubscribe();
  }
}
