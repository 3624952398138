import { Component } from '@angular/core';

@Component({
  selector: 'list-table-items',
  template: `
    <ul class="list__items padding-left--delta padding-right--delta">
      <ng-content></ng-content>
    </ul>
  `,
})
export class ListTableItemsComponent { }
