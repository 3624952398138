// tslint:disable:max-line-length
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { Angulartics2Module } from 'angulartics2';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MatDatepickerModule,
  MatNativeDateModule,
  MatFormFieldModule,
  MatInputModule
} from '@angular/material';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { environment } from '../environments/environment';

import { Ng2Webstorage } from 'ngx-webstorage';
import { Angular2TokenService } from 'app/angular2-token/angular2-token.service';
import { InlineSVGModule } from 'ng-inline-svg';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { TextMaskModule } from 'angular2-text-mask';

import { Ng2PageScrollModule } from 'ng2-page-scroll';

import { reducers } from './store/reducers/index';

import { AdminCustomersEffects } from './store/effects/admin-customers.effects';
import { UsersEffects } from './store/effects/users.effects';
import { BakeryMgmtEffects } from './store/effects/bakery-mgmt.effects';
import { BakeryPricingEffects } from './store/effects/bakery/bakery-pricing.effects';
import { BakeryMgmtEmployeesEffects } from './store/effects/bakery-mgmt-employees.effects';
import { BakeryMgmtLocationsEffects } from './store/effects/bakery-mgmt-locations.effects';
import { BakeryCustEffects } from './store/effects/bakery-cust/bakery-cust.effects';
import { BakeryCustProductsEffects } from 'app/store/effects/bakery-cust/bakery-cust-products.effects';
import { BakeryCustCartOrderEffects } from 'app/store/effects/bakery-cust/bakery-cust-cart-order.effects';
import { BakeryCustWholesaleCartOrderEffects } from 'app/store/effects/bakery-cust/bakery-cust-wholesale-cart-order.effects';
import { BakeryMgmtProductTemplatesEffects } from 'app/store/effects/bakery-mgmt/bakery-mgmt-bakery-product-templates.effects';
import { BakeryMgmtProductAttributesEffects } from 'app/store/effects/bakery-mgmt/bakery-mgmt-bakery-product-attributes.effects';
import { BakeryEmplCartOrderEffects } from './store/effects/bakery-empl/bakery-empl-cart-order.effects';
import * as bakeryMgmtEffects from 'app/store/effects/bakery-mgmt';
import { RequestEffects } from 'app/store/effects/request.effects';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PageNotFoundComponent } from 'app/shared/components/page-not-found/page-not-found.component';
import { BakesmartSignInComponent } from './sign-in/sign-in.component';
import { BakeryOwnerSelfInviteComponent } from 'app/marketing/bakery-owner-self-invite/bakery-owner-self-invite.component';
import { AccountSettingsEditPasswordComponent } from 'app/shared/components/edit-password/edit-password.component';
import { AccountSettingsEditUserDetailsComponent } from 'app/shared/components/edit-user-details/edit-user-details.component';

import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AccountConfirmationComponent } from './account-confirmation/account-confirmation.component';

import { PhoneNumberPipe } from './shared/pipes/phone-number.pipe';
import { ChipNamePipe } from './shared/pipes/chip-name.pipe';
import { BakeryRoleNamePipe } from './shared/pipes/bakery-role-name.pipe';
import { FulfillmentTypeNamePipe } from './shared/pipes/fulfillment-type-name.pipe';
import { CentsPipe } from './shared/pipes/cents.pipe';
import { CentsObservablePipe } from './shared/pipes/cents-observable.pipe';
import { StoreHourPipe } from './shared/pipes/store-hour.pipe';

import {
  OrderCustomerTypePipe,
  OrderItemNamePipe,
  OrderTotalItemQuantityPipe,
  CapitalizePipe
} from 'app/shared/pipes';

import * as sharedComponents from 'app/shared/components';
import {
  TooltipOrderItemsListComponent
} from 'app/shared/components';
import {
  TooltipContainerComponent,
  TooltipItemsListComponent,
  TooltipLabelComponent,
  TooltipListHeaderComponent,
  TooltipListItemComponent
} from 'app/shared/components/card-list-tooltip';
import {
  ListTableHeadersComponent,
  ListTableHeaderComponent,
  ListTableItemsComponent
} from 'app/shared/components/list-table';
import * as sharedComponentsOrders from 'app/shared/components/orders';
import * as sharedComponentsInvoices from 'app/shared/components/invoices';


import { AlertService } from './shared/components/alerts/alert.service';
import { AlertContainerComponent } from './shared/components/alerts/alert-container.component';
import { AlertMessageComponent } from './shared/components/alerts/alert-message.component';
import { AvatarUploadComponent } from 'app/shared/components/avatar-upload/avatar-upload.component';
import { ChipAvatarSmallComponent } from 'app/shared/components/chip-avatar-small/chip-avatar-small.component';
import { ChipTextSmallComponent } from 'app/shared/components/chip-text-small/chip-text-small.component';
import { EditCautionAlertComponent } from 'app/shared/components/alerts/edit-caution-alert.component';
import { LoadingIndicatorComponent } from './shared/components/loading-indicator/loading-indicator.component';
import { ModalContainerComponent } from './shared/components/modals/modal-container.component';
import { ModalComponent } from './shared/components/modals/modal.component';
import { ModalService } from './shared/components/modals/modal.service';
import { PopoutContainerComponent } from './shared/components/popouts/popout-container.component';
import { PopoutListItemComponent } from './shared/components/popouts/popout-list-item.component';
import { PopoutListHeaderComponent } from './shared/components/popouts/popout-list-header.component';
import { PopoutItemsListComponent } from './shared/components/popouts/popout-items-list.component';
import { PopoutTriggerComponent } from './shared/components/popouts/popout-trigger.component';
import { PrintButtonComponent } from './shared/components/print/print-button.component';
import { TooltipComponent } from './shared/components/tooltip/tooltip.component';
import { OrderingArrowComponent } from './shared/components/ordering-arrow.component';

import { SharedSignInComponent } from 'app/shared/components/sign-in/sign-in.component';
import { SharedForgotPasswordComponent } from 'app/shared/components/forgot-password/forgot-password.component';
import { SharedResetPasswordComponent } from 'app/shared/components/reset-password/reset-password.component';
import { SharedAccountConfirmationComponent } from 'app/shared/components/account-confirmation/account-confirmation.component';
import { UserCreditCardComponent } from 'app/shared/components/user-credit-card/user-credit-card.component';

import { BakeryMgmtPageBlankStateComponent } from './shared/components/pages/bakery-mgmt/page-blank-state.component';
import { BakeryMgmtPageHeaderComponent } from './shared/components/pages/bakery-mgmt/page-header.component';
import { BakeryMgmtPageHeaderDetailComponent } from './shared/components/pages/bakery-mgmt/page-header-detail.component';

import { ChipColorDirective } from './shared/directives/chip-color.directive';
import { RoleRestrictedDirective } from './shared/directives/role-restricted.directive';
import { GridColDirective } from './shared/directives/grid-col.directive';
import { GridRowDirective } from './shared/directives/grid-row.directive';
import { DisableControlDirective } from './shared/directives/disabled-control-directive';

import { BakeryMgmtComponent } from 'app/bakery-mgmt/bakery-mgmt.component';
import { BakeryMgmtHeaderNavComponent } from './bakery-mgmt/header-nav/header-nav.component';

import * as bkryMgmtDashboard from 'app/bakery-mgmt/dashboard';

import { BakeryMgmtAccountSettingsComponent } from 'app/bakery-mgmt/account-settings/account-settings.component';
import { BakeryMgmtAccountSettingsBillingComponent } from 'app/bakery-mgmt/account-settings/billing/billing.component';

import { BakeryMgmtStoreMgmtComponent } from 'app/bakery-mgmt/store-mgmt/store-mgmt.component';
import { BakeryMgmtStoreMainComponent } from 'app/bakery-mgmt/store-mgmt/main/main.component';
import { BakeryMgmtStoreMainLocationsComponent } from 'app/bakery-mgmt/store-mgmt/main/locations/locations.component';
import { BakeryMgmtStoreMainPaymentsComponent } from 'app/bakery-mgmt/store-mgmt/main/payments/payments.component';
import { BakeryMgmtStoreMainPaymentsCashProviderComponent } from 'app/bakery-mgmt/store-mgmt/main/payments/cash-provider.component';
import { BakeryMgmtStoreMainPaymentsStripeConnectProviderComponent } from 'app/bakery-mgmt/store-mgmt/main/payments/stripe-connected.component';
import { BakeryMgmtStoreMainSettingsComponent } from 'app/bakery-mgmt/store-mgmt/settings/settings.component';
import { BakeryMgmtStoreSettingsEditImageComponent } from 'app/bakery-mgmt/store-mgmt/settings/edit-image.component';
import { BakeryMgmtStoreSettingsEditDescriptionComponent } from 'app/bakery-mgmt/store-mgmt/settings/edit-description.component';
import { LocationPhotoUploadComponent } from 'app/bakery-mgmt/store-mgmt/location-photo-upload/location-photo-upload.component';
import { BakeryMgmtStoreLocationDetailEditComponent } from 'app/bakery-mgmt/store-mgmt/location-detail-edit/location-detail-edit.component';
import { BusinessHoursComponent } from 'app/bakery-mgmt/store-mgmt/location-detail-edit/business-hours.component';
import { BakeryMgmtStoreAddLocationComponent } from 'app/bakery-mgmt/store-mgmt/add-location/add-location.component';
import { BakeryMgmtStoreLocationDetailComponent } from 'app/bakery-mgmt/store-mgmt/location-detail/location-detail.component';

import { BakeryMgmtEmployeesComponent } from 'app/bakery-mgmt/employees/employees.component';
import { BakeryMgmtEmployeesMasterComponent } from 'app/bakery-mgmt/employees/master/master.component';
import { BakeryMgmtEmployeesHeaderComponent } from 'app/bakery-mgmt/employees/master/header/header.component';
import { BakeryMgmtEmployeesListComponent } from 'app/bakery-mgmt/employees/master/list/list.component';
import { BakeryMgmtEmployeesListItemComponent } from 'app/bakery-mgmt/employees/master/list/list-item/list-item.component';
import { BakeryMgmtEmployeesDetailComponent } from 'app/bakery-mgmt/employees/detail/detail.component';
import { BakeryMgmtEditEmployeeDetailsComponent } from 'app/bakery-mgmt/employees/detail/edit-employee-details/edit-employee-details.component';

import { BakeryMgmtNewOrderComponent } from 'app/bakery-mgmt/new-order/new-order.component';
import { BakeryMgmtNewOrderMasterComponent } from 'app/bakery-mgmt/new-order/master/master.component';
import { BakeryMgmtNewOrderCheckoutComponent } from 'app/bakery-mgmt/new-order/checkout/checkout.component';
import { BakeryMgmtNewOrderCheckoutReviewOrderComponent } from 'app/bakery-mgmt/new-order/checkout/review-order.component';
import { BakeryMgmtNewOrderCheckoutNotesEditComponent } from 'app/bakery-mgmt/new-order/checkout/notes-edit.component';
import { BakeryMgmtNewOrderOrderListItemComponent } from 'app/bakery-mgmt/new-order/order-list-item/order-list-item.component';
import { BakeryMgmtNewOrderCheckoutCustomerInfoEditComponent } from 'app/bakery-mgmt/new-order/checkout/customer-info-edit.component';
import { BakeryMgmtNewOrderCheckoutPaymentEditComponent } from 'app/bakery-mgmt/new-order/checkout/payment-edit.component';
import { BakeryMgmtNewOrderCheckoutFulfillmentEditComponent } from 'app/bakery-mgmt/new-order/checkout/fulfillment-edit.component';
import { BakeryMgmtNewOrderCustomerSelectionComponent } from 'app/bakery-mgmt/new-order/customer-selection/customer-selection.component';
import { BakeryMgmtNewOrderProductSelectionComponent } from 'app/bakery-mgmt/new-order/product-selection/product-selection.component';
import { BakeryMgmtNewOrderCheckoutPromoCodeEditComponent } from 'app/bakery-mgmt/new-order/checkout/checkout-promo-code-edit.component';
import { BakeryMgmtNewOrderProductSelectionListItemComponent } from 'app/bakery-mgmt/new-order/product-selection/list-item/list-item.component';
import { BakeryMgmtCustomersComponent } from 'app/bakery-mgmt/customers/customers.component';
import { BakeryMgmtCustomersMasterComponent } from 'app/bakery-mgmt/customers/master/master.component';
import { BakeryMgmtCustomersMasterHeaderComponent } from 'app/bakery-mgmt/customers/master/header/header.component';
import { BakeryMgmtCustomersMasterListItemComponent } from 'app/bakery-mgmt/customers/master/list-item/list-item.component';
import { BakeryMgmtCustomersDetailComponent } from 'app/bakery-mgmt/customers/detail/detail.component';
import { BakeryMgmtCustomersDetailOrderHistoryComponent } from 'app/bakery-mgmt/customers/detail/order-history/order-history.component';
import { BakeryMgmtCustomersDetailSettingsComponent } from 'app/bakery-mgmt/customers/detail/settings/settings.component';
import { BakeryMgmtCustomersEditBasicDetailsComponent } from 'app/bakery-mgmt/customers/detail/edit-basic-details/edit-basic-details.component';
import { BakeryMgmtCustomerCreditCardComponent } from 'app/bakery-mgmt/customers/detail/customer-credit-card.component';

import { BakeryMgmtPromoCodesMasterComponent } from 'app/bakery-mgmt/promo-codes/master/master.component';
import { BakeryMgmtPromoCodesHeaderComponent } from 'app/bakery-mgmt/promo-codes/master/header/header.component';
import { BakeryMgmtPromoCodesComponent } from 'app/bakery-mgmt/promo-codes/promo-codes.component';
import { BakeryMgmtPromoCodesListComponent } from 'app/bakery-mgmt/promo-codes/master/list/list.component';
import { BakeryMgmtPromoCodesListItemComponent } from 'app/bakery-mgmt/promo-codes/master/list/list-item/list-item.component';

import { SubscriptionComponent } from './subscription/subscription.component';
import { SubscriptionInvoiceComponent } from './subscription/invoice/invoice.component';
import { SubscriptionHeaderNavComponent } from './subscription/header-nav/header-nav.component';
import { StripeCardFormComponent } from './subscription/stripe-card-form/stripe-card-form.component';

import { BakeryMgmtProductMgmtComponent } from 'app/bakery-mgmt/product-mgmt/product-mgmt.component';
import { BakeryMgmtProductMainComponent } from 'app/bakery-mgmt/product-mgmt/main/main.component';
import { BakeryMgmtProductProductsMasterComponent } from 'app/bakery-mgmt/product-mgmt/products/master/master.component';
import { MasterHeaderComponent as BakeryMgmtProductProductsMasterHeaderComponent } from 'app/bakery-mgmt/product-mgmt/products/master/header/header.component';
import { MasterListItemComponent as BakeryMgmtProductProductsMasterListItemComponent } from 'app/bakery-mgmt/product-mgmt/products/master/list-item/list-item.component';
import { BakeryMgmtProductProductAddComponent } from 'app/bakery-mgmt/product-mgmt/products/add/add.component';
import { BakeryMgmtProductProductDetailComponent } from 'app/bakery-mgmt/product-mgmt/products/detail/detail.component';
import { BakeryMgmtProductCategoriesMasterComponent } from 'app/bakery-mgmt/product-mgmt/categories/master/master.component';
import { MasterHeaderComponent as BakeryMgmtProductCategoriesMasterHeaderComponent } from 'app/bakery-mgmt/product-mgmt/categories/master/header/header.component';
import { MasterListItemComponent as BakeryMgmtProductCategoriesMasterListItemComponent } from 'app/bakery-mgmt/product-mgmt/categories/master/list-item/list-item.component';
import { BakeryMgmtProductCategoryDetailComponent } from 'app/bakery-mgmt/product-mgmt/categories/detail/detail.component';
import { BakeryMgmtProductCategoryDetailEditComponent } from 'app/bakery-mgmt/product-mgmt/categories/category-detail-edit/category-detail-edit.component';
import { BakeryMgmtProductCategoryProductAssociationsComponent } from 'app/bakery-mgmt/product-mgmt/categories/detail/product-associations/product-associations.component';

import { BakeryMgmtProductAttributesMasterComponent } from 'app/bakery-mgmt/product-mgmt/attributes/master/master.component';
import { BakeryMgmtProductAttributeDetailComponent } from 'app/bakery-mgmt/product-mgmt/attributes/detail/detail.component';
import { BakeryMgmtProductAttributeAddComponent } from 'app/bakery-mgmt/product-mgmt/attributes/add/add.component';
import { BakeryMgmtProductAttributeDetailEditComponent } from 'app/bakery-mgmt/product-mgmt/attributes/attribute-detail-edit/attribute-detail-edit.component';
import { MasterHeaderComponent as BakeryMgmtProductAttributesMasterHeaderComponent } from 'app/bakery-mgmt/product-mgmt/attributes/master/header/header.component';
import { MasterListItemComponent as BakeryMgmtProductAttributesMasterListItemComponent } from 'app/bakery-mgmt/product-mgmt/attributes/master/list-item/list-item.component';
import { BakeryMgmtProductAttributeVariantAssociationsComponent } from 'app/bakery-mgmt/product-mgmt/attributes/detail/variant-associations/variant-associations.component';

import { BakeryMgmtProductTemplatesMasterComponent } from 'app/bakery-mgmt/product-mgmt/templates/master/master.component';
import { BakeryMgmtProductTemplateDetailComponent } from 'app/bakery-mgmt/product-mgmt/templates/detail/detail.component';
import { BakeryMgmtProductTemplateAddComponent } from 'app/bakery-mgmt/product-mgmt/templates/add/add.component';
import { BakeryMgmtProductTemplateDetailEditComponent } from 'app/bakery-mgmt/product-mgmt/templates/template-detail-edit/template-detail-edit.component';
import { MasterHeaderComponent as BakeryMgmtProductTemplatesMasterHeaderComponent } from 'app/bakery-mgmt/product-mgmt/templates/master/header/header.component';
import { MasterListItemComponent as BakeryMgmtProductTemplatesMasterListItemComponent } from 'app/bakery-mgmt/product-mgmt/templates/master/list-item/list-item.component';
import { BakeryMgmtProductTemplateAttributeAssociationsComponent } from 'app/bakery-mgmt/product-mgmt/templates/detail/attribute-associations/attribute-associations.component';

import { BakeryMgmtProductProductDetailFormComponent } from 'app/bakery-mgmt/product-mgmt/products/detail-forms/detail-form.component';
import { ProductDetailFormsEditImageComponent } from 'app/bakery-mgmt/product-mgmt/products/detail-forms/edit-image.component';
import { ProductDetailFormsEditDescriptionComponent } from 'app/bakery-mgmt/product-mgmt/products/detail-forms/edit-description.component';
import { ProductDetailFormsEditTemplatesComponent } from 'app/bakery-mgmt/product-mgmt/products/detail-forms/edit-templates.component';
import { ProductDetailFormsEditStorePresentationComponent } from 'app/bakery-mgmt/product-mgmt/products/detail-forms/edit-store-presentation.component';
import { ProductDetailFormsEditAvailabilityComponent } from 'app/bakery-mgmt/product-mgmt/products/detail-forms/edit-availability.component';
import { ProductDetailFormsEditCategoriesComponent } from 'app/bakery-mgmt/product-mgmt/products/detail-forms/edit-categories.component';
import { ProductDetailFormsEditArchiveButtonComponent } from 'app/bakery-mgmt/product-mgmt/products/detail-forms/edit-archive-button.component';
import { ProductDetailFormsEditDeleteButtonComponent } from 'app/bakery-mgmt/product-mgmt/products/detail-forms/edit-delete-button.component';
import { ProductDetailFormsEditAdvancedAttributesComponent } from 'app/bakery-mgmt/product-mgmt/products/detail-forms/edit-advanced-attributes.component';
import { ProductDetailFormsEditAdvancedTemplatesComponent } from 'app/bakery-mgmt/product-mgmt/products/detail-forms/edit-advanced-templates.component';
import { ProductDetailFormsEditAdvancedOptionComponent } from 'app/bakery-mgmt/product-mgmt/products/detail-forms/edit-advanced-option.component';
import { ProductDetailFormsEditTaxesComponent } from 'app/bakery-mgmt/product-mgmt/products/detail-forms/edit-taxes.component';
import { BakeryMgmtReportingComponent } from 'app/bakery-mgmt/reporting/reporting.component';
import { BakeryMgmtReportingSalesTaxComponent } from 'app/bakery-mgmt/reporting/sales-tax/sales-tax.component';
import { BakeryMgmtReportingProductionCheckListComponent } from 'app/bakery-mgmt/reporting/production-check-list/production-check-list.component';
import { BakeryMgmtReportingProductionCheckListItemComponent } from 'app/bakery-mgmt/reporting/production-check-list/list-item/list-item.component';
import { BakeryMgmtReportingDecoratingComponent } from 'app/bakery-mgmt/reporting/decorating/decorating.component';
import { BakeryMgmtReportingItemSummaryComponent } from './bakery-mgmt/reporting/item-summary/item-summary.component';
import { BakeryMgmtReportingAttributeSummaryComponent } from './bakery-mgmt/reporting/attribute-summary/attribute-summary.component';
import { BakeryMgmtPromoCodesEffects } from 'app/store/effects/bakery-mgmt/bakery-mgmt-promo-codes.effects';

import * as bkryMgmtOrderMgmt from 'app/bakery-mgmt/order-mgmt';
import * as bkryMgmtWholesalers from 'app/bakery-mgmt/wholesalers';

import * as bkryMgmtWholesalersLists from 'app/bakery-mgmt/wholesalers/lists';
import * as bkryMgmtWholesalersDetail from 'app/bakery-mgmt/wholesalers/wholesaler-detail';
import * as bkryMgmtWholesalersGroupDetail from 'app/bakery-mgmt/wholesalers/group-detail';
import * as bkryMgmtWholesalersInvoiceDetail from 'app/bakery-mgmt/wholesalers/invoice-detail';
import * as bkryMgmtWholesalersCreateInvoice from 'app/bakery-mgmt/wholesalers/create-invoice';

import { RoleRouteGuard } from 'app/shared/role-route-guard.service';
import { WholesalerCustomerRouteGuard } from 'app/shared/wholesaler-customer-route-guard.service';

// FIXME: These bakery customer sections should be in a new module!
import { BakeryRouteGuard } from 'app/bakery-cust/bakery-route-guard.service';
import { BakeryCustComponent } from 'app/bakery-cust/bakery-cust.component';
import { HeaderNavComponent as BakeryCustHeaderNavComponent } from 'app/bakery-cust/header-nav/header-nav.component';
import { FooterNavComponent as BakeryCustFooterNavComponent } from 'app/bakery-cust/footer-nav/footer-nav.component';
import { BakeryCustProductMasterComponent } from 'app/bakery-cust/product/master/master.component';
import { BakeryCustProductMasterLocationDetailsComponent } from 'app/bakery-cust/product/master/location-details/location-details.component';
import { BakeryCustProductDetailComponent } from 'app/bakery-cust/product/detail/detail.component';
import { BakeryCustSignInComponent } from 'app/bakery-cust/sign-in/sign-in.component';
import { BakeryCustRegisterComponent } from 'app/bakery-cust/register/register.component';
import { BakeryCustForgotPasswordComponent } from 'app/bakery-cust/forgot-password/forgot-password.component';
import { BakeryCustResetPasswordComponent } from 'app/bakery-cust/reset-password/reset-password.component';
import { BakeryCustAccountConfirmationComponent } from 'app/bakery-cust/account-confirmation/account-confirmation.component';
import { BakeryCustAccountSettingsComponent } from 'app/bakery-cust/account-settings/account-settings.component';
import { BakeryCustAccountSettingUserDetailEditComponent } from 'app/bakery-cust/account-settings/user-detail-edit/user-detail-edit.component';
import { BakeryCustAccountSettingsUserEditImageComponent } from 'app/bakery-cust/account-settings/edit-image.component';
import { BakeryCustAccountSettingsUserPasswordEditComponent } from 'app/bakery-cust/account-settings/user-password-edit.component';
import { BakeryCustAccountSettingsUserCreditCardComponent } from 'app/bakery-cust/account-settings/user-credit-card.component';
import { BakeryCustPageHeaderComponent } from 'app/shared/components/pages/bakery-cust/page-header.component';
import { BakeryCustCartComponent } from 'app/bakery-cust/cart/cart.component';
import { BakeryCustCartOrderItemQuantityEditComponent } from 'app/bakery-cust/cart/order-item-quantity-edit.component';
import { BakeryCustCheckoutComponent } from 'app/bakery-cust/checkout/checkout.component';
import { BakeryCustCheckoutCustomerPromoCodeEditComponent } from 'app/bakery-cust/checkout/checkout-promo-code-edit.component';
import { BakeryCustCheckoutCustomerInfoEditComponent } from 'app/bakery-cust/checkout/customer-info-edit.component';
import { BakeryCustCheckoutFulfillmentEditComponent } from 'app/bakery-cust/checkout/fulfillment-edit.component';
import { BakeryCustCheckoutPaymentEditComponent } from 'app/bakery-cust/checkout/payment-edit.component';
import { BakeryCustOrderHistoryMasterComponent } from 'app/bakery-cust/order-history/master/master.component';

import * as bakeryCustComponents from 'app/bakery-cust';
import * as bakeryCustComponentsWholesaler from 'app/bakery-cust/wholesale';

import * as wholesalerSharedComponents from 'app/wholesaler/components';

import {RollbarService, rollbarFactory, RollbarErrorHandler} from './rollbar-service.provider';

import { ProductsSetupExamplesPopupComponent } from 'app/bakery-mgmt/product-mgmt/products-setup-examples-popup.component';

@NgModule({
  declarations: [
    AppComponent,

    // Shared
    PhoneNumberPipe,
    ChipNamePipe,
    BakeryRoleNamePipe,
    FulfillmentTypeNamePipe,
    CentsPipe,
    CentsObservablePipe,
    StoreHourPipe,
    OrderCustomerTypePipe,
    OrderItemNamePipe,
    OrderTotalItemQuantityPipe,
    CapitalizePipe,
    AlertContainerComponent,
    AlertMessageComponent,
    EditCautionAlertComponent,
    ModalContainerComponent,
    ModalComponent,
    PopoutContainerComponent,
    PopoutListItemComponent,
    PopoutListHeaderComponent,
    PopoutItemsListComponent,
    PopoutTriggerComponent,
    PrintButtonComponent,
    TooltipComponent,
    OrderingArrowComponent,
    ChipAvatarSmallComponent,
    ChipTextSmallComponent,
    AvatarUploadComponent,
    ChipColorDirective,
    RoleRestrictedDirective,
    GridColDirective,
    GridRowDirective,
    DisableControlDirective,
    TooltipContainerComponent,
    TooltipItemsListComponent,
    TooltipLabelComponent,
    TooltipListHeaderComponent,
    TooltipListItemComponent,
    UserCreditCardComponent,
    LoadingIndicatorComponent,
    sharedComponents.OnButtonComponent,
    sharedComponents.LoadingContainerComponent,
    sharedComponents.ToggleItemComponent,
    sharedComponents.TabGroupComponent,
    sharedComponents.TabItemComponent,
    sharedComponents.SimpleAddressComponent,
    sharedComponents.FileUploadTriggerComponent,
    sharedComponents.SidebarLayoutComponent,
    sharedComponents.ListSearchFormComponent,
    sharedComponents.DateRangeSearchFormComponent,
    sharedComponents.DateRangeSearchForm2Component,
    sharedComponents.MaskedInputComponent,
    sharedComponents.FormSubmitCancelControlComponent,
    sharedComponents.PhoneInputComponent,
    sharedComponents.StateSelectInputComponent,
    sharedComponents.ZipCodeInputComponent,
    sharedComponents.ChipImageUploadInputComponent,
    sharedComponents.TooltipAssociationListComponent,
    sharedComponents.MobileAssociationListComponent,
    sharedComponents.TooltipOrderItemsListComponent,
    sharedComponents.MobileOrderItemsListComponent,
    sharedComponents.TimezoneSelectComponent,
    sharedComponents.OrderStatusBadgeComponent,
    sharedComponents.InvoicePaymentStatusBadgeComponent,
    sharedComponents.DashboardTileComponent,
    sharedComponents.PaymentCardTextComponent,
    sharedComponents.TitledCardComponent,

    ListTableHeadersComponent,
    ListTableHeaderComponent,
    ListTableItemsComponent,

    sharedComponentsOrders.OrdersSumTileComponent,
    sharedComponentsOrders.OrderScheduleTypeComponent,
    sharedComponentsOrders.OrderHistoryCardComponent,
    sharedComponentsOrders.BakeryCustOrderDetailComponent,
    sharedComponentsOrders.BakeryCustOrderDetailPrezComponent,
    sharedComponentsOrders.OrderBalanceComponent,

    sharedComponentsInvoices.InvoiceDetailsCardComponent,
    sharedComponentsInvoices.InvoiceAddPaymentCardComponent,
    sharedComponentsInvoices.InvoiceAddPaymentFormComponent,
    sharedComponentsInvoices.InvoicesTableComponent,
    sharedComponentsInvoices.InvoicePaymentSummaryTextComponent,
    sharedComponentsInvoices.InvoicePaymentSummaryHistoryComponent,
    sharedComponentsInvoices.InvoicePaymentSummaryHistoryTableComponent,

    // Shared Page Styles - App Specific
    BakeryMgmtPageBlankStateComponent,
    BakeryMgmtPageHeaderComponent,
    BakeryMgmtPageHeaderDetailComponent,

    // Shared components
    PageNotFoundComponent,
    BakesmartSignInComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    AccountConfirmationComponent,
    BakeryOwnerSelfInviteComponent,
    SharedSignInComponent,
    SharedForgotPasswordComponent,
    SharedResetPasswordComponent,
    SharedAccountConfirmationComponent,

    AccountSettingsEditPasswordComponent,
    AccountSettingsEditUserDetailsComponent,

    // Bakery self-management
    BakeryMgmtComponent,
    bkryMgmtDashboard.DashboardComponent,
    bkryMgmtDashboard.HeaderComponent,
    bkryMgmtDashboard.OrderStatsComponent,
    bkryMgmtDashboard.OrderListComponent,
    bkryMgmtDashboard.OrderListItemComponent,
    bkryMgmtDashboard.SetupStatusComponent,
    BakeryMgmtHeaderNavComponent,
    BakeryMgmtAccountSettingsComponent,
    BakeryMgmtAccountSettingsBillingComponent,
    BakeryMgmtStoreMgmtComponent,
    BakeryMgmtStoreMainComponent,
    BakeryMgmtStoreMainLocationsComponent,
    BakeryMgmtStoreMainPaymentsComponent,
    BakeryMgmtStoreMainPaymentsCashProviderComponent,
    BakeryMgmtStoreMainPaymentsStripeConnectProviderComponent,
    BakeryMgmtStoreMainSettingsComponent,
    BakeryMgmtStoreSettingsEditImageComponent,
    BakeryMgmtStoreSettingsEditDescriptionComponent,
    BakeryMgmtStoreAddLocationComponent,
    LocationPhotoUploadComponent,
    BakeryMgmtStoreLocationDetailEditComponent,
    BusinessHoursComponent,
    BakeryMgmtStoreLocationDetailComponent,
    BakeryMgmtEmployeesComponent,
    BakeryMgmtEmployeesMasterComponent,
    BakeryMgmtEmployeesHeaderComponent,
    BakeryMgmtEmployeesListComponent,
    BakeryMgmtEmployeesListItemComponent,
    BakeryMgmtEmployeesDetailComponent,
    BakeryMgmtEditEmployeeDetailsComponent,
    BakeryMgmtNewOrderComponent,
    BakeryMgmtNewOrderMasterComponent,
    BakeryMgmtNewOrderCheckoutComponent,
    BakeryMgmtNewOrderCheckoutReviewOrderComponent,
    BakeryMgmtNewOrderCheckoutPaymentEditComponent,
    BakeryMgmtNewOrderCheckoutFulfillmentEditComponent,
    BakeryMgmtNewOrderOrderListItemComponent,
    BakeryMgmtNewOrderCheckoutCustomerInfoEditComponent,
    BakeryMgmtNewOrderCheckoutPromoCodeEditComponent,
    BakeryMgmtNewOrderCustomerSelectionComponent,
    BakeryMgmtNewOrderProductSelectionComponent,
    BakeryMgmtNewOrderCheckoutNotesEditComponent,
    BakeryMgmtNewOrderProductSelectionListItemComponent,
    BakeryMgmtCustomersComponent,
    BakeryMgmtCustomersMasterComponent,
    BakeryMgmtCustomersMasterHeaderComponent,
    BakeryMgmtCustomersMasterListItemComponent,
    BakeryMgmtCustomersDetailComponent,
    BakeryMgmtCustomersDetailOrderHistoryComponent,
    BakeryMgmtCustomersDetailSettingsComponent,
    BakeryMgmtCustomersEditBasicDetailsComponent,
    BakeryMgmtCustomerCreditCardComponent,
    BakeryMgmtPromoCodesComponent,
    BakeryMgmtPromoCodesHeaderComponent,
    BakeryMgmtPromoCodesListComponent,
    BakeryMgmtPromoCodesListItemComponent,
    BakeryMgmtPromoCodesMasterComponent,

    BakeryMgmtProductMgmtComponent,
    BakeryMgmtProductMainComponent,
    BakeryMgmtProductProductsMasterComponent,
    BakeryMgmtProductProductsMasterHeaderComponent,
    BakeryMgmtProductProductsMasterListItemComponent,
    BakeryMgmtProductProductAddComponent,
    BakeryMgmtProductProductDetailComponent,
    BakeryMgmtProductCategoriesMasterComponent,
    BakeryMgmtProductCategoriesMasterHeaderComponent,
    BakeryMgmtProductCategoriesMasterListItemComponent,
    BakeryMgmtProductCategoryDetailComponent,
    BakeryMgmtProductCategoryDetailEditComponent,
    BakeryMgmtProductCategoryProductAssociationsComponent,

    BakeryMgmtProductTemplatesMasterComponent,
    BakeryMgmtProductTemplateDetailComponent,
    BakeryMgmtProductTemplateAddComponent,
    BakeryMgmtProductTemplateDetailEditComponent,
    BakeryMgmtProductTemplatesMasterHeaderComponent,
    BakeryMgmtProductTemplatesMasterListItemComponent,
    BakeryMgmtProductTemplateAttributeAssociationsComponent,

    BakeryMgmtProductAttributesMasterComponent,
    BakeryMgmtProductAttributeDetailComponent,
    BakeryMgmtProductAttributeAddComponent,
    BakeryMgmtProductAttributeDetailEditComponent,
    BakeryMgmtProductAttributesMasterHeaderComponent,
    BakeryMgmtProductAttributesMasterListItemComponent,
    BakeryMgmtProductAttributeVariantAssociationsComponent,

    BakeryMgmtReportingComponent,
    BakeryMgmtReportingSalesTaxComponent,
    BakeryMgmtReportingProductionCheckListComponent,
    BakeryMgmtReportingProductionCheckListItemComponent,
    BakeryMgmtReportingDecoratingComponent,
    BakeryMgmtReportingItemSummaryComponent,
    BakeryMgmtReportingAttributeSummaryComponent,

    bkryMgmtOrderMgmt.BakeryMgmtOrderMgmtComponent,
    bkryMgmtOrderMgmt.BakeryMgmtOrderMasterComponent,
    bkryMgmtOrderMgmt.BakeryMgmtOrderMasterListComponent,
    bkryMgmtOrderMgmt.BakeryMgmtOrderMasterListItemComponent,
    bkryMgmtOrderMgmt.OrderDetailComponent,
    bkryMgmtOrderMgmt.OverviewComponent,
    bkryMgmtOrderMgmt.OverviewPrezComponent,
    bkryMgmtOrderMgmt.EditComponent,
    bkryMgmtOrderMgmt.EditOrderItemListComponent,
    bkryMgmtOrderMgmt.EditOrderItemComponent,
    bkryMgmtOrderMgmt.EditProductSelectionComponent,
    bkryMgmtOrderMgmt.EditProductSelectionListItemComponent,
    bkryMgmtOrderMgmt.NotesComponent,
    bkryMgmtOrderMgmt.NotesPrezComponent,
    bkryMgmtOrderMgmt.ImageControlComponent,
    bkryMgmtOrderMgmt.BillingComponent,
    bkryMgmtOrderMgmt.BillingPrezComponent,
    bkryMgmtOrderMgmt.OrderStatusActionButtonComponent,
    bkryMgmtOrderMgmt.CustomerDetailsComponent,
    bkryMgmtOrderMgmt.OrderDetailsComponent,
    bkryMgmtOrderMgmt.RefundNegativeBalanceComponent,
    bkryMgmtOrderMgmt.OrderBalanceComponent,

    bkryMgmtWholesalers.WholesalersComponent,
    bkryMgmtWholesalersLists.ListsComponent,
    bkryMgmtWholesalersLists.WholesalerListComponent,
    bkryMgmtWholesalersLists.WholesalerListPrezComponent,
    bkryMgmtWholesalersLists.WholesalerListHeaderComponent,
    bkryMgmtWholesalersLists.WholesalerListHeaderPrezComponent,
    bkryMgmtWholesalersLists.WholesalerListItemComponent,
    bkryMgmtWholesalersLists.ListArchiveButton,
    bkryMgmtWholesalersLists.GroupListComponent,
    bkryMgmtWholesalersLists.GroupListPrezComponent,
    bkryMgmtWholesalersLists.GroupListHeaderComponent,
    bkryMgmtWholesalersLists.GroupListHeaderPrezComponent,
    bkryMgmtWholesalersLists.GroupListItemComponent,
    bkryMgmtWholesalersLists.AddGroupFormComponent,
    bkryMgmtWholesalersLists.AddGroupFormPrezComponent,
    bkryMgmtWholesalersDetail.DetailComponent,
    bkryMgmtWholesalersDetail.SidebarSummaryComponent,
    bkryMgmtWholesalersDetail.SidebarArchiveButton,
    bkryMgmtWholesalersDetail.OrdersComponent,
    bkryMgmtWholesalersDetail.RecurringOrdersCardComponent,
    bkryMgmtWholesalersDetail.NextOrderDateTileComponent,
    bkryMgmtWholesalersDetail.OrderScheduleChangeActiveStatusButtonComponent,
    bkryMgmtWholesalersDetail.InvoicesComponent,
    bkryMgmtWholesalersDetail.WholesalerInvoicesCardComponent,
    bkryMgmtWholesalersDetail.WholesalerInvoiceCreationCardComponent,
    bkryMgmtWholesalersDetail.WholesalerInvoiceCreationCardPrezComponent,
    bkryMgmtWholesalersDetail.UsersComponent,
    bkryMgmtWholesalersDetail.TransferWholesalerOwnershipComponent,
    bkryMgmtWholesalersDetail.SettingsComponent,
    bkryMgmtWholesalersDetail.SettingsPrezComponent,
    bkryMgmtWholesalersDetail.OrgAccountInputComponent,
    bkryMgmtWholesalersGroupDetail.DetailComponent,
    bkryMgmtWholesalersGroupDetail.SettingsComponent,
    bkryMgmtWholesalersGroupDetail.SettingsPrezComponent,
    bkryMgmtWholesalersGroupDetail.PriceIndexComponent,
    bkryMgmtWholesalersGroupDetail.DeleteButton,
    bkryMgmtWholesalersInvoiceDetail.DetailComponent,
    bkryMgmtWholesalersCreateInvoice.CreateInvoiceComponent,

    BakeryMgmtProductProductDetailFormComponent,
    ProductDetailFormsEditImageComponent,
    ProductDetailFormsEditDescriptionComponent,
    ProductDetailFormsEditTemplatesComponent,
    ProductDetailFormsEditStorePresentationComponent,
    ProductDetailFormsEditAvailabilityComponent,
    ProductDetailFormsEditCategoriesComponent,
    ProductDetailFormsEditArchiveButtonComponent,
    ProductDetailFormsEditDeleteButtonComponent,
    ProductDetailFormsEditAdvancedAttributesComponent,
    ProductDetailFormsEditAdvancedTemplatesComponent,
    ProductDetailFormsEditAdvancedOptionComponent,
    ProductDetailFormsEditTaxesComponent,

    // Subscription
    SubscriptionComponent,
    SubscriptionInvoiceComponent,
    SubscriptionHeaderNavComponent,
    StripeCardFormComponent,

    BakeryCustComponent,
    BakeryCustHeaderNavComponent,
    BakeryCustFooterNavComponent,
    BakeryCustProductMasterComponent,
    BakeryCustProductMasterLocationDetailsComponent,
    BakeryCustProductDetailComponent,
    BakeryCustSignInComponent,
    BakeryCustRegisterComponent,
    BakeryCustForgotPasswordComponent,
    BakeryCustResetPasswordComponent,
    BakeryCustAccountConfirmationComponent,
    BakeryCustAccountSettingsComponent,
    BakeryCustAccountSettingUserDetailEditComponent,
    BakeryCustAccountSettingsUserEditImageComponent,
    BakeryCustAccountSettingsUserPasswordEditComponent,
    BakeryCustAccountSettingsUserCreditCardComponent,
    BakeryCustPageHeaderComponent,
    BakeryCustCartComponent,
    BakeryCustCartOrderItemQuantityEditComponent,
    BakeryCustCheckoutComponent,
    BakeryCustCheckoutCustomerPromoCodeEditComponent,
    BakeryCustCheckoutCustomerInfoEditComponent,
    BakeryCustCheckoutFulfillmentEditComponent,
    BakeryCustCheckoutPaymentEditComponent,
    BakeryCustOrderHistoryMasterComponent,
    bakeryCustComponents.BakeryCustOrderHistoryDetailComponent,
    bakeryCustComponents.BakeryCustOrderHistoryDetailOrderCancelButtonComponent,
    bakeryCustComponentsWholesaler.WholesalerComponent,
    bakeryCustComponentsWholesaler.ManagementComponent,
    bakeryCustComponentsWholesaler.ScheduleComponent,
    bakeryCustComponentsWholesaler.ScheduleDaySectionComponent,
    bakeryCustComponentsWholesaler.ScheduleDaySectionItemComponent,
    bakeryCustComponentsWholesaler.InvoicesComponent,
    bakeryCustComponentsWholesaler.PriceIndexComponent,
    bakeryCustComponentsWholesaler.SettingsComponent,
    bakeryCustComponentsWholesaler.SettingsPrezComponent,
    bakeryCustComponentsWholesaler.CreateOrderComponent,
    bakeryCustComponentsWholesaler.InvoiceDetailsComponent,
    bakeryCustComponentsWholesaler.BakeryWholesalerCustOrderDetailComponent,

    wholesalerSharedComponents.PriceIndexTableComponent,
    wholesalerSharedComponents.AddressesInputComponent,
    wholesalerSharedComponents.BasicDetailsInputComponent,
    wholesalerSharedComponents.CreditCardInputComponent,

    ProductsSetupExamplesPopupComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    InlineSVGModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    Ng2PageScrollModule,
    StoreModule.forRoot(reducers),
    !environment.production ? StoreDevtoolsModule.instrument({ maxAge: 50 }) : [],
    Ng2Webstorage.forRoot({
      prefix: 'on',
      separator: '.',
      caseSensitive: true
    }),
    EffectsModule.forRoot([
      AdminCustomersEffects,
      UsersEffects,
      BakeryMgmtEffects,
      BakeryMgmtEmployeesEffects,
      bakeryMgmtEffects.BakeryMgmtCustomersEffects,
      BakeryMgmtLocationsEffects,
      BakeryPricingEffects,
      bakeryMgmtEffects.BakeryMgmtProductsEffects,
      bakeryMgmtEffects.BakeryMgmtProductCategoriesEffects,
      bakeryMgmtEffects.BakeryMgmtStorePaymentsEffects,
      bakeryMgmtEffects.BakeryMgmtSubscriptionInvoicesEffects,
      bakeryMgmtEffects.BakeryMgmtReportingSalesTaxEffects,
      bakeryMgmtEffects.BakeryMgmtDecoratingReportEffects,
      bakeryMgmtEffects.BakeryMgmtItemSummaryReportEffects,
      bakeryMgmtEffects.BakeryMgmtAttributeSummaryReportEffects,
      bakeryMgmtEffects.BakeryMgmtOrderMgmtEffects,
      bakeryMgmtEffects.BakeryMgmtDashboardEffects,
      bakeryMgmtEffects.BakeryMgmtWholesalerEffects,
      bakeryMgmtEffects.BakeryMgmtWholesalerGroupEffects,
      BakeryCustEffects,
      BakeryCustProductsEffects,
      BakeryCustCartOrderEffects,
      BakeryMgmtProductTemplatesEffects,
      BakeryMgmtProductAttributesEffects,
      BakeryMgmtPromoCodesEffects,
      BakeryEmplCartOrderEffects,
      BakeryCustWholesaleCartOrderEffects,
      RequestEffects
    ]),
    AppRoutingModule,
    Angulartics2Module.forRoot([Angulartics2GoogleAnalytics]),
    SimpleNotificationsModule.forRoot(),
    TextMaskModule,
  ],
  providers: [
    Angular2TokenService,
    AlertService,
    ModalService,
    BakeryRouteGuard,
    RoleRouteGuard,
    WholesalerCustomerRouteGuard,
    CentsPipe,
    CentsObservablePipe,
    { provide: ErrorHandler, useClass: RollbarErrorHandler },
    { provide: RollbarService, useFactory: rollbarFactory }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    BakeryCustComponent,
    BakeryCustProductMasterComponent
  ]
})
export class AppModule {}
// tslint:enable:max-line-length
