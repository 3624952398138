import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import { getEntities } from './entities';
import { AppState } from 'app/store/app-state';
import { wholesalerOrgSchema } from 'app/store/schema/default-schemata';

export const getBkryMgmtWholesalerState = (state: AppState) => state.bakeryMgmtUiState.wholesalerState;

/*
  Wholesaler list state
*/
export const getBkryMgmtWholesalerStateMasterResult = (state: AppState) => state.bakeryMgmtUiState.wholesalerState.master.result;
export const getMasterIsLoading = (state: AppState) => state.bakeryMgmtUiState.wholesalerState.master.isLoading;
export const getBkryMgmtWholesalerMasterInviteLoading = (state: AppState) => state.bakeryMgmtUiState.wholesalerState.master.invite.isLoading;

export const getBkryMgmtWholesalerMasterResults = createSelector(
  getEntities,
  getBkryMgmtWholesalerStateMasterResult,
  (entities, resultIds: number[]) => {
    // Apparently this returns null instead of an empty array when there are no
    // results to denormalize.
    const {wholesaler_orgs: results} = denormalize(
      {wholesaler_orgs: resultIds},
      {wholesaler_orgs: [wholesalerOrgSchema]},
      entities
    );

    return results;
  }
);

/*
  Wholesaler detail state
*/

export const getBkryMgmtWholesalerStateDetail = (state: AppState) => state.bakeryMgmtUiState.wholesalerState.detail;
export const getBkryMgmtWholesalerDetailIsLoading = (state: AppState) => state.bakeryMgmtUiState.wholesalerState.detail.isLoading;

export const getBkryMgmtWholesalerStateDetailResult = (state: AppState) => state.bakeryMgmtUiState.wholesalerState.detail.result;

export const getBkryMgmtWholesalerMasterResultsCount = createSelector(
  getBkryMgmtWholesalerState,
  (wholesalerState) => {
    return !!wholesalerState.master.result && (wholesalerState.master.result instanceof Array) ? (<number[]>wholesalerState.master.result).length : 0;
  }
);

export const getBkryMgmtWholesalerDetailResult = createSelector(
  getEntities,
  getBkryMgmtWholesalerStateDetailResult,
  (entities, resultId: number) => {
    // FIXME: bakery_orders can't really be the correct key, right?
    const {bakery_orders: result} = denormalize(
      {bakery_orders: resultId},
      {bakery_orders: wholesalerOrgSchema},
      entities
    );

    return result;
  }
);
