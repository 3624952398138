import { TimestampedEntity } from 'app/store/timestamped-entity';
import { compareStrings } from 'app/shared/string-utils';

export function cloneSort(array: any[], sortFn: (a: any, b: any) => number) {
  const clone = [...array];

  // Sort in place.
  clone.sort(sortFn);

  return clone;
}

export function sortByCreatedAt(a: TimestampedEntity, b: TimestampedEntity): number {
  if (a.created_at < b.created_at) return -1;
  if (a.created_at > b.created_at) return 1;

  return 0;
}

export function sortByCreatedAtDesc(a: TimestampedEntity, b: TimestampedEntity): number {
  return -1 * sortByCreatedAt(a, b);
}

export function stringPropSorter(propName: string = 'name', ascending: boolean = true): (a: any, b: any) => number {
  const directionAdjuster = ascending ? 1 : -1;

  return function(a: any, b: any): number {
    return directionAdjuster * compareStrings(a[propName], b[propName]);
  }
}
