import { Component, Input } from '@angular/core';
import * as Rx from 'rxjs';

import { Bakery } from 'app/store/bakery';
import { BakeryCustEffects } from 'app/store/effects/bakery-cust/bakery-cust.effects';

@Component({
  selector: 'bakery-cust-page-header',
  template: `
    <header class="container print--hide">
      <div class="row margin-bottom--delta">
        <a
          *ngIf="hasLink"
          [routerLink]="backLink"
        >{{ backLinkText }}</a>
      </div>
      <h3 class="float--left">{{ title }}</h3>
      <p class="float--right visibility--small-hide margin-reset--bottom margin-top--echo">
        <ng-template [ngIf]="(hasStoreEmail$|async)">
          Please <a href="mailto:{{ (currentBakery$|async)?.store_email }}" class="clickable">contact {{ (currentBakery$|async)?.name }}</a> if you have questions.
        </ng-template>
        <ng-template [ngIf]="!(hasStoreEmail$|async)">
          Please contact us if you have questions.
        </ng-template>
      </p>
    </header>
    <hr
      class="dark print--hide"
      [ngClass]="{'margin-bottom--delta': hasTabs, 'margin-bottom--charlie': !hasTabs}"
    />
  `
})
export class BakeryCustPageHeaderComponent {
  @Input() title: string;
  @Input() backLink: string;
  @Input() backLinkText: string;
  @Input() hasTabs = false;

  hasStoreEmail$: Rx.Observable<boolean>;

  constructor(
    private bakeryCustEffects: BakeryCustEffects,
  ) {
    this.hasStoreEmail$ = this.bakeryCustEffects.currentBakery$
      .map((bakery: Bakery): boolean => {
        if (bakery == null) { return false; }

        return bakery.store_email != null && bakery.store_email.length > 0;
      });
  }

  get currentBakery$(): Rx.Observable<Bakery> {
    return this.bakeryCustEffects.currentBakery$;
  }

  get hasLink(): boolean {
    return this.backLink != null;
  }
}
