import {
  Component,
  Input,
  Output,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { AbstractControl, FormArray, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subject, Subscription } from 'rxjs';
import { Actions as NgRxActions } from '@ngrx/effects';

import { AppState } from 'app/store/app-state';
import * as selectors from 'app/store/selectors';
import { GetAction, PostAction } from 'app/store/actions/request.actions';
import { WholesalerOrg } from 'app/store/wholesaler-org';
import { BakeryOrder } from 'app/store/bakery-order';
import {
  bakeryOrderSchema,
  bakeryOrderInvoiceSchema
} from 'app/store/schema/default-schemata';
import { ModalComponent } from 'app/shared/components/modals/modal.component';

const ordersOpKey = 'wholesalerUninvoicedOrders',
      ordersSchema = [bakeryOrderSchema],
      invoiceCreationOpKey = 'wholesalerInvoiceCreation',
      invoiceCreationSchema = bakeryOrderInvoiceSchema;

@Component({
  selector: 'bkry-mgmt-wholesaler-invoice-creation-card',
  template: `
    <bkry-mgmt-wholesaler-invoice-creation-card-prez
      [bakeryOrders]="bakeryOrders$ | async"
      [isOrdersLoading]="isOrdersLoading$ | async"
      [isInvoiceCreateLoading]="isInvoiceCreateLoading$ | async"
      (onFormSubmit)="onFormSubmit($event)"
    ></bkry-mgmt-wholesaler-invoice-creation-card-prez>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WholesalerInvoiceCreationCardComponent implements OnInit, OnDestroy {
  @Input() wholesalerOrg: WholesalerOrg;

  isOrdersLoading$: Observable<boolean> = this.store
    .select(selectors.getRequestIsLoading(ordersOpKey))
    .startWith(true);

  bakeryOrders$: Observable<BakeryOrder[]> = this.store
    .select(selectors.getRequestResult(ordersOpKey, ordersSchema, []));

   isInvoiceCreateLoading$: Observable<boolean> = this.store
    .select(selectors.getRequestIsLoading(ordersOpKey))
    .startWith(true);

  onInvoiceCreateSuccess$: Observable<undefined> = selectors
    .onRequestSuccessBuilder(this.actions$, invoiceCreationOpKey);

  onInvoiceCreateSuccessSub: Subscription;

  constructor(
    private store: Store<AppState>,
    private actions$: NgRxActions,
    private router: Router
  ) { }

  ngOnInit() {
    const ordersActionPayload = {
      url: `/api/bakeries/${this.wholesalerOrg.bakery_id}/orders`,
      opKey: ordersOpKey,
      schema: ordersSchema,
      queryParams: {
        parent_type: 'WholesalerOrg',
        parent_id: this.wholesalerOrg.id,
        invoiced: false
      }
    };
    this.store.dispatch(new GetAction(ordersActionPayload));

    this.onInvoiceCreateSuccessSub = this.onInvoiceCreateSuccess$
      .subscribe((response: any) => {
        const invoiceDetailsUrl = `/bakery_mgmt/wholesalers/invoice/${response.payload.result}`;
        this.router.navigate([invoiceDetailsUrl]);
      })
  }

  ngOnDestroy() {
    this.onInvoiceCreateSuccessSub.unsubscribe();
  }

  onFormSubmit(bakeryOrderIds: number[]) {
    const invoiceCreateActionPayload = {
      url: `/api/invoices`,
      opKey: invoiceCreationOpKey,
      schema: invoiceCreationSchema,
      body: {
        parent_type: 'WholesalerOrg',
        parent_id: this.wholesalerOrg.id,
        bakery_order_ids: bakeryOrderIds
      }
    };
    this.store.dispatch(new PostAction(invoiceCreateActionPayload));
  }
}

@Component({
  selector: 'bkry-mgmt-wholesaler-invoice-creation-card-prez',
  template: require('./wholesaler-invoice-creation-card.component.html'),
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WholesalerInvoiceCreationCardPrezComponent {
  @ViewChild(ModalComponent) modal: ModalComponent;

  private _bakeryOrders: BakeryOrder[] = [];
  @Input()
  set bakeryOrders(bakeryOrders: BakeryOrder[]) {
    this._bakeryOrders = bakeryOrders;

    this.resetForm();
  };

  get bakeryOrders(): BakeryOrder[] {
    return this._bakeryOrders;
  }

  @Input() isOrdersLoading = true;
  @Input() isInvoiceCreateLoading = false;

  @Output('onFormSubmit') onFormSubmit$ = new Subject<number[]>();

  form: FormGroup;

  constructor(
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({
      bakery_order_ids: this.fb.array([], validateAtLeastOneOrder)
    })
  }

  onFormSubmit() {
    this.modal.openModal();
  }

  onFormSubmitConfirmation() {
    const bakeryOrderIds = this.form.value.bakery_order_ids
      .map((controlValue: boolean, idx: number) => {
        return {
          isSelected: controlValue,
          bakeryOrderId: this.bakeryOrders[idx].id
        }
      })
      .filter(mappedValue => mappedValue.isSelected)
      .map(mappedValue => mappedValue.bakeryOrderId)

    this.onFormSubmit$.next(bakeryOrderIds);
  }

  onFormCancel() {
    this.resetForm();
  }

  get bakery_order_ids() {
    return this.form.get('bakery_order_ids');
  }

  get isSubmittable(): boolean {
    return !this. isInvoiceCreateLoading && this.form.valid && this.form.dirty;
  }

  get isCancelable(): boolean {
    return !this. isInvoiceCreateLoading && this.form.dirty;
  }

  private resetForm() {
    const bakeryOrderIdsArray = this.form.get('bakery_order_ids') as FormArray;

    while (bakeryOrderIdsArray.length) {
      bakeryOrderIdsArray.removeAt(0);
    }

    this.bakeryOrders.forEach(bakeryOrder => {
      bakeryOrderIdsArray.push(this.fb.control(false));
    })
  }
}

function validateAtLeastOneOrder(control: FormArray): {[key: string]: boolean} {
  let isValid = false;
  for (let i = 0; i < control.length; i++) {
    const orderControlValue = control.at(i).value;

    if (orderControlValue === true) {
      isValid = true;
    }
  }

  if (!isValid) {
    return {required_order_selection: true};
  }

  return null;
}
