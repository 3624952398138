import { Component, OnInit, OnDestroy, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { Subject } from 'rxjs/Subject';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

import { ProductCategory } from 'app/store/product-category';
import { BakeryProduct } from 'app/store/bakery-product';
import { BakeryProductProductCategory } from 'app/store/bakery-product-product-category';
import { UiState } from '../detail.component';
import { SimpleFormState } from 'app/shared/forms/form-states';

@Component({
  selector: 'bakery-mgmt-product-category-product-associations',
  template: require('./product-associations.component.html'),
})
export class BakeryMgmtProductCategoryProductAssociationsComponent implements OnInit, OnDestroy {
  @Input('uiState$') uiState$: Observable<UiState>;
  @Input('success$') success$: Observable<any>;
  @Input('reset$') reset$: Observable<any>;
  @Output('formState') formState$ = new Subject<SimpleFormState>();

  form: FormGroup;
  selectedAssociations$ = new BehaviorSubject<BakeryProductProductCategory[]>([]);
  selectableBakeryProducts$: Observable<BakeryProduct[]>;

  private filteredProdCat$: Observable<ProductCategory>;
  private formStateSub: Subscription;
  private formValueChangesSub: Subscription;
  private selectedAssociationsSub: Subscription;

  constructor(
  ) {
    this.form = new FormGroup({
      product: new FormControl(''),
    });
  }

  ngOnInit() {
    this.selectedAssociationsSub = Observable
      .merge(
        this.uiState$,
        this.reset$.switchMap(() => this.uiState$.take(1)),
      )
      .filter((uiState: UiState) => !uiState.isLoading)
      .subscribe((uiState: UiState) => {
        const bppcs = uiState.productCategory.bakery_product_product_categories
          .map((bppc) => {
            bppc = Object.assign({}, bppc);

            bppc.bakery_product = uiState.bakeryProducts.find(bkryProd => bkryProd.id === bppc.bakery_product_id);

            return bppc;
          });

        this.selectedAssociations$.next(bppcs);
      });

    this.selectableBakeryProducts$ = this.selectedAssociations$
      .withLatestFrom(
        this.uiState$.filter((uiState: UiState) => !uiState.isLoading),
        (selectedAssociations, uiState) => ({selectedAssociations, uiState})
      )
      .map(({selectedAssociations, uiState}) => {
        const productCategory = uiState.productCategory;

        const selectableBakeryProducts = uiState.bakeryProducts
          .filter((bakeryProduct) => !selectedAssociations.some(bppc => bppc.bakery_product_id === bakeryProduct.id));

        return selectableBakeryProducts;
      });

    this.formValueChangesSub = this.form.valueChanges
      .withLatestFrom(
        this.uiState$.filter((uiState: UiState) => !uiState.isLoading),
        (formValue, uiState) => ({formValue, uiState})
      )
      .subscribe(({formValue, uiState}) => {
        const productId = parseInt(formValue.product);

        if (!isNaN(productId)) {
          this.form.reset({product: ''});

          const bppc = {
            bakery_product_id: productId,
            product_category_id: uiState.productCategory.id,
            bakery_product: uiState.bakeryProducts.find(bkryProd => bkryProd.id === productId),
          };

          const associations = <BakeryProductProductCategory[]>[...this.selectedAssociations$.value, bppc];
          this.selectedAssociations$.next(associations);
        }
      });

    this.formStateSub = Observable
      .combineLatest(
        this.selectedAssociations$,
        this.uiState$.filter((uiState: UiState) => !uiState.isLoading),
        (selectedAssociations, uiState) => ({selectedAssociations, uiState})
      )
      .map(({selectedAssociations, uiState}) => {
        const existingAssociations = uiState.productCategory.bakery_product_product_categories;

        const newAssociations = selectedAssociations
          .filter(selectedAssoc => {
            const match = existingAssociations.find(existingAssoc => {
              return existingAssoc.bakery_product_id === selectedAssoc.bakery_product_id && existingAssoc.product_category_id === selectedAssoc.product_category_id;
            });

            return match == null;
          });

        const removedAssociations = existingAssociations
          .filter(existingAssoc => {
            const match = selectedAssociations.find(selectedAssoc => {
              return existingAssoc.bakery_product_id === selectedAssoc.bakery_product_id && existingAssoc.product_category_id === selectedAssoc.product_category_id;
            });

            return match == null;
          });

        return {newAssociations, removedAssociations};
      })
      .map(({newAssociations, removedAssociations}) => {
        const preppedRemovedAssocs = removedAssociations
          .map(assoc => {
            return Object.assign({}, assoc, {_destroy: true});
          });

        const attributesValueArray = [
          ...newAssociations,
          ...preppedRemovedAssocs
        ];

        return {
          isValid: true, // this form is always valid
          isDirty: !!newAssociations.length || !!removedAssociations.length,
          value: {
            bakery_product_product_categories_attributes: attributesValueArray,
          },
        };
      })
      .subscribe(val => this.formState$.next(val));
  }

  onClickRemoveAssociation(bakeryProductId: number, productCategoryId: number) {
    const selectedAssociations = this.selectedAssociations$.value;

    this.selectedAssociations$.next(
      selectedAssociations.filter(bppc => {
        return !(bppc.bakery_product_id === bakeryProductId && bppc.product_category_id === productCategoryId);
      })
    )
  }

  ngOnDestroy() {
    this.formStateSub.unsubscribe();
    this.formValueChangesSub.unsubscribe();
    this.selectedAssociationsSub.unsubscribe();
  }
}
