import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { Actions } from 'app/store/actions';
import { BakeryOrder } from 'app/store/bakery-order';
import { NOT_ASKED, LOADING, fromSuccess, fromError } from 'app/remote-data';
import { RemoteData } from 'app/remote-data';
import { CheckoutBakeryOrderFormCust } from 'app/bakery-cust/checkout/checkout-bakery-order-form';

export interface State {
  isLoading: boolean;
  bakeryOrder?: CheckoutBakeryOrderFormCust;
  taxes: RemoteData<number>;
}

export const initialState: State = {
  isLoading: true,
  bakeryOrder: null,
  taxes: NOT_ASKED,
};

export function reducer(state: State = initialState, action: UnsafeAction): State {
  switch (action.type) {
    case Actions.SET_CUST_BAKERY_ORDER:
    case Actions.SET_WHOLESALE_BAKERY_ORDER:
      return {
        ...state,
        isLoading: false,
        taxes: NOT_ASKED,
        bakeryOrder: {
          ...state.bakeryOrder,
          order_items: action.payload.cart.order_items,
          total_price: action.payload.cart.total_price,
          total_discount_price: action.payload.cart.total_discount_price,
          payment_card_id: null,
          payment_card_selection: null,
          form_fulfillment_data: {
            fulfillment_type: null,
            fulfillment_date: null,
            fulfillment_at: null,
            fulfillment_location_id: null,
            fulfillment_location_type: null,
            bakeryLocations: action.payload.currentBakery.bakery_locations,
            customer_address: action.payload.currentUser != null
              ? action.payload.currentUser.default_address
              : null,
            fulfillment_address: {
              street: null,
              unit: null,
              city: null,
              state: null,
              zip: null,
            },
          },
          ...(action.payload.currentUser != null && {
            order_customer_email: action.payload.currentUser.email,
            order_customer_name: action.payload.currentUser.name,
            order_customer_phone_number: action.payload.currentUser.phone_number,
          })
        }
      };

    case Actions.UPDATE_CUST_BAKERY_ORDER:
    case Actions.UPDATE_WHOLESALE_BAKERY_ORDER:
      return {
        ...state,
        bakeryOrder: {
          ...state.bakeryOrder,
          ...action.payload,
        },
      };

    case Actions.UPDATE_CUST_BAKERY_ORDER_DISCOUNT:
      return {
        ...state,
        bakeryOrder: {
          ...state.bakeryOrder,
          ...action.payload,
        },
      };

    case Actions.REQUEST_TAXES_FOR_CUSTOMER:
      return {
        ...state,
        taxes: LOADING
      };

    case Actions.REQUEST_TAXES_FOR_CUSTOMER_SUCCESS:
      return {
        ...state,
        taxes: fromSuccess(action.payload)
      };

    case Actions.REQUEST_TAXES_FOR_CUSTOMER_ERROR:
      return {
        ...state,
        taxes: fromError(action.payload)
      };

    case Actions.UPDATE_WHOLESALE_ORDER_ITEMS:
      return {
        ...state,
        bakeryOrder: {
          ...state.bakeryOrder,
          order_items: action.payload,
        },
      };

    default:
      return state;
  }
}
