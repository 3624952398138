import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Actions as NgRxActions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { Angular2TokenService } from 'app/angular2-token/angular2-token.service';
import { HttpParams, HttpParameterCodec } from '@angular/common/http';
import { Moment } from 'moment/moment';
import * as moment from 'moment/moment';

import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { AlertService } from 'app/shared/components/alerts/alert.service';
import { AppState } from 'app/store/app-state';
import { Actions } from 'app/store/actions';
import * as selectors from 'app/store/selectors';
import { BakeryOrder } from 'app/store/bakery-order';
import { OrderItemsDateRangeService } from 'app/shared/services/order-items-date-range.service';
import { optionalValidator } from 'app/shared/forms/optional-validator';
import { dateValidator, searchDateRangeValidatorBuilder } from 'app/shared/forms/date-validators';

export interface SearchOptions {
  searchStartDate?: Moment;
  searchEndDate?: Moment;
  query: string;
}

declare var process: any;

const PER_PAGE = 50;

enum Page {
  Previous,
  Next,
  Reset
}

@Component({
  template: require('./list.component.html'),
  providers: [OrderItemsDateRangeService],
})
export class BakeryMgmtOrderMasterListComponent implements OnInit, OnDestroy {
  filterForm: FormGroup;
  hasQuery$: Observable<boolean>;
  private querySub: Subscription;
  private reloadOrders = new Subject<null>();

  onChangeSearchOptions$: Observable<SearchOptions>;
  onClickShowDetails$ = new Subject<BakeryOrder>();

  exportHref$: Observable<string>;

  private pageMessage$ = new BehaviorSubject<Page>(Page.Reset);
  page$ = this.pageMessage$
    .scan((acc, message) => {
      switch (message) {
        case Page.Previous: return acc - 1;
        case Page.Next: return acc + 1;
        case Page.Reset: return 0;
      }
    }, 0)
    .distinctUntilChanged();

  bakeryOrders$ = this.store.select(selectors.selectBakeryOrders);
  private min$ = this.page$.map(page => page * PER_PAGE);
  private max$ = this.min$.map(min => min + PER_PAGE);
  currentPage$ = Observable.combineLatest(
    this.min$,
    this.max$,
    this.bakeryOrders$
  )
    .map(([min, max, bakeryOrders]) => bakeryOrders.slice(min, max));
  resultsCount$ = this.bakeryOrders$.map((xs) => xs.length);
  bottom$ = this.min$.map(min => min + 1);
  top$ = Observable.combineLatest(
    this.max$,
    this.resultsCount$
  )
    .map(([max, total]) => Math.min(max, total));

  private onClickShowDetailsSub: Subscription;
  private alertsSub: Subscription;
  private searchSub: Subscription;

  constructor(
    private actions$: NgRxActions,
    private store: Store<AppState>,
    private router: Router,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private dateRangeService: OrderItemsDateRangeService,
    private tokenService: Angular2TokenService
  ) {
    this.filterForm = new FormGroup(
      {
        search_start_date: new FormControl(this.dateRangeService.defaultStartDate.toDate(), [optionalValidator(dateValidator)]),
        search_end_date: new FormControl(this.dateRangeService.defaultEndDate.toDate(), [optionalValidator(dateValidator)]),
        query: new FormControl('', []),
      },
      searchDateRangeValidatorBuilder('search_start_date', 'search_end_date')
    );
  }

  ngOnInit() {
    this.onChangeSearchOptions$ = this.filterForm.valueChanges
      .filter(val => this.filterForm.valid)
      .map(formValue => {
        const query = formValue.query.trim();
        const searchStartDate = moment(formValue.search_start_date).startOf('day');
        const searchEndDate = moment(formValue.search_end_date).endOf('day');

        return {
          query,
          searchStartDate,
          searchEndDate,
        };
      });

    this.hasQuery$ = this.filterForm.get('query').valueChanges.map(query => query.trim().length > 0);

    this.querySub = this.hasQuery$.subscribe(hasQuery => {
      const action = hasQuery ? 'disable' : 'enable';
      this.filterForm.get('search_start_date')[action]();
      this.filterForm.get('search_end_date')[action]();
    });

    const searchOptions$ = this.onChangeSearchOptions$
      .do(() => this.pageMessage$.next(Page.Reset))
      .debounce(() => Observable.timer(500))
      .startWith({
        query: '',
        searchStartDate: this.dateRangeService.defaultStartDate,
        searchEndDate: this.dateRangeService.defaultEndDate
      });

    const filters$ = searchOptions$.map((searchOptions: SearchOptions) => {
      return {
        order_status: this.route.snapshot.data.order_status,
        query: searchOptions.query,
        search_start_date: searchOptions.searchStartDate,
        search_end_date: searchOptions.searchEndDate,
      };
    });

    const reload$ = this.reloadOrders.asObservable().startWith(null);
    this.searchSub = Observable.combineLatest(filters$, reload$).subscribe(([payload, _]) => {
      this.store.dispatch({
        type: Actions.CHANGE_BKRY_MGMT_ORDER_MGMT_ORDERS_FILTERS,
        payload
      });
    });

    this.exportHref$ = filters$.map((payload) => {
      const params = new HttpParams({
        fromObject: {
          ...this.tokenService.getAuthDataFromStorage(),
          ...payload,
        },
        encoder: new HttpUrlEncodingCodec()
      });
      return `${process.env.API_URL}/api/bakeries/whatever/orders.csv?${params}`;
    });

    this.onClickShowDetailsSub = this.onClickShowDetails$
      .subscribe((bakeryOrder: BakeryOrder) => {
        this.router.navigate([
          '/bakery_mgmt/order_mgmt/orders/',
          bakeryOrder.id,
        ]);
      });

    this.alertsSub = this.actions$
      .subscribe((action: UnsafeAction) => {
        switch (action.type) {
          case Actions.REQUEST_BKRY_MGMT_ORDER_MGMT_CHANGE_ORDER_STATUS_SUCCESS:
            this.reloadOrders.next();
            this.alertService.success('The order status has been updated.');
            return;

          case Actions.REQUEST_BKRY_MGMT_ORDER_MGMT_CHANGE_ORDER_STATUS_ERROR:
            this.alertService.warning(action.payload.error || 'Something went wrong!');
            return;
        }
      });
  }

  ngOnDestroy() {
    this.onClickShowDetailsSub.unsubscribe();
    this.alertsSub.unsubscribe();
    this.searchSub.unsubscribe();
    this.querySub.unsubscribe();
  }

  previousPage() {
    this.pageMessage$.next(Page.Previous);
  }

  nextPage() {
    this.pageMessage$.next(Page.Next);
  }
}

class HttpUrlEncodingCodec implements HttpParameterCodec {
  encodeKey(k: string): string { return encodeURIComponent(k); }

  encodeValue(v: string): string { return Array.isArray(v) ? encodeURIComponent(JSON.stringify(v)) : encodeURIComponent(v); }

  decodeKey(k: string): string { return decodeURIComponent(k); }

  decodeValue(v: string) { return decodeURIComponent(v); }
}
