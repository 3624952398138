import { FormControl } from '@angular/forms';

export function validatePositiveInteger(minimumValue: number = 0) {
  return function(c: FormControl) {
    const parsedValue = parseInt(c.value);

    if (isNaN(parsedValue)) { return {positiveInteger: false}; }

    if (parsedValue < minimumValue) { return {positiveInteger: false}; }

    return null;
  }
}
