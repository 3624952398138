import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';

import { PopoutService } from 'app/shared/components/popouts/popout.service';

@Component({
  selector: 'popout-container',
  template: `
    <ng-content></ng-content>
  `,
  providers: [PopoutService]
})
export class PopoutContainerComponent implements OnDestroy {
  isPopoutActive = false;

  private popoutActiveSub: Subscription;

  constructor(
    private popoutService: PopoutService,
  ) {
    this.popoutActiveSub = this.popoutService.popoutActive$
      .subscribe(isActive => this.isPopoutActive = isActive);
  }

  ngOnDestroy() {
    this.popoutActiveSub.unsubscribe();
  }

  onClickPopoutActive() {
    this.popoutService.togglePopoutActive();
  }
}
