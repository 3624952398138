import { Component, OnInit, OnDestroy, Input, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as Rx from 'rxjs';
import { Actions as NgRxActions } from '@ngrx/effects';

import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { AppState } from 'app/store/app-state';
import { AlertService } from 'app/shared/components/alerts/alert.service';
import { UsersEffects } from 'app/store/effects/users.effects';
import { Actions } from 'app/store/actions';
import { User } from 'app/store/user';
import { Bakery } from 'app/store/bakery';
import { MasterState } from 'app/store/reducers/master-state-reducer';
import { validateNoWhitespaces } from 'app/shared/forms/no-whitespaces-validator';

interface UiState {
  isLoading: boolean;
  isBakesmart?: boolean;
  isBakery?: boolean;
  bakery?: Bakery;
}

@Component({
  selector: 'shared-sign-in',
  template: require('./sign-in.component.html'),
})
export class SharedSignInComponent implements OnInit, OnDestroy {
  @Input('forgotPasswordLink') forgotPasswordLink: string;
  @Input('uiState') uiState$: Rx.Observable<UiState>;
  @Output('signInSuccess') signInSuccess$: Rx.Observable<User>;

  form: FormGroup;

  private submit$ = new Rx.Subject<any>();
  private alertsSub: Rx.Subscription;
  private submitSub: Rx.Subscription;

  constructor(
    private router: Router,
    private alertService: AlertService,
    private usersEffects: UsersEffects,
    private actions$: NgRxActions,
    private store: Store<AppState>,
  ) {
    this.form = new FormGroup({
      'email': new FormControl(null, [Validators.required, Validators.email]),
      'password': new FormControl(null, [Validators.required, Validators.minLength(8), validateNoWhitespaces]),
    });

    this.signInSuccess$ = this.usersEffects.requestUserSignInEffects$
      .filter(action => action.type === Actions.REQUEST_USER_SIGN_IN_SUCCESS)
      .withLatestFrom(
        this.usersEffects.currentUser$,
        (_, currentUser) => currentUser
      );
  }

  ngOnInit() {
    this.alertsSub = this.actions$
      .subscribe((action: UnsafeAction) => {
        let messageType = null,
            messageContent = null;

        switch (action.type) {
          case Actions.REQUEST_USER_SIGN_IN_SUCCESS:
            if (action.payload.bakery_account_status !== 'paused') { return; }
            messageType = 'warning';
            messageContent = `Your account has been paused. Please contact customer support at <a href="mailto:hello@ordernova.com">hello@ordernova.com</a> to resolve this issue.`;
            break;

          case Actions.REQUEST_USER_SIGN_IN_ERROR:
            messageType = 'warning';
            messageContent = `Could not sign in with the provided credentials.`;

            switch (action.payload.error) {
              case 'unconfirmed_email':
                messageContent = `A confirmation email was sent to your account at '${action.payload.error_data.email}'. You must follow the instructions in the email before your account can be activated.`;
                break;

              case 'archived_user':
                messageContent = `Your account has been archived. Please contact customer support at <a href="mailto:hello@ordernova.com">hello@ordernova.com</a> to resolve this issue.`;
                break;

              case 'paused_user':
                messageContent = `Your account has been paused. Please contact customer support at <a href="mailto:hello@ordernova.com">hello@ordernova.com</a> to resolve this issue.`;
                break;
            }
            break;
        }

        if (messageType != null && messageContent != null) {
          this.alertService[messageType](messageContent);
        }
      });

    this.submitSub = this.submit$
      .withLatestFrom(
        this.uiState$,
        (_, uiState) => uiState
      )
      .subscribe(uiState => {
        const bakeryId = uiState.isBakery ? uiState.bakery.id : null;

        this.usersEffects.requestUserSignIn(
          this.form.value.email,
          this.form.value.password,
          bakeryId,
        );
      });
  }

  onSubmit() {
    this.submit$.next();
  }

  ngOnDestroy() {
    this.alertsSub.unsubscribe();
    this.submitSub.unsubscribe();
  }
}
