import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { Store } from '@ngrx/store';
import { Angular2TokenService } from 'app/angular2-token/angular2-token.service';
import { Actions as NgRxActions, Effect } from '@ngrx/effects';
import * as Rx from 'rxjs';
import { normalize, schema } from 'normalizr';

import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { AppState } from 'app/store/app-state';
import { Actions } from 'app/store/actions';
import * as selectors from 'app/store/selectors';
import { addResultEntityRequestHandler } from 'app/store/effects/helpers';
import {
  wholesalerGroupSchema,
  wholesalerOrgSchema,
  bakeryProductSchema
} from 'app/store/schema/default-schemata';
import { shallowPruneEmpty } from 'app/store/effects/params-helper';

@Injectable()
export class BakeryMgmtWholesalerGroupEffects {
  constructor(
    private actions$: NgRxActions,
    private tokenService: Angular2TokenService,
    private store: Store<AppState>,
  ) { }

  @Effect() changeListFiltersEffects$ = this.actions$
    .ofType(Actions.CHANGE_BKRY_MGMT_WHOLESALER_GROUPS_FILTERS)
    .switchMap((action: UnsafeAction) => {
      return Rx.Observable.of({
        type: Actions.REQUEST_GET_BKRY_MGMT_WHOLESALER_GROUPS,
      });
    });

  @Effect() getWholesalerGroupsEffects$ = this.actions$
    .ofType(Actions.REQUEST_GET_BKRY_MGMT_WHOLESALER_GROUPS)
    .withLatestFrom(
      this.store
        .pluck('bakeryMgmtUiState', 'wholesalerGroupState', 'master', 'query_params')
        .distinctUntilChanged(),
      (action: UnsafeAction, queryParams) => ({action, queryParams})
    )
    .switchMap(({action, queryParams}) => {
      const prunedQueryParams = shallowPruneEmpty(queryParams);
      if (action.payload != null) {
        queryParams = action.payload;
      }

      return addResultEntityRequestHandler(
        this.tokenService.get(
          `/api/wholesaler_groups`,
          {
            // Include payload as query params. If payload is empty, no query
            // params are added.
            params: prunedQueryParams,
          },
        ),
        [wholesalerGroupSchema],
        Actions.REQUEST_GET_BKRY_MGMT_WHOLESALER_GROUPS,
      );
    })
    .share();

  @Effect() getWholesalerGroupEffects$ = this.actions$
    .ofType(Actions.REQUEST_GET_BKRY_MGMT_WHOLESALER_GROUP)
    .switchMap((action: UnsafeAction) => {
      return addResultEntityRequestHandler(
        this.tokenService.get(
          `/api/wholesaler_groups/${action.payload}`,
        ),
        wholesalerGroupSchema,
        Actions.REQUEST_GET_BKRY_MGMT_WHOLESALER_GROUP,
      );
    })
    .share();

  @Effect() createWholesalerGroupEffects$ = this.actions$
    .ofType(Actions.REQUEST_CREATE_BKRY_WHOLESALER_GROUP)
    .switchMap((action: UnsafeAction) => {
      return Rx.Observable
        .concat(
          addResultEntityRequestHandler(
            this.tokenService.post(
              `/api/wholesaler_groups`,
              JSON.stringify(action.payload)
            ),
            wholesalerGroupSchema,
            Actions.REQUEST_CREATE_BKRY_WHOLESALER_GROUP,
          ),
          Rx.Observable.of({type: Actions.REQUEST_GET_BKRY_MGMT_WHOLESALER_GROUPS})
        )
    })
    .share();

  @Effect() updateWholesalerGroupEffects$ = this.actions$
    .ofType(Actions.REQUEST_UPDATE_BKRY_MGMT_WHOLESALER_GROUP)
    .switchMap((action: UnsafeAction) => {
      return addResultEntityRequestHandler(
          this.tokenService.put(
            `/api/wholesaler_groups/${action.payload.id}`,
            JSON.stringify(action.payload)
          ),
          wholesalerGroupSchema,
          Actions.REQUEST_UPDATE_BKRY_MGMT_WHOLESALER_GROUP,
        )
    })
    .share();

  @Effect() destroyWholesalerGroupEffects$ = this.actions$
    .ofType(Actions.REQUEST_DESTROY_BKRY_MGMT_WHOLESALER_GROUP)
    .switchMap((action: UnsafeAction) => {
      return addResultEntityRequestHandler(
          this.tokenService.delete(
            `/api/wholesaler_groups/${action.payload}`,
          ),
          wholesalerGroupSchema,
          Actions.REQUEST_DESTROY_BKRY_MGMT_WHOLESALER_GROUP,
        )
    })
    .share();

  @Effect() getWholesalerGroupOrgsEffects$ = this.actions$
    .ofType(Actions.REQUEST_GET_BKRY_MGMT_WHOLESALER_GROUP_ORGS)
    .switchMap((action: UnsafeAction) => {
      return addResultEntityRequestHandler(
        this.tokenService.get(
          `/api/wholesalers?archived=false`,
        ),
        [wholesalerOrgSchema],
        Actions.REQUEST_GET_BKRY_MGMT_WHOLESALER_GROUP_ORGS,
      );
    })
    .share();

  @Effect() getWholesalerGroupProductsEffects$ = this.actions$
    .ofType(Actions.REQUEST_GET_BKRY_MGMT_WHOLESALER_GROUP_PRODUCTS)
    .switchMap((action: UnsafeAction) => {
      return this.store.select(selectors.getBakeryMgmtCurrentBakeryId)
        .filter(val => !!val)
        .map((bakeryId) => ({action, bakeryId}));
    })
    .switchMap((bakeryId) => {
      return addResultEntityRequestHandler(
        this.tokenService.get(
          `/api/bakeries/${bakeryId}/bakery_products?archived=false`,
        ),
        [bakeryProductSchema],
        Actions.REQUEST_GET_BKRY_MGMT_WHOLESALER_GROUP_PRODUCTS,
      );
    })
    .share();
}
