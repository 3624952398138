import { BakeryProductTemplate } from '../bakery-product-template';
import { BakeryProductProductTemplate } from '../bakery-product-product-template';
import { BakeryProduct } from '../bakery-product';
import { EntitiesState } from '../entities-state';
import { denormalize as denormalizr } from 'normalizr';
import { bakeryProductTemplateSchema } from 'app/store/schema/default-schemata';

export function denormalize(
  bakeryProductTemplate: BakeryProductTemplate,
  entitiesState: EntitiesState
): BakeryProductTemplate {
  const bakery_product_template = denormalizr(bakeryProductTemplate, bakeryProductTemplateSchema, entitiesState)

  return bakery_product_template
}
