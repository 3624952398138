import { Component, OnInit } from '@angular/core';

import { BakeryMgmtEmployeesEffects } from 'app/store/effects/bakery-mgmt-employees.effects';

@Component({
  selector: 'bakery-mgmt-employees-master',
  template: `
    <bakery-mgmt-page-header title="Manage Employees"></bakery-mgmt-page-header>
    <bakery-mgmt-employees-list></bakery-mgmt-employees-list>
  `
})
export class BakeryMgmtEmployeesMasterComponent implements OnInit {
  constructor(
    private bakeryMgmtEmployeesEffects: BakeryMgmtEmployeesEffects,
  ) { }

  ngOnInit() {
    this.bakeryMgmtEmployeesEffects.requestGetEmployees();
  }
}
