import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import { getEntities } from './entities';
import { AppState } from 'app/store/app-state';
import {
  wholesalerGroupSchema,
  wholesalerOrgSchema,
  bakeryProductSchema,
  bakeryProductProductTemplateSchema
} from 'app/store/schema/default-schemata';
import { BakeryProductProductTemplate } from 'app/store/bakery-product-product-template';

/*
  Master state
*/
export const getMasterState = (state: AppState) => state.bakeryMgmtUiState.wholesalerGroupState.master;
export const getMasterResult = (state: AppState) => state.bakeryMgmtUiState.wholesalerGroupState.master.result;

export const getMasterResultCount = (state: AppState) => {
  const result = state.bakeryMgmtUiState.wholesalerGroupState.master.result as any[];
  if (result == null || result.length == null) { return 0; }

  return result.length;
}
export const getMasterIsLoading = (state: AppState) => state.bakeryMgmtUiState.wholesalerGroupState.master.isLoading;
export const getMasterCreateIsLoading = (state: AppState) => state.bakeryMgmtUiState.wholesalerGroupState.master.create.isLoading;

export const getMasterResults = createSelector(
  getEntities,
  getMasterResult,
  (entities, resultIds: number[]) => {
    // Apparently this returns null instead of an empty array when there are no
    // results to denormalize.
    const {wholesaler_groups: results} = denormalize(
      {wholesaler_groups: resultIds},
      {wholesaler_groups: [wholesalerGroupSchema]},
      entities
    );

    return results;
  }
);

/*
  Detail state
*/
export const getDetailResult = (state: AppState) => state.bakeryMgmtUiState.wholesalerGroupState.detail.result;
export const getDetailIsLoading = (state: AppState) => state.bakeryMgmtUiState.wholesalerGroupState.detail.isLoading;

export const getDetailUpdateIsLoading = (state: AppState) => state.bakeryMgmtUiState.wholesalerGroupState.detail.update.isLoading;

export const getDetailDestroyIsLoading = (state: AppState) => state.bakeryMgmtUiState.wholesalerGroupState.detail.destroy.isLoading;

export const getDetailOrgsResult = (state: AppState) => state.bakeryMgmtUiState.wholesalerGroupState.detail.orgs.result;
export const getDetailOrgsIsLoading = (state: AppState) => state.bakeryMgmtUiState.wholesalerGroupState.detail.orgs.isLoading;

export const getDetailProductsResult = (state: AppState) => state.bakeryMgmtUiState.wholesalerGroupState.detail.products.result;
export const getDetailProductsIsLoading = (state: AppState) => state.bakeryMgmtUiState.wholesalerGroupState.detail.products.isLoading;

export const getDetailResultEntity = createSelector(
  getEntities,
  getDetailResult,
  (entities, resultId: number) => {
    const {wholesaler_groups: result} = denormalize(
      {wholesaler_groups: resultId},
      {wholesaler_groups: wholesalerGroupSchema},
      entities
    );

    return result;
  }
);

export const getDetailOrgsEntities = createSelector(
  getEntities,
  getDetailOrgsResult,
  (entities, resultIds: number[]) => {
    const {wholesaler_orgs: result} = denormalize(
      {wholesaler_orgs: resultIds},
      {wholesaler_orgs: [wholesalerOrgSchema]},
      entities
    );

    return result;
  }
);

export const getDetailProductsEntities = createSelector(
  getEntities,
  getDetailProductsResult,
  (entities, resultIds: number[]) => {
    const {bakery_products: result} = denormalize(
      {bakery_products: resultIds},
      {bakery_products: [bakeryProductSchema]},
      entities
    );

    return result;
  }
);

function entityIds(entitiesMap: {[key: string]: any}): string[] {
  if (entitiesMap == null) return [];

  return Object.keys(entitiesMap);
}

export const getDetailAllBakeryProductProductTemplates = createSelector(
  getEntities,
  (entities) => {
    const templateIds = entityIds(entities['bakery_product_product_templates']);

    const {bakery_product_product_templates: denormedArray} = denormalize(
      {bakery_product_product_templates: templateIds},
      {bakery_product_product_templates: [bakeryProductProductTemplateSchema]},
      entities
    );

    const normalizedMap = (denormedArray as BakeryProductProductTemplate[])
      .reduce((map, bppt) => {
        map[bppt.id] = bppt;

        return map;
      }, {});

    return normalizedMap;
  }
);
