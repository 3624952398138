import { EntitiesState } from 'app/store/entities-state';

export function entitiesArray(entitiesName: string, entitiesState: EntitiesState): any[] {
  return Object.keys(entitiesState[entitiesName])
    .map(key => entitiesState[entitiesName][key]);
}

export function shallowClone(entity: any): any {
  return {...entity};
}
