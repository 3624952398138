import { Component, OnInit, OnDestroy } from '@angular/core';

import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import { Actions as NgRxActions } from '@ngrx/effects';
import { UnsafeAction } from 'app/store/effects/unsafe-action';
import {
  BakeryMgmtEmployeesEffects,
  UiState,
} from 'app/store/effects/bakery-mgmt-employees.effects';
import { Actions } from 'app/store/actions';
import { UsersEffects } from 'app/store/effects/users.effects';
import { AlertService } from 'app/shared/components/alerts/alert.service';

@Component({
  selector: 'bakery-mgmt-employees-list',
  template: require('./list.component.html'),
})
export class BakeryMgmtEmployeesListComponent implements OnInit, OnDestroy {
  filters$ = new Subject<boolean>();

  private alertsSub: Subscription;

  constructor(
    private bakeryMgmtEmployeesEffects: BakeryMgmtEmployeesEffects,
    private usersEffects: UsersEffects,
    private alertService: AlertService,
    private actions$: NgRxActions,
  ) { }

  ngOnInit() {
    this.filters$.forEach(filterValue => { this.bakeryMgmtEmployeesEffects.changeArchiveFilter(filterValue)
    });

    this.alertsSub = this.actions$
      .subscribe((action: UnsafeAction) => {
        let messageType = null,
            messageContent = null;

        switch (action.type) {
          case Actions.REQUEST_UPDATE_ARCHIVE_STATUS_SUCCESS:
            messageType = 'success';
            messageContent = `Employee archive status has been updated.`;
            break;

          case Actions.REQUEST_UPDATE_ARCHIVE_STATUS_ERROR:
            messageType = 'warning';
            messageContent = `Employee archive status update failed.`;
            break;

          case Actions.REQUEST_USER_RESEND_INVITE_SUCCESS:
            messageType = 'success';
            messageContent = `Employee has been sent another invite.`;
            break;

          case Actions.REQUEST_USER_RESEND_INVITE_ERROR:
            messageType = 'warning';
            messageContent = `Employee invite resend request failed.`;
            break;

          case Actions.REQUEST_USER_DESTROY_SUCCESS:
            messageType = 'success';
            messageContent = `Employee has been deleted.`;
            break;

          case Actions.REQUEST_USER_DESTROY_ERROR:
            messageType = 'warning';
            messageContent = `Employee delete request failed.`;
            break;
        }

        if (messageType != null && messageContent != null) {
          this.alertService[messageType](messageContent);
        }
      });
  }

  ngOnDestroy() {
    this.alertsSub.unsubscribe();
  }

  get uiStates$() {
    return this.bakeryMgmtEmployeesEffects.uiStates$;
  }
}
