import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'chipName'})
export class ChipNamePipe implements PipeTransform {
  public transform(name: string) {
    let result = '??';

    if (name == null) { name = '??' }

    const nameSections = name.trim().split(' ');

    if (nameSections.length > 1) {
      result = nameSections[0].charAt(0) + nameSections[1].charAt(0);
    } else {
      result = name.substring(0, 2);
    }

    result = result.toUpperCase();

    return result;
  }
}
