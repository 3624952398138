// FIXME: this is not really an index file. It really should be more
// focused on actually importing definitions for re-export, rather than
// containing the original definitions as it currently does.

import { createSelector } from 'reselect';
import { denormalize, schema } from 'normalizr';
import { Observable } from 'rxjs/Observable';
import { Actions as NgRxActions } from '@ngrx/effects';

import { AppState } from 'app/store/app-state';
import { EntitiesState } from 'app/store/entities-state';
import { User } from 'app/store/user';
import { Role } from 'app/store/role';
import { Bakery } from 'app/store/bakery';
import { BakeryLocation } from 'app/store/bakery-location';
import { BakeryCart } from 'app/store/bakery-cart';
import * as BakeryOrder from 'app/store/bakery-order';
import { CartState } from 'app/store/reducers/bakery-cust-ui-state-reducer';
import { BakeryCustomerUiState } from 'app/store/reducers/bakery-cust-ui-state-reducer';
import * as orderHistory from 'app/store/reducers/bakery-cust-ui-state/order-history-state.reducer';
import { CheckoutBakeryOrderForm } from 'app/bakery-mgmt/new-order/checkout/checkout-bakery-order-form';
import { CheckoutBakeryOrderFormCust } from 'app/bakery-cust/checkout/checkout-bakery-order-form';
import {
  bakeryOrderSchema,
  storePaymentConfigDeepSchema,
  bakerySchema
} from 'app/store/schema/default-schemata';
import * as storePayments from 'app/store/reducers/bakery-mgmt-ui-state/store-payments-state.reducer';
import { PaymentProviderConfig } from 'app/store/payment-provider-config';
import { PaymentCard } from 'app/store/payment-card';
import { SimplifiedResourceRequestState } from 'app/store/reducers/request-states';
import { BakeryProductProductTemplate } from 'app/store/bakery-product-product-template';
import { RequestSuccessAction } from 'app/store/actions/request.actions';
import { Constants } from 'app/constants';
import * as newOrder from 'app/store/reducers/new-order-reducer';
import * as reportingTaxes from 'app/store/reducers/reporting-taxes-reducer';
import * as reportingItemSummary from 'app/store/reducers/reporting-item-summary-reducer';
import * as reportingDecoration from 'app/store/reducers/reporting-decoration-reducer';
import * as bakeryLocations from 'app/store/reducers/bakery-locations-reducer';
import * as bakeryProducts from 'app/store/reducers/bakery-products-reducer';
import * as bakeryEmployeeOrderState from 'app/store/reducers/bakery-mgmt-ui-state/bakery-employee-order-state.reducer';
import * as bakeryCustOrderState from 'app/store/reducers/bakery-cust-ui-state/bakery-order-state.reducer';

import {
  getEntities,
  getBakeryEntities,
  getBakeryLocationEntities,
  entityMapToArray,
  getPaymentProviderConfigEntities,
} from './entities';

import * as bkryMgmtWholesaler from './bkry-mgmt-wholesaler';
import * as bkryMgmtWholesalerGroup from './bkry-mgmt-wholesaler-group';
import * as Hour from 'app/hour';

export { bkryMgmtWholesaler };
export { bkryMgmtWholesalerGroup };

const selectRoles = (state: AppState): { [key: number]: Role } => state.entitiesState.roles;

export const getCurrentUser = (appState: AppState): User => {
  if (!appState.authState.isLoggedIn) { return null; }

  return appState.entitiesState.users[
    appState.authState.currentUser.id
  ];
};

const toRoleIds = (user: User): number[] => {
  const toId = (role: number | Role): number => typeof role === 'number' ? role : role.id;
  return (user.roles as (Role | number)[]).map(toId);
};

const selectCurrentUserRoles = createSelector(
  getCurrentUser,
  selectRoles,
  (currentUser: User | null, roles: { [key: number]: Role }) =>
    !!currentUser ? toRoleIds(currentUser).map(id => roles[id]) : []
);

const intersect = (xs, ys) => xs.filter(x => ys.includes(x));
const selectIsBakery = createSelector(
  selectCurrentUserRoles,
  (roles: Role[]) =>
    intersect(['bakery_owner', 'bakery_manager', 'bakery_employee'], roles.map(x => x.name)).length > 0
);

export const getCurrentUserWholesalerEnabled = createSelector(
  getCurrentUser,
  currentUser => {
    if (currentUser == null || currentUser.wholesaler_org == null) return false;

    return !currentUser.wholesaler_org.archived;
  }
)

export const getBakeryCustUiState = (state: AppState): BakeryCustomerUiState => state.bakeryCustUiState;

export const getCurrentCustBakery = createSelector(
  getBakeryCustUiState,
  getEntities,
  (bakeryCustUiState, entitiesState) => {
    if (bakeryCustUiState.bakeryId == null) return null;

    return denormalize(
      entitiesState.bakeries[bakeryCustUiState.bakeryId],
      bakerySchema,
      entitiesState
    );
  }
);

export const getBakeryMgmtUiState = (state: AppState) => state.bakeryMgmtUiState;
export const getBakeryMgmtCurrentBakeryId = (state: AppState) => state.bakeryMgmtUiState.bakeryId;

export const getCurrentBkryMgmtBakery = createSelector(
  getBakeryMgmtUiState,
  getBakeryEntities,
  getBakeryLocationEntities,
  (bakeryMgmtUiState, bakeryEntities, bakeryLocationEntities) => {
    if (bakeryMgmtUiState.bakeryId == null) return null;

    const bakery = {...bakeryEntities[bakeryMgmtUiState.bakeryId]};

    bakery.bakery_locations = (<number[]>bakery.bakery_locations)
      .map(id => bakeryLocationEntities[id]);

    return bakery;
  }
);
export const getEmployeeCartState = (state: AppState): CartState => state.bakeryMgmtUiState.cartState;
export const getCartState = (state: AppState): CartState => state.bakeryCustUiState.cartState;
export const getWholesaleCartState = (state: AppState): CartState => state.bakeryWholesaleUiState.cartState;

const getCart = (cartState, entitiesState) => {
  if (
    cartState.state == 'loading' ||
    cartState.detailId == null ||
    entitiesState.bakery_carts[cartState.detailId] == null
  ) { return null; }

  const bakeryCart = {...entitiesState.bakery_carts[cartState.detailId]};

  bakeryCart.order_items = (<number[]>bakeryCart.order_items)
    .map(id => entitiesState.order_items[id])
    .map(orderItem => {
      const bakeryProductProductTemplate = entitiesState.bakery_product_product_templates[<number>orderItem.bakery_product_product_template];
      const bakeryProductTemplate = entitiesState.bakery_product_templates[<number>orderItem.bakery_product_template];
      const bakeryProduct = entitiesState.bakery_products[orderItem.bakery_product_id];
      return {
        ...orderItem,
        bakery_product_template: bakeryProductTemplate,
        bakery_product_product_template: bakeryProductProductTemplate,
        bakery_product: bakeryProduct,
      }
    });

  return bakeryCart;
}
export const getCustomerCart = createSelector(
  getCartState,
  getEntities,
  getCart
);
export const getEmployeeCart = createSelector(
  getEmployeeCartState,
  getEntities,
  getCart
);

export const getWholesaleCart = createSelector(
  getWholesaleCartState,
  getEntities,
  getCart
);

export const getEmployeeOrderState = (state: AppState): bakeryEmployeeOrderState.State => state.bakeryMgmtUiState.bakeryOrderState;
export const getEmployeeOrder = (state: AppState): CheckoutBakeryOrderForm => state.bakeryMgmtUiState.bakeryOrderState.bakeryOrder;

export const getOrderState = (state: AppState): bakeryCustOrderState.State => state.bakeryCustUiState.bakeryOrderState;
export const getCustomerOrder = (state: AppState): CheckoutBakeryOrderFormCust => state.bakeryCustUiState.bakeryOrderState.bakeryOrder;

export const selectOrder = createSelector(
  selectIsBakery,
  getEmployeeOrder,
  getCustomerOrder,
  (isBakery: boolean, employeeOrder: CheckoutBakeryOrderForm, customerOrder: CheckoutBakeryOrderFormCust): CheckoutBakeryOrderForm | CheckoutBakeryOrderFormCust =>
    isBakery ? employeeOrder : customerOrder
);

export const getWholesaleOrderState = (state: AppState): bakeryCustOrderState.State => state.bakeryCustUiState.bakeryOrderState;
export const getWholesaleOrder = (state: AppState): CheckoutBakeryOrderFormCust => state.bakeryCustUiState.bakeryOrderState.bakeryOrder;
export const getCurrentUserPaymentCards = createSelector(
  getCurrentUser,
  (user): PaymentCard[] => {
    if (user == null) {
      return [];
    }

    return user.payment_cards;
  }
);
export const getCustomerOrderTaxEstimateState = (state: AppState) => {
  return state.bakeryCustUiState.bakeryOrderState.taxes;
};
export const getEmployeeOrderTaxEstimateState = (state: AppState) => {
  return state.bakeryMgmtUiState.bakeryOrderState.taxes;
};

export const isCheckoutCreditCardFormVisible = createSelector(
  getCustomerOrder,
  getCurrentUserPaymentCards,
  (checkoutBakeryOrderForm, paymentCards): boolean => {
    // Always show card form if there are no payment cards.
    if (paymentCards.length === 0) return true;

    if (checkoutBakeryOrderForm.payment_card_selection == null) return false;

    return isNaN(parseInt(checkoutBakeryOrderForm.payment_card_selection));
  }
);

export const getBakeryOrder = (entitiesState: EntitiesState, bakeryOrderId: number|string): BakeryOrder.BakeryOrder => {
  return <BakeryOrder.BakeryOrder>denormalize(
    entitiesState.bakery_orders[bakeryOrderId],
    bakeryOrderSchema,
    entitiesState
  );
}

export const getCustOrderHistoryMasterState = (state: AppState): orderHistory.OrderHistoryMasterState => state.bakeryCustUiState.orderHistoryState.master;
export const getWiredCustOrderHistoryMasterState = createSelector(
  getCustOrderHistoryMasterState,
  getEntities,
  (masterState, entities) => {
    const {bakery_orders: bakeryOrders} = denormalize(
      {bakery_orders: masterState.result},
      {bakery_orders: [bakeryOrderSchema]},
      entities
    );

    bakeryOrders.forEach(bkryOrder => {
      bkryOrder.order_items_total_quantity = bkryOrder.order_items.reduce((sum, curr) => {
        return sum + curr.quantity;
      }, 0)
    });

    return {
      ...masterState,
      isLoading: masterState.state === 'loading',
      wiredResult: bakeryOrders,
    };
  }
);

export const getCustOrderHistoryDetailState = (state: AppState): orderHistory.OrderHistoryDetailState => state.bakeryCustUiState.orderHistoryState.detail;
export const getWiredCustOrderHistoryDetailState = createSelector(
  getCustOrderHistoryDetailState,
  getEntities,
  (detailState, entities) => {
    const {bakery_orders: bakeryOrder} = denormalize(
      {bakery_orders: detailState.result},
      {bakery_orders: bakeryOrderSchema},
      entities
    );

    return {
      ...detailState,
      isLoading: detailState.state === 'loading',
      wiredResult: bakeryOrder,
    };
  }
);

export const getBakeryMgmtStorePaymentsState = (state: AppState): storePayments.State => state.bakeryMgmtUiState.storePaymentsState;
export const getWiredBakeryMgmtStorePaymentsState = createSelector(
  getBakeryMgmtStorePaymentsState,
  getEntities,
  (storePaymentsState, entities) => {
    const {store_payment_configs: storePaymentConfig} = denormalize(
      {store_payment_configs: storePaymentsState.result},
      {store_payment_configs: storePaymentConfigDeepSchema},
      entities
    );

    return {
      ...storePaymentsState,
      isLoading: storePaymentsState.state === 'loading' || storePaymentsState.state == null,
      wiredResult: storePaymentConfig,
    };
  }
);

export const getPaymentProviderConfigByTypeKey = (typeKey: string) => {
  return createSelector(
    getPaymentProviderConfigEntities,
    (paymentProviderConfigEntities) => {
      if (paymentProviderConfigEntities == null) return null;

      const paymentProviderConfigs = entityMapToArray(paymentProviderConfigEntities);

      const matchingPaymentProviderConfig = paymentProviderConfigs.find(providerConfig => providerConfig.type_key === typeKey);

      return matchingPaymentProviderConfig;
    }
  )
}
export const getCustBakeryPermitEcomm = createSelector(
  getCurrentCustBakery,
  (currentCustBakery) => {
    if (currentCustBakery == null) return false;

    return currentCustBakery.store_payment_config.permit_ecomm;
  }
);

// Note: this is not in use, but may prove useful in the future.
export const getCustBakeryPermitWholesaler = createSelector(
  getCurrentCustBakery,
  (currentCustBakery) => {
    if (
      currentCustBakery == null ||
      currentCustBakery.bakery_subscription_status == null ||
      currentCustBakery.bakery_subscription_status.feature_modules == null
    ) return false;

    const enabledFeatureModules = currentCustBakery.bakery_subscription_status.feature_modules;

    return enabledFeatureModules.some(featureModule => featureModule.type_name === 'online_wholesale_store');
  }
);

export const getCustBakeryPermitWholesalerEcomm = createSelector(
  getCurrentCustBakery,
  (currentCustBakery) => {
    if (
      currentCustBakery == null ||
      currentCustBakery.bakery_subscription_status == null ||
      currentCustBakery.bakery_subscription_status.feature_modules == null
    ) return false;

    const wholesalerEcommPlan = (currentCustBakery.bakery_subscription_status as any)
      .primary_subscription.child_bakery_subscriptions
      .find(sub => sub.subscription_plan_type.type_name === 'wholesaler_ecomm');

    return wholesalerEcommPlan && wholesalerEcommPlan.quantity > 0
  }
);

export const getLoadingState = (rezReqState: SimplifiedResourceRequestState<any>) => rezReqState.isLoading;
export const getLoadingFromState = (stateSelector) => {
  return (state: AppState) => stateSelector(state).isLoading;
}

export const getBkryMgmtOrderMgmtDetailState = (state: AppState) => state.bakeryMgmtUiState.orderMgmtState.detail;
export const getBkryMgmtOrderMgmtDetailIsLoading = (state: AppState) => state.bakeryMgmtUiState.orderMgmtState.detail.isLoading;
export const getBkryMgmtOrderMgmtStateDetailResult = (state: AppState) => state.bakeryMgmtUiState.orderMgmtState.detail.result;
export const getBkryMgmtOrderMgmtDetailResult = createSelector(
  getEntities,
  getBkryMgmtOrderMgmtStateDetailResult,
  (entities, resultId: number) => {
    let {bakery_orders: result} = denormalize(
      {bakery_orders: resultId},
      {bakery_orders: bakeryOrderSchema},
      entities
    );

    if (result != null) {
      result = {
        ...result,
        isActive: BakeryOrder.isActive(result),
        isOnHold: BakeryOrder.isOnHold(result),
        isCanceled: BakeryOrder.isCanceled(result),
        isCompleted: BakeryOrder.isCompleted(result),
        isOpen: BakeryOrder.isOpen(result),
      };
    }

    return result;
  }
);

export const getEditFormData = createSelector(
  getBkryMgmtOrderMgmtDetailResult,
  bakeryOrder => {
    if (!bakeryOrder) { return null; }

    return {
      fulfillment_date: bakeryOrder.fulfillment_date,
      fulfillment_at: bakeryOrder.fulfillment_at,
      fulfillment_type: bakeryOrder.fulfillment_type,
      schedule_interval: bakeryOrder.order_schedule && bakeryOrder.order_schedule.schedule_interval,
      order_type: bakeryOrder.order_schedule ? 'recurring' : 'once',
      address: bakeryOrder.fulfillment_location && {
        street: bakeryOrder.fulfillment_location.street,
        unit: bakeryOrder.fulfillment_location.street2,
        city: bakeryOrder.fulfillment_location.city,
        state: bakeryOrder.fulfillment_location.state,
        zip: bakeryOrder.fulfillment_location.zip,
      },
      fulfillment_location_id: bakeryOrder.fulfillment_location_id,
      fulfillment_location_type: bakeryOrder.fulfillment_location_type,
    }
  }
);

/*
  Generic request selectors
*/

export const getRequestState = (opKey: string) => (state: AppState) => state.requestState[opKey];

export const getRequestIsLoading = (opKey: string) => (state: AppState) => {
  const opKeyState = state.requestState[opKey];

  if (opKeyState == null) return false;

  return opKeyState.isLoading;
}

export const getRequestResult = (opKey: string, schema: schema.Entity|schema.Entity[], nullSafeValue: any = null) => (state: AppState) => {
  const {entitiesState, requestState} = state;
  const result = requestState[opKey] != null
    ? requestState[opKey].result
    : null;

  const denormalizedResult = denormalize(
    result,
    schema,
    entitiesState
  );

  if (denormalizedResult == null) {
    return nullSafeValue;
  }

  return denormalizedResult;
};

export const getRequestResultRaw = (opKey: string) => (state: AppState) => state.requestState[opKey].result;

export const onRequestSuccessBuilder = (actions$: NgRxActions, opKey: string): Observable<any> => {
  return actions$
    .ofType(RequestSuccessAction.type)
    .filter((action: RequestSuccessAction) => action.payload.opKey === opKey);
};

const findHoursFor = (day: number, location: BakeryLocation) =>
  Object.values(location.hours).find(hours => hours.day === day);

const isOpenOn = (date: Date, location: BakeryLocation): boolean =>
  findHoursFor(date.getDay() || 7, location).open;

const does = (fulfillments: string[]) => (location: BakeryLocation): boolean =>
  fulfillments.includes(location.fulfillment_type);

const isOpenOnAndDoesPickup = (date: Date) => (location: BakeryLocation): boolean =>
  isOpenOn(date, location) && does([Constants.PICKUP_ONLY, Constants.DELIVERY_AND_PICKUP])(location);

export const selectAvailablePickupLocations = createSelector(
  selectOrder,
  selectIsBakery,
  (order, isBakery) => {
    if (!order) { return []; }
    const form = order.form_fulfillment_data;
    if (form.fulfillment_location_type !== 'BakeryLocation') { return []; }
    if (isBakery) { return form.bakeryLocations; }
    return form.bakeryLocations.filter(isOpenOnAndDoesPickup(form.fulfillment_date));
  }
);

export const selectAvailablePickupLocations2 = createSelector(
  getCurrentBkryMgmtBakery,
  (currentBakery) => {
    if (!currentBakery) { return []; }
    return currentBakery.bakery_locations;
  }
);

export const selectCountryCode = createSelector(
  getCurrentBkryMgmtBakery,
  (currentBakery) => {
    if (!currentBakery) { return null; }
    return currentBakery.country_code;
  }
);

const selectLocationsByFulfillments = (fulfillments: string[]) => (locations: BakeryLocation[]): BakeryLocation[] =>
  locations.filter(({ fulfillment_type }) => fulfillments.includes(fulfillment_type));

const zipWithOr = (xss: boolean[][]): boolean[] =>
  xss.reduce((acc, xs) => acc.map((y, i) => y || xs[i]), Array(7).fill(false));

const toSingleOpenDays = (location: BakeryLocation): boolean[] =>
  Object.values(location.hours).sort((a, b) => a.day - b.day).map(x => x.open);

const toOpenDays = (locations: BakeryLocation[]): boolean[] =>
  startFromSunday(zipWithOr(locations.map(toSingleOpenDays)));

const startFromSunday = (xs: boolean[]): boolean[] =>
  xs.slice(6, 7).concat(xs.slice(0, 6));

const selectDaysAtLeastOneLocationDoes = fulfillments => createSelector(
  selectOrder,
  (order) => {
    if (!order) { return Array(7).fill(false); }
    const form = order.form_fulfillment_data;
    return toOpenDays(form.bakeryLocations.filter(does(fulfillments)));
  }
);

export const selectDaysAtLeastOneLocationDoesFulfill =
  selectDaysAtLeastOneLocationDoes([Constants.DELIVERY_ONLY, Constants.PICKUP_ONLY, Constants.DELIVERY_AND_PICKUP]);

export const selectDaysAtLeastOneLocationDoesDelivery =
  selectDaysAtLeastOneLocationDoes([Constants.DELIVERY_ONLY, Constants.DELIVERY_AND_PICKUP]);

export const selectDaysAtLeastOneLocationDoesPickup =
  selectDaysAtLeastOneLocationDoes([Constants.PICKUP_ONLY, Constants.DELIVERY_AND_PICKUP]);

export const selectRefundRequest = createSelector(getBkryMgmtOrderMgmtDetailState, state => state.refundStatus);

export const selectBakeryOrders = state => state.bakeryOrders;

const selectNewOrder = state => state.newOrder;

export const selectNewOrderCustomers = createSelector(selectNewOrder, newOrder.selectCustomers);

export const selectReportingTaxes = state => state.reportingTaxes;

export const selectProductionChecklist = state => state.reportingProductionChecklist;

export const selectCustomers = state => state.customers;

export const selectReportingItemSummary = state => state.reportingItemSummary;

export const selectReportingDecoration = state => state.reportingDecoration;

export const selectBakeryLocations = state => state.bakeryLocations;

export const getBakeryLocations = state => getBakeryLocationEntities(state);

const selectEditOrder = state => state.bakeryProducts;

export const selectBakeryProducts = createSelector(selectEditOrder, bakeryProducts.selectBakeryProducts);

export const selectReportingAttributeSummary = state => state.reportingAttributeSummary;

export const selectAvailableFulfillmentAts = createSelector(
  selectIsBakery,
  selectOrder,
  (isBakery: boolean, order: CheckoutBakeryOrderForm | CheckoutBakeryOrderFormCust) => {
    if (!order) { return []; }
    if (isBakery) { return Hour.ALL_HOURS; }
    const selectedLocationId = order.form_fulfillment_data.fulfillment_location_id;
    if (!selectedLocationId) { return Hour.ALL_HOURS; }
    if (order.form_fulfillment_data.fulfillment_location_type === 'Address') { return Hour.ALL_HOURS; }
    const location = order.form_fulfillment_data.bakeryLocations.find(x => x.id === selectedLocationId);
    const hours = findHoursFor(order.form_fulfillment_data.fulfillment_date.getDay() || 7, location);
    return Hour.toFullHoursExceptLast(hours.open_at, hours.close_at);
  }
);
