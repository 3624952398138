import { Component, OnInit, Input } from '@angular/core';

import { BakeryLocation } from 'app/store/bakery-location';

interface DayHour {
  day: number;
  open: boolean;
  open_at: number;
  close_at: number;
  day_name: string
}

@Component({
  selector: 'bakery-product-master-location-details',
  template: require('./location-details.component.html'),
})
export class BakeryCustProductMasterLocationDetailsComponent implements OnInit {
  dayHours: DayHour[];

  private bkryLoc: BakeryLocation;

  constructor(
  ) {
  }

  ngOnInit() {
  }

  @Input('bakeryLocation')
  set bakeryLocation(newLoc: BakeryLocation) {
    this.bkryLoc = newLoc;

    this.dayHours = Object.keys(newLoc.hours)
      .map(day => newLoc.hours[day])
      .sort((dayA, dayB) => {
        return dayA.day - dayB.day;
      });
  }

  get bakeryLocation(): BakeryLocation {
    return this.bkryLoc;
  }
}
