import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Angular2TokenService } from 'app/angular2-token/angular2-token.service';

import { passwordMatchValidatorBuilder } from '../shared/forms/password-match-validator';
import { validateNoWhitespaces } from 'app/shared/forms/no-whitespaces-validator';

@Component({
  selector: 'app-account-confirmation',
  template: require('./account-confirmation.component.html'),
  styles: []
})
export class AccountConfirmationComponent implements OnInit {
  token = '';
  form: FormGroup;
  isLoading = false;

  constructor(
    private tokenService: Angular2TokenService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.token = this.route.snapshot.queryParams['token'];

    this.form = new FormGroup({
      'password': new FormControl(null, [Validators.required, Validators.minLength(8), validateNoWhitespaces]),
      'confirmation': new FormControl(null, [Validators.required, Validators.minLength(8), validateNoWhitespaces]),
    }, passwordMatchValidatorBuilder('password', 'confirmation'));
  }

  get disabled(): boolean {
    return !this.form.valid || this.isLoading;
  }

  onSubmit() {
    if (this.isLoading) return;

    this.isLoading = true;

    const password: string = this.form.value.password,
          confirmation: string = this.form.value.confirmation;

    const update$ = this.tokenService.put(
      `/api/auth/confirmation`,
      JSON.stringify({
        password: password,
        token: this.token,
      })
    );

    update$.subscribe(
        res => {
          window.location.href = '/';
        },
        error => {
          this.isLoading = false;

          console.log(error)
        }
    );
  }
}
