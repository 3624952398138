import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Angular2TokenService } from 'app/angular2-token/angular2-token.service';
import { Actions as NgRxActions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';
import { Response } from '@angular/http';

import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { AppState } from 'app/store/app-state';
import { Actions } from 'app/store/actions';
import { bakeryOrderSchema } from 'app/store/schema/default-schemata';
import { addResultEntityRequestHandler } from 'app/store/effects/helpers';
import * as selectors from 'app/store/selectors';
import { shallowPruneEmpty } from 'app/store/effects/params-helper';
import { AlertService } from 'app/shared/components/alerts/alert.service';

@Injectable()
export class BakeryMgmtOrderMgmtEffects {

  constructor(
    private actions$: NgRxActions,
    private tokenService: Angular2TokenService,
    private store: Store<AppState>,
    private alertService: AlertService
  ) { }

  requestOrderDetailsUpdate(detailsPayload) {
    this.store.dispatch({
      type: Actions.REQUEST_UPDATE_BKRY_MGMT_ORDER_MGMT_ORDER_DETAILS,
      payload: detailsPayload,
    });
  }

  requestOrderDetailsUpdate2(detailsPayload) {
    this.store.dispatch({
      type: Actions.REQUEST_UPDATE_BKRY_MGMT_ORDER_MGMT_ORDER_DETAILS2,
      payload: detailsPayload,
    });
  }

  @Effect() changeOrdersFiltersEffects3$ = this.actions$
    .ofType(Actions.CHANGE_BKRY_MGMT_ORDER_MGMT_ORDERS_FILTERS)
    .combineLatest(
      this.store.select(selectors.getBakeryMgmtCurrentBakeryId),
      (action: UnsafeAction, bakeryId) => ({ action, bakeryId })
    )
    .skipWhile(({ bakeryId }) => !bakeryId)
    .switchMap(({ action, bakeryId }) => {
      const params = {
        ...action.payload,
        search_start_date: action.payload.search_start_date.format('YYYY-MM-DD'),
        search_end_date: action.payload.search_end_date.format('YYYY-MM-DD'),
      };

      return this.tokenService.get(`/api/bakeries/${bakeryId}/orders3`, { params })
        .map((response: Response) => response.json())
        .switchMap(response => {
          return Observable.of({ type: Actions.REQUEST_GET_BKRY_MGMT_ORDER_MGMT_ORDERS_SUCCESS2, payload: response });
        })
        .catch(() => {
          return Observable.of({ type: Actions.REQUEST_GET_BKRY_MGMT_ORDER_MGMT_ORDERS_ERROR2 });
        });
    })
    .share();

  @Effect() requestProductionChecklist$ = this.actions$
    .ofType(Actions.REQUEST_PRODUCTION_CHECKLIST)
    .switchMap((action: UnsafeAction) => {
      const params = {
        ...action.payload,
        search_start_date: action.payload.search_start_date.format('YYYY-MM-DD'),
        search_end_date: action.payload.search_end_date.format('YYYY-MM-DD'),
      };

      return this.tokenService.get('/api/bakery/reporting/production_checklist', { params })
        .map((response: Response) => response.json())
        .switchMap(response => {
          return Observable.of({ type: Actions.REQUEST_PRODUCTION_CHECKLIST_SUCCESS, payload: response });
        })
        .catch(() => {
          return Observable.of({ type: Actions.REQUEST_PRODUCTION_CHECKLIST_ERROR });
        });
    })
    .share();

  @Effect() requestPrintableProductionChecklist$ = this.actions$
    .ofType(Actions.REQUEST_PRINTABLE_PRODUCTION_CHECKLIST)
    .switchMap((action: UnsafeAction) => {
      const params = {
        ...action.payload,
        search_start_date: action.payload.search_start_date.format('YYYY-MM-DD'),
        search_end_date: action.payload.search_end_date.format('YYYY-MM-DD'),
      };
      return this.tokenService.getWithoutSpinner('/api/bakery/reporting/printable_production_checklist', { params })
        .map((response: Response) => response.json())
        .switchMap(response => {
          return Observable.of({ type: Actions.REQUEST_PRINTABLE_PRODUCTION_CHECKLIST_SUCCESS, payload: response });
        })
        .catch(() => {
          return Observable.of({ type: Actions.REQUEST_PRINTABLE_PRODUCTION_CHECKLIST_ERROR });
        });
    })
    .share();


  @Effect() getOrdersEffects$ = this.actions$
    .ofType(Actions.REQUEST_GET_BKRY_MGMT_ORDER_MGMT_ORDERS)
    .switchMap((action: UnsafeAction) => {
      return this.store.select(selectors.getBakeryMgmtCurrentBakeryId)
        .filter(val => !!val)
        .map((bakeryId) => ({action, bakeryId}));
    })
    .withLatestFrom(
      this.store
        .pluck('bakeryMgmtUiState', 'orderMgmtState', 'master', 'query_params')
        .distinctUntilChanged(),
      ({action, bakeryId}, queryParams) => ({action, bakeryId, queryParams})
    )
    .switchMap(({action, bakeryId, queryParams}) => {
      const prunedQueryParams = shallowPruneEmpty(queryParams);
      if (action.payload != null) {
        queryParams = action.payload;
      }

      return addResultEntityRequestHandler(
        this.tokenService.get(
          `/api/bakeries/${bakeryId}/orders`,
          {
            // Include payload as query params. If payload is empty, no query
            // params are added.
            params: prunedQueryParams,
          },
        ),
        [bakeryOrderSchema],
        Actions.REQUEST_GET_BKRY_MGMT_ORDER_MGMT_ORDERS,
      );
    })
    .share();

  @Effect() listOrderStatusChangeEffects$ = this.actions$
    .ofType(Actions.BKRY_MGMT_ORDER_MGMT_LIST_ORDER_STATUS_CHANGE)
    .switchMap((action: UnsafeAction) => {
      const requestChangeOrderStatus$ = this.store
        .select(selectors.getBakeryMgmtCurrentBakeryId)
        .take(1)
        .switchMap((bakeryId) => {
          const bakeryOrderId = action.payload.id;
          const newStatus = action.payload.newStatus;

          return addResultEntityRequestHandler(
            this.tokenService.put(
              `/api/bakeries/${bakeryId}/orders/${bakeryOrderId}/${newStatus}`,
              {}
            ),
            bakeryOrderSchema,
            Actions.REQUEST_BKRY_MGMT_ORDER_MGMT_CHANGE_ORDER_STATUS,
          );
        });

      return requestChangeOrderStatus$
    })
    .share();

  @Effect() changeOrderStatusEffects$ = this.actions$
    .ofType(Actions.REQUEST_BKRY_MGMT_ORDER_MGMT_CHANGE_ORDER_STATUS)
    .combineLatest(
      this.store.select(selectors.getBakeryMgmtCurrentBakeryId)
        .filter(val => !!val),
      (action: UnsafeAction, bakeryId) => ({action, bakeryId})
    )
    .switchMap(({action, bakeryId}) => {
      const bakeryOrderId = action.payload.id;
      const newStatus = action.payload.newStatus;

      return addResultEntityRequestHandler(
        this.tokenService.put(
          `/api/bakeries/${bakeryId}/orders/${bakeryOrderId}/${newStatus}`,
          {}
        ),
        bakeryOrderSchema,
        Actions.REQUEST_BKRY_MGMT_ORDER_MGMT_CHANGE_ORDER_STATUS,
      );
    })
    .share();

  @Effect() getOrderEffects$ = this.actions$
    .ofType(Actions.REQUEST_GET_BKRY_MGMT_ORDER_MGMT_ORDER)
    .combineLatest(
      this.store.select(selectors.getBakeryMgmtCurrentBakeryId)
        .filter(val => !!val),
      (action: UnsafeAction, bakeryId) => ({action, bakeryId})
    )
    .switchMap(({action, bakeryId}) => {
      return addResultEntityRequestHandler(
        this.tokenService.get(
          `/api/bakeries/${bakeryId}/orders/${action.payload}`,
        ),
        bakeryOrderSchema,
        Actions.REQUEST_GET_BKRY_MGMT_ORDER_MGMT_ORDER,
      );
    })
    .share();

  @Effect() updateOrderDetailsEffects$ = this.actions$
    .ofType(Actions.REQUEST_UPDATE_BKRY_MGMT_ORDER_MGMT_ORDER_DETAILS)
    .combineLatest(
      this.store.select(selectors.getBakeryMgmtCurrentBakeryId)
        .filter(val => !!val),
      (action: UnsafeAction, bakeryId) => ({action, bakeryId})
    )
    .switchMap(({action, bakeryId}) => {
      return addResultEntityRequestHandler(
        this.tokenService.put(
          `/api/bakeries/${bakeryId}/orders/${action.payload.id}`,
          JSON.stringify(action.payload.data)
        ),
        bakeryOrderSchema,
        Actions.REQUEST_UPDATE_BKRY_MGMT_ORDER_MGMT_ORDER_DETAILS,
      )
    })
    .share();

  @Effect() updateOrderDetailsEffects2$ = this.actions$
    .ofType(Actions.REQUEST_UPDATE_BKRY_MGMT_ORDER_MGMT_ORDER_DETAILS2)
    .combineLatest(
      this.store.select(selectors.getBakeryMgmtCurrentBakeryId)
        .filter(val => !!val),
      (action: UnsafeAction, bakeryId) => ({action, bakeryId})
    )
    .switchMap(({action, bakeryId}) => {
      return addResultEntityRequestHandler(
        this.tokenService.put(
          `/api/bakeries/${bakeryId}/orders/${action.payload.id}/fulfillment_details`,
          JSON.stringify(action.payload.data)
        ),
        bakeryOrderSchema,
        Actions.REQUEST_UPDATE_BKRY_MGMT_ORDER_MGMT_ORDER_DETAILS,
      )
    })
    .share();

  @Effect() updateOrderItemQuantity$ = this.actions$
    .ofType(Actions.REQUEST_UPDATE_ORDER_ITEM)
    .combineLatest(
      this.store.select(selectors.getBakeryMgmtCurrentBakeryId).filter(val => !!val)
    )
    .switchMap(([action, bakeryId]: [UnsafeAction, number]) => {
      return addResultEntityRequestHandler(
        this.tokenService.put(
          `/api/bakeries/${bakeryId}/order_items/${action.payload.orderItemId}`,
          JSON.stringify(action.payload)
        ),
        bakeryOrderSchema,
        Actions.REQUEST_UPDATE_BKRY_MGMT_ORDER_MGMT_ORDER_DETAILS,
      )
    })
    .share();

  @Effect() refundEffects$ = this.actions$
    .ofType(Actions.BAKERY_ORDER_REFUND_REQUESTED)
    .withLatestFrom(
      this.store.select(selectors.getBakeryMgmtCurrentBakeryId),
      this.store.select(selectors.getBkryMgmtOrderMgmtDetailResult),
      (action: UnsafeAction, bakeryId, bakeryOrder) => ({ action, bakeryId, bakeryOrder })
    )
    .switchMap(({ action, bakeryId, bakeryOrder }) => {
      return this.tokenService
        .post(`/api/bakeries/${bakeryId}/orders/${bakeryOrder.id}/refund`, action.payload)
        .map((response: Response) => response.json())
        .switchMap(response => {
          this.alertService[AlertService.SUCCESS_TYPE]('The refund has been noted');
          return Observable.of({ type: Actions.BAKERY_ORDER_REFUND_SUCCEEDED, payload: response });
        })
        .catch(() => {
          this.alertService[AlertService.WARNING_TYPE]('Refund could not be recorded');
          return Observable.of({ type: Actions.BAKERY_ORDER_REFUND_FAILED });
        })
    })
    .share();
}
