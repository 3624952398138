import { Component, OnInit, OnDestroy, Input, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

import { BakeryProduct } from 'app/store/bakery-product';
import { SimpleFormState } from 'app/shared/forms/form-states';
import { DetailUiState } from 'app/bakery-mgmt/product-mgmt/products/detail-ui-state';

@Component({
  selector: 'product-detail-forms-edit-store-presentation',
  template: `
    <form [formGroup]="form">
      <titled-card title="Online Store Presentation">
        <ng-container data-card-body>
          <div class="row">
            <div class="columns-8__s columns-12__l">
              <p>These settings control where a product shows up in the online store.</p>
            </div>
          </div>
          <div class="row">
            <div class="columns-8__s columns-3__m columns-4__l">
              <label class="label--required">Store Type</label>
              <select formControlName="store_type" required aria-required="true">
                <option disabled [ngValue]="null">Select One</option>
                <option value="retail_only">Retail Only</option>
                <option value="wholesale_only">Wholesale Only</option>
                <option value="retail_wholesale">Retail and Wholesale</option>
                <option value="internal_only">Internal Only</option>
              </select>
            </div>
            <div class="columns-8__s columns-3__m columns-4__l">
              <label class="label--required">Featured Product</label>
              <select formControlName="store_featured" required aria-required="true">
                <option disabled [ngValue]="null">Select One</option>
                <option [ngValue]="true">Featured</option>
                <option [ngValue]="false">Not Featured</option>
              </select>
            </div>
            <div class="columns-8__s columns-3__m columns-4__l">
              <label>Sort Order</label>
              <select formControlName="store_sort_priority">
                <option disabled [ngValue]="null">Select One</option>
                <option [ngValue]="1">1 (top)</option>
                <option [ngValue]="2">2</option>
                <option [ngValue]="3">3</option>
                <option [ngValue]="4">4</option>
                <option [ngValue]="5">5</option>
                <option [ngValue]="6">6</option>
                <option [ngValue]="7">7</option>
                <option [ngValue]="8">8</option>
                <option [ngValue]="9">9</option>
                <option [ngValue]="10">10 (bottom)</option>
              </select>
            </div>
          </div>
        </ng-container>
      </titled-card>
    </form>
  `
})
export class ProductDetailFormsEditStorePresentationComponent implements OnInit, OnDestroy {
  @Input('uiState$') uiState$: Observable<DetailUiState>;
  @Input('reset$') reset$: Observable<any>;
  @Output('formState') formState$: Observable<SimpleFormState>;

  form: FormGroup;

  private formValueSub: Subscription;
  private storeTypeSub: Subscription;

  constructor(
  ) {
    this.form = new FormGroup({
      store_type: new FormControl('retail_wholesale', [Validators.required]),
      store_featured: new FormControl(null, [Validators.required]),
      store_sort_priority: new FormControl(null, []),
    });

    this.formState$ = this.form.valueChanges
      .map((formValues): SimpleFormState => {
        const formValuesClone = Object.assign({store_featured: false, store_sort_priority: null}, formValues);

        return {
          isDirty: this.form.dirty,
          isValid: this.form.valid,
          value: formValuesClone,
        };
      })
  }

  ngOnInit() {
    this.formValueSub = Observable
      .merge(
        this.uiState$.filter((uiState: DetailUiState) => !uiState.isLoading),
        this.reset$.switchMap(() => this.uiState$.filter((uiState: DetailUiState) => !uiState.isLoading).take(1))
      )
      .map((uiState: DetailUiState) => uiState.bakeryProduct)
      // .do(bakeryProduct => console.log(`resetting with:`, bakeryProduct))
      .subscribe((bakeryProduct: BakeryProduct) => {
        // FIXME: For some reason, a true form reset requires calling #reset twice...
        this.resetForm(bakeryProduct);
        this.resetForm(bakeryProduct);
      });

    this.storeTypeSub = this.form.get('store_type').valueChanges
      .subscribe((storeTypeValue: any) => {
        if (storeTypeValue === 'internal_only') {
          if (this.form.get('store_featured').value !== false || this.form.get('store_sort_priority').value !== null) {
            this.disableFeaturedSort();

            this.form.patchValue({
              store_featured: false,
              store_sort_priority: null,
            })

            console.log(`[store-presentation] value patched`)
          }
        } else {
          this.enableFeaturedSort()
        }
      });
  }

  ngOnDestroy() {
    this.formValueSub.unsubscribe();
    this.storeTypeSub.unsubscribe();
  }

  private resetForm(bakeryProduct: BakeryProduct) {
    this.form.reset({
      store_type: bakeryProduct.store_type || 'retail_wholesale',
      store_featured: bakeryProduct.store_featured,
      store_sort_priority: bakeryProduct.store_sort_priority,
    });

    if (bakeryProduct.store_type === 'internal_only') {
      this.disableFeaturedSort();
    }
  }

  private disableFeaturedSort() {
    this.form.get('store_featured').disable();
    this.form.get('store_sort_priority').disable();
  }

  private enableFeaturedSort() {
    this.form.get('store_featured').enable();
    this.form.get('store_sort_priority').enable();
  }
}
