import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';

import { AppState } from 'app/store/app-state';
import * as selectors from 'app/store/selectors';
import { GetAction } from 'app/store/actions/request.actions';
import { Bakery } from 'app/store/bakery';
import { BakeryOrderInvoice } from 'app/store/bakery-order-invoice';
import { WholesalerOrg } from 'app/store/wholesaler-org';
import { bakeryOrderInvoiceSchema } from 'app/store/schema/default-schemata';

const opKey = 'wholesalerInvoiceDetail',
      schema = bakeryOrderInvoiceSchema;

@Component({
  template: `
    <bakery-mgmt-page-header-detail
      title="Invoice Details"
      routeTitle="Back to wholesaler details"
      [route]="routeUrl$ | async">
    </bakery-mgmt-page-header-detail>
    <alert-container></alert-container>
    <invoice-details-card
      [isLoading]="isLoading$ | async"
      [invoice]="invoice$ | async"
      [bakery]="currentBakery$ | async"
      [wholesalerOrg]="wholesalerOrg$ | async"
      [isManualAllowed]="true"
      paymentButtonText="Record Payment"
    ></invoice-details-card>
  `
})
export class DetailComponent implements OnInit, OnDestroy {
  invoiceId$ = this.route.params
    .map((params): number => parseInt(params['invoice_id']));

  isLoading$: Observable<boolean> = this.store
    .select(selectors.getRequestIsLoading(opKey));

  invoice$: Observable<BakeryOrderInvoice> = this.store
    .select(selectors.getRequestResult(opKey, schema))
    .filter(val => !!val);

  wholesalerOrg$: Observable<WholesalerOrg> = this.invoice$
    .map((invoice: BakeryOrderInvoice) => invoice.parent);

  currentBakery$: Observable<Bakery> = this.store
    .select(selectors.getCurrentBkryMgmtBakery)
    .filter(val => !!val);

  routeUrl$: Observable<string> = this.invoice$
    .map((invoice: BakeryOrderInvoice) => {
      if (invoice == null) {
        return `/bakery_mgmt/wholesalers/lists/active`;
      }

      return `/bakery_mgmt/wholesalers/wholesaler/${invoice.parent_id}/invoices`
    })

  private idSub: Subscription;

  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.idSub = this.invoiceId$
      .subscribe((id: number) => {
        const actionPayload = {
          url: `/api/invoices/${id}`,
          opKey: opKey,
          schema: schema
        };
        this.store.dispatch(new GetAction(actionPayload));
      });
  }

  ngOnDestroy() {
    this.idSub.unsubscribe();
  }
}
