// FIXME: This and corresponding header nav are highly redundant.

import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import * as Rx from 'rxjs';
import { Store } from '@ngrx/store';

import { AppState } from 'app/store/app-state';
import { User } from 'app/store/user';
import { UsersEffects } from 'app/store/effects/users.effects';
import { BakeryCustEffects } from 'app/store/effects/bakery-cust/bakery-cust.effects';
import * as selectors from 'app/store/selectors';

@Component({
  selector: 'bakery-cust-footer-nav',
  template: require('./footer-nav.component.html'),
})
export class FooterNavComponent {
  isClassMobileMenuActive = false;
  permitEcomm$: Rx.Observable<boolean> = this.store.select(selectors.getCustBakeryPermitEcomm);

  constructor(
    private usersEffects: UsersEffects,
    private bakeryCustEffects: BakeryCustEffects,
    private router: Router,
    private store: Store<AppState>,
  ) { }

  get customerOverview$() {
    return this.bakeryCustEffects.customerOverview$;
  }

  onClickMobileMenuActive() {
  	this.isClassMobileMenuActive = !this.isClassMobileMenuActive
  }

  onClickSignOut() {
    this.usersEffects.requestUserSignOut();
  }

  onClickAccountSettings() {
    this.router.navigate(['/store/account']);
  }
}
