// TODO: ListArchiveButton and SidebarArchiveButton are mostly redundant.

import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions as NgRxActions } from '@ngrx/effects';
import { Observable, Subject, Subscription } from 'rxjs';
import { FormArray, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

import { AppState } from 'app/store/app-state';
import * as selectors from 'app/store/selectors';
import { wholesalerOrgSchema } from 'app/store/schema/default-schemata';
import { PutAction, PutActionPayload } from 'app/store/actions/request.actions';
import { WholesalerOrg } from 'app/store/wholesaler-org';
import { AlertAction } from 'app/shared/components/alerts/actions';

const opKey = 'wholesalerTransferOwnership',
      schema = wholesalerOrgSchema;

@Component({
  selector: 'bkry-mgmt-transfer-wholesaler-ownership',
  template: `
    <titled-card title="Wholesale Organization Ownership">
      <ng-container data-card-body>
        <p>Each wholesale organization has one owner. The wholesaler organization owner is responsible for managing the wholesaler account and placing orders on behalf of the organization. You can use the form below to transfer ownership to another user. If the user is not currently in the system, they will be invited to join.</p>
        <form
          [formGroup]="form"
          (submit)="onSubmitForm()"
          gridRow
        >
          <div gridCol l="3">
            <label>Owner First Name</label>
            <input
              type="text"
              placeholder="John"
              formControlName="new_owner_user_first_name"
            />
          </div>
          <div gridCol l="3">
            <label>Owner Last Name</label>
            <input
              type="text"
              placeholder="Smith"
              formControlName="new_owner_user_last_name"
            />
          </div>
          <div gridCol l="4">
            <label>Owner Email</label>
            <input
              type="email"
              placeholder="john@email.com"
              formControlName="new_owner_user_email"
            />
          </div>
          <div gridCol l="2">
            <on-button
              [isEnabled]="form.dirty && form.valid"
              [isLoading]="isLoading$ | async"
              [isLabelOffset]="true"
              buttonType="submit"
            >
              <ng-container>Transfer</ng-container>
              <ng-container data-loading>Transferring</ng-container>
            </on-button>
          </div>
        </form>
      </ng-container>
    </titled-card>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransferWholesalerOwnershipComponent implements OnInit, OnDestroy {
  @Input() wholesalerOrg: any;

  isLoading$: Observable<boolean> = this.store
    .select(selectors.getRequestIsLoading(opKey))
    .startWith(false);

  onSuccess$: Observable<undefined> = selectors.onRequestSuccessBuilder(this.actions$, opKey);

  op$ = new Subject<void>();

  private opSub: Subscription;
  private opSuccessSub: Subscription;

  form: FormGroup;

  constructor(
    private store: Store<AppState>,
    private actions$: NgRxActions,
    private fb: FormBuilder
  ) {
    this.form = fb.group({
      new_owner_user_first_name: fb.control(null, [Validators.required]),
      new_owner_user_last_name: fb.control(null, [Validators.required]),
      new_owner_user_email: fb.control(null, [Validators.required, Validators.email]),
    });
  }

  ngOnInit() {
    this.opSub = this.op$
      .switchMap(() => this.isLoading$.take(1))
      .filter(isLoading => !isLoading)
      .subscribe(() => {
        const actionPayload: PutActionPayload = {
          url: `/api/wholesalers/${this.wholesalerOrg.id}/transfer_ownership`,
          opKey: opKey,
          schema: schema,
          body: {
            ...this.form.value
          },
          successChainAction: AlertAction.buildSuccess(`The wholesaler org ownership has been transferred.`)
        }
        this.store.dispatch(new PutAction(actionPayload));
      });

    this.opSuccessSub = this.onSuccess$
      .subscribe(() => this.form.reset());
  }

  ngOnDestroy() {
    this.opSub.unsubscribe();
    this.opSuccessSub.unsubscribe();
  }

  onSubmitForm() {
    this.op$.next();
  }
}
