import { Component, ChangeDetectionStrategy } from '@angular/core';

import { AssociationListComponent } from './association-list.component';

@Component({
  selector: 'tooltip-association-list',
  template: `
    <ng-container [ngSwitch]="list.length">
      <ng-container *ngSwitchCase="0">{{ emptyName }}</ng-container>
      <ng-container *ngSwitchCase="1">{{ list[0][listNameKey] }}</ng-container>
      <card-list-tooltip-container *ngSwitchDefault class="tooltip__item tooltip__item--block">
        <card-list-tooltip-label>{{ list.length }} {{ countLabel }}</card-list-tooltip-label>
        <card-list-tooltip-items>
          <card-list-tooltip-list-header>{{ label }}</card-list-tooltip-list-header>
          <card-list-tooltip-list-item *ngFor="let item of list">
            {{ item[listNameKey] }}
          </card-list-tooltip-list-item>
        </card-list-tooltip-items>
      </card-list-tooltip-container>
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipAssociationListComponent extends AssociationListComponent { }
