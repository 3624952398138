import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { Store } from '@ngrx/store';
import { EntitiesState } from 'app/store/entities-state';
import { Bakery } from 'app/store/bakery';
import { BakeryMgmtEffects } from 'app/store/effects/bakery-mgmt.effects';
import * as selectors from 'app/store/selectors';

import { BakerySubscriptionInvoice } from 'app/store/bakery-subscription-invoice';
import { BakeryMgmtSubscriptionInvoicesEffects } from 'app/store/effects/bakery-mgmt/bakery-mgmt-subscription-invoices.effects';


interface UiState {
  isLoading: boolean;
  bakery?: Bakery;
  bakerySubscriptionInvoices?: BakerySubscriptionInvoice[];
  bkrySubStatus?: any;
  wholesalerEcommEnabled?: boolean;
}

@Component({
  selector: 'bakery-mgmt-account-settings-billing',
  template: require('./billing.component.html')
})
export class BakeryMgmtAccountSettingsBillingComponent implements OnInit, OnDestroy {
  bakery$: Observable<UiState>;
  invoices: Observable<UiState>;
  uiState: UiState;

  private valuesSub: Subscription;

  constructor(
    private store: Store<any>,
    private bkryMgmtEffects: BakeryMgmtEffects,
    private bakeryMgmtSubscriptionInvoicesEffects: BakeryMgmtSubscriptionInvoicesEffects,
  ) {
    this.bakery$ = Observable
      .combineLatest(
        this.bkryMgmtEffects.currentBakery$,
        this.store.select('entitiesState'),
        (bakery, entitiesState) => ({bakery, entitiesState})
      ).map((combined): UiState => {
        const bakery = <Bakery>combined.bakery;
        const entitiesState = <EntitiesState>combined.entitiesState;

        if (bakery == null) {
          return {
            isLoading: true,
          };
        }

        const bakerySubscriptionInvoices = Object.keys(entitiesState.bakery_subscription_invoices)
          .map(key => entitiesState.bakery_subscription_invoices[key]);

        const wholesalerEcommPlan = (bakery.bakery_subscription_status as any).primary_subscription.child_bakery_subscriptions
          .find(sub => sub.subscription_plan_type.type_name === 'wholesaler_ecomm');

        return {
          isLoading: false,
          bakery: bakery,
          bakerySubscriptionInvoices: bakerySubscriptionInvoices,
          bkrySubStatus: bakery.bakery_subscription_status,
          wholesalerEcommEnabled: wholesalerEcommPlan && wholesalerEcommPlan.quantity > 0
        };
      })
      .startWith({isLoading: true})
      .shareReplay(1)

    this.valuesSub = this.bakery$
      .subscribe((uiState: UiState) => this.uiState = uiState);
  }

  ngOnInit() {
    this.bakeryMgmtSubscriptionInvoicesEffects.requestGetSubscriptionInvoices();
  }

  get isTrialing() {
    return this.uiState.bkrySubStatus.status === 'trialing' ? 'Trial' : null;
  }

  ngOnDestroy() {
    if (this.valuesSub != null) { this.valuesSub.unsubscribe(); }
  }
}
