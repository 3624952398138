import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as Rx from 'rxjs';
import { Actions as NgRxActions } from '@ngrx/effects';

import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { AppState } from 'app/store/app-state';
import { AlertService } from 'app/shared/components/alerts/alert.service';
import { BakeryCustEffects } from 'app/store/effects/bakery-cust/bakery-cust.effects';
import { UsersEffects } from 'app/store/effects/users.effects';
import { Actions } from 'app/store/actions';
import { User } from 'app/store/user';
import { hasRole } from 'app/store/users/utils';
import { Role } from 'app/store/role';
import { Bakery } from 'app/store/bakery';
import { MasterState } from 'app/store/reducers/master-state-reducer';
import { validateNoWhitespaces } from 'app/shared/forms/no-whitespaces-validator';

interface UiState {
  isLoading: boolean;
  bakery?: Bakery;
}

@Component({
  template: require('./register.component.html'),
})
export class BakeryCustRegisterComponent implements OnInit, OnDestroy {
  uiState$: Rx.Observable<UiState>;
  form: FormGroup;

  private signInSuccess$ = new Rx.Subject<any>();
  private alertsSub: Rx.Subscription;

  constructor(
    private router: Router,
    private alertService: AlertService,
    private bakeryCustEffects: BakeryCustEffects,
    private actions$: NgRxActions,
    private store: Store<AppState>,
  ) {
    this.form = new FormGroup({
      'first_name': new FormControl(null, [Validators.required, validateNoWhitespaces]),
      'last_name': new FormControl(null, [Validators.required, validateNoWhitespaces]),
      'email': new FormControl(null, [Validators.required, Validators.email]),
    });
  }

  ngOnInit() {
    this.uiState$ = this.bakeryCustEffects.currentBakery$
      .map(currentBakery => {
        return {
          isLoading: currentBakery == null,
          bakery: currentBakery,
        };
      });


    this.alertsSub = this.actions$
      .subscribe((action: UnsafeAction) => {
        let messageType = null,
            messageContent = null;

        switch (action.type) {
          case Actions.REQUEST_REGISTER_BAKERY_CUSTOMER_SUCCESS:
            messageType = 'success';
            messageContent = `Registration successful! You will find an account confirmation email in your inbox soon.`;
            break;

          case Actions.REQUEST_REGISTER_BAKERY_CUSTOMER_ERROR:
            messageType = 'warning';
            messageContent = 'Something went wrong!';

            if (action.payload.error === 'duplicate_email_address') {
              messageContent = `Another user already has that email address.`;
            }

            break;
        }

        if (messageType != null && messageContent != null) {
          this.alertService[messageType](messageContent);
        }
      });
  }

  ngOnDestroy() {
  }

  onSubmit() {
    this.bakeryCustEffects.requestRegisterCustomer(
      this.form.value.first_name,
      this.form.value.last_name,
      this.form.value.email
    );
  }
}
