import { Component, Input, Output, EventEmitter } from '@angular/core';

import { PopoutContainerComponent } from 'app/shared/components/popouts/popout-container.component';
import { PopoutService } from 'app/shared/components/popouts/popout.service';

@Component({
  selector: 'popout-list-item',
  template: `
  <li class="popout__item">
    <a
      [ngClass]="{'clickable': isEnabled, 'link--disabled': !isEnabled}"
      (click)="onClick($event)">
      <ng-content></ng-content>
    </a>
  </li>
  `
})
export class PopoutListItemComponent {
  @Output('click') clickEvent = new EventEmitter<Event>();
  @Input('enabled') isEnabled = true;

  constructor(
    private popoutService: PopoutService,
  ) { }

  onClick($event: Event) {
    // Prevent the click event from bubbling up.
    $event.stopPropagation();
    $event.preventDefault();

    this.clickEvent.next($event);

    this.popoutService.closePopout();
  }
}
