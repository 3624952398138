import { Component, OnInit, Input, Output, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as Rx from 'rxjs';

import { AppState } from 'app/store/app-state';
import { Actions } from 'app/store/actions';

@Component({
  selector: 'bakery-mgmt-wholesalers-group-list-item',
  template: require('./list-item.component.html'),
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupListItemComponent implements OnInit {
  @Input() wholesalerGroup: any;
  @Output('onClickShowDetails') onClickShowDetails$ = new Rx.Subject<any>();

  orderStatusAction = Actions.BKRY_MGMT_ORDER_MGMT_LIST_ORDER_STATUS_CHANGE;

  ngOnInit() {
  }

  onClickDetails() {
    this.onClickShowDetails$.next(this.wholesalerGroup);
  }

  onClickCheckbox($event: Event) {
    $event.stopPropagation();
  }
}
