import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs/Observable';

@Component({
  template: `
  <bakery-mgmt-page-blank-state
  title="Page Not Found"
  [description]="null"
  icon="store"
  ariaLabel="Location Icon"
  >
    <p>
      The page you are looking for cannot be found.

      <br>

      <span *ngIf="isBakery$|async">
        Please check your URL or return to <a routerLink="/" class="link--warning">OrderNova</a>.
      </span>

      <span *ngIf="!(isBakery$|async)">
        Please check your URL or call us.
      </span>
    </p>
  </bakery-mgmt-page-blank-state>
  `
})
export class PageNotFoundComponent implements OnInit {
  isBakery$: Observable<boolean>;

  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    const isCustomer$ = this.activatedRoute.queryParams.map(params => params.hasOwnProperty('isCustomer'));
    this.isBakery$ = isCustomer$.map(x => !x);
  }
}
