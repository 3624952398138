import { Component, OnInit, OnDestroy } from '@angular/core';


@Component({
  template: require('./create-invoice.component.html'),
})
export class CreateInvoiceComponent implements OnInit, OnDestroy {
  constructor(
  ) {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }
}
