import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { Actions as NgRxActions } from '@ngrx/effects';

import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { AppState } from 'app/store/app-state';
import { AlertService } from 'app/shared/components/alerts/alert.service';
import { Actions } from 'app/store/actions';
import { BakeryOrder } from 'app/store/bakery-order';
import * as selectors from 'app/store/selectors';
import { phoneNumberValidator } from 'app/shared/forms/form-number-validator';
import { futureDateValidator, twoDaysDateValidator } from 'app/shared/forms/date-validators';
import { CheckoutBakeryOrderFormCust } from 'app/bakery-cust/checkout/checkout-bakery-order-form';
import * as originalMoment from 'moment/moment';
import { extendMoment } from 'moment-range';
import * as entitySelectors from 'app/store/selectors/entities';
import { BakeryLocation } from 'app/store/bakery-location';
import { validateNoWhitespaces } from 'app/shared/forms/no-whitespaces-validator';
import { Constants } from 'app/constants';
import { CentsObservablePipe } from 'app/shared/pipes/cents-observable.pipe';
import { isWithinAvailabilityRange } from 'app/isWithinAvailabilityRange';

const moment = extendMoment(originalMoment);

@Component({
  template: require('./checkout.component.html'),
})
export class BakeryCustCheckoutComponent implements OnInit, OnDestroy {
  form: FormGroup;
  bakeryLocations: BakeryLocation[];
  availableFulfillmentTypesForBakery: string[];
  order$: Observable<BakeryOrder>;
  taxes$: Observable<string>;
  total$: Observable<string>;
  formValid$: Observable<boolean>;
  isLoading$: Observable<boolean>;
  confirmOrder$ = new Subject<any>();
  createToken$ = new Subject<any>();
  stripeToken$ = new Subject<any>();
  cardValid$ = new Subject<boolean>();
  triggerValidation$ = new Subject<boolean>();
  validFulfillmentDate = false;
  validFulfillmentType = false;
  isSubmitting = false;
  dateValidationErrors: Object[];
  fulfillmentTypeForProductValidationErrors: Object[];
  validFulfillmentTypeForBakery = false;

  private deleteCartSuccessSub: Subscription;
  private submitCheckoutSuccessSub: Subscription;
  private alertsSub: Subscription;
  private orderValuesSub: Subscription;
  private validatorsSub: Subscription;
  private formValuesSub: Subscription;
  private confirmOrderSub: Subscription;
  private dateValidatorSub: Subscription;
  private fulfillmentTypeValidatorSub: Subscription;

  constructor(
    private router: Router,
    private alertService: AlertService,
    private actions$: NgRxActions,
    private store: Store<AppState>,
    private fb: FormBuilder,
    private centsObservablePipe: CentsObservablePipe
  ) {
    this.form = this.fb.group(
      {
        fulfillment_type: [null, [Validators.required]],
        fulfillment_date: [null, [Validators.required, futureDateValidator, twoDaysDateValidator]],
        fulfillment_at: [null, [Validators.required]],
        fulfillment_location_id: [null, [Validators.required]],
        street: [null],
        unit: [null],
        city: [null],
        state: [null],
        zip: [null],
        order_customer_first_name: [null, [Validators.required, validateNoWhitespaces]],
        order_customer_last_name: [null, [Validators.required, validateNoWhitespaces]],
        order_customer_email: [null, [Validators.required, Validators.email]],
        order_customer_phone_number: [null, [phoneNumberValidator]],
        attached_images_attributes: this.fb.array([]),
        order_customer_notes: [null],
        payment_card_selection: [null],
        payment_type: ['credit_card', [Validators.required]],
      },
    );
  }

  ngOnInit() {
    this.store.dispatch({type: Actions.INIT_CUST_BAKERY_ORDER});

    this.store.select(entitySelectors.getBakeryLocationEntitiesArray)
      .subscribe((locations: BakeryLocation[]) => {
        this.bakeryLocations = locations;
        this.availableFulfillmentTypesForBakery = this.getAvailableFulfillmentTypesForAllLocations();
      });
    this.order$ = this.store.select(selectors.getCustomerOrder);

    const taxes$ = this.store.select(selectors.getCustomerOrderTaxEstimateState);
    this.taxes$ = taxes$.switchMap(taxes => {
      switch (taxes.status) {
        case 'success': return this.centsObservablePipe.transform(taxes.value);
        case 'loading': return Observable.of('Loading...');
        case 'error': return Observable.of('Error');
        case 'notAsked': return Observable.of('N/A');
      }
    });
    this.total$ = Observable.combineLatest(taxes$, this.order$)
      .switchMap(([taxes, order]) => {
        switch (taxes.status) {
          case 'success':
            const total = order.total_price - order.total_discount_price + taxes.value;
            return this.centsObservablePipe.transform(Math.max(total, 0));
          case 'loading': return Observable.of('Loading...');
          case 'error': return Observable.of('Error');
          case 'notAsked': return Observable.of('N/A');
        }
      });

    this.orderValuesSub = this.order$
      .filter(val => val != null)
      .subscribe((order: CheckoutBakeryOrderFormCust) => {
        this.form.patchValue(
          {
            fulfillment_type: order.form_fulfillment_data.fulfillment_type,
            fulfillment_at: order.form_fulfillment_data.fulfillment_at,
            fulfillment_location_id: order.form_fulfillment_data.fulfillment_location_id,
            street: order.form_fulfillment_data.fulfillment_address.street,
            unit: order.form_fulfillment_data.fulfillment_address.unit,
            city: order.form_fulfillment_data.fulfillment_address.city,
            state: order.form_fulfillment_data.fulfillment_address.state,
            zip: order.form_fulfillment_data.fulfillment_address.zip,
            order_customer_first_name: order.order_customer_first_name,
            order_customer_last_name: order.order_customer_last_name,
            order_customer_email: order.order_customer_email,
            order_customer_phone_number: order.order_customer_phone_number,
            order_customer_notes: order.order_customer_notes,
            payment_card_selection: order.payment_card_selection,
          },
          {
            emitEvent: false,
          }
        );
        // Since `emitEvent` is set to `false` above, validation needs to be manually triggered.
        // `emitEvent` is set to false because otherwise there's an infinite loop when the form
        // is updated by filling a field.
        // Fixes the following bug:
        // - click on "New Order"
        // - select "Retail Order"
        // - add a product
        // - go to checkout
        // - select "pickup" as fulfilment method
        // - select a fulfilment date in the future
        // - select a pickup location
        // - fill in customer info
        // - select no payment
        // - notice the create order button is enabled
        // - select"delivery" as fulfilment method
        // - notice that the delivery address is blank but the create order button is enabled
        //   (the create order button should be disabled instead)
        this.triggerValidation$.next(true);
      });

    this.fulfillmentTypeValidatorSub = this.form.get('fulfillment_type').valueChanges.withLatestFrom(
      this.order$,
      (type, order) => ({ type, order })
    ).subscribe(({type, order}) => {
      this.fulfillmentTypeForProductValidationErrors = (<any[]>order.order_items).map(item => {
        const availableFulfillmentTypesForProduct = this.getAvailableFulfillmentTypesForProduct(item.bakery_product);
        const errors = [];

        if (!availableFulfillmentTypesForProduct.includes(type)) {
          errors.push({ name: item.bakery_product.name, error: `not available for ${type}` });
        }

        return errors;
      }).filter(arr => arr.length !== 0);

      if (this.fulfillmentTypeForProductValidationErrors.length > 0) {
        const messageType = 'warning';
        const messageContent = `The following products are not not available for ${type} (${this.fulfillmentTypeForProductValidationErrors.map(errors => errors[0].name).join(', ')})`;
        this.alertService[messageType](messageContent);
      }

      if (!this.availableFulfillmentTypesForBakery.includes(type)) {
        this.validFulfillmentTypeForBakery = false;
        const messageType = 'warning';
        const messageContent = `Our bakery doesn't provide ${type} option`;
        this.alertService[messageType](messageContent);
      } else {
        this.validFulfillmentTypeForBakery = true;
      }

      this.validFulfillmentType = this.fulfillmentTypeForProductValidationErrors.length > 0 || !this.validFulfillmentTypeForBakery ? false : true;
    });

    this.dateValidatorSub = this.form.get('fulfillment_date').valueChanges.withLatestFrom(
      this.order$,
      (date, order) => ({date, order})
    ).subscribe(({date, order}) => {
      this.dateValidationErrors = (<any[]>order.order_items).map((item) => {
        const errors = [];
        if (item.bakery_product.available_day_specific) {
          const day = moment(date).format('dddd');

          if (!item.bakery_product.available_days.includes(day)) {
            errors.push({name: item.bakery_product.name, error: `not sold on ${day}`})
          }
        }

        if (item.bakery_product.available_range_specific) {
          const {
            available_range_start_day,
            available_range_start_month,
            available_range_end_day,
            available_range_end_month
          } = item.bakery_product;
          const availabilityRange = {
            available_range_start_day,
            available_range_start_month,
            available_range_end_day,
            available_range_end_month
          };
          if (!isWithinAvailabilityRange(date, availabilityRange)) {
            errors.push({name: item.bakery_product.name, error: 'not sold on this date'});
          }
        }

        return errors;
      }).filter(arr => arr.length !== 0);

      if (this.dateValidationErrors.length > 0) {
        const messageType = 'warning';
        const messageContent = `The following products are not sold on this day (${this.dateValidationErrors.map(errors => errors[0].name).join(', ')})`;
        this.alertService[messageType](messageContent);
      }

      this.validFulfillmentDate = this.dateValidationErrors.length > 0 ? false : true;
    });

    this.validatorsSub = this.form.valueChanges
      .withLatestFrom(
        this.store.select(selectors.getCurrentUserPaymentCards),
        (formValue, paymentCards) => ({formValue, paymentCards})
      )
      .subscribe(({formValue, paymentCards}) => {
        const isAddressRequired = formValue.fulfillment_location_id === 'add_new';

        const streetCtrl = this.form.get('street'),
              cityCtrl = this.form.get('city'),
              stateCtrl = this.form.get('state'),
              zipCtrl = this.form.get('zip');

        if (isAddressRequired) {
          streetCtrl.setValidators([Validators.required, validateNoWhitespaces]);
          cityCtrl.setValidators([Validators.required, validateNoWhitespaces]);
          stateCtrl.setValidators([Validators.required, validateNoWhitespaces]);
          zipCtrl.setValidators([Validators.required]);
        } else {
          streetCtrl.setValidators([]);
          cityCtrl.setValidators([]);
          stateCtrl.setValidators([]);
          zipCtrl.setValidators([]);
        }

        if (paymentCards.length > 0) {
          this.form.get('payment_card_selection').setValidators([Validators.required]);
        } else {
          this.form.get('payment_card_selection').setValidators([]);
        }
      });

    this.formValuesSub = this.form.valueChanges
      .withLatestFrom(
        this.order$,
        (formValue, checkoutBakeryOrderForm) => ({formValue, checkoutBakeryOrderForm})
      )
      .debounce(() => Observable.timer(500))
      .distinctUntilChanged((x, y) => JSON.stringify(x) === JSON.stringify(y))
      .subscribe(({formValue, checkoutBakeryOrderForm}: {formValue: any, checkoutBakeryOrderForm: CheckoutBakeryOrderFormCust}) => {
        const fulfillmentLocationType =
          formValue.fulfillment_type === 'pickup'
          ? 'BakeryLocation'
          : formValue.fulfillment_type === 'shipping' || formValue.fulfillment_type === 'delivery'
          ? 'Address'
          : null;

        const fulfillmentLocationId =
            formValue.fulfillment_type === checkoutBakeryOrderForm.form_fulfillment_data.fulfillment_type
            ? formValue.fulfillment_location_id
            : null;

        const fulfillmentDateChanged =
          formValue.fulfillment_date !== checkoutBakeryOrderForm.form_fulfillment_data.fulfillment_date;

        const fulfillment = {
          fulfillment_type: fulfillmentDateChanged ? null : formValue.fulfillment_type,
          fulfillment_date: formValue.fulfillment_date,
          fulfillment_at: fulfillmentDateChanged ? null : formValue.fulfillment_at,
          fulfillment_location_type: fulfillmentDateChanged ? null : fulfillmentLocationType,
          fulfillment_location_id: fulfillmentDateChanged ? null : fulfillmentLocationId,
        };

        this.store.dispatch({
          type: Actions.UPDATE_CUST_BAKERY_ORDER,
          payload: {
            order_customer_first_name: formValue.order_customer_first_name,
            order_customer_last_name: formValue.order_customer_last_name,
            order_customer_email: formValue.order_customer_email,
            order_customer_phone_number: formValue.order_customer_phone_number,
            order_customer_notes: formValue.order_customer_notes,
            attached_images_attributes: formValue.attached_images_attributes.map(attachedImage => attachedImage.image),
            form_fulfillment_data: {
              ...checkoutBakeryOrderForm.form_fulfillment_data,
              ...fulfillment,
              fulfillment_address: {
                street: formValue.street,
                unit: formValue.unit,
                city: formValue.city,
                state: formValue.state,
                zip: formValue.zip,
              }
            },
            payment_type: formValue.payment_type,
            payment_card_selection: formValue.payment_card_selection,
          },
        })
      });

    this.isLoading$ = this.store.select(selectors.getOrderState).map(state => state.isLoading);

    this.deleteCartSuccessSub = this.actions$
      .ofType(Actions.REQUEST_DELETE_CUST_CART_SUCCESS)
      .subscribe(() => this.router.navigate(['/']));

    this.submitCheckoutSuccessSub = this.actions$
      .ofType(Actions.REQUEST_CREATE_CUST_BAKERY_ORDER_SUCCESS)
      .subscribe((action: UnsafeAction) => {
        // That action payload should be the new BakeryOrder ID. Simply
        // forward to the order detail view.
        this.router.navigate(['/store/order_history/', action.payload]);
      });

    this.formValid$ = Observable
      .combineLatest(
        this.form.valueChanges,
        this.cardValid$
          .startWith(false),
        this.triggerValidation$,
        (formValue, isCardValid, _) => ({formValue, isCardValid})
      )
      .map(({formValue, isCardValid}: {formValue: any, isCardValid: boolean}) => {
        if (!this.form.valid || !this.validFulfillmentDate || !this.validFulfillmentType) return false;

        if (formValue.payment_card_selection !== 'add_new' && formValue.payment_card_selection != null) return true;

        return isCardValid;
      });

    this.confirmOrderSub = this.confirmOrder$
      .skipUntil(this.formValid$)
      .skipUntil(Observable.of(!this.isSubmitting))
      .switchMap(() => this.store.select(selectors.getCustomerOrder).take(1))
      .switchMap((orderForm) => {
        this.isSubmitting = true;
        if (orderForm.payment_card_selection === 'add_new' || orderForm.payment_card_selection == null) {
          // Request a new token be created.
          this.createToken$.next();

          return this.stripeToken$
            .switchMap((tokenResponse) => {
              // Catch a token error, and quit.
              if (tokenResponse.error != null) {
                let errorMessage = 'Something went wrong!';

                if (!!tokenResponse.error && !!tokenResponse.error.message) {
                  errorMessage = tokenResponse.error.message;
                }

                this.alertService.warning(errorMessage);

                return Observable.empty();
              }

              // Dispatch two actions: update order and submit order
              return Observable.from([
                {
                  type: Actions.UPDATE_CUST_BAKERY_ORDER,
                  payload: {
                    stripe_token: tokenResponse.token.id,
                  }
                },
                {
                  type: Actions.REQUEST_CREATE_CUST_BAKERY_ORDER,
                },
              ]);
            });
        } else {
          return Observable.of({
            type: Actions.REQUEST_CREATE_CUST_BAKERY_ORDER,
          });
        }
      })
      .subscribe((action: any) => {
        if (action != null && !!action.type) {
          this.store.dispatch(action)
        }
      });

    this.alertsSub = this.actions$
      .subscribe((action: UnsafeAction) => {
        let messageType = null,
            messageContent = null;

        switch (action.type) {
          case Actions.REQUEST_CREATE_CUST_BAKERY_ORDER_ERROR:
            this.isSubmitting = false;
            messageType = 'warning';
            messageContent = action.payload.error || 'Something went wrong!';

            if (action.payload.error === 'duplicate_email_address') {
              messageContent = 'Another user already has that email address.';
            }
            break;
        }

        if (messageType != null && messageContent != null) {
          this.alertService[messageType](messageContent);
        }
      });
  }

  getAttributes(item) {
    const attributes = item.meta.template_variants.map(variant => (
      `${variant.bakery_product_attribute.visible_name}: <strong>${variant.bakery_attribute_variant.name}</strong><br>`
    )).concat(item.meta.attribute_variants.map(variant => (
      `${variant.bakery_product_attribute.visible_name}: <strong>${variant.bakery_attribute_variant.name}</strong><br>`
    )));

    return attributes.join('');
  }

  ngOnDestroy() {
    this.deleteCartSuccessSub.unsubscribe();
    this.submitCheckoutSuccessSub.unsubscribe();
    this.alertsSub.unsubscribe();
    this.validatorsSub.unsubscribe();
    this.formValuesSub.unsubscribe();
    this.confirmOrderSub.unsubscribe();
    this.dateValidatorSub.unsubscribe();
    this.fulfillmentTypeValidatorSub.unsubscribe();
  }

  onClickConfirmOrder() {
    this.confirmOrder$.next();
  }

  onClickCancelOrder() {
    this.store.dispatch({
      type: Actions.REQUEST_DELETE_CUST_CART,
    });
  }

  getAvailableFulfillmentTypesForAllLocations(): string[] {
    const typesMap = {
      [Constants.DELIVERY_ONLY]: ['shipping', 'delivery'],
      [Constants.DELIVERY_AND_PICKUP]: ['shipping', 'delivery', 'pickup'],
      [Constants.PICKUP_ONLY]: ['shipping', 'pickup'],
      [Constants.NONE]: [],
    };
    const availableTypes = this.bakeryLocations.map(location => typesMap[location.fulfillment_type]) || [];
    const availableTypesFlat = [].concat.apply([], availableTypes);

    return availableTypesFlat;
  }

  getAvailableFulfillmentTypesForProduct(bakeryProduct): string[] {
    const fulfilmentTypes = [
      [ 'delivery', bakeryProduct.fulfillment_option_delivery ],
      [ 'pickup', bakeryProduct.fulfillment_option_pickup ],
      [ 'shipping', bakeryProduct.fulfillment_option_ship ],
    ];

    return fulfilmentTypes.filter(x => x[1]).map(x => x[0])
  }
}
