import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { Actions } from 'app/store/actions';
import { BakeryOrder } from 'app/store/bakery-order';
import { NormalizedResourceRequestState } from 'app/store/reducers/request-states';

export interface OrderHistoryMasterState extends NormalizedResourceRequestState<BakeryOrder[], number[]> { }
export interface OrderHistoryDetailState extends NormalizedResourceRequestState<BakeryOrder, number> {
  detailId: number;
  isCancelLoading: boolean;
}

export interface State {
  master: OrderHistoryMasterState;
  detail: OrderHistoryDetailState;
}

export const initialState: State = {
  master: {state: null, result: []},
  detail: {state: null, result: null, detailId: null, isCancelLoading: false},
}

export function reducer(
  state: State = initialState,
  action: UnsafeAction
): State {
  switch (action.type) {
    case Actions.REQUEST_PUT_CUST_BAKERY_ORDER_CANCEL:
      return {
        ...state,
        detail: {
          ...state.detail,
          isCancelLoading: true,
        }
      };

    case Actions.REQUEST_PUT_CUST_BAKERY_ORDER_CANCEL_SUCCESS:
    case Actions.REQUEST_PUT_CUST_BAKERY_ORDER_CANCEL_ERROR:
      return {
        ...state,
        detail: {
          ...state.detail,
          isCancelLoading: false,
        }
      };

    default:
      return state;
  }
}
