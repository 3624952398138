import { Component, OnInit, OnDestroy, Input, Output } from '@angular/core';
import * as Rx from 'rxjs';

import { PaymentCard } from 'app/store/payment-card';

@Component({
  selector: 'user-credit-card',
  template: require('./user-credit-card.component.html'),
})
export class UserCreditCardComponent implements OnInit, OnDestroy {
  @Input('paymentCards') paymentCards: PaymentCard[] = [];
  @Input('reset$') reset$: Rx.Observable<any>;
  @Output('addCard$') addCard$ = new Rx.Subject<PaymentCard>();
  @Output('removeCard$') removeCard$ = new Rx.Subject<PaymentCard>();

  createToken$ = new Rx.Subject<any>();
  stripeToken$ = new Rx.Subject<any>();
  clear$ = new Rx.Subject<any>();
  valid$ = new Rx.BehaviorSubject<boolean>(false);

  private resetFormSub: Rx.Subscription;
  private stripeTokenSub: Rx.Subscription;

  ngOnInit() {
    if (this.reset$ != null) {
      this.resetFormSub = this.reset$
        .subscribe(() => {
          this.clear$.next();
        });
    }

    this.stripeTokenSub = this.stripeToken$
      .subscribe((stripeResultObject) => {
        const token = stripeResultObject.token,
              card = stripeResultObject.token.card;

        const newCard = {
          id: null,
          owner_type: null,
          owner_id: null,
          brand: card.brand,
          last4: card.last4,
          exp_month: card.exp_month,
          exp_year: card.exp_year,
          stripe_token: token.id,
          pay_gtwy_type: 'stripe',
        };

        this.addCard$.next(newCard);

        this.clear$.next();
      });
  }

  ngOnDestroy() {
    if (this.resetFormSub != null) {
      this.resetFormSub.unsubscribe();
    }

    this.stripeTokenSub.unsubscribe();
  }

  onClickRemoveCard(card: PaymentCard) {
    this.removeCard$.next({...card, _destroy: true});
  }
}
