import { denormalize, schema } from 'normalizr';

import { bakeryProductProductTemplateSchema } from 'app/store/schema/default-schemata';
import { BakeryProductProductTemplate } from 'app/store/bakery-product-product-template';
import { WholesalerProductOverride } from 'app/store/wholesaler-product-override';
import { compareStrings } from 'app/shared/string-utils';
import { WholesalerGroup } from 'app/store/wholesaler-group';
import { EntitiesState } from 'app/store/entities-state';
import { PricedProductVariation } from 'app/wholesaler/priced-product-variation';

const wholesalerProductStoreTypes = ['wholesale_only', 'retail_wholesale'];

export function buildPricedProductVariations(
  entitiesState: EntitiesState,
  wholesalerGroup: WholesalerGroup
): PricedProductVariation[] {
  // Collect the IDs of all BPPTs
  const bpptIds = Object.keys(entitiesState.bakery_product_product_templates);

  // Denormalize those BPPTs, hydrating their associations.
  let bppts: BakeryProductProductTemplate[] = denormalize(
    bpptIds,
    [bakeryProductProductTemplateSchema],
    entitiesState
  );

  // Filter out any BPPTs which are tied to archived (Bakery)Products.
  bppts = bppts
    .filter(bppt => !bppt.bakery_product.archived)
    .filter(bppt => wholesalerProductStoreTypes.includes(bppt.bakery_product.store_type));

  // Flatten the BPPTs into PricedProductVariations.
  let pricedProductVariations: PricedProductVariation[] = bppts.map(bppt => {
    return {
      bakery_product_product_template_id: bppt.id,
      product_name: bppt.bakery_product.name,
      product_template_name: bppt.bakery_product_template.name,
      unit_price: bppt.bakery_product_template.unit_price,
      order_minimum: 0
    }
  });

  // Create a map of all WholesalerProductOverrides that belong to the
  // current WholesalerGroup.
  const productOverridesMap: {[key: string]: WholesalerProductOverride} = wholesalerGroup.wholesaler_product_overrides
    .reduce((map, curr) => {
      return {
        ...map,
        [curr.bakery_product_product_template_id]: curr
      };
    }, {});

  // Convert the wholesaler's default discount (as integer percent) into
  // a decimal value.
  const defaultDiscount = (100 - wholesalerGroup.default_discount) / 100;

  // For each PricedProductVariation, attempt to match that with a
  // WholesalerProductOverride (matching on shared BPPT id).
  // If there's a match, update the PricedProductVariation with the
  // overridden price. If there is no match, apply the group discount.
  pricedProductVariations = pricedProductVariations
    .map(pricedProductVariation => {
      const matchingOverride = productOverridesMap[pricedProductVariation.bakery_product_product_template_id];

      if (matchingOverride == null) {
        return {
          ...pricedProductVariation,
          unit_price: pricedProductVariation.unit_price * defaultDiscount,
        };
      }

      return {
        ...pricedProductVariation,
        unit_price: matchingOverride.unit_price,
        order_minimum: matchingOverride.order_minimum
      }
    })

  // Sort by product and then product template name.
  pricedProductVariations = pricedProductVariations.sort((pricedVarA, pricedVarB) => {
    const compareResult = compareStrings(pricedVarA.product_name, pricedVarB.product_name);

    if (compareResult != 0) return compareResult;

    return compareStrings(pricedVarA.product_template_name, pricedVarB.product_template_name);
  });

  return pricedProductVariations;
}
