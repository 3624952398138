interface AvailabilityRange {
  available_range_start_day: number;
  available_range_start_month: number;
  available_range_end_day: number;
  available_range_end_month: number;
}

interface MonthDay {
  month: number;
  day: number;
}

export const isWithinAvailabilityRange = (date: Date, availabilityRange: AvailabilityRange): boolean => {
  const monthDayS = JSON.stringify({ month: date.getMonth() + 1, day: date.getDate() });
  return !!monthDayRange(availabilityRange).find(x => JSON.stringify(x) === monthDayS);
};

const monthDayRange = (availabilityRange: AvailabilityRange): MonthDay[] => {
  const start = {
    month: availabilityRange.available_range_start_month,
    day: availabilityRange.available_range_start_day
  };
  const end = {
    month: availabilityRange.available_range_end_month,
    day: availabilityRange.available_range_end_day
  };
  return monthDayRangeHelp(start, end, []);
}

const monthDayRangeHelp = (current: MonthDay, end: MonthDay, acc: MonthDay[]): MonthDay[] => {
  if (current.day === end.day && current.month === end.month) return acc.concat(current);
  if (current.month === 13) return monthDayRangeHelp({ month: 1, day: 1 }, end, acc);
  if (current.day === 32) return monthDayRangeHelp({ month: current.month + 1, day: 1 }, end, acc);
  return monthDayRangeHelp({ month: current.month, day: current.day + 1 }, end, acc.concat(current));
}

export const wrappingRange = <Type>({ from, to, min, max }: { from: Type, to: Type, min: Type, max: Type }): Type[] => {
  if (from <= to) {
    return range(from, to);
  } else {
    return range(from, max).concat(range(min, to));
  }
};

const range = <Type>(from: Type, to: Type): Type[] => {
  const go = z => {
    if (z > to) { return []; }
    return [z].concat(go(z + 1));
  };
  return go(from);
};
