import { Component, Input, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import * as moment from 'moment/moment';
import { Actions } from 'app/store/actions';
import { User } from 'app/store/user';
import { BakerySubscriptionStatus } from 'app/store/bakery-subscription-status';
import { UsersEffects } from 'app/store/effects/users.effects';
import { BakeryMgmtEffects } from 'app/store/effects/bakery-mgmt.effects';
import { ModalComponent } from 'app/shared/components/modals/modal.component';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/app-state';

@Component({
  selector: 'bakery-mgmt-header-nav',
  template: require('./header-nav.component.html'),
})
export class BakeryMgmtHeaderNavComponent implements OnInit, OnDestroy {
  @ViewChild(ModalComponent) modal: ModalComponent;
  isClassMobileMenuActive = false;
  routeToWholesalerNewOrder = false;
  isWholesalerActive$: Observable<boolean>;
  isTrial$: Observable<boolean>;
  remainingTrialDuration$: Observable<string>;

  isGracePeriod$: Observable<boolean>;
  remainingGracePeriodDuration$: Observable<string>;

  private routerEventsSub: Subscription;

  constructor(
    private usersEffects: UsersEffects,
    private router: Router,
    private bkryMgmtEffects: BakeryMgmtEffects,
    private store: Store<AppState>,
  ) { }

  ngOnInit() {
    // NOTE: Not sure if this is the best method.
    // My thoughts, listen for route change, if mobile menu active remove class on change?
    this.routerEventsSub = this.router.events.subscribe((val) => {
      if (this.isClassMobileMenuActive) {
        this.isClassMobileMenuActive = !this.isClassMobileMenuActive
      }
    });

    this.isTrial$ = this.bkryMgmtEffects.currentBakery$
      .filter(bakery => bakery != null)
      .map(bakery => {
        return ((<BakerySubscriptionStatus>bakery.bakery_subscription_status).status === 'trialing');
      })
      .startWith(false);

    this.isWholesalerActive$ = this.bkryMgmtEffects.currentBakery$
      .filter(bakery => bakery != null)
      .map(bakery => {
        return ((<BakerySubscriptionStatus>bakery.bakery_subscription_status).status === 'trialing' || (<any>bakery.bakery_subscription_status).feature_modules.some(module => module.type_name === 'online_wholesale_store'));
      })
      .startWith(false);

    this.remainingTrialDuration$ = this.bkryMgmtEffects.currentBakery$
      .filter(bakery => bakery != null)
      .map(bakery => {
        const endAtMoment = moment((<BakerySubscriptionStatus>bakery.bakery_subscription_status).end_at),
              nowMoment = moment();

        const hoursRemaining = endAtMoment.diff(nowMoment, 'hours');

        if (hoursRemaining > 24) {
          return Math.ceil(hoursRemaining / 24) + ' Days';
        }

        return hoursRemaining + ' Hours';
      });

    this.isGracePeriod$ = this.bkryMgmtEffects.currentBakery$
      .filter(bakery => bakery != null)
      .map(bakery => {
        return ((<BakerySubscriptionStatus>bakery.bakery_subscription_status).status === 'past_due');
      })
      .startWith(false);

    this.remainingGracePeriodDuration$ = this.bkryMgmtEffects.currentBakery$
      .filter(bakery => bakery != null)
      .map(bakery => {
        const endAtMoment = moment((<BakerySubscriptionStatus>bakery.bakery_subscription_status).grace_period_end_at),
              nowMoment = moment();

        const hoursRemaining = endAtMoment.diff(nowMoment, 'hours');

        if (hoursRemaining > 24) {
          return Math.ceil(hoursRemaining / 24) + ' Days';
        }

        return hoursRemaining + ' Hours';
      });
  }

  ngOnDestroy() {
    this.routerEventsSub.unsubscribe();
  }

  get currentUser$(): Observable<User> {
    return this.usersEffects.currentUser$;
  }

  onClickMobileMenuActive() {
    this.isClassMobileMenuActive = !this.isClassMobileMenuActive
  }

  onClickSignOut() {
    this.usersEffects.requestUserSignOut();
  }

  onClickStartNewOrder() {
    if (this.isClassMobileMenuActive) {
      this.isClassMobileMenuActive = !this.isClassMobileMenuActive;
    }
    this.modal.openModal();
  }

  onClickHandleRouteToWholesalerNewOrder(shouldRouteToWholesaler) {
    this.routeToWholesalerNewOrder = shouldRouteToWholesaler
  }

  onConfirmStartNewOrder() {
    this.store.dispatch({
      type: Actions.REQUEST_DELETE_EMPLOYEE_CART,
    });
    setTimeout(() => {
      if (this.routeToWholesalerNewOrder) {
        this.router.navigate(['/bakery_mgmt/new_wholesaler_order']);
      } else {
        this.router.navigate(['/bakery_mgmt/new_order']);
      }
    }, 1000)
  }
}
