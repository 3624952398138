import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { Actions } from 'app/store/actions';
import {
  SimplifiedResourceRequestState,
  updateResourceLoading,
  updateResourceComplete
} from 'app/store/reducers/request-states';

export interface State extends SimplifiedResourceRequestState<number[]> {
  query_params: {
    name?: string;
  };
  create: SimplifiedResourceRequestState<number>;
}

export const initialState: State = {
  isLoading: false,
  result: null,
  query_params: {},
  create: {isLoading: false, result: null}
};

export function reducer(
  state: State = initialState,
  action: UnsafeAction
): State {
  switch (action.type) {
    case Actions.CHANGE_BKRY_MGMT_WHOLESALER_GROUPS_FILTERS:
      return {
        ...state,
        query_params: {
          ...state.query_params,
          ...action.payload,
        },
      };

    case Actions.REQUEST_GET_BKRY_MGMT_WHOLESALER_GROUPS:
      return updateResourceLoading(state);

    case Actions.REQUEST_GET_BKRY_MGMT_WHOLESALER_GROUPS_SUCCESS:
      return updateResourceComplete(state, action.payload);

    case Actions.REQUEST_CREATE_BKRY_WHOLESALER_GROUP:
      return {
        ...state,
        create: updateResourceLoading(state.create)
      };

    case Actions.REQUEST_CREATE_BKRY_WHOLESALER_GROUP_SUCCESS:
      return {
        ...state,
        create: updateResourceComplete(state.create, action.payload)
      };

    default:
      return state;
  }
}
