import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { Store } from '@ngrx/store';
import { Angular2TokenService } from 'app/angular2-token/angular2-token.service';
import { Actions as NgRxActions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';
import { normalize, schema } from 'normalizr';

import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { AppState } from 'app/store/app-state';
import { EntitiesState } from 'app/store/entities-state';
import { Actions } from 'app/store/actions';
import { subscriptionPlanSchema } from 'app/store/schema/subscription-plan';
import {
  bakeryProductSchema,
  bakeryProductTemplateSchema
} from 'app/store/schema/default-schemata';
import { BakeryMgmtEffects } from 'app/store/effects/bakery-mgmt.effects';
import { BakeryProductTemplate } from 'app/store/bakery-product-template';
import { Bakery } from 'app/store/bakery';
import {
  basicRequestHandler,
  addEntityRequestHandler
} from 'app/store/effects/helpers';

export interface MasterUiState {
  isLoading: boolean;
}

@Injectable()
export class BakeryMgmtProductTemplatesEffects {
  masterUiStates$: Observable<MasterUiState>;

  constructor(
    private actions$: NgRxActions,
    private tokenService: Angular2TokenService,
    private store: Store<AppState>,
    private bakeryMgmtEffects: BakeryMgmtEffects
  ) {}

  requestGetBakeryProductTemplates() {
    this.store.dispatch({
      type: Actions.REQUEST_GET_BAKERY_PRODUCT_TEMPLATES
    });
  }

  requestGetBakeryProductTemplate(id: number) {
    this.store.dispatch({
      type: Actions.REQUEST_GET_BAKERY_PRODUCT_TEMPLATE,
      payload: id
    });
  }

  requestGetBakeryProductTemplateDetails(id: number) {
    this.store.dispatch({
      type: Actions.REQUEST_GET_BAKERY_PRODUCT_TEMPLATE_DETAILS,
      payload: id
    });
  }

  requestCreateBakeryProductTemplate(newTemplateDetails: any) {
    this.store.dispatch({
      type: Actions.REQUEST_CREATE_BAKERY_PRODUCT_TEMPLATE,
      payload: newTemplateDetails
    });
  }

  requestUpdateBakeryProductTemplate(
    productTemplate: BakeryProductTemplate,
    updateDetailsPayload: any
  ) {
    this.store.dispatch({
      type: Actions.REQUEST_UPDATE_BAKERY_PRODUCT_TEMPLATE,
      payload: {
        id: productTemplate.id,
        payload: updateDetailsPayload
      }
    });
  }

  requestDeleteBakeryProductTemplate(productTemplate: BakeryProductTemplate) {
    this.store.dispatch({
      type: Actions.REQUEST_DELETE_BAKERY_PRODUCT_TEMPLATE,
      payload: productTemplate.id
    });
  }

  private currentBakeryLatestMap(
    action: UnsafeAction
  ): Observable<{ action: UnsafeAction; bakery: Bakery }> {
    return this.bakeryMgmtEffects.currentBakery$
      .filter(bakery => bakery != null)
      .map(bakery => ({ action, bakery }))
      .take(1);
  }

  @Effect()
  getProductTemplatesEffects$ = this.actions$
    .ofType(Actions.REQUEST_GET_BAKERY_PRODUCT_TEMPLATES)
    .switchMap(this.currentBakeryLatestMap.bind(this))
    .switchMap(({ action, bakery }) => {
      return addEntityRequestHandler(
        this.tokenService.get(
          `/api/bakeries/${bakery.id}/bakery_product_templates`
        ),
        [bakeryProductTemplateSchema],
        Actions.REQUEST_GET_BAKERY_PRODUCT_TEMPLATES
      );
    })
    .share();

  @Effect()
  getBakeryProductTemplateEffects$ = this.actions$
    .ofType(Actions.REQUEST_GET_BAKERY_PRODUCT_TEMPLATE)
    .switchMap(this.currentBakeryLatestMap.bind(this))
    .switchMap(({ action, bakery }) => {
      return addEntityRequestHandler(
        this.tokenService.get(
          `/api/bakeries/${bakery.id}/bakery_product_templates/${
            action.payload
          }`
        ),
        bakeryProductTemplateSchema,
        Actions.REQUEST_GET_BAKERY_PRODUCT_TEMPLATE
      );
    })
    .share();

  @Effect()
  getBakeryProductTemplateDetailsEffects$ = this.actions$
    .ofType(Actions.REQUEST_GET_BAKERY_PRODUCT_TEMPLATE_DETAILS)
    .switchMap(this.currentBakeryLatestMap.bind(this))
    .switchMap(({ action, bakery }) => {
      return Observable.forkJoin(
        basicRequestHandler(
          this.tokenService.get(
            `/api/bakeries/${bakery.id}/bakery_product_templates/${
              action.payload
            }`
          ),
          bakeryProductTemplateSchema
        ),
        basicRequestHandler(
          this.tokenService.get(`/api/bakeries/${bakery.id}/bakery_products`),
          [bakeryProductSchema]
        )
      );
    })
    .mergeMap(actions => {
      return Observable.from([
        ...actions,
        { type: Actions.REQUEST_GET_BAKERY_PRODUCT_TEMPLATE_DETAILS_SUCCESS }
      ]);
    })
    .share();

  @Effect()
  createBakeryProductTemplateEffects$ = this.actions$
    .ofType(Actions.REQUEST_CREATE_BAKERY_PRODUCT_TEMPLATE)
    .switchMap(this.currentBakeryLatestMap.bind(this))
    .switchMap(({ action, bakery }) => {
      return addEntityRequestHandler(
        this.tokenService.post(
          `/api/bakeries/${bakery.id}/bakery_product_templates`,
          JSON.stringify(action.payload)
        ),
        bakeryProductTemplateSchema,
        Actions.REQUEST_CREATE_BAKERY_PRODUCT_TEMPLATE
      );
    })
    .share();

  @Effect()
  updateBakeryProductTemplateEffects$ = this.actions$
    .ofType(Actions.REQUEST_UPDATE_BAKERY_PRODUCT_TEMPLATE)
    .switchMap(this.currentBakeryLatestMap.bind(this))
    .switchMap(({ action, bakery }) => {
      return addEntityRequestHandler(
        this.tokenService.put(
          `/api/bakeries/${bakery.id}/bakery_product_templates/${
            action.payload.id
          }`,
          JSON.stringify(action.payload.payload)
        ),
        bakeryProductTemplateSchema,
        Actions.REQUEST_UPDATE_BAKERY_PRODUCT_TEMPLATE
      );
    })
    .share();

  // TODO: This isn't supported by addEntityRequestHandler yet, as it's a
  // REMOVE_ENTITY action generator
  @Effect()
  deleteBakeryProductTemplateEffects$ = this.actions$
    .ofType(Actions.REQUEST_DELETE_BAKERY_PRODUCT_TEMPLATE)
    .switchMap(this.currentBakeryLatestMap.bind(this))
    .switchMap(({ action, bakery }) => {
      return this.tokenService
        .delete(
          `/api/bakeries/${bakery.id}/bakery_product_templates/${
            action.payload
          }`
        )
        .map((response: Response) => response.json())
        .switchMap(() => {
          return Observable.from([
            {
              type: Actions.REMOVE_ENTITY,
              payload: {
                typeKey: 'bakery_product_templates',
                entityKey: action.payload
              }
            },
            {
              type: Actions.REQUEST_DELETE_BAKERY_PRODUCT_TEMPLATE_SUCCESS
            }
          ]);
        })
        .catch(error => {
          const errorPayload =
            typeof error.json === 'function' ? error.json() : error;

          return Observable.of({
            type: Actions.REQUEST_DELETE_BAKERY_PRODUCT_TEMPLATE_ERROR,
            payload: error
          });
        });
    })
    .share();
}
