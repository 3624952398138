import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'simple-address',
  template: `
    <ng-template [ngIf]="breakStreet">
      {{ combinedStreetUnit }}<br/>{{ address.city }}, {{ address.state }}, {{ address.zip }}
    </ng-template>
    <ng-template [ngIf]="!breakStreet">
      {{ combinedStreetUnit }}, {{ address.city }}, {{ address.state }} {{ address.zip }}
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleAddressComponent {
  @Input() breakStreet = false;
  @Input() address: {
    street: string;
    unit: string;
    city: string;
    state: string;
    zip: string;
  };

  get hasUnit(): boolean {
    return !!this.address.unit && typeof this.address.unit === 'string' && this.address.unit.trim().length > 0;
  }

  get combinedStreetUnit(): string {
    if (this.hasUnit) {
      return `${this.address.street} #${this.address.unit}`
    }

    return this.address.street;
  }
}
