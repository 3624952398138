import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { Store } from '@ngrx/store';
import { Angular2TokenService } from 'app/angular2-token/angular2-token.service';
import { Actions as NgRxActions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';
import { normalize, schema } from 'normalizr';

import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { AppState } from 'app/store/app-state';
import { EntitiesState } from 'app/store/entities-state';
import { Actions } from 'app/store/actions';
import { subscriptionPlanSchema } from 'app/store/schema/subscription-plan';
import {
  bakeryProductSchema,
  productCategoryShallowSchema,
 } from 'app/store/schema/default-schemata';
import { BakeryMgmtEffects } from 'app/store/effects/bakery-mgmt.effects';
import { BakeryProduct } from 'app/store/bakery-product';
import { Bakery } from 'app/store/bakery';
import { addEntityRequestHandler, basicRequestHandler } from 'app/store/effects/helpers';
import * as selectors from 'app/store/selectors';

// FIXME: This is a nasty hack around the fact that the bakery products
// retrieved here aren't well handled in respect to data normalization.
// This request and state infrastructure needs to be replaced by the streamlined
// framework in the new RequestState/Effects components.
const hackBakeryProductSchema = new schema.Entity('bakery_products');

export interface MasterUiState {
  isLoading: boolean;
}

@Injectable()
export class BakeryMgmtProductsEffects {
  masterUiStates$: Observable<MasterUiState>;

  constructor(
    private actions$: NgRxActions,
    private tokenService: Angular2TokenService,
    private store: Store<AppState>,
    private bakeryMgmtEffects: BakeryMgmtEffects,
  ) { }

  requestGetBakeryProducts(archiveQueryParam: boolean) {
    this.store.dispatch({
      type: Actions.REQUEST_GET_BAKERY_PRODUCTS,
      payload: {
        archived: archiveQueryParam
      }
    });
  }

  requestGetBakeryProducts2(query?: string) {
    this.store.dispatch({
      type: Actions.REQUEST_GET_BAKERY_PRODUCTS2,
      payload: { query }
    });
  }

  requestGetBakeryProduct(id?: number) {
    this.store.dispatch({
      type: Actions.REQUEST_GET_BAKERY_PRODUCT,
      payload: id,
    });
  }

  requestCreateBakeryProduct(bakeryProductDetails: any) {
    this.store.dispatch({
      type: Actions.REQUEST_CREATE_BAKERY_PRODUCT,
      payload: bakeryProductDetails,
    });
  }

  requestUpdateBakeryProduct(bakeryProduct: BakeryProduct, bakeryProductDetails: any) {
    this.store.dispatch({
      type: Actions.REQUEST_UPDATE_BAKERY_PRODUCT,
      payload: {
        id: bakeryProduct.id,
        payload: bakeryProductDetails,
      },
    });
  }

  requestDeleteBakeryProduct(bakeryProduct: BakeryProduct) {
    this.store.dispatch({
      type: Actions.REQUEST_DELETE_BAKERY_PRODUCT,
      payload: bakeryProduct.id,
    });
  }

  private currentBakeryLatestMap(action: UnsafeAction): Observable<{action: UnsafeAction, bakery: Bakery}> {
    return this.bakeryMgmtEffects.currentBakery$
      .filter(bakery => bakery != null)
      .map(bakery => ({action, bakery}))
      .take(1);
  }

  @Effect() getProductsEffects$ = this.actions$
    .ofType(Actions.REQUEST_GET_BAKERY_PRODUCTS)
    .switchMap(this.currentBakeryLatestMap.bind(this))
    .switchMap(({action, bakery}) => {
      return addEntityRequestHandler(
        this.tokenService.get(
          `/api/bakeries/${bakery.id}/bakery_products?archived=${action.payload.archived}`
        ),
        // FIXME: this is a temporary hack, should be replaced soon.
        [hackBakeryProductSchema],
        Actions.REQUEST_GET_BAKERY_PRODUCTS
      );
    })
    .share();

  @Effect() getProductsEffects2$ = this.actions$
    .ofType(Actions.REQUEST_GET_BAKERY_PRODUCTS2)
    .combineLatest(
      this.store.select(selectors.getBakeryMgmtCurrentBakeryId),
      (action: UnsafeAction, bakeryId) => ({ action, bakeryId })
    )
    .skipWhile(({ bakeryId }) => !bakeryId)
    .switchMap(({ action, bakeryId }) => {
      const query = [
        'archived=false',
        action.payload.query ? `search_text=${encodeURIComponent(action.payload.query)}` : ''
      ].filter(x => x.length > 0).join('&');
      return this.tokenService
        .get(`/api/bakeries/${bakeryId}/bakery_products?${query}`)
        .map((response: Response) => response.json())
        .switchMap(response => {
          return Observable.of({ type: Actions.REQUEST_GET_BAKERY_PRODUCTS_SUCCESS2, payload: response });
        })
        .catch(() => {
          return Observable.of({ type: Actions.REQUEST_GET_BAKERY_PRODUCTS_ERROR2 });
        });
    })
    .share();

  @Effect() getProductEffects$ = this.actions$
    .ofType(Actions.REQUEST_GET_BAKERY_PRODUCT)
    .switchMap(this.currentBakeryLatestMap.bind(this))
    .switchMap(({action, bakery}) => {
      const requests = [
        basicRequestHandler(
          this.tokenService.get(
            `/api/bakeries/${bakery.id}/product_categories`
          ),
          [productCategoryShallowSchema],
        )
      ];

      if (action.payload != null) {
        requests.unshift(
          basicRequestHandler(
            this.tokenService.get(
              `/api/bakeries/${bakery.id}/bakery_products/${action.payload}`
            ),
            bakeryProductSchema,
          )
        );
      }

      return Observable.forkJoin(...requests);
    })
    .mergeMap((actions) => {
      console.log(`actions:`, actions);

      return Observable.from([
        ...actions,
        {type: Actions.REQUEST_GET_BAKERY_PRODUCT_SUCCESS}
      ]);
    })
    .share();

  @Effect() createBakeryProductEffects$ = this.actions$
    .ofType(Actions.REQUEST_CREATE_BAKERY_PRODUCT)
    .switchMap(this.currentBakeryLatestMap.bind(this))
    .switchMap(({action, bakery}) => {
      return addEntityRequestHandler(
        this.tokenService.post(
          `/api/bakeries/${bakery.id}/bakery_products`,
          JSON.stringify({
            ...action.payload,
            fulfillment_option_delivery: true,
            fulfillment_option_pickup: true,
            fulfillment_option_ship: true
          })
        ),
        bakeryProductSchema,
        Actions.REQUEST_CREATE_BAKERY_PRODUCT
      );
    })
    .share();

  @Effect() updateBakeryProductEffects$ = this.actions$
    .ofType(Actions.REQUEST_UPDATE_BAKERY_PRODUCT)
    .switchMap(this.currentBakeryLatestMap.bind(this))
    .switchMap(({action, bakery}) => {
      return addEntityRequestHandler(
        this.tokenService.put(
          `/api/bakeries/${bakery.id}/bakery_products/${action.payload.id}`,
          JSON.stringify({
            ...action.payload.payload,
            fulfillment_option_delivery: true,
            fulfillment_option_pickup: true,
            fulfillment_option_ship: true
          })
        ),
        bakeryProductSchema,
        Actions.REQUEST_UPDATE_BAKERY_PRODUCT
      );
    })
    .share();

  // TODO: This isn't supported by addEntityRequestHandler yet, as it's a
  // REMOVE_ENTITY action generator
  @Effect() deleteBakeryProductEffects$ = this.actions$
    .ofType(Actions.REQUEST_DELETE_BAKERY_PRODUCT)
    .switchMap(this.currentBakeryLatestMap.bind(this))
    .switchMap(({action, bakery}) => {
      return this.tokenService
        .delete(
          `/api/bakeries/${bakery.id}/bakery_products/${action.payload}`,
        )
        .map((response: Response) => response.json())
        .switchMap(() => {
          return Observable.from([
            {
              type: Actions.REMOVE_ENTITY,
              payload: {
                typeKey: 'bakery_products',
                entityKey: action.payload,
              },
            },
            {
              type: Actions.REQUEST_DELETE_BAKERY_PRODUCT_SUCCESS,
            },
          ]);
        })
        .catch((error) => {
          const errorPayload = typeof error.json === 'function' ? error.json() : error;

          return Observable.of({
            type: Actions.REQUEST_DELETE_BAKERY_PRODUCT_ERROR,
            payload: error,
          });
        });
    })
    .share();
}
