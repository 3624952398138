import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/app-state';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { Subject } from 'rxjs/Subject';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

import { BakeryProduct } from 'app/store/bakery-product';
import { BakeryAttributeVariant } from 'app/store/bakery-attribute-variant';
import { BakeryProductTemplate } from 'app/store/bakery-product-template';
import * as selectors from 'app/store/selectors';
import { BakeryCart } from 'app/store/bakery-cart';
import { OrderItem } from 'app/store/order-item';
import { Actions as NgRxActions } from '@ngrx/effects';
import { Actions } from 'app/store/actions';
import { BakeryProductProductTemplate } from 'app/store/bakery-product-product-template';
import { BakeryProductAttribute } from 'app/store/bakery-product-attribute';
import { User } from 'app/store/user';
import { AlertService } from 'app/shared/components/alerts/alert.service';
import { WholesalerProductOverride } from 'app/store/wholesaler-product-override';
import { rebuildControls } from 'app/rebuild-controls';

export interface UiState {
  bakeryProduct: BakeryProduct;
}

@Component({
  selector: 'bakery-mgmt-new-order-product-selection-list-item',
  template: require('./list-item.component.html'),
})
export class BakeryMgmtNewOrderProductSelectionListItemComponent implements OnInit, OnDestroy {
  @Input() bakeryProduct: BakeryProduct;
  uiState$: Observable<UiState>;
  private submitForm$ = new Subject<any>();
  private templateChangesSub: Subscription;
  @Input() selectedCustomer: User;
  @Input() employee: boolean;
  @Input() wholesaler: boolean;
  private submitFormSub: Subscription;
  private selectedTemplateSub: Subscription;
  selectedTemplate$ = new BehaviorSubject<BakeryProductProductTemplate>(null);
  form: FormGroup;

  constructor(
    private store: Store<AppState>,
    private actions$: NgRxActions,
    private formBuilder: FormBuilder,
    private alertService: AlertService
  ) {
    this.form = this.formBuilder.group({
      'template': new FormControl(null, [Validators.required]),
      'order_qty': new FormControl(1, [Validators.required, Validators.min(1)]),
      'inscription': new FormControl(null),
      'open_charge': new FormControl(0, [Validators.min(0)]),
      'open_charge_description': new FormControl(''),
      'notes': new FormControl(''),
    });
  }

  lowPrice(bakeryProduct) {
    return this.priceArray(bakeryProduct).reverse()[0];
  }

  highPrice(bakeryProduct) {
    return this.priceArray(bakeryProduct)[0];
  }

  priceArray(bakeryProduct) {
    return bakeryProduct.bakery_product_templates
      .map(x => x.unit_price)
      .sort((a, b) => b - a);
  }

  ngOnInit() {
    this.uiState$ = Observable.of({ bakeryProduct: this.bakeryProduct });

    this.selectedTemplateSub = this.uiState$
      .withLatestFrom(
        this.selectedTemplate$,
        (uiState, selectedTemplate) => ({uiState, selectedTemplate})
      )
      .subscribe(({uiState, selectedTemplate}) => {
        if (!uiState.bakeryProduct) {
          return;
        }
        if (selectedTemplate == null && uiState.bakeryProduct.bakery_product_templates.length === 1) {
          const defaultTemplate = <BakeryProductProductTemplate>uiState.bakeryProduct.bakery_product_product_templates[0];
          this.form.patchValue({ template: defaultTemplate.id }, { emitEvent: false });
          this.selectedTemplate$.next(defaultTemplate);
        }
      });

    this.templateChangesSub = this.form.get('template').valueChanges
      .withLatestFrom(
        this.uiState$,
        (templateValue, uiState) => ({ templateValue, uiState })
      )
      .subscribe(({templateValue, uiState}) => {
        this.updateBppt(templateValue, uiState.bakeryProduct, false);
      });

    if (this.bakeryProduct.bakery_product_product_templates.length === 1) {
      const bppt = this.bakeryProduct.bakery_product_product_templates[0] as BakeryProductProductTemplate;

      this.updateBppt(bppt.id, this.bakeryProduct, true);
    } else {
      rebuildControls(
        this.form,
        this.formBuilder,
        'attribute_variants',
        this.bakeryProduct.bakery_product_attributes as BakeryProductAttribute[]
      );
    }

    this.submitFormSub = this.submitForm$
      .skipWhile(() => !this.form.valid)
      .withLatestFrom(
        this.uiState$,
        this.store.select(this.employee ? selectors.getEmployeeCart : selectors.getCustomerCart),
        (_, uiState, currentCart) => ({uiState, currentCart})
      )
      .subscribe(combined => {
        const formValue = this.form.value;
        const currentCart = <BakeryCart>combined.currentCart;
        const uiState = combined.uiState;

        const orderItemsAttributes: any = {
          bakery_product_product_template_id: formValue.template,
          quantity: parseInt(formValue.order_qty, 10),
          open_charge: parseInt(formValue.open_charge, 10),
          open_charge_description: formValue.open_charge_description,
          notes: formValue.notes,
          tax_exempt: !uiState.bakeryProduct.taxable
        };

        if (currentCart != null) {
          const matchingTemplateOrderItem = (<OrderItem[]>currentCart.order_items).find(orderItem => {
            const templateVariants = (<any>orderItem.meta).template_variants.map(variant => variant.bakery_attribute_variant.id);
            const attributeVariants = (<any>orderItem.meta).attribute_variants.map(variant => variant.bakery_attribute_variant.id);
            const inscriptions = (<any>orderItem.meta).inscription;
            const isTemplateVariantsEqual = !formValue.template_variants ||
                                            formValue.template_variants.map(str => str.split('-')).map(([_, variant]) => parseInt(variant, 10)).every(id => templateVariants.includes(id));
            const isAttributeVariantsEqual = !formValue.attribute_variants ||
                                             formValue.attribute_variants.map(str => str.split('-')).map(([_, variant]) => parseInt(variant, 10)).every(id => attributeVariants.includes(id));
            const isInscriptionEqual = !formValue.inscription || formValue.inscription === inscriptions;
            return (<BakeryProductProductTemplate>orderItem.bakery_product_product_template).id === formValue.template &&
              isTemplateVariantsEqual && isAttributeVariantsEqual && isInscriptionEqual;
          });

          if (matchingTemplateOrderItem != null) {
            orderItemsAttributes.id = matchingTemplateOrderItem.id;
            orderItemsAttributes.quantity = orderItemsAttributes.quantity + matchingTemplateOrderItem.quantity;
          }
        }

        orderItemsAttributes.template_variants = (formValue.template_variants || []).map(str => str.split('-')).map(([bpa, variant]) => ({ bpa, variant }));
        orderItemsAttributes.attribute_variants = (formValue.attribute_variants || []).map(str => str.split('-')).map(([bpa, variant]) => ({ bpa, variant }));
        orderItemsAttributes.meta = {inscription: formValue.inscription};

        if (this.wholesaler && this.selectedCustomer) {
          const groupId = this.selectedCustomer.wholesaler_group.id;

          const template = (<any>uiState.bakeryProduct.bakery_product_product_templates)
            .find(tpl => tpl.id === orderItemsAttributes.bakery_product_product_template_id);

          if (template.wholesaler_product_overrides) {
            const override = template.wholesaler_product_overrides
              .find(overr => overr.wholesaler_group_id === groupId && template.id === overr.bakery_product_product_template_id);

            if (override && override.order_minimum > orderItemsAttributes.quantity) {
              const messageType = 'warning';
              const messageContent = `Order minimum for ${template.bakery_product.name}
                                     (${template.bakery_product_template.name}) is ${override.order_minimum}`;
              return this.alertService[messageType](messageContent);
            }
          }
        }

        this.store.dispatch({
          type: this.employee ? Actions.ADD_PRODUCT_TEMPLATE_TO_EMPLOYEE_CART : Actions.ADD_PRODUCT_TEMPLATE_TO_WHOLESALE_CART,
          payload: {
            employee_order: this.employee,
            wholesaler_order: this.wholesaler,
            customer_id: this.selectedCustomer && this.wholesaler ? this.selectedCustomer.id : null,
            order_items_attributes: [orderItemsAttributes],
          }
        });
      });
  }

  private updateBppt(
    bpptId: number,
    bakeryProduct: BakeryProduct,
    updateTemplateFormInput: boolean = false
  ) {
    if (bpptId == null) {
      return;
    }

    const bppt = (<BakeryProductProductTemplate[]>bakeryProduct.bakery_product_product_templates)
      .find(template => template.id === bpptId);

    const template: BakeryProductTemplate = bppt.bakery_product_template;

    let order_qty = this.form.get('order_qty').value || 1;

    if (this.wholesaler && this.selectedCustomer && bppt.wholesaler_product_overrides) {
      const wholesaler_product_override = (<BakeryProductProductTemplate>bppt)
        .wholesaler_product_overrides
        .find((override: WholesalerProductOverride) => {
          return override.wholesaler_group_id === this.selectedCustomer.wholesaler_group.id
                 && override.bakery_product_product_template_id === bppt.id;
        });

      order_qty = wholesaler_product_override.order_minimum > order_qty ? wholesaler_product_override.order_minimum : order_qty;
    }

    this.form.controls['order_qty'].setValidators([Validators.required, Validators.min(order_qty)]);
    this.form.patchValue({ order_qty });

    if (updateTemplateFormInput) {
      this.form.patchValue({ template: bpptId }, { emitEvent: false });
    }

    if (bakeryProduct.advanced) {
      rebuildControls(
        this.form,
        this.formBuilder,
        'template_variants',
        bppt.bakery_product_template.bakery_product_attributes as BakeryProductAttribute[]
      );
      rebuildControls(
        this.form,
        this.formBuilder,
        'attribute_variants',
        bakeryProduct.bakery_product_attributes as BakeryProductAttribute[]
      );
    }

    this.selectedTemplate$.next(bppt);
  }

  onSubmitOrderForm() {
    this.submitForm$.next();
  }

  ngOnDestroy() {
    this.templateChangesSub.unsubscribe();
    this.submitFormSub.unsubscribe();
    this.selectedTemplateSub.unsubscribe();
  }
}
