import { Component, Input } from '@angular/core';

@Component({
  selector: 'sidebar-layout',
  template: `
    <div gridRow>
      <div gridCol s="4" [m]="mediumSidebarWidth" l="4">
        <ng-content
          select="[data-layout-sidebar]"
        ></ng-content>
      </div>
      <div gridCol s="4" [m]="mediumMainWidth" l="8">
        <ng-content
          select="[data-layout-main]"
        ></ng-content>
      </div>
      <div gridCol s="4" m="9" l="0">
        <ng-content
          select="[data-layout-footer]"
        ></ng-content>
      </div>
    </div>
  `,
})
export class SidebarLayoutComponent {
  @Input() isMediumStacked = false;

  get mediumSidebarWidth(): number {
    return this.isMediumStacked ? 9 : 4;
  }

  get mediumMainWidth(): number {
    return this.isMediumStacked ? 9 : 5;
  }
}
