import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { MasterDetailUiState } from 'app/store/bakery-mgmt-ui-state';
import { ResourceRequestState, DetailResourceRequestState } from 'app/store/reducers/request-states';
import { Actions } from 'app/store/actions';
import { BakeryCart } from 'app/store/bakery-cart';
import * as bakeryOrderState from 'app/store/reducers/bakery-cust-ui-state/bakery-order-state.reducer';

export interface CartState extends DetailResourceRequestState<BakeryCart> { }

export interface BakeryWholesaleUiState {
  isLoading: boolean;
  cartState: CartState;
  bakeryOrderState: bakeryOrderState.State;
}


const INIT_BAKERY_CUST_STATE: BakeryWholesaleUiState = {
  isLoading: false,
  cartState: {
    detailId: null,
    state: null,
    result: null,
  },
  bakeryOrderState: bakeryOrderState.initialState,
};

export function bakeryWholesaleUiStateReducer(
  state: BakeryWholesaleUiState = INIT_BAKERY_CUST_STATE,
  action: UnsafeAction
): BakeryWholesaleUiState {
  state = {
    ...state,
    bakeryOrderState: bakeryOrderState.reducer(state.bakeryOrderState, action),
  };

  switch (action.type) {
    case Actions.REQUEST_GET_WHOLESALE_CART:
      return {
        ...state,
        cartState: {
          ...state.cartState,
          state: 'loading',
        }
      };

    case Actions.REQUEST_GET_CUST_CART_ERROR:
      return {
        ...state,
        cartState: {
          ...state.cartState,
          state: 'error',
          detailId: null,
        }
      };

    case Actions.REQUEST_GET_WHOLESALE_CART_SUCCESS:
    case Actions.REQUEST_UPDATE_WHOLESALE_CART_SUCCESS:
    case Actions.REQUEST_CREATE_WHOLESALE_CART_SUCCESS:
      const successBakeryCart = action.payload.bakery_carts != null ?
        Object.keys(action.payload.bakery_carts).map(key => action.payload.bakery_carts[key])[0]
        : null;

      return {
        ...state,
        cartState: {
          ...state.cartState,
          state: 'success',
          result: successBakeryCart,
          detailId: successBakeryCart != null ? successBakeryCart.id : null,
        },
      };

    case Actions.REQUEST_DELETE_WHOLESALE_CART_SUCCESS:
      return {
        ...state,
        cartState: {
          ...state.cartState,
          state: null,
          result: null,
          detailId: null,
        },
      };

    default:
      return state;
  }
}
