import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/app-state';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { Subject } from 'rxjs/Subject';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

import { BakeryOrder } from 'app/store/bakery-order';
import { BakeryProduct } from 'app/store/bakery-product';
import { BakeryProductTemplate } from 'app/store/bakery-product-template';
import { OrderItem } from 'app/store/order-item';
import { Actions } from 'app/store/actions';
import { BakeryProductProductTemplate } from 'app/store/bakery-product-product-template';
import { BakeryProductAttribute } from 'app/store/bakery-product-attribute';
import { BakeryMgmtOrderMgmtEffects } from 'app/store/effects/bakery-mgmt/bakery-mgmt-order-mgmt.effects';
import { rebuildControls } from 'app/rebuild-controls';

export interface UiState {
  bakeryProduct: BakeryProduct;
}

@Component({
  selector: 'order-mgmt-detail-edit-product-selection-list-item',
  template: require('./list-item.component.html'),
})
export class EditProductSelectionListItemComponent implements OnInit, OnDestroy {
  @Input() bakeryProduct: BakeryProduct;
  @Input() bakeryOrder: BakeryOrder;
  uiState$: Observable<UiState>;
  private submitForm$ = new Subject<any>();
  private templateChangesSub: Subscription;
  private submitFormSub: Subscription;
  private selectedTemplateSub: Subscription;
  selectedTemplate$ = new BehaviorSubject<BakeryProductProductTemplate>(null);
  form: FormGroup;

  constructor(
    private store: Store<AppState>,
    private formBuilder: FormBuilder,
    private orderEffects: BakeryMgmtOrderMgmtEffects,
  ) {
    this.form = this.formBuilder.group({
      'template': new FormControl(null, [Validators.required]),
      'order_qty': new FormControl(1, [Validators.required, Validators.min(1)]),
      'inscription': new FormControl(null),
      'open_charge': new FormControl(0, [Validators.min(0)]),
      'open_charge_description': new FormControl(''),
      'notes': new FormControl(''),
    });
  }

  lowPrice(bakeryProduct) {
    return this.priceArray(bakeryProduct).reverse()[0];
  }

  highPrice(bakeryProduct) {
    return this.priceArray(bakeryProduct)[0];
  }

  priceArray(bakeryProduct) {
    return bakeryProduct.bakery_product_templates
      .map(x => x.unit_price)
      .sort((a, b) => b - a);
  }

  ngOnInit() {
    this.uiState$ = Observable.of({ bakeryProduct: this.bakeryProduct });

    this.selectedTemplateSub = this.uiState$
      .withLatestFrom(
        this.selectedTemplate$,
        (uiState, selectedTemplate) => ({ uiState, selectedTemplate })
      )
      .subscribe(({ uiState, selectedTemplate }) => {
        if (!uiState.bakeryProduct) {
          return;
        }

        if (selectedTemplate == null && uiState.bakeryProduct.bakery_product_templates.length === 1) {
          const defaultTemplate = <BakeryProductProductTemplate>uiState.bakeryProduct.bakery_product_product_templates[0];
          this.form.patchValue({ template: defaultTemplate.id }, { emitEvent: false });
          this.selectedTemplate$.next(defaultTemplate);
        }
      });

    this.templateChangesSub = this.form.get('template').valueChanges
      .withLatestFrom(
        this.uiState$,
        (templateValue, uiState) => ({ templateValue, uiState })
      )
      .subscribe(({ templateValue, uiState }) => {
        this.updateBppt(templateValue, uiState.bakeryProduct, false);
      });

    if (this.bakeryProduct.bakery_product_product_templates.length === 1) {
      const bppt = this.bakeryProduct.bakery_product_product_templates[0] as BakeryProductProductTemplate;

      this.updateBppt(bppt.id, this.bakeryProduct, true);
    } else {
      rebuildControls(
        this.form,
        this.formBuilder,
        'attribute_variants',
        this.bakeryProduct.bakery_product_attributes as BakeryProductAttribute[]
      );
    }

    this.submitFormSub = this.submitForm$
      .skipWhile(() => !this.form.valid)
      .subscribe(() => {
        const formValue = this.form.value;

        const orderItemsAttributes: any = {
          bakery_product_product_template_id: formValue.template,
          quantity: parseInt(formValue.order_qty, 10),
          open_charge: parseInt(formValue.open_charge, 10),
          open_charge_description: formValue.open_charge_description,
          notes: formValue.notes,
        };

        const matchingTemplateOrderItem = (<OrderItem[]>this.bakeryOrder.order_items).find(orderItem => {
          const templateVariants = (<any>orderItem.meta).template_variants.map(variant => variant.bakery_attribute_variant.id);
          const attributeVariants = (<any>orderItem.meta).attribute_variants.map(variant => variant.bakery_attribute_variant.id);
          const inscriptions = (<any>orderItem.meta).inscription;
          const isTemplateVariantsEqual = !formValue.template_variants ||
                                          formValue.template_variants.map(str => str.split('-')).map(([_, variant]) => parseInt(variant, 10)).every(id => templateVariants.includes(id));
          const isAttributeVariantsEqual = !formValue.attribute_variants ||
                                           formValue.attribute_variants.map(str => str.split('-')).map(([_, variant]) => parseInt(variant, 10)).every(id => attributeVariants.includes(id));
          const isInscriptionEqual = !formValue.inscription || formValue.inscription === inscriptions;
          return (<BakeryProductTemplate>orderItem.bakery_product_template).id === formValue.template &&
            isTemplateVariantsEqual && isAttributeVariantsEqual && isInscriptionEqual;
        });

        if (matchingTemplateOrderItem != null) {
          orderItemsAttributes.id = matchingTemplateOrderItem.id;
          orderItemsAttributes.quantity = orderItemsAttributes.quantity + matchingTemplateOrderItem.quantity;
        }

        orderItemsAttributes.template_variants = (formValue.template_variants || []).map(str => str.split('-')).map(([bpa, variant]) => ({ bpa, variant }));
        orderItemsAttributes.attribute_variants = (formValue.attribute_variants || []).map(str => str.split('-')).map(([bpa, variant]) => ({ bpa, variant }));

        orderItemsAttributes.meta = { inscription: formValue.inscription };

        const payload =  {
          id: this.bakeryOrder.id,
          data: {
            order_items_attributes: [orderItemsAttributes],
          },
        };

        this.orderEffects.requestOrderDetailsUpdate(payload);
      });
  }

  private updateBppt(
    bpptId: number,
    bakeryProduct: BakeryProduct,
    updateTemplateFormInput: boolean = false
  ) {
    if (bpptId == null) {
      return;
    }

    const bppt = (<BakeryProductProductTemplate[]>bakeryProduct.bakery_product_product_templates)
      .find(template => template.id === bpptId);

    const order_qty = this.form.get('order_qty').value || 1;

    this.form.controls['order_qty'].setValidators([Validators.required, Validators.min(order_qty)]);
    this.form.patchValue({ order_qty });

    if (updateTemplateFormInput) {
      this.form.patchValue({ template: bpptId }, { emitEvent: false });
    }

    if (bakeryProduct.advanced) {
      rebuildControls(
        this.form,
        this.formBuilder,
        'template_variants',
        bppt.bakery_product_template.bakery_product_attributes as BakeryProductAttribute[]
      );
      rebuildControls(
        this.form,
        this.formBuilder,
        'attribute_variants',
        bakeryProduct.bakery_product_attributes as BakeryProductAttribute[]
      );
    }

    this.selectedTemplate$.next(bppt);
  }

  onSubmitOrderForm() {
    this.submitForm$.next();
  }

  ngOnDestroy() {
    this.templateChangesSub.unsubscribe();
    this.submitFormSub.unsubscribe();
    this.selectedTemplateSub.unsubscribe();
  }
}
