import {Pipe, PipeTransform} from '@angular/core';

import { BakeryOrder } from 'app/store/bakery-order';
import { OrderItem } from 'app/store/order-item';

const DEFAULT_OPTIONS = {
  quantity: true,
};

@Pipe({name: 'orderTotalItemQuantity'})
export class OrderTotalItemQuantityPipe implements PipeTransform {
  public transform(bakeryOrder: BakeryOrder, options: any) {
    let result: string|number = '???';

    if (bakeryOrder == null || bakeryOrder.order_items == null) {
      return result;
    }

    result = (bakeryOrder.order_items as OrderItem[])
      .reduce(
        (total: number, currItem: {quantity: number}) => {
          return total + currItem.quantity
        },
        0
      );

    return result;
  }
}
