import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

import { OrderSchedule } from 'app/store/order-schedule';

@Component({
  selector: 'bkry-mgmt-wholesaler-recurring-orders-card',
  template: require('./recurring-orders-card.component.html'),
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RecurringOrdersCardComponent {
  @Input() isLoading = true;
  @Input() orderSchedules: OrderSchedule[] = [];
}
