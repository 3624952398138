import { Component, Input, Output, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Response } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import { Actions as NgRxActions } from '@ngrx/effects';

import { Actions } from 'app/store/actions';
import { BakeryMgmtEmployeesEffects } from 'app/store/effects/bakery-mgmt-employees.effects';
import { AlertService } from 'app/shared/components/alerts/alert.service';
import { UiState } from '../master.component';
import { validateNoWhitespaces } from 'app/shared/forms/no-whitespaces-validator';

const HELP_DISMISSED = 'templatesHelpDismissed';

@Component({
  selector: 'bakery-mgmt-prod-mgmt-templates-master-header',
  template: require('./header.component.html'),
})
export class MasterHeaderComponent implements OnInit {
  @Input() uiState$: Observable<UiState>;
  @Input() close$: Observable<any>;
  @Output('changeFilterText') changeFilterText$: Observable<string>;

  filters$ = new Subject<string>();
  form: FormGroup;
  isFormOpen = false;
  filterForm: FormGroup;

  private showHelp = new Subject<boolean>();
  showHelp$: Observable<boolean>;

  constructor(
    private alertService: AlertService,
    private actions$: NgRxActions,
  ) {
    this.filterForm = new FormGroup({
      'search': new FormControl(null, [Validators.required, validateNoWhitespaces]),
    });

    this.changeFilterText$ = this.filterForm.get('search').valueChanges
      .map((filterText: string) => filterText.trim())
      .debounce(() => Observable.timer(500));

    this.form = new FormGroup({
      'name': new FormControl(null, [Validators.required, validateNoWhitespaces]),
    });
  }

  dismissHelp() {
    this.showHelp.next(false);
  }

  ngOnInit() {
    const key = this.uiState$.map(uiState => `${HELP_DISMISSED}-${uiState.currentUserId}`);

    this.showHelp$ = key
      .map(k => window.localStorage.getItem(k) === null)
      .take(1)
      .concat(this.showHelp.asObservable())
      .scan((acc, show) => acc && show, true)
      .distinctUntilChanged()
      .withLatestFrom(key)
      .do(([show, k]) => show ? null : window.localStorage.setItem(k, 'yes'))
      .map(([show, k]) => show);
  }
}
