import { UnsafeAction } from 'app/store/effects/unsafe-action';

// This typeCatch is heavily inspired by similar code in the dashing angular
// project: https://github.com/onehungrymind/dashing-angular

const typeCache: { [label: string]: boolean } = {};

export function namespacedType(namespace: string, label: string): string {
  const combinedName = `[${namespace}] ${label}`;

  if (typeCache[combinedName]) {
    throw new Error(`Action type "${combinedName}" is not unique"`);
  }

  typeCache[combinedName] = true;

  return combinedName;
}

export class TypedAction implements UnsafeAction {
  get type(): string {
    return (this.constructor as any).type;
  }

  constructor(public payload?: any) { }
}
