import { Component } from '@angular/core';

@Component({
  selector: 'list-table-header',
  template: `
    <li class="form__label text--grey-dark margin-reset--bottom">
      <ng-content></ng-content>
    </li>
  `,
})
export class ListTableHeaderComponent { }
