import { Component, Input } from '@angular/core';

import iconHelp from 'angular/assets/images/icons/icons-functional/icon-help.svg';

@Component({
  selector: 'tooltip',
  template: `
    <span class="tooltip__item">
      {{ label }}
      <i aria-label="Help Icon" class="tooltip__icon"
        [inlineSVG]="iconHelp"></i>
    </span>
    <figure class="tooltip">
      <p class="tooltip__title"><b>{{ title }}</b></p>
      <p class="tooltip__text">{{ text }}</p>
    </figure>
  `,
})
export class TooltipComponent {
  @Input('label') label: string;
  @Input('title') title: string;
  @Input('text') text: string;

  iconHelp = iconHelp;
}
