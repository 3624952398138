import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import * as moment from 'moment/moment';
import { BakeryOrder } from 'app/store/bakery-order';
import { ModalComponent } from 'app/shared/components/modals/modal.component';


@Component({
  selector: 'bkry-cust-wholesaler-schedule-day-section',
  template: `
    <div class="margin-bottom--bravo">
      <h4 class="margin-bottom--charlie">{{ dateName }}</h4>
      <div aria-label="Active Orders" role="region">
        <ul gridRow class="padding-left--delta padding-right--delta">
          <li gridCol s="2" m="2" l="4" class="form__label">Order ID</li>
          <li gridCol s="0" m="2" l="2" class="form__label">Status</li>
          <li gridCol s="0" m="2" l="2" class="form__label">Schedule Type</li>
          <li gridCol s="0" m="1" l="2" class="form__label">Order Items</li>
          <li gridCol s="2" m="1" l="1" class="form__label">Order Price</li>
        </ul>
        <bkry-cust-wholesaler-schedule-day-section-item
          *ngFor="let bakeryOrder of bakeryOrders"
          [bakeryOrder]="bakeryOrder"
          [isEcommPermitted]="isEcommPermitted"
          [onClickConfirmCancelOrder]="onClickConfirmCancelOrder"
        ></bkry-cust-wholesaler-schedule-day-section-item>
      </div>

    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScheduleDaySectionComponent {
  @Input() bakeryOrders: BakeryOrder[] = [];
  @Input('onClickConfirmCancelOrder') onClickConfirmCancelOrder: Function;
  @Input() isEcommPermitted = false;

  // Tuesday, Sept. 5
  get dateName(): string {
    const firstBo = this.bakeryOrders[0];
    return moment(firstBo.fulfillment_date).format('dddd, MMM D');
  }
}
