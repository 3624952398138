import { Component, OnInit, Input, Output, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BakeryOrder } from 'app/store/bakery-order';
import { BakeryProduct } from 'app/store/bakery-product';
import { OrderItem } from 'app/store/order-item';
import { Subscription } from 'rxjs/Subscription';
import { Actions } from 'app/store/actions';
import { Actions as NgRxActions } from '@ngrx/effects';
import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { validateNoWhitespaces } from 'app/shared/forms/no-whitespaces-validator';
import { RemoteData } from 'app/remote-data';

@Component({
  selector: 'order-mgmt-detail-edit-product-selection',
  template: require('./product-selection.component.html'),
})
export class EditProductSelectionComponent implements OnInit, OnDestroy {
  @Input() isLoading: boolean;
  @Input() bakeryOrder: BakeryOrder;
  @Input() bakeryProducts: RemoteData<BakeryProduct[]>;
  @Input() orderItems: OrderItem[];
  @Output() changeFilterProductText$ = new Subject<string>();
  private stateSub: Subscription;
  private subscription: Subscription;

  filterForm: FormGroup;

  constructor(
    private actions$: NgRxActions,
  ) {
    this.filterForm = new FormGroup({
      'search': new FormControl('', [Validators.required, validateNoWhitespaces]),
    });
  }

  ngOnInit() {
    this.stateSub = this.actions$
      .subscribe((action: UnsafeAction) => {
        switch (action.type) {
          case Actions.REQUEST_UPDATE_BKRY_MGMT_ORDER_MGMT_ORDER_DETAILS_SUCCESS:
            this.filterForm.patchValue({ search: '' });
            break;
        }
      });


    this.subscription = this.filterForm
      .get('search')
      .valueChanges
      .map(x => x.trim())
      .debounceTime(300)
      .distinctUntilChanged()
      .filter(x => this.filterForm.valid)
      .filter(x => x.length >= 3)
      .subscribe((x: string) => this.changeFilterProductText$.next(x));
  }

  ngOnDestroy() {
    this.stateSub.unsubscribe();
    this.subscription.unsubscribe();
  }
}
