import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import { Actions as NgRxActions } from '@ngrx/effects';

import { Actions } from 'app/store/actions';
import { BakeryMgmtCustomersEffects } from 'app/store/effects/bakery-mgmt/bakery-mgmt-customers.effects';
import { validateNoWhitespaces } from 'app/shared/forms/no-whitespaces-validator';

@Component({
  selector: 'bakery-mgmt-customers-master-header',
  template: require('./header.component.html'),
})
export class BakeryMgmtCustomersMasterHeaderComponent implements OnInit, OnDestroy {
  @Input() resultsCount$: Observable<number>;
  @Input() filterForm: FormGroup;

  filters$ = new Subject<string>();
  form: FormGroup;
  isAddFormOpen = false;

  private inviteSuccessSub: Subscription;

  constructor(
    private bakeryMgmtCustomersEffects: BakeryMgmtCustomersEffects,
    private actions$: NgRxActions,
  ) { }

  ngOnInit() {
    this.form = new FormGroup({
      'first_name': new FormControl(null, [Validators.required, validateNoWhitespaces]),
      'last_name': new FormControl(null, [Validators.required, validateNoWhitespaces]),
      'email': new FormControl(null, [Validators.required, Validators.email]),
    });

    this.inviteSuccessSub = this.actions$
      .ofType(Actions.REQUEST_BAKERY_INVITE_CUSTOMER_SUCCESS)
      .subscribe(() => {
        this.onClickCancel();
      });
  }

  ngOnDestroy() {
    this.inviteSuccessSub.unsubscribe();
  }

  onClickAdd() {
    this.isAddFormOpen = true;
  }

  onClickCancel() {
    this.isAddFormOpen = false;
    this.form.reset();
  }

  onSubmitAddEmployee() {
    this.bakeryMgmtCustomersEffects.requestBakeryInviteCustomer({
      first_name: this.form.value.first_name,
      last_name: this.form.value.last_name,
      email: this.form.value.email,
    });
  }
}
