import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ViewContainerRef,
  TemplateRef,
  ContentChild,
} from '@angular/core';

import {
  ModalService,
  ModalAction,
  Modal
} from 'app/shared/components/modals/modal.service';

@Component({
  selector: 'modal',
  template: `
    <ng-template #content>
      <ng-content></ng-content>
    </ng-template>
  `,
})
export class ModalComponent {
  @Input() title: string;
  @Input() intro: string;
  @Input() cancelText?: string;
  @Input() confirmText = 'Confirm';
  @Input() confirmButtonStyle = 'danger';
  @Input() klass = '';

  @Output('cancel') cancelEvent = new EventEmitter<any>();
  @Output('confirm') confirmEvent = new EventEmitter<any>();

  @ViewChild('content') contentTempRef: TemplateRef<any>;

  private modal: Modal;

  constructor(
    private modalService: ModalService,
  ) { }

  openModal() {
    this.modalService.openModal(
      this.title,
      this.intro,
      {
        content: this.contentTempRef,
        cancelText: this.cancelText,
        confirmText: this.confirmText,
        klass: this.klass,
        confirmButtonStyle: this.confirmButtonStyle,
        onCancel: () => this.cancelEvent.next(),
        onConfirm: () => this.confirmEvent.next(),
      }
    );
  }

  closeModal() {
  }
}
