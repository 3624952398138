import {
  ChangeDetectionStrategy,
  Component,
  Input
} from '@angular/core';

import { PricedProductVariation } from 'app/wholesaler/priced-product-variation';

@Component({
  selector: 'wholesaler-price-index-table',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: require('./price-index-table.component.html'),
})
export class PriceIndexTableComponent {
  @Input() isLoading: boolean;
  @Input() pricedProductVariations: PricedProductVariation[];
}
