import { Component, OnInit, OnDestroy, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { Subject } from 'rxjs/Subject';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Actions as NgRxActions } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { AppState } from 'app/store/app-state';
import { AlertService } from 'app/shared/components/alerts/alert.service';
import { ModalComponent } from 'app/shared/components/modals/modal.component';
import { Actions } from 'app/store/actions';
import { BakeryMgmtProductCategoriesEffects } from 'app/store/effects/bakery-mgmt/bakery-mgmt-product-categories.effects';
import { ProductCategory } from 'app/store/product-category';
import { BakeryProduct } from 'app/store/bakery-product';
import { BakeryMgmtUiState } from 'app/store/bakery-mgmt-ui-state';
import { EntitiesState } from 'app/store/entities-state';
import { denormalize as denormalizeProdCat } from 'app/store/product-categories/utils';
import { SimpleFormState, SubmittableFormState } from 'app/shared/forms/form-states';

export interface UiState {
  isLoading: boolean;
  productCategory?: ProductCategory;
  bakeryProducts?: BakeryProduct[];
}

@Component({
  template: require('./detail.component.html'),
})
export class BakeryMgmtProductCategoryDetailComponent implements OnInit, OnDestroy {
  @ViewChild(ModalComponent) deleteModal: ModalComponent;

  uiState$: Observable<UiState>;
  detailsForm: FormGroup;
  updateSuccess$: Observable<any>;
  productCategory$: Observable<ProductCategory>;

  public isDirty = false;
  public isValid = false;

  private categoryId$: Observable<number>;
  private categoryIdSub: Subscription;
  private updateDetails$ = new Subject<any>();
  private updateDetailsSub: Subscription;
  private confirmDelete$ = new Subject<any>();
  private confirmDeleteSub: Subscription;
  private deleteSuccessSub: Subscription;
  private alertsSub: Subscription;
  private formSubmitSub: Subscription;
  public formState$: Observable<SubmittableFormState>;
  public detailsFormState$ = new Subject<SimpleFormState>();
  public associationsFormState$ = new Subject<SimpleFormState>();
  public formSubmit$ = new Subject<any>();
  public formReset$ = new Subject<any>();
  public editCautionVisible$: Observable<boolean>;

  constructor(
    private bakeryMgmtProductsEffects: BakeryMgmtProductCategoriesEffects,
    private router: Router,
    private route: ActivatedRoute,
    private actions$: NgRxActions,
    private store: Store<AppState>,
    private alertService: AlertService,
  ) {
    this.categoryId$ = this.route.params
      .map((params): number => parseInt(params['category_id']));

    this.categoryIdSub = this.categoryId$
      .subscribe((id: number) => this.bakeryMgmtProductsEffects.requestGetProductCategoryDetails(id));

    this.uiState$ = Observable
      .combineLatest(
        this.store.select('bakeryMgmtUiState'),
        this.store.select('entitiesState'),
        this.categoryId$,
        (uiState, entities, categoryId) => ({uiState, entities, categoryId})
      )
      .map((combined) => {
        const bakeryMgmtUiState = <BakeryMgmtUiState> combined.uiState;
        const entitiesState = <EntitiesState> combined.entities;
        const categoryId = <number> combined.categoryId;

        if (
          bakeryMgmtUiState.productCategoriesUiState.isDetailLoading ||
          entitiesState.product_categories[categoryId] == null
        ) {
          return {
            isLoading: true,
          };
        }

        const productCategory = denormalizeProdCat(
          Object.assign({}, entitiesState.product_categories[categoryId]),
          entitiesState
        );

        const bakeryProducts = Object.keys(entitiesState.bakery_products)
          .map(key => entitiesState.bakery_products[key]);

        return {
          isLoading: false,
          productCategory: productCategory,
          bakeryProducts: bakeryProducts,
        }
      })
      .shareReplay(1);

    this.productCategory$ = this.uiState$
      .filter((uiState: UiState) => !uiState.isLoading)
      .map((uiState: UiState): ProductCategory => uiState.productCategory);

    this.updateDetailsSub = this.updateDetails$
      .combineLatest(
        this.productCategory$.take(1),
        (updateDetailsPayload, productCategory) => ({updateDetailsPayload, productCategory})
      )
      .subscribe(({updateDetailsPayload, productCategory}) => {
        this.bakeryMgmtProductsEffects.requestUpdateProductCategory(
          productCategory,
          updateDetailsPayload
        );
      });

    this.confirmDeleteSub = this.confirmDelete$
      .switchMap(() => this.productCategory$.take(1))
      .subscribe((productCategory) => {
        this.bakeryMgmtProductsEffects.requestDeleteProductCategory(productCategory);
      });

    this.deleteSuccessSub = this.actions$
      .ofType(Actions.REQUEST_DELETE_PRODUCT_CATEGORY_SUCCESS)
      .delay(1500)
      .subscribe(() => this.router.navigate(['/bakery_mgmt/product_mgmt/main/categories']));

    this.updateSuccess$ = this.bakeryMgmtProductsEffects.updateProductCategoryEffects$
      .filter(action => action.type === Actions.REQUEST_UPDATE_PRODUCT_CATEGORY_SUCCESS);

    this.alertsSub = this.actions$
      .subscribe((action: UnsafeAction) => {
        let messageType = null,
            messageContent = null;

        switch (action.type) {
          case Actions.REQUEST_UPDATE_PRODUCT_CATEGORY_SUCCESS:
            messageType = 'success';
            messageContent = `The category has been updated.`;
            break;

          case Actions.REQUEST_DELETE_PRODUCT_CATEGORY_SUCCESS:
            messageType = 'success';
            messageContent = `The category has been deleted.`;
            break;

          case Actions.REQUEST_UPDATE_PRODUCT_CATEGORY_ERROR:
          case Actions.REQUEST_DELETE_PRODUCT_CATEGORY_ERROR:
            messageType = 'warning';
            messageContent = 'Something went wrong!';
            break;
        }

        if (messageType != null && messageContent != null) {
          this.alertService[messageType](messageContent);
        }
      });
  }

  ngOnInit() {
    this.formState$ = Observable
      .combineLatest(
        this.detailsFormState$,
        this.associationsFormState$,
        (detailsFormState, associationsFormState): SubmittableFormState => {
          const isDirty = detailsFormState.isDirty || associationsFormState.isDirty;
          const isValid = detailsFormState.isValid && associationsFormState.isValid;

          return {
            isValid: isValid,
            isDirty: isDirty,
            isSubmittable: isDirty && isValid,
            value: Object.assign({}, detailsFormState.value, associationsFormState.value)
          };
        }
      )
      .startWith({isDirty: false, isValid: false, isSubmittable: false, value: {}})
      .shareReplay(1);

    this.editCautionVisible$ = this.formState$
      .map((formState: SubmittableFormState) => formState.isDirty);

    this.formSubmitSub = this.formSubmit$
      .switchMap(() => this.formState$.take(1))
      .withLatestFrom(
        this.uiState$,
        (formState, uiState) => ({formState, uiState})
      )
      .subscribe(({formState, uiState}) => {
        this.bakeryMgmtProductsEffects.requestUpdateProductCategory(
          uiState.productCategory,
          formState.value,
        );
      })
  }

  ngOnDestroy() {
    this.categoryIdSub.unsubscribe();
    this.updateDetailsSub.unsubscribe();
    this.confirmDeleteSub.unsubscribe();
    this.deleteSuccessSub.unsubscribe();
    this.alertsSub.unsubscribe();
  }

  onClickFormSubmit() {
    this.formSubmit$.next();
  }

  onClickFormReset() {
    this.formReset$.next();
  }

  onSubmitDetailsForm(detailsUpdatePayload: any) {
    this.updateDetails$.next(detailsUpdatePayload);
  }

  onClickDelete() {
    this.deleteModal.openModal();
  }

  onConfirmDelete() {
    this.confirmDelete$.next();
  }

  onChangeDetailsFormState(formState: SimpleFormState) {
    this.detailsFormState$.next(formState);
  }

  onChangeAssociationsFormState(formState: SimpleFormState) {
    this.associationsFormState$.next(formState);
  }
}
