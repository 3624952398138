import { Component, Input, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import { Actions as NgRxActions } from '@ngrx/effects';

import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { AlertService } from 'app/shared/components/alerts/alert.service';
import { User } from 'app/store/user';
import { AppState } from 'app/store/app-state';
import { UsersEffects } from 'app/store/effects/users.effects';
import { Actions } from 'app/store/actions';

interface Base64FileDetails {
  encoded: string;
  mimeType: string;
}

@Component({
  selector: 'avatar-upload',
  template: require('./avatar-upload.component.html'),
})
export class AvatarUploadComponent implements OnInit, OnDestroy {
  @Input('user') user: User;
  @ViewChild('fileForm') fileFormElement: ElementRef;

  private uploadSuccessSub: Subscription;
  private alertsSub: Subscription;
  private tempAvatar: Base64FileDetails;

  constructor(
    private alertService: AlertService,
    private usersEffects: UsersEffects,
    private actions$: NgRxActions,
  ) {
    this.uploadSuccessSub = this.usersEffects.requestUserAvatarUpdateEffects$
      .filter(action => action.type === Actions.REQUEST_USER_AVATAR_UPDATE_SUCCESS)
      .subscribe(() => {
        // Clear temp avatar
        this.tempAvatar = null;

        this.resetFileForm();
      });

    this.alertsSub = this.actions$
      .subscribe((action: UnsafeAction) => {
        let messageType = null,
            messageContent = null;

        switch (action.type) {
          case Actions.REQUEST_USER_AVATAR_UPDATE_SUCCESS:
            messageType = 'success';
            messageContent = `Your avatar has successfully been updated.`;
            break;

          case Actions.REQUEST_USER_AVATAR_UPDATE_ERROR:
            messageType = 'warning';
            messageContent = `Something went wrong! We couldn't upload your avatar.`;
            break;
        }

        if (messageType != null && messageContent != null) {
          this.alertService[messageType](messageContent);
        }
      });
  }

  ngOnInit() { }

  ngOnDestroy() {
    this.uploadSuccessSub.unsubscribe();
    this.alertsSub.unsubscribe();
  }

  get hasUserAvatar(): boolean {
    return this.user.avatar != null;
  }

  get hasTempAvatar(): boolean {
    return this.tempAvatar != null;
  }

  get hasAvatar(): boolean {
    return this.hasUserAvatar || this.hasTempAvatar;
  }

  get avatarUrl(): string {
    if (this.hasTempAvatar) {
      return `data:${this.tempAvatar.mimeType};base64,${this.tempAvatar.encoded}`;
    }

    if (this.hasUserAvatar) {
      return this.user.avatar;
    }

    return '';
  }

  onClickCancel() {
    this.tempAvatar = null;

    this.resetFileForm();
  }

  onClickSave() {
    this.usersEffects.requestUserAvatarUpdate(
      this.user,
      `data:${this.tempAvatar.mimeType};base64,${this.tempAvatar.encoded}`
    );
  }

  onClickRemoveImage() {
    this.usersEffects.requestUserAvatarUpdate(
      this.user,
      null
    );
  }

  onChangeFileUpload($event) {
    const file = $event.target.files[0];
    const fileReader = new FileReader();
    let fileBinResults = null;
    fileReader.onload = (readerEvent) => {
      fileBinResults = (<any>readerEvent.target).result;

      this.tempAvatar = {
        encoded: btoa(fileBinResults),
        mimeType: file.type,
      };
    };

    fileReader.readAsBinaryString(file);
  }

  private resetFileForm() {
    this.fileFormElement.nativeElement.reset();
  }
}
