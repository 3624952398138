import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { Actions } from 'app/store/actions';
import {
  SimplifiedResourceRequestState,
  updateResourceLoading,
  updateResourceComplete
} from 'app/store/reducers/request-states';

export interface State extends SimplifiedResourceRequestState<number> {
  update: SimplifiedResourceRequestState<number>;
}

export const initialState: State = {
  detailId: null,
  isLoading: false,
  result: null,
  update: {
    isLoading: false,
    result: null,
  }
};

export function reducer(
  state: State = initialState,
  action: UnsafeAction
): State {
  switch (action.type) {
    case Actions.REQUEST_GET_BKRY_WHOLESALER:
      return {
        ...updateResourceLoading(state),
        detailId: action.payload,
        update: initialState.update,
      };

    case Actions.REQUEST_GET_BKRY_WHOLESALER_SUCCESS:
      return updateResourceComplete(state, action.payload);

    // case Actions.REQUEST_UPDATE_BKRY_MGMT_ORDER_MGMT_ORDER_DETAILS:
    //   return {
    //     ...state,
    //     update: updateResourceLoading(state.update),
    //   };
    //
    // case Actions.REQUEST_UPDATE_BKRY_MGMT_ORDER_MGMT_ORDER_DETAILS_SUCCESS:
    //   return {
    //     ...state,
    //     update: updateResourceComplete(state.update, action.payload),
    //   };

    default:
      return state;
  }
}
