import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { Store } from '@ngrx/store';
import { Angular2TokenService } from 'app/angular2-token/angular2-token.service';
import { Actions as NgRxActions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';

import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { AppState } from 'app/store/app-state';
import { Actions } from 'app/store/actions';

@Injectable()
export class BakeryMgmtReportingSalesTaxEffects {
  constructor(
    private actions$: NgRxActions,
    private tokenService: Angular2TokenService,
    private store: Store<AppState>,
  ) {}

  @Effect() getTaxReport$ = this.actions$
    .ofType(Actions.REQUEST_TAX_REPORT)
    .switchMap((action: UnsafeAction) => {
      return this.tokenService
        .get('/api/bakery/reporting/sales_tax', { params: action.payload })
        .map((response: Response) => response.json())
        .switchMap(response => {
          return Observable.of({ type: Actions.REQUEST_TAX_REPORT_SUCCESS, payload: response });
        })
        .catch(() => {
          return Observable.of({ type: Actions.REQUEST_TAX_REPORT_ERROR });
        });
    })
    .share();

 @Effect() getPrintableTaxReport$ = this.actions$
   .ofType(Actions.REQUEST_PRINTABLE_TAX_REPORT)
   .switchMap((action: UnsafeAction) => {
     return this.tokenService
       .getWithoutSpinner('/api/bakery/reporting/printable_sales_tax', { params: action.payload })
       .map((response: Response) => response.json())
       .switchMap(response => {
         return Observable.of({ type: Actions.REQUEST_PRINTABLE_TAX_REPORT_SUCCESS, payload: response });
       })
       .catch(() => {
         return Observable.of({ type: Actions.REQUEST_PRINTABLE_TAX_REPORT_ERROR });
       });
   })
   .share();
}
