import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import * as Rx from 'rxjs';
import { Store } from '@ngrx/store';
import { Actions as NgRxActions } from '@ngrx/effects';

import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { AlertService } from 'app/shared/components/alerts/alert.service';
import { AppState } from 'app/store/app-state';
import { EntitiesState } from 'app/store/entities-state';
import { Actions } from 'app/store/actions';
import { BakeryProduct } from 'app/store/bakery-product';
import { ProductCategory } from 'app/store/product-category';
import { BakeryProductTemplate } from 'app/store/bakery-product-template';
import { BakeryProductProductTemplate } from 'app/store/bakery-product-product-template';
import { UsersEffects } from 'app/store/effects/users.effects';
import { BakeryCustEffects } from 'app/store/effects/bakery-cust/bakery-cust.effects';
import { BakeryCustProductsEffects } from 'app/store/effects/bakery-cust/bakery-cust-products.effects';
import { BakeryCustomerUiState } from 'app/store/reducers/bakery-cust-ui-state-reducer';
import { BakeryCart } from 'app/store/bakery-cart';
import { OrderItem } from 'app/store/order-item';
import * as selectors from 'app/store/selectors';
import { BakeryProductAttribute } from 'app/store/bakery-product-attribute';
import { rebuildControls } from 'app/rebuild-controls';

export interface UiState {
  isLoading: boolean;
  bakeryProduct?: BakeryProduct;
}

export interface SuggestionsUiState {
  isLoading: boolean;
  suggestions?: BakeryProduct[];
}

@Component({
  template: require('./detail.component.html'),
})
export class BakeryCustProductDetailComponent implements OnInit {
  uiState$: Rx.Observable<UiState>;
  availabilityRange$: Rx.Observable<string>;
  suggestionsUiState$: Rx.Observable<SuggestionsUiState>;
  permitEcomm$: Rx.Observable<boolean>;
  form: FormGroup;
  selectedTemplate$ = new Rx.BehaviorSubject<BakeryProductProductTemplate>(null);
  isAttributeControlsVisible$: Rx.Observable<boolean>;

  private productId$: Rx.Observable<number>;
  private submitForm$ = new Rx.Subject<any>();
  private productIdSub: Rx.Subscription;
  private templateChangesSub: Rx.Subscription;
  private submitFormSub: Rx.Subscription;
  private selectedTemplateSub: Rx.Subscription;
  private alertsSub: Rx.Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private usersEffects: UsersEffects,
    private bakeryCustEffects: BakeryCustEffects,
    private bakeryCustProductsEffects: BakeryCustProductsEffects,
    private store: Store<AppState>,
    private alertService: AlertService,
    private actions$: NgRxActions,
  ) { }

  createVariant(): FormGroup {
    return this.formBuilder.group({
      id: '',
    });
  }

  ngOnInit() {
    this.permitEcomm$ = this.store.select(selectors.getCustBakeryPermitEcomm);

    this.form = this.formBuilder.group({
      'template': new FormControl(null, [Validators.required]),
      'order_qty': new FormControl(1, [Validators.required, Validators.min(1)]),
      'template_variants': this.formBuilder.array([]),
      'attribute_variants': this.formBuilder.array([]),
      'inscription': new FormControl(null),
    });

    this.productId$ = this.route.params
      .map((params): number => parseInt(params['product_id']));

    this.productIdSub = this.productId$
      .subscribe((id: number) => {
        this.bakeryCustProductsEffects.requestGetBakeryProduct(id);
        this.bakeryCustProductsEffects.requestGetBakeryProductSuggestions(id);
        this.form.reset({ order_qty: 1 });
      });

    this.uiState$ = Rx.Observable
      .combineLatest(
        this.store.select('bakeryCustUiState'),
        this.store.select('entitiesState'),
        (uiState, entities) => ({uiState, entities})
      )
      .map((combined) => {
        const bakeryCustUiState = <BakeryCustomerUiState> combined.uiState;
        const entitiesState = <EntitiesState> combined.entities;
        const productId = <number> bakeryCustUiState.bakeryProductDetailState.detailId;

        if (
          bakeryCustUiState.bakeryProductDetailState.state == null ||
          bakeryCustUiState.bakeryProductDetailState.state == 'loading' ||
          entitiesState.bakery_products[productId] == null
        ) {
          return {
            isLoading: true,
          };
        }

        const bakeryProduct = {...entitiesState.bakery_products[productId]};

        bakeryProduct.product_categories = (<number[]>bakeryProduct.product_categories)
          .map((prodCatId: number): ProductCategory => ({...entitiesState.product_categories[prodCatId]}));

        bakeryProduct.computedAvailability = 'year_round';

        if (bakeryProduct.available_day_specific && bakeryProduct.available_range_specific) {
          bakeryProduct.computedAvailability = 'day_range';
        } else if (bakeryProduct.available_day_specific) {
          bakeryProduct.computedAvailability = 'day_only';
        } else if (bakeryProduct.available_range_specific) {
          bakeryProduct.computedAvailability = 'range_only';
        }

        (<BakeryProductTemplate[]>bakeryProduct.bakery_product_templates).sort((varA, varB) => {
          return varA.unit_price - varB.unit_price;
        });

        rebuildControls(
          this.form,
          this.formBuilder,
          'attribute_variants',
          bakeryProduct.bakery_product_attributes as BakeryProductAttribute[]
        );

        return {
          isLoading: false,
          bakeryProduct: bakeryProduct,
        };
      })
      .catch(error => {
        console.error(`error:`, error);

        return Rx.Observable.of(error);
      })
      .shareReplay(1);

    this.availabilityRange$ = this.uiState$
      .map(uiState => uiState.bakeryProduct)
      .map(bakeryProduct => {
        const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const startMonth = MONTHS[bakeryProduct.available_range_start_month - 1];
        const startDay = bakeryProduct.available_range_start_day;
        const endMonth = MONTHS[bakeryProduct.available_range_end_month - 1];
        const endDay = bakeryProduct.available_range_end_day;
        return `${startMonth} ${startDay} to ${endMonth} ${endDay}`;
      });

    // FIXME: The suggestions really should be their own separate component.
    this.suggestionsUiState$ = Rx.Observable
      .combineLatest(
        this.store.select('bakeryCustUiState'),
        this.store.select('entitiesState'),
        (uiState, entities) => ({uiState, entities})
      )
      .map((combined) => {
        const bakeryCustUiState = <BakeryCustomerUiState> combined.uiState;
        const entitiesState = <EntitiesState> combined.entities;

        if (
          bakeryCustUiState.bakeryProductSuggestionsState.state == null ||
          bakeryCustUiState.bakeryProductSuggestionsState.state == 'loading'
        ) {
          return {
            isLoading: true,
          };
        }

        const requestResult: any = bakeryCustUiState.bakeryProductSuggestionsState.result;
        let suggestions = [];
        if (requestResult.bakery_products) {
          suggestions = Object.keys(requestResult.bakery_products)
            .map(key => ({...entitiesState.bakery_products[key]}));
        }

        return {
          isLoading: false,
          suggestions: suggestions,
        };
      })
      .shareReplay(1);

    this.selectedTemplateSub = this.uiState$
      .filter(uiState => !uiState.isLoading)
      .withLatestFrom(
        this.selectedTemplate$,
        (uiState, selectedTemplate) => ({uiState, selectedTemplate})
      )
      .subscribe(({uiState, selectedTemplate}) => {
        if (selectedTemplate == null && uiState.bakeryProduct.bakery_product_product_templates.length === 1) {
          const defaultTemplate = uiState.bakeryProduct.bakery_product_product_templates[0] as BakeryProductProductTemplate;

          this.form.patchValue({template: defaultTemplate.id}, {emitEvent: true});

          rebuildControls(
            this.form,
            this.formBuilder,
            'template_variants',
            defaultTemplate.bakery_product_template.bakery_product_attributes as BakeryProductAttribute[]
          );

          this.selectedTemplate$.next(defaultTemplate);
        }
      })

    this.templateChangesSub = this.form.get('template').valueChanges
      .withLatestFrom(
        this.uiState$
          .filter(uiState => !uiState.isLoading),
        (templateValue, uiState) => {
          return {
            templateValue,
            uiState
          }
        }
      )
      .subscribe(({templateValue, uiState}) => {
        let template: BakeryProductProductTemplate = null;

        if (templateValue != null) {
          template = (<BakeryProductProductTemplate[]>uiState.bakeryProduct.bakery_product_product_templates)
            .find(bppt => bppt.id === templateValue);

          if (template && uiState.bakeryProduct && uiState.bakeryProduct.advanced) {
            const bakeryProductTemplate: BakeryProductTemplate = template.bakery_product_template;

            rebuildControls(
              this.form,
              this.formBuilder,
              'template_variants',
              bakeryProductTemplate.bakery_product_attributes as BakeryProductAttribute[]
            );
          }
        }

        this.selectedTemplate$.next(template);
      });

    this.submitFormSub = this.submitForm$
      .skipWhile(() => !this.form.valid)
      .withLatestFrom(
        this.uiState$,
        this.store.select(selectors.getCustomerCart),
        (_, uiState, currentCart) => ({uiState, currentCart})
      )
      .subscribe(combined => {
        const formValue = this.form.value;
        const uiState = combined.uiState;
        const currentCart = <BakeryCart>combined.currentCart;

        const orderItemsAttributes: any = {
          bakery_product_product_template_id: formValue.template,
          quantity: parseInt(formValue.order_qty),
          tax_exempt: !uiState.bakeryProduct.taxable
        };

        if (currentCart != null) {
          const matchingTemplateOrderItem = (<OrderItem[]>currentCart.order_items).find(orderItem => {
            const templateVariants = (<any>orderItem.meta).template_variants.map(variant => variant.bakery_attribute_variant.id);
            const attributeVariants = (<any>orderItem.meta).attribute_variants.map(variant => variant.bakery_attribute_variant.id);
            const inscriptions = (<any>orderItem.meta).inscription
            const isTemplateVariantsEqual = !formValue.template_variants ||
                                            formValue.template_variants.map(str => str.split('-')).map(([_, variant]) => parseInt(variant, 10)).every(id => templateVariants.includes(id));
            const isAttributeVariantsEqual = !formValue.attribute_variants ||
                                             formValue.attribute_variants.map(str => str.split('-')).map(([_, variant]) => parseInt(variant, 10)).every(id => attributeVariants.includes(id));
            const isInscriptionEqual = !formValue.inscription || formValue.inscription == inscriptions;

            return (<BakeryProductTemplate>orderItem.bakery_product_template).id === formValue.template && isTemplateVariantsEqual && isAttributeVariantsEqual && isInscriptionEqual;
          })

          if (matchingTemplateOrderItem != null) {
            orderItemsAttributes.id = matchingTemplateOrderItem.id;
            orderItemsAttributes.quantity = orderItemsAttributes.quantity + matchingTemplateOrderItem.quantity;
          }
        }

        const orderLimit = (<BakeryProductProductTemplate[]>uiState.bakeryProduct.bakery_product_product_templates).find(template => template.id === formValue.template).bakery_product_template.order_limit;

        if ( orderLimit < orderItemsAttributes.quantity && orderLimit !== 0) {
          const messageType = 'warning';
          const messageContent = `There is a quantity limit for ${uiState.bakeryProduct.name} of ${orderLimit}`;
          this.alertService[messageType](messageContent);
          return false;
        }

        orderItemsAttributes.template_variants = (formValue.template_variants || []).map(str => str.split('-')).map(([bpa, variant]) => ({ bpa, variant }));
        orderItemsAttributes.attribute_variants = (formValue.attribute_variants || []).map(str => str.split('-')).map(([bpa, variant]) => ({ bpa, variant }));

        orderItemsAttributes.meta = {inscription: formValue.inscription}

        this.store.dispatch({
          type: Actions.ADD_PRODUCT_TEMPLATE_TO_CART,
          payload: {
            order_items_attributes: [orderItemsAttributes],
          }
        })
      })

    this.isAttributeControlsVisible$ = this.uiState$
      .map((uiState: UiState) => uiState.bakeryProduct)
      .filter(val => !!val)
      .combineLatest(
        this.selectedTemplate$,
        (bakeryProduct, selectedTemplate) => ({bakeryProduct, selectedTemplate})
      )
      .map(({bakeryProduct, selectedTemplate}): boolean => {
        return bakeryProduct.advanced && !!selectedTemplate;
      })
      .startWith(false);

    this.alertsSub = this.actions$
      .subscribe((action: UnsafeAction) => {
        let messageType = null,
            messageContent = null;

        switch (action.type) {
          case Actions.REQUEST_GET_STORE_BAKERY_PRODUCT_ERROR:
            this.router.navigate(['/404']);

          case Actions.REQUEST_CREATE_CUST_CART_SUCCESS:
          case Actions.REQUEST_UPDATE_CUST_CART_SUCCESS:
            messageType = 'success';
            const productNames = action.payload
              .bpptIds
              .map(bpptId => action.payload.bakery_product_product_templates[bpptId])
              .map(bppt => bppt.bakery_product_id)
              .map(bakeryProductId => action.payload.bakery_products[bakeryProductId])
              .map(bakeryProduct => bakeryProduct.name)
              .join(', ');
            messageContent = [
              `We added <strong>${productNames}</strong> to your cart.`,
              'If you are done shopping go ahead and click <a class="text--bold text--underline text--green" href="/store/cart">cart to checkout</a>.'
            ].join(' ');
            break;

          case Actions.REQUEST_CREATE_CUST_CART_ERROR:
          case Actions.REQUEST_UPDATE_CUST_CART_ERROR:
            messageType = 'warning';
            messageContent = 'Something went wrong!';
            break;
        }

        if (messageType != null && messageContent != null) {
          this.alertService[messageType](messageContent);
        }
      });
  }

  ngOnDestroy() {
    this.productIdSub.unsubscribe();
    this.templateChangesSub.unsubscribe();
    this.submitFormSub.unsubscribe();
    this.selectedTemplateSub.unsubscribe();
    this.alertsSub.unsubscribe();
  }

  onSubmitOrderForm() {
    // this.form.patchValue({template: null});
    this.submitForm$.next();
    window.scrollTo(0, 0);
  }
}
