import {
  Component,
  Input,
  OnInit,
  OnDestroy
} from '@angular/core';
import { Router } from '@angular/router';
import { Actions as NgRxActions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as Rx from 'rxjs';

import { AppState } from 'app/store/app-state';
import { AlertService } from 'app/shared/components/alerts/alert.service';
import { Actions } from 'app/store/actions';
import * as selectors from 'app/store/selectors';

@Component({
  selector: 'bkry-mgmt-wholesaler-group-delete-button',
  template: `
    <button
      class="button--danger button--full"
      (click)="onClickDelete()"
      [ngClass]="{'button--disabled': isLoading$ | async}"
    >
      <ng-template [ngIf]="!(isLoading$ | async)">Delete</ng-template>
      <ng-template [ngIf]="(isLoading$ | async)">Deleting</ng-template>
    </button>
  `,
})
export class DeleteButton implements OnInit, OnDestroy {
  isLoading$ = this.store
    .select(selectors.bkryMgmtWholesalerGroup.getDetailDestroyIsLoading);

  delete$ = new Rx.Subject<void>();

  private deleteSub: Rx.Subscription;
  private deleteSuccessSub: Rx.Subscription;

  constructor(
    private router: Router,
    private actions$: NgRxActions,
    private store: Store<AppState>,
    private alertService: AlertService,
  ) { }

  ngOnInit() {
    this.deleteSub = this.delete$
      .switchMap(() => this.isLoading$.take(1))
      .filter(isLoading => !isLoading)
      .switchMap(() => this.store
        .select(selectors.bkryMgmtWholesalerGroup.getDetailResultEntity)
        .take(1)
      )
      .map(entity => entity.id)
      .subscribe((wholesalerGroupId) => {
        this.store.dispatch({
          type: Actions.REQUEST_DESTROY_BKRY_MGMT_WHOLESALER_GROUP,
          payload: wholesalerGroupId,
        });
      });

    this.deleteSuccessSub = this.actions$
      .ofType(Actions.REQUEST_DESTROY_BKRY_MGMT_WHOLESALER_GROUP_SUCCESS)
      .subscribe(() => {
        this.router.navigate(['/bakery_mgmt/wholesalers/lists/groups']);
      });
  }

  ngOnDestroy() {
    this.deleteSub.unsubscribe();
    this.deleteSuccessSub.unsubscribe();
  }

  onClickDelete() {
    this.delete$.next();
  }
}
