import { Component, Input } from '@angular/core';

import { BakeryOrder } from 'app/store/bakery-order';

@Component({
  selector: 'order-status-badge',
  template: `
    <span [ngClass]="cssClass">{{ statusText }}</span>
  `,
})
export class OrderStatusBadgeComponent {
  @Input() bakeryOrder: BakeryOrder;
  @Input() textOnly = false;

  get cssClass(): string {
    if (this.textOnly) {
      switch (this.bakeryOrder.order_status) {
        case 'submitted':
          return 'text--green';
        case 'hold':
          return 'text--yellow';
        case 'completed':
          return 'text--blue';
        case 'canceled':
          return 'text--red';
        default:
          return '';
      }
    }

    switch (this.bakeryOrder.order_status) {
      case 'submitted':
        return 'badge--active';
      case 'hold':
        return 'badge--caution';
      case 'completed':
        return 'badge--primary';
      case 'canceled':
        return 'badge--warning';
      default:
        return '';
    }
  }

  get statusText(): string {
    switch (this.bakeryOrder.order_status) {
      case 'submitted':
        return 'Unfulfilled';
      case 'hold':
        return 'On Hold';
      case 'completed':
        return 'Fulfilled';
      case 'canceled':
        return 'Canceled';
      default:
        return 'Unknown';
    }
  }
}
