import { Component, Input } from '@angular/core';

@Component({
  selector: 'loading-indicator',
  template: `
    <div class="loading-indicator__container">
      <div class="loading-indicator">
        <ul class="loading-indicator__icons">
          <li class="loading-indicator__icon--1 loading-indicator__icon"></li>
          <li class="loading-indicator__icon--2 loading-indicator__icon"></li>
          <li class="loading-indicator__icon--3 loading-indicator__icon"></li>
          <li class="loading-indicator__icon--4 loading-indicator__icon"></li>
          <li class="loading-indicator__icon--5 loading-indicator__icon"></li>
          <li class="loading-indicator__icon--6 loading-indicator__icon"></li>
          <li class="loading-indicator__icon--7 loading-indicator__icon"></li>
          <li class="loading-indicator__icon--8 loading-indicator__icon"></li>
          <li class="loading-indicator__icon--9 loading-indicator__icon"></li>
          <li class="loading-indicator__icon--10 loading-indicator__icon"></li>
          <li class="loading-indicator__icon--11 loading-indicator__icon"></li>
          <li class="loading-indicator__icon--12 loading-indicator__icon"></li>
        </ul>
      </div>
      <p class="text--uppercase">
        <small><b>Loading</b></small>
      </p>
    </div>
  `,
  styles: []
})
export class LoadingIndicatorComponent {

}
