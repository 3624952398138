import { Component, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { PaymentCard } from 'app/store/payment-card';

@Component({
  selector: 'bakery-mgmt-new-order-checkout-payment-edit',
  template: require('./payment-edit.component.html'),
})
export class BakeryMgmtNewOrderCheckoutPaymentEditComponent {
  @Input() parent: FormGroup;
  @Input() paymentCards: PaymentCard[] = [];
  @Input() employee = false;
  @Input() isManualPaymentAllowed = true;
  @Input() createToken$: Observable<any>;
  @Output() stripeToken$ = new Subject<any>();
  @Output() valid$ = new Subject<boolean>();

  private getControlValue(key: string): any {
    return this.parent.get(key).value;
  }

  get isRecurringOrder(): boolean {
    return this.getControlValue('order_type') === 'recurring';
  }

  get isPaymentDetailsVisible(): boolean {
    const value = this.getControlValue('payment_type');

    return value !== 'none' && value !== null;
  }

  get isCardSelectFormVisible(): boolean {
    const value = this.getControlValue('payment_type');

    return value === 'credit_card' && (this.paymentCards && this.paymentCards.length > 0);
  }

  get isAddCardFormVisible(): boolean {
    const paymentTypeValue = this.getControlValue('payment_type'),
          selectionValue = this.getControlValue('payment_card_selection');

    return paymentTypeValue === 'credit_card' &&
           (selectionValue === 'add_new' || this.paymentCards == null || this.paymentCards.length === 0);
  }
}
