import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { Actions } from 'app/store/actions';
import { RemoteData, NOT_ASKED, LOADING, fromSuccess, fromError } from 'app/remote-data';

export interface State {
  bakeryProducts: RemoteData<any[]>;
}

const INITIAL_STATE = {
  bakeryProducts: NOT_ASKED,
};

export function reducer(state: State = INITIAL_STATE, action: UnsafeAction): State {
  switch (action.type) {
    case Actions.REQUEST_GET_BAKERY_PRODUCTS:
      return { ...state, bakeryProducts: LOADING };

    case Actions.REQUEST_GET_BAKERY_PRODUCTS_SUCCESS2:
      return { ...state, bakeryProducts: fromSuccess(action.payload) };

    case Actions.REQUEST_GET_BAKERY_PRODUCTS_ERROR2:
      return { ...state, bakeryProducts: fromError(action.payload) };

    case Actions.REQUEST_UPDATE_BKRY_MGMT_ORDER_MGMT_ORDER_DETAILS:
    case Actions.REQUEST_UPDATE_EMPLOYEE_CART_SUCCESS:
    case Actions.REQUEST_CREATE_EMPLOYEE_CART_SUCCESS:
    case Actions.REQUEST_UPDATE_WHOLESALE_CART_SUCCESS:
    case Actions.REQUEST_CREATE_WHOLESALE_CART_SUCCESS:
    case Actions.NEW_ORDER_STARTED:
    case Actions.EDIT_ORDER_STARTED:
      return INITIAL_STATE;

    default:
      return state;
  }
}

export const selectBakeryProducts = (state: State): RemoteData<any[]> =>
  state.bakeryProducts;
