import { Component, Input } from '@angular/core';

@Component({
  selector: 'tab-item',
  template: `
    <a
      [routerLink]="routerLink"
      routerLinkActive="is-active"
      class="tab__item"
    >
      <ng-content></ng-content>
    </a>
  `,
  host: { 'class': 'tab__item' }
})
export class TabItemComponent {
  @Input('link') routerLink: string;
}
