import { Component, OnInit, OnDestroy, Input, Output } from '@angular/core';
import { AbstractControl, FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

declare var process: any;

import { Bakery } from 'app/store/bakery';
import { SimpleFormState } from 'app/shared/forms/form-states';
import { UiState } from 'app/bakery-mgmt/store-mgmt/settings/settings.component';
import { phoneNumberValidator } from 'app/shared/forms/form-number-validator';
import { validateNoWhitespaces } from 'app/shared/forms/no-whitespaces-validator';
import { Countries } from 'app/constants';

const COUNTRIES = [
  { code: Countries.USA, name: 'United States' },
  { code: Countries.ZAF, name: 'South Africa' },
  { code: Countries.CAN, name: 'Canada' },
];

const DEFAULT_CURRENCY_CODE = 'USD';
const CURRENCY_CODES = [DEFAULT_CURRENCY_CODE, 'ZAR', 'CAD'];

@Component({
  selector: 'bakery-store-edit-description',
  template: require('./edit-description.component.html')
})
export class BakeryMgmtStoreSettingsEditDescriptionComponent implements OnInit, OnDestroy {
  @Input() uiState$: Observable<UiState>;
  @Input() reset$: Observable<any>;
  @Output('formState') formState$: Observable<SimpleFormState>;

  form: FormGroup;
  domainSuffix = '.' + process.env.WEB_DOMAIN;
  currencyCodes = CURRENCY_CODES;
  countries = COUNTRIES;
  private formValueSub: Subscription;

  simpleDomainMask(rawValue) {
    const nameLengthLimit = 25;

    const newMask = [];

    for (let i = 0; i < (nameLengthLimit - 1) && i < (rawValue.length - 0); i++) {
      newMask.splice(1, 0, /[a-zA-Z0-9\-]/);
    }

    return newMask;
  }

  constructor(
  ) {
    this.form = new FormGroup({
      name: new FormControl(null, [Validators.required, validateNoWhitespaces]),
      phone_number: new FormControl(null, [Validators.required, phoneNumberValidator]),
      default_product_category_id: new FormControl(null, []),
      store_description: new FormControl(null, []),
      time_zone: new FormControl(null, [Validators.required]),
      country_code: new FormControl(Countries.USA, [Validators.required]),
      currency_code: new FormControl(DEFAULT_CURRENCY_CODE, [Validators.required]),
      store_domain: new FormControl(null, [Validators.required, validateDomainName, validateNoOrderNova]),
      store_email: new FormControl(null, [Validators.email]),
      order_disclaimer: new FormControl(null, []),
    });

    this.formState$ = this.form.valueChanges
      .map((): SimpleFormState => {
        return {
          isDirty: this.form.dirty,
          isValid: this.form.valid,
          value: {...this.form.value},
        };
      })
      .do(formState => console.log(`[description] formState:`, formState))
      .shareReplay(1)
  }

  bakeryStoreLink$: Observable<string>;

  ngOnInit() {
    this.formValueSub = Observable
      .merge(
        this.uiState$.filter((uiState: UiState) => !uiState.isLoading),
        this.reset$.switchMap(() => this.uiState$.filter((uiState: UiState) => !uiState.isLoading).take(1))
      )
      .map((uiState: UiState) => uiState.bakery)
      .subscribe((bakery:  Bakery) => {
        // FIXME: For some reason, a true form reset requires calling #reset twice...
        this.resetForm(bakery);
        this.resetForm(bakery);
      });

      this.bakeryStoreLink$ = this.uiState$
        .pluck('bakery')
        .map((bakery: Bakery) => {
          if (bakery == null || bakery.store_domain == null) return null;

          return `http://${bakery.store_domain}.${process.env.WEB_DOMAIN.replace(/app\./i, '')}/`;
        });

  }

  ngOnDestroy() {
    this.formValueSub.unsubscribe();
  }

  private resetForm(bakery: Bakery) {
    this.form.reset({
      name: bakery.name,
      phone_number: bakery.phone_number,
      default_product_category_id: bakery.default_product_category_id,
      store_description: bakery.store_description,
      store_domain: bakery.store_domain,
      store_email: bakery.store_email,
      time_zone: bakery.time_zone,
      order_disclaimer: bakery.order_disclaimer,
      country_code: bakery.country_code,
      currency_code: bakery.currency_code,
    });
  }
}

const domainNameRegex = /^\w[a-zA-Z0-9\-]{1,23}\w$/;
function validateDomainName(control: AbstractControl): {[key: string]: boolean} {
  if (domainNameRegex.test(control.value)) {
    // Input looks good.
    return null;
  }

  return {invalid_domain_name: true};
}

const orderNovaNameRegex = /([o0]rder).*(n[o0]va)/i;
function validateNoOrderNova(control: AbstractControl): {[key: string]: boolean} {
  if (!orderNovaNameRegex.test(control.value)) {
    // Input looks good.
    return null;
  }

  return {invalid_domain_name_contents: true};
}
