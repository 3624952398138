import {
  Component,
  Input,
  ChangeDetectionStrategy
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { BakeryOrderInvoice } from 'app/store/bakery-order-invoice';
import { Bakery } from 'app/store/bakery';
import { WholesalerOrg } from 'app/store/wholesaler-org';
import { HeaderStateService } from 'app/shared/services/header-state.service';

@Component({
  selector: 'invoice-details-card',
  template: require('./invoice-details-card.component.html'),
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [HeaderStateService]
})
export class InvoiceDetailsCardComponent {
  @Input() isLoading = true;
  @Input() isManualAllowed = false;
  @Input() invoice: BakeryOrderInvoice;
  @Input() bakery: Bakery;
  @Input() wholesalerOrg: WholesalerOrg;
  @Input() paymentButtonText = 'Make Payment';

  constructor(
    private headerStateService: HeaderStateService
  ) { }

  onClickAddPayment() {
    this.headerStateService.updateIsExpanded(true);
  }
}
