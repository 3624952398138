import {
  Component,
  Input,
  Output,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import { createSelector } from 'reselect';

import { AppState } from 'app/store/app-state';
import * as selectors from 'app/store/selectors';
import { Actions } from 'app/store/actions';
import { Base64FileDetails } from 'app/shared/components/file-upload-trigger/file-upload-trigger.component';

const compSelector = 'bkry-mgmt-order-details-notes-prez';

const getNotesFormData = createSelector(
  selectors.getBkryMgmtOrderMgmtDetailResult,
  selectors.getBkryMgmtOrderMgmtDetailState,
  (bakeryOrder, detailState) => {
    return {
      notesVisibility: detailState.notesVisibility,
      bakery_notes: bakeryOrder.bakery_notes,
      customer_notes: bakeryOrder.customer_notes,
    };
  }
);

@Component({
  template: `
    <ng-template [ngIf]="(isLoading$|async)">
      <loading-indicator></loading-indicator>
    </ng-template>
    <ng-template [ngIf]="!(isLoading$|async)">
      <${compSelector}
        [bakeryOrder]="bakeryOrder$ | async"
        [formData]="formData$ | async"
        [isLoading]="isUpdateLoading$ | async"
        (onFormSubmit$)="onFormSubmit$.next($event)"
      ></${compSelector}>
    </ng-template>
  `,
})
export class NotesComponent implements OnInit, OnDestroy {
  isLoading$ = this.store.select(selectors.getBkryMgmtOrderMgmtDetailIsLoading);
  bakeryOrder$ = this.store.select(selectors.getBkryMgmtOrderMgmtDetailResult);
  isUpdateLoading$ = this.store.select(selectors.getBkryMgmtOrderMgmtDetailState)
    .pluck('update', 'isLoading');
  formData$ = this.store.select(getNotesFormData);
  onFormSubmit$ = new Subject<any>();

  private formSubmitSub: Subscription;

  constructor(
    private store: Store<AppState>,
  ) { }

  ngOnInit() {
    this.formSubmitSub = this.onFormSubmit$
      .withLatestFrom(
        this.bakeryOrder$,
        (formData, bakeryOrder) => ({formData, bakeryOrder})
      )
      .subscribe(({formData, bakeryOrder}) => {
        // Update notes visibility.
        this.store.dispatch({
          type: Actions.SET_BKRY_MGMT_ORDER_MGMT_ORDER_DETAILS_NOTES_VISIBILITY,
          payload: formData.notesVisibility,
        });

        this.store.dispatch({
          type: Actions.REQUEST_UPDATE_BKRY_MGMT_ORDER_MGMT_ORDER_DETAILS,
          payload: {
            id: bakeryOrder.id,
            data: {
              bakery_notes: formData.bakery_notes,
              attached_images_attributes: formData.attached_images.map(attachedImage => attachedImage.image),
            }
          },
        });
      });
  }

  ngOnDestroy() {
    this.formSubmitSub.unsubscribe();
  }
}

@Component({
  selector: compSelector,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: require('./notes.component.html'),
})
export class NotesPrezComponent {
  @Input() isLoading = false;
  @Output() onFormSubmit$ = new Subject<any>();

  form: FormGroup;

  private _formData: any;
  private _bakeryOrder: any;

  constructor(
    private fb: FormBuilder,
  ) {
    this.form = this.fb.group({
      notesVisibility: [null, [Validators.required]],
      bakery_notes: [null, []],
      customer_notes: [{value: null, disabled: true}, []],
      attached_images: this.fb.array([]),
    });
  }

  @Input()
  set formData(formData: any) {
    this._formData = formData;

    this.resetForm();
  }

  get formData(): any {
    return this._formData;
  }

  @Input()
  set bakeryOrder(bakeryOrder: any) {
    this._bakeryOrder = bakeryOrder;

    this.resetForm();
  }

  get bakeryOrder(): any {
    return this._bakeryOrder;
  }

  get customer(): any {
    return this.bakeryOrder.parent;
  }

  get isFormDisabled(): boolean {
    return !this.form.dirty || this.isLoading || !this.form.valid;
  }

  get imageControls(): FormGroup[] {
    const imageControls = (this.form.get('attached_images') as FormArray).controls as FormGroup[];

    return imageControls.filter(imageControl => imageControl.value.image._destroy !== true);
  }

  onAddImageFile($event: Base64FileDetails) {
    console.log(`Added file:`, $event);
    const attachedImagesCtrlArray = this.form.get('attached_images') as FormArray;
    const imageFormGroup = this.fb.group({
      image: new FormControl({
        image: `data:${$event.mimeType};base64,${$event.encoded}`,
      })
    });

    attachedImagesCtrlArray.push(imageFormGroup);
    imageFormGroup.markAsDirty();
  }

  onFormSubmit() {
    if (this.isFormDisabled) { return; }

    this.onFormSubmit$.next(this.form.value);
  }

  onClickFormReset() {
    this.resetForm();
  }

  private resetForm() {
    this.form.reset(
      {
        ...this.formData,
        attached_images: [],
      },
      {emitEvent: false}
    );

    if (this.bakeryOrder) {
      const attachedImagesCtrlArray = this.form.get('attached_images') as FormArray;
      while (attachedImagesCtrlArray.length) {
        attachedImagesCtrlArray.removeAt(0);
      }

      this.bakeryOrder.attached_images.forEach(attachedImage => {
        const imageFormGroup = this.fb.group({
          image: new FormControl(attachedImage)
        });

        attachedImagesCtrlArray.push(imageFormGroup);
      });
    }
  }
}
