import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { UnsafeAction } from 'app/store/effects/unsafe-action';
import * as selectors from 'app/store/selectors';
import { Actions } from 'app/store/actions';
import { AppState } from 'app/store/app-state';
import { BakeryMgmtPromoCodesEffects } from 'app/store/effects/bakery-mgmt/bakery-mgmt-promo-codes.effects';
import { Actions as NgRxActions } from '@ngrx/effects';
import { AlertService } from 'app/shared/components/alerts/alert.service';
import { PromoCode } from 'app/store/promo-code';
import { BakeryCustEffects } from 'app/store/effects/bakery-cust/bakery-cust.effects';

@Component({
  selector: 'bkry-cust-checkout-customer-promo-code-edit',
  template: require('./checkout-promo-code-edit.component.html'),
})
export class BakeryCustCheckoutCustomerPromoCodeEditComponent implements OnInit, OnDestroy {
  promoCode$: Observable<string|null> = this.store
    .select(selectors.getCustomerCart)
    .map(x => x && x.promo_code && x.promo_code.code);

  form: FormGroup;
  isSubmitting = false;
  alertsSub: Subscription;
  currentBakerySub: Subscription;
  bakeryId: number;

  constructor(
    private store: Store<AppState>,
    private fb: FormBuilder,
    private alertService: AlertService,
    private bakeryMgmtPromoCodesEffects: BakeryMgmtPromoCodesEffects,
    private bakeryCustEffects: BakeryCustEffects,
    private actions$: NgRxActions,
  ) {
    this.form = this.fb.group({code: [null, [Validators.required]]});
  }

  ngOnInit() {
    this.currentBakerySub = this.bakeryCustEffects.currentBakery$.subscribe(bakery => {
      this.bakeryId = bakery.id;
    });

    this.alertsSub = this.actions$
      .subscribe((action: UnsafeAction) => {
        let messageType = null,
            messageContent = null;
        switch (action.type) {
          case Actions.REQUEST_GET_PROMO_CODES_SUCCESS:
            if (action.payload.promo_codes && Object.keys(action.payload.promo_codes).length === 1) {
              this.addPromoCodeToCart(Object.keys(action.payload.promo_codes).shift());
              this.form.reset();
              messageType = 'success';
              messageContent = 'Promotion Code added!';
            } else {
              messageType = 'warning';
              messageContent = 'Promotion Code does not exist!';
            }
            this.isSubmitting = false;
            break;
          case Actions.REQUEST_REMOVE_PROMO_CODE_FOR_RETAIL_SUCCESS:
            this.isSubmitting = false;
            break;
          case Actions.REQUEST_GET_PROMO_CODES_ERROR:
          case Actions.REQUEST_REMOVE_PROMO_CODE_FOR_RETAIL_ERROR:
            this.isSubmitting = false;
            messageType = 'warning';
            messageContent = 'Something went wrong!';
            break;
        }

        if (messageType != null && messageContent != null) {
          this.alertService[messageType](messageContent);
        }
      });
  }

  onClickAddPromoCode() {
    if (this.form.valid) {
      this.isSubmitting = true;
      this.bakeryMgmtPromoCodesEffects.requestGetPromoCodes(this.form.get('code').value, this.bakeryId);
    }
  }

  onClickRemovePromoCode() {
    this.isSubmitting = true;
    this.store.dispatch({ type: Actions.REQUEST_REMOVE_PROMO_CODE_FOR_RETAIL });
  }

  addPromoCodeToCart(promo_code_id: string) {
    this.store.dispatch({ type: Actions.REQUEST_ADD_PROMO_CODE_FOR_RETAIL, payload: { promo_code_id } });
  }


  ngOnDestroy() {
    this.currentBakerySub.unsubscribe();
    this.alertsSub.unsubscribe();
  }
}
