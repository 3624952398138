import { Component } from '@angular/core';

@Component({
  selector: 'tab-group',
  template: `
    <nav
      role="navigation"
      class="tabs print--hide"
    >
      <ul class="tab__list tab__list--light margin-bottom--charlie">
        <ng-content></ng-content>
      </ul>
    </nav>
  `,
})
export class TabGroupComponent { }
