import { Component } from '@angular/core';

@Component({
  template: require('./master.component.html'),
})
export class BakeryMgmtOrderMasterComponent {

  constructor(
  ) {
  }
}
