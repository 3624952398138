import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

import { Countries } from 'app/constants';

@Component({
  selector: 'zip-code-input',
  template: `
    <div [formGroup]="formGroup">
      <label class='{{ isRequired ? "label--required" : "" }}'>{{ zipOrPostalCode$ | async }}</label>
      <input
        type="tel"
        placeholder="{{ placeholder$ | async }}"
        [textMask]="{mask: (mask$|async)}"
        formControlName="zip"
      />
    </div>
  `
})
export class ZipCodeInputComponent implements OnInit, OnDestroy {
  @Input() countryCode$: Observable<string>;
  @Input() formGroup: FormGroup;
  @Input() isRequired = true;

  mask$: Observable<RegExp[]>;
  zipOrPostalCode$: Observable<string>;
  placeholder$: Observable<string>;

  private subscription: Subscription;

  ngOnInit() {
    this.mask$ = this.countryCode$
      .map(countryCode => {
        return {
          [Countries.ZAF]: [/\d/, /\d/, /\d/, /\d/],
          [Countries.USA]: [/\d/, /\d/, /\d/, /\d/, /\d/],
          [Countries.CAN]: [/[A-Z0-9]/, /[A-Z0-9]/, /[A-Z0-9]/, /[A-Z0-9]/, /[A-Z0-9]/, /[A-Z0-9]/],
        }[countryCode];
      })
      .startWith([]);

    this.zipOrPostalCode$ = this.countryCode$
      .map(countryCode => {
        return {
          [Countries.ZAF]: 'Postal Code',
          [Countries.USA]: 'Zip Code',
          [Countries.CAN]: 'Postal Code',
        }[countryCode];
      });

    this.placeholder$ = this.mask$.map(mask => mask.map((_, i) => i + 1).join(''));

    this.subscription = this.countryCode$
      .do(countryCode => {
        const pattern = {
          [Countries.ZAF]: '^[0-9]{4}',
          [Countries.USA]: '^[0-9]{5}',
          [Countries.CAN]: '^[A-Z0-9]{6}',
        }[countryCode];
        // Avoids the ExpressionChangedAfterItHasBeenCheckedError when loading
        // /bakery_mgmt/store_mgmt/locations/ID with a bakery that has a 4-digit
        // postal code (e.g., South Africa) but the country is set to a place with
        // a 5-digit zip code (e.g., United States). Or the other way around.
        setTimeout(
          () => this.formGroup.get('zip').setValidators([this.isRequired ? Validators.required : null, Validators.pattern(pattern)]),
          0
        );
      })
      .subscribe();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
