import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { User } from 'app/store/user';
import { UsersEffects } from 'app/store/effects/users.effects';
import { BakeryMgmtEffects } from 'app/store/effects/bakery-mgmt.effects';

@Component({
  selector: 'bakery-mgmt-dashboard-header',
  template: `
    <header aria-label="current-user">
      <div class="row center__parent">
        <div>
          <h3>Welcome, {{ (currentUser$|async)?.first_name }}!</h3>
          <p>Here is what's going on at your store right now.</p>
        </div>
        <!--
          <div *ngIf="(currentUser$|async)?.roles[0].name == 'bakery_owner' && (currentBakery$|async)?.bakery_subscription_status.status == 'trialing' "
            class="float--right margin-left--auto visibility--small-hide">
            <a routerLink="/subscription" class="button--secondary button--small clickable">Upgrade Account</a>
          </div>
        -->
        <div class="float--right margin-left--auto">
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSc-aJ9Tdffrrw_xomA7uHoV_nFFcdEzb19pC3Q8iv2pYep09g/viewform?usp=sf_link" target="_blank" rel="nooopener" class="button--small button--secondary-outline button--shadow">Add Daily Review</a>
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSf46zMAjM6zxa08-7HS7MHFSbAfxCVnhdfM7_mN83A8zIGPGQ/viewform?usp=sf_link" target="_blank" rel="nooopener" class="button--small button--danger-outline button--shadow">Report Issue</a>
        </div>
      </div>
    </header>
    <hr />
  `,
})
export class HeaderComponent implements OnInit {

  constructor(
    private usersEffects: UsersEffects,
    private bakeryMgmtEffects: BakeryMgmtEffects,
  ) { }

  get currentUser$(): Observable<User> {
    return this.usersEffects.currentUser$;
  }

  get currentBakery$() {
    return this.bakeryMgmtEffects.currentBakery$;
  }

  ngOnInit() { }
}
