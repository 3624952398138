import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { Actions } from 'app/store/actions';
import {
  SimplifiedResourceRequestState,
  updateResourceLoading,
  updateResourceComplete
} from 'app/store/reducers/request-states';

export interface State extends SimplifiedResourceRequestState<number> {
  notesVisibility: string;
  update: SimplifiedResourceRequestState<number>;
  refundStatus: 'notRequested' | 'loading' | 'failure' | 'success';
  orderItemIdsBeingEdited: Set<number>;
}

export const initialState: State = {
  detailId: null,
  isLoading: false,
  result: null,
  notesVisibility: 'both',
  update: {
    isLoading: false,
    result: null,
  },
  refundStatus: 'notRequested',
  orderItemIdsBeingEdited: new Set()
};

export function reducer(
  state: State = initialState,
  action: UnsafeAction
): State {
  switch (action.type) {
    case Actions.REQUEST_GET_BKRY_MGMT_ORDER_MGMT_ORDER:
      return {
        ...updateResourceLoading(state),
        detailId: action.payload,
        update: initialState.update,
      };

    case Actions.REQUEST_GET_BKRY_MGMT_ORDER_MGMT_ORDER_SUCCESS:
      return updateResourceComplete(state, action.payload);

    case Actions.SET_BKRY_MGMT_ORDER_MGMT_ORDER_DETAILS_NOTES_VISIBILITY:
      return {
        ...state,
        notesVisibility: action.payload
      };

    case Actions.REQUEST_UPDATE_BKRY_MGMT_ORDER_MGMT_ORDER_DETAILS:
      return {
        ...state,
        update: updateResourceLoading(state.update),
      };

    case Actions.REQUEST_UPDATE_BKRY_MGMT_ORDER_MGMT_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        update: updateResourceComplete(state.update, action.payload),
        orderItemIdsBeingEdited: new Set(),
      };

    case Actions.REQUEST_CANCEL_UPDATE_ORDER_ITEM:
      const orderItemIdsBeingEdited = new Set(state.orderItemIdsBeingEdited);
      orderItemIdsBeingEdited.delete(action.payload);
      return {
        ...state,
        orderItemIdsBeingEdited
      };

    case Actions.REQUEST_START_UPDATE_ORDER_ITEM:
      return {
        ...state,
        orderItemIdsBeingEdited: new Set(state.orderItemIdsBeingEdited).add(action.payload),
      };

    case Actions.BAKERY_ORDER_REFUND_REQUESTED:
      return { ...state, refundStatus: 'loading' };

    case Actions.BAKERY_ORDER_REFUND_SUCCEEDED:
      return { ...state, refundStatus: 'success' };

    case Actions.BAKERY_ORDER_REFUND_FAILED:
      return { ...state, refundStatus: 'failure' };


    default:
      return state;
  }
}
