import { Component, OnInit } from '@angular/core';
import { BakeryMgmtEffects } from 'app/store/effects/bakery-mgmt.effects';

@Component({
  selector: 'bakery-mgmt-store-main',
  template: `
    <bakery-mgmt-page-header title="Manage Products"></bakery-mgmt-page-header>
    <div class="tabs">
      <ul class="tab__list tab__list--light margin-bottom--charlie">
        <li class="tab__item"
          routerLink="active_products"
          routerLinkActive="is-active">
          Active Products
        </li>
        <li class="tab__item tab__item--sm-hide"
          *ngIf="advanced"
          routerLink="attributes"
          routerLinkActive="is-active">
          Attributes
        </li>
        <li class="tab__item tab__item--sm-hide"
          *ngIf="advanced"
          routerLink="templates"
          routerLinkActive="is-active">
          Templates
        </li>
        <li class="tab__item"
          routerLink="categories"
          routerLinkActive="is-active">
          Categories
        </li>
        <li class="tab__item"
          routerLink="archived_products"
          routerLinkActive="is-active">
          Archived Products
        </li>
        <li class="tab__item tab__item--sm-show"
          *ngIf="advanced">
          <popout-container class="popout__container">
            <popout-trigger text="More"></popout-trigger>
            <popout-items-list>
              <popout-list-item>
                <span routerLink="templates">Templates</span>
              </popout-list-item>
              <popout-list-item>
                <span routerLink="attributes">Attributes</span>
              </popout-list-item>
            </popout-items-list>
          </popout-container>
        </li>
      </ul>
    </div>
    <router-outlet></router-outlet>
  `
})
export class BakeryMgmtProductMainComponent implements OnInit {
  activeClass = false;

  advanced
  constructor(private bakeryMgmtEffects: BakeryMgmtEffects) {
    this.bakeryMgmtEffects.currentBakery$.subscribe(bakery => {
      this.advanced = bakery.advanced_products
    })
  }

  ngOnInit() { }

}
