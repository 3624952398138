import { AlertService } from './alert.service';
import { TypedAction, namespacedType } from 'app/store/actions/typed-action';

export interface AlertActionPayload {
  type?: string;
  message: string;
}

export class AlertAction extends TypedAction {
  static type = namespacedType('Alert', 'Add');

  static NOTIFICATION_TYPE = AlertService.NOTIFICATION_TYPE;
  static SUCCESS_TYPE = AlertService.SUCCESS_TYPE;
  static CAUTION_TYPE = AlertService.CAUTION_TYPE;
  static WARNING_TYPE = AlertService.WARNING_TYPE;

  constructor(public payload: AlertActionPayload) { super(payload) }

  static buildNotification(message: string): AlertAction {
    return new AlertAction({type: AlertService.NOTIFICATION_TYPE, message});
  }

  static buildSuccess(message: string): AlertAction {
    return new AlertAction({type: AlertService.SUCCESS_TYPE, message});
  }

  static buildCaution(message: string): AlertAction {
    return new AlertAction({type: AlertService.CAUTION_TYPE, message});
  }

  static buildWarning(message: string): AlertAction {
    return new AlertAction({type: AlertService.WARNING_TYPE, message});
  }
}
