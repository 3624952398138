import { ControlValueAccessor } from '@angular/forms';

export abstract class GenericControlValueInput<T> implements ControlValueAccessor {
  protected onChangedCallback: Function;
  protected onTouchedCallback: Function;

  registerOnChange(fn: Function) {
    this.onChangedCallback = fn;
  }

  registerOnTouched(fn: Function) {
    this.onTouchedCallback = fn;
  }

  abstract writeValue(value: T);
}
