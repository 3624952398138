import { Component, OnInit } from '@angular/core';

import { BakeryMgmtPromoCodesEffects } from 'app/store/effects/bakery-mgmt/bakery-mgmt-promo-codes.effects';

@Component({
  template: `
    <router-outlet></router-outlet>
  `
})
export class BakeryMgmtPromoCodesComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}
