import { Component, Input, Output, OnInit, ChangeDetectionStrategy, EventEmitter } from '@angular/core';

@Component({
  selector: 'on-button',
  template: `
    <button
      (click)="onClickSubmit()"
      [type]="buttonType"
      class="button--primary button--input"
      [ngClass]="ngClassValues"
    >
      <ng-template [ngIf]="!isLoading">
        <ng-content></ng-content>
      </ng-template>
      <ng-template [ngIf]="isLoading">
        <ng-content select="[data-loading]"></ng-content>
      </ng-template>
    </button>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OnButtonComponent {
  @Input() isEnabled = false;
  @Input() isLoading = false;
  @Input() isLabelOffset = false;
  @Input() buttonSize = 'full';
  @Input() buttonType = 'submit';
  @Output('onClick') onClick$ = new EventEmitter<void>();

  get ngClassValues(): {[key: string]: boolean} {
    return {
      'button--disabled': !this.isSubmittable,
      'label--offset': this.isLabelOffset,
      [`button--${this.buttonSize}`]: true
    };
  }

  get isSubmittable(): boolean {
    return this.isEnabled && !this.isLoading;
  }

  onClickSubmit() {
    if (!this.isSubmittable) return;

    this.onClick$.next();
  }
}
