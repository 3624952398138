import { Component, OnInit, Input, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { Store } from '@ngrx/store';

import { ProductCategory } from 'app/store/product-category';
import { AppState } from 'app/store/app-state';
import { AuthState } from 'app/store/auth-state';
import { ModalComponent } from 'app/shared/components/modals/modal.component';

@Component({
  selector: 'bakery-mgmt-prod-mgmt-categories-master-list-item',
  template: require('./list-item.component.html'),
})
export class MasterListItemComponent implements OnInit {
  @Input('productCategory') productCategory: ProductCategory;
  @Output('showDetails') showDetails$ = new Subject<ProductCategory>();
  @Output('deleteCategory') deleteCategory$ = new Subject<ProductCategory>();
  @ViewChild(ModalComponent) modal: ModalComponent;

  constructor(
    private router: Router,
    private store: Store<AppState>,
  ) { }

  ngOnInit() {
  }

  onClickDetails() {
    this.showDetails$.next(this.productCategory);
  }

  onClickDelete() {
    this.modal.openModal();
  }

  onConfirmDelete() {
    this.deleteCategory$.next(this.productCategory);
  }
}
