import { Injectable, TemplateRef } from '@angular/core';
import { Subject } from 'rxjs/Subject';

export class Modal {
  content: TemplateRef<any>;

  constructor(
    public title: string,
    public intro: string,
    public options: any,
  ) {
    this.content = <TemplateRef<any>> options.content;
  }

  onCancel() {
    if (typeof this.options.onCancel !== 'function') { return; }

    this.options.onCancel();
  }

  onConfirm() {
    if (typeof this.options.onConfirm !== 'function') { return; }

    this.options.onConfirm();
  }
}

export interface ModalAction {
  action: 'open'|'close';
  modal: Modal;
}

@Injectable()
export class ModalService {
  modalActions$ = new Subject<ModalAction>();

  constructor() { }

  openModal(title: string, intro: string, modalOptions: any) {
    const modal = new Modal(title, intro, modalOptions);

    this.modalActions$.next({
      action: 'open',
      modal: modal,
    });

    return modal;
  }

  closeModal(modal: Modal) {
    this.modalActions$.next({
      action: 'close',
      modal: modal,
    })
  }
}
