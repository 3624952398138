import {
  Component,
  Input,
  Output,
  OnDestroy,
  OnInit,
  EventEmitter,
  ChangeDetectionStrategy
} from '@angular/core';
import {
  AbstractControl,
  FormGroup,
  FormControl,
  Validators
} from '@angular/forms';
import { Observable, Subscription } from 'rxjs';

import { AppState } from 'app/store/app-state';
import { dateValidator, searchDateRangeValidatorBuilder } from 'app/shared/forms/date-validators';

export interface DateRange {
  searchStartDate: string;
  searchEndDate: string;
}

@Component({
  selector: 'date-range-search-form',
  template: `
    <form
      [formGroup]="form"
      class="center__parent"
    >
      <mat-form-field>
        <input
          matInput
          [matDatepicker]="searchStartDate"
          formControlName="searchStartDate"
          class="input__date margin-reset--bottom"
        >
          <mat-datepicker-toggle
            matSuffix
            [for]="searchStartDate"
          ></mat-datepicker-toggle>
        <mat-datepicker #searchStartDate></mat-datepicker>
      </mat-form-field>
      <label class="padding-left--delta padding-right--delta margin-top--echo text--grey-dark text--lowercase">to</label>
      <mat-form-field>
        <input
          matInput
          [matDatepicker]="searchEndDate"
          formControlName="searchEndDate"
          class="input__date margin-reset--bottom"
        >
        <mat-datepicker-toggle
          matSuffix
          [for]="searchEndDate"
        ></mat-datepicker-toggle>
        <mat-datepicker #searchEndDate></mat-datepicker>
      </mat-form-field>
    </form>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateRangeSearchFormComponent implements OnInit, OnDestroy {
  private _searchStartDate: string = null;
  private _searchEndDate: string = null;

  @Input()
  set searchStartDate(date: string) {
    this._searchStartDate = date;

    this.resetForm();
  }

  get searchStartDate(): string {
    return this._searchStartDate;
  }

  @Input()
  set searchEndDate(date: string) {
    this._searchEndDate = date;

    this.resetForm();
  }

  get searchEndDate(): string {
    return this._searchEndDate;
  }

  @Input() debounce = 500;
  @Output() onChangeDateRange = new EventEmitter<DateRange>();

  form: FormGroup;

  private formValuesSub: Subscription;

  constructor(
  ) {
    this.form = new FormGroup(
      {
        // TODO: These need date validators.
        searchStartDate: new FormControl(this.searchStartDate, [Validators.required, dateValidator]),
        searchEndDate: new FormControl(this.searchEndDate, [Validators.required, dateValidator])
      },
      searchDateRangeValidatorBuilder('searchStartDate', 'searchEndDate')
    );
  }

  ngOnInit() {
    this.formValuesSub = this.form.valueChanges
      // Only fire when form is valid
      .filter(val => this.form.valid)
      .debounce(() => Observable.timer(this.debounce))
      .subscribe(searchOptions => {
        this.onChangeDateRange.emit(searchOptions);
      });
  }

  ngOnDestroy() {
    this.formValuesSub.unsubscribe();
  }

  private resetForm() {
    this.form.reset(
      {
        searchStartDate: this.searchStartDate,
        searchEndDate: this.searchEndDate
      },
      {emitEvent: false}
    );
  }
}
