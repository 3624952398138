import { Component, Input } from '@angular/core';

@Component({
  selector: 'titled-card',
  template: `
    <div class="card padding--reset margin-bottom--charlie">
      <header class="row padding-top--delta padding-left--delta padding-right--delta">
        <h6 class="margin-reset--bottom float--left">
          {{ title }}
        </h6>
        <div class="float--right">
          <ng-content select="[data-card-header-right]"></ng-content>
        </div>
      </header>
      <hr [ngClass]="{'margin-reset--bottom':bodyPaddingReset}">
      <div
        [ngClass]="{
          'padding--reset':bodyPaddingReset,
          'padding-left--delta':!bodyPaddingReset,
          'padding-right--delta':!bodyPaddingReset
        }">
        <ng-content select="[data-card-body]"></ng-content>
      </div>
    </div>
  `,
})
export class TitledCardComponent {
  @Input() title: string;
  @Input() bodyPaddingReset = false;
}
