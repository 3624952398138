import { wrappingRange } from 'app/isWithinAvailabilityRange';

export type Hour =
   0 |  0.5 |  1 |  1.5 |  2 |  2.5 | 3  |  3.5 |  4 |  4.5 |  5 |  5.5 |  6 |  6.5 |  7 |  7.5 |  8 |  8.5 |  9 |  9.5 |
  10 | 10.5 | 11 | 11.5 | 12 | 12.5 | 13 | 13.5 | 14 | 14.5 | 15 | 15.5 | 16 | 16.5 | 17 | 17.5 | 18 | 18.5 | 19 | 19.5 |
  20 | 20.5 | 21 | 21.5 | 22 | 22.5 | 23 | 23.5;

const toTwelveHourTime = (hour: Hour): string => {
  if (hour === 0) { return '12:00 AM'; }
  if (hour === 12) { return '12:00 PM'; }
  return `${hour % 12}:00 ${hour / 12 >= 1 ? 'PM' : 'AM'}`;
};

export const toHours = (from: Hour, to: Hour): string[] =>
  wrappingRange<Hour>({ from, to, max: 23, min: 0 })
    .sort((x, y) => x - y)
    .map(toTwelveHourTime);

export const toFullHoursExceptLast = (from: Hour, to: Hour): string[] =>
  toHours(ceil(from), floor(to)).slice(0, -1);

const ceil = (hour: Hour): Hour => Math.ceil(hour as number) as Hour;
const floor = (hour: Hour): Hour => Math.floor(hour as number) as Hour;

export const ALL_HOURS = toHours(0, 23);

export const formatted = (hour?: Hour): string => {
  if (hour === null) { return ''; }
  const fullHour = Math.floor(hour);
  const formattedHour = fullHour % 12 === 0 ? 12 : fullHour % 12;
  const formattedMinute = hour - fullHour === 0 ? '00' : '30';
  const amPm = hour < 12 ? 'a.m.' : 'p.m.';
  return `${formattedHour}:${formattedMinute} ${amPm}`;
}
