// FIXME: This chip avatar is probably unnecessarily specific to source models.
// Probably should be rewritten to be more configurable and generic.

import { Component, OnInit, OnDestroy, Input } from '@angular/core';

@Component({
  selector: 'chip-avatar-small',
  template: `
    <div class="chip__avatar" [chipColor]="source.name">
      <ng-template [ngIf]="hasAvatar">
        <img [src]="source.avatar" width="40" height="40">
      </ng-template>
      <ng-template [ngIf]="!hasAvatar">
        <strong class="chip__avatar-text">{{ source.name | chipName }}</strong>
      </ng-template>
    </div>
  `,
  // changeDetection: Change
})
export class ChipAvatarSmallComponent implements OnInit, OnDestroy {
  @Input('source') source: {name: string, avatar: any};

  constructor(
  ) { }

  ngOnInit() { }

  ngOnDestroy() { }

  get hasAvatar() {
    return this.source.avatar != null;
  }
}
