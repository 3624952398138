import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { Store } from '@ngrx/store';
import { Angular2TokenService } from 'app/angular2-token/angular2-token.service';
import { Actions as NgRxActions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';
import { normalize, schema } from 'normalizr';

import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { AppState } from 'app/store/app-state';
import { EntitiesState } from 'app/store/entities-state';
import { Actions } from 'app/store/actions';
import { subscriptionPlanSchema } from 'app/store/schema/subscription-plan';
import {
  bakeryProductSchema,
  productCategorySchema,
 } from 'app/store/schema/default-schemata';
import { BakeryMgmtEffects } from 'app/store/effects/bakery-mgmt.effects';
import { ProductCategory } from 'app/store/product-category';
import { Bakery } from 'app/store/bakery';
import { basicRequestHandler, addEntityRequestHandler } from 'app/store/effects/helpers';

export interface MasterUiState {
  isLoading: boolean;
}

@Injectable()
export class BakeryMgmtProductCategoriesEffects {
  masterUiStates$: Observable<MasterUiState>;

  constructor(
    private actions$: NgRxActions,
    private tokenService: Angular2TokenService,
    private store: Store<AppState>,
    private bakeryMgmtEffects: BakeryMgmtEffects,
  ) { }

  requestGetProductCategories() {
    this.store.dispatch({
      type: Actions.REQUEST_GET_PRODUCT_CATEGORIES,
    });
  }

  requestGetProductCategory(id: number) {
    this.store.dispatch({
      type: Actions.REQUEST_GET_PRODUCT_CATEGORY,
      payload: id,
    });
  }

  requestGetProductCategoryDetails(id: number) {
    this.store.dispatch({
      type: Actions.REQUEST_GET_PRODUCT_CATEGORY_DETAILS,
      payload: id,
    });
  }

  requestCreateProductCategory(newCategoryDetails: any) {
    this.store.dispatch({
      type: Actions.REQUEST_CREATE_PRODUCT_CATEGORY,
      payload: newCategoryDetails,
    });
  }

  requestUpdateProductCategory(productCategory: ProductCategory, updateDetailsPayload: any) {
    this.store.dispatch({
      type: Actions.REQUEST_UPDATE_PRODUCT_CATEGORY,
      payload: {
        id: productCategory.id,
        payload: updateDetailsPayload,
      }
    });
  }

  requestDeleteProductCategory(productCategory: ProductCategory) {
    this.store.dispatch({
      type: Actions.REQUEST_DELETE_PRODUCT_CATEGORY,
      payload: productCategory.id,
    });
  }

  private currentBakeryLatestMap(action: UnsafeAction): Observable<{action: UnsafeAction, bakery: Bakery}> {
    return this.bakeryMgmtEffects.currentBakery$
      .filter(bakery => bakery != null)
      .map(bakery => ({action, bakery}))
      .take(1);
  }

  @Effect() getProductCategoriesEffects$ = this.actions$
    .ofType(Actions.REQUEST_GET_PRODUCT_CATEGORIES)
    .switchMap(this.currentBakeryLatestMap.bind(this))
    .switchMap(({action, bakery}) => {
      return addEntityRequestHandler(
        this.tokenService.get(`/api/bakeries/${bakery.id}/product_categories`),
        [productCategorySchema],
        Actions.REQUEST_GET_PRODUCT_CATEGORIES,
      );
    })
    .share();

  @Effect() getProductCategoryEffects$ = this.actions$
    .ofType(Actions.REQUEST_GET_PRODUCT_CATEGORY)
    .switchMap(this.currentBakeryLatestMap.bind(this))
    .switchMap(({action, bakery}) => {
      return addEntityRequestHandler(
        this.tokenService.get(`/api/bakeries/${bakery.id}/product_categories/${action.payload}`),
        productCategorySchema,
        Actions.REQUEST_GET_PRODUCT_CATEGORY,
      );
    })
    .share();

  @Effect() getProductCategoryDetailsEffects$ = this.actions$
    .ofType(Actions.REQUEST_GET_PRODUCT_CATEGORY_DETAILS)
    .switchMap(this.currentBakeryLatestMap.bind(this))
    .switchMap(({action, bakery}) => {
      return Observable.forkJoin(
        basicRequestHandler(
          this.tokenService.get(
            `/api/bakeries/${bakery.id}/product_categories/${action.payload}`
          ),
          productCategorySchema,
        ),
        basicRequestHandler(
          this.tokenService.get(
            `/api/bakeries/${bakery.id}/bakery_products`
          ),
          [bakeryProductSchema],
        ),
      );
    })
    .mergeMap((actions) => {
      return Observable.from([
        ...actions,
        {type: Actions.REQUEST_GET_PRODUCT_CATEGORY_DETAILS_SUCCESS}
      ]);
    })
    .share();

  @Effect() createProductCategoryEffects$ = this.actions$
    .ofType(Actions.REQUEST_CREATE_PRODUCT_CATEGORY)
    .switchMap(this.currentBakeryLatestMap.bind(this))
    .switchMap(({action, bakery}) => {
      return addEntityRequestHandler(
        this.tokenService.post(
          `/api/bakeries/${bakery.id}/product_categories`,
          JSON.stringify(action.payload)
        ),
        productCategorySchema,
        Actions.REQUEST_CREATE_PRODUCT_CATEGORY,
      );
    })
    .share();

  @Effect() updateProductCategoryEffects$ = this.actions$
    .ofType(Actions.REQUEST_UPDATE_PRODUCT_CATEGORY)
    .switchMap(this.currentBakeryLatestMap.bind(this))
    .switchMap(({action, bakery}) => {
      return addEntityRequestHandler(
        this.tokenService.put(
          `/api/bakeries/${bakery.id}/product_categories/${action.payload.id}`,
          JSON.stringify(action.payload.payload)
        ),
        productCategorySchema,
        Actions.REQUEST_UPDATE_PRODUCT_CATEGORY,
      );
    })
    .share();

  // TODO: This isn't supported by addEntityRequestHandler yet, as it's a
  // REMOVE_ENTITY action generator
  @Effect() deleteProductCategoryEffects$ = this.actions$
    .ofType(Actions.REQUEST_DELETE_PRODUCT_CATEGORY)
    .switchMap(this.currentBakeryLatestMap.bind(this))
    .switchMap(({action, bakery}) => {
      return this.tokenService
        .delete(
          `/api/bakeries/${bakery.id}/product_categories/${action.payload}`,
        )
        .map((response: Response) => response.json())
        .switchMap(() => {
          return Observable.from([
            {
              type: Actions.REMOVE_ENTITY,
              payload: {
                typeKey: 'product_categories',
                entityKey: action.payload,
              },
            },
            {
              type: Actions.REQUEST_DELETE_PRODUCT_CATEGORY_SUCCESS,
            },
          ]);
        })
        .catch((error) => {
          const errorPayload = typeof error.json === 'function' ? error.json() : error;

          return Observable.of({
            type: Actions.REQUEST_DELETE_PRODUCT_CATEGORY_ERROR,
            payload: error,
          });
        });
    })
    .share();
}
