// TODO: ListArchiveButton and SidebarArchiveButton are mostly redundant.

import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject, Subscription } from 'rxjs';

import { AppState } from 'app/store/app-state';
import * as selectors from 'app/store/selectors';
import { wholesalerOrgSchema } from 'app/store/schema/default-schemata';
import { PutAction, PutActionPayload } from 'app/store/actions/request.actions';
import { WholesalerOrg } from 'app/store/wholesaler-org';
import { AlertAction } from 'app/shared/components/alerts/actions';

const opKey = 'wholesalerDetailUpdateArchive',
      schema = wholesalerOrgSchema;

@Component({
  selector: 'bkry-mgmt-wholesaler-org-sidebar-archive-button',
  template: `
    <button
      class="button--primary button--full"
      (click)="onClick()"
      [ngClass]="{'button--disabled': isLoading$ | async}"
    >
      <ng-template [ngIf]="!(isLoading$ | async)">
        <ng-template [ngIf]="!wholesalerOrg.archived">Archive Customer</ng-template>
        <ng-template [ngIf]="wholesalerOrg.archived">Unarchive Customer</ng-template>
      </ng-template>
      <ng-template [ngIf]="(isLoading$ | async)">Updating</ng-template>
    </button>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarArchiveButton implements OnInit, OnDestroy {
  @Input() wholesalerOrg: any;

  isLoading$: Observable<boolean> = this.store
    .select(selectors.getRequestIsLoading(opKey))

  op$ = new Subject<void>();

  private opSub: Subscription;
  private opSuccessSub: Subscription;

  constructor(
    private store: Store<AppState>,
  ) { }

  ngOnInit() {
    this.opSub = this.op$
      .switchMap(() => this.isLoading$.take(1))
      .filter(isLoading => !isLoading)
      .subscribe(() => {
        const actionName = this.wholesalerOrg.archived ? 'unarchive' : 'archive';
        const actionPayload: PutActionPayload = {
          url: `/api/wholesalers/${this.wholesalerOrg.id}/${actionName}`,
          opKey: opKey,
          schema: schema,
          body: {},
          successChainAction: AlertAction.buildSuccess(`The wholesale customer has been ${actionName}d.`)
        }
        this.store.dispatch(new PutAction(actionPayload));
      });
  }

  ngOnDestroy() {
    this.opSub.unsubscribe();
  }

  onClick() {
    this.op$.next();
  }
}
