import { Component, Input } from '@angular/core';

@Component({
  selector: 'bakery-mgmt-page-header-detail',
  template: `
    <header
      class="bakery-application-page__header print--hide layout-flex layout-flex--center-stretched"
      [ngClass]="{'bakery-application-page__header--tabs': hasTabs}"
    >
      <div>
        <a routerLink="{{route}}">{{ routeTitle }}</a>
        <h3 class="margin-top--delta">{{ title }}</h3>
      </div>
      <div>
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSc-aJ9Tdffrrw_xomA7uHoV_nFFcdEzb19pC3Q8iv2pYep09g/viewform?usp=sf_link" target="_blank" rel="nooopener" class="button--small button--secondary-outline button--shadow">Add Daily Review</a>
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSf46zMAjM6zxa08-7HS7MHFSbAfxCVnhdfM7_mN83A8zIGPGQ/viewform?usp=sf_link" target="_blank" rel="nooopener" class="button--small button--danger-outline button--shadow">Report Issue</a>
      </div>
    </header>
  `
})
export class BakeryMgmtPageHeaderDetailComponent {
  @Input() title: string;
  @Input() route: string;
  @Input() routeTitle: string;
  @Input() hasTabs = false;

  constructor() { }
}
