import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { Actions } from 'app/store/actions';
import {
  SimplifiedResourceRequestState,
  updateResourceLoading,
  updateResourceComplete
} from 'app/store/reducers/request-states';

export interface State extends SimplifiedResourceRequestState<number[]> {
  query_params: {
    archived?: boolean;
    name?: string;
  };
  invite: SimplifiedResourceRequestState<number>;
}

export const initialState: State = {
  isLoading: false,
  result: null,
  query_params: {},
  invite: {isLoading: false, result: null}
};

export function reducer(
  state: State = initialState,
  action: UnsafeAction
): State {
  switch (action.type) {
    case Actions.CHANGE_BKRY_MGMT_WHOLESALERS_ORDERS_FILTERS:
      return {
        ...state,
        query_params: {
          ...state.query_params,
          ...action.payload,
        },
      };

    case Actions.REQUEST_GET_BKRY_MGMT_WHOLESALERS:
      return updateResourceLoading(state);

    case Actions.REQUEST_GET_BKRY_MGMT_WHOLESALERS_SUCCESS:
      return updateResourceComplete(state, action.payload);

    case Actions.REQUEST_INVITE_BKRY_WHOLESALER:
      return {
        ...state,
        invite: updateResourceLoading(state.invite)
      };

    case Actions.REQUEST_INVITE_BKRY_WHOLESALER_SUCCESS:
      return {
        ...state,
        invite: updateResourceComplete(state.invite, action.payload)
      };

    default:
      return state;
  }
}
