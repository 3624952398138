import {
  Component,
  Input,
  Output,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy
} from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Actions as NgRxActions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import * as Rx from 'rxjs';

import { AlertService } from 'app/shared/components/alerts/alert.service';
import { AppState } from 'app/store/app-state';
import { Actions } from 'app/store/actions';
import { BakeryMgmtProductsEffects } from 'app/store/effects/bakery-mgmt/bakery-mgmt-products.effects';
import { BakeryProduct } from 'app/store/bakery-product';
import * as selectors from 'app/store/selectors';
import { Identifiable } from 'app/store/identifiable';

const compSelector = 'bkry-mgmt-wholesaler-group-list-prez';

@Component({
  template: `
    <${compSelector}
      [wholesalerGroups]="wholesalerGroups$ | async"
      [isLoading]="isLoading$ | async"
    ></${compSelector}>
  `
})
export class GroupListComponent implements OnInit {
  wholesalerGroups$ = this.store
    .select(selectors.bkryMgmtWholesalerGroup.getMasterResults);
  isLoading$ = this.store
    .select(selectors.bkryMgmtWholesalerGroup.getMasterIsLoading);

  constructor(
    private bkryMgmtProductsEffects: BakeryMgmtProductsEffects,
    private actions$: NgRxActions,
    private store: Store<AppState>,
    private router: Router,
    private alertService: AlertService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.store.dispatch({
      type: Actions.CHANGE_BKRY_MGMT_WHOLESALER_GROUPS_FILTERS,
      payload: {
        name: '',
      },
    });
  }
}

@Component({
  selector: compSelector,
  template: require('./group-list.component.html'),
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupListPrezComponent {
  @Input() wholesalerGroups: any[];
  @Input() isLoading: boolean;

  get hasResults(): boolean {
    return !!this.wholesalerGroups && this.wholesalerGroups.length > 0;
  }
}
