import { Component, OnInit, OnDestroy, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { Subject } from 'rxjs/Subject';

import { ProductCategory } from 'app/store/product-category';
import { SimpleFormState } from 'app/shared/forms/form-states';
import { validateNoWhitespaces } from 'app/shared/forms/no-whitespaces-validator';

@Component({
  selector: 'bakery-mgmt-product-category-detail-edit',
  template: require('./category-detail-edit.component.html'),
})
export class BakeryMgmtProductCategoryDetailEditComponent implements OnInit, OnDestroy {
  @Input('productCategory$') productCategory$: Observable<ProductCategory>;
  @Input('success$') success$: Observable<any>;
  @Input('reset$') reset$: Observable<any>;
  @Output('submitForm') submitForm$ = new Subject<any>();
  @Output('formState') formState$: Observable<SimpleFormState>;

  form: FormGroup;

  private filteredProdCat$: Observable<ProductCategory>;
  private resetFormEvents = new EventEmitter<any>();
  private formValuesSub: Subscription;
  private resetFormSub: Subscription;

  constructor(
  ) {
    this.form = new FormGroup({
      name: new FormControl(null, [Validators.required, validateNoWhitespaces]),
    });

    this.formState$ = this.form.valueChanges
      .map((): SimpleFormState => {
        return {
          isDirty: this.form.dirty,
          isValid: this.form.valid,
          value: this.form.value,
        };
      });
  }

  ngOnInit() {
    this.filteredProdCat$ = this.productCategory$
      .filter((productCategory: ProductCategory) => productCategory != null);

    this.formValuesSub = this.filteredProdCat$
      .subscribe((productCategory: ProductCategory) => {
        this.setFormValues(productCategory);
      });

    this.resetFormSub = this.reset$
      .switchMap(() => this.filteredProdCat$.take(1))
      .subscribe((productCategory: ProductCategory) => this.resetForm(productCategory));

    this.success$
      .switchMap(() => {
        return this.productCategory$
          .filter(productCategory => productCategory != null)
          .take(1)
      })
      .subscribe((productCategory: ProductCategory) => this.resetForm(productCategory));
  }

  ngOnDestroy() {
    this.formValuesSub.unsubscribe();
    // this.resetFormSub.unsubscribe();
  }

  private resetForm(productCategory: ProductCategory) {
    this.form.reset(this.buildFormValue(productCategory));
    this.form.reset(this.buildFormValue(productCategory));
  }

  private setFormValues(productCategory: ProductCategory) {
    this.form.setValue(this.buildFormValue(productCategory));
  }

  private buildFormValue(productCategory: ProductCategory) {
    return {
      name: productCategory.name,
    };
  }

  onClickResetForm() {
    this.resetFormEvents.next();
  }

  onSubmitForm() {
    const updatePayload = {
      name: this.form.value.name,
    };

    this.submitForm$.next(updatePayload);
  }
}
