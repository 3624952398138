import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { Actions as NgRxActions } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import * as selectors from 'app/store/selectors';
import { AppState } from 'app/store/app-state';
import { EntitiesState } from 'app/store/entities-state';
import { Actions } from 'app/store/actions';
import { User } from 'app/store/user';
import { AlertService } from 'app/shared/components/alerts/alert.service';
import { UsersEffects } from 'app/store/effects/users.effects';
import { BakeryMgmtCustomersEffects } from 'app/store/effects/bakery-mgmt/bakery-mgmt-customers.effects';
import { CustomersDetailUiState } from 'app/store/bakery-mgmt-ui-state';

export interface UiState {
  isLoading: boolean;
  user?: User;
}

@Component({
  template: `
    <bakery-mgmt-customers-edit-basic-details
      [user$]="user$"
      [countryCode$]="countryCode$"
    ></bakery-mgmt-customers-edit-basic-details>
    <bakery-mgmt-customer-credit-card
      [user$]="user$"
    ></bakery-mgmt-customer-credit-card>
  `
})
export class BakeryMgmtCustomersDetailSettingsComponent implements OnInit {
    countryCode$: Observable<string>;
    uiState$: Observable<UiState>;
    user$: Observable<User>;

    constructor(
      private actions$: NgRxActions,
      private store: Store<AppState>,
    ) { }

  ngOnInit() {
    this.uiState$ = Observable
      .combineLatest(
        this.store.select('bakeryMgmtUiState', 'customersUiState', 'detailState'),
        this.store.select('entitiesState'),
        (detailState, entitiesState) => ({detailState, entitiesState})
      )
      .map((combined) => {
        const detailState = <CustomersDetailUiState> combined.detailState;
        const entitiesState = <EntitiesState> combined.entitiesState;

        if (
          detailState.state == null ||
          detailState.state == 'loading' ||
          entitiesState.users[detailState.detailId] == null
        ) {
          return {
            isLoading: true,
          };
        }

        const user = {...entitiesState.users[detailState.detailId]};

        return {
          isLoading: false,
          user: user,
        };
      })
      .startWith({isLoading: true})
      .shareReplay(1);

    this.user$ = this.uiState$
      .filter(uiState => !uiState.isLoading)
      .pluck('user');

    this.countryCode$ = this.store
      .select(selectors.getCurrentBkryMgmtBakery)
      .skipWhile(x => !x)
      .pluck('country_code');
  }
}
