import { Component, Input } from '@angular/core';

import { BakeryOrderInvoice } from 'app/store/bakery-order-invoice';

@Component({
  selector: 'invoice-payment-status-badge',
  template: `
    <span [ngClass]="cssClass">
      {{ status }}
    </span>
  `,
})
export class InvoicePaymentStatusBadgeComponent {
  @Input() invoice: BakeryOrderInvoice;

  get cssClass(): string {
    switch (this.invoice.payment_status) {
      case 'unpaid': {
        return 'text--yellow';
      }
      case 'void': {
        return 'text--grey-dark';
      }
      case 'paid': {
        return 'text--green';
      }
    }
  }

  get status(): string {
    switch (this.invoice.payment_status) {
      case 'unpaid': {
        return 'Pending';
      }
      case 'void': {
        return 'Void';
      }
      case 'paid': {
        return 'Paid';
      }
    }
  }
}
