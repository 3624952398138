import { Component, Output, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import * as Rx from 'rxjs/Subject';

export interface Base64FileDetails {
  encoded: string;
  mimeType: string;
}

// class="button--secondary button--small margin-bottom--delta clickable"
@Component({
  selector: 'file-upload-trigger',
  template: `
    <label
      for="fileUploadTrigger"
    >
      <ng-content></ng-content>
    </label>
    <form #fileForm>
      <input
        style="display: none;"
        id="fileUploadTrigger"
        type="file"
        (change)="onChangeFileUpload($event)">
    </form>
  `
})
export class FileUploadTriggerComponent {
  @Output('onFileUpload') onFileUpload$ = new Rx.Subject<Base64FileDetails>();
  @ViewChild('fileForm') fileFormElement: ElementRef;

  onChangeFileUpload($event) {
    const file = $event.target.files[0];
    const fileReader = new FileReader();
    let fileBinResults = null;
    fileReader.onload = (readerEvent) => {
      fileBinResults = (<any>readerEvent.target).result;

      this.onFileUpload$.next({
        encoded: btoa(fileBinResults),
        mimeType: file.type,
      });
    };

    fileReader.readAsBinaryString(file);
  }
}
