import { Component, OnInit, OnDestroy, Input, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

import { BakeryProduct } from 'app/store/bakery-product';
import { SimpleFormState } from 'app/shared/forms/form-states';
import { DetailUiState } from 'app/bakery-mgmt/product-mgmt/products/detail-ui-state';
import { validateNoWhitespaces } from 'app/shared/forms/no-whitespaces-validator';

@Component({
  selector: 'product-detail-forms-edit-description',
  template: `
    <form [formGroup]="form">
      <div class="card padding--reset margin-bottom--charlie">
        <header class="padding-top--delta padding-left--delta padding-right--delta">
          <h6 class="margin-reset--bottom">Product Description</h6>
        </header>
        <hr />
        <div class="padding-left--delta padding-right--delta">
          <div gridRow>
            <div gridCol s="4" m="9" l="12">
              <label class="label--required">Name</label>
              <input
                type="text"
                placeholder="Product name"
                formControlName="name"
                required
              />
            </div>
          </div>
          <div gridRow>
            <div gridCol s="4" m="9" l="12">
              <label>Description</label>
              <textarea
                type="text"
                placeholder="Short product description"
                formControlName="description"
              ></textarea>
            </div>
          </div>
          <div gridRow class="padding-bottom--delta">
            <div gridCol s="4" m="9" l="12">
              <label>
                <input type="checkbox" name="allow_inscription" formControlName="allow_inscription" />
                Allow Cake Inscription
              </label>
              <tooltip
                title="What is Cake Inscription?"
                text="It's an option for customers to require message written on this product (e.g. Happy Birthday Paul)">
              </tooltip>
            </div>
          </div>
        </div>
      </div>
    </form>
  `
})
export class ProductDetailFormsEditDescriptionComponent implements OnInit, OnDestroy {
  @Input('uiState$') uiState$: Observable<DetailUiState>;
  @Input('reset$') reset$: Observable<any>;
  @Output('formState') formState$: Observable<SimpleFormState>;

  form: FormGroup;

  private formValueSub: Subscription;

  constructor(
  ) {
    this.form = new FormGroup({
      name: new FormControl(null, [Validators.required, validateNoWhitespaces]),
      description: new FormControl(null, []),
      allow_inscription: new FormControl(null, []),
    });

    this.formState$ = this.form.valueChanges
      .map((): SimpleFormState => {
        return {
          isDirty: this.form.dirty,
          isValid: this.form.valid,
          value: this.form.value,
        };
      })
      // .do(formState => console.log(`descriptionFormState:`, formState))
  }

  ngOnInit() {
    this.formValueSub = Observable
      .merge(
        this.uiState$.filter((uiState: DetailUiState) => !uiState.isLoading),
        this.reset$.switchMap(() => this.uiState$.filter((uiState: DetailUiState) => !uiState.isLoading).take(1))
      )
      .map((uiState: DetailUiState) => uiState.bakeryProduct)
      // .do(bakeryProduct => console.log(`resetting with:`, bakeryProduct))
      .subscribe((bakeryProduct: BakeryProduct) => {
        // FIXME: For some reason, a true form reset requires calling #reset twice...
        this.resetForm(bakeryProduct);
        this.resetForm(bakeryProduct);
      })
  }

  ngOnDestroy() {
    this.formValueSub.unsubscribe();
  }

  private resetForm(bakeryProduct: BakeryProduct) {
    this.form.reset({
      name: bakeryProduct.name,
      description: bakeryProduct.description,
      allow_inscription: bakeryProduct.allow_inscription,
    });
  }
}
