import { Component, EventEmitter, Input, OnInit, OnDestroy, Output } from '@angular/core';
import { AbstractControl, FormGroup, FormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

import { User } from 'app/store/user';
import { phoneNumberValidator } from 'app/shared/forms/form-number-validator';
import { UsersEffects } from 'app/store/effects/users.effects';
import { validateNoWhitespaces } from 'app/shared/forms/no-whitespaces-validator';

const REQUIRED_ADDRESS_CONTROL_NAMES = ['street', 'city', 'state', 'zip'];

@Component({
  selector: 'bakery-mgmt-customers-edit-basic-details',
  template: require('./edit-basic-details.component.html'),
})
export class BakeryMgmtCustomersEditBasicDetailsComponent implements OnInit, OnDestroy {
  @Input() user$: Observable<User>;
  @Input() countryCode$: Observable<string>;
  formReset$ = new Subject<any>();
  formSubmit$ = new Subject<any>();
  form: FormGroup;

  private formResetSub: Subscription;
  private formSubmitSub: Subscription;

  constructor(
    private usersEffects: UsersEffects,
  ) { }

  ngOnInit() {
    this.form = new FormGroup(
      {
        first_name: new FormControl(null, [Validators.required, validateNoWhitespaces]),
        last_name: new FormControl(null, [Validators.required, validateNoWhitespaces]),
        email: new FormControl(null, [optionalEmailValidator]),
        phone_number: new FormControl(null, [phoneNumberValidator]),
        street: new FormControl(null, []),
        unit: new FormControl(null, []),
        city: new FormControl(null, []),
        state: new FormControl(null, []),
        zip: new FormControl(null, []),
      },
      allOrNothing(REQUIRED_ADDRESS_CONTROL_NAMES)
    );

    this.formResetSub = Observable
      .merge(
        this.user$,
        this.formReset$.switchMap(() => this.user$.take(1))
      )
      .subscribe(user => this.resetForm(user));

    this.formSubmitSub = this.formSubmit$
      .switchMap(() => this.user$.take(1))
      .withLatestFrom(this.form.valueChanges)
      .subscribe(([user, formValue]) => {
        if (this.form.invalid) { return; }
        const values = REQUIRED_ADDRESS_CONTROL_NAMES.map(field => this.form.get(field).value);
        const default_address_attributes = areAll(isEmpty, values) ?
          null :
          {
            id: user.default_address_id,
            parent_type: 'User',
            parent_id: user.id,
            street: formValue.street,
            unit: formValue.unit,
            city: formValue.city,
            state: formValue.state,
            zip: formValue.zip,
          };

          const preparedFormValues = {
            id: user.id,
            first_name: formValue.first_name,
            last_name: formValue.last_name,
            phone_number: formValue.phone_number,
            email: formValue.email,
            default_address_attributes,
          };

        this.usersEffects.requestUserDetailsUpdate(preparedFormValues);
      });
  }

  ngOnDestroy() {
    this.formResetSub.unsubscribe();
  }

  private resetForm(user: User) {
    let newFormValues = null;

    if (user.default_address != null) {
      newFormValues = {
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        phone_number: user.phone_number,
        street: user.default_address.street,
        unit: user.default_address.unit,
        city: user.default_address.city,
        state: user.default_address.state,
        zip: user.default_address.zip,
      };
    } else {
      newFormValues = {
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        phone_number: user.phone_number,
      };
    }

    this.form.reset(newFormValues);
  }

}

const allOrNothing = (controlName) => (formGroup: AbstractControl): { [key: string]: boolean } => {
  const values = controlName.map(field => formGroup.get(field).value);
  if (areAll(isEmpty, values) || areAll(isPresent, values)) { return; }
  return { invalid_address: true };
}

const isEmpty = string => string.length === 0;
const isPresent = string => string.length > 0;
const areAll = (predicate, values) => values
  .map(maybeValue => maybeValue || '')
  .map(value => value.trim())
  .every(predicate);

const optionalEmailValidator = (control: AbstractControl): { [key: string]: boolean } => {
  if ((control.value || '').length === 0) { return; }
  return Validators.email(control);
}
