import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { Actions } from 'app/store/actions';
import { SimplifiedResourceRequestState, updateResourceLoading, updateResourceComplete } from 'app/store/reducers/request-states';

export interface State extends SimplifiedResourceRequestState<any> {}

export const initialState: State = {
  isLoading: false,
  result: null,
};

export function reducer(
  state: State = initialState,
  action: UnsafeAction
): State {
  switch (action.type) {
    case Actions.REQUEST_GET_BKRY_MGMT_DASHBOARD:
      return updateResourceLoading(state);

    case Actions.REQUEST_GET_BKRY_MGMT_DASHBOARD_SUCCESS:
      return updateResourceComplete(state, action.payload);

    default:
      return state;
  }
}
