import { Component, OnInit } from '@angular/core';

@Component({
  template: `
    <router-outlet></router-outlet>
  `
})
export class WholesalersComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}
