import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { Actions } from 'app/store/actions';

export interface State {
  data: any[];
  printableData: any[];
  top: number;
  bottom: number;
  total: number;
  taxableAmount: number;
  taxAmount: number;
}

const INITIAL_STATE = {
  data: [],
  printableData: [],
  top: 0,
  bottom: 0,
  total: 0,
  taxableAmount: 0,
  taxAmount: 0
};

export function reducer(state: State = INITIAL_STATE, action: UnsafeAction): State {
  switch (action.type) {
    case Actions.REQUEST_PRINTABLE_TAX_REPORT:
      return {
        ...state,
        printableData: [],
      };

    case Actions.REQUEST_TAX_REPORT_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        top: action.payload.top,
        bottom: action.payload.bottom,
        total: action.payload.total,
        taxableAmount: action.payload.taxable_amount,
        taxAmount: action.payload.tax_amount
      };

    case Actions.REQUEST_PRINTABLE_TAX_REPORT_SUCCESS:
      return {
        ...state,
        printableData: action.payload,
      };

    default:
      return state;
  }
}
