import {
  Component,
  forwardRef,
  HostBinding,
  Input,
  ElementRef,
  ViewChild
} from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR
} from '@angular/forms';

import { GenericControlValueInput } from 'app/shared/components/form/generic-control-value-input';
import { buildForwardRef } from 'app/shared/components/form/input-forward-ref-builder';

@Component({
  selector: 'phone-input',
  template: `
    <input
      type="tel"
      [placeholder]="placeholder"
      [textMask]="{mask: mask}"
      [(ngModel)]="value"
      [readonly]="readonly"
      (ngModelChange)="onChange($event)"
    />
  `,
  providers: buildForwardRef(PhoneInputComponent)
})
export class PhoneInputComponent extends GenericControlValueInput<string> {
  @Input() placeholder = '(999) 999-9999';
  @Input() mask: any[] = [
    '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/
  ];
  @Input() readonly = false;

  value: string = null;

  onChange(rawValue: string) {
    // Remove mask chars from phone number text
    const cleanedValue = rawValue.replace(/[^0-9]/gi, '')

    this.onChangedCallback(cleanedValue);
  }

  writeValue(val: string) {
    this.value = val;
  }
}
