export const Constants = {
  DELIVERY_ONLY: 'delivery_only',
  DELIVERY_AND_PICKUP: 'delivery_and_pickup',
  PICKUP_ONLY: 'pickup_only',
  NONE: 'none',
};

export const Countries = {
  USA: 'USA',
  ZAF: 'ZAF',
  CAN: 'CAN',
};
