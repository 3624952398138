import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { UsersEffects } from 'app/store/effects/users.effects';
import { User } from 'app/store/user';
import { hasRole } from 'app/store/users/utils';

@Directive({
  selector: '[roleRestricted]',
})
export class RoleRestrictedDirective implements OnInit {
  @Input('roleRestricted') roleNameInput: any;
  roleNames: string[];

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private usersEffects: UsersEffects,
  ) { }

  ngOnInit() {
    if (this.roleNameInput.length) {
      this.roleNames = [...this.roleNameInput];
    } else {
      this.roleNames = this.roleNameInput.split(',').map(roleName => roleName.trim());
    }

    this.viewContainer.clear();

    this.usersEffects.currentUser$
      .forEach((currentUser: User) => {
        const userHasRequiredRole = hasRole(currentUser, ...this.roleNames);

        if (userHasRequiredRole) {
          this.viewContainer.clear();
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainer.clear();
        }
      });
  }
}
