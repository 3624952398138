import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Actions as NgRxActions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { AppState } from 'app/store/app-state';
import { AlertService } from 'app/shared/components/alerts/alert.service';
import { Actions } from 'app/store/actions';
import * as selectors from 'app/store/selectors';
import { BakeryOrder } from 'app/store/bakery-order';

@Component({
  template: require('./detail.component.html'),
})
export class OrderDetailComponent implements OnInit, OnDestroy {
  bakeryOrder$: Observable<BakeryOrder>;

  private orderId$: Observable<number>;
  private orderIdSub: Subscription;
  private alertsSub: Subscription;

  constructor(
    private route: ActivatedRoute,
    private actions$: NgRxActions,
    private store: Store<AppState>,
    private alertService: AlertService,
  ) {
  }

  ngOnInit() {
    this.bakeryOrder$ = this.store.select(selectors.getBkryMgmtOrderMgmtDetailResult);

    this.orderId$ = this.route.params
      .map((params): number => parseInt(params['order_id'], 10));

    this.orderIdSub = this.orderId$
      .subscribe((id: number) => {
        this.store.dispatch({
          type: Actions.REQUEST_GET_BKRY_MGMT_ORDER_MGMT_ORDER,
          payload: id,
        });
      });

    this.alertsSub = this.actions$
      .subscribe((action: UnsafeAction) => {
        let messageType = null,
            messageContent = null;

        switch (action.type) {
          case Actions.REQUEST_UPDATE_BKRY_MGMT_ORDER_MGMT_ORDER_DETAILS_SUCCESS:
          case Actions.REQUEST_BKRY_MGMT_ORDER_MGMT_CHANGE_ORDER_STATUS_SUCCESS:
            messageType = 'success';
            messageContent = 'The order has been updated.';
            break;

          case Actions.REQUEST_BKRY_MGMT_ORDER_MGMT_CHANGE_ORDER_STATUS_ERROR:
            messageType = 'warning';
            messageContent = action.payload.error || 'Something went wrong!';
            break;

          case Actions.REQUEST_UPDATE_BKRY_MGMT_ORDER_MGMT_ORDER_DETAILS_ERROR:
            messageType = 'warning';
            messageContent = action.payload.error || 'Something went wrong!';
            break;
        }

        if (messageType != null && messageContent != null) {
          this.alertService[messageType](messageContent);
        }
      });
  }

  ngOnDestroy() {
    this.alertsSub.unsubscribe();
    this.orderIdSub.unsubscribe();
  }
}
