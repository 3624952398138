import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

import { PaymentCard } from 'app/store/payment-card';

@Component({
  selector: 'payment-card-text',
  template: `
    {{ paymentCard.brand }} - {{ paymentCard.last4 }} - expires {{ paymentCard.exp_month | number:'2.0' }}/{{ paymentCard.exp_year}}
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentCardTextComponent {
  @Input() paymentCard: PaymentCard;
}
