import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { Store } from '@ngrx/store';
import { Angular2TokenService } from 'app/angular2-token/angular2-token.service';
import { Actions as NgRxActions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';
import { normalize, schema } from 'normalizr';

import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { AppState } from 'app/store/app-state';
import { EntitiesState } from 'app/store/entities-state';
import { Actions } from 'app/store/actions';
import { subscriptionPlanSchema } from 'app/store/schema/subscription-plan';
import {
  bakeryProductSchema,
  bakeryAttributeSchema
} from 'app/store/schema/default-schemata';
import { BakeryMgmtEffects } from 'app/store/effects/bakery-mgmt.effects';
import { BakeryAttribute } from 'app/store/bakery-attribute';
import { Bakery } from 'app/store/bakery';
import {
  basicRequestHandler,
  addEntityRequestHandler
} from 'app/store/effects/helpers';

export interface MasterUiState {
  isLoading: boolean;
}

@Injectable()
export class BakeryMgmtProductAttributesEffects {
  masterUiStates$: Observable<MasterUiState>;

  constructor(
    private actions$: NgRxActions,
    private tokenService: Angular2TokenService,
    private store: Store<AppState>,
    private bakeryMgmtEffects: BakeryMgmtEffects
  ) {}

  requestGetBakeryAttributes() {
    this.store.dispatch({
      type: Actions.REQUEST_GET_BAKERY_ATTRIBUTES
    });
  }

  requestGetBakeryAttribute(id: number) {
    this.store.dispatch({
      type: Actions.REQUEST_GET_BAKERY_ATTRIBUTE,
      payload: id
    });
  }

  requestGetBakeryAttributeDetails(id: number) {
    this.store.dispatch({
      type: Actions.REQUEST_GET_BAKERY_ATTRIBUTE_DETAILS,
      payload: id
    });
  }

  requestCreateBakeryAttribute(newAttributeDetails: any) {
    this.store.dispatch({
      type: Actions.REQUEST_CREATE_BAKERY_ATTRIBUTE,
      payload: newAttributeDetails
    });
  }

  requestUpdateBakeryAttribute(
    attribute: BakeryAttribute,
    updateDetailsPayload: any
  ) {
    this.store.dispatch({
      type: Actions.REQUEST_UPDATE_BAKERY_ATTRIBUTE,
      payload: {
        id: attribute.id,
        payload: updateDetailsPayload
      }
    });
  }

  requestDeleteBakeryAttribute(bakeryAttribute: BakeryAttribute) {
    this.store.dispatch({
      type: Actions.REQUEST_DELETE_BAKERY_ATTRIBUTE,
      payload: bakeryAttribute.id
    });
  }

  private currentBakeryLatestMap(
    action: UnsafeAction
  ): Observable<{ action: UnsafeAction; bakery: Bakery }> {
    return this.bakeryMgmtEffects.currentBakery$
      .filter(bakery => bakery != null)
      .map(bakery => ({ action, bakery }))
      .take(1);
  }

  @Effect()
  getAttributesEffects$ = this.actions$
    .ofType(Actions.REQUEST_GET_BAKERY_ATTRIBUTES)
    .switchMap(this.currentBakeryLatestMap.bind(this))
    .switchMap(({ action, bakery }) => {
      return addEntityRequestHandler(
        this.tokenService.get(
          `/api/bakeries/${bakery.id}/bakery_attributes`
        ),
        [bakeryAttributeSchema],
        Actions.REQUEST_GET_BAKERY_ATTRIBUTES
      );
    })
    .share();

  @Effect()
  getBakeryAttributeEffects$ = this.actions$
    .ofType(Actions.REQUEST_GET_BAKERY_ATTRIBUTE)
    .switchMap(this.currentBakeryLatestMap.bind(this))
    .switchMap(({ action, bakery }) => {
      return addEntityRequestHandler(
        this.tokenService.get(
          `/api/bakeries/${bakery.id}/bakery_attributes/${
            action.payload
          }`
        ),
        bakeryAttributeSchema,
        Actions.REQUEST_GET_BAKERY_ATTRIBUTE
      );
    })
    .share();

  @Effect()
  getBakeryAttributeDetailsEffects$ = this.actions$
    .ofType(Actions.REQUEST_GET_BAKERY_ATTRIBUTE_DETAILS)
    .switchMap(this.currentBakeryLatestMap.bind(this))
    .switchMap(({ action, bakery }) => {
      return Observable.forkJoin(
        basicRequestHandler(
          this.tokenService.get(
            `/api/bakeries/${bakery.id}/bakery_attributes/${
              action.payload
            }`
          ),
          bakeryAttributeSchema
        )
      );
    })
    .mergeMap(actions => {
      return Observable.from([
        ...actions,
        { type: Actions.REQUEST_GET_BAKERY_ATTRIBUTE_DETAILS_SUCCESS }
      ]);
    })
    .share();

  @Effect()
  createBakeryAttributeEffects$ = this.actions$
    .ofType(Actions.REQUEST_CREATE_BAKERY_ATTRIBUTE)
    .switchMap(this.currentBakeryLatestMap.bind(this))
    .switchMap(({ action, bakery }) => {
      return addEntityRequestHandler(
        this.tokenService.post(
          `/api/bakeries/${bakery.id}/bakery_attributes`,
          JSON.stringify(action.payload)
        ),
        bakeryAttributeSchema,
        Actions.REQUEST_CREATE_BAKERY_ATTRIBUTE
      );
    })
    .share();

  @Effect()
  updateBakeryAttributeEffects$ = this.actions$
    .ofType(Actions.REQUEST_UPDATE_BAKERY_ATTRIBUTE)
    .switchMap(this.currentBakeryLatestMap.bind(this))
    .switchMap(({ action, bakery }) => {
      return addEntityRequestHandler(
        this.tokenService.put(
          `/api/bakeries/${bakery.id}/bakery_attributes/${
            action.payload.id
          }`,
          JSON.stringify(action.payload.payload)
        ),
        bakeryAttributeSchema,
        Actions.REQUEST_UPDATE_BAKERY_ATTRIBUTE
      );
    })
    .share();

  // TODO: This isn't supported by addEntityRequestHandler yet, as it's a
  // REMOVE_ENTITY action generator
  @Effect()
  deleteBakeryAttributeEffects$ = this.actions$
    .ofType(Actions.REQUEST_DELETE_BAKERY_ATTRIBUTE)
    .switchMap(this.currentBakeryLatestMap.bind(this))
    .switchMap(({ action, bakery }) => {
      return this.tokenService
        .delete(
          `/api/bakeries/${bakery.id}/bakery_attributes/${
            action.payload
          }`
        )
        .map((response: Response) => response.json())
        .switchMap(() => {
          return Observable.from([
            {
              type: Actions.REMOVE_ENTITY,
              payload: {
                typeKey: 'bakery_attributes',
                entityKey: action.payload
              }
            },
            {
              type: Actions.REQUEST_DELETE_BAKERY_ATTRIBUTE_SUCCESS
            }
          ]);
        })
        .catch(error => {
          const errorPayload =
            typeof error.json === 'function' ? error.json() : error;

          return Observable.of({
            type: Actions.REQUEST_DELETE_BAKERY_ATTRIBUTE_ERROR,
            payload: error
          });
        });
    })
    .share();
}
