import {
  Component,
  Input,
  Output,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy
} from '@angular/core';
import { AbstractControl, FormGroup, FormControl, Validators } from '@angular/forms';
import * as Rx from 'rxjs';
import { Store } from '@ngrx/store';
import { Actions as NgRxActions } from '@ngrx/effects';

import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { AppState } from 'app/store/app-state';
import { Actions } from 'app/store/actions';
import * as selectors from 'app/store/selectors';
import { AlertService } from 'app/shared/components/alerts/alert.service';
import { GetAction } from 'app/store/actions/request.actions';
import { wholesalerGroupSchema } from 'app/store/schema/default-schemata';
import { WholesalerGroup } from 'app/store/wholesaler-group';
import { validateNoWhitespaces } from 'app/shared/forms/no-whitespaces-validator';

const compSelector = 'bakery-mgmt-wholesalers-wholesaler-list-header-prez';

const opKey = 'wholesalerGroups',
      schema = [wholesalerGroupSchema];

export interface AddWholesalerFormData {
  org_name: string;
  org_owner_first_name: string;
  org_owner_last_name: string;
  org_owner_email: string;
  wholesaler_group_id: number;
}

@Component({
  selector: 'bakery-mgmt-wholesalers-wholesaler-list-header',
  template: `
    <${compSelector}
      [count]="count$ | async"
      [addFormData]="addFormData"
      [isExpanded]="onChangeExpanded$ | async"
      [isLoading]="isInviteLoading$ | async"
      [wholesalerGroups]="wholesalerGroups$ | async"
      (onChangeSearchOptions)="onChangeSearchOptions$.next($event)"
      (onClickAddWholesaler)="onClickAddWholesaler($event)"
      (onChangeExpanded)="onChangeExpanded$.next($event)"
    ></${compSelector}>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WholesalerListHeaderComponent implements OnInit, OnDestroy {
  count$ = this.store
    .select(selectors.bkryMgmtWholesaler.getBkryMgmtWholesalerMasterResultsCount);
  isInviteLoading$ = this.store
    .select(selectors.bkryMgmtWholesaler.getBkryMgmtWholesalerMasterInviteLoading);
  onChangeSearchOptions$ = new Rx.Subject<string>();
  onChangeExpanded$ = new Rx.Subject<boolean>();

  wholesalerGroups$: Rx.Observable<WholesalerGroup[]> = this.store
    .select(selectors.getRequestResult(opKey, schema))
    .filter(val => !!val)
    .startWith([]);

  isExpanded: boolean;
  addFormData: AddWholesalerFormData;

  private onChangeSearchOptionsSub: Rx.Subscription;
  private alertsSub: Rx.Subscription;
  private successSub: Rx.Subscription;

  constructor(
    private store: Store<AppState>,
    private alertService: AlertService,
    private actions$: NgRxActions
  ) {
    this.resetAddFormState();
  }

  ngOnInit() {
    this.onChangeSearchOptionsSub = this.onChangeSearchOptions$
      .debounceTime(300)
      .subscribe(name => {
        this.onChangeSearchOptions(name);
      });

    const actionPayload = {
      url: `/api/wholesaler_groups`,
      opKey: opKey,
      schema: schema,
      queryParams: {
        archived: false
      }
    };
    this.store.dispatch(new GetAction(actionPayload));

    this.alertsSub = this.actions$
      .subscribe((action: UnsafeAction) => {
        let messageType = null,
            messageContent = null;

        switch (action.type) {
          case Actions.REQUEST_INVITE_BKRY_WHOLESALER_SUCCESS:
            messageType = 'success';
            messageContent = `The wholesale customer has been invited.`;
            break;

          case Actions.REQUEST_INVITE_BKRY_WHOLESALER_ERROR:
            messageType = 'warning';
            messageContent = `Something went wrong! Please try again later.`;

            if (action.payload.error_type === 'duplicate_email_address') {
              messageContent = `Another user already has that email address.`;
            }
            break;
        }

        if (messageType != null && messageContent != null) {
          this.alertService[messageType](messageContent);
        }
      });

    this.successSub = this.actions$
      .ofType(Actions.REQUEST_INVITE_BKRY_WHOLESALER_SUCCESS)
      .subscribe(() => this.resetAddFormState());
  }

  ngOnDestroy() {
    this.onChangeSearchOptionsSub.unsubscribe();
    this.alertsSub.unsubscribe();
    this.successSub.unsubscribe();
    this.onChangeSearchOptions('');
  }

  onChangeSearchOptions(name: string) {
    this.store.dispatch({
      type: Actions.CHANGE_BKRY_MGMT_WHOLESALERS_ORDERS_FILTERS,
      payload: { name }
    });
  }

  onClickAddWholesaler(event: AddWholesalerFormData) {
    this.store.dispatch({
      type: Actions.REQUEST_INVITE_BKRY_WHOLESALER,
      payload: event
    });
  }

  private resetAddFormState() {
    // this.isExpanded = false;
    this.onChangeExpanded$.next(false);
    this.addFormData = {
      org_name: '',
      org_owner_first_name: '',
      org_owner_last_name: '',
      org_owner_email: '',
      wholesaler_group_id: null
    };
  }
}

@Component({
  selector: compSelector,
  template: require('./header.component.html'),
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WholesalerListHeaderPrezComponent {
  @Input() count: number;

  private _isExpanded = false;
  @Input()
  set isExpanded(isExpanded: boolean) {
    this._isExpanded = isExpanded;
  }
  get isExpanded(): boolean {
    return this._isExpanded;
  }

  @Input() isLoading = false;
  @Input() wholesalerGroups: WholesalerGroup[] = [];
  @Output('onChangeSearchOptions') onChangeSearchOptions$: Rx.Observable<string>;
  @Output('onClickAddWholesaler') onClickAddWholesaler$ = new Rx.Subject<AddWholesalerFormData>();
  @Output('onChangeExpanded') onChangeExpanded$ = new Rx.Subject<boolean>();

  @Input()
  set addFormData(addFormData: any) {
    this.addForm.reset(addFormData, {emitEvent: false});
  }

  addForm: FormGroup;
  filterForm: FormGroup;

  constructor() {
    this.filterForm = new FormGroup({
      name: new FormControl(null, [Validators.required, validateNoWhitespaces])
    });

    this.addForm = new FormGroup(
      {
        org_name: new FormControl(null, [Validators.required, validateNoWhitespaces]),
        org_owner_first_name: new FormControl(null, [Validators.required, validateNoWhitespaces]),
        org_owner_last_name: new FormControl(null, [Validators.required, validateNoWhitespaces]),
        org_owner_email: new FormControl(null, [Validators.required, Validators.email]),
        wholesaler_group_id: new FormControl(null, [Validators.required]),
      },
    );

    this.onChangeSearchOptions$ = this.filterForm.get('name').valueChanges
      .filter(() => this.filterForm.valid);
  }

  onClickAddWholesaler() {
    // this.isExpanded = true;
    this.onChangeExpanded$.next(true);
  }

  onClickCancel() {
    // this.isExpanded = false;
    this.onChangeExpanded$.next(false);
  }

  get isSubmittable(): boolean {
    return this.addForm.valid && !this.isLoading;
  }

  onSubmitAddWholesaler() {
    if (!this.isSubmittable) return;

    this.onClickAddWholesaler$.next(this.addForm.value);
  }
}
