import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { MasterDetailUiState } from 'app/store/bakery-mgmt-ui-state';
import { ResourceRequestState, DetailResourceRequestState } from 'app/store/reducers/request-states';
import { Actions } from 'app/store/actions';
import { ProductCategory } from 'app/store/product-category';
import { BakeryProduct } from 'app/store/bakery-product';
import { BakeryCart } from 'app/store/bakery-cart';
import * as bakeryOrderState from 'app/store/reducers/bakery-cust-ui-state/bakery-order-state.reducer';
import * as orderHistory from 'app/store/reducers/bakery-cust-ui-state/order-history-state.reducer';

export interface ProductsMasterState {
  bakeryProductsState: ResourceRequestState<BakeryProduct[]>;
  productCategoriesState: ResourceRequestState<ProductCategory[]>;
  categoryFilters: number[];
  searchText: string;
  storeType: string;
}

export interface CartState extends DetailResourceRequestState<BakeryCart> { }

export interface BakeryCustomerUiState {
  domainName: string;
  bakeryId: number;
  isLoading: boolean;
  bakeryProductsMasterState: ProductsMasterState;
  bakeryProductDetailState: DetailResourceRequestState<BakeryProduct>;
  bakeryProductSuggestionsState: ResourceRequestState<BakeryProduct[]>;
  cartState: CartState;
  bakeryOrderState: bakeryOrderState.State;
  orderHistoryState: orderHistory.State;
}

const INIT_PRODUCTS_MASTER_STATE: ProductsMasterState = {
  bakeryProductsState: {
    state: null,
    result: null,
  },
  productCategoriesState: {
    state: null,
    result: null,
  },
  categoryFilters: [],
  searchText: '',
  storeType: ''
};

export function productsMasterState(
  state: ProductsMasterState = INIT_PRODUCTS_MASTER_STATE,
  action: UnsafeAction
): ProductsMasterState {
  switch (action.type) {
    case Actions.REQUEST_GET_STORE_PRODUCT_CATEGORIES:
      return {
        ...state,
        productCategoriesState: {
          ...state.productCategoriesState,
          state: 'loading',
          result: []
        }
      };

    case Actions.REQUEST_GET_STORE_PRODUCT_CATEGORIES_SUCCESS:
      return {
        ...state,
        productCategoriesState: {
          ...state.productCategoriesState,
          state: 'success',
          result: action.payload,
        }
      };

    case Actions.REQUEST_GET_STORE_BAKERY_PRODUCTS:
      return {
        ...state,
        bakeryProductsState: {
          ...state.bakeryProductsState,
          state: 'loading',
          result: []
        }
      };

    case Actions.REQUEST_GET_STORE_BAKERY_PRODUCTS_SUCCESS:
      return {
        ...state,
        bakeryProductsState: {
          ...state.bakeryProductsState,
          state: 'success',
          result: action.payload,
        }
      };

    case Actions.SET_STORE_BAKERY_PRODUCTS_SEARCH_TEXT:
      return {
        ...state,
        searchText: action.payload,
      };

    case Actions.SET_STORE_BAKERY_PRODUCTS_STORE_TYPE:
      return {
        ...state,
        storeType: action.payload,
      };

    case Actions.ADD_STORE_BAKERY_PRODUCTS_CATEGORY_FILTER:
      return {
        ...state,
        categoryFilters: [...state.categoryFilters, action.payload],
      };

    case Actions.REMOVE_STORE_BAKERY_PRODUCTS_CATEGORY_FILTER:
      const idLocation = state.categoryFilters.indexOf(action.payload),
            categoryFiltersClone = [...state.categoryFilters];

      categoryFiltersClone.splice(idLocation, 1);

      return {
        ...state,
        categoryFilters: categoryFiltersClone,
      };

    case Actions.REMOVE_STORE_BAKERY_PRODUCTS_CATEGORY_FILTERS:
      return {
        ...state,
        categoryFilters: [],
      };
  }

  return state;
}

const INIT_PRODUCT_CATEGORIES_STATE: ResourceRequestState<ProductCategory[]> = {
  state: null,
  result: [],
}

export function productCategoriesMasterState(
  state: ResourceRequestState<ProductCategory[]> = INIT_PRODUCT_CATEGORIES_STATE,
  action: UnsafeAction
): ResourceRequestState<ProductCategory[]> {
  switch (action.type) {
    case Actions.REQUEST_GET_STORE_PRODUCT_CATEGORIES:
      return {...state, state: 'loading', result: []};

    case Actions.REQUEST_GET_STORE_PRODUCT_CATEGORIES_SUCCESS:
      return {...state, state: 'success', result: action.payload};
  }

  return state;
}

const INIT_BAKERY_PRODUCT_DETAIL_STATE: DetailResourceRequestState<BakeryProduct> = {
  detailId: null,
  state: null,
  result: null,
}

export function bakeryProductDetailState(
  state: DetailResourceRequestState<BakeryProduct> = INIT_BAKERY_PRODUCT_DETAIL_STATE,
  action: UnsafeAction
): DetailResourceRequestState<BakeryProduct> {
  switch (action.type) {
    case Actions.REQUEST_GET_STORE_BAKERY_PRODUCT:
      return {...state, detailId: action.payload, state: 'loading', result: null};

    case Actions.REQUEST_GET_STORE_BAKERY_PRODUCT_SUCCESS:
      return {...state, state: 'success', result: action.payload};
  }

  return state;
}

const INIT_BAKERY_PRODUCTS_MASTER_STATE: ResourceRequestState<BakeryProduct[]> = {
  state: null,
  result: [],
}

export function bakeryProductsMasterState(
  state: ResourceRequestState<BakeryProduct[]> = INIT_BAKERY_PRODUCTS_MASTER_STATE,
  action: UnsafeAction
): ResourceRequestState<BakeryProduct[]> {
  switch (action.type) {
    case Actions.REQUEST_GET_STORE_BAKERY_PRODUCTS:
      return Object.assign({}, state, {state: 'loading', result: []});

    case Actions.REQUEST_GET_STORE_BAKERY_PRODUCTS_SUCCESS:
      return Object.assign({}, state, {state: 'success', result: action.payload});
  }

  return state;
}

const INIT_BAKERY_PRODUCT_SUGGESTIONS_STATE: ResourceRequestState<BakeryProduct[]> = {
  state: null,
  result: [],
}

export function bakeryProductSuggestionsState(
  state: ResourceRequestState<BakeryProduct[]> = INIT_BAKERY_PRODUCT_SUGGESTIONS_STATE,
  action: UnsafeAction
): ResourceRequestState<BakeryProduct[]> {
  switch (action.type) {
    case Actions.REQUEST_GET_STORE_BAKERY_PRODUCT_SUGGESTIONS:
      return Object.assign({}, state, {state: 'loading', result: []});

    case Actions.REQUEST_GET_STORE_BAKERY_PRODUCT_SUGGESTIONS_SUCCESS:
      return Object.assign({}, state, {state: 'success', result: action.payload});
  }

  return state;
}

const INIT_BAKERY_CUST_STATE: BakeryCustomerUiState = {
  domainName: null,
  bakeryId: null,
  isLoading: false,
  bakeryProductsMasterState: undefined,
  bakeryProductDetailState: undefined,
  bakeryProductSuggestionsState: undefined,
  cartState: {
    detailId: null,
    state: null,
    result: null,
  },
  bakeryOrderState: bakeryOrderState.initialState,
  orderHistoryState: orderHistory.initialState,
};

export function bakeryCustUiStateReducer(
  state: BakeryCustomerUiState = INIT_BAKERY_CUST_STATE,
  action: UnsafeAction
): BakeryCustomerUiState {
  state = {
    ...state,
    bakeryProductsMasterState: productsMasterState(state.bakeryProductsMasterState, action),
    bakeryProductDetailState: bakeryProductDetailState(state.bakeryProductDetailState, action),
    bakeryProductSuggestionsState: bakeryProductSuggestionsState(state.bakeryProductSuggestionsState, action),
    bakeryOrderState: bakeryOrderState.reducer(state.bakeryOrderState, action),
    orderHistoryState: orderHistory.reducer(state.orderHistoryState, action),
  };

  switch (action.type) {
    case Actions.REQUEST_GET_CUST_BAKERY:
      return Object.assign({}, state, {isLoading: true});

    case Actions.REQUEST_GET_CUST_BAKERY_SUCCESS:
      return Object.assign({}, state, {isLoading: false, bakeryId: action.payload});

    case Actions.REQUEST_GET_CUST_BAKERY_ERROR:
    case Actions.REQUEST_GET_CUST_BAKERY_ERROR:
      return Object.assign({}, state, {isLoading: false});

    case Actions.REQUEST_GET_CUST_CART:
      return {
        ...state,
        cartState: {
          ...state.cartState,
          state: 'loading',
        }
      };

    case Actions.REQUEST_GET_CUST_CART_ERROR:
      return {
        ...state,
        cartState: {
          ...state.cartState,
          state: 'error',
          detailId: null,
        }
      };

    case Actions.REQUEST_GET_CUST_CART_SUCCESS:
    case Actions.REQUEST_UPDATE_CUST_CART_SUCCESS:
    case Actions.REQUEST_CREATE_CUST_CART_SUCCESS:
      const successBakeryCart = action.payload.bakery_carts != null ?
        Object.keys(action.payload.bakery_carts).map(key => action.payload.bakery_carts[key])[0]
        : null;

      return {
        ...state,
        cartState: {
          ...state.cartState,
          state: 'success',
          result: successBakeryCart,
          detailId: successBakeryCart != null ? successBakeryCart.id : null,
        },
      };

    case Actions.REQUEST_DELETE_CUST_CART_SUCCESS:
      return {
        ...state,
        cartState: {
          ...state.cartState,
          state: null,
          result: null,
          detailId: null,
        },
      };

    default:
      return state;
  }
}
