import { Component, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'business-hours',
  template: `
    <div class="row" [formGroup]="formGroup">
      <label>{{formattedDay}}'s Business Hours</label>
      <div class="row">
        <div class="columns-4__s columns-4__l">
          <select formControlName="open">
            <option disabled value="">Select One</option>
            <option [ngValue]="true">Open</option>
            <option [ngValue]="false">Closed</option>
          </select>
        </div>
        <div class="columns-2__s columns-4__l">
          <select formControlName="open_at">
            <option disabled value="">Select One</option>
            <option value="0">12:00 AM</option>
            <option value="0.5">12:30 AM</option>
            <option value="1">1:00 AM</option>
            <option value="1.5">1:30 AM</option>
            <option value="2">2:00 AM</option>
            <option value="2.5">2:30 AM</option>
            <option value="3">3:00 AM</option>
            <option value="3.5">3:30 AM</option>
            <option value="4">4:00 AM</option>
            <option value="4.5">4:30 AM</option>
            <option value="5">5:00 AM</option>
            <option value="5.5">5:30 AM</option>
            <option value="6">6:00 AM</option>
            <option value="6.5">6:30 AM</option>
            <option value="7">7:00 AM</option>
            <option value="7.5">7:30 AM</option>
            <option value="8">8:00 AM</option>
            <option value="8.5">8:30 AM</option>
            <option value="9">9:00 AM</option>
            <option value="9.5">9:30 AM</option>
            <option value="10">10:00 AM</option>
            <option value="10.5">10:30 AM</option>
            <option value="11">11:00 AM</option>
            <option value="11.5">11:30 AM</option>
            <option value="12">12:00 PM</option>
            <option value="12.5">12:30 PM</option>
            <option value="13">1:00 PM</option>
            <option value="13.5">1:30 PM</option>
            <option value="14">2:00 PM</option>
            <option value="14.5">2:30 PM</option>
            <option value="15">3:00 PM</option>
            <option value="15.5">3:30 PM</option>
            <option value="16">4:00 PM</option>
            <option value="16.5">4:30 PM</option>
            <option value="17">5:00 PM</option>
            <option value="17.5">5:30 PM</option>
            <option value="18">6:00 PM</option>
            <option value="18.5">6:30 PM</option>
            <option value="19">7:00 PM</option>
            <option value="19.5">7:30 PM</option>
            <option value="20">8:00 PM</option>
            <option value="20.5">8:30 PM</option>
            <option value="21">9:00 PM</option>
            <option value="21.5">9:30 PM</option>
            <option value="22">10:00 PM</option>
            <option value="22.5">10:30 PM</option>
            <option value="23">11:00 PM</option>
            <option value="23.5">11:30 PM</option>
          </select>
        </div>
        <div class="columns-2__s columns-4__l">
          <select formControlName="close_at">
            <option disabled value="">Select One</option>
            <option value="0">12:00 AM</option>
            <option value="0.5">12:30 AM</option>
            <option value="1">1:00 AM</option>
            <option value="1.5">1:30 AM</option>
            <option value="2">2:00 AM</option>
            <option value="2.5">2:30 AM</option>
            <option value="3">3:00 AM</option>
            <option value="3.5">3:30 AM</option>
            <option value="4">4:00 AM</option>
            <option value="4.5">4:30 AM</option>
            <option value="5">5:00 AM</option>
            <option value="5.5">5:30 AM</option>
            <option value="6">6:00 AM</option>
            <option value="6.5">6:30 AM</option>
            <option value="7">7:00 AM</option>
            <option value="7.5">7:30 AM</option>
            <option value="8">8:00 AM</option>
            <option value="8.5">8:30 AM</option>
            <option value="9">9:00 AM</option>
            <option value="9.5">9:30 AM</option>
            <option value="10">10:00 AM</option>
            <option value="10.5">10:30 AM</option>
            <option value="11">11:00 AM</option>
            <option value="11.5">11:30 AM</option>
            <option value="12">12:00 PM</option>
            <option value="12.5">12:30 PM</option>
            <option value="13">1:00 PM</option>
            <option value="13.5">1:30 PM</option>
            <option value="14">2:00 PM</option>
            <option value="14.5">2:30 PM</option>
            <option value="15">3:00 PM</option>
            <option value="15.5">3:30 PM</option>
            <option value="16">4:00 PM</option>
            <option value="16.5">4:30 PM</option>
            <option value="17">5:00 PM</option>
            <option value="17.5">5:30 PM</option>
            <option value="18">6:00 PM</option>
            <option value="18.5">6:30 PM</option>
            <option value="19">7:00 PM</option>
            <option value="19.5">7:30 PM</option>
            <option value="20">8:00 PM</option>
            <option value="20.5">8:30 PM</option>
            <option value="21">9:00 PM</option>
            <option value="21.5">9:30 PM</option>
            <option value="22">10:00 PM</option>
            <option value="22.5">10:30 PM</option>
            <option value="23">11:00 PM</option>
            <option value="23.5">11:30 PM</option>
          </select>
        </div>
      </div>
    </div>
  `
})
export class BusinessHoursComponent {
  @Input() form: any;
  @Input() day;

  formGroup: FormControl;
  formattedDay: string;

  ngOnInit() {
    this.formGroup = this.form.get(this.day);
    this.formattedDay = this.day.charAt(0).toUpperCase() + this.day.slice(1);
  }
}
