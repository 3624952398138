import { Component } from '@angular/core';

@Component({
  selector: 'list-table-headers',
  template: `
    <ul class="list__header padding-left--charlie padding-right--charlie padding-top--delta padding-bottom--delta background--grey-light row">
      <ng-content></ng-content>
    </ul>
  `,
})
export class ListTableHeadersComponent { }
