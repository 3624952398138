import { Component, Input, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import * as moment from 'moment/moment';

import { BakeryOrder } from 'app/store/bakery-order';
import { ModalComponent } from 'app/shared/components/modals/modal.component';

@Component({
  selector: 'bkry-cust-wholesaler-schedule-day-section-item',
  template: require('./schedule-day-section-item.component.html'),
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScheduleDaySectionItemComponent {
  @Input() bakeryOrder: BakeryOrder;
  @ViewChild(ModalComponent) modal: ModalComponent;
  @Input('onClickConfirmCancelOrder') onClickConfirmCancelOrder: Function;
  @Input() isEcommPermitted = false;

  withinCancellationRange() {
    return this.bakeryOrder.order_status === 'submitted' && moment(this.bakeryOrder.fulfillment_date) > moment().add(2, 'days');
  }

  onClickCancelOrder() {
    this.modal.openModal();
  }
}
