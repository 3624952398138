import { Component } from '@angular/core';

@Component({
  selector: 'card-list-tooltip-items',
  template: `
    <figure class="tooltip tooltip--list">
      <ul>
        <ng-content></ng-content>
      </ul>
    </figure>
  `,
})
export class TooltipItemsListComponent { }
