import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { Store } from '@ngrx/store';
import { Actions as NgRxActions } from '@ngrx/effects';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { ReplaySubject } from 'rxjs/ReplaySubject';

import * as selectors from 'app/store/selectors';
import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { Actions } from 'app/store/actions';
import { AppState } from 'app/store/app-state';
import { AlertService } from 'app/shared/components/alerts/alert.service';
import { BakeryMgmtCustomersEffects } from 'app/store/effects/bakery-mgmt/bakery-mgmt-customers.effects';

enum Page {
  Previous,
  Next,
  Reset
}

@Component({
  template: require('./master.component.html'),
})
export class BakeryMgmtCustomersMasterComponent implements OnInit, OnDestroy {
  archivedStatusFilter$ = new BehaviorSubject(false);
  filterForm: FormGroup;

  onChangeSearchOptions$ = new ReplaySubject<string>();

  private pageMessage$ = new BehaviorSubject<Page>(Page.Reset);
  page$ = Observable.merge(
      this.pageMessage$,
      this.archivedStatusFilter$.map(() => Page.Reset)
    )
    .scan((acc, message) => {
      switch (message) {
        case Page.Previous: return acc - 1;
        case Page.Next: return acc + 1;
        case Page.Reset: return 0;
      }
    }, 0)
    .distinctUntilChanged();

  private subscription: Subscription;

  customers$ = this.store.select(selectors.selectCustomers);
  currentPage$ = this.customers$.pluck('data');
  resultsCount$ = this.customers$.pluck('total');
  bottom$ = this.customers$.pluck('bottom');
  top$ = this.customers$.pluck('top');

  private alertsSub: Subscription;

  constructor(
    private bakeryMgmtCustomersEffects: BakeryMgmtCustomersEffects,
    private alertService: AlertService,
    private actions$: NgRxActions,
    private store: Store<AppState>,
  ) {
    this.filterForm = new FormGroup({ query: new FormControl('', [])});
  }

  ngOnInit() {
    this.bakeryMgmtCustomersEffects.requestGetCustomers({ isArchived: false });

    const searchOptions$ = this.filterForm.valueChanges
      .do(() => this.pageMessage$.next(Page.Reset))
      .debounceTime(500)
      .map(form => ({ query: form.query.trim() }))
      .startWith({ query: '' })

    const refetch = new BehaviorSubject(null);

    this.subscription = Observable
      .combineLatest(this.page$, searchOptions$, this.archivedStatusFilter$, refetch)
      .subscribe(([page, searchOptions, isArchived]) => {
        const payload = { page, query: searchOptions.query, archive_status: isArchived };
        this.bakeryMgmtCustomersEffects.requestGetCustomers(payload);
      });

    this.alertsSub = this.actions$
      .subscribe((action: UnsafeAction) => {
        let messageType = null,
            messageContent = null;

        switch (action.type) {
          case Actions.REQUEST_UPDATE_ARCHIVE_STATUS_SUCCESS:
            messageType = 'success';
            messageContent = `Customer archive status has been updated.`;
            break;

          case Actions.REQUEST_UPDATE_ARCHIVE_STATUS_ERROR:
            messageType = 'warning';
            messageContent = `Customer archive status update failed.`;
            break;

          case Actions.REQUEST_USER_RESEND_INVITE_SUCCESS:
            messageType = 'success';
            messageContent = `Customer has been sent another invite.`;
            break;

          case Actions.REQUEST_USER_RESEND_INVITE_ERROR:
            messageType = 'warning';
            messageContent = `Customer invite resend request failed.`;
            break;

          case Actions.REQUEST_USER_DESTROY_SUCCESS:
            messageType = 'success';
            messageContent = `Customer has been deleted.`;
            break;

          case Actions.REQUEST_USER_DESTROY_ERROR:
            messageType = 'warning';
            messageContent = `Customer delete request failed.`;
            break;

          case Actions.REQUEST_BAKERY_INVITE_CUSTOMER_SUCCESS:
            messageType = 'success';
            messageContent = `An invitation has been sent to the new bakery customer.`;
            break;

          case Actions.REQUEST_BAKERY_INVITE_CUSTOMER_ERROR:
            messageType = 'warning';
            messageContent = `Something went wrong! Please try again later.`;

            if (action.payload.error === 'duplicate_email_address') {
              messageContent = `Another user already has that email address.`;
            }
            break;
        }

        if (messageType != null && messageContent != null) {
          this.alertService[messageType](messageContent);
          refetch.next(null);
        }
      });
  }

  ngOnDestroy() {
    this.alertsSub.unsubscribe();
    this.subscription.unsubscribe();
  }

  onClickChangeArchiveFilter(newArchivedFilterValue: boolean) {
    this.archivedStatusFilter$.next(newArchivedFilterValue);
  }

  previousPage() {
    this.pageMessage$.next(Page.Previous);
  }

  nextPage() {
    this.pageMessage$.next(Page.Next);
  }
}
