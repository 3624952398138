import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

const compSelector = 'bkry-mgmt-wholesaler-detail-sidebar-summary';

@Component({
  selector: compSelector,
  template: require('./sidebar-summary.component.html'),
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarSummaryComponent {
  @Input() wholesalerOrg: any;
}
