import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Actions as NgRxActions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { AlertService } from 'app/shared/components/alerts/alert.service';
import { AppState } from 'app/store/app-state';
import { Actions } from 'app/store/actions';
import { BakeryMgmtProductCategoriesEffects } from 'app/store/effects/bakery-mgmt/bakery-mgmt-product-categories.effects';
import { BakeryMgmtUiState } from 'app/store/bakery-mgmt-ui-state';
import { EntitiesState } from 'app/store/entities-state';
import { ProductCategory } from 'app/store/product-category';
import { denormalize as denormalizeProdCat } from 'app/store/product-categories/utils';

export interface UiState {
  isLoading: boolean;
  hasProductCategories: boolean;
  productCategories: ProductCategory[];
  currentUserId: number;
}

@Component({
  template: require('./master.component.html'),
})
export class BakeryMgmtProductCategoriesMasterComponent implements OnInit, OnDestroy {
  public uiState$: Observable<any>;
  public addSuccess$: Observable<any>;

  private filterText$ = new BehaviorSubject<string>('');
  private alertsSub: Subscription;

  constructor(
    private bkryMgmtProductsEffects: BakeryMgmtProductCategoriesEffects,
    private actions$: NgRxActions,
    private store: Store<AppState>,
    private router: Router,
    private alertService: AlertService,
  ) {
    this.uiState$ = Observable
      .combineLatest(
        this.store.select('bakeryMgmtUiState'),
        this.store.select('entitiesState'),
        this.filterText$,
        this.store.map(x => x.authState.currentUser.id).distinctUntilChanged()
      )
      .map(([bakeryMgmtUiState, entitiesState, filterText, currentUserId]) => {
        if (bakeryMgmtUiState.productCategoriesUiState.isMasterLoading) {
          return {
            isLoading: true,
            hasProductCategories: false,
            productCategories: [],
            currentUserId,
          };
        }

        let productCategories = Object.keys(entitiesState.product_categories)
          .map(key => denormalizeProdCat(entitiesState.product_categories[key], entitiesState));

        if (filterText.length >= 2) {
          productCategories = productCategories.filter(prodCat => {
            return prodCat.name.toLowerCase().includes(filterText.toLowerCase());
          });
        }

        return {
          isLoading: false,
          hasProductCategories: productCategories.length > 0,
          productCategories: productCategories,
          currentUserId,
        }
      })
      .shareReplay(1);

    this.addSuccess$ = this.actions$.ofType(Actions.REQUEST_CREATE_PRODUCT_CATEGORY_SUCCESS);

    this.alertsSub = this.actions$
      .subscribe((action: UnsafeAction) => {
        let messageType = null,
            messageContent = null;

        switch (action.type) {
          case Actions.REQUEST_CREATE_PRODUCT_CATEGORY:
            messageType = 'success';
            messageContent = `The category has been created.`;
            break;

          case Actions.REQUEST_DELETE_PRODUCT_CATEGORY_SUCCESS:
            messageType = 'success';
            messageContent = `The category has been deleted.`;
            break;

          case Actions.REQUEST_CREATE_PRODUCT_CATEGORY_ERROR:
          case Actions.REQUEST_DELETE_PRODUCT_CATEGORY_ERROR:
            messageType = 'warning';
            messageContent = 'Something went wrong!';
            break;
        }

        if (messageType != null && messageContent != null) {
          this.alertService[messageType](messageContent);
        }
      });
  }

  ngOnInit() {
    this.bkryMgmtProductsEffects.requestGetProductCategories();
  }

  ngOnDestroy() {
    this.alertsSub.unsubscribe();
  }

  onAddCategory(newCategoryDetails: any) {
    this.bkryMgmtProductsEffects.requestCreateProductCategory(newCategoryDetails);
  }

  onChangeFilterText(filterText: string) {
    this.filterText$.next(filterText);
  }

  onShowDetails(productCategory: ProductCategory) {
    this.router.navigate([
      '/bakery_mgmt/product_mgmt/categories/',
      productCategory.id,
    ]);
  }

  onDeleteCategory(productCategory: ProductCategory) {
    this.bkryMgmtProductsEffects.requestDeleteProductCategory(productCategory);
  }
}
