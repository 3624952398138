import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { Actions } from 'app/store/actions';

export interface State {
  customers: any[];
}

const INITIAL_STATE = {
  customers: [],
};

export function reducer(state: State = INITIAL_STATE, action: UnsafeAction): State {
  switch (action.type) {
    case Actions.NEW_ORDER_STARTED:
      return INITIAL_STATE;

    case Actions.REQUEST_GET_BAKERY_CUSTOMERS_SUCCESS2:
      return { ...state, customers: action.payload };

    default:
      return state;
  }
}

export const selectCustomers = state => state.customers;
