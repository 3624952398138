import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { Store } from '@ngrx/store';
import { Angular2TokenService } from 'app/angular2-token/angular2-token.service';
import { Actions as NgRxActions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';
import { normalize, schema } from 'normalizr';
import { LocalStorageService } from 'ngx-webstorage';

import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { AlertAction } from 'app/shared/components/alerts/actions';
import { AppState } from 'app/store/app-state';
import { AuthState } from 'app/store/auth-state';
import { Actions } from 'app/store/actions';
import { Bakery } from 'app/store/bakery';
import { BakeryCart } from 'app/store/bakery-cart';
import { OrderItem } from 'app/store/order-item';
import { BakeryCustomerUiState } from 'app/store/reducers/bakery-cust-ui-state-reducer';
import { EntitiesState } from 'app/store/entities-state';
import { deepBakerySchema } from 'app/store/schema/bakery';
import { bakeryCartSchema, bakeryOrderSchema, userSchema } from 'app/store/schema/default-schemata';
import { addEntityRequestHandler, addResultEntityRequestHandler } from 'app/store/effects/helpers';
import { CartState } from 'app/store/reducers/bakery-cust-ui-state-reducer';
import { User } from 'app/store/user';
import { BakeryOrder } from 'app/store/bakery-order';
import { UsersEffects } from 'app/store/effects/users.effects';
import * as selectors from 'app/store/selectors';
import { BakeryCustEffects } from 'app/store/effects/bakery-cust/bakery-cust.effects';

export interface CustomerOverview {
  bakery: Bakery;
  isLoggedIn: boolean;
  user: User;
  cartQuantity: number;
}

@Injectable()
export class BakeryCustCartOrderEffects {

  constructor(
    private actions$: NgRxActions,
    private tokenService: Angular2TokenService,
    private store: Store<AppState>,
    private usersEffects: UsersEffects,
    private localStore: LocalStorageService,
  ) {
    // Set cust cart ID when we've successfully retrieved or created a cart.
    this.actions$
      .ofType(
        Actions.REQUEST_CREATE_CUST_CART_SUCCESS,
        Actions.REQUEST_GET_CUST_CART_SUCCESS
      )
      .subscribe((action: UnsafeAction) => {
        this.localStore.store('custCartId', Object.keys(action.payload.bakery_carts).shift());
      });

    // Clear the cust cart ID if there's an error in creating or retrieving the cart.
    this.actions$
      .ofType(
        Actions.REQUEST_CREATE_CUST_CART_ERROR,
        Actions.REQUEST_GET_CUST_CART_ERROR
      )
      .subscribe(action => {
        this.localStore.clear('custCartId');
      });

    this.actions$
      .ofType(Actions.REQUEST_CREATE_CUST_BAKERY_ORDER_SUCCESS)
      .subscribe(action => {
        this.usersEffects.requestValidateToken();
      });
  }

  @Effect() addProductTemplateToCartEffects$ = this.actions$
    .ofType(Actions.ADD_PRODUCT_TEMPLATE_TO_CART)
    .withLatestFrom(
      this.store.select(selectors.getCurrentCustBakery),
      this.store.select(selectors.getCartState),
      (action: UnsafeAction, bakery, cartState) => ({action, bakery, cartState})
    )
    .switchMap(({action, bakery, cartState}) => {
      const bpptIds = {
        bpptIds: Object
          .values(action.payload.order_items_attributes)
          .map((x: any) => x.bakery_product_product_template_id)
      };

      if ((<any>cartState).result == null) {
        return addEntityRequestHandler(
          this.tokenService.post(
            `/api/bakeries/${bakery.id}/carts`,
            JSON.stringify(action.payload),
          ),
          bakeryCartSchema,
          Actions.REQUEST_CREATE_CUST_CART,
          bpptIds
        );
      } else {
        return addEntityRequestHandler(
          this.tokenService.put(
            `/api/bakeries/${bakery.id}/carts/${(<any>cartState).result.id}`,
            JSON.stringify(action.payload),
          ),
          bakeryCartSchema,
          Actions.REQUEST_UPDATE_CUST_CART,
          bpptIds
        );
      }
    })
    .share();

  @Effect() removeProductTemplateFromCartEffects$ = this.actions$
    .ofType(Actions.REQUEST_UPDATE_CUST_CART)
    .withLatestFrom(
      this.store.select(selectors.getCurrentCustBakery),
      this.store.select(selectors.getCartState),
      (action: UnsafeAction, bakery, cartState) => ({action, bakery, cartState})
    )
    .switchMap(({action, bakery, cartState}) => {
      return addEntityRequestHandler(
        this.tokenService.put(
          `/api/bakeries/${bakery.id}/carts/${(<any>cartState).result.id}`,
          JSON.stringify(action.payload),
        ),
        bakeryCartSchema,
        Actions.REQUEST_UPDATE_CUST_CART,
      );
    })
    .share();

  @Effect() deleteCartEffects$ = this.actions$
    .ofType(Actions.REQUEST_DELETE_CUST_CART)
    .withLatestFrom(
      this.store.select(selectors.getCurrentCustBakery),
      this.store.select(selectors.getCartState),
      (action: UnsafeAction, bakery, cartState) => ({action, bakery, cartState})
    )
    .switchMap(({action, bakery, cartState}) => {
      return this.tokenService.delete(`/api/bakeries/${bakery.id}/carts/${(<any>cartState).result.id}`)
        .map((response: Response) => response.json())
        .switchMap((entities) => {
          return Observable.from([
            {
              type: Actions.REMOVE_ENTITY,
              payload: {
                typeKey: 'bakery_carts',
                entityKey: (<any>cartState).result.id
              },
            },
            {
              type: Actions.REQUEST_DELETE_CUST_CART_SUCCESS,
            },
          ]);
        });
    })
    .catch(error => {
      console.log(error)
      return Observable.throw(error);
    })
    .share();

  @Effect() custBakeryOrderCreateEffects$ = this.actions$
    .ofType(Actions.INIT_CUST_BAKERY_ORDER)
    .switchMap(() => {
      return this.store
        .select(selectors.getCartState)
        .filter(cartState => cartState.state === 'success')
    })
    .withLatestFrom(
      this.store.select(selectors.getCustomerCart),
      this.store.select(selectors.getCurrentUser),
      this.store.select(selectors.getCurrentCustBakery)
    )
    .switchMap(([_cartState, cart, currentUser, currentBakery]: [CartState, BakeryCart, User, Bakery]) => {
      return Observable.of({
        type: Actions.SET_CUST_BAKERY_ORDER,
        payload: { cart, currentUser, currentBakery },
      });
    })
    .catch(error => {
      return Observable.throw(error);
    })
    .share();

  @Effect() removeCreateCustBakeryOrderEffects$ = this.actions$
    .ofType(Actions.REQUEST_CREATE_CUST_BAKERY_ORDER)
    .withLatestFrom(
      this.store.select(selectors.getCustomerCart),
      this.store.select(selectors.getCustomerOrder),
      (action, cart, checkoutBakeryOrderForm) => ({cart, checkoutBakeryOrderForm})
    )
    .switchMap(({cart, checkoutBakeryOrderForm}) => {
      // Basics
      const bakeryOrder: any = {
        bakery_id: cart.bakery_id,
        bakery_cart_id: cart.id,
        order_customer_email: checkoutBakeryOrderForm.order_customer_email,
        order_customer_first_name: checkoutBakeryOrderForm.order_customer_first_name,
        order_customer_last_name: checkoutBakeryOrderForm.order_customer_last_name,
        order_customer_phone_number: checkoutBakeryOrderForm.order_customer_phone_number,
        order_customer_notes: checkoutBakeryOrderForm.order_customer_notes,
        attached_images_attributes: checkoutBakeryOrderForm.attached_images_attributes
      };

      // Fulfillment.
      const fulfillmentData = checkoutBakeryOrderForm.form_fulfillment_data;
      bakeryOrder.fulfillment_type = fulfillmentData.fulfillment_type;
      bakeryOrder.fulfillment_date = fulfillmentData.fulfillment_date;
      bakeryOrder.fulfillment_at = fulfillmentData.fulfillment_at;
      bakeryOrder.fulfillment_on = [
        fulfillmentData.fulfillment_date.getFullYear(),
        fulfillmentData.fulfillment_date.getMonth() + 1,
        fulfillmentData.fulfillment_date.getDate()
      ].join("-");
      bakeryOrder.fulfillment_location_type = fulfillmentData.fulfillment_location_type;
      if (fulfillmentData.fulfillment_location_id === 'add_new') {
        bakeryOrder.fulfillment_location_attributes = {
          ...fulfillmentData.fulfillment_address
        };
      } else {
        bakeryOrder.fulfillment_location_id = fulfillmentData.fulfillment_location_id;
      }

      // Payment.
      bakeryOrder.payment_card_attributes = {
        payment_type: checkoutBakeryOrderForm.payment_type,
        amount: checkoutBakeryOrderForm.amount,
        notes: checkoutBakeryOrderForm.notes
      };

      if (checkoutBakeryOrderForm.payment_type === 'credit_card') {
        if (
          checkoutBakeryOrderForm.payment_card_selection === 'add_new' ||
          (checkoutBakeryOrderForm.payment_card_selection == null && checkoutBakeryOrderForm.stripe_token != null)
        ) {
          bakeryOrder.payment_card_attributes.stripe_token = checkoutBakeryOrderForm.stripe_token;
        } else {
          bakeryOrder.payment_card_attributes.id = checkoutBakeryOrderForm.payment_card_selection;
        }
      }

      return addResultEntityRequestHandler(
        this.tokenService.post(
          `/api/bakeries/${bakeryOrder.bakery_id}/orders`,
          JSON.stringify(bakeryOrder),
        ),
        bakeryOrderSchema,
        Actions.REQUEST_CREATE_CUST_BAKERY_ORDER,
      );
    })
    .share();

  @Effect() putCustBakeryOrderCancelEffects$ = this.actions$
    .ofType(Actions.REQUEST_PUT_CUST_BAKERY_ORDER_CANCEL)
    .withLatestFrom(
      this.store.select(selectors.getCurrentCustBakery),
      (action: UnsafeAction, bakery) => ({action, bakery})
    )
    .switchMap(({action, bakery}) => {
      const bakeryOrderId = action.payload.id;

      return addResultEntityRequestHandler(
        this.tokenService.put(
          `/api/bakeries/${bakery.id}/orders/${bakeryOrderId}/cancel`,
          {}
        ),
        bakeryOrderSchema,
        Actions.REQUEST_PUT_CUST_BAKERY_ORDER_CANCEL,
      );
    })
    .share();

  @Effect() calculateTaxesForCustomer$ = this.actions$
    .ofType(
      Actions.UPDATE_CUST_BAKERY_ORDER,
      Actions.UPDATE_CUST_BAKERY_ORDER_DISCOUNT
    )
    .withLatestFrom(
      this.store.select(selectors.getCustomerCart),
      this.store.select(selectors.getCustomerOrder),
      this.store.select(selectors.getBakeryLocations)
    )
    .filter(([_action, _cart, order, _bakeryLocations]) => !order.wholesaler_order)
    .filter(([_action, _cart, order, _bakeryLocations]) => {
      return ['BakeryLocation', 'Address'].includes(order.form_fulfillment_data && order.form_fulfillment_data.fulfillment_location_type);
    })
    .map(([_action, cart, order, bakeryLocations]) => {
      const fulfillmentData = order.form_fulfillment_data;
      const bakeryLocationId = fulfillmentData.fulfillment_location_type === 'BakeryLocation' ?
        fulfillmentData.fulfillment_location_id : null;
      const state = fulfillmentData.fulfillment_location_type === 'Address' ?
        (fulfillmentData.fulfillment_location_id === 'add_new'
          ? fulfillmentData.fulfillment_address.state
          : (fulfillmentData.customer_address && fulfillmentData.customer_address.state)
        ) : null;
      return {
        fulfillment_location_type: fulfillmentData.fulfillment_location_type,
        cart_id: cart.id,
        bakery_id: cart.bakery_id,
        bakery_location_id: bakeryLocationId,
        state,
      };
    })
    .filter(x => {
      if(x.fulfillment_location_type === 'BakeryLocation') {
        return !!x.bakery_location_id;
      } else if (x.fulfillment_location_type === 'Address') {
        return !!x.state;
      } else {
        return false;
      }
    })
    .switchMap(x => Observable.of({ type: Actions.REQUEST_TAXES_FOR_CUSTOMER, payload: x }));

  @Effect() taxEstimabilityWatchV2Effects$ = this.actions$
    .ofType(Actions.REQUEST_TAXES_FOR_CUSTOMER)
    .switchMap((action: UnsafeAction) => {
      return this.tokenService
        .post(`/api/bakeries/${action.payload.bakery_id}/carts/${action.payload.cart_id}/taxes`, JSON.stringify(action.payload))
        .map((response: Response) => response.json())
        .switchMap(response => {
          return Observable.of({
            type: Actions.REQUEST_TAXES_FOR_CUSTOMER_SUCCESS,
            payload: response,
          });
        })
        .catch((error) => {
          const message = (error.json && error.json().error) || 'Something went wrong';
          const action = { type: Actions.REQUEST_TAXES_FOR_CUSTOMER_ERROR, payload: message };
          return Observable.from([AlertAction.buildWarning(message), action]);
        });
    })
    .share();

  @Effect() requestAddPromoCode$ = this.actions$
    .ofType(Actions.REQUEST_ADD_PROMO_CODE_FOR_RETAIL)
    .withLatestFrom(
      this.store.select(selectors.getCurrentCustBakery).filter(x => !!x).pluck('id'),
      this.store.select(selectors.getCartState),
    )
    .switchMap(([action, bakeryId, cartState]: [UnsafeAction, number, CartState]) => {
      return addEntityRequestHandler(
        this.tokenService.post(`/api/bakeries/${bakeryId}/carts/${cartState.result.id}/promo_codes`, action.payload),
        bakeryCartSchema,
        Actions.REQUEST_ADD_PROMO_CODE_FOR_RETAIL
      )
    })
    .share();

  @Effect() requestRemovePromoCode$ = this.actions$
    .ofType(Actions.REQUEST_REMOVE_PROMO_CODE_FOR_RETAIL)
    .withLatestFrom(
      this.store.select(selectors.getCurrentCustBakery).filter(x => !!x).pluck('id'),
      this.store.select(selectors.getCartState),
    )
    .switchMap(([action, bakeryId, cartState]: [UnsafeAction, number, CartState]) => {
      return addEntityRequestHandler(
        this.tokenService.delete(`/api/bakeries/${bakeryId}/carts/${cartState.result.id}/promo_codes`),
        bakeryCartSchema,
        Actions.REQUEST_REMOVE_PROMO_CODE_FOR_RETAIL
      )
    })
    .share();

  @Effect() updateBakeryOrder$ = this.actions$
    .ofType(
      Actions.REQUEST_UPDATE_CUST_CART_SUCCESS,
      Actions.REQUEST_ADD_PROMO_CODE_FOR_RETAIL_SUCCESS,
      Actions.REQUEST_REMOVE_PROMO_CODE_FOR_RETAIL_SUCCESS
    )
    .withLatestFrom(
      this.store.select(selectors.getCustomerCart).filter(x => !!x)
    )
    .switchMap(([_action, cart]: [UnsafeAction, BakeryCart]) => {
      const bakeryOrder: any = {
        order_items: cart.order_items,
        total_price: cart.total_price,
        total_discount_price: cart.total_discount_price
      };
      return Observable.of({
        type: Actions.UPDATE_CUST_BAKERY_ORDER_DISCOUNT,
        payload: bakeryOrder,
      });
    })
    .catch(error => {
      return Observable.throw(error);
    })
    .share();
}
