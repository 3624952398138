import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Store } from '@ngrx/store';

import { AppState } from 'app/store/app-state';
import { Actions } from 'app/store/actions';
import * as selectors from 'app/store/selectors';
import { DateRange } from 'app/shared/components/date-range-search-form/date-range-search-form-2.component';

@Component({
  template: require('./sales-tax.component.html'),
})
export class BakeryMgmtReportingSalesTaxComponent implements OnInit, OnDestroy {
  onChangeSearchOptions$ = new ReplaySubject<DateRange>();
  filterForm: FormGroup;

  private pageNumber$ = new BehaviorSubject<number>(0);
  pageNumber = 0;
  private subscription: Subscription;

  reportingTaxes$ = this.store.select(selectors.selectReportingTaxes);
  currentPage$ = this.reportingTaxes$.pluck('data');
  resultsCount$ = this.reportingTaxes$.pluck('total');
  bottom$ = this.reportingTaxes$.pluck('bottom');
  top$ = this.reportingTaxes$.pluck('top');
  taxAmount$ = this.reportingTaxes$.pluck('taxAmount');
  taxableAmount$ = this.reportingTaxes$.pluck('taxableAmount');
  printableSalesTaxSummaries$ = this.reportingTaxes$.pluck('printableData');

  constructor(
    private store: Store<AppState>
  ) {}

  ngOnInit() {
    const page$ = this.pageNumber$.do(offset => this.pageNumber += offset);

    const searchOptions$ = this.onChangeSearchOptions$
      .do(() => this.pageNumber = 0);

    this.subscription = Observable.combineLatest(
      page$.timestamp(),
      searchOptions$.timestamp()
    ).subscribe(([page, searchOptions]) => {
      const payload = {
        search_start_date: searchOptions.value.searchStartDate,
        search_end_date: searchOptions.value.searchEndDate,
      };

      this.store.dispatch({
        type: Actions.REQUEST_TAX_REPORT,
        payload: { ...payload, page: this.pageNumber }
      });
      if (searchOptions.timestamp >= page.timestamp) {
        this.store.dispatch({ type: Actions.REQUEST_PRINTABLE_TAX_REPORT, payload });
      }
    });
  }

  previousPage() {
    this.pageNumber$.next(-1);
  }

  nextPage() {
    this.pageNumber$.next(1);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
