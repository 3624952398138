import { Component, OnInit, EventEmitter, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { Subject } from 'rxjs/Subject';
import { Actions as NgRxActions } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { AlertService } from 'app/shared/components/alerts/alert.service';
import { Actions } from 'app/store/actions';
import { BakeryMgmtLocationsEffects } from 'app/store/effects/bakery-mgmt-locations.effects';
import { BakeryLocation } from 'app/store/bakery-location';
import { Bakery } from 'app/store/bakery';
import { AppState } from 'app/store/app-state';
import { getCurrentBkryMgmtBakery } from 'app/store/selectors';

@Component({
  selector: 'bakery-mgmt-store-add-locations',
  template: require('./add-location.component.html'),
})
export class BakeryMgmtStoreAddLocationComponent implements OnInit, OnDestroy {
  location$: BehaviorSubject<BakeryLocation>;
  imageLocation$: BehaviorSubject<BakeryLocation>;
  reset$ = new Subject<any>();
  createSuccess$: Observable<any>;
  bakery$: Observable<Bakery>;

  private newLocation: BakeryLocation;
  private detailsFormSubmitEvents = new EventEmitter<any>();
  private detailsFormSubmitSub: Subscription;
  private alertsSub: Subscription;
  private createSuccessSub: Subscription;

  constructor(
    private bakeryMgmtLocationEffects: BakeryMgmtLocationsEffects,
    private actions$: NgRxActions,
    private alertService: AlertService,
    private router: Router,
    private store: Store<AppState>
  ) {
    this.newLocation = {
      name: '',
      phone: '',
      street: '',
      city: '',
      state: '',
      zip: '',
      fulfillment_type: 'none',
      hours: {
        monday: {day: 1, day_name: 'Monday', open: false, open_at: 8, close_at: 18},
        tuesday: {day: 1, day_name: 'Tuesday', open: false, open_at: 8, close_at: 18},
        wednesday: {day: 1, day_name: 'Wednesday', open: false, open_at: 8, close_at: 18},
        thursday: {day: 1, day_name: 'Thursday', open: false, open_at: 8, close_at: 18},
        friday: {day: 1, day_name: 'Friday', open: false, open_at: 8, close_at: 18},
        saturday: {day: 1, day_name: 'Saturday', open: false, open_at: 8, close_at: 18},
        sunday: {day: 1, day_name: 'Sunday', open: false, open_at: 8, close_at: 18},
      },
      location_photo: null,
      tax_rate: 0
    };

    this.location$ = new BehaviorSubject<BakeryLocation>(this.newLocation);
    this.imageLocation$ = new BehaviorSubject<BakeryLocation>(this.newLocation);
  }

  ngOnInit() {
    this.createSuccess$ = this.bakeryMgmtLocationEffects.createBakeryLocationEffects$
      .filter(action => action.type === Actions.REQUEST_CREATE_BAKERY_LOCATION_SUCCESS);

    this.alertsSub = this.actions$
      .subscribe((action: UnsafeAction) => {
        let messageType = null,
            messageContent = null;

        switch (action.type) {
          case Actions.REQUEST_CREATE_BAKERY_LOCATION_SUCCESS:
            messageType = 'success';
            messageContent = `The location has been created.`;
            break;

          case Actions.REQUEST_CREATE_BAKERY_LOCATION_ERROR:
            messageType = 'warning';
            messageContent = 'Something went wrong!';
            break;
        }

        if (messageType != null && messageContent != null) {
          this.alertService[messageType](messageContent);
        }
      });

    this.createSuccessSub = this.createSuccess$
      .delay(1500)
      .subscribe(() => {
        window.location.href = '/bakery_mgmt/store_mgmt';
      });

    this.detailsFormSubmitSub = this.detailsFormSubmitEvents
      .withLatestFrom(
        this.imageLocation$,
        (updatePayload, location) => ({updatePayload, location})
      )
      .subscribe((combined) => {
        const imageLocation$ = this.imageLocation$;
        const location = <BakeryLocation> combined.location;
        const updatePayload = <any> combined.updatePayload;

        updatePayload.location_photo = location.location_photo;

        this.bakeryMgmtLocationEffects.requestCreateLocation(
          updatePayload,
        );
      });

    this.bakery$ = this.store.select(getCurrentBkryMgmtBakery).skipWhile(x => !x);
  }

  ngOnDestroy() {
    this.alertsSub.unsubscribe();
    this.createSuccessSub.unsubscribe();
    this.detailsFormSubmitSub.unsubscribe();
  }

  onSubmitDetailsForm(updatePayload: any) {
    this.detailsFormSubmitEvents.next(updatePayload);
  }

  onSaveImage(imageDataUri: string) {
    this.newLocation = Object.assign({}, this.newLocation, {location_photo: imageDataUri});

    this.imageLocation$.next(this.newLocation);
    this.reset$.next();
  }
}
