import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'print-button',
  template: `
    <button
      class="button--secondary {{ size }}"
      [ngClass]="{'button--disabled': disabled}"
      (click)="onClickPrintPage()"
      [disabled]="disabled"
    >{{ text }}</button>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrintButtonComponent {
  @Input() text: string;
  @Input() size: string;
  @Input() disabled = false;

  constructor() { }

  onClickPrintPage() {
    window.print();
  }
}
