import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Response } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import { Actions as NgRxActions } from '@ngrx/effects';

import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { Actions } from 'app/store/actions';
import { BakeryMgmtEmployeesEffects } from 'app/store/effects/bakery-mgmt-employees.effects';
import { AlertService } from 'app/shared/components/alerts/alert.service';
import { validateNoWhitespaces } from 'app/shared/forms/no-whitespaces-validator';

@Component({
  selector: 'bakery-mgmt-employees-header',
  template: require('./header.component.html'),
})
export class BakeryMgmtEmployeesHeaderComponent implements OnInit, OnDestroy {
  filters$ = new Subject<string>();
  form: FormGroup;
  filterForm: FormGroup;
  isAddEmployeeOpen = false;

  private alertsSub: Subscription;
  private inviteSuccessSub: Subscription;

  constructor(
    private bakeryMgmtEmployeesEffects: BakeryMgmtEmployeesEffects,
    private alertService: AlertService,
    private actions$: NgRxActions,
  ) { }

  get uiStates$() {
    return this.bakeryMgmtEmployeesEffects.uiStates$;
  }

  ngOnInit() {
    this.filterForm = new FormGroup({
      'name': new FormControl(null, [Validators.required, validateNoWhitespaces]),
    });

    this.filterForm.get('name').valueChanges
      .debounceTime(500)
      .forEach(filterText => {
        this.bakeryMgmtEmployeesEffects.changeUserFilter(filterText);
      });

    this.form = new FormGroup({
      'first_name': new FormControl(null, [Validators.required, validateNoWhitespaces]),
      'last_name': new FormControl(null, [Validators.required, validateNoWhitespaces]),
      'email': new FormControl(null, [Validators.required, Validators.email]),
      'role': new FormControl('', [Validators.required]),
    });

    this.inviteSuccessSub = this.bakeryMgmtEmployeesEffects.requestBakeryInviteEmployeeEffects$
      .filter(action => action.type === Actions.REQUEST_BAKERY_INVITE_EMPLOYEE_SUCCESS)
      .subscribe(() => {
        this.onClickCancel();
      });

    this.alertsSub = this.actions$
      .subscribe((action: UnsafeAction) => {
        let messageType = null,
            messageContent = null;

        switch (action.type) {
          case Actions.REQUEST_BAKERY_INVITE_EMPLOYEE_SUCCESS:
            messageType = 'success';
            messageContent = `An invitation has been sent to the new bakery employee.`;
            break;

          case Actions.REQUEST_BAKERY_INVITE_EMPLOYEE_ERROR:
            messageType = 'warning';
            messageContent = `Something went wrong! Please try again later.`;

            if (action.payload.error === 'duplicate_email_address') {
              messageContent = `Another user already has that email address.`;
            }
            break;
        }

        if (messageType != null && messageContent != null) {
          this.alertService[messageType](messageContent);
        }
      });
  }

  ngOnDestroy() {
    this.alertsSub.unsubscribe();
    this.inviteSuccessSub.unsubscribe();
    this.bakeryMgmtEmployeesEffects.changeUserFilter('');
  }

  onClickAddEmployee() {
    this.isAddEmployeeOpen = true;
  }

  onClickCancel() {
    this.isAddEmployeeOpen = false;
    this.form.reset({role: ''});
  }

  onSubmitAddEmployee() {
    this.bakeryMgmtEmployeesEffects.requestBakeryInviteEmployee({
      first_name: this.form.value.first_name,
      last_name: this.form.value.last_name,
      email: this.form.value.email,
      role: this.form.value.role,
    });
  }
}
