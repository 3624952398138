import {
  Component,
  Input,
  Output,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy
} from '@angular/core';
import { AbstractControl, FormGroup, FormControl, Validators } from '@angular/forms';
import * as Rx from 'rxjs';
import { Store } from '@ngrx/store';
import { Actions as NgRxActions } from '@ngrx/effects';

import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { AppState } from 'app/store/app-state';
import { Actions } from 'app/store/actions';
import * as selectors from 'app/store/selectors';
import { AlertService } from 'app/shared/components/alerts/alert.service';
import { HeaderStateService } from 'app/shared/services/header-state.service';

const compSelector = 'bakery-mgmt-wholesalers-group-list-header-add-form';

interface AddFormData {
  name: string;
}

@Component({
  selector: compSelector,
  template: `
    <${compSelector}-prez
      [formData]="formData"
      [isLoading]="isLoading$ | async"
      (onSubmitForm)="onSubmitForm($event)"
    ></${compSelector}-prez>
  `
})
export class AddGroupFormComponent implements OnInit, OnDestroy {
  formData: AddFormData;
  isLoading$ = this.store
    .select(selectors.bkryMgmtWholesalerGroup.getMasterCreateIsLoading);

  private alertsSub: Rx.Subscription;
  private successSub: Rx.Subscription;

  constructor(
    private store: Store<AppState>,
    private alertService: AlertService,
    private actions$: NgRxActions,
    private headerStateService: HeaderStateService
  ) {
    this.resetAddFormState();
  }

  onSubmitForm(formData: AddFormData) {
    this.store.dispatch({
      type: Actions.REQUEST_CREATE_BKRY_WHOLESALER_GROUP,
      payload: {...formData}
    });
  }

  ngOnInit() {
    this.alertsSub = this.actions$
      .subscribe((action: UnsafeAction) => {
        let messageType = null,
            messageContent = null;

        switch (action.type) {
          case Actions.REQUEST_CREATE_BKRY_WHOLESALER_GROUP_SUCCESS:
            messageType = 'success';
            messageContent = `The wholesale group has been created.`;
            break;

          case Actions.REQUEST_CREATE_BKRY_WHOLESALER_GROUP_ERROR:
            messageType = 'warning';
            messageContent = `Something went wrong! Please try again later.`;
            break;
        }

        if (messageType != null && messageContent != null) {
          this.alertService[messageType](messageContent);
        }
      });

    this.successSub = this.actions$
      .ofType(Actions.REQUEST_CREATE_BKRY_WHOLESALER_GROUP)
      .subscribe(() => {
        this.headerStateService.updateIsExpanded(false);
        this.resetAddFormState();
      });
  }

  ngOnDestroy() {
    this.alertsSub.unsubscribe();
    this.successSub.unsubscribe();
  }

  private resetAddFormState() {
    this.formData = {name: ''};
  }
}

@Component({
  selector: compSelector + '-prez',
  template: `
    <form
      [formGroup]="form"
      (ngSubmit)="onSubmitForm()"
    >
      <div class="columns-7__m columns-10__l">
        <label>Wholesale Group Name</label>
        <input
          type="text"
          placeholder="Group Name"
          formControlName="name"
        />
      </div>
      <div class="columns-2__m columns-2__l margin-bottom--delta float--right">
        <button
          type="submit"
          class="button--primary button--input button--full label--offset"
          [ngClass]="{'button--disabled': !isSubmittable}"
          [disabled]="!isSubmittable"
        >
          <span *ngIf="!isLoading">Create</span>
          <span *ngIf="isLoading">Creating</span>
        </button>
      </div>
    </form>
  `
})
export class AddGroupFormPrezComponent {
  @Input() isLoading = false;
  @Input()
  set formData(formData: AddFormData) {
    this.form.reset(formData, {emitEvent: false});
  }
  @Output('onSubmitForm') onSubmitForm$ = new Rx.Subject<AddFormData>();

  form: FormGroup;

  constructor() {
    this.form = new FormGroup({
      name: new FormControl(null, [Validators.required])
    });
  }

  get isSubmittable(): boolean {
    return this.form.valid && !this.isLoading;
  }

  onSubmitForm() {
    if (!this.isSubmittable) return;

    this.onSubmitForm$.next(this.form.value)
  }
}
