import {
  Component,
  Input,
  Output,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy
} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions as NgRxActions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as Rx from 'rxjs';

import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { AppState } from 'app/store/app-state';
import { AlertService } from 'app/shared/components/alerts/alert.service';
import { Actions } from 'app/store/actions';
import * as selectors from 'app/store/selectors';
import { BakeryProduct } from 'app/store/bakery-product';
import { BakeryProductProductTemplate } from 'app/store/bakery-product-product-template';

const compSelector = 'bakery-mgmt-wholesalers-group-detail-prez';

const wholesalerProductStoreTypes = ['wholesale_only', 'retail_wholesale'];

@Component({
  template: `
    <${compSelector}
      [wholesalerGroup]="wholesalerGroup$ | async"
      [isLoading]="isLoading$ | async"
      [updateIsLoading]="updateIsLoading$ | async"
      [orgsIsLoading]="orgsIsLoading$ | async"
      [wholesalerOrgs]="wholesalerOrgs$ | async"
      [productsIsLoading]="productsIsLoading$ | async"
      [bakeryProducts]="bakeryProducts$ | async"
      [bakeryProductProductTemplatesMap]="bakeryProductProductTemplatesMap$ | async"
      (onFormSubmit)="onFormSubmit$.next($event)"
    ></${compSelector}>
  `
})
export class SettingsComponent implements OnInit, OnDestroy {
  wholesalerGroup$ = this.store
    .select(selectors.bkryMgmtWholesalerGroup.getDetailResultEntity);
  isLoading$ = this.store
    .select(selectors.bkryMgmtWholesalerGroup.getDetailIsLoading);
  updateIsLoading$ = this.store
    .select(selectors.bkryMgmtWholesalerGroup.getDetailUpdateIsLoading);
  orgsIsLoading$ = this.store
    .select(selectors.bkryMgmtWholesalerGroup.getDetailOrgsIsLoading);
  wholesalerOrgs$ = this.store
    .select(selectors.bkryMgmtWholesalerGroup.getDetailOrgsEntities);
  productsIsLoading$ = this.store
    .select(selectors.bkryMgmtWholesalerGroup.getDetailProductsIsLoading);
  bakeryProducts$ = this.store
    .select(selectors.bkryMgmtWholesalerGroup.getDetailProductsEntities);
  bakeryProductProductTemplatesMap$ = this.store
    .select(selectors.bkryMgmtWholesalerGroup.getDetailAllBakeryProductProductTemplates);

  onFormSubmit$ = new Rx.Subject<any>();

  private alertsSub: Rx.Subscription;
  private onFormSubmitSub: Rx.Subscription;

  constructor(
    private route: ActivatedRoute,
    private actions$: NgRxActions,
    private store: Store<AppState>,
    private alertService: AlertService,
  ) { }

  ngOnInit() {
    this.store.dispatch({type: Actions.REQUEST_GET_BKRY_MGMT_WHOLESALER_GROUP_ORGS});
    this.store.dispatch({type: Actions.REQUEST_GET_BKRY_MGMT_WHOLESALER_GROUP_PRODUCTS});

    this.alertsSub = this.actions$
      .subscribe((action: UnsafeAction) => {
        let messageType = null,
            messageContent = null;

        switch (action.type) {
          case Actions.REQUEST_UPDATE_BKRY_MGMT_WHOLESALER_GROUP_SUCCESS:
            messageType = 'success';
            messageContent = `The wholesaler group has been updated.`;
            break;

          case Actions.REQUEST_UPDATE_BKRY_MGMT_WHOLESALER_GROUP_ERROR:
            messageType = 'warning';
            messageContent = `Something went wrong! Please try again later.`;
            break;
        }

        if (messageType != null && messageContent != null) {
          this.alertService[messageType](messageContent);
        }
      });

    this.onFormSubmitSub = this.onFormSubmit$
      .withLatestFrom(
        this.wholesalerGroup$,
        (formValue, wholesalerGroup) => ({formValue, wholesalerGroup})
      )
      .subscribe(({formValue, wholesalerGroup}) => {
        this.store.dispatch({
          type: Actions.REQUEST_UPDATE_BKRY_MGMT_WHOLESALER_GROUP,
          payload: {
            id: wholesalerGroup.id,
            ...formValue,
          }
        });
      });
  }

  ngOnDestroy() {
    this.alertsSub.unsubscribe();
    this.onFormSubmitSub.unsubscribe();
  }
}

@Component({
  selector: compSelector,
  template: require('./settings.component.html'),
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsPrezComponent {
  private _wholesalerGroup: any;
  @Input()
  set wholesalerGroup(wholesalerGroup) {
    this._wholesalerGroup = wholesalerGroup;

    if (wholesalerGroup != null) {
      this.resetForm();
    }
  }
  get wholesalerGroup(): any {
    return this._wholesalerGroup;
  }
  @Input() isLoading: boolean;
  @Input() updateIsLoading: boolean;
  @Input() orgsIsLoading: boolean;
  @Input() wholesalerOrgs: any[];
  @Input() productsIsLoading: boolean;
  private _bakeryProducts: BakeryProduct[];
  @Input()
  set bakeryProducts(bakeryProducts: BakeryProduct[]) {
    this._bakeryProducts = bakeryProducts;
  }
  get bakeryProducts(): BakeryProduct[] {
    return this._bakeryProducts.filter(bakeryProduct => wholesalerProductStoreTypes.includes(bakeryProduct.store_type));
  }
  @Input() bakeryProductProductTemplatesMap: {[key: number]: BakeryProductProductTemplate};
  @Output('onFormSubmit') onFormSubmit$ = new Rx.Subject<any>();

  form: FormGroup;
  addOverrideForm: FormGroup;
  discountMask = rawValue => {
    const valueLength = (rawValue || '').replace('%', '').length;
    const missingDigits = Math.min(valueLength, 3);
    return Array(missingDigits).fill(/\d/).concat('%');
  }
  selectedProduct: BakeryProduct;

  bppts: {[key: number]: BakeryProductProductTemplate} = {};

  constructor(
    private fb: FormBuilder
  ) {
    this.form = fb.group({
      name: fb.control(null, [Validators.required]),
      default_discount: fb.control(null, [Validators.required]),
      wholesaler_org_ids: fb.control([], []),
      selected_org_id: fb.control(null, []),
      wholesaler_product_overrides_attributes: fb.control([], [])
    });

    this.addOverrideForm = fb.group({
      bakery_product_id: fb.control(null, [Validators.required]),
      bakery_product_template_id: fb.control({value: null, disabled: true}, [Validators.required]),
      unit_price: fb.control(null, [Validators.required]),
      order_minimum: fb.control(0, [Validators.required]),
    });
  }

  get isSubmittable(): boolean {
    return !this.isLoading && this.form.valid && this.form.dirty;
  }

  get isCancelable(): boolean {
    return !this.isLoading && this.form.dirty;
  }

  onFormCancel() {
    this.resetForm();
  }

  onFormSubmit() {
    this.onFormSubmit$.next(this.form.value);
  }

  get selectedWholesalerOrgs(): any[] {
    return this.form.value.wholesaler_org_ids
      .map(orgId => this.wholesalerOrgs.find(org => org.id === orgId));
  }

  get availableWholesalerOrgs(): any[] {
    return this.wholesalerOrgs
      .filter(org => !this.form.value.wholesaler_org_ids.find(id => id === org.id));
  }

  get selectedProductTemplates(): any[] {
    if (this.selectedProduct == null) return [];

    // return this.selectedProduct.bakery_product_product_templates;

    return (<BakeryProductProductTemplate[]>this.selectedProduct.bakery_product_product_templates)
      .filter(selectedProductBppt => !this.overriddenBakeryProductProductTemplates.find(bppt => bppt.id === selectedProductBppt.id));
  }

  get isAddProductOverrideDisabled(): boolean {
    return !this.addOverrideForm.valid;
  }

  get productOverrides(): any[] {
    const allOverrides = this.form.get('wholesaler_product_overrides_attributes').value;

    const filteredOverrides = allOverrides
      .filter(override => override._destroy !== true);

    const mappedOverrides = filteredOverrides
      .map(rawOverride => {
        const matchingBppt = this.bakeryProductProductTemplatesMap[
          rawOverride.bakery_product_product_template_id
        ];

        return {
          ...rawOverride,
          bakery_product_product_template: matchingBppt
        }
      });

    return mappedOverrides;
  }

  get overriddenBakeryProductProductTemplates(): BakeryProductProductTemplate[] {
    return this.form.get('wholesaler_product_overrides_attributes').value
      .map(rawOverride => this.bakeryProductProductTemplatesMap[rawOverride.bakery_product_product_template_id]);
  }

  onSelectProduct() {
    this.selectedProduct = this.bakeryProducts.find(prod => prod.id === this.addOverrideForm.value.bakery_product_id);

    const selectedTemplateControl = this.addOverrideForm.get('bakery_product_template_id')
    if (this.selectedProduct == null) {
      selectedTemplateControl.disable();
    } else {
      selectedTemplateControl.enable();
    }
  }

  onSelectWholesalerOrgId() {
    const updatedOrgIds = [
      ...this.form.value.wholesaler_org_ids,
      this.form.value.selected_org_id
    ];

    this.form.get('wholesaler_org_ids').markAsDirty();
    this.form.patchValue({wholesaler_org_ids: updatedOrgIds, selected_org_id: null});
  }

  onClickRemoveWholesalerOrg(orgId: number) {
    const currentOrgIds = this.form.value.wholesaler_org_ids;
    const orgIdx = currentOrgIds.indexOf(orgId);
    const updatedOrgIds = [
      ...currentOrgIds.slice(0, orgIdx),
      ...currentOrgIds.slice(orgIdx + 1)
    ];

    this.form.get('wholesaler_org_ids').markAsDirty();
    this.form.patchValue({wholesaler_org_ids: updatedOrgIds}, {emitEvent: true});
  }

  onAddOverrideFormSubmit() {
    const productId = this.addOverrideForm.value.bakery_product_id,
          templateId = this.addOverrideForm.value.bakery_product_template_id;
    const matchingBppt = Object.keys(this.bakeryProductProductTemplatesMap)
      .map(key => this.bakeryProductProductTemplatesMap[key])
      .find(bppt => bppt.bakery_product_id === productId && bppt.bakery_product_template_id === templateId);

    const newProductOverride = {
      bakery_product_product_template_id: matchingBppt.id,
      order_minimum: this.addOverrideForm.value.order_minimum,
      unit_price: this.addOverrideForm.value.unit_price
    };
    const currentProductOverrides = this.form.get('wholesaler_product_overrides_attributes').value;

    this.form.patchValue({wholesaler_product_overrides_attributes: [...currentProductOverrides, newProductOverride]});

    this.resetAddOverrideForm();
    this.form.get('wholesaler_product_overrides_attributes').markAsDirty();
  }

  onClickRemoveProductOverride(productOverride: any) {
    const replacement = [];

    const currentOverrides = this.form.value.wholesaler_product_overrides_attributes;
    const orgIdx = currentOverrides
      .findIndex(override => override.bakery_product_product_template_id === productOverride.bakery_product_product_template_id);
    if (productOverride.id) {
      replacement.push({
        ...productOverride,
        _destroy: true
      })
    }
    const updatedOverrides = [
      ...currentOverrides.slice(0, orgIdx),
      ...replacement,
      ...currentOverrides.slice(orgIdx + 1)
    ];

    this.form.get('wholesaler_product_overrides_attributes').markAsDirty();
    this.form.patchValue({wholesaler_product_overrides_attributes: updatedOverrides}, {emitEvent: true});
  }

  private resetForm() {
    const trimmedProductOverrides = this.wholesalerGroup.wholesaler_product_overrides
      .map(productOverride => {
        return {
          id: productOverride.id,
          unit_price: productOverride.unit_price,
          order_minimum: productOverride.order_minimum,
          bakery_product_product_template_id: productOverride.bakery_product_product_template_id
        };
      });

    this.form.reset(
      {
        name: this.wholesalerGroup.name,
        default_discount: this.wholesalerGroup.default_discount,
        wholesaler_org_ids: this.wholesalerGroup.active_wholesaler_orgs.map(org => org.id),
        selected_org_id: null,
        wholesaler_product_overrides_attributes: trimmedProductOverrides
      },
      {emitEvent: false}
    );

    this.resetAddOverrideForm();
  }

  private resetAddOverrideForm() {
    this.addOverrideForm.reset(
      {
        bakery_product_id: null,
        bakery_product_template_id: {value: null, disabled: true},
        unit_price: null,
        order_minimum: 0
      },
      {emitEvent: false}
    );
  }
}
