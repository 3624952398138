import { Component, OnInit, OnDestroy, Input, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Actions as NgRxActions } from '@ngrx/effects';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';

import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { AppState } from 'app/store/app-state';
import { AlertService } from 'app/shared/components/alerts/alert.service';
import { BakeryCustEffects } from 'app/store/effects/bakery-cust/bakery-cust.effects';
import { UsersEffects } from 'app/store/effects/users.effects';
import { Actions } from 'app/store/actions';
import { User } from 'app/store/user';
import { hasRole } from 'app/store/users/utils';
import { Role } from 'app/store/role';
import { Bakery } from 'app/store/bakery';
import { MasterState } from 'app/store/reducers/master-state-reducer';
import { BakeryCart } from 'app/store/bakery-cart';
import { BakeryOrder } from 'app/store/bakery-order';
import { OrderItem } from 'app/store/order-item';
import { CartState } from 'app/store/reducers/bakery-cust-ui-state-reducer';
import { EntitiesState } from 'app/store/entities-state';
import * as selectors from 'app/store/selectors';
import { phoneNumberValidator } from 'app/shared/forms/form-number-validator';
import { futureDateValidator } from 'app/shared/forms/date-validators';
import { CheckoutBakeryOrderForm } from './checkout-bakery-order-form';
import { WholesalerOrg } from 'app/store/wholesaler-org';
import { PaymentCard } from 'app/store/payment-card';
import { Address } from 'app/store/address';
import { validatePositiveInteger } from 'app/shared/forms/positive-integer-validator';
import { validateNoWhitespaces } from 'app/shared/forms/no-whitespaces-validator';
import { CentsObservablePipe } from 'app/shared/pipes/cents-observable.pipe';


@Component({
  selector: 'bakery-mgmt-new-order-checkout',
  template: require('./checkout.component.html')
})
export class BakeryMgmtNewOrderCheckoutComponent implements OnInit, OnDestroy {
  @Input() orderItems: OrderItem[];
  @Input() selectedCustomer: User;
  @Input() changeToCheckout: Function;
  @Input() checkout: boolean;
  @Input() wholesaler: boolean;
  @Input() selectedCustomer$;
  form: FormGroup;

  order$: Observable<BakeryOrder>;
  taxes$: Observable<string>;
  total$: Observable<string>;
  formValid$: Observable<boolean>;
  isLoading$: Observable<boolean>;
  confirmOrder$ = new Subject<any>();
  createToken$ = new Subject<any>();
  stripeToken$ = new Subject<any>();
  cardValid$ = new Subject<boolean>();
  triggerValidation$ = new Subject<boolean>();
  orderItemsCount = 0;
  isSubmitting = false;
  paymentCards: PaymentCard[];
  addresses: Address[];

  private deleteCartSuccessSub: Subscription;
  private submitCheckoutSuccessSub: Subscription;
  private alertsSub: Subscription;
  private orderValuesSub: Subscription;
  private validatorsSub: Subscription;
  private formValuesSub: Subscription;
  private confirmOrderSub: Subscription;
  private selectedCustomerSub: Subscription;
  private orderTypeValueChangesSub: Subscription;
  private paymentAmountTypeValueChanges: Subscription;

  constructor(
    private router: Router,
    private alertService: AlertService,
    private actions$: NgRxActions,
    private store: Store<AppState>,
    private fb: FormBuilder,
    private centsObservablePipe: CentsObservablePipe
  ) {
    this.form = this.fb.group(
      {
        fulfillment_type: [null, [Validators.required]],
        fulfillment_date: [null, [Validators.required, futureDateValidator]],
        fulfillment_at: [null, []],
        fulfillment_location_id: [null, [Validators.required]],
        street: [null],
        unit: [null],
        city: [null],
        state: [null],
        zip: [null],
        order_customer_id: [null],
        order_customer_name: [null],
        order_customer_first_name: [null, [Validators.required, validateNoWhitespaces]],
        order_customer_last_name: [null, [Validators.required, validateNoWhitespaces]],
        order_customer_email: [null, [optionalEmailValidator]],
        order_customer_phone_number: [null, [phoneNumberValidator]],
        order_customer_notes: [null],
        attached_images_attributes: this.fb.array([]),
        payment_type: [null, [Validators.required]],
        amount: [{value: 0, disabled: true}, [validatePositiveInteger]],
        notes: [null],
        payment_card_selection: [null],
        order_type: [this.wholesaler ? null : 'once', [Validators.required]],
        schedule_interval: [{value: 'none', disabled: true}, [Validators.required]],
        payment_amount_type: ['full', [Validators.required]]
      },
      { validator: [checkoutFormValidator, eitherOr('order_customer_email', 'order_customer_phone_number')] }
    );
  }

  ngOnInit() {
    this.store.dispatch({type: Actions.INIT_EMPLOYEE_BAKERY_ORDER});
    this.order$ = this.store.select(selectors.getEmployeeOrder);

    const taxes$ = this.store.select(selectors.getEmployeeOrderTaxEstimateState);
    this.taxes$ = taxes$.switchMap(taxes => {
      switch (taxes.status) {
        case 'success': return this.centsObservablePipe.transform(taxes.value);
        case 'loading': return Observable.of('Loading...');
        case 'error': return Observable.of('Error');
        case 'notAsked': return Observable.of('N/A');
      }
    });
    this.total$ = Observable.combineLatest(taxes$, this.order$)
      .switchMap(([taxes, order]) => {
        if (this.wholesaler) {
          const total = order.total_price - order.total_discount_price;
          return this.centsObservablePipe.transform(Math.max(total, 0));
        }
        switch (taxes.status) {
          case 'success':
            const total = order.total_price - order.total_discount_price + taxes.value;
            return this.centsObservablePipe.transform(Math.max(total, 0));
          case 'loading': return Observable.of('Loading...');
          case 'error': return Observable.of('Error');
          case 'notAsked': return Observable.of('N/A');
        }
      });
    this.orderItemsCount = this.orderItems.length;

    this.selectedCustomerSub = this.selectedCustomer$.filter((customer) => customer != null).subscribe(customer => {
      this.addresses =
        this.wholesaler ?
          <Address[]> customer.wholesaler_org.addresses :
          <Address[]> (customer.default_address ? [<Address>customer.default_address] : []);
      const order_customer_name = this.wholesaler ? <WholesalerOrg> customer.wholesaler_org.name : null;
      this.paymentCards = this.wholesaler ? <WholesalerOrg> customer.wholesaler_org.payment_cards : customer.payment_cards;
      this.form.patchValue(
        {
          order_customer_id: customer.id,
          order_customer_name: order_customer_name,
          order_customer_first_name: customer.first_name,
          order_customer_last_name: customer.last_name,
          order_customer_email: customer.email,
          order_customer_phone_number: customer.phone_number,
          fulfillment_type: null,
          fulfillment_location_id: null,
          payment_card_selection: null,
        }
      );
      this.form.markAsPristine();
    });

    this.orderValuesSub = Observable.combineLatest(this.order$, (order: CheckoutBakeryOrderForm) => (order))
      .filter((order) => order != null)
      .subscribe((order) => {
        this.form.patchValue(
          {
            fulfillment_type: order.form_fulfillment_data.fulfillment_type,
            fulfillment_at: order.form_fulfillment_data.fulfillment_at,
            fulfillment_location_id: order.form_fulfillment_data.fulfillment_location_id,
            street: order.form_fulfillment_data.fulfillment_address.street,
            unit: order.form_fulfillment_data.fulfillment_address.unit,
            city: order.form_fulfillment_data.fulfillment_address.city,
            state: order.form_fulfillment_data.fulfillment_address.state,
            zip: order.form_fulfillment_data.fulfillment_address.zip
          },
          {
            emitEvent: false,
          }
        );
        // Since `emitEvent` is set to `false` above, validation needs to be manually triggered.
        // `emitEvent` is set to false because otherwise there's an infinite loop when the form
        // is updated by filling a field.
        // Fixes the following bug:
        // - click on "New Order"
        // - select "Retail Order"
        // - add a product
        // - go to checkout
        // - select "pickup" as fulfilment method
        // - select a fulfilment date in the future
        // - select a pickup location
        // - fill in customer info
        // - select no payment
        // - notice the create order button is enabled
        // - select"delivery" as fulfilment method
        // - notice that the delivery address is blank but the create order button is enabled
        //   (the create order button should be disabled instead)
        this.triggerValidation$.next(true);
      });

    this.validatorsSub = this.form.valueChanges
      .do((formValue) => {
        const isFulfillmentAtRequired = ['pickup', 'delivery'].includes(formValue.fulfillment_type);
        if (isFulfillmentAtRequired) {
          this.form.get('fulfillment_at').setValidators([Validators.required]);
        } else {
          this.form.get('fulfillment_at').setValidators([]);
        }
      })
      .subscribe((formValue) => {
        const isAddressRequired = formValue.fulfillment_location_id === 'add_new';

        const streetCtrl = this.form.get('street'),
              cityCtrl = this.form.get('city'),
              stateCtrl = this.form.get('state'),
              zipCtrl = this.form.get('zip');

        if (isAddressRequired) {
          streetCtrl.setValidators([Validators.required, validateNoWhitespaces]);
          cityCtrl.setValidators([Validators.required, validateNoWhitespaces]);
          stateCtrl.setValidators([Validators.required, validateNoWhitespaces]);
          zipCtrl.setValidators([Validators.required]);
        } else {
          streetCtrl.setValidators([]);
          cityCtrl.setValidators([]);
          stateCtrl.setValidators([]);
          zipCtrl.setValidators([]);
        }
      });

    this.orderTypeValueChangesSub = this.form.get('order_type').valueChanges
      .debounce(() => Observable.timer(200))
      .subscribe((orderType: string) => {
        if (orderType === 'once') {
          this.form.patchValue({schedule_interval: 'none'}, {emitEvent: false});
          this.form.get('schedule_interval').disable();
        } else {
          this.form.patchValue({schedule_interval: null}, {emitEvent: false});
          this.form.get('schedule_interval').enable();
        }
      });

    this.paymentAmountTypeValueChanges = this.form.get('payment_amount_type').valueChanges
      .debounce(() => Observable.timer(200))
      .subscribe((orderType: string) => {
        this.form.patchValue({amount: null}, {emitEvent: false});
        if (orderType === 'full') {
          this.form.get('amount').disable();
        } else {
          this.form.get('amount').enable();
        }
      });

    this.formValuesSub = this.form.valueChanges
      .withLatestFrom(
        this.order$,
        (formValue, checkoutBakeryOrderForm) => ({formValue, checkoutBakeryOrderForm})
      )
      .debounce(() => Observable.timer(500))
      .distinctUntilChanged((x, y) => JSON.stringify(x) === JSON.stringify(y))
      .subscribe(({formValue, checkoutBakeryOrderForm}: {formValue: any, checkoutBakeryOrderForm: CheckoutBakeryOrderForm}) => {
        const fulfillmentLocationType =
          formValue.fulfillment_type === 'pickup'
          ? 'BakeryLocation'
          : formValue.fulfillment_type === 'shipping' || formValue.fulfillment_type === 'delivery'
          ? 'Address'
          : null;

        const fulfillmentLocationId =
            formValue.fulfillment_type === checkoutBakeryOrderForm.form_fulfillment_data.fulfillment_type
            ? formValue.fulfillment_location_id
            : null;

        const fulfillmentDateChanged =
          formValue.fulfillment_date !== checkoutBakeryOrderForm.form_fulfillment_data.fulfillment_date;

        const fulfillmentTypeChanged =
          formValue.fulfillment_type !== checkoutBakeryOrderForm.form_fulfillment_data.fulfillment_type;

        const fulfillment = {
          fulfillment_type: fulfillmentDateChanged ? null : formValue.fulfillment_type,
          fulfillment_date: formValue.fulfillment_date,
          fulfillment_at: (fulfillmentDateChanged || fulfillmentTypeChanged) ? null : formValue.fulfillment_at,
          fulfillment_location_type: fulfillmentDateChanged ? null : fulfillmentLocationType,
          fulfillment_location_id: fulfillmentDateChanged ? null : fulfillmentLocationId,
        };

        this.store.dispatch({
          type: Actions.UPDATE_EMPLOYEE_BAKERY_ORDER,
          payload: {
            wholesaler_order: this.wholesaler,
            order_type: formValue.order_type,
            schedule_interval: formValue.schedule_interval,
            order_customer_id: formValue.order_customer_id,
            order_customer_name: formValue.order_customer_name,
            order_customer_first_name: formValue.order_customer_first_name,
            order_customer_last_name: formValue.order_customer_last_name,
            order_customer_email: formValue.order_customer_email,
            order_customer_notes: formValue.order_customer_notes,
            order_customer_phone_number: formValue.order_customer_phone_number,
            attached_images_attributes: formValue.attached_images_attributes.map(attachedImage => attachedImage.image),
            form_fulfillment_data: {
              ...checkoutBakeryOrderForm.form_fulfillment_data,
              ...fulfillment,
              fulfillment_address: {
                street: formValue.street,
                unit: formValue.unit,
                city: formValue.city,
                state: formValue.state,
                zip: formValue.zip,
              }
            },
            payment_type: formValue.payment_type,
            payment_card_selection: formValue.payment_card_selection,
            amount: formValue.amount,
            notes: formValue.notes,
          },
        });
      });

    this.isLoading$ = this.store.select(selectors.getOrderState).map(state => state.isLoading);

    this.deleteCartSuccessSub = this.actions$
      .ofType(Actions.REQUEST_DELETE_EMPLOYEE_CART_SUCCESS)
      .subscribe(() => this.changeToCheckout(false));

    this.submitCheckoutSuccessSub = this.actions$
      .ofType(Actions.REQUEST_CREATE_EMPLOYEE_BAKERY_ORDER_SUCCESS)
      .subscribe((action: UnsafeAction) => {
        // forward to the order detail view.
        this.router.navigate([`/bakery_mgmt/order_mgmt/orders/${action.payload}/overview`]);
      });

    this.formValid$ = Observable
      .combineLatest(
        this.form.valueChanges,
        this.cardValid$.startWith(false),
        this.store.select(selectors.getEmployeeOrderTaxEstimateState),
        this.triggerValidation$
      )
      .map(([formValue, isCardValid, taxes]) => {
        if (!this.form.valid) { return false; }
        if (taxes.status === 'error') { return false; }

        if (formValue.payment_type === 'none') { return true; }

        const amountValue = formValue.amount,
              paymentAmountTypeValue = formValue.payment_amount_type;

        if (paymentAmountTypeValue === 'deposit' && amountValue < 1) {
          return false;
        }

        if (formValue.payment_type === 'credit_card') {
          if (
            formValue.payment_card_selection !== 'add_new' &&
            formValue.payment_card_selection != null
          ) {
            return true;
          }

          return isCardValid;
        }

        return true;
      });

    this.confirmOrderSub = this.confirmOrder$
      .skipUntil(this.formValid$)
      .skipUntil(Observable.of(!this.isSubmitting))
      .switchMap(() => this.store.select(selectors.getEmployeeOrder).take(1))
      .switchMap((orderForm) => {
        this.isSubmitting = true;
        if (orderForm.payment_type === 'credit_card' &&
           (orderForm.payment_card_selection === 'add_new' || orderForm.payment_card_selection == null)) {
          // Request a new token be created.
          this.createToken$.next();

          return this.stripeToken$
            .switchMap((tokenResponse) => {
              // Catch a token error, and quit.
              if (tokenResponse.error != null) {
                let errorMessage = 'Something went wrong!';

                if (!!tokenResponse.error && !!tokenResponse.error.message) {
                  errorMessage = tokenResponse.error.message;
                }

                this.alertService.warning(errorMessage);

                return Observable.empty();
              }
              // Dispatch two actions: update order and submit order
              return Observable.from([
                {
                  type: Actions.UPDATE_EMPLOYEE_BAKERY_ORDER,
                  payload: {
                    stripe_token: tokenResponse.token.id,
                  }
                },
                {
                  type: Actions.REQUEST_CREATE_EMPLOYEE_BAKERY_ORDER,
                  payload: {
                    wholesaler: this.wholesaler
                  }
                },
              ]);
            });
        } else {
          return Observable.of({
            type: Actions.REQUEST_CREATE_EMPLOYEE_BAKERY_ORDER,
            payload: {
              wholesaler: this.wholesaler
            }
          });
        }
      })
      .subscribe((action: any) => {
        if (action != null && !!action.type) {
          this.store.dispatch(action);
        }
      });

    this.alertsSub = this.actions$
      .subscribe((action: UnsafeAction) => {
        let messageType = null,
            messageContent = null;

        switch (action.type) {
          case Actions.REQUEST_CREATE_EMPLOYEE_BAKERY_ORDER_ERROR:
            this.isSubmitting = false;
            messageType = 'warning';
            messageContent = action.payload.error || 'Something went wrong!';

            if (action.payload.error === 'duplicate_email_address') {
              messageContent = 'Another user already has that email address.';
            }
            break;
        }

        if (messageType != null && messageContent != null) {
          this.alertService[messageType](messageContent);
        }
      });
  }

  ngOnDestroy() {
    this.deleteCartSuccessSub.unsubscribe();
    this.submitCheckoutSuccessSub.unsubscribe();
    this.alertsSub.unsubscribe();
    this.orderValuesSub.unsubscribe();
    this.validatorsSub.unsubscribe();
    this.formValuesSub.unsubscribe();
    this.confirmOrderSub.unsubscribe();
    this.selectedCustomerSub.unsubscribe();
    this.orderTypeValueChangesSub.unsubscribe();
    this.paymentAmountTypeValueChanges.unsubscribe();
  }

  onClickConfirmOrder() {
    this.confirmOrder$.next();
  }

  onClickCancelOrder() {
    this.store.dispatch({
      type: Actions.REQUEST_DELETE_EMPLOYEE_CART,
    });
  }
}

function checkoutFormValidator(formGroup: AbstractControl): {[key: string]: boolean} {
  const amountValue = formGroup.get('amount').value,
        paymentAmountTypeValue = formGroup.get('payment_amount_type').value;

  if (paymentAmountTypeValue === 'deposit' && amountValue < 1) {
    return {invalid_deposit_value: true};
  }

  return null;
}

const eitherOr = (field1, field2) => (formGroup: AbstractControl): { [key: string]: boolean } => {
  if ((formGroup.get(field1).value || '').length > 0 && formGroup.get(field1).valid) { return; }
  if ((formGroup.get(field2).value || '').length > 0 && formGroup.get(field2).valid) { return; }
  return { invalid_contact_information: true };
}

const optionalEmailValidator = (control: AbstractControl): { [key: string]: boolean } => {
  if ((control.value || '').length === 0) { return; }
  return Validators.email(control);
}
