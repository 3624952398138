import { Component, OnInit, OnDestroy, Input, Output, ViewChild, ElementRef } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import * as Rx from 'rxjs';

import { User } from 'app/store/user';
import { BakeryProductTemplate } from 'app/store/bakery-product-template';
import { SimpleFormState } from 'app/shared/forms/form-states';

interface ImageUiState {
  user: User;
  imageUrl: string;
  hasImage: boolean;
  hasTempImage: boolean;
}

interface ImageInfo {
  hasImage: boolean;
  encoded?: string;
  mimeType?: string;
}

@Component({
  selector: 'bakery-cust-user-edit-image',
  template: `
    <titled-card title="Edit Avatar">
      <div data-card-body class="text--center">
         <ng-template [ngIf]="!(imageState$|async).hasImage">
          <div class="chip__container chip__center">
            <div class="chip__avatar chip__avatar--xlg float--none margin-reset--right margin-bottom--delta" [chipColor]="(imageState$|async).user.name">
              <h5 class="chip__avatar-text chip__avatar-text--lg">
                {{ (imageState$|async).user.name | chipName }}
              </h5>
            </div>
          </div>
        </ng-template>
        <ng-template [ngIf]="(imageState$|async).hasImage">
          <div class="chip__container chip__center">
            <div class="chip__avatar chip__avatar--xlg float--none margin-reset--right margin-bottom--delta">
              <img [src]="(imageState$|async).imageUrl" />
            </div>
          </div>
        </ng-template>
        <ng-template [ngIf]="!(imageState$|async).hasTempImage">
          <label [for]="uploadInputId" class="button--secondary button--small margin-bottom--delta clickable">Upload Image</label>
          <button *ngIf="hasImage" (click)="onClickRemoveImage()" class="button--warning button--small margin-bottom--delta clickable">Remove Image</button>
        </ng-template>
        <p><em>Note: Square images will display best.</em></p>
        <form #fileForm>
          <input
            style="display: none;"
            [id]="uploadInputId"
            type="file"
            (change)="onChangeFileUpload($event)">
        </form>
      </div>
    </titled-card>
  `
})
export class BakeryCustAccountSettingsUserEditImageComponent implements OnInit, OnDestroy {
  @Input('user$') user$: Rx.Observable<User>;
  @Input('reset$') reset$: Rx.Observable<any>;
  @Output('formState') formState$ = new Rx.ReplaySubject<SimpleFormState>(1);
  @ViewChild('fileForm') fileFormElement: ElementRef;

  imageState$: Rx.Observable<ImageUiState>;
  tempImage$ = new Rx.BehaviorSubject<ImageInfo>({hasImage: false});

  private resetSub: Rx.Subscription;
  private imageStateSub: Rx.Subscription;
  private tempImageUpdateSub: Rx.Subscription;
  private randomInputId: string;

  constructor(
  ) {
    this.randomInputId = (new Date()).getTime() + '';
  }

  ngOnInit() {
    this.resetSub = this.reset$.subscribe(() => this.reset());

    this.tempImageUpdateSub = this.user$
      .subscribe(user => this.reset());

    this.imageState$ = Rx.Observable
      .combineLatest(
        this.user$,
        this.tempImage$,
        (user, tempImage) => ({user, tempImage})
      )
      .map(({user, tempImage}): ImageUiState => {
        const newImageState = {
          user: user,
          hasTempImage: tempImage.hasImage,
          imageUrl: tempImage.hasImage ? `data:${tempImage.mimeType};base64,${tempImage.encoded}` : user.avatar,
          hasImage: user.avatar != null || tempImage.hasImage,
        };

        return newImageState;
      });

    this.imageStateSub = this.imageState$
      .subscribe((imageState: ImageUiState) => {
        const formValue: any = {};

        if (imageState.hasTempImage) {
          formValue.avatar = imageState.imageUrl;
        }

        const newFormState = {
          isDirty: imageState.hasTempImage,
          isValid: true,
          value: formValue,
        };

        this.formState$.next(newFormState);
      });
  }

  ngOnDestroy() {
    this.resetSub.unsubscribe();
    this.imageStateSub.unsubscribe();
    this.tempImageUpdateSub.unsubscribe();
  }

  get uploadInputId(): string {
    return `photoUpload_id${this.randomInputId}`;
  }

  onChangeFileUpload($event) {
    const file = $event.target.files[0];
    const fileReader = new FileReader();
    let fileBinResults = null;
    fileReader.onload = (readerEvent) => {
      fileBinResults = (<any>readerEvent.target).result;

      this.tempImage$.next({
        hasImage: true,
        encoded: btoa(fileBinResults),
        mimeType: file.type,
      });
    };

    fileReader.readAsBinaryString(file);
  }

  private resetFileForm() {
    this.fileFormElement.nativeElement.reset();
  }

  private reset() {
    this.tempImage$.next({hasImage: false});
    this.resetFileForm();
  }
}
