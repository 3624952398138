import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import * as Rx from 'rxjs';
import { Actions as NgRxActions } from '@ngrx/effects';

import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { AppState } from 'app/store/app-state';
import { Actions } from 'app/store/actions';
import * as selectors from 'app/store/selectors';
import { AlertService } from 'app/shared/components/alerts/alert.service';
import { BakeryCustEffects } from 'app/store/effects/bakery-cust/bakery-cust.effects';
import { Bakery } from 'app/store/bakery';
import { MasterState } from 'app/store/reducers/master-state-reducer';
import { BakeryOrder } from 'app/store/bakery-order';
import { OrderHistoryDetailState } from 'app/store/reducers/bakery-cust-ui-state/order-history-state.reducer';
import { isCustomerCancelable } from './bakery-order-projections';
import { ModalComponent } from 'app/shared/components/modals/modal.component';

@Component({
  selector: 'bkry-cust-order-history-detail-order-cancel-button',
  template: `
    <button
      class="button--small"
      [ngClass]="{'button--danger': !(isCancelLoading$|async),'button--disabled': (isCancelLoading$|async)}"
      *ngIf="isCancelable$ | async"
      (click)="onClickCancel()"
    >
      <ng-template [ngIf]="!(isCancelLoading$|async)">Cancel</ng-template>
      <ng-template [ngIf]="(isCancelLoading$|async)">Canceling</ng-template>
    </button>
    <modal
      title="Are you sure about this?"
      intro="You are about to cancel this order. Once the order is canceled, you will receive a refund for any credit card payments you have made for this order. Please contact the bakery to arrange for refunds for any cash payments."
      confirmText="Confirm"
      cancelText="Cancel"
      (confirm)="onConfirmCancel()"></modal>
  `,
})
export class BakeryCustOrderHistoryDetailOrderCancelButtonComponent implements OnInit, OnDestroy {
  @ViewChild(ModalComponent) modal: ModalComponent;

  state$: Rx.Observable<OrderHistoryDetailState>;
  bakeryOrder$: Rx.Observable<BakeryOrder>;
  isCancelable$: Rx.Observable<boolean>;
  isCancelLoading$: Rx.Observable<boolean>;

  private cancel$ = new Rx.Subject<any>();
  private cancelSub: Rx.Subscription;
  private alertsSub: Rx.Subscription;

  constructor(
    private store: Store<AppState>,
    private alertService: AlertService,
    private actions$: NgRxActions,
  ) { }

  ngOnInit() {
    this.bakeryOrder$ = this.store
      .select(selectors.getWiredCustOrderHistoryDetailState)
      .map(state => state.wiredResult as BakeryOrder)
      .filter(val => !!val);

    this.isCancelLoading$ = this.store
      .select(selectors.getWiredCustOrderHistoryDetailState)
      .pluck('isCancelLoading');

    this.isCancelable$ = this.bakeryOrder$.map(isCustomerCancelable);

    this.cancelSub = this.cancel$
      .switchMap(() => this.isCancelLoading$.take(1))
      .filter(isCancelLoading => !isCancelLoading)
      // LOL.
      .switchMap(() => this.bakeryOrder$.take(1))
      .subscribe((bakeryOrder) => {
        this.store.dispatch({
          type: Actions.REQUEST_PUT_CUST_BAKERY_ORDER_CANCEL,
          payload: {
            ...bakeryOrder
          }
        });
      });

    this.alertsSub = this.actions$
      .subscribe((action: UnsafeAction) => {
        let messageType = null,
            messageContent = null;

        switch (action.type) {
          case Actions.REQUEST_PUT_CUST_BAKERY_ORDER_CANCEL_SUCCESS:
            messageType = 'success';
            messageContent = `Your order has been canceled.`;
            break;

          case Actions.REQUEST_PUT_CUST_BAKERY_ORDER_CANCEL_ERROR:
            messageType = 'warning';
            messageContent = `Something went wrong!`;
            break;
        }

        if (messageType != null && messageContent != null) {
          this.alertService[messageType](messageContent);
        }
      });
  }

  ngOnDestroy() {
    this.cancelSub.unsubscribe();
    this.alertsSub.unsubscribe();
  }

  onClickCancel() {
    this.modal.openModal();
  }

  onConfirmCancel() {
    this.cancel$.next();
  }
}
