import { Component, OnInit, OnDestroy, Input, Output } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

import { BakeryProduct } from 'app/store/bakery-product';
import { SimpleFormState } from 'app/shared/forms/form-states';
import { DetailUiState } from 'app/bakery-mgmt/product-mgmt/products/detail-ui-state';

@Component({
  selector: 'product-detail-forms-edit-taxes',
  template: `
    <form [formGroup]="form">
      <div class="card padding--reset margin-bottom--charlie">
        <header class="padding-top--delta padding-left--delta padding-right--delta">
          <h6 class="margin-reset--bottom">Sales Tax</h6>
        </header>
        <hr />
        <div class="padding-left--delta padding-right--delta">
          <div gridRow class="padding-bottom--delta">
            <div gridCol s="4" m="9" l="12">
              <label>
                <input type="checkbox" name="taxable" formControlName="taxable" />
                Apply Sales Tax
              </label>
            </div>
          </div>
        </div>
      </div>
    </form>
  `
})
export class ProductDetailFormsEditTaxesComponent implements OnInit, OnDestroy {
  @Input('uiState$') uiState$: Observable<DetailUiState>;
  @Input('reset$') reset$: Observable<any>;
  @Output('formState') formState$: Observable<SimpleFormState>;

  form: FormGroup;

  private formValueSub: Subscription;

  constructor(
  ) {
    this.form = new FormGroup({
      taxable: new FormControl(null, []),
    });

    this.formState$ = this.form.valueChanges
      .map((): SimpleFormState => {
        return {
          isDirty: this.form.dirty,
          isValid: this.form.valid,
          value: this.form.value,
        };
      })
  }

  ngOnInit() {
    this.formValueSub = Observable
      .merge(
        this.uiState$.filter((uiState: DetailUiState) => !uiState.isLoading),
        this.reset$.switchMap(() => this.uiState$.filter((uiState: DetailUiState) => !uiState.isLoading).take(1))
      )
      .map((uiState: DetailUiState) => uiState.bakeryProduct)
      .subscribe((bakeryProduct: BakeryProduct) => {
        // FIXME: For some reason, a true form reset requires calling #reset twice...
        this.resetForm(bakeryProduct);
        this.resetForm(bakeryProduct);
      })
  }

  ngOnDestroy() {
    this.formValueSub.unsubscribe();
  }

  private resetForm(bakeryProduct: BakeryProduct) {
    this.form.reset({
      taxable: bakeryProduct.taxable,
    });
  }
}
