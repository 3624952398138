import { Component, OnInit, Input, Output, ViewChild, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UiState } from 'app/bakery-mgmt/new-order/master/master.component';
import { BakeryProduct } from 'app/store/bakery-product';
import { Subscription } from 'rxjs/Subscription';
import { Actions } from 'app/store/actions';
import { Actions as NgRxActions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { OrderItem } from 'app/store/order-item';
import { AppState } from 'app/store/app-state';
import { User } from 'app/store/user';
import { ActivatedRoute } from '@angular/router';
import { validateNoWhitespaces } from 'app/shared/forms/no-whitespaces-validator';
import { RemoteData } from 'app/remote-data';

@Component({
  selector: 'bakery-mgmt-new-order-product-selection',
  template: require('./product-selection.component.html'),
})
export class BakeryMgmtNewOrderProductSelectionComponent implements OnInit, OnDestroy {
  @Input() isLoading: boolean;
  @Input() checkout: boolean;
  @Input() employee: boolean;
  @Input() wholesaler: boolean;
  @Input() changeToCheckout: Function;
  @Input() selectedCustomer: User;
  @Input() bakeryProducts: RemoteData<BakeryProduct[]>;
  @Input() orderItems: OrderItem[];
  @Input() isCard = false;
  @Output() changeFilterProductText$: Observable<string>;
  private stateSub: Subscription;

  filterForm: FormGroup;
  constructor(
    private actions$: NgRxActions,
    private store: Store<AppState>,
    private route: ActivatedRoute,
  ) {
    this.wholesaler = this.route.snapshot.data.wholesaler || this.wholesaler;
    this.filterForm = new FormGroup({
      'search': new FormControl('', [Validators.required, validateNoWhitespaces]),
    });

    this.changeFilterProductText$ = this.filterForm
      .get('search')
      .valueChanges
      .debounceTime(300)
      .distinctUntilChanged()
      .filter(x => x.length >= 3)
      .filter(x => this.filterForm.valid)
      .map(x => x.trim());
  }

  ngOnInit() {
    this.stateSub = this.actions$
      .subscribe((action: UnsafeAction) => {
        switch (action.type) {
          case Actions.REQUEST_UPDATE_EMPLOYEE_CART_SUCCESS:
          case Actions.REQUEST_CREATE_EMPLOYEE_CART_SUCCESS:
          case Actions.REQUEST_UPDATE_WHOLESALE_CART_SUCCESS:
          case Actions.REQUEST_CREATE_WHOLESALE_CART_SUCCESS:
            this.filterForm.patchValue({search: ''});
            break;
        }
      });
  }

  onClickDeleteCart() {
    this.store.dispatch({
      type: Actions.REQUEST_DELETE_EMPLOYEE_CART
    });
  }

  ngOnDestroy() {
    this.stateSub.unsubscribe();
  }
}
