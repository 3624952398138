import {
  Component,
  Input,
  OnInit,
  OnDestroy
} from '@angular/core';
import { Actions as NgRxActions } from '@ngrx/effects';
import { Subscription } from 'rxjs';

import { AlertService } from 'app/shared/components/alerts/alert.service';
import { AlertAction } from './actions';

@Component({
  selector: 'alert-container',
  template: `
    <simple-notifications
      class="alert__container"
      [options]="options"
    ></simple-notifications>
  `
})
export class AlertContainerComponent implements OnInit, OnDestroy {
  @Input() options = {
    timeOut: 7500,
    lastOnBottom: true,
    clickToClose: true,
    showProgressBar: false,
    animate: null,
    position: ["top", "left"],
  };

  private alertsSub: Subscription;

  constructor(
    private actions$: NgRxActions,
    private alertService: AlertService,
  ) { }

  ngOnInit() {
    this.alertsSub = this.actions$
      .ofType(AlertAction.type)
      .subscribe((action: AlertAction) => {
        const messageType = action.payload.type != null ? action.payload.type : 'notification',
            messageContent = action.payload.message;

        this.alertService[messageType](messageContent);
      });
  }

  ngOnDestroy() {
    this.alertsSub.unsubscribe();
  }
}
