import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';

import { AppState } from 'app/store/app-state';
import { CentsPipe } from 'app/shared/pipes/cents.pipe';
import { Bakery } from 'app/store/bakery';

@Pipe({
  name: 'centsObservable'
})
export class CentsObservablePipe implements PipeTransform {
  constructor(
    private store: Store<AppState>,
    private centsPipe: CentsPipe
  ) {}

  public transform(
    priceInCents: number,
    currencyCode?: string,
    display?: 'code'|'symbol'|'symbol-narrow'|string|boolean,
    digitsInfo?: string,
    locale?: string
  ) {
    return this.store
      .select('entitiesState')
      .select('bakeries')
      .map((bakeriesById: Record<any, Bakery>) => Object.values(bakeriesById))
      .map((bakeries: Bakery[]) => bakeries.map(bakery => bakery.currency_code))
      .map((currencyCodes: string[]) => currencyCodes[0])
      .distinctUntilChanged()
      .map(_currencyCode => {
        return this.centsPipe.transform(priceInCents, _currencyCode, 'symbol-narrow', digitsInfo, locale);
      });
  }
}
