import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'bakery-mgmt-store-main',
  template: `
    <bakery-mgmt-page-header title="Manage Company"></bakery-mgmt-page-header>
    <div class="tabs">
      <ul class="tab__list tab__list--light margin-bottom--charlie">
        <li class="tab__item"
          routerLink="locations"
          routerLinkActive="is-active">
          Locations
        </li>
        <li class="tab__item"
          routerLink="payments"
          routerLinkActive="is-active">
          Payments
        </li>
        <li class="tab__item"
          routerLink="settings"
          routerLinkActive="is-active">
          Settings
        </li>
      </ul>
    </div>
    <router-outlet></router-outlet>
  `
})
export class BakeryMgmtStoreMainComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}
