import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  template: `
    <bkry-cust-order-detail
      [orderId]="detailId$ | async"
      backLink="/store/wholesaler/management/schedule"
      backLinkText="Back to order schedule"
    ></bkry-cust-order-detail>
  `,
})
export class BakeryWholesalerCustOrderDetailComponent {
  detailId$ = this.route.params
    .map((params: any): number => parseInt(params['order_id']));

  constructor(
    private route: ActivatedRoute,
  ) { }
}
