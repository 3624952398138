import { Component, OnInit, OnDestroy, Input, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Response } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import { Actions as NgRxActions } from '@ngrx/effects';

import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { Actions } from 'app/store/actions';
import {
  BakeryMgmtPromoCodesEffects,
  UiState
} from 'app/store/effects/bakery-mgmt/bakery-mgmt-promo-codes.effects';
import { AlertService } from 'app/shared/components/alerts/alert.service';
import { PromoCode } from 'app/store/promo-code';
import { validateNoWhitespaces } from 'app/shared/forms/no-whitespaces-validator';

@Component({
  selector: 'bakery-mgmt-promo-codes-header',
  template: require('./header.component.html')
})
export class BakeryMgmtPromoCodesHeaderComponent implements OnInit, OnDestroy {
  @Input('editablePromoCode$') editablePromoCode$;
  editablePromoCodeSub: Subscription;
  filters$ = new Subject<string>();
  form: FormGroup;
  filterForm: FormGroup;
  isAddPromoCodeOpen = false;
  isUpdating: Boolean = false;
  isSubmitting: Boolean = false;

  private alertsSub: Subscription;

  constructor(
    private bakeryMgmtPromoCodesEffects: BakeryMgmtPromoCodesEffects,
    private alertService: AlertService,
    private actions$: NgRxActions
  ) {}

  get uiState$() {
    return this.bakeryMgmtPromoCodesEffects.uiState$;
  }

  ngOnInit() {
    this.editablePromoCodeSub = this.editablePromoCode$.subscribe(promoCode => {
      if (promoCode) {
        this.isUpdating = true;
        this.isAddPromoCodeOpen = true;
        this.form.patchValue(promoCode);
      } else {
        this.isAddPromoCodeOpen = false;
      }
    });

    this.filterForm = new FormGroup({
      code: new FormControl(null, [Validators.required, validateNoWhitespaces])
    });

    this.filterForm
      .get('code')
      .valueChanges.debounce(() => Observable.timer(500))
      .forEach(filterText => {
        this.bakeryMgmtPromoCodesEffects.changePromoCodeFilter(filterText);
      });

    this.form = new FormGroup({
      id: new FormControl(null, []),
      code: new FormControl(null, [Validators.required, validateNoWhitespaces]),
      start_date: new FormControl(null, [Validators.required]),
      end_date: new FormControl(null, [Validators.required]),
      discount_type: new FormControl(null, [Validators.required]),
      discount: new FormControl(null, [Validators.required])
    });

    this.form.get('discount_type').valueChanges.forEach(value => {
      if (this.isUpdating) {
        this.form.get('discount_type').disable({emitEvent: false});
        this.form.get('discount').disable({emitEvent: false});
        return;
      }

      this.form.get('discount_type').enable({emitEvent: false});

      if (value === null) {
        this.form.get('discount').disable({emitEvent: false});
      } else {
        this.form.get('discount').enable({emitEvent: false});
      }

      this.form.patchValue({discount: null})
    })

    this.alertsSub = this.actions$.subscribe((action: UnsafeAction) => {
      let messageType = null,
        messageContent = null;

      switch (action.type) {
        case Actions.REQUEST_UPDATE_PROMO_CODE_SUCCESS:
          this.isSubmitting = false;
          this.onClickCancel();
          messageType = 'success';
          messageContent = `The promotional code has been updated.`;
          break;

        case Actions.REQUEST_CREATE_PROMO_CODE_SUCCESS:
          this.isSubmitting = false;
          this.onClickCancel();
          messageType = 'success';
          messageContent = `The promotional code has been created.`;
          break;

        case Actions.REQUEST_UPDATE_PROMO_CODE_ERROR:
        case Actions.REQUEST_CREATE_PROMO_CODE_ERROR:
          this.isSubmitting = false;
          messageType = 'warning';
          messageContent = `Something went wrong! Please try again later.`;

          if (action.payload.error_type === 'unique_error') {
            messageContent = `The promotional code already exists.`;
          }
          break;
      }

      if (messageType != null && messageContent != null) {
        this.alertService[messageType](messageContent);
      }
    });
  }

  ngOnDestroy() {
    this.alertsSub.unsubscribe();
    this.editablePromoCodeSub.unsubscribe();
  }

  onClickAddPromoCode() {
    this.isAddPromoCodeOpen = true;
  }

  onClickCancel() {
    this.isUpdating = false;
    this.isAddPromoCodeOpen = false;
    this.editablePromoCode$.next(null);
    this.form.reset({ discount_type: null });
  }

  onSubmitAddPromoCode() {
    if (!this.form.valid || !this.form.dirty || this.isSubmitting) return;
    this.isSubmitting = true;
    if (this.isUpdating) {
      this.bakeryMgmtPromoCodesEffects.requestUpdatePromoCode(
        this.form.value
      );
    } else {
      this.bakeryMgmtPromoCodesEffects.requestCreatePromoCode(
        this.form.value
      );
    }
  }
}
