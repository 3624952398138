import { Component, OnInit, Input, Output, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { UiState } from 'app/bakery-mgmt/new-order/master/master.component';
import { User } from 'app/store/user';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Subject } from 'rxjs/Subject';
import { ActivatedRoute } from '@angular/router';
import { validateNoWhitespaces } from 'app/shared/forms/no-whitespaces-validator';

@Component({
  selector: 'bakery-mgmt-new-order-customer-selection',
  template: require('./customer-selection.component.html'),
})
export class BakeryMgmtNewOrderCustomerSelectionComponent implements OnInit, OnDestroy {
  @Input() customers: User[];
  @Input() selectedCustomer: User;
  @Output() changeFilterCustomerText$ = new Subject<string>();
  @Input() onChangeCustomer: Function;
  wholesaler: boolean;
  filterForm: FormGroup;
  private subscription: Subscription;

  constructor(
    private route: ActivatedRoute
  ) {
    this.wholesaler = this.route.snapshot.data.wholesaler;
    this.filterForm = new FormGroup({
      'search': new FormControl(null, [Validators.required, validateNoWhitespaces]),
    });
  }

  ngOnInit() {
    this.subscription = this.filterForm
      .get('search')
      .valueChanges
      .debounceTime(300)
      .distinctUntilChanged()
      .filter(x => x.length >= 2)
      .filter(x => this.filterForm.valid)
      .map(x => x.trim())
      .subscribe((x: string) => this.changeFilterCustomerText$.next(x));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
