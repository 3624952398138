import {
  Component,
  Input,
  Output,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject, Subscription } from 'rxjs';
import * as moment from 'moment/moment';

import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { AppState } from 'app/store/app-state';
import * as selectors from 'app/store/selectors';
import { GetAction } from 'app/store/actions/request.actions';
import {
  BakeryOrder,
  allOrderStatuses,
  orderFulfillmentDateDescSorter
} from 'app/store/bakery-order';
import { User } from 'app/store/user';
import { WholesalerOrg } from 'app/store/wholesaler-org';
import { bakeryOrderSchema } from 'app/store/schema/default-schemata';
import { cloneSort, sortByCreatedAt } from 'app/store/entity-utils';
import { DateRange } from 'app/shared/components/date-range-search-form/date-range-search-form.component';
import { Actions } from 'app/store/actions';
import { AlertService } from 'app/shared/components/alerts/alert.service';
import { Actions as NgRxActions } from '@ngrx/effects';

const compSelector = 'bkry-mgmt-wholesaler-detail-orders';

const opKey = 'wholesalerOrderHistory',
      schema = [bakeryOrderSchema];

interface OrderDateMap {
  [key: string]: BakeryOrder[]
}

@Component({
  template: require('./schedule.component.html'),
})
export class ScheduleComponent implements OnInit {
  isLoading$: Observable<boolean> = this.store
    .select(selectors.getRequestIsLoading(opKey));

  bakeryOrders$: Observable<Array<BakeryOrder[]>> = this.store
    .select(selectors.getRequestResult(opKey, schema))
    .filter(val => !!val)
    .map((bakeryOrders: BakeryOrder[]) => {
      const dateMap: OrderDateMap = bakeryOrders
        .reduce((map: OrderDateMap, bakeryOrder: BakeryOrder) => {
          const date = moment(bakeryOrder.fulfillment_date).startOf('day').toISOString();
          map[date] = map[date] || [];

          map[date].push(bakeryOrder);

          return map;
        }, {});

      return Object.keys(dateMap)
        // Convert back to arrays of bakery orders
        .map(key => dateMap[key])
        // Replace each array with a sorted array
        .map((bakeryOrders: BakeryOrder[]) => bakeryOrders.sort((a, b) => -1 * sortByCreatedAt(a, b)))
        // Sort between the bakery orders arrays to present in descending order.
        .sort((arrA: BakeryOrder[], arrB: BakeryOrder[]): number => {
          const boA = arrA[0], boB = arrB[0];

          return orderFulfillmentDateDescSorter(boA, boB);
        });
    })
    .startWith([]);

  wholesalerOrg$: Observable<WholesalerOrg> = this.store
    .select(selectors.getCurrentUser)
    .filter(val => !!val)
    .map((user: User) => user.wholesaler_org);

  permitWholesalerEcomm$: Observable<boolean> = this.store
    .select(selectors.getCustBakeryPermitWholesalerEcomm)
    .startWith(false);

  dateRange$ = new Subject<DateRange>();
  searchStartDate = moment().startOf('day').toISOString();
  searchEndDate = moment().startOf('day').add(7, 'days').toISOString();

  private initSub: Subscription;
  private dateRangeSub: Subscription;
  alertsSub: Subscription;

  constructor(
    private store: Store<AppState>,
    private alertService: AlertService,
    private actions$: NgRxActions) {

    this.alertsSub = this.actions$
      .subscribe((action: UnsafeAction) => {
        let messageType = null,
            messageContent = null;

        switch (action.type) {
          case Actions.REQUEST_PUT_WHOLESALE_BAKERY_ORDER_CANCEL_ERROR:
            messageType = 'warning';
            messageContent = action.payload.error || 'Something went wrong!';
            break;
          case Actions.REQUEST_PUT_WHOLESALE_BAKERY_ORDER_CANCEL_SUCCESS:
            messageType = 'success';
            messageContent = 'Got it. We have notified the bakery and your next scheduled order will be canceled and all orders after that one.';
            break;
        }

        if (messageType != null && messageContent != null) {
          this.alertService[messageType](messageContent);
        }
      });
  }


  ngOnInit() {
    this.initSub = this.wholesalerOrg$
      .filter(val => !!val)
      .take(1)
      .subscribe((wholesalerOrg: WholesalerOrg) => {
        this.executeQuery(
          wholesalerOrg.bakery_id,
          wholesalerOrg.id,
          this.searchStartDate,
          this.searchEndDate
        )
      });

    this.dateRangeSub = this.dateRange$
      .withLatestFrom(
        this.wholesalerOrg$.filter(val => !!val),
        (dateRange, wholesalerOrg) => ({dateRange, wholesalerOrg})
      )
      .subscribe(({dateRange, wholesalerOrg}) => {
        this.executeQuery(
          wholesalerOrg.bakery_id,
          wholesalerOrg.id,
          dateRange.searchStartDate,
          dateRange.searchEndDate
        )
      });
  }

  onClickConfirmCancelOrder = (bakeryOrder) => {
    this.store.dispatch({
      type: Actions.REQUEST_PUT_WHOLESALE_BAKERY_ORDER_CANCEL,
      payload: {
        id: bakeryOrder.id
      }
    });
  }

  ngOnDestroy() {
    this.initSub.unsubscribe();
    this.alertsSub.unsubscribe();
    this.dateRangeSub.unsubscribe();
  }

  private executeQuery(
    bakeryId: number,
    wholesalerOrgId: number,
    searchStartDate: string,
    searchEndDate: string
  ) {
    const actionPayload = {
      url: `/api/bakeries/${bakeryId}/orders`,
      opKey: opKey,
      schema: schema,
      queryParams: {
        parent_type: 'WholesalerOrg',
        parent_id: wholesalerOrgId,
        search_start_date: searchStartDate,
        search_end_date: searchEndDate,
        order_status: allOrderStatuses
      }
    };
    this.store.dispatch(new GetAction(actionPayload));
  }

  onChangeDateRange($event: DateRange) {
    this.dateRange$.next($event);
  }
}
