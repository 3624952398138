import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { Actions } from 'app/store/actions';

export interface State {
  item_quantity: number;
  categories: Category[];
}

interface Category {
  categary_item_quantity: number;
  categary_name: string;
  products: Product[];
}

interface Product {
  product_item_quantity: number;
  product_name: string;
  templates: Template[];
}

interface Template {
  template_name: string;
  template_quantity: number;
  unit_quantity: number;
}

const INITIAL_STATE = {
  item_quantity: 0,
  categories: [],
};

export function reducer(state: State = INITIAL_STATE, action: UnsafeAction): State {
  switch (action.type) {
    case Actions.REQUEST_ITEM_SUMMARY_SUCCESS:
      return action.payload;

    default:
      return state;
  }
}
