import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as moment from 'moment/moment';
import { Moment } from 'moment/moment';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Angular2TokenService } from 'app/angular2-token/angular2-token.service';
import { HttpParams, HttpParameterCodec } from '@angular/common/http';

import { AppState } from 'app/store/app-state';
import { Actions } from 'app/store/actions';
import * as selectors from 'app/store/selectors';
import { DateRange } from 'app/shared/components/date-range-search-form/date-range-search-form-2.component';

declare var process: any;

@Component({
  template: require('./attribute-summary.component.html'),
})

export class BakeryMgmtReportingAttributeSummaryComponent implements OnInit, OnDestroy {
  filterForm: FormGroup;
  locationForm: FormGroup;
  locationControls$;

  attributeSummary$ = this.store.select(selectors.selectReportingAttributeSummary);
  selectedFulfillments$: Observable<string>;
  bakeryLocations$: Observable<{ id: number, name: string }[]> = this.store
    .select(selectors.selectBakeryLocations)
    .pluck('bakeryLocations');

  onChangeSearchOptions$ = new ReplaySubject<DateRange>();
  searchStartDate$ = this.onChangeSearchOptions$.pluck('searchStartDate').map((x: Moment) => x.toISOString());
  searchEndDate$ = this.onChangeSearchOptions$.pluck('searchEndDate').map((x: Moment) => x.toISOString());

  exportHref$: Observable<string>;

  private subscription: Subscription;

  constructor(
    private store: Store<AppState>,
    private tokenService: Angular2TokenService
  ) {
    this.filterForm = new FormGroup({
      delivery: new FormControl(true),
      shipping: new FormControl(true),
    });
    this.locationForm = new FormGroup({});
  }

  ngOnInit() {
    this.store.dispatch({ type: Actions.GET_BAKERY_LOCATIONS });

    this.locationControls$ = this.bakeryLocations$
      .skipWhile((xs: any[]) => xs.length === 0)
      .take(1)
      .do((locations: any[]) => {
        locations.forEach(location => {
          this.locationForm.addControl(location.id.toString(), new FormControl(true));
        });
      })
      .map((locations: any[]) => {
        return locations.map(location => (
          { controlName: location.id.toString(), controlLabel: location.name + (location.deleted ? ' (deleted)' : '') }
        ));
      });

    const locationFormValueChanges$ = this.locationControls$
      // Emit only after locationControls$ has completed
      .concatMap(() => this.locationForm.valueChanges.startWith(this.locationForm.value))
      // Emit only when values changed
      .distinctUntilChanged((x, y) => JSON.stringify(x) === JSON.stringify(y));
      // The above is needed because addControl emits so
      // - we want to wait for the controls to be there
      // - we want changes to be distinct

    const locationFormSelectedFilters$ = locationFormValueChanges$
      .map(x => [this.getSelectedFiltersFor(x)]);

    const filterFormSelectedFilters$ = this.filterForm
      .valueChanges
      .startWith(this.filterForm.value)
      .map(x => [this.getSelectedFiltersFor(x)]);

    const filters$ = Observable.combineLatest(
      this.onChangeSearchOptions$,
      filterFormSelectedFilters$,
      locationFormSelectedFilters$
    ).map(([searchOptions, filterForm, locationForm]) => {
      return {
        search_start_date: searchOptions.searchStartDate.format('YYYY-MM-DD'),
        search_end_date: searchOptions.searchEndDate.format('YYYY-MM-DD'),
        fulfillment_types: filterForm,
        locations: locationForm
      };
    });

    this.subscription = filters$.subscribe((payload) => {
      this.store.dispatch({
        type: Actions.REQUEST_ATTRIBUTE_SUMMARY,
        payload
      });
    });

    this.exportHref$ = filters$.map((payload) => {
      const params = new HttpParams({
        fromObject: {
          ...this.tokenService.getAuthDataFromStorage(),
          ...payload,
        },
        encoder: new HttpUrlEncodingCodec()
      });
      return `${process.env.API_URL}/api/bakery/reports/attribute_summary.csv?${params}`;
    });

    const selectedBakeryLocationNames$ = Observable.combineLatest(
      locationFormValueChanges$,
      this.bakeryLocations$
    )
    .map(([locationForm, bakeryLocations]) => {
      const isSelected = (form, id) =>
        this.getSelectedFiltersFor(form).includes(String(id));

      return bakeryLocations
        .filter(location => isSelected(locationForm, location.id))
        .map(location => `pickup at ${location.name}`);
    })
    .startWith([]);

    const selectedFulfillmentTypes$ = this.filterForm.valueChanges
      .startWith(this.filterForm.value)
      .map(filterForm => this.getSelectedFiltersFor(filterForm));

    this.selectedFulfillments$ = Observable.combineLatest(
      selectedBakeryLocationNames$,
      selectedFulfillmentTypes$
    )
    .map(([bakeryLocations, fulfillmentTypes]) => bakeryLocations.concat(fulfillmentTypes).join(', '));
  }

  getSelectedFiltersFor(filters) {
    const selectedFilters = [];
    for (const key of Object.keys(filters)) {
      if (filters[key]) {
        selectedFilters.push(key);
      }
    }
    return selectedFilters;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}

class HttpUrlEncodingCodec implements HttpParameterCodec {
  encodeKey(k: string): string { return encodeURIComponent(k); }

  encodeValue(v: string): string { return Array.isArray(v) ? encodeURIComponent(JSON.stringify(v)) : encodeURIComponent(v); }

  decodeKey(k: string): string { return decodeURIComponent(k); }

  decodeValue(v: string) { return decodeURIComponent(v); }
}
