import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { Store } from '@ngrx/store';
import { Angular2TokenService } from 'app/angular2-token/angular2-token.service';
import { Actions as NgRxActions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';
import { normalize } from 'normalizr';

import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { AppState } from 'app/store/app-state';
import { Actions } from 'app/store/actions';
import { BakeryMgmtEffects } from 'app/store/effects/bakery-mgmt.effects';
import { Bakery } from 'app/store/bakery';
import { BakeryLocation } from 'app/store/bakery-location';
import { hasRole } from 'app/store/users/utils';
import { sortUsersByRoleName } from 'app/store/users/sort';
import { BakeryMgmtUiState } from 'app/store/bakery-mgmt-ui-state';
import { EntitiesState } from 'app/store/entities-state';
import { bakeryLocationSchema } from 'app/store/schema/bakery-location';

export interface MasterUiState {
  isLoading: boolean;
  locations: BakeryLocation[];
  locationsCount: number;
}

export interface DetailUiState {
  isLoading: boolean;
  location: BakeryLocation;
}

@Injectable()
export class BakeryMgmtLocationsEffects {
  masterUiStates$: Observable<MasterUiState>;
  detailUiStates$: Observable<DetailUiState>;

  constructor(
    private actions$: NgRxActions,
    private tokenService: Angular2TokenService,
    private store: Store<AppState>,
    private bakeryMgmtEffects: BakeryMgmtEffects,
  ) {
    this.masterUiStates$ = Observable
      .combineLatest(
        this.store.select('bakeryMgmtUiState'),
        this.store.select('entitiesState'),
        (uiState, entities) => ({uiState, entities})
      )
      .map((combined) => {
        const bakeryMgmtUiState = <BakeryMgmtUiState> combined.uiState;
        const entitiesState = <EntitiesState> combined.entities;

        if (bakeryMgmtUiState.locationsUiState.isLocationsLoading) {
          return {
            isLoading: true,
            locations: [],
            locationsCount: 0,
          };
        }

        const locations: BakeryLocation[] = Object.keys(entitiesState.bakery_locations)
          .map(key => entitiesState.bakery_locations[key])
          .map(bakeryLocation => Object.assign({}, bakeryLocation));

        return {
          isLoading: false,
          locations: locations,
          locationsCount: locations.length,
        }
      })
      .shareReplay(1);

    this.detailUiStates$ = Observable
      .combineLatest(
        this.store.select('bakeryMgmtUiState'),
        this.store.select('entitiesState'),
        (uiState, entities) => ({uiState, entities})
      )
      .map((combined) => {
        const locationsUiState = (<BakeryMgmtUiState> combined.uiState).locationsUiState;
        const entitiesState = <EntitiesState> combined.entities;

        if (
          locationsUiState.isLocationLoading ||
          locationsUiState.detailId == null ||
          entitiesState.bakery_locations[locationsUiState.detailId] == null
        ) {
          return {
            isLoading: true,
            location: null,
          };
        }

        const location = Object.assign({}, entitiesState.bakery_locations[locationsUiState.detailId]);

        return {
          isLoading: false,
          location: location,
        }
      })
      .shareReplay(1);
  }

  requestGetLocations() {
    this.store.dispatch({
      type: Actions.REQUEST_BAKERY_LOCATIONS,
    });
  }

  requestGetLocation(id: number) {
    this.store.dispatch({
      type: Actions.REQUEST_BAKERY_LOCATION,
      payload: id,
    });
  }

  requestDestroyLocation(location: BakeryLocation) {
    this.store.dispatch({
      type: Actions.REQUEST_DESTROY_BAKERY_LOCATION,
      payload: location,
    });
  }

  requestUpdateLocation(location: BakeryLocation, updatePayload: any) {
    this.store.dispatch({
      type: Actions.REQUEST_UPDATE_BAKERY_LOCATION,
      payload: {
        id: location.id,
        updates: updatePayload
      },
    });
  }

  requestCreateLocation(location: BakeryLocation) {
    this.store.dispatch({
      type: Actions.REQUEST_CREATE_BAKERY_LOCATION,
      payload: location,
    });
  }

  @Effect() getBakeryLocationsEffects$ = this.actions$
    .ofType(Actions.REQUEST_BAKERY_LOCATIONS)
    .switchMap((action: UnsafeAction) => {
      return this.bakeryMgmtEffects.currentBakery$
        .filter(bakery => bakery != null)
        .map(bakery => ({action, bakery}))
        .take(1)
    })
    .switchMap(combined => {
      const action = <UnsafeAction> combined.action;
      const bakery = <Bakery> combined.bakery;

      return this.tokenService.get(`/api/bakeries/${bakery.id}/locations`)
        .map((response: Response) => response.json())
        .map((data: any[]) => {
          const results = normalize(data, [bakeryLocationSchema]);

          return results.entities;
        })
        .switchMap((entities) => {
          return Observable.from([
            {
              type: Actions.LOADED_ENTITIES,
              payload: entities,
            },
            {
              type: Actions.REQUEST_BAKERY_LOCATIONS_SUCCESS,
            },
          ]);
        })
    })
    .share();

  @Effect() getBakeryLocationEffects$ = this.actions$
    .ofType(Actions.REQUEST_BAKERY_LOCATION)
    .switchMap((action: UnsafeAction) => {
      return this.bakeryMgmtEffects.currentBakery$
        .filter(bakery => bakery != null)
        .map(bakery => ({action, bakery}))
        .take(1)
    })
    .switchMap(combined => {
      const action = <UnsafeAction> combined.action;
      const bakery = <Bakery> combined.bakery;

      return this.tokenService.get(`/api/bakeries/${bakery.id}/locations/${action.payload}`)
        .map((response: Response) => response.json())
        .map((data: any[]) => {
          const results = normalize(data, bakeryLocationSchema);

          return results.entities;
        })
        .switchMap((entities) => {
          return Observable.from([
            {
              type: Actions.LOADED_ENTITIES,
              payload: entities,
            },
            {
              type: Actions.REQUEST_BAKERY_LOCATION_SUCCESS,
            },
          ]);
        })
    })
    .share();

  @Effect() destroyBakeryLocationEffects$ = this.actions$
    .ofType(Actions.REQUEST_DESTROY_BAKERY_LOCATION)
    .switchMap((action: UnsafeAction) => {
      return this.bakeryMgmtEffects.currentBakery$
        .filter(bakery => bakery != null)
        .map(bakery => ({action, bakery}))
        .take(1)
    })
    .switchMap(combined => {
      const action = <UnsafeAction> combined.action;
      const bakery = <Bakery> combined.bakery;

      return this.tokenService.delete(`/api/bakeries/${bakery.id}/locations/${action.payload.id}`)
        .map((response: Response) => response.json())
        .switchMap((entities) => {
          return Observable.from([
            {
              type: Actions.REMOVE_ENTITY,
              payload: {
                typeKey: 'bakery_locations',
                entityKey: action.payload.id
              },
            },
            {
              type: Actions.REQUEST_DESTROY_BAKERY_LOCATION_SUCCESS,
            },
          ]);
        })
    })
    .share();

  @Effect() updateBakeryLocationEffects$ = this.actions$
    .ofType(Actions.REQUEST_UPDATE_BAKERY_LOCATION)
    .switchMap((action: UnsafeAction) => {
      return this.bakeryMgmtEffects.currentBakery$
        .filter(bakery => bakery != null)
        .map(bakery => ({action, bakery}))
        .take(1)
    })
    .switchMap(combined => {
      const action = <UnsafeAction> combined.action;
      const bakery = <Bakery> combined.bakery;

      return this.tokenService
        .put(
          `/api/bakeries/${bakery.id}/locations/${action.payload.id}`,
          JSON.stringify(action.payload.updates)
        )
        .map((response: Response) => response.json())
        .map((data: any[]) => {
          const results = normalize(data, bakeryLocationSchema);

          return results.entities;
        })
        .switchMap((entities) => {
          return Observable.from([
            {
              type: Actions.LOADED_ENTITIES,
              payload: entities,
            },
            {
              type: Actions.REQUEST_UPDATE_BAKERY_LOCATION_SUCCESS,
            },
          ]);
        })
        .catch((error) => {
          const errorPayload = error.json();

          return Observable.of({
            type: Actions.REQUEST_UPDATE_BAKERY_LOCATION_ERROR,
            payload: errorPayload,
          });
        });
    })
    .share();

  @Effect() createBakeryLocationEffects$ = this.actions$
    .ofType(Actions.REQUEST_CREATE_BAKERY_LOCATION)
    .switchMap((action: UnsafeAction) => {
      return this.bakeryMgmtEffects.currentBakery$
        .filter(bakery => bakery != null)
        .map(bakery => ({action, bakery}))
        .take(1)
    })
    .switchMap(combined => {
      const action = <UnsafeAction> combined.action;
      const bakery = <Bakery> combined.bakery;

      return this.tokenService
        .post(
          `/api/bakeries/${bakery.id}/locations`,
          JSON.stringify(action.payload)
        )
        .map((response: Response) => response.json())
        .map((data: any[]) => {
          const results = normalize(data, bakeryLocationSchema);

          return results.entities;
        })
        .switchMap((entities) => {
          return Observable.from([
            {
              type: Actions.LOADED_ENTITIES,
              payload: entities,
            },
            {
              type: Actions.REQUEST_CREATE_BAKERY_LOCATION_SUCCESS,
            },
          ]);
        })
        .catch((error) => {
          const errorPayload = error.json();

          return Observable.of({
            type: Actions.REQUEST_CREATE_BAKERY_LOCATION_ERROR,
            payload: errorPayload,
          });
        });
    })
    .share();
}
