import * as moment from 'moment/moment';

export function isCustomerCancelable(bakeryOrder: any): any {
  const isCancelableStatus = (
    bakeryOrder.order_status === 'hold' || bakeryOrder.order_status === 'submitted'
  );
  let isCancelableDate = false;
  if (isCancelableStatus) {
    const minimumCancellableDate = moment().add(48, 'hours'),
    fulfillDateMoment = moment(bakeryOrder.fulfillment_date);

    isCancelableDate = fulfillDateMoment.isAfter(minimumCancellableDate);
  }

  return isCancelableStatus && isCancelableDate;
}
