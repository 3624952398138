import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { Store } from '@ngrx/store';

import { PromoCode } from 'app/store/promo-code';
import {
  BakeryMgmtPromoCodesEffects,
  UiState,
} from 'app/store/effects/bakery-mgmt/bakery-mgmt-promo-codes.effects';
import { AppState } from 'app/store/app-state';
import { AuthState } from 'app/store/auth-state';
import { ModalComponent } from 'app/shared/components/modals/modal.component';

interface ActionState {
  isRendered: boolean;
  isEnabled: boolean;
}

interface ExtraActionsState {
  archivePromoCode: ActionState;
  unarchivePromoCode: ActionState;
  deletePromoCode: ActionState;
}

@Component({
  selector: 'bakery-mgmt-promo-codes-list-item',
  template: require('./list-item.component.html'),
})
export class BakeryMgmtPromoCodesListItemComponent implements OnInit {
  @Input('editablePromoCode$') editablePromoCode$;
  @Input() promoCode: PromoCode;
  @ViewChild(ModalComponent) modal: ModalComponent;
  extraActionsStates$: Observable<ExtraActionsState>;

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private bakeryMgmtPromoCodesEffects: BakeryMgmtPromoCodesEffects,
  ) { }

  ngOnInit() {

  }

  onClickEdit() {
    this.editablePromoCode$.next(this.promoCode);
  }

  onClickDelete() {
    this.modal.openModal();
  }

  onConfirmDelete() {
    this.bakeryMgmtPromoCodesEffects.requestDeletePromoCode(this.promoCode.id);
  }
}
