import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as Rx from 'rxjs';
import { Actions as NgRxActions } from '@ngrx/effects';

import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { AppState } from 'app/store/app-state';
import { AlertService } from 'app/shared/components/alerts/alert.service';
import { BakeryCustEffects } from 'app/store/effects/bakery-cust/bakery-cust.effects';
import { UsersEffects } from 'app/store/effects/users.effects';
import { Actions } from 'app/store/actions';
import { User } from 'app/store/user';
import { hasRole } from 'app/store/users/utils';
import { Role } from 'app/store/role';
import { Bakery } from 'app/store/bakery';
import { MasterState } from 'app/store/reducers/master-state-reducer';

interface UiState {
  isLoading: boolean;
  isBakesmart?: boolean;
  isBakery?: boolean;
  bakery?: Bakery;
}

@Component({
  selector: 'app-sign-in',
  template: require('./sign-in.component.html'),
})
export class BakesmartSignInComponent implements OnInit, OnDestroy {
  uiState$: Rx.Observable<UiState>;
  form: FormGroup;

  private signInSuccess$ = new Rx.Subject<any>();
  private signInSuccessSub: Rx.Subscription;

  constructor(
    private router: Router,
    private alertService: AlertService,
    private bakeryCustEffects: BakeryCustEffects,
    private actions$: NgRxActions,
    private store: Store<AppState>,
  ) {
  }

  ngOnInit() {
    this.uiState$ = Rx.Observable.of({isLoading: false, isBakesmart: true, isBakery: false})
    this.signInSuccessSub = this.signInSuccess$
      .subscribe(currentUser => this.router.navigate(['/bakery_mgmt/dashboard']));
  }

  ngOnDestroy() {
    this.signInSuccessSub.unsubscribe();
  }
}
