import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({name: 'cents'})
export class CentsPipe extends CurrencyPipe implements PipeTransform {
  transform(
    priceInCents: number,
    currencyCode?: string,
    display?: 'code'|'symbol'|'symbol-narrow'|string|boolean,
    digitsInfo?: string,
    locale?: string
  ) {
    if (priceInCents === null) { return ''; }
    const priceInUnits = priceInCents / 100;
    return super.transform(priceInUnits, currencyCode, 'symbol-narrow', digitsInfo, locale);
  }
}
