import {
  Component,
  Input,
  OnInit
} from '@angular/core';
import {
  FormGroup,
  Validators,
  FormBuilder
} from '@angular/forms';
import { Observable } from 'rxjs/Observable';

import { GenericControlValueInput } from 'app/shared/components/form/generic-control-value-input';
import { buildForwardRef } from 'app/shared/components/form/input-forward-ref-builder';

export interface AddressFormData {
  id?: number;
  street: string;
  unit: string;
  city: string;
  state: string;
  zip: string;
  _destroy?: boolean;
}

@Component({
  selector: 'wholesaler-org-settings-addresses-input',
  template: require('./addresses-input.component.html'),
  providers: buildForwardRef(AddressesInputComponent)
})
export class AddressesInputComponent extends GenericControlValueInput<AddressFormData[]> implements OnInit {
  @Input() countryCode$: Observable<string>;

  value: AddressFormData[] = [];
  form: FormGroup;

  constructor(
    private fb: FormBuilder
  ) {
    super();
  }

  ngOnInit() {
    this.form = this.fb.group({
      street: this.fb.control(null, [Validators.required]),
      unit: this.fb.control(null, []),
      city: this.fb.control(null, [Validators.required]),
      state: this.fb.control(null, [Validators.required]),
      zip: this.fb.control(null, [Validators.required]),
    });
  }

  get liveAddresses(): AddressFormData[] {
    return this.value
      .filter(address => !address._destroy);
  }

  onSubmitForm() {
    if (!this.form.valid) return;

    this.value = [
      ...this.value,
      this.form.value
    ];

    this.form.reset();
    this.onChangedCallback(this.value);
  }

  onClickRemove(addressIndex: number) {
    const replacement = [];

    const targetAddress = this.liveAddresses[addressIndex];

    if (!!targetAddress.id) {
      replacement.push({
        ...targetAddress,
        _destroy: true
      });
    }

    this.value = [
      ...this.value.slice(0, addressIndex),
      ...replacement,
      ...this.value.slice(addressIndex + 1)
    ];

    this.onChangedCallback(this.value);
  }

  writeValue(val: AddressFormData[]) {
    this.value = val;
  }
}
