import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { Actions } from 'app/store/actions';
import { NOT_ASKED, LOADING, fromSuccess, fromError } from 'app/remote-data';
import { RemoteData } from 'app/remote-data';
import { CheckoutBakeryOrderForm } from 'app/bakery-mgmt/new-order/checkout/checkout-bakery-order-form';

export interface State {
  isLoading: boolean;
  bakeryOrder?: CheckoutBakeryOrderForm;
  taxes: RemoteData<number>;
}

export const initialState: State = {
  isLoading: true,
  bakeryOrder: null,
  taxes: NOT_ASKED,
};

export function reducer(state: State = initialState, action: UnsafeAction): State {
  switch (action.type) {
    case Actions.SET_EMPLOYEE_BAKERY_ORDER:
       return {
         ...state,
         isLoading: false,
         bakeryOrder: {
           ...state.bakeryOrder,
           order_items: action.payload.cart.order_items,
           total_price: action.payload.cart.total_price,
           total_discount_price: action.payload.cart.total_discount_price,
           payment_card_id: null,
           payment_card_selection: null,
           form_fulfillment_data: {
             fulfillment_type: null,
             fulfillment_date: null,
             fulfillment_at: null,
             fulfillment_location_id: null,
             fulfillment_location_type: null,
             bakeryLocations: action.payload.currentBakery.bakery_locations,
             fulfillment_address: {
               street: null,
               unit: null,
               city: null,
               state: null,
               zip: null,
             },
           }
         },
         taxes: NOT_ASKED,
       };

    case Actions.UPDATE_EMPLOYEE_BAKERY_ORDER:
      return {
        ...state,
        bakeryOrder: {
          ...state.bakeryOrder,
          ...action.payload,
        },
      };

    case Actions.REQUEST_TAXES_FOR_BAKERY:
      return {
        ...state,
        taxes: LOADING
      };

    case Actions.REQUEST_TAXES_FOR_BAKERY_SUCCESS:
      return {
        ...state,
        taxes: fromSuccess(action.payload)
      };

    case Actions.REQUEST_TAXES_FOR_BAKERY_ERROR:
      return {
        ...state,
        taxes: fromError(action.payload)
      };

    case Actions.NEW_ORDER_STARTED:
      // Prolly `Actions.REQUEST_CREATE_EMPLOYEE_BAKERY_ORDER_SUCCESS:`
      // would be a better choice to reset but some selectors explode
      // because they are still listening while submitting the order.
      return initialState;

    default:
      return state;
  }
}
