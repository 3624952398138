import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'fulfillmentTypeName'})
export class FulfillmentTypeNamePipe implements PipeTransform {
  public transform(fulfillmentType: string) {
    let result = '???';

    if (fulfillmentType == null || typeof fulfillmentType.trim !== 'function') {
      return result;
    }

    switch (fulfillmentType) {
      case 'delivery':
        result = 'Delivery';
        break;
      case 'pickup':
        result = 'Pickup';
        break;
      case 'shipping':
        result = 'Shipping';
        break;
    }

    return result;
  }
}
