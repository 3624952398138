import {
  Component,
  forwardRef,
  HostBinding,
  Input,
  AfterViewInit,
  OnChanges,
  ElementRef,
  ViewChild
} from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { zeroPad } from 'app/shared/string-utils';
import { Observable } from 'rxjs/Observable';

const percentageMaxLength = 3;

@Component({
  selector: 'masked-input',
  template: `
    <input #input [class]="class" [placeholder]="placeholder" (keyup)="writeValue()">
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MaskedInputComponent),
      multi: true
    }
  ]
})
export class MaskedInputComponent implements ControlValueAccessor {
  @ViewChild('input') input;
  @Input('placeholder') placeholder;
  @Input('maskType') maskType;
  @Input('class') class;
  @Input() _inputValue = null;

  get counterValue() {
    return this._inputValue;
  }

  set counterValue(val) {
    this._inputValue = val;
    this.updateNativeElementAndPropagateChange();
  }

  writeValue(val?: any) {
    const newVal = val || this.input.nativeElement.value;
    if (val === null) {
      this._inputValue = null;
    } else if (newVal !== undefined) {
      this._inputValue = parseInt(String(newVal).replace(/\D/g, ''), 10);
      if (isNaN(this._inputValue)) {
        this._inputValue = null;
      }
    } else {
      this._inputValue = null;
    }
    this.updateNativeElementAndPropagateChange();
  }

  propagateChange = (_: any) => {};

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  updateNativeElementAndPropagateChange() {
    if (this._inputValue === null) {
      this.input.nativeElement.value = '';
      return;
    }
    const numberArray = String(this._inputValue).split('');
    if (this.maskType === 'currency') {
      this.propagateChange(this._inputValue);
      const [units, cents] = [
        numberArray.slice(0, numberArray.length - 2).join(''),
        numberArray.slice(numberArray.length - 2, numberArray.length).join('')
      ];
      this.input.nativeElement.value = `$${units || 0}.${
        cents.length === 1 ? 0 + cents : cents
      }`;
    } else if (this.maskType === 'percentage') {
      const sliceStart = numberArray.length - percentageMaxLength > 0
        ? numberArray.length - percentageMaxLength
        : 0;
      let percentage = numberArray
        .slice(sliceStart, numberArray.length)
        .join('');

      if (parseInt(percentage) > 100) {
        percentage = '100';
      }
      this.input.nativeElement.value = `${percentage}%`;
      this.propagateChange(percentage);
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.input.nativeElement.disabled = isDisabled;
  }

  registerOnTouched() {}
}
