/*
  TODO: The wholesaler customer and bkry mgmt wholesaler invoices are pretty
    redundant at this point. If they continue to share much of their
    presentation and data retrieval logic, they probably should be consolidated
    into a single component.
*/

import {
  Component,
  Input,
  Output,
  ChangeDetectionStrategy,
  OnInit
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AppState } from 'app/store/app-state';
import * as selectors from 'app/store/selectors';
import { WholesalerOrg } from 'app/store/wholesaler-org';
import { User } from 'app/store/user';
import { GetAction } from 'app/store/actions/request.actions';
import { BakeryOrderInvoice } from 'app/store/bakery-order-invoice';
import { bakeryOrderInvoiceSchema } from 'app/store/schema/default-schemata';
import { cloneSort } from 'app/store/entity-utils';

const opKey = 'wholesalerInvoices',
      schema = [bakeryOrderInvoiceSchema];

@Component({
  template: `
    <h3>Invoices ({{ invoicesCount$ | async }})</h3>
    <bkry-mgmt-wholesaler-invoices-card
      [isLoading]="isLoading$ | async"
      [invoices]="invoices$ | async"
      [showCreateLink]="false"
      detailsLink="/store/wholesaler/invoices/"
    ></bkry-mgmt-wholesaler-invoices-card>
  `,
})
export class InvoicesComponent implements OnInit {
  isLoading$: Observable<boolean> = this.store
    .select(selectors.getRequestIsLoading(opKey));

  invoices$: Observable<BakeryOrderInvoice[]> = this.store
    .select(selectors.getRequestResult(opKey, schema))
    .filter(val => !!val)
    .startWith([]);

  invoicesCount$: Observable<number> = this.invoices$
    .map((invoices: BakeryOrderInvoice[]) => invoices.length)
    .startWith(0);

  wholesalerOrg$: Observable<WholesalerOrg> = this.store
    .select(selectors.getCurrentUser)
    .filter(val => !!val)
    .map((user: User) => user.wholesaler_org);

  constructor(
    private store: Store<AppState>,
  ) { }

  ngOnInit() {
    this.wholesalerOrg$
      .filter(val => !!val)
      .take(1)
      .subscribe((wholesalerOrg: WholesalerOrg) => {
          const actionPayload = {
            url: `/api/invoices`,
            opKey: opKey,
            schema: schema,
            queryParams: {
              parent_type: 'WholesalerOrg',
              parent_id: wholesalerOrg.id,
            }
          };
          this.store.dispatch(new GetAction(actionPayload));
      });
  }
}
