import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { Store } from '@ngrx/store';
import { Angular2TokenService } from 'app/angular2-token/angular2-token.service';
import { Actions as NgRxActions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';
import { normalize, schema } from 'normalizr';

import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { AppState } from 'app/store/app-state';
import { Actions } from 'app/store/actions';
import { BakeryMgmtEffects } from 'app/store/effects/bakery-mgmt.effects';
import { Bakery } from 'app/store/bakery';
import { User } from 'app/store/user';
import { hasRole } from 'app/store/users/utils';
import { sortUsersByRoleName } from 'app/store/users/sort';
import { BakeryMgmtUiState } from 'app/store/bakery-mgmt-ui-state';
import { EntitiesState } from 'app/store/entities-state';

const roleSchema = new schema.Entity('roles');
const userSchema = new schema.Entity('users', {
  roles: [roleSchema],
});

export interface UiState {
  isLoading: boolean;
  archivedStatusFilter: boolean;
  employees?: User[];
  employeesCount: number;
}

export interface DetailUiState {
  isLoading: boolean;
  user?: User;
}

@Injectable()
export class BakeryMgmtEmployeesEffects {
  uiStates$: Observable<UiState>;
  detailUiStates$: Observable<DetailUiState>;

  constructor(
    private actions$: NgRxActions,
    private tokenService: Angular2TokenService,
    private store: Store<AppState>,
    private bakeryMgmtEffects: BakeryMgmtEffects,
  ) {
    this.uiStates$ = Observable
      .combineLatest(
        this.store.select('bakeryMgmtUiState'),
        this.store.select('entitiesState'),
        (uiState, entities) => ({uiState, entities})
      )
      .map((combined) => {
        const bakeryMgmtUiState = <BakeryMgmtUiState> combined.uiState;
        const archivedStatusFilter = bakeryMgmtUiState.employeesUiState.archivedStatusFilter;
        const serachFilter = bakeryMgmtUiState.employeesUiState.searchFilter;
        const entitiesState = <EntitiesState> combined.entities;

        if (bakeryMgmtUiState.employeesUiState.isEmployeesLoading) {
          return {
            isLoading: true,
            archivedStatusFilter,
            employeesCount: 0,
          };
        }

        let employees = Object.keys(entitiesState.users)
          .map(key => entitiesState.users[key])
          .filter(user => user.archive_status === archivedStatusFilter);

        if (serachFilter.trim().length >= 2) {
          const normalizedFilterText = serachFilter.toLowerCase();

          employees = employees.filter(user => {
            const normalizedUserName = user.name.toLowerCase(),
                  normalizedUserEmail = user.email.toLowerCase();

            return normalizedUserName.includes(normalizedFilterText) || normalizedUserEmail.includes(normalizedFilterText);
          });
        }

        employees = employees
          .map(user => {
            user = Object.assign({}, user);

            user.roles = user.roles.map(roleId => entitiesState.roles[roleId]);
            user.primary_role = user.roles[0];

            return user;
          });

        employees = employees
          .filter(user => {
            return hasRole(user, 'bakery_owner', 'bakery_manager', 'bakery_employee')
          });

        // Sort by role, name.
        employees.sort(sortUsersByRoleName);

        return {
          isLoading: false,
          archivedStatusFilter: archivedStatusFilter,
          employees: employees,
          employeesCount: employees.length,
        }
      })
      .shareReplay(1);

    this.detailUiStates$ = Observable
      .combineLatest(
        this.store.select('bakeryMgmtUiState'),
        this.store.select('entitiesState'),
        (uiState, entities) => ({uiState, entities})
      )
      .map((combined) => {
        const employeesUiState = (<BakeryMgmtUiState> combined.uiState).employeesUiState;
        const entitiesState = <EntitiesState> combined.entities;

        if (
          employeesUiState.isEmployeeLoading ||
          employeesUiState.detailId == null ||
          entitiesState.users[employeesUiState.detailId] == null
        ) {
          return {
            isLoading: true,
          };
        }

        const user = Object.assign({}, entitiesState.users[employeesUiState.detailId]);

        user.roles = (<number[]>user.roles).map(roleId => entitiesState.roles[roleId]);
        user.primary_role = user.roles[0];

        return {
          isLoading: false,
          user: user,
        }
      })
      .shareReplay(1);
  }

  requestGetEmployees() {
    this.store.dispatch({
      type: Actions.REQUEST_BAKERY_EMPLOYEES,
    });
  }

  requestGetEmployee(id: number) {
    this.store.dispatch({
      type: Actions.REQUEST_BAKERY_EMPLOYEE,
      payload: id,
    });
  }

  requestBakeryInviteEmployee(employeeInviteDetails) {
    this.store.dispatch({
      type: Actions.REQUEST_BAKERY_INVITE_EMPLOYEE,
      payload: employeeInviteDetails,
    });
  }

  changeArchiveFilter(newArchivedFilterValue: boolean) {
    this.store.dispatch({
      type: Actions.CHANGE_BAKERY_EMPLOYEES_ARCHIVE_FILTER,
      payload: newArchivedFilterValue,
    });
  }

  changeUserFilter(filterText: string) {
    this.store.dispatch({
      type: Actions.CHANGE_BAKERY_EMPLOYEES_SEARCH_FILTER,
      payload: filterText,
    });
  }

  requestEmployeeDetailsUpdate(user: User, detailsPayload: any) {
    this.store.dispatch({
      type: Actions.REQUEST_EMPLOYEE_DETAILS_UPDATE,
      payload: {
        userId: user.id,
        updateValues: detailsPayload,
      },
    });
  }

  @Effect() getBakeryEmployees$ = this.actions$
    .ofType(Actions.REQUEST_BAKERY_EMPLOYEES)
    .switchMap((action: UnsafeAction) => {
      return this.bakeryMgmtEffects.currentBakery$
        .filter(bakery => bakery != null)
        .map(bakery => ({action, bakery}))
        .take(1)
    })
    .switchMap(combined => {
      const action = <UnsafeAction> combined.action;
      const bakery = <Bakery> combined.bakery;

      return this.tokenService.get(`/api/bakeries/${bakery.id}/employees`)
        .map((response: Response) => response.json())
        .map((data: any[]) => {
          const results = normalize(data, [userSchema]);

          return results.entities;
        })
        .switchMap((entities) => {
          return Observable.from([
            {
              type: Actions.LOADED_ENTITIES,
              payload: entities,
            },
            {
              type: Actions.REQUEST_BAKERY_EMPLOYEES_SUCCESS,
            },
          ]);
        })
    })
    .share();

  @Effect() getBakeryEmployee$ = this.actions$
    .ofType(Actions.REQUEST_BAKERY_EMPLOYEE)
    .switchMap((action: UnsafeAction) => {
      return this.tokenService.get(`/api/users/${action.payload}`)
        .map((response: Response) => response.json())
        .map((data: any[]) => {
          const results = normalize(data, userSchema);

          return results.entities;
        })
        .switchMap((entities) => {
          return Observable.from([
            {
              type: Actions.LOADED_ENTITIES,
              payload: entities,
            },
            {
              type: Actions.REQUEST_BAKERY_EMPLOYEE_SUCCESS,
            },
          ]);
        })
    })
    .share();

    @Effect() requestBakeryInviteEmployeeEffects$ = this.actions$
      .ofType(Actions.REQUEST_BAKERY_INVITE_EMPLOYEE)
      .switchMap((action: UnsafeAction) => {
        return this.bakeryMgmtEffects.currentBakery$
          .filter(bakery => bakery != null)
          .map(bakery => ({action, bakery}))
          .take(1)
      })
      .switchMap(combined => {
        const action = <UnsafeAction> combined.action;
        const bakery = <Bakery> combined.bakery;

        return this.tokenService
          .post(
            `/api/bakeries/${bakery.id}/employees/invites`,
            JSON.stringify(action.payload)
          )
          .map((response: Response) => response.json())
          .map((data: any[]) => {
            const results = normalize(data, userSchema);

            return results.entities;
          })
          .switchMap((entities) => {
            return Observable.from([
              {
                type: Actions.LOADED_ENTITIES,
                payload: entities,
              },
              {
                type: Actions.REQUEST_BAKERY_INVITE_EMPLOYEE_SUCCESS,
              },
            ]);
          })
          .catch((error) => {
            const errorPayload = error.json();

            return Observable.of({
              type: Actions.REQUEST_BAKERY_INVITE_EMPLOYEE_ERROR,
              payload: errorPayload,
            });
          });
      })
      .share();

    @Effect() requestEmployeeDetailsUpdateEffects$ = this.actions$
      .ofType(Actions.REQUEST_EMPLOYEE_DETAILS_UPDATE)
      .switchMap((action: UnsafeAction) => {
        return this.bakeryMgmtEffects.currentBakery$
          .filter(bakery => bakery != null)
          .map(bakery => ({action, bakery}))
          .take(1)
      })
      .switchMap(combined => {
        const action = <UnsafeAction> combined.action;
        const bakery = <Bakery> combined.bakery;

        return this.tokenService
          .put(
            `/api/bakeries/${bakery.id}/employees/${action.payload.userId}`,
            JSON.stringify(action.payload.updateValues)
          )
          .map((response: Response) => response.json())
          .map((data: any[]) => {
            const results = normalize(data, userSchema);

            return results.entities;
          })
          .switchMap((entities) => {
            return Observable.from([
              {
                type: Actions.LOADED_ENTITIES,
                payload: entities,
              },
              {
                type: Actions.REQUEST_EMPLOYEE_DETAILS_UPDATE_SUCCESS,
              },
            ]);
          })
          .catch((error) => {
            const errorPayload = typeof error.json === 'function' ? error.json() : error;

            return Observable.of({
              type: Actions.REQUEST_EMPLOYEE_DETAILS_UPDATE_ERROR,
              payload: errorPayload,
            });
          });
      })
      .share();
}
