import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

const compSelector = 'bkry-mgmt-order-details-order-details';

@Component({
  selector: compSelector,
  template: require('./order-details.component.html'),
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderDetailsComponent {
  @Input() bakeryOrder: any;

  get customer(): any {
    return this.bakeryOrder.parent;
  }

  get isPickup(): boolean {
    return this.bakeryOrder.fulfillment_type === 'pickup';
  }

  get editOrderLink(): string {
    return `/bakery_mgmt/order_mgmt/orders/${this.bakeryOrder.id}/edit`;
  }
}
