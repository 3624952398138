import { Component, Input } from '@angular/core';

import { BakeryOrder } from 'app/store/bakery-order';
import iconDollar from 'angular/assets/images/icons/icons-functional/icon-dollar.svg';

@Component({
  selector: 'order-balance',
  template: `
    <div class="card card--md">
      <div class="chip__container margin-reset--bottom">
        <div class="chip__avatar green-base">
          <i
            aria-label="Dollar Icon"
            class="chip__avatar-text chip__avatar-icon"
            [inlineSVG]="iconDollar"
          ></i>
        </div>
        <div class="chip__text-container">
          <span class="chip__text-title">Balance:</span>
          <span class="chip__text-regular">{{ balance | centsObservable | async }}</span>
        </div>
      </div>
    </div>
  `
})
export class OrderBalanceComponent {
  iconDollar = iconDollar;
  @Input() bakeryOrder: BakeryOrder;

  get balance(): number {
    return this.bakeryOrder.invoice
      ? this.bakeryOrder.invoice.balance
      : this.bakeryOrder.total_price;
  }
}
