import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Actions as NgRxActions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { AlertService } from 'app/shared/components/alerts/alert.service';
import { AppState } from 'app/store/app-state';
import { Actions } from 'app/store/actions';
import { BakeryMgmtProductAttributesEffects } from 'app/store/effects/bakery-mgmt/bakery-mgmt-bakery-product-attributes.effects';
import { BakeryMgmtUiState } from 'app/store/bakery-mgmt-ui-state';
import { EntitiesState } from 'app/store/entities-state';
import { BakeryAttribute } from 'app/store/bakery-attribute';
import { denormalize as denormalizeAttr } from 'app/store/bakery-product-attributes/utils';

export interface UiState {
  isLoading: boolean;
  hasBakeryAttributes: boolean;
  bakeryAttributes?: BakeryAttribute[];
  currentUserId: number;
}

@Component({
  template: require('./master.component.html'),
})
export class BakeryMgmtProductAttributesMasterComponent implements OnInit, OnDestroy {
  uiState$: Observable<UiState>;
  addSuccess$: Observable<any>;

  private filterText$ = new BehaviorSubject<string>('');
  private alertsSub: Subscription;

  constructor(
    private bakeryMgmtProductAttributesEffects: BakeryMgmtProductAttributesEffects,
    private actions$: NgRxActions,
    private store: Store<AppState>,
    private router: Router,
    private alertService: AlertService,
  ) {
    this.uiState$ = Observable
      .combineLatest(
        this.store.select('bakeryMgmtUiState'),
        this.store.select('entitiesState'),
        this.filterText$,
        this.store.map(x => x.authState.currentUser.id).distinctUntilChanged()
      )
      .map(([bakeryMgmtUiState, entitiesState, filterText, currentUserId]) => {
        if (bakeryMgmtUiState.bakeryAttributesUiState.isMasterLoading) {
          return {
            isLoading: true,
            hasBakeryAttributes: false,
            bakeryAttributes: [],
            currentUserId,
          };
        }

        let bakeryAttributes = Object.keys(entitiesState.bakery_attributes)
          .map(key => denormalizeAttr(entitiesState.bakery_attributes[key], entitiesState));

        if (filterText.length >= 2) {
          bakeryAttributes = bakeryAttributes.filter(prodTem => {
            return prodTem.name.toLowerCase().includes(filterText.toLowerCase());
          });
        }

        return {
          isLoading: false,
          hasBakeryAttributes: bakeryAttributes.length > 0,
          bakeryAttributes: bakeryAttributes,
          currentUserId,
        }
      })
      .shareReplay(1);

    this.alertsSub = this.actions$
      .subscribe((action: UnsafeAction) => {
        let messageType = null,
            messageContent = null;

        switch (action.type) {
          case Actions.REQUEST_DELETE_BAKERY_ATTRIBUTE_SUCCESS:
            messageType = 'success';
            messageContent = `The attribute has been deleted.`;
            break;
          case Actions.REQUEST_DELETE_BAKERY_ATTRIBUTE_ERROR:
            messageType = 'warning';
            messageContent = 'Something went wrong!';
            break;
        }

        if (messageType != null && messageContent != null) {
          this.alertService[messageType](messageContent);
        }
      });
  }

  ngOnInit() {
    this.bakeryMgmtProductAttributesEffects.requestGetBakeryAttributes();
  }

  ngOnDestroy() {
    this.alertsSub.unsubscribe();
  }

  onChangeFilterText(filterText: string) {
    this.filterText$.next(filterText);
  }

  onShowDetails(bakeryAttribute: BakeryAttribute) {
    this.router.navigate([
      '/bakery_mgmt/product_mgmt/attributes/',
      bakeryAttribute.id,
    ]);
  }

  onDeleteAttribute(bakeryAttribute: BakeryAttribute) {
    this.bakeryMgmtProductAttributesEffects.requestDeleteBakeryAttribute(bakeryAttribute);
  }
}
