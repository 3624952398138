import { Component, Input } from '@angular/core';

@Component({
  selector: 'alert-message',
  template: `
    <ng-content></ng-content>
  `
})
export class AlertMessageComponent {
  @Input('triggerAction') triggerAction: string;

  get data() {
    return 'Hullo';
  }
}
