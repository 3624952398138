import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as Rx from 'rxjs';
import { Actions as NgRxActions } from '@ngrx/effects';

import { AppState } from 'app/store/app-state';
import { AlertService } from 'app/shared/components/alerts/alert.service';
import { BakeryCustEffects } from 'app/store/effects/bakery-cust/bakery-cust.effects';
import { UsersEffects } from 'app/store/effects/users.effects';
import { Actions } from 'app/store/actions';
import { User } from 'app/store/user';
import { hasRole } from 'app/store/users/utils';
import { Role } from 'app/store/role';
import { Bakery } from 'app/store/bakery';
import { MasterState } from 'app/store/reducers/master-state-reducer';
import { UiState } from 'app/shared/components/reset-password/reset-password.component';

@Component({
  template: require('./reset-password.component.html'),
})
export class BakeryCustResetPasswordComponent implements OnInit, OnDestroy {
  uiState$: Rx.Observable<UiState>;

  private resetSuccess$ = new Rx.Subject<any>();
  private resetSuccessSub: Rx.Subscription;

  constructor(
    private router: Router,
    private alertService: AlertService,
    private bakeryCustEffects: BakeryCustEffects,
    private actions$: NgRxActions,
    private store: Store<AppState>,
  ) {  }

  ngOnInit() {
    this.uiState$ = Rx.Observable
      .combineLatest(
        this.store.select('masterState'),
        this.bakeryCustEffects.currentBakery$.startWith(null),
        (masterState, currentBakery) => ({masterState, currentBakery})
      )
      .map(combined => {
        const masterState = <MasterState>combined.masterState;
        const currentBakery = <Bakery>combined.currentBakery;

        if (masterState.type == null) {
          return {isLoading: true};
        }

        if (masterState.type === 'custom_bakery') {
          return {
            isLoading: currentBakery == null,
            isBakery: true,
            bakery: currentBakery,
          };
        }

        // Shouldn't reach this point...
        return {
          isLoading: true,
        };
      })

    this.resetSuccessSub = this.resetSuccess$
      .subscribe(currentUser => {
        if (hasRole(currentUser, 'bakery_customer')) {
          this.router.navigate(['/']);
        }

        // TODO: we should never reach this point. Raise error?
      });
  }

  ngOnDestroy() {
    this.resetSuccessSub.unsubscribe();
  }
}
