import { Component, Input } from '@angular/core';

import iconSort from 'angular/assets/images/icons/icons-functional/icon-sort.svg';
import iconSortAscending from 'angular/assets/images/icons/icons-functional/icon-sort-ascending.svg';
import iconSortDescending from 'angular/assets/images/icons/icons-functional/icon-sort-descending.svg';

export enum Direction {
  Asc,
  Desc,
  None
}

@Component({
  selector: 'ordering-arrow',
  template: `
    <span [ngSwitch]="direction">
      <i
        *ngSwitchCase="DirectionType.None"
        class="icon icon-size--tiny tab__sort-icon icon-color--grey-alt"
        aria-label="sort column"
        [inlineSVG]="iconSort"
      ></i>
      <i
        *ngSwitchCase="DirectionType.Asc"
        class="icon icon-size--tiny tab__sort-icon icon-color--grey-alt"
        aria-label="sort column"
        [inlineSVG]="iconSortAscending"
      ></i>
      <i
        *ngSwitchCase="DirectionType.Desc"
        class="icon icon-size--tiny tab__sort-icon icon-color--grey-alt"
        aria-label="sort column"
        [inlineSVG]="iconSortDescending"
      ></i>
    </span>
  `,
})
export class OrderingArrowComponent {
  @Input() direction: Direction;
  DirectionType = Direction;
  iconSort = iconSort;
  iconSortAscending = iconSortAscending;
  iconSortDescending = iconSortDescending;
}
