import { Component, OnInit, OnDestroy, Input, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as Rx from 'rxjs';
import { Actions as NgRxActions } from '@ngrx/effects';

import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { Actions } from 'app/store/actions';
import { AlertService } from 'app/shared/components/alerts/alert.service';
import { UsersEffects } from 'app/store/effects/users.effects';
import { Bakery } from 'app/store/bakery';

export interface UiState {
  isLoading: boolean;
  isBakesmart?: boolean;
  isBakery?: boolean;
  bakery?: Bakery;
}

@Component({
  selector: 'shared-forgot-password',
  template: require('./forgot-password.component.html'),
})
export class SharedForgotPasswordComponent implements OnInit {
  @Input('signInLink') signInLink: string;
  @Input('uiState') uiState$: Rx.Observable<UiState>;
  @Output('resetSuccess') resetSuccess$: Rx.Observable<string>;
  form: FormGroup;

  private submit$ = new Rx.Subject<any>();
  private alertsSub: Rx.Subscription;
  private submitSub: Rx.Subscription;

  constructor(
    private alertService: AlertService,
    private actions$: NgRxActions,
    private usersEffects: UsersEffects,
  ) {
    this.resetSuccess$ = this.actions$
      .ofType(Actions.REQUEST_USER_FORGOT_PASSWORD_SUCCESS)
      .map(() => this.form.value.email);
  }

  ngOnInit() {
    this.form = new FormGroup({
      'email': new FormControl(null, [Validators.required, Validators.email]),
    });

    this.alertsSub = this.actions$
      .subscribe((action: UnsafeAction) => {
        let messageType = null,
            messageContent = null;

        switch (action.type) {
          case Actions.REQUEST_USER_FORGOT_PASSWORD_SUCCESS:
            messageType = 'success';
            messageContent = `An email has been sent to ${action.payload.email} containing instructions on how to reset your password.`;
            break;

          case Actions.REQUEST_USER_FORGOT_PASSWORD_ERROR:
            messageType = 'warning';
            messageContent = `System error: Could not reset your password.`;

            if (action.payload.error === 'invalid_email_address') {
              messageContent = `The e-mail address provided does not match a user in our system. Please check your input and try again.`;
            }
            break;
        }

        if (messageType != null && messageContent != null) {
          this.alertService[messageType](messageContent);
        }
      });

    this.submitSub = this.submit$
      .withLatestFrom(
        this.uiState$,
        (_, uiState) => uiState
      )
      .subscribe(uiState => {
        const bakeryId = uiState.isBakery ? uiState.bakery.id : null;

        this.usersEffects.requestUserForgotPassword(
          this.form.value.email,
          bakeryId,
        );
      });
  }

  onSubmit() {
    this.submit$.next();
  }

  ngOnDestroy() {
    this.alertsSub.unsubscribe();
  }
}
