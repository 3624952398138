// FIXME: This and corresponding footer nav are highly redundant.

import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';

import { AppState } from 'app/store/app-state';
import { User } from 'app/store/user';
import { UsersEffects } from 'app/store/effects/users.effects';
import { BakeryCustEffects } from 'app/store/effects/bakery-cust/bakery-cust.effects';
import * as selectors from 'app/store/selectors';

@Component({
  selector: 'bakery-cust-header-nav',
  template: require('./header-nav.component.html'),
})
export class HeaderNavComponent implements OnInit, OnDestroy {
  isClassMobileMenuActive = false;

  permitEcomm$: Observable<boolean> = this.store
    .select(selectors.getCustBakeryPermitEcomm);

  isWholesalerEnabled$: Observable<boolean> = this.store
    .select(selectors.getCurrentUserWholesalerEnabled);

  private routerEventsSub: Subscription;

  constructor(
    private usersEffects: UsersEffects,
    private bakeryCustEffects: BakeryCustEffects,
    private router: Router,
    private store: Store<AppState>,
  ) { }

  ngOnInit() {
    this.routerEventsSub = this.router.events.subscribe((val) => {
      if (this.isClassMobileMenuActive) {
        this.isClassMobileMenuActive = !this.isClassMobileMenuActive
      }
    });
  }

  ngOnDestroy() {
    this.routerEventsSub.unsubscribe();
  }

  get customerOverview$() {
    return this.bakeryCustEffects.customerOverview$;
  }

  onClickMobileMenuActive() {
  	this.isClassMobileMenuActive = !this.isClassMobileMenuActive
  }

  onClickSignOut() {
    this.usersEffects.requestUserSignOut();
  }

  onClickWholesalerAccount() {
    this.router.navigate(['/store/wholesaler']);
  }

  onClickAccountSettings() {
    this.router.navigate(['/store/account']);
  }

  onClickOrderHistory() {
    this.router.navigate(['/store/order_history']);
  }
}
