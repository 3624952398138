import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { Store } from '@ngrx/store';
import { AlertService } from 'app/shared/components/alerts/alert.service';
import { AppState } from 'app/store/app-state';
import { EntitiesState } from 'app/store/entities-state';
import { Actions } from 'app/store/actions';
import { Bakery } from 'app/store/bakery';
import { BakeryMgmtEffects } from 'app/store/effects/bakery-mgmt.effects';
import { BakeryMgmtSubscriptionInvoicesEffects } from 'app/store/effects/bakery-mgmt';
import { BakerySubscriptionInvoice } from 'app/store/bakery-subscription-invoice';

interface UiState {
  isLoading: boolean;
  bakery?: Bakery;
  bakerySubscriptionInvoice?: BakerySubscriptionInvoice;
  invoicePayment?: object;
}

@Component({
  selector: 'app-subscription-invoice',
  template: require('./invoice.component.html'),
})
export class SubscriptionInvoiceComponent implements OnInit, OnDestroy {
  private sub: any;
  invoice_id: number;
  back: object;
  bakery$: Observable<UiState>;
  uiState: UiState;

  private valuesSub: Subscription;

  constructor(
    private bakeryMgmtSubscriptionInvoicesEffects: BakeryMgmtSubscriptionInvoicesEffects,
    private route: ActivatedRoute,
    private router: Router,
    private bkryMgmtEffects: BakeryMgmtEffects,
    private store: Store<AppState>,
  ) {
    this.back = {
      link: '/bakery_mgmt/account_settings',
      text: 'Back To Account Settings'
    }

    this.bakery$ = Observable.combineLatest(
      this.bkryMgmtEffects.currentBakery$,
      this.store.select('entitiesState'),
      (bakery, entitiesState) => ({bakery, entitiesState})
    ).map((combined): UiState => {
      const bakery = <Bakery>combined.bakery;
      const entitiesState = <EntitiesState>combined.entitiesState;

      if (bakery == null || this.invoice_id == null) {
        return {
          isLoading: true,
        };
      }

      const bakerySubscriptionInvoice = entitiesState.bakery_subscription_invoices[this.invoice_id];
      const invoicePayment = bakerySubscriptionInvoice.bakery_subscription_invoice_payment;
      return {
        isLoading: false,
        bakery,
        bakerySubscriptionInvoice,
        invoicePayment
      };
    })
    .startWith({isLoading: true})
    .shareReplay(1)

    this.valuesSub = this.bakery$.subscribe((uiState: UiState) => this.uiState = uiState);

  }


  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.invoice_id = params['invoice_id'];
      this.bakeryMgmtSubscriptionInvoicesEffects.requestGetSubscriptionInvoice(this.invoice_id);
      this.bkryMgmtEffects.requestGetOwnBakery();
    });



    this.bakeryMgmtSubscriptionInvoicesEffects.getSubscriptionInvoiceEffects$
    .filter(action => {
      return (
        action.type === Actions.REQUEST_GET_BAKERY_SUBSCRIPTION_INVOICE_ERROR
      );
    })
    .forEach(() => {
      this.router.navigate(['/404']);
    })
  }

  ngOnDestroy() {
    if (this.valuesSub != null) { this.valuesSub.unsubscribe(); }
    this.sub.unsubscribe();
  }

}
