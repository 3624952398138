import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { Store } from '@ngrx/store';
import { Angular2TokenService } from 'app/angular2-token/angular2-token.service';
import { Actions as NgRxActions, Effect } from '@ngrx/effects';
import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { AppState } from 'app/store/app-state';
import { Actions } from 'app/store/actions';
import { PromoCode } from 'app/store/promo-code';
import {
  basicRequestHandler,
  addEntityRequestHandler
} from 'app/store/effects/helpers';
import { promoCodeSchema } from 'app/store/schema/default-schemata';
import { EntitiesState } from 'app/store/entities-state';
import { Observable } from 'rxjs/Observable';
import { PromoCodesUiState } from 'app/store/bakery-mgmt-ui-state';
import { entitiesArray, shallowClone } from 'app/store/entities-state-utils';

export interface UiState {
  isLoading: boolean;
  statusFilter?: string;
  promoCodes?: PromoCode[];
}

@Injectable()
export class BakeryMgmtPromoCodesEffects {
  uiState$: Observable<UiState>;

  constructor(
    private actions$: NgRxActions,
    private tokenService: Angular2TokenService,
    private store: Store<AppState>
  ) {
    this.uiState$ = Observable.combineLatest(
      this.store.select('bakeryMgmtUiState', 'promoCodesUiState'),
      this.store.select('entitiesState'),
      (promoCodesUiState, entitiesState) => ({
        promoCodesUiState,
        entitiesState
      })
    )
      .map(combined => {
        const promoCodesUiState = <PromoCodesUiState>combined.promoCodesUiState;
        const entitiesState = <EntitiesState>combined.entitiesState;

        const isLoading =
          promoCodesUiState.isLoading == null ||
          promoCodesUiState.isLoading === true;

        // Still loading? Quit early.
        if (isLoading) {
          return {
            isLoading: true,
            promoCodes: [],
            statusFilter: 'active'
          };
        }

        const codes = entitiesArray('promo_codes', entitiesState).map(
          shallowClone
        );

        function filterByCode(
          promoCode: PromoCode,
          filterText: string
        ): boolean {
          const normalizedCode = promoCode.code.toLowerCase(),
            normalizedFilterText = filterText.toLowerCase();

          return normalizedCode.includes(normalizedFilterText);
        }

        const textFilterFunc = (promoCode): boolean =>
          filterByCode(promoCode, promoCodesUiState.filterText);

        const promoCodes = codes
          .filter(
            promoCode =>
              promoCode.status ===
              promoCodesUiState.statusFilter
          )
          .filter(textFilterFunc);
        return {
          isLoading: false,
          promoCodes: promoCodes,
          statusFilter:
            promoCodesUiState.statusFilter
        };
      })
      .startWith({ isLoading: true })
      .shareReplay(1);
  }

  requestGetPromoCodes(code?: string, bakery_id?: number) {
    this.store.dispatch({
      type: Actions.REQUEST_GET_PROMO_CODES,
      payload: {
        code,
        bakery_id
      }
    });
  }

  requestGetPromoCode(id: number) {
    this.store.dispatch({
      type: Actions.REQUEST_GET_PROMO_CODE,
      payload: id
    });
  }

  requestDeletePromoCode(id: number) {
    this.store.dispatch({
      type: Actions.REQUEST_DELETE_PROMO_CODE,
      payload: id
    });
  }

  requestCreatePromoCode(promoCodeDetails) {
    this.store.dispatch({
      type: Actions.REQUEST_CREATE_PROMO_CODE,
      payload: promoCodeDetails
    });
  }

  requestUpdatePromoCode(promoCodeDetails) {
    this.store.dispatch({
      type: Actions.REQUEST_UPDATE_PROMO_CODE,
      payload: promoCodeDetails
    });
  }

  changeStatusFilter(newStatusFilterValue: string) {
    this.store.dispatch({
      type: Actions.CHANGE_PROMO_CODES_STATUS_FILTER,
      payload: newStatusFilterValue
    });
  }

  changePromoCodeFilter(filterText: string) {
    this.store.dispatch({
      type: Actions.CHANGE_PROMO_CODES_SEARCH_FILTER,
      payload: filterText
    });
  }

  @Effect()
  getPromoCodes$ = this.actions$
    .ofType(Actions.REQUEST_GET_PROMO_CODES)
    .switchMap((action: UnsafeAction) => {
      return addEntityRequestHandler(
        this.tokenService.get(`/api/promo_codes`, {
          params: action.payload
        }),
        [promoCodeSchema],
        Actions.REQUEST_GET_PROMO_CODES
      );
    })
    .share();

  @Effect()
  getPromoCode$ = this.actions$
    .ofType(Actions.REQUEST_GET_PROMO_CODE)
    .switchMap((action: UnsafeAction) => {
      return addEntityRequestHandler(
        this.tokenService.get(`/api/promo_codes/${action.payload}`),
        promoCodeSchema,
        Actions.REQUEST_GET_PROMO_CODE
      );
    })
    .share();


  @Effect()
  deletePromoCode$ = this.actions$
    .ofType(Actions.REQUEST_DELETE_PROMO_CODE)
    .switchMap((action: UnsafeAction) => {
      return this.tokenService.delete(
        `/api/promo_codes/${action.payload}`
      ).map((response: Response) => response.json())
      .switchMap(() => {
        return Observable.from([
          {
            type: Actions.REMOVE_ENTITY,
            payload: {
              typeKey: 'promo_codes',
              entityKey: action.payload,
            },
          },
          {
            type: Actions.REQUEST_DELETE_PROMO_CODE_SUCCESS,
          },
        ])
        .catch((error) => {
          const errorPayload = typeof error.json === 'function' ? error.json() : error;
          return Observable.of({
            type: Actions.REQUEST_DELETE_PROMO_CODE_ERROR,
            payload: error,
          });
        });
    })
    .share();
  });

  @Effect()
  createPromoCode$ = this.actions$
    .ofType(Actions.REQUEST_CREATE_PROMO_CODE)
    .switchMap((action: UnsafeAction) => {
      return addEntityRequestHandler(
        this.tokenService.post(
          `/api/promo_codes`,
          JSON.stringify(action.payload)
        ),
        promoCodeSchema,
        Actions.REQUEST_CREATE_PROMO_CODE
      );
    })
    .share();

  @Effect()
  updatePromoCode$ = this.actions$
    .ofType(Actions.REQUEST_UPDATE_PROMO_CODE)
    .switchMap((action: UnsafeAction) => {
      return addEntityRequestHandler(
        this.tokenService.put(
          `/api/promo_codes/${action.payload.id}`,
          JSON.stringify(action.payload)
        ),
        promoCodeSchema,
        Actions.REQUEST_UPDATE_PROMO_CODE
      );
    })
    .share();
}
