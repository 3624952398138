import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { Store } from '@ngrx/store';
import { Angular2TokenService } from 'app/angular2-token/angular2-token.service';
import { Actions as NgRxActions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { normalize, schema } from 'normalizr';

import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { AppState } from 'app/store/app-state';
import { Actions } from 'app/store/actions';
import * as selectors from 'app/store/selectors';
import { AlertAction } from 'app/shared/components/alerts/actions';

import {
  GetAction,
  RequestSuccessAction,
  PutAction,
  PostAction
} from 'app/store/actions/request.actions';
import { shallowPruneEmpty } from 'app/store/effects/params-helper';

function addToArray(array: any[], value: any|any[]) {
  if (value == null) return array;

  if (Array.isArray(value)) {
    return [
      ...array,
      ...value
    ];
  }

  return [
    ...array,
    value
  ];
}

function generalizedRequestHandler(
  requestObservable: Observable<any>,
  action: GetAction|PutAction
): Observable<any> {
  return requestObservable
    .switchMap((response: Response) => {
      return Observable.of(response)
        .map((res: Response) => res.json())
        .map((data: any[]) => {
          return normalize(data, action.payload.schema);
        })
        .switchMap((results) => {
          let actions: UnsafeAction[] = [
            {
              type: Actions.LOADED_ENTITIES,
              payload: results.entities,
            },
            new RequestSuccessAction({
              opKey: action.payload.opKey,
              schema: action.payload.schema,
              result: results.result
            })
          ];

          actions = addToArray(actions, action.payload.successChainAction);

          return Observable.from(actions);
        })
        .catch((error) => {
          const message = (error.json && error.json().error) || 'Something went wrong';
          return Observable.of(AlertAction.buildWarning(message));
        });
    });
}

@Injectable()
export class RequestEffects {
  constructor(
    private actions$: NgRxActions,
    private tokenService: Angular2TokenService,
    private store: Store<AppState>,
  ) { }

  @Effect() getEffect$ = this.actions$
    .ofType(GetAction.type)
    .mergeMap((action: GetAction) => {
      const prunedQueryParams = shallowPruneEmpty(action.payload.queryParams);

      return generalizedRequestHandler(
        this.tokenService
          .get(
            action.payload.url,
            {
              params: prunedQueryParams
            }
          ),
        action
      );
    })
    .share();

  @Effect() putEffect$ = this.actions$
    .ofType(PutAction.type)
    .switchMap((action: PutAction) => {
      return generalizedRequestHandler(
        this.tokenService
          .put(
            action.payload.url,
            JSON.stringify(action.payload.body)
          ),
        action
      );
    })
    .share();

  @Effect() postEffect$ = this.actions$
    .ofType(PostAction.type)
    .switchMap((action: PutAction) => {
      return generalizedRequestHandler(
        this.tokenService
          .post(
            action.payload.url,
            JSON.stringify(action.payload.body)
          ),
        action
      );
    })
    .share();
}
