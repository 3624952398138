import { Injectable } from '@angular/core';
import { Angular2TokenService } from 'app/angular2-token/angular2-token.service';
import { Actions as NgRxActions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';
import { Response } from '@angular/http';
import { Store } from '@ngrx/store';
import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { AppState } from 'app/store/app-state';
import { LOADED_ENTITIES, } from 'app/store/reducers/entities-state-reducer';
import { Actions } from '../actions';
import { normalize, schema } from 'normalizr';


const roleSchema = new schema.Entity('roles');
const userSchema = new schema.Entity('users', {
  roles: [roleSchema],
});
const bakeryLocationSchema = new schema.Entity('bakery_locations');
const bakerySchema = new schema.Entity('bakeries', {
  owner: userSchema,
  bakery_locations: [bakeryLocationSchema],
});

@Injectable()
export class AdminCustomersEffects {
  constructor(
    private actions$: NgRxActions,
    private tokenService: Angular2TokenService,
    private store: Store<AppState>,
  ) { }

  requestBakeryInvite(bakeryDetails) {
    this.store.dispatch({
      type: Actions.REQUEST_INVITE_BAKERY,
      payload: bakeryDetails,
    });
  }

  @Effect() requestAddBakery$ = this.actions$
    .ofType(Actions.REQUEST_INVITE_BAKERY)
    .switchMap((action: UnsafeAction) => {
      return this.tokenService.post(
        `/api/bakeries/invite`,
        JSON.stringify(action.payload)
      )
        .map((response: Response) => response.json())
        .map((data: any[]) => {
          const arrayResults = normalize(data, bakerySchema);

          return arrayResults.entities;
        })
        .switchMap((entities) => {
          return Observable.from([
            {
              type: LOADED_ENTITIES,
              payload: entities,
            },
            {
              type: Actions.REQUEST_INVITE_BAKERY_SUCCESS,
              payload: {
                ownerEmail: action.payload.owner_email,
              },
            },
          ]);
        })
        .catch((error) => {
          const errorPayload = error.json();

          return Observable.of({
            type: Actions.REQUEST_INVITE_BAKERY_ERROR,
            payload: errorPayload,
          });
        });
    })
    .share();
}
