import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs/Observable';

import { passwordMatchValidatorBuilder } from 'app/shared/forms/password-match-validator';
import { validateNoWhitespaces } from 'app/shared/forms/no-whitespaces-validator';

@Component({
  selector: 'account-settings-edit-password',
  template: `
    <form
      [formGroup]="form"
      (ngSubmit)="onSubmitForm()"
    >
      <div class="row">
        <div class="columns-6__l columns-4__s">
          <label>Update Password</label>
          <input
            type="password"
            placeholder="••••••••"
            formControlName="password"
          />
        </div>
        <div class="columns-6__l columns-4__s">
          <label>Confirm Password</label>
          <input
            type="password"
            placeholder="••••••••"
            formControlName="confirm"
          />
        </div>
      </div>
      <div class="row">
        <div class="columns-9__m columns-12__l margin-bottom--delta float--right">
          <a
            (click)="onClickResetForm()"
            class="link--default margin-right--delta"
            [ngClass]="{'link--disabled': !form.dirty, 'clickable': form.dirty}"
          >
            Cancel
          </a>
          <button
            type="submit"
            class="button--primary button--input label--offset"
            [ngClass]="{'button--disabled': !form.valid || !form.dirty}"
            [disabled]="!form.valid || !form.dirty"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  `
})
export class AccountSettingsEditPasswordComponent implements OnInit {
  @Input('success$') success$: Observable<any>;
  @Output('submitForm') submitForm = new EventEmitter<string>();
  form: FormGroup;

  constructor() { }

  ngOnInit() {
    this.form = new FormGroup({
      'password': new FormControl(null, [Validators.required, Validators.minLength(8), validateNoWhitespaces]),
      'confirm': new FormControl(null, [Validators.required, Validators.minLength(8), validateNoWhitespaces]),
    }, passwordMatchValidatorBuilder('password', 'confirm'));

    this.success$
      .subscribe(
        val => this.onClickResetForm()
      )
  }

  onClickResetForm() {
    this.form.reset();
  }

  onSubmitForm() {
    this.submitForm.next(this.form.value.password);
    this.form.reset();
  }
}
