import {
  Component,
  OnInit,
  OnDestroy,
  EventEmitter,
  ViewChild
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { Subject } from 'rxjs/Subject';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Actions as NgRxActions } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { AppState } from 'app/store/app-state';
import { AlertService } from 'app/shared/components/alerts/alert.service';
import { ModalComponent } from 'app/shared/components/modals/modal.component';
import { Actions } from 'app/store/actions';
import { BakeryMgmtProductTemplatesEffects } from 'app/store/effects/bakery-mgmt/bakery-mgmt-bakery-product-templates.effects';
import { BakeryProductTemplate } from 'app/store/bakery-product-template';
import { BakeryProduct } from 'app/store/bakery-product';
import { BakeryAttribute } from 'app/store/bakery-attribute';
import { BakeryMgmtUiState } from 'app/store/bakery-mgmt-ui-state';
import { EntitiesState } from 'app/store/entities-state';
import { denormalize as denormalizeProdTemp } from 'app/store/bakery-product-templates/utils';
import {
  SimpleFormState,
  SubmittableFormState
} from 'app/shared/forms/form-states';
import { formArrayNameProvider } from '@angular/forms/src/directives/reactive_directives/form_group_name';
import { BakeryMgmtProductAttributesEffects } from 'app/store/effects/bakery-mgmt/bakery-mgmt-bakery-product-attributes.effects';

export interface UiState {
  isLoading: boolean;
  productTemplate?: BakeryProductTemplate;
  bakeryProducts?: BakeryProduct[];
  bakeryAttributes?: BakeryAttribute[];
}

@Component({
  template: require('./detail.component.html')
})
export class BakeryMgmtProductTemplateDetailComponent
  implements OnInit, OnDestroy {
  @ViewChild(ModalComponent) deleteModal: ModalComponent;

  uiState$: Observable<UiState>;
  detailsForm: FormGroup;
  updateSuccess$: Observable<any>;
  productTemplate$: Observable<BakeryProductTemplate>;

  public isDirty = false;
  public isValid = false;

  private templateId$: Observable<number>;
  private templateIdSub: Subscription;
  private updateDetails$ = new Subject<any>();
  private updateDetailsSub: Subscription;
  private confirmDelete$ = new Subject<any>();
  private confirmDeleteSub: Subscription;
  private deleteSuccessSub: Subscription;
  private alertsSub: Subscription;
  private formSubmitSub: Subscription;
  public formState$: Observable<SubmittableFormState>;
  public detailsFormState$ = new Subject<SimpleFormState>();
  public associationsFormState$ = new Subject<SimpleFormState>();
  public formSubmit$ = new Subject<any>();
  public formReset$ = new Subject<any>();
  public editCautionVisible$: Observable<boolean>;

  constructor(
    private bakeryMgmtProductsEffects: BakeryMgmtProductTemplatesEffects,
    private bakeryMgmtProductAttributesEffects: BakeryMgmtProductAttributesEffects,
    private router: Router,
    private route: ActivatedRoute,
    private actions$: NgRxActions,
    private store: Store<AppState>,
    private alertService: AlertService
  ) {
    this.bakeryMgmtProductAttributesEffects.requestGetBakeryAttributes();
    this.templateId$ = this.route.params.map((params): number =>
      parseInt(params['template_id'])
    );

    this.templateIdSub = this.templateId$.subscribe((id: number) =>
      this.bakeryMgmtProductsEffects.requestGetBakeryProductTemplateDetails(id)
    );

    this.uiState$ = Observable.combineLatest(
      this.store.select('bakeryMgmtUiState'),
      this.store.select('entitiesState'),
      this.templateId$,
      (uiState, entities, templateId) => ({ uiState, entities, templateId })
    )
      .map(combined => {
        const bakeryMgmtUiState = <BakeryMgmtUiState>combined.uiState;
        const entitiesState = <EntitiesState>combined.entities;
        const templateId = <number>combined.templateId;

        if (
          bakeryMgmtUiState.bakeryProductTemplatesUiState.isDetailLoading ||
          entitiesState.bakery_product_templates[templateId] == null
        ) {
          return {
            isLoading: true
          };
        }

        const productTemplate = denormalizeProdTemp(
          Object.assign({}, entitiesState.bakery_product_templates[templateId]),
          entitiesState
        );

        const bakeryProducts = Object.keys(entitiesState.bakery_products).map(
          key => entitiesState.bakery_products[key]
        );

        const bakeryAttributes = Object.keys(entitiesState.bakery_attributes).map(
          key => entitiesState.bakery_attributes[key]
        );

        return {
          isLoading: false,
          productTemplate: productTemplate,
          bakeryProducts: bakeryProducts,
          bakeryAttributes: bakeryAttributes
        };
      })
      .shareReplay(1);

    this.productTemplate$ = this.uiState$
      .filter((uiState: UiState) => !uiState.isLoading)
      .map(
        (uiState: UiState): BakeryProductTemplate => uiState.productTemplate
      );

    this.updateDetailsSub = this.updateDetails$
      .combineLatest(
        this.productTemplate$.take(1),
        (updateDetailsPayload, productTemplate) => ({
          updateDetailsPayload,
          productTemplate
        })
      )
      .subscribe(({ updateDetailsPayload, productTemplate }) => {
        this.bakeryMgmtProductsEffects.requestUpdateBakeryProductTemplate(
          productTemplate,
          updateDetailsPayload
        );
      });

    this.confirmDeleteSub = this.confirmDelete$
      .switchMap(() => this.productTemplate$.take(1))
      .subscribe(productTemplate => {
        this.bakeryMgmtProductsEffects.requestDeleteBakeryProductTemplate(
          productTemplate
        );
      });

    this.deleteSuccessSub = this.actions$
      .ofType(Actions.REQUEST_DELETE_BAKERY_PRODUCT_TEMPLATE_SUCCESS)
      .delay(1500)
      .subscribe(() =>
        this.router.navigate(['/bakery_mgmt/product_mgmt/main/templates'])
      );

    this.updateSuccess$ = this.bakeryMgmtProductsEffects.updateBakeryProductTemplateEffects$.filter(
      action =>
        action.type === Actions.REQUEST_UPDATE_BAKERY_PRODUCT_TEMPLATE_SUCCESS
    );

    this.alertsSub = this.actions$.subscribe((action: UnsafeAction) => {
      let messageType = null,
        messageContent = null;

      switch (action.type) {
        case Actions.REQUEST_UPDATE_BAKERY_PRODUCT_TEMPLATE_SUCCESS:
          messageType = 'success';
          messageContent = `The template has been updated.`;
          break;

        case Actions.REQUEST_DELETE_BAKERY_PRODUCT_TEMPLATE_SUCCESS:
          messageType = 'success';
          messageContent = `The template has been deleted.`;
          break;

        case Actions.REQUEST_UPDATE_BAKERY_PRODUCT_TEMPLATE_ERROR:
        case Actions.REQUEST_DELETE_BAKERY_PRODUCT_TEMPLATE_ERROR:
          messageType = 'warning';
          messageContent = 'Something went wrong!';
          break;
      }

      if (messageType != null && messageContent != null) {
        this.alertService[messageType](messageContent);
      }
    });
  }

  ngOnInit() {
    this.formState$ = Observable.combineLatest(
      this.detailsFormState$,
      this.associationsFormState$,
      (detailsFormState, associationsFormState): SubmittableFormState => {
        const isDirty =
          detailsFormState.isDirty || associationsFormState.isDirty;
        const isValid =
          detailsFormState.isValid && associationsFormState.isValid;
        return {
          isValid: isValid,
          isDirty: isDirty,
          isSubmittable: isDirty && isValid,
          value: Object.assign(
            {},
            detailsFormState.value,
            associationsFormState.value
          )
        };
      }
    )
      .startWith({
        isDirty: false,
        isValid: false,
        isSubmittable: false,
        value: {}
      })
      .shareReplay(1);

    this.editCautionVisible$ = this.formState$.map(
      (formState: SubmittableFormState) => formState.isDirty
    );

    this.formSubmitSub = this.formSubmit$
      .switchMap(() => this.formState$.take(1))
      .withLatestFrom(this.uiState$, (formState, uiState) => ({
        formState,
        uiState
      }))
      .subscribe(({ formState, uiState }) => {
        if (!formState.isSubmittable) return null;
        this.bakeryMgmtProductsEffects.requestUpdateBakeryProductTemplate(
          uiState.productTemplate,
          {
            ...formState.value,
            unit_price: formState.value.unit_price
          }
        );
      });
  }

  ngOnDestroy() {
    this.templateIdSub.unsubscribe();
    this.updateDetailsSub.unsubscribe();
    this.confirmDeleteSub.unsubscribe();
    this.deleteSuccessSub.unsubscribe();
    this.alertsSub.unsubscribe();
  }

  onClickFormSubmit() {
    this.formSubmit$.next();
  }

  onClickFormReset() {
    this.formReset$.next();
  }

  onSubmitDetailsForm(detailsUpdatePayload: any) {
    this.updateDetails$.next(detailsUpdatePayload);
  }

  onClickDelete() {
    this.deleteModal.openModal();
  }

  onConfirmDelete() {
    this.confirmDelete$.next();
  }

  onChangeDetailsFormState(formState: SimpleFormState) {
    this.detailsFormState$.next(formState);
  }

  onChangeAssociationsFormState(formState: SimpleFormState) {
    this.associationsFormState$.next(formState);
  }
}
