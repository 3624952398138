import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as moment from 'moment/moment';
import { Moment } from 'moment/moment';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subscription } from 'rxjs/Subscription';
import { Angular2TokenService } from 'app/angular2-token/angular2-token.service';
import { HttpParams, HttpParameterCodec } from '@angular/common/http';

import { AppState } from 'app/store/app-state';
import { Actions } from 'app/store/actions';
import * as selectors from 'app/store/selectors';
import { DateRange } from 'app/shared/components/date-range-search-form/date-range-search-form-2.component';
import * as OrderingArrowComponent from 'app/shared/components/ordering-arrow.component';

enum OrderingDirection {
  Asc,
  Desc
}

enum OrderingField {
  OrderNumber,
  FulfillmentOn,
  LastName
}

interface Ordering {
  field: OrderingField;
  direction: OrderingDirection;
}

const orderingLabel = field => ordering => {
  if (ordering.field !== field) { return OrderingArrowComponent.Direction.None; }
  switch (ordering.direction) {
    case OrderingDirection.Asc: return OrderingArrowComponent.Direction.Asc;
    case OrderingDirection.Desc: return OrderingArrowComponent.Direction.Desc;
  }
};

const serializeOrderingField = ordering => {
  switch (ordering.field) {
    case OrderingField.OrderNumber: return 'order_number';
    case OrderingField.LastName: return 'order_customer_name';
    case OrderingField.FulfillmentOn: return 'fulfillment_on';
  }
};

const serializeOrderingDirection = ordering => {
  switch (ordering.direction) {
    case OrderingDirection.Asc: return 'asc';
    case OrderingDirection.Desc: return 'desc';
  }
};

declare var process: any;

@Component({
  template: require('./decorating.component.html'),
})

export class BakeryMgmtReportingDecoratingComponent implements OnInit, OnDestroy {
  OrderingFieldType = OrderingField;
  orderingDirection$ = new Subject<OrderingField>();
  private ordering$ = Observable
    .from(this.orderingDirection$)
    .scan((acc, field) => {
      if (acc.field !== field) {
        return { field, direction: OrderingDirection.Asc };
      }
      switch (acc.direction) {
        case OrderingDirection.Asc: return { field, direction: OrderingDirection.Desc };
        case OrderingDirection.Desc: return { field, direction: OrderingDirection.Asc };
      }
    }, { field: OrderingField.FulfillmentOn, direction: OrderingDirection.Asc })
    .startWith({ field: OrderingField.FulfillmentOn, direction: OrderingDirection.Asc });
  orderNumberOrderDirection$ = this.ordering$.map(orderingLabel(OrderingField.OrderNumber));
  lastNameOrderDirection$ = this.ordering$.map(orderingLabel(OrderingField.LastName));
  fulfillmentOnOrderDirection$ = this.ordering$.map(orderingLabel(OrderingField.FulfillmentOn));
  filterForm: FormGroup;
  locationForm: FormGroup;
  locationControls$;

  decoratingReport$ = this.store.select(selectors.selectReportingDecoration);
  selectedFulfillments$: Observable<string>;
  bakeryLocations$: Observable<{ id: number, name: string }[]> = this.store
    .select(selectors.selectBakeryLocations)
    .pluck('bakeryLocations');

  onChangeSearchOptions$ = new ReplaySubject<DateRange>();
  searchStartDate$ = this.onChangeSearchOptions$.pluck('searchStartDate').map((x: Moment) => x.toISOString());
  searchEndDate$ = this.onChangeSearchOptions$.pluck('searchEndDate').map((x: Moment) => x.toISOString());

  exportHref$: Observable<string>;

  private subscription: Subscription;

  constructor(
    private store: Store<AppState>,
    private tokenService: Angular2TokenService
  ) {
    this.filterForm = new FormGroup({
      delivery: new FormControl(true),
      shipping: new FormControl(true),
    });
    this.locationForm = new FormGroup({});
  }

  ngOnInit() {
    this.store.dispatch({ type: Actions.GET_BAKERY_LOCATIONS });

    this.locationControls$ = this.bakeryLocations$
      .skipWhile((xs: any[]) => xs.length === 0)
      .take(1)
      .do((locations: any[]) => {
        locations.forEach(location => {
          this.locationForm.addControl(location.id.toString(), new FormControl(true));
        });
      })
      .map((locations: any[]) => {
        return locations.map(location => (
          { controlName: location.id.toString(), controlLabel: location.name + (location.deleted ? ' (deleted)' : '') }
        ));
      });

    const locationFormValueChanges$ = this.locationControls$
      // Emit only after locationControls$ has completed
      .concatMap(() => this.locationForm.valueChanges.startWith(this.locationForm.value))
      // Emit only when values changed
      .distinctUntilChanged((x, y) => JSON.stringify(x) === JSON.stringify(y));
      // The above is needed because addControl emits so
      // - we want to wait for the controls to be there
      // - we want changes to be distinct

    const locationFormSelectedFilters$ = locationFormValueChanges$
      .map(x => [this.getSelectedFiltersFor(x)]);

    const filterFormSelectedFilters$ = this.filterForm
      .valueChanges
      .startWith(this.filterForm.value)
      .map(x => [this.getSelectedFiltersFor(x)]);

    const filters$ = Observable.combineLatest(
      this.onChangeSearchOptions$,
      filterFormSelectedFilters$,
      locationFormSelectedFilters$,
      this.ordering$
    )
    .map(([searchOptions, filterForm, locationForm, ordering]) => {
      return {
        search_start_date: searchOptions.searchStartDate.format('YYYY-MM-DD'),
        search_end_date: searchOptions.searchEndDate.format('YYYY-MM-DD'),
        fulfillment_types: filterForm,
        locations: locationForm,
        order_by: serializeOrderingField(ordering),
        order_direction: serializeOrderingDirection(ordering),
      };
    });

    this.subscription = filters$.subscribe((payload) => {
      this.store.dispatch({
        type: Actions.REQUEST_DECORATING_REPORT,
        payload
      });
    });

    this.exportHref$ = filters$.map((payload) => {
      const params = new HttpParams({
        fromObject: {
          ...this.tokenService.getAuthDataFromStorage(),
          ...payload,
        },
        encoder: new HttpUrlEncodingCodec()
      });
      return `${process.env.API_URL}/api/bakery/reports/decorating.csv?${params}`;
    });

    const selectedBakeryLocationNames$ = Observable.combineLatest(
      locationFormValueChanges$,
      this.bakeryLocations$
    )
    .map(([locationForm, bakeryLocations]) => {
      const isSelected = (form, id) =>
        this.getSelectedFiltersFor(form).includes(String(id));

      return bakeryLocations
        .filter(location => isSelected(locationForm, location.id))
        .map(location => `pickup at ${location.name}`);
    })
    .startWith([]);

    const selectedFulfillmentTypes$ = this.filterForm.valueChanges
      .startWith(this.filterForm.value)
      .map(filterForm => this.getSelectedFiltersFor(filterForm));

    this.selectedFulfillments$ = Observable.combineLatest(
      selectedBakeryLocationNames$,
      selectedFulfillmentTypes$
    )
    .map(([bakeryLocations, fulfillmentTypes]) => bakeryLocations.concat(fulfillmentTypes).join(', '));
  }

  getSelectedFiltersFor(filters) {
    const selectedFilters = [];
    for (const key of Object.keys(filters)) {
      if (filters[key]) {
        selectedFilters.push(key);
      }
    }
    return selectedFilters;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getAttributes(item) {
    const attributes = item.meta.template_variants.map(variant => (
      `${variant.bakery_product_attribute.visible_name}: <strong>${variant.bakery_attribute_variant.name}</strong><br>`
    )).concat(item.meta.attribute_variants.map(variant => (
      `${variant.bakery_product_attribute.visible_name}: <strong>${variant.bakery_attribute_variant.name}</strong><br>`
    )));

    return attributes.join('');
  }

  getProductAndTemplateName(item) {
    const result =
      item.meta.bakery_product_template ? `${(item.bakery_product)} - ${(item.meta.bakery_product_template.name)}` : item.bakery_product;

    return result;
  }
}

class HttpUrlEncodingCodec implements HttpParameterCodec {
  encodeKey(k: string): string { return encodeURIComponent(k); }

  encodeValue(v: string): string { return Array.isArray(v) ? encodeURIComponent(JSON.stringify(v)) : encodeURIComponent(v); }

  decodeKey(k: string): string { return decodeURIComponent(k); }

  decodeValue(v: string) { return decodeURIComponent(v); }
}
