import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as moment from 'moment/moment';

import { AppState } from 'app/store/app-state';
import * as selectors from 'app/store/selectors';
import { GetAction } from 'app/store/actions/request.actions';
import { BakeryMgmtUiState } from 'app/store/bakery-mgmt-ui-state';
import {
  BakeryOrder,
  allOrderStatuses,
  orderFulfillmentDateDescSorter
} from 'app/store/bakery-order';
import { bakeryOrderSchema } from 'app/store/schema/default-schemata';
import { cloneSort } from 'app/store/entity-utils';

const opKey = 'customerOrderHistory',
      schema = [bakeryOrderSchema];

@Component({
  template: require('./order-history.component.html')
})
export class BakeryMgmtCustomersDetailOrderHistoryComponent implements OnInit {
  isLoading$: Observable<boolean> = this.store
    .select(selectors.getRequestIsLoading(opKey));

  bakeryOrders$: Observable<BakeryOrder[]> = this.store
    .select(selectors.getRequestResult(opKey, schema))
    .filter(val => !!val)
    // Sort by fulfillment_date, descending
    .map((bakeryOrders: BakeryOrder[]) => cloneSort(bakeryOrders, orderFulfillmentDateDescSorter))
    .startWith([]);

  mostRecentOrderDate$ = this.bakeryOrders$
    .map((bakeryOrders: BakeryOrder[]) => {
      // BakeryOrders$ is already sorted so that the most recent order is first.
      const lastOrder = bakeryOrders[0];

      if (lastOrder == null) return 'None';

      const momentCreatedAt = moment(lastOrder.created_at);

      return momentCreatedAt.format('MM/DD/YY');
    });

  constructor(
    private store: Store<AppState>,
  ) { }

  ngOnInit() {
    this.store
      .select('bakeryMgmtUiState')
      .map((bakeryMgmtUiState: BakeryMgmtUiState) => {
        return {
          bakeryId: bakeryMgmtUiState.bakeryId,
          customerId: bakeryMgmtUiState.customersUiState.detailState.detailId
        };
      })
      .take(1)
      .subscribe(({bakeryId, customerId}) => {
          const actionPayload = {
            url: `/api/bakeries/${bakeryId}/orders`,
            opKey: opKey,
            schema: schema,
            queryParams: {
              parent_type: 'User',
              parent_id: customerId,
              order_status: allOrderStatuses
            }
          };
          this.store.dispatch(new GetAction(actionPayload));
      });
  }
}
