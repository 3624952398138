import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { Actions } from 'app/store/actions';

export interface State {
  data: any[];
  total: number;
  bottom: number;
  top: number;
}

const INITIAL_STATE = {
  data: [],
  total: 0,
  bottom: 0,
  top: 0
};

export function reducer(state: State = INITIAL_STATE, action: UnsafeAction): State {
  switch (action.type) {
    case Actions.REQUEST_GET_BAKERY_CUSTOMERS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        total: action.payload.total,
        bottom: action.payload.bottom,
        top: action.payload.top,
      };

    default:
      return state;
  }
}
