import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as Rx from 'rxjs';
import { Actions as NgRxActions } from '@ngrx/effects';

import * as selectors from 'app/store/selectors';
import { Actions } from 'app/store/actions';
import { AppState } from 'app/store/app-state';
import { BakeryOrder } from 'app/store/bakery-order';
import { phoneNumberValidator } from 'app/shared/forms/form-number-validator';

@Component({
  selector: 'bkry-cust-checkout-customer-info-edit',
  template: `
    <titled-card title="Customer Information">
      <div data-card-body
        [formGroup]="parent">
        <fieldset gridRow>
          <label>Customer First Name</label>
          <input
            type="text"
            placeholder="Andre"
            formControlName="order_customer_first_name"
          />
          <label>Customer Last Name</label>
          <input
            type="text"
            placeholder="Staltz"
            formControlName="order_customer_last_name"
          />
        </fieldset>
        <div gridRow>
          <div gridCol s="4" m="9" l="6">
            <label>Email Address</label>
            <input
              type="email"
              placeholder="astaltz@example.com"
              formControlName="order_customer_email"
            />
          </div>
          <div gridCol s="4" m="9" l="6">
            <label>Phone Number</label>
            <phone-input formControlName="order_customer_phone_number"></phone-input>
          </div>
        </div>
      </div>
    </titled-card>
  `
})
export class BakeryCustCheckoutCustomerInfoEditComponent implements OnInit, OnDestroy {
  @Input() parent: FormGroup;

  constructor(
    private store: Store<AppState>,
  ) {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }
}
