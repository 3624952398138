import { Injectable } from '@angular/core';
import { Angular2TokenService } from 'app/angular2-token/angular2-token.service';
import { Actions as NgRxActions, Effect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';
import { Response } from '@angular/http';

import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { AppState } from 'app/store/app-state';
import { Actions } from 'app/store/actions';
import * as selectors from 'app/store/selectors';

@Injectable()
export class BakeryMgmtAttributeSummaryReportEffects {

  constructor(
    private actions$: NgRxActions,
    private tokenService: Angular2TokenService,
    private store: Store<AppState>
  ) { }

  @Effect() requestAttributeSummary$ = this.actions$
    .ofType(Actions.REQUEST_ATTRIBUTE_SUMMARY)
    .switchMap((action: UnsafeAction) => {
      return this.tokenService
        .get(`/api/bakery/reports/attribute_summary`, { params: action.payload })
        .map((response: Response) => response.json())
        .switchMap(response => {
          return Observable.of({ type: Actions.REQUEST_ATTRIBUTE_SUMMARY_SUCCESS, payload: response });
        })
        .catch(() => {
          return Observable.of({ type: Actions.REQUEST_ATTRIBUTE_SUMMARY_ERROR });
        });
    })
    .share();
}
