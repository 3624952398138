import { Store } from '@ngrx/store';

import { AppState } from 'app/store/app-state';
import * as selectors from 'app/store/selectors';

export class DetailPaneWrapperComponent {
  isLoading$ = this.store
    .select(selectors.bkryMgmtWholesaler.getBkryMgmtWholesalerStateDetail)
    .pluck('isLoading');
  wholesalerOrg$ = this.store
    .select(selectors.bkryMgmtWholesaler.getBkryMgmtWholesalerDetailResult)
    .filter(val => !!val);
  isUpdateLoading$ = this.store
    .select(selectors.getBkryMgmtOrderMgmtDetailState)
    .pluck('update', 'isLoading');

  constructor(
    protected store: Store<AppState>,
  ) { }
}
