import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subscription } from 'rxjs/Subscription';
import { Actions as NgRxActions } from '@ngrx/effects';

import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { AlertService } from 'app/shared/components/alerts/alert.service';
import { User } from 'app/store/user';
import { AppState } from 'app/store/app-state';
import { UsersEffects } from 'app/store/effects/users.effects';
import { BakeryMgmtEffects } from 'app/store/effects/bakery-mgmt.effects';
import { Actions } from 'app/store/actions';
import { UserDetailsForm } from 'app/shared/components/edit-user-details/edit-user-details.component';
import { ModalComponent } from 'app/shared/components/modals/modal.component';

// FIXME: This is a bit absurd, because there's no "loading" state for
// currentUser within guarded routes--currentUser would already be resolved
// in order to authorize the route presentation.
// ...actually... that might not always be true...
interface UiState {
  isLoading: boolean;
  user?: User;
}

@Component({
  selector: 'bakery-mgmt-account-settings',
  template: require('./account-settings.component.html'),
})
export class BakeryMgmtAccountSettingsComponent implements OnInit, OnDestroy {
  @ViewChild('accountDeleteModal', {read: ModalComponent}) accountDeleteModal: ModalComponent;
  // @ViewChild(ModalComponent) accountDeleteModal: ModalComponent;

  uiStates$ = new BehaviorSubject<UiState>({isLoading: true});
  passwordSuccess$: Observable<any>;
  userDetailsSuccess$: Observable<any>;

  private alertsSub: Subscription;
  private destroyOwnBakerySub: Subscription;

  constructor(
    private alertService: AlertService,
    private usersEffects: UsersEffects,
    private actions$: NgRxActions,
    private router: Router,
    private bakeryMgmtEffects: BakeryMgmtEffects,
  ) {
    this.usersEffects.currentUser$
      .subscribe((user: User) => {
        this.uiStates$.next({
          isLoading: false,
          user: user,
        });
      });

    this.passwordSuccess$ = this.usersEffects.requestUserPasswordUpdateEffects$
      .filter(action => action.type === Actions.REQUEST_USER_PASSWORD_UPDATE_SUCCESS);

    this.userDetailsSuccess$ = this.usersEffects.requestUserDetailsUpdateEffects$
      .filter(action => action.type === Actions.REQUEST_USER_DETAILS_UPDATE_SUCCESS);

    this.alertsSub = this.actions$
      .subscribe((action: UnsafeAction) => {
        let messageType = null,
            messageContent = null;

        switch (action.type) {
          case Actions.REQUEST_USER_PASSWORD_UPDATE_SUCCESS:
            messageType = 'success';
            messageContent = `Your password has been updated.`;
            break;

          case Actions.REQUEST_UPDATE_SUBSCRIBE_SUCCESS:
            messageType = 'success';
            messageContent = `Your subscription has been updated.`;
            break;

          case Actions.REQUEST_USER_DETAILS_UPDATE_SUCCESS:
            messageType = 'success';
            messageContent = `Your user details have been updated.`;
            break;

          case Actions.REQUEST_USER_DETAILS_UPDATE_ERROR:
            messageType = 'warning';
            messageContent = 'Something went wrong!';

            if (action.payload.error === 'duplicate_email_address') {
              messageContent = 'Another user already has that email address.';
            }
            break;
        }

        if (messageType != null && messageContent != null) {
          this.alertService[messageType](messageContent);
        }
      });

    this.destroyOwnBakerySub = this.bakeryMgmtEffects.destroyOwnBakeryEffects$
      .filter(action => action.type === Actions.REQUEST_DESTROY_OWN_BAKERY_SUCCESS)
      .subscribe(() => this.router.navigate(['/']))
  }

  get currentUser$(): Observable<User> {
    return this.usersEffects.currentUser$;
  }

  ngOnInit() { }

  ngOnDestroy() {
    this.alertsSub.unsubscribe();
  }

  onSubmitUserDetailsForm(updatedUserDetails: UserDetailsForm) {
    this.usersEffects.requestUserDetailsUpdate({
      id: this.uiStates$.value.user.id,
      first_name: updatedUserDetails.first_name,
      last_name: updatedUserDetails.last_name,
      email: updatedUserDetails.email,
    });
  }

  onSubmitUserPasswordForm(newPassword) {
    this.usersEffects.requestUserPasswordUpdate({
      id: this.uiStates$.value.user.id,
      password: newPassword,
    });
  }

  onClickDeleteAccount() {
    this.accountDeleteModal.openModal();
  }

  onConfirmDeleteAccount() {
    this.bakeryMgmtEffects.requestDestroyOwnBakery();
  }
}
