import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'bakery-mgmt-store-mgmt',
  template: `
    <router-outlet></router-outlet>
  `
})
export class BakeryMgmtStoreMgmtComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}
