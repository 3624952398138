import { Component, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'bakery-mgmt-page-blank-state',
  template: `
    <div class="row text--center margin-top--alpha">
      <i aria-label="ariaLable"
        class="icon icon--lg icon--shadow margin-bottom--charlie"
        [inlineSVG]="iconPath"></i>
      <h4 class="margin-bottom--echo">{{ title }}</h4>
      <p *ngIf="isTextDescriptionVisible">{{ description }}</p>
      <ng-content></ng-content>
    </div>
  `
})
export class BakeryMgmtPageBlankStateComponent {
  @Input() title: string;
  @Input() description: string;
  @Input() icon: string;
  @Input() ariaLabel: string;

  constructor() { }

  get iconPath(): string {
    return require(`angular/assets/images/icons/icons-functional/icon-${this.icon}.svg`);
  }

  get isTextDescriptionVisible(): boolean {
    return this.description != null;
  };
}
