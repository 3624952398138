import { schema } from 'normalizr';

export const attachedImageSchema = new schema.Entity('attached_images');
export const addressSchema = new schema.Entity('addresses');
export const bakeryLocationSchema = new schema.Entity('bakery_locations');
export const featureModulesSchema = new schema.Entity('feature_modules');
export const subscriptionPlanSchema = new schema.Entity('subscription_plans');
export const subscriptionPlanTypeSchema = new schema.Entity('subscription_plan_types');
export const roleSchema = new schema.Entity('roles');
export const userSchema = new schema.Entity('users', {
  roles: [ roleSchema ],
});
export const promoCodeSchema = new schema.Entity('promo_codes');
export const bakerySubscriptionStatusSchema = new schema.Entity('bakery_subscription_statuses', {
  feature_modules: [ featureModulesSchema ],
});
export const bakerySchema = new schema.Entity('bakeries', {
  bakery_subscription_status: bakerySubscriptionStatusSchema,
  bakery_locations: [bakeryLocationSchema]
});
export const bakerySubscriptionInvoiceSchema = new schema.Entity('bakery_subscription_invoices');

export const bakeryProductSchema = new schema.Entity('bakery_products', {
  bakery_product_product_categories: [new schema.Entity('bakery_product_product_categories')],
  bakery_product_product_templates: [new schema.Entity('bakery_product_product_templates')],
  bakery_product_templates: [new schema.Entity('bakery_product_templates')]
});

export const bakeryProductProductTemplateSchema = new schema.Entity('bakery_product_product_templates', {
  bakery_product: new schema.Entity('bakery_products'),
  bakery_product_template: new schema.Entity('bakery_product_templates')
});

export const productCategoryShallowSchema = new schema.Entity('product_categories');

export const bakeryProductDeepSchema = new schema.Entity('bakery_products', {
  product_categories: [productCategoryShallowSchema],
});

export const productCategorySchema = new schema.Entity('product_categories', {
  bakery_products: [bakeryProductSchema],
});

export const bakeryAttributeVariantTemplateSchema = new schema.Entity('bakery_attribute_variants');

export const bakeryAttributeSchema = new schema.Entity('bakery_attributes', {
  bakery_attribute_variants: [ bakeryAttributeVariantTemplateSchema ]
});

export const bakeryProductAttributeTemplateSchema = new schema.Entity('bakery_product_attributes', {
  bakery_products: [bakeryProductSchema],
  bakery_attribute: bakeryAttributeSchema,
});

export const bakeryProductTemplateSchema = new schema.Entity('bakery_product_templates', {
  bakery_product_product_templates: [ bakeryProductProductTemplateSchema ],
  bakery_product_attributes: [ bakeryProductAttributeTemplateSchema ]
});

export const orderItemSchema = new schema.Entity('order_items', {
  bakery_product_product_template: bakeryProductProductTemplateSchema,
  bakery_product_template: bakeryProductTemplateSchema,
  bakery_product: bakeryProductSchema,
});
export const bakeryCartSchema = new schema.Entity('bakery_carts', {
  order_items: [ orderItemSchema ],
});

export const bakeryInvoicePaymentSchema = new schema.Entity('bakery_invoice_payments');

export const bakeryOrderSchema = new schema.Entity('bakery_orders', {
  order_items: [ orderItemSchema ],
  invoice: new schema.Entity('bakery_order_invoices', {
    bakery_invoice_payments: [ bakeryInvoicePaymentSchema ]
  }),
  attached_images: [ attachedImageSchema ],
  order_schedule: new schema.Entity('order_schedules')
});

export const bakeryOrderInvoiceSchema = new schema.Entity('bakery_order_invoices', {
  bakery_orders: [ bakeryOrderSchema ],
  bakery_invoice_payments: [ bakeryInvoicePaymentSchema ]
});

export const paymentProviderConfigShallowSchema = new schema.Entity('payment_provider_configs');
export const storePaymentConfigDeepSchema = new schema.Entity('store_payment_configs', {
  payment_provider_configs: [ paymentProviderConfigShallowSchema ],
});
export const storePaymentConfigShallowSchema = new schema.Entity('store_payment_configs');
export const paymentProviderConfigDeepSchema = new schema.Entity('payment_provider_configs', {
  store_payment_config: storePaymentConfigShallowSchema,
});

export const wholesalerOrgSchema = new schema.Entity('wholesaler_orgs', {
  wholesaler_groups: [new schema.Entity('wholesaler_groups')]
});
export const wholesalerProductOverrideSchema = new schema.Entity('wholesaler_product_overrides', {
  bakery_product_product_template: bakeryProductProductTemplateSchema,
  // bakery_product: bakeryProductSchema,
  // bakery_product_template: bakeryProductTemplateSchema
});
export const wholesalerGroupSchema = new schema.Entity('wholesaler_groups', {
  active_wholesaler_orgs: [new schema.Entity('wholesaler_orgs')],
  wholesaler_product_overrides: [wholesalerProductOverrideSchema]
});

export const orderScheduleSchema = new schema.Entity('order_schedules', {
  bakery_order: bakeryOrderSchema,
  bakery_orders: [ bakeryOrderSchema ]
});
