import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { NotificationsService } from 'angular2-notifications';

// FIXME: This is an abomination.

@Injectable()
export class AlertService {
  static NOTIFICATION_TYPE = 'notification';
  static SUCCESS_TYPE = 'success';
  static CAUTION_TYPE = 'caution';
  static WARNING_TYPE = 'warning';

  constructor(
    private notificationsService: NotificationsService,
    private sanitizer: DomSanitizer,
  ) { }

  notification(content: string, options: any = {}) {
    return this.generic(AlertService.NOTIFICATION_TYPE, content, options);
  }

  success(content: string, options: any = {}) {
    return this.generic(AlertService.SUCCESS_TYPE, content, options);
  }

  caution(content: string, options: any = {}) {
    return this.generic(AlertService.CAUTION_TYPE, content, options);
  }

  warning(content: string, options: any = {}) {
    return this.generic(AlertService.WARNING_TYPE, content, { ...options, timeOut: 0 });
  }

  editCaution(content: string, type = 'caution') {
    const options = {
      timeOut: 0,
      clickToClose: false,
    };

    const htmlContent = `
      <figure class="alert--${type}">
        <svg width="17" height="19" viewbox="0 0 17 19" xmlns="http://www.w3.org/2000/svg" class="alert__icon"><title>icon</title>
        <path d="M13.363 12.57l-1.537-2.644c-.113-.196-.173-.418-.173-.644v-3.43c0-1.81-1.51-3.288-3.385-3.288-1.875 0-3.384 1.48-3.384 3.29V9.28c0 .226-.06.448-.174.644L3.172 12.57h10.19zm-1.482 2.565c-.073 1.91-1.668 3.43-3.612 3.43-1.943 0-3.538-1.52-3.612-3.43H1.773c-1.377 0-2.204-1.31-1.54-2.608l2.087-3.59V5.853C2.32 2.615 4.99 0 8.268 0c3.28 0 5.95 2.615 5.95 5.853v3.084l2.118 3.65c.63 1.238-.195 2.548-1.572 2.548H11.88zm-2.57 0H7.226c.07.484.506.865 1.04.865.537 0 .972-.38 1.042-.865z" fill="#CDD8DD" fill-rule="nonzero" id="app-icon"></path></svg>

        <p class="alert__text">${content}</p>
      </figure>
    `;

    const sanitizedHtmlContent = this.sanitizer.bypassSecurityTrustHtml(htmlContent);

    return this.notificationsService.html(sanitizedHtmlContent, 'bare', options);
  }

  generic(type: string, content: string, options: any = {}) {
    const htmlContent = `
      <figure class="alert--${type}">
        <svg width="17" height="19" viewbox="0 0 17 19" xmlns="http://www.w3.org/2000/svg" class="alert__icon"><title>icon</title>
        <path d="M13.363 12.57l-1.537-2.644c-.113-.196-.173-.418-.173-.644v-3.43c0-1.81-1.51-3.288-3.385-3.288-1.875 0-3.384 1.48-3.384 3.29V9.28c0 .226-.06.448-.174.644L3.172 12.57h10.19zm-1.482 2.565c-.073 1.91-1.668 3.43-3.612 3.43-1.943 0-3.538-1.52-3.612-3.43H1.773c-1.377 0-2.204-1.31-1.54-2.608l2.087-3.59V5.853C2.32 2.615 4.99 0 8.268 0c3.28 0 5.95 2.615 5.95 5.853v3.084l2.118 3.65c.63 1.238-.195 2.548-1.572 2.548H11.88zm-2.57 0H7.226c.07.484.506.865 1.04.865.537 0 .972-.38 1.042-.865z" fill="#CDD8DD" fill-rule="nonzero" id="app-icon"></path></svg>

        <p class="alert__text">${content}</p>

        <a class="clickable">
          <svg width="15" height="15" viewbox="0 0 15 15" xmlns="http://www.w3.org/2000/svg" class="alert__trigger"><title>icon</title>
        <path d="M.44 2.56l11.998 11.99c.585.587 1.535.586 2.12 0 .586-.586.586-1.535 0-2.12L2.56.44C1.974-.148 1.025-.147.44.44c-.587.586-.586 1.535 0 2.12zM12.438.44L.44 12.43c-.585.585-.586 1.534 0 2.12.585.586 1.535.587 2.12 0l12-11.99c.585-.585.585-1.534 0-2.12-.586-.586-1.536-.587-2.122 0z" fill="#CDD8DD" fill-rule="nonzero" id="app-icon"></path></svg>
        </a>
      </figure>
    `;

    const sanitizedHtmlContent = this.sanitizer.bypassSecurityTrustHtml(htmlContent);

    return this.notificationsService.html(sanitizedHtmlContent, 'bare', options);
  }

  remove(alertId?: string) {
    this.notificationsService.remove(alertId);
  }
}
