import {
  Component,
  Input,
  Output,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy
} from '@angular/core';
import { Store } from '@ngrx/store';
import * as Rx from 'rxjs';

import { AppState } from 'app/store/app-state';
import * as selectors from 'app/store/selectors';
import { DetailPaneWrapperComponent } from '../detail-pane-wrapper';

const compSelector = 'bkry-mgmt-wholesaler-detail-users';

@Component({
  template: `
    <bkry-mgmt-transfer-wholesaler-ownership
      [wholesalerOrg]="wholesalerOrg$ | async"
    ></bkry-mgmt-transfer-wholesaler-ownership>
  `,
})
export class UsersComponent extends DetailPaneWrapperComponent {
  constructor(
    store: Store<AppState>,
  ) {
    super(store);
  }
}
