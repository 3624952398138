import { Component, Input } from '@angular/core';
import { OrderItem } from 'app/store/order-item';

@Component({
  selector: 'order-mgmt-detail-edit-order-item-list',
  template: require('./order-item-list.component.html'),
})
export class EditOrderItemListComponent {
  private _orderId: number;
  private _orderItems: OrderItem[];

  @Input()
  set orderId(orderId: number) {
    this._orderId = orderId;
  }

  get orderId(): number {
    return this._orderId;
  }

  @Input()
  set orderItems(orderItems: OrderItem[]) {
    this._orderItems = orderItems || [];
  }

  get orderItems(): OrderItem[] {
    return this._orderItems;
  }

  constructor() {}
}
