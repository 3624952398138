import {
  Component,
  Input,
  ChangeDetectionStrategy,
  OnInit,
  ViewChild
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';
import { Actions as NgRxActions } from '@ngrx/effects';

import { AppState } from 'app/store/app-state';
import * as selectors from 'app/store/selectors';
import {
  PostAction,
  PutActionPayload
} from 'app/store/actions/request.actions';
import { AlertAction } from 'app/shared/components/alerts/actions';
import { PaymentData } from 'app/shared/components/invoices/invoice-add-payment-form.component';
import { InvoiceAddPaymentFormComponent } from 'app/shared/components/invoices/invoice-add-payment-form.component';

import { schema } from 'normalizr';
import { bakeryOrderInvoiceSchema } from 'app/store/schema/default-schemata';

const compSelector = 'bkry-mgmt-order-details-billing-prez';

const createOpKey = 'invoicePaymentCreate',
      createOpSchema = new schema.Entity('bakery_invoice_payments', {bakery_order_invoice: bakeryOrderInvoiceSchema});

@Component({
  template: `
    <ng-template [ngIf]="(isLoading$|async)">
      <loading-indicator></loading-indicator>
    </ng-template>
    <ng-template [ngIf]="!(isLoading$|async)">
      <${compSelector}
        [bakeryOrder]="bakeryOrder$ | async"
      ></${compSelector}>
    </ng-template>
  `,
})
export class BillingComponent {
  bakeryOrder$ = this.store.select(selectors.getBkryMgmtOrderMgmtDetailResult);
  isLoading$ = this.store.select(selectors.getBkryMgmtOrderMgmtDetailIsLoading);
  isLoadingState$ = this.store.select(selectors.getBkryMgmtOrderMgmtDetailIsLoading)
    .map(isLoading => ({isLoading}));

  constructor(
    private store: Store<AppState>,
  ) { }
}

@Component({
  selector: compSelector,
  template: require('./billing.component.html'),
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BillingPrezComponent implements OnInit {
  @Input() bakeryOrder: any;
  @ViewChild(InvoiceAddPaymentFormComponent) paymentFormComponent: InvoiceAddPaymentFormComponent;

  onSuccess$: Observable<undefined> = selectors.onRequestSuccessBuilder(this.actions$, createOpKey);

  createIsLoading$: Observable<boolean> = this.store
    .select(selectors.getRequestIsLoading(createOpKey));

  constructor(
    private actions$: NgRxActions,
    private store: Store<AppState>
  ) { }

  ngOnInit() {
    this.onSuccess$.subscribe(() => this.paymentFormComponent.resetForm());
  }

  get customer(): any {
    return this.bakeryOrder.parent;
  }

  onAddPayment(paymentData: PaymentData) {
    const formValue = {
      payment_source: {
        type: paymentData.payment_type === 'credit_card' ? 'PaymentCard' : 'ManualPaymentDetail',
        payment_type: paymentData.payment_type,
        id: paymentData.payment_card_selection === 'add_new' ? null : paymentData.payment_card_selection,
        stripe_token: paymentData.payment_card_stripe_token
      },
      amount: paymentData.amount,
      notes: paymentData.notes
    };

    const actionPayload: PutActionPayload = {
      url: `/api/invoices/${this.bakeryOrder.invoice.id}/payments`,
      opKey: createOpKey,
      schema: createOpSchema,
      body: formValue,
      successChainAction: AlertAction.buildSuccess(`The payment has been added.`)
    };

    this.store.dispatch(new PostAction(actionPayload));
  }
}
