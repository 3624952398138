import { schema } from 'normalizr';
import {
  bakeryLocationSchema,
  bakerySubscriptionStatusSchema,
  userSchema,
} from './default-schemata';

export const deepBakerySchema = new schema.Entity('bakeries', {
  bakery_locations: [ bakeryLocationSchema ],
  owner: userSchema,
  bakery_subscription_status: bakerySubscriptionStatusSchema
});
