import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

import { BakeryOrder } from 'app/store/bakery-order';

@Component({
  selector: 'order-schedule-type',
  template: `
    <ng-container *ngIf="bakeryOrder.order_schedule_id">
      Repeat {{ bakeryOrder.order_schedule.schedule_interval }}
    </ng-container>
    <ng-container *ngIf="!bakeryOrder.order_schedule_id">
      One-time order
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderScheduleTypeComponent {
  @Input() bakeryOrder: BakeryOrder;
}
