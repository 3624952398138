import { AbstractControl, FormControl } from '@angular/forms';
import * as moment from 'moment/moment';
import { ValidationResults } from './validation-results';
import { optionalValidator } from './optional-validator';

export function dateValidatorBuilder(minimumDate: Date = new Date()) {
  return function(c: FormControl) {
    const valueMoment = moment(c.value),
          minimumDateMoment = moment(minimumDate);

    // if (isNaN(parsedValue)) { return {positiveInteger: false}; }

    // if (parsedValue < minimumValue) { return {positiveInteger: false}; }

    return null;
  }
}

export function dateValidator(ctrl: FormControl) {
  const valueMoment = moment(ctrl.value);

  if (valueMoment.isValid()) return null;

  return {invalid_date: true};
}

export function futureDateValidator(ctrl: FormControl) {
  const dateValidationResult = dateValidator(ctrl);

  if (dateValidationResult != null) return dateValidationResult;

  const valueMoment = moment(ctrl.value).startOf('day'),
        todayMoment = moment().startOf('day');

  if (valueMoment.isSameOrAfter(todayMoment)) return null;

  return {invalid_past_date: true};
}

export function twoDaysDateValidator(ctrl: FormControl) {
  const dateValidationResult = dateValidator(ctrl);

  if (dateValidationResult != null) return dateValidationResult;

  const valueMoment = moment(ctrl.value).startOf('day'),
        twoDaysAwayMoment = moment().startOf('day').add(2, 'days');

  if (valueMoment.isSameOrAfter(twoDaysAwayMoment)) return null;

  return {invalid_past_date: true};
}

export function validateSearchDateRange(
  inputGroup: AbstractControl,
  startControlName: string,
  endControlName: string
): ValidationResults {
  const startDateValue = inputGroup.get(startControlName).value;
  const endDateValue = inputGroup.get(endControlName).value;

  // No dates, we're all good.
  if (startDateValue == null && endDateValue == null) return null;

  if (
    (startDateValue == null || endDateValue == null) && (startDateValue !== endDateValue)
  ) {
    return {'incomplete_date_range': true};
  }

  const momentStartDate = moment(startDateValue);
  const momentEndDate = moment(endDateValue);
  if (
    !momentStartDate.isValid()
    ||
    !momentEndDate.isValid()
    ||
    momentEndDate.isBefore(momentStartDate)
  ) {
    return {'impossible_date_range': true};
  }

  // Yay!
  return null;
}

export function searchDateRangeValidatorBuilder(
  startControlName: string = 'search_start_date',
  endControlName: string = 'search_end_date'
) {
  return function(inputGroup: AbstractControl) {
    return validateSearchDateRange(inputGroup, startControlName, endControlName);
  }
}
