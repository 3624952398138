import { Component, Input } from '@angular/core';

@Component({
  template: `
    <div class="container">
      <bakery-cust-page-header
        title="Wholesale Management"
        hasTabs="true"
      >
        <!-- TODO: "next invoice" content -->
      </bakery-cust-page-header>
      <div class="tabs">
        <ul class="tab__list margin-bottom--charlie">
          <li class="tab__item"
            routerLink="schedule"
            routerLinkActive="is-active">
            Schedule
          </li>
          <li class="tab__item"
            routerLink="invoices"
            routerLinkActive="is-active">
            Invoices
          </li>
          <li class="tab__item"
            routerLink="price_index"
            routerLinkActive="is-active">
            Price Index
          </li>
          <li class="tab__item"
            routerLink="settings"
            routerLinkActive="is-active">
            Settings
          </li>
        </ul>
      </div>
      <alert-container></alert-container>
      <router-outlet></router-outlet>
    </div>
  `
})
export class ManagementComponent {
}
