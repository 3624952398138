import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'bakery-mgmt-reporting-production-check-list-item',
  template: require('./list-item.component.html'),
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BakeryMgmtReportingProductionCheckListItemComponent {
  @Input() orderItem;
  @Input() getAttributes;
}

// to remove
