import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';

import { AppState } from 'app/store/app-state';

@Component({
  selector: 'bakery-mgmt-new-order-checkout-customer-info-edit',
  template: `
    <titled-card [title]="title">
      <div data-card-body
        [formGroup]="parent"
        class="checkout__customer-info">
        <fieldset
          gridRow
          *ngIf="wholesaler"
        >
          <label>Customer Name</label>
          <input
            type="text"
            placeholder="Example Bakery"
            formControlName="order_customer_name"
            [readonly]="wholesaler"
          />
        </fieldset>
        <fieldset gridRow>
          <label class="label--required">Customer First Name</label>
          <input
            type="text"
            placeholder="Andre"
            formControlName="order_customer_first_name"
            required
            aria-required="true"
            [readonly]="wholesaler"
          />
          <label class="label--required">Customer Last Name</label>
          <input
            type="text"
            placeholder="Staltz"
            formControlName="order_customer_last_name"
            required
            aria-required="true"
            [readonly]="wholesaler"
          />
        </fieldset>
        <fieldset gridRow>
          <div *ngIf="!wholesaler">
            <label class="label--required">Contact Information</label>
            <p>Provide at least one way to contact the customer</p>
          </div>
          <div gridCol s="4" m="9" l="6">
            <label>Email Address</label>
            <input
              type="email"
              placeholder="astaltz@example.com"
              formControlName="order_customer_email"
              [readonly]="wholesaler"
            />
          </div>
          <div gridCol s="4" m="9" l="6">
            <label>Phone Number</label>
            <phone-input
              formControlName="order_customer_phone_number"
              [readonly]="wholesaler"
            ></phone-input>
          </div>
        </fieldset>
      </div>
    </titled-card>
  `
})
export class BakeryMgmtNewOrderCheckoutCustomerInfoEditComponent implements OnInit {
  @Input() parent: FormGroup;
  @Input() wholesaler: boolean;
  title: String;

  constructor(
    private store: Store<AppState>,
  ) {
  }

  ngOnInit() {
    this.title = `Customer Information${this.wholesaler ? ' (Select customer on the left)' : ''}`;
  }
}
