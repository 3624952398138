let pendingGetRequests = 0;

const getSpinner = (): HTMLElement => document.getElementById('spinner');

export const show = (): void => {
  pendingGetRequests += 1;
  const spinner = getSpinner();
  if (!spinner) { return; }
  spinner.style.display = 'block';
};

export const hide = (): void => {
  pendingGetRequests -= 1;
  if (pendingGetRequests > 0) { return; }
  pendingGetRequests = 0;
  const spinner = getSpinner();
  if (!spinner) { return; }
  spinner.style.display = 'none';
};
