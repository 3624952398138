import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { Store } from '@ngrx/store';
import { Angular2TokenService } from 'app/angular2-token/angular2-token.service';
import { Actions as NgRxActions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';
import { normalize, schema } from 'normalizr';
import { UnsafeAction } from 'app/store/effects/unsafe-action';
import * as selectors from 'app/store/selectors';
import { AppState } from 'app/store/app-state';
import { EntitiesState } from 'app/store/entities-state';
import { Actions } from 'app/store/actions';
import { subscriptionPlanSchema } from 'app/store/schema/subscription-plan';
import {
  bakeryProductDeepSchema,
  productCategoryShallowSchema,
 } from 'app/store/schema/default-schemata';
import { BakeryCustEffects } from 'app/store/effects/bakery-cust/bakery-cust.effects';
import { BakeryProduct } from 'app/store/bakery-product';
import { Bakery } from 'app/store/bakery';
import { addEntityRequestHandler, basicRequestHandler } from 'app/store/effects/helpers';

@Injectable()
export class BakeryCustProductsEffects {
  constructor(
    private actions$: NgRxActions,
    private tokenService: Angular2TokenService,
    private store: Store<AppState>,
    private bakeryCustEffects: BakeryCustEffects,
  ) {}

  requestGetProductCategories() {
    this.store.dispatch({
      type: Actions.REQUEST_GET_STORE_PRODUCT_CATEGORIES,
    });
  }

  requestGetBakeryProducts(query: string) {
    this.store.dispatch({
      type: Actions.REQUEST_GET_BAKERY_PRODUCTS_FOR_WHOLESALE,
      payload: { query }
    });
  }

  requestGetBakeryProduct(id: number) {
    this.store.dispatch({
      type: Actions.REQUEST_GET_STORE_BAKERY_PRODUCT,
      payload: id,
    });
  }

  requestGetBakeryProductSuggestions(id: number) {
    this.store.dispatch({
      type: Actions.REQUEST_GET_STORE_BAKERY_PRODUCT_SUGGESTIONS,
      payload: id,
    });
  }

  private currentBakeryLatestMap(action: UnsafeAction): Observable<{action: UnsafeAction, bakery: Bakery}> {
    return this.bakeryCustEffects.currentBakery$
      .filter(bakery => bakery != null)
      .map(bakery => ({action, bakery}))
      .take(1);
  }

  @Effect() getProductsEffects$ = this.actions$
    .ofType(Actions.REQUEST_GET_BAKERY_PRODUCTS_FOR_WHOLESALE)
    .switchMap(this.currentBakeryLatestMap.bind(this))
    .switchMap(({action, bakery}) => {
      const query = [
        'archived=false',
        action.payload.query ? `search_text=${encodeURIComponent(action.payload.query)}` : ''
      ].filter(x => x.length > 0).join('&');
      return this.tokenService
        .get(`/api/bakeries/${bakery.id}/bakery_products?${query}`)
        .map((response: Response) => response.json())
        .switchMap(response => {
          return Observable.of({ type: Actions.REQUEST_GET_BAKERY_PRODUCTS_SUCCESS2, payload: response });
        })
        .catch(() => {
          return Observable.of({ type: Actions.REQUEST_GET_BAKERY_PRODUCTS_ERROR2 });
        });
    })
    .share();


  @Effect() getProductCategoriesEffects$ = this.actions$
    .ofType(Actions.REQUEST_GET_STORE_PRODUCT_CATEGORIES)
    .switchMap(this.currentBakeryLatestMap.bind(this))
    .switchMap(({action, bakery}) => {
      return addEntityRequestHandler(
        this.tokenService.get(
          `/api/bakeries/${bakery.id}/product_categories`
        ),
        [productCategoryShallowSchema],
        Actions.REQUEST_GET_STORE_PRODUCT_CATEGORIES
      );
    })
    .share();

  @Effect() updateBakeryProductsSearchTermsEffects$ = Observable
    .merge(
      this.actions$.ofType(Actions.LOAD_STORE_PRODUCT_CATEGORIES),
      this.actions$.ofType(Actions.SET_STORE_BAKERY_PRODUCTS_SEARCH_TEXT),
      this.actions$.ofType(Actions.ADD_STORE_BAKERY_PRODUCTS_CATEGORY_FILTER),
      this.actions$.ofType(Actions.REMOVE_STORE_BAKERY_PRODUCTS_CATEGORY_FILTER),
      this.actions$.ofType(Actions.REMOVE_STORE_BAKERY_PRODUCTS_CATEGORY_FILTERS),
    )
    .do(val => console.log(`searchTermsEffects val: `, val))
    .withLatestFrom(
      this.store.select('bakeryCustUiState'),
      (action, bakeryCustUiState) => ({action, bakeryCustUiState})
    )
    .do(val => console.log(`searchTermsEffects joined val: `, val))
    .switchMap((combined) => {
      const bakeryCustUiState = combined.bakeryCustUiState;

      const payload = {
        categories: (<any>bakeryCustUiState).bakeryProductsMasterState.categoryFilters,
        search_text: (<any>bakeryCustUiState).bakeryProductsMasterState.searchText,
        store_type: (<any>bakeryCustUiState).bakeryProductsMasterState.storeType,
      }

      return Observable.of({
        type: Actions.REQUEST_GET_STORE_BAKERY_PRODUCTS,
        payload: payload,
      });
    })
    .share();

  @Effect() getBakeryProductsEffects$ = this.actions$
    .ofType(Actions.REQUEST_GET_STORE_BAKERY_PRODUCTS)
    .switchMap(this.currentBakeryLatestMap.bind(this))
    .switchMap(({action, bakery}) => {
      console.log(`get products action:`, action);

      const url = `/api/bakeries/${bakery.id}/bakery_products?store_type=${action.payload.store_type}&categories=${action.payload.categories.join(',')}&search_text=${action.payload.search_text}`;

      return addEntityRequestHandler(
        this.tokenService.get(url),
        [bakeryProductDeepSchema],
        Actions.REQUEST_GET_STORE_BAKERY_PRODUCTS
      );
    })
    .share();

  @Effect() getBakeryProductEffects$ = this.actions$
    .ofType(Actions.REQUEST_GET_STORE_BAKERY_PRODUCT)
    .switchMap(this.currentBakeryLatestMap.bind(this))
    .switchMap(({action, bakery}) => {
      return addEntityRequestHandler(
        this.tokenService.get(
          `/api/bakeries/${bakery.id}/bakery_products/${action.payload}`
        ),
        bakeryProductDeepSchema,
        Actions.REQUEST_GET_STORE_BAKERY_PRODUCT
      );
    })
    .share();

  @Effect() getBakeryProductSuggestionsEffects$ = this.actions$
    .ofType(Actions.REQUEST_GET_STORE_BAKERY_PRODUCT_SUGGESTIONS)
    .switchMap(this.currentBakeryLatestMap.bind(this))
    .switchMap(({action, bakery}) => {
      return addEntityRequestHandler(
        this.tokenService.get(
          `/api/bakeries/${bakery.id}/bakery_products/${action.payload}/suggestions`
        ),
        [bakeryProductDeepSchema],
        Actions.REQUEST_GET_STORE_BAKERY_PRODUCT_SUGGESTIONS
      );
    })
    .share();
}
