import {
  Component,
  Input,
  Output,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy
} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions as NgRxActions } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { AppState } from 'app/store/app-state';
import { AlertService } from 'app/shared/components/alerts/alert.service';
import { Actions } from 'app/store/actions';
import * as selectors from 'app/store/selectors';
import { idDetailComponent } from 'app/shared/decorators/id-detail-component';

@idDetailComponent('group_id', Actions.REQUEST_GET_BKRY_MGMT_WHOLESALER_GROUP)
@Component({
  template: require('./detail.component.html')
})
export class DetailComponent implements OnInit, OnDestroy {
  wholesalerGroup$ = this.store
    .select(selectors.bkryMgmtWholesalerGroup.getDetailResultEntity);
  isLoading$ = this.store
    .select(selectors.bkryMgmtWholesalerGroup.getDetailIsLoading)
    .startWith(true);

  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {}
  ngOnDestroy() {}
}
