import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';

import { AppState } from 'app/store/app-state';
import * as selectors from 'app/store/selectors';
import { GetAction } from 'app/store/actions/request.actions';
import { Bakery } from 'app/store/bakery';
import { BakeryOrderInvoice } from 'app/store/bakery-order-invoice';
import { bakeryOrderInvoiceSchema } from 'app/store/schema/default-schemata';
import { WholesalerOrg } from 'app/store/wholesaler-org';
import { User } from 'app/store/user';

const opKey = 'wholesalerInvoiceDetail',
      schema = bakeryOrderInvoiceSchema;

@Component({
  template: `
    <div class="container">
      <bakery-cust-page-header
        title="Invoice Details"
        backLink="/store/wholesaler/management/invoices"
        backLinkText="Back to invoices"
      ></bakery-cust-page-header>
      <alert-container></alert-container>
      <invoice-details-card
        [isLoading]="isLoading$ | async"
        [invoice]="invoice$ | async"
        [bakery]="currentBakery$ | async"
        [wholesalerOrg]="wholesalerOrg$ | async"
        [isManualAllowed]="false"
        paymentButtonText="Add Payment"
      ></invoice-details-card>
    </div>
  `
})
export class InvoiceDetailsComponent implements OnInit, OnDestroy {
  invoiceId$ = this.route.params
    .map((params): number => parseInt(params['invoice_id']));

  isLoading$: Observable<boolean> = this.store
    .select(selectors.getRequestIsLoading(opKey));

  invoice$: Observable<BakeryOrderInvoice> = this.store
    .select(selectors.getRequestResult(opKey, schema))
    .filter(val => !!val);

  wholesalerOrg$: Observable<WholesalerOrg> = this.store
    .select(selectors.getCurrentUser)
    .map((user: User) => user.wholesaler_org);

  currentBakery$: Observable<Bakery> = this.store
    .select(selectors.getCurrentCustBakery);

  private idSub: Subscription;

  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.idSub = this.invoiceId$
      .subscribe((id: number) => {
        const actionPayload = {
          url: `/api/invoices/${id}`,
          opKey: opKey,
          schema: schema
        };
        this.store.dispatch(new GetAction(actionPayload));
      });
  }

  ngOnDestroy() {
    this.idSub.unsubscribe();
  }
}
