import { User } from 'app/store/user';
import { Role } from 'app/store/role';
import { EntitiesState } from 'app/store/entities-state';

export function hasRole(user: User, ...roleNames: string[]): boolean {
  // If user is non-existent, it never has the requested role.
  if (user == null) { return false; }

  if (user.roles == null || typeof user.roles.some !== 'function') {
    return false;
  }

  return (<Role[]>user.roles).some((role: Role): boolean => {
    return roleNames.some(roleName => roleName === role.name);
  });
}
