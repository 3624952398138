import { AbstractControl, FormControl } from '@angular/forms';
import { ValidationResults } from './validation-results';

export function optionalValidator(validatorFn: (input: AbstractControl) => ValidationResults): (input: AbstractControl) => ValidationResults {
  return (input: AbstractControl): ValidationResults => {
    if (input.value == null) return null;

    return validatorFn(input);
  }
}
