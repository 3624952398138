import { Component, OnInit } from '@angular/core';
import * as Rx from 'rxjs';
import { Store } from '@ngrx/store';
import { Actions as NgRxActions } from '@ngrx/effects';

import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { Actions } from 'app/store/actions';
import { AppState } from 'app/store/app-state';
import { EntitiesState } from 'app/store/entities-state';
import { AlertService } from 'app/shared/components/alerts/alert.service';
import { Bakery } from 'app/store/bakery';
import { ProductCategory } from 'app/store/product-category';
import { BakeryMgmtEffects } from 'app/store/effects/bakery-mgmt.effects';
import { BakeryMgmtProductCategoriesEffects } from 'app/store/effects/bakery-mgmt/bakery-mgmt-product-categories.effects';
import { SimpleFormState, SubmittableFormState } from 'app/shared/forms/form-states';

export interface UiState {
  isLoading: boolean;
  bakery?: Bakery;
  productCategories?: ProductCategory[];
}

@Component({
  selector: 'bakery-mgmt-store-settings',
  template: `
    <alert-container></alert-container>
    <ng-template [ngIf]="(uiState$|async).isLoading">
      <loading-indicator></loading-indicator>
    </ng-template>
    <ng-template [ngIf]="!(uiState$|async).isLoading">
      <edit-caution-alert
        [visible$]="editCautionVisible$"
        message="You have made changes to the store settings. Please click Save to persist your changes."
      ></edit-caution-alert>
      <div class="row">
        <div class="columns-4__l">
          <bakery-store-edit-image
            [uiState$]="uiState$"
            [reset$]="resetForm$"
            (formState)="imageFormState$.next($event)"
          ></bakery-store-edit-image>
        </div>
        <div class="columns-8__l">
          <bakery-store-edit-description
            [uiState$]="uiState$"
            [reset$]="resetForm$"
            (formState)="descriptionFormState$.next($event)"
          ></bakery-store-edit-description>
          <div class="row">
            <div class="columns-9__m columns-12__l margin-bottom--delta float--right">
              <a (click)="resetForm$.next()" class="link--secondary margin-right--delta link--disabled" [ngClass]="{'link--disabled': !(formState$|async).isDirty, 'clickable': (formState$|async).isDirty }">
                Cancel
              </a>
              <button (click)="submitForm$.next()" type="submit" class="button--primary button--input" [ngClass]="{'button--disabled': !(formState$|async).isSubmittable}">
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  `
})
export class BakeryMgmtStoreMainSettingsComponent implements OnInit {
  uiState$: Rx.Observable<UiState>;
  editCautionVisible$ = Rx.Observable.of(false);
  formState$: Rx.Observable<SubmittableFormState>;
  resetForm$ = new Rx.Subject<any>();
  imageFormState$ = new Rx.Subject<SimpleFormState>();
  descriptionFormState$ = new Rx.Subject<SimpleFormState>();
  submitForm$ = new Rx.Subject<any>();

  private submitFormSub: Rx.Subscription;
  private alertsSub: Rx.Subscription;

  constructor(
    private bkryMgmtEffects: BakeryMgmtEffects,
    private bkryMgmtProdCatEffects: BakeryMgmtProductCategoriesEffects,
    private store: Store<AppState>,
    private actions$: NgRxActions,
    private alertService: AlertService,
  ) {
    this.uiState$ = Rx.Observable
      .combineLatest(
        this.bkryMgmtEffects.currentBakery$,
        this.store.select('entitiesState'),
        (bakery, entitiesState) => ({bakery, entitiesState})
      )
      .map((combined): UiState => {
        const bakery = <Bakery>combined.bakery;
        const entitiesState = <EntitiesState>combined.entitiesState;

        if (bakery == null) {
          return {
            isLoading: true,
          };
        }

        const productCategoriesArray = Object.keys(entitiesState.product_categories)
          .map(key => entitiesState.product_categories[key]);

        return {
          isLoading: false,
          bakery: bakery,
          productCategories: productCategoriesArray,
        };
      })
      .startWith({isLoading: true})
      .shareReplay(1);

    this.formState$ = Rx.Observable
      .combineLatest(
        this.imageFormState$
          .startWith({isDirty: false, isValid: true, value: {}}),
        this.descriptionFormState$
          .startWith({isDirty: false, isValid: true, value: {}}),
      )
      .map((simpleFormStates: SimpleFormState[]): SubmittableFormState => {
          const isDirty = simpleFormStates.some(formState => formState.isDirty);
          const isValid = simpleFormStates.every(formState => formState.isValid);
          const values = simpleFormStates.map(formState => formState.value);

          return {
            isValid: isValid,
            isDirty: isDirty,
            isSubmittable: isDirty && isValid,
            value: Object.assign({}, ...values)
          };
        }
      )
      .startWith({isDirty: false, isValid: false, isSubmittable: false, value: {}})
      .shareReplay(1);

    this.submitFormSub = this.submitForm$
      .switchMap(() => this.formState$.take(1))
      .filter((submittableFormState: SubmittableFormState) => submittableFormState.isSubmittable)
      .map((submittableFormState: SubmittableFormState) => submittableFormState.value)
      .subscribe((formValue) => {
        this.bkryMgmtEffects.requestUpdateOwnBakery(
          formValue,
        );
      });

    this.alertsSub = this.actions$
      .subscribe((action: UnsafeAction) => {
        let messageType = null,
            messageContent = null;

        switch (action.type) {
          case Actions.REQUEST_UPDATE_OWN_BAKERY_SUCCESS:
            messageType = 'success';
            messageContent = `Your store details have been updated.`;
            break;

          case Actions.REQUEST_UPDATE_OWN_BAKERY_ERROR:
            messageType = 'warning';
            messageContent = action.payload.error || 'Something went wrong!';
            break;
        }

        if (messageType != null && messageContent != null) {
          this.alertService[messageType](messageContent);
        }
      });
  }

  ngOnInit() {
    this.bkryMgmtProdCatEffects.requestGetProductCategories();
  }

  ngOnDestroy() {
    this.submitFormSub.unsubscribe();
    this.alertsSub.unsubscribe();
  }
}
