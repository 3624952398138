import { Injectable, Inject } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

import { AppState } from 'app/store/app-state';
import { AuthState } from 'app/store/auth-state';
import { UsersEffects } from 'app/store/effects/users.effects';
import { User } from 'app/store/user';
import { hasRole } from 'app/store/users/utils';
import { DOCUMENT } from '@angular/common';

declare var process: any;

interface CombinedAuthState {
  isAuthenticationComplete: boolean;
  isLoggedIn: boolean;
  currentUser?: User;
}

@Injectable()
export class BakeryRouteGuard implements CanActivate, CanActivateChild {
  comboAuth$: Observable<CombinedAuthState>;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private usersEffects: UsersEffects,
    @Inject(DOCUMENT) private document: any,
  ) {
    this.comboAuth$ = this.store.select('authState')
      .switchMap((authState: AuthState) => {
        if (!authState.isLoggedIn) {
          return Observable.of({
            isAuthenticationComplete: authState.isAuthenticationComplete,
            isLoggedIn: authState.isLoggedIn,
          })
        }

        return this.usersEffects.currentUser$
          .map((currentUser: User) => {
            return {
              isAuthenticationComplete: authState.isAuthenticationComplete,
              isLoggedIn: authState.isLoggedIn,
              currentUser: currentUser,
            }
          })
      });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.onCanActivateRequest(route, state);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.onCanActivateRequest(route, state);
  }

  private onCanActivateRequest(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.store
      .filter(appState => !appState.bakeryCustUiState.isLoading)
      .switchMap((appState: AppState) => {
        const bakeryId = appState.bakeryCustUiState.bakeryId;
        const bakery  = appState.entitiesState.bakeries[bakeryId];

        if (bakery && bakery.account_status === 'paused'){
          this.document.location.href = process.env.WEB_URL + '/404?isCustomer';
          return Observable.of(false);
        }

        if (bakery != null) {
          // Bakery found, allow navigation.
          return Observable.of(true);
        }

        // Bakery can't be found? Bounce to b2o/generic 404 page.
        this.document.location.href = process.env.WEB_URL + '/404?isCustomer';

        return Observable.of(false);
      })
      .first();
  }
}
