import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

import { AppState } from 'app/store/app-state';
import { AuthState } from 'app/store/auth-state';
import { UsersEffects } from 'app/store/effects/users.effects';
import { User } from 'app/store/user';
import { hasRole } from 'app/store/users/utils';

export interface CombinedAuthState {
  isAuthenticationComplete: boolean;
  isLoggedIn: boolean;
  currentUser?: User;
}

@Injectable()
export class RoleRouteGuard implements CanActivate, CanActivateChild {
  comboAuth$: Observable<CombinedAuthState>;
  authEvents: Subscription;

  constructor(
    protected store: Store<AppState>,
    protected router: Router,
    protected usersEffects: UsersEffects,
  ) {
    this.comboAuth$ = this.store.select('authState')
      .switchMap((authState: AuthState) => {
        if (!authState.isLoggedIn) {
          return Observable.of({
            isAuthenticationComplete: authState.isAuthenticationComplete,
            isLoggedIn: authState.isLoggedIn,
          })
        }

        return this.usersEffects.currentUser$
          .map((currentUser: User) => {
            return {
              isAuthenticationComplete: authState.isAuthenticationComplete,
              isLoggedIn: authState.isLoggedIn,
              currentUser: currentUser,
            }
          })
      });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.onCanActivateRequest(route, state);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.onCanActivateRequest(route, state);
  }

  protected onCanActivateRequest(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.comboAuth$
      .filter((comboAuthState: CombinedAuthState) => comboAuthState.isAuthenticationComplete)
      .switchMap((comboAuthState: CombinedAuthState) => {
        let isAllowed = comboAuthState.isLoggedIn;

        if (route.data.allowableRoles != null) {
          isAllowed = hasRole(comboAuthState.currentUser, ...route.data.allowableRoles);
        }

        if (!isAllowed) {
          let signInUrl = '/sign_in';

          if (route.data.signInUrl) {
            signInUrl = route.data.signInUrl;
          }

          this.router.navigate([signInUrl]);
        }

        if (isAllowed && this.accountPaused(comboAuthState, state)) {
          this.router.navigate(['/sign_in']);
        }

        return Observable.of(isAllowed);
      })
      .first();
  }

  private accountPaused(comboAuthState: CombinedAuthState, state: RouterStateSnapshot): boolean {
    return comboAuthState.currentUser.bakery_account_status === 'paused' && state.url !== '/subscription';
  }
}
