import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ElementRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';

import { GenericControlValueInput } from 'app/shared/components/form/generic-control-value-input';
import { buildForwardRef } from 'app/shared/components/form/input-forward-ref-builder';

interface Base64FileDetails {
  encoded: string;
  mimeType: string;
}

@Component({
  selector: 'chip-image-upload-input',
  template: require('./chip-image-upload-input.component.html'),
  providers: buildForwardRef(ChipImageUploadInputComponent),
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChipImageUploadInputComponent extends GenericControlValueInput<string> implements OnInit {
  @Input() fallbackName = '??';
  @Input() imageInputId = 'imageUploadInput';
  @ViewChild('fileForm') fileFormElement: ElementRef;

  value: string = null;

  private tempAvatar: Base64FileDetails;

  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() { }

  writeValue(val: string) {
    this.value = val;
  }

  get hasImage(): boolean {
    return this.value != null;
  }

  get imageUrl(): string {
    return this.value;
  }

  onClickRemoveImage() {
    this.value = null;
    this.onChangedCallback(this.value);
  }

  onChangeFileUpload($event) {
    const file = $event.target.files[0];
    const fileReader = new FileReader();
    let fileBinResults = null;
    fileReader.onload = (readerEvent) => {
      fileBinResults = (readerEvent.target as any).result;

      const encoded = btoa(fileBinResults),
      mimeType = file.type;

      this.value = `data:${mimeType};base64,${encoded}`;
      this.onChangedCallback(this.value);

      // Notify angular's change detection that the input/component value has
      // been updated. This won't happen automagically because this operation
      // is taking place within a DOM element callback.
      this.changeDetectorRef.markForCheck();
    };

    fileReader.readAsBinaryString(file);
  }

  private resetFileForm() {
    this.fileFormElement.nativeElement.reset();
  }
}
