import { combineReducers } from '@ngrx/store';

import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { Actions } from 'app/store/actions';

interface FulfillmentLocation {
  name: string;
  street: string;
  city: string;
  state: string;
  zip: string;
}

interface Invoice {
  balance: number;
}

interface Parent {
  name: string;
  avatar?: string;
}

interface Variant {
  bakery_product_attribute: { visible_name: string };
  bakery_attribute_variant: { name: string; };
}

interface OrderItem {
  bakery_product: { name: string; };
  bakery_product_template: { name: string; };
  meta?: {
    attribute_variants: Variant[];
    template_variants: Variant[];
    bakery_product: { name: string; };
    bakery_product_template: { name: string; };
    inscription?: string
  };
  open_charge: number;
  open_charge_description?: string;
  quantity: number;
}

interface BakeryOrder {
  created_at: string;
  fulfillment_at: string;
  fulfillment_at_raw: string;
  fulfillment_on: string;
  fulfillment_type: string;
  id: number;
  order_customer_email: string;
  order_customer_name: string;
  order_customer_phone_number: string;
  order_customer_type: string;
  order_number: number;
  order_status: string;
  total_price: number;
  fulfillment_location: FulfillmentLocation;
  invoice?: Invoice;
  parent: Parent;
  order_items: OrderItem[];
}

export type State = BakeryOrder[];

const INITIAL_STATE = [];

export function reducer(state: State = INITIAL_STATE, action: UnsafeAction): State {
  switch (action.type) {
    case Actions.REQUEST_GET_BKRY_MGMT_ORDER_MGMT_ORDERS_SUCCESS2:
      return action.payload;

    case Actions.REQUEST_GET_BKRY_MGMT_ORDER_MGMT_ORDERS_ERROR2:
      return state;

    default:
      return state;
  }
}
