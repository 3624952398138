import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import './polyfills';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import './styles.scss';

if (environment.production) {
  enableProdMode();
}

console.log({environment})

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic().bootstrapModule(AppModule);
});
