import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { Store } from '@ngrx/store';
import { Angular2TokenService } from 'app/angular2-token/angular2-token.service';
import { Actions as NgRxActions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';
import { normalize, schema } from 'normalizr';
import { LocalStorageService } from 'ngx-webstorage';

import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { AlertAction } from 'app/shared/components/alerts/actions';
import { AppState } from 'app/store/app-state';
import { AuthState } from 'app/store/auth-state';
import { Actions } from 'app/store/actions';
import { Bakery } from 'app/store/bakery';
import { BakeryCart } from 'app/store/bakery-cart';
import { OrderItem } from 'app/store/order-item';
import { EntitiesState } from 'app/store/entities-state';
import { deepBakerySchema } from 'app/store/schema/bakery';
import { bakeryCartSchema, bakeryOrderSchema, userSchema } from 'app/store/schema/default-schemata';
import { addEntityRequestHandler, addResultEntityRequestHandler } from 'app/store/effects/helpers';
import { CartState } from 'app/store/reducers/bakery-cust-ui-state-reducer';
import { User } from 'app/store/user';
import { BakeryOrder } from 'app/store/bakery-order';
import { UsersEffects } from 'app/store/effects/users.effects';
import * as selectors from 'app/store/selectors';

@Injectable()
export class BakeryEmplCartOrderEffects {

  constructor(
    private actions$: NgRxActions,
    private tokenService: Angular2TokenService,
    private store: Store<AppState>,
    private usersEffects: UsersEffects,
    private localStore: LocalStorageService,
  ) {
    // Set employee cart ID when we've successfully retrieved or created a cart.
    this.actions$
      .ofType(
        Actions.ADD_CUSTOMER_TO_EMPLOYEE_CART_SUCCESS,
        Actions.REQUEST_CREATE_EMPLOYEE_CART_SUCCESS,
        Actions.REQUEST_GET_EMPLOYEE_CART_SUCCESS
      )
      .subscribe((action: UnsafeAction) => {
        this.localStore.store('emplCartId', Object.keys(action.payload.bakery_carts).shift());
      });

    // Clear the employee cart ID if there's an error in creating or retrieving the cart.
    this.actions$
      .ofType(
        Actions.ADD_CUSTOMER_TO_EMPLOYEE_CART_ERROR,
        Actions.REQUEST_CREATE_EMPLOYEE_CART_ERROR,
        Actions.REQUEST_GET_EMPLOYEE_CART_ERROR
      )
      .subscribe(action => {
        this.localStore.clear('emplCartId');
      });

    this.actions$
      .ofType(Actions.REQUEST_CREATE_EMPLOYEE_BAKERY_ORDER_SUCCESS)
      .subscribe(action => {
        this.usersEffects.requestValidateToken();
      });
  }

  @Effect() getEmplCart$ = this.actions$
    .ofType(Actions.REQUEST_GET_EMPLOYEE_CART)
    .withLatestFrom(
      this.store.select(selectors.getBakeryMgmtCurrentBakeryId),
      (action: UnsafeAction, bakeryId) => ({action, bakeryId})
    )
    .switchMap(({action, bakeryId}) => {
      if (action.payload != null) {
        return addEntityRequestHandler(
          this.tokenService.get(
            `/api/bakeries/${bakeryId}/carts/${action.payload}`
          ),
          bakeryCartSchema,
          Actions.REQUEST_GET_EMPLOYEE_CART
        );
      } else {
        return addEntityRequestHandler(
          this.tokenService.get(
            `/api/me/cart`
          ),
          bakeryCartSchema,
          Actions.REQUEST_GET_EMPLOYEE_CART
        );
      }
    })
    .share();

  @Effect() addCustomerToCartEffects$ = this.actions$
    .ofType(Actions.ADD_CUSTOMER_TO_EMPLOYEE_CART)
    .withLatestFrom(
      this.store.select(selectors.getBakeryMgmtCurrentBakeryId),
      this.store.select(selectors.getEmployeeCartState),
      (action: UnsafeAction, bakeryId, cartState) => ({action, bakeryId, cartState})
    )
    .switchMap(({action, bakeryId, cartState}) => {
      if ((<any>cartState).result == null) {
        return addEntityRequestHandler(
          this.tokenService.post(
            `/api/bakeries/${bakeryId}/carts`,
            JSON.stringify(action.payload),
          ),
          bakeryCartSchema,
          Actions.ADD_CUSTOMER_TO_EMPLOYEE_CART,
        );
      } else {
        return addEntityRequestHandler(
          this.tokenService.put(
            `/api/bakeries/${bakeryId}/carts/${(<any>cartState).result.id}`,
            JSON.stringify(action.payload),
          ),
          bakeryCartSchema,
          Actions.ADD_CUSTOMER_TO_EMPLOYEE_CART,
        );
      }
    })
    .share();

  @Effect() addProductTemplateToCartEffects$ = this.actions$
    .ofType(Actions.ADD_PRODUCT_TEMPLATE_TO_EMPLOYEE_CART)
    .withLatestFrom(
      this.store.select(selectors.getBakeryMgmtCurrentBakeryId),
      this.store.select(selectors.getEmployeeCartState),
      (action: UnsafeAction, bakeryId, cartState) => ({action, bakeryId, cartState})
    )
    .switchMap(({action, bakeryId, cartState}) => {
      if ((<any>cartState).result == null) {
        return addEntityRequestHandler(
          this.tokenService.post(
            `/api/bakeries/${bakeryId}/carts`,
            JSON.stringify(action.payload),
          ),
          bakeryCartSchema,
          Actions.REQUEST_CREATE_EMPLOYEE_CART,
        );
      } else {
        return addEntityRequestHandler(
          this.tokenService.put(
            `/api/bakeries/${bakeryId}/carts/${(<any>cartState).result.id}`,
            JSON.stringify(action.payload),
          ),
          bakeryCartSchema,
          Actions.REQUEST_UPDATE_EMPLOYEE_CART,
        );
      }
    })
    .share();

  @Effect() removeProductTemplateFromCartEffects$ = this.actions$
    .ofType(Actions.REQUEST_UPDATE_EMPLOYEE_CART)
    .withLatestFrom(
      this.store.select(selectors.getBakeryMgmtCurrentBakeryId),
      this.store.select(selectors.getEmployeeCartState),
      (action: UnsafeAction, bakeryId, cartState) => ({action, bakeryId, cartState})
    )
    .switchMap(({action, bakeryId, cartState}) => {
      return addEntityRequestHandler(
        this.tokenService.put(
          `/api/bakeries/${bakeryId}/carts/${(<any>cartState).result.id}`,
          JSON.stringify(action.payload),
        ),
        bakeryCartSchema,
        Actions.REQUEST_UPDATE_EMPLOYEE_CART,
      );
    })
    .share();

  @Effect() deleteCartEffects$ = this.actions$
    .ofType(Actions.REQUEST_DELETE_EMPLOYEE_CART)
    .withLatestFrom(
      this.store.select(selectors.getBakeryMgmtCurrentBakeryId),
      this.store.select(selectors.getEmployeeCartState),
      (action, bakeryId, cartState) => ({action, bakeryId, cartState})
    )
    .switchMap(({action, bakeryId, cartState}) => {
      if (!(<any>cartState).result) return [];
      return this.tokenService.delete(`/api/bakeries/${bakeryId}/carts/${(<any>cartState).result.id}`)
        .map((response: Response) => response.json())
        .switchMap((entities) => {
          return Observable.from([
            {
              type: Actions.REMOVE_ENTITY,
              payload: {
                typeKey: 'bakery_carts',
                entityKey: (<any>cartState).result.id
              },
            },
            {
              type: Actions.REQUEST_DELETE_EMPLOYEE_CART_SUCCESS,
            },
          ]);
        });
    })
    .catch(error => {
      console.log(error)
      return Observable.throw(error);
    })
    .do(val => console.log(`delete cust val:`, val))
    .share();

  @Effect() emplBakeryOrderCreateEffects$ = this.actions$
    .ofType(Actions.INIT_EMPLOYEE_BAKERY_ORDER)
    .withLatestFrom(
      this.store.select(selectors.getEmployeeCart),
      this.store.select(selectors.getCurrentBkryMgmtBakery)
    )
    .switchMap(([_action, cart, currentBakery]: [UnsafeAction, BakeryCart, Bakery]) => {
      return Observable.of({
        type: Actions.SET_EMPLOYEE_BAKERY_ORDER,
        payload: { cart, currentBakery },
      });
    })
    .catch(error => {
      return Observable.throw(error);
    })
    .share();

  @Effect() removeCreateEmplBakeryOrderEffects$ = this.actions$
    .ofType(Actions.REQUEST_CREATE_EMPLOYEE_BAKERY_ORDER)
    .withLatestFrom(
      this.store.select(selectors.getEmployeeCart),
      this.store.select(selectors.getEmployeeOrder),
      this.store.select(selectors.getCurrentUser),
      (action: UnsafeAction, cart, checkoutBakeryOrderForm, currentUser) => ({action, cart, checkoutBakeryOrderForm, currentUser})
    )
    .switchMap(({action, cart, checkoutBakeryOrderForm, currentUser}) => {
      // Basics
      const bakeryOrder: any = {
        order_type: checkoutBakeryOrderForm.order_type,
        schedule_interval: checkoutBakeryOrderForm.schedule_interval,
        wholesaler: action.payload.wholesaler,
        bakery_id: cart.bakery_id,
        bakery_cart_id: cart.id,
        order_customer_id: checkoutBakeryOrderForm.order_customer_id,
        order_customer_email: checkoutBakeryOrderForm.order_customer_email,
        order_customer_name: checkoutBakeryOrderForm.order_customer_name,
        order_customer_first_name: checkoutBakeryOrderForm.order_customer_first_name,
        order_customer_last_name: checkoutBakeryOrderForm.order_customer_last_name,
        order_customer_phone_number: checkoutBakeryOrderForm.order_customer_phone_number,
        order_customer_notes: checkoutBakeryOrderForm.order_customer_notes,
        attached_images_attributes: checkoutBakeryOrderForm.attached_images_attributes
      };

      // Fulfillment.
      const fulfillmentData = checkoutBakeryOrderForm.form_fulfillment_data;
      bakeryOrder.fulfillment_type = fulfillmentData.fulfillment_type;
      bakeryOrder.fulfillment_date = fulfillmentData.fulfillment_date;
      bakeryOrder.fulfillment_at = fulfillmentData.fulfillment_at;
      bakeryOrder.fulfillment_on = [
        fulfillmentData.fulfillment_date.getFullYear(),
        fulfillmentData.fulfillment_date.getMonth() + 1,
        fulfillmentData.fulfillment_date.getDate()
      ].join("-");
      bakeryOrder.fulfillment_location_type = fulfillmentData.fulfillment_location_type;
      if (fulfillmentData.fulfillment_location_id === 'add_new') {
        bakeryOrder.fulfillment_location_attributes = {
          ...fulfillmentData.fulfillment_address
        };
      } else {
        bakeryOrder.fulfillment_location_id = fulfillmentData.fulfillment_location_id;
      }

      // Payment.
      bakeryOrder.payment_card_attributes = {
        payment_type: checkoutBakeryOrderForm.payment_type,
        amount: checkoutBakeryOrderForm.amount,
        notes: checkoutBakeryOrderForm.notes
      };

      if (checkoutBakeryOrderForm.payment_type === 'credit_card') {
        if (
          checkoutBakeryOrderForm.payment_card_selection === 'add_new' ||
          (checkoutBakeryOrderForm.payment_card_selection == null && checkoutBakeryOrderForm.stripe_token != null)
        ) {
          bakeryOrder.payment_card_attributes.stripe_token = checkoutBakeryOrderForm.stripe_token;
        } else {
          bakeryOrder.payment_card_attributes.id = checkoutBakeryOrderForm.payment_card_selection;
        }
      } else {
        bakeryOrder.payment_card_attributes.bakery_employee_id = currentUser.id;
      }

      return addResultEntityRequestHandler(
        this.tokenService.post(
          `/api/bakeries/${bakeryOrder.bakery_id}/employees/orders`,
          JSON.stringify(bakeryOrder),
        ),
        bakeryOrderSchema,
        Actions.REQUEST_CREATE_EMPLOYEE_BAKERY_ORDER,
      );
    })
    .share();

  @Effect() calculateTaxesForBakery$ = this.actions$
    .ofType(
      Actions.UPDATE_EMPLOYEE_BAKERY_ORDER
    )
    .withLatestFrom(
      this.store.select(selectors.getEmployeeCart),
      this.store.select(selectors.getEmployeeOrder),
      this.store.select(selectors.getBakeryLocations),
      this.store.select(selectors.selectNewOrderCustomers)
    )
    .filter(([_action, _cart, order, _bakeryLocations, _customers]) => !order.wholesaler_order)
    .filter(([_action, _cart, order, _bakeryLocations, _customers]) => {
      return ['BakeryLocation', 'Address'].includes(order.form_fulfillment_data.fulfillment_location_type);
    })
    .map(([_action, cart, order, bakeryLocations, customers]) => {
      const fulfillmentData = order.form_fulfillment_data;
      const bakeryLocationId = fulfillmentData.fulfillment_location_type === 'BakeryLocation' ?
        fulfillmentData.fulfillment_location_id : null;
      const address = () => {
        const customer = customers.find(x => x.id === order.order_customer_id);
        if (!customer) return {};
        return [customer.default_address]
          .filter(x => x)
          .find(x => x.id === fulfillmentData.fulfillment_location_id) || {};
      }
      const state = fulfillmentData.fulfillment_location_type === 'Address' ?
        (fulfillmentData.fulfillment_location_id === 'add_new'
          ? fulfillmentData.fulfillment_address.state
          : address().state
        ) : null;
      return {
        fulfillment_location_type: fulfillmentData.fulfillment_location_type,
        cart_id: cart.id,
        bakery_id: cart.bakery_id,
        bakery_location_id: bakeryLocationId,
        state,
      };
    })
    .filter(x => {
      if(x.fulfillment_location_type === 'BakeryLocation') {
        return !!x.bakery_location_id;
      } else if (x.fulfillment_location_type === 'Address') {
        return !!x.state;
      } else {
        return false;
      }
    })
    .switchMap(x => Observable.of({ type: Actions.REQUEST_TAXES_FOR_BAKERY, payload: x }));

  @Effect() taxEstimabilityWatchV2Effects$ = this.actions$
    .ofType(Actions.REQUEST_TAXES_FOR_BAKERY)
    .switchMap((action: UnsafeAction) => {
      return this.tokenService
        .post(`/api/bakeries/${action.payload.bakery_id}/carts/${action.payload.cart_id}/taxes`, JSON.stringify(action.payload))
        .map((response: Response) => response.json())
        .switchMap(response => {
          return Observable.of({
            type: Actions.REQUEST_TAXES_FOR_BAKERY_SUCCESS,
            payload: response,
          });
        })
        .catch((error) => {
          const message = (error.json && error.json().error) || 'Something went wrong';
          const action = { type: Actions.REQUEST_TAXES_FOR_BAKERY_ERROR, payload: message };
          return Observable.from([AlertAction.buildWarning(message), action]);
        });
    })
    .share();

  @Effect() requestAddPromoCode$ = this.actions$
    .ofType(Actions.REQUEST_ADD_PROMO_CODE_FOR_BAKERY)
    .withLatestFrom(
      this.store.select(selectors.getBakeryMgmtCurrentBakeryId),
      this.store.select(selectors.getEmployeeCartState)
    )
    .switchMap(([action, bakeryId, cartState]: [UnsafeAction, number, CartState]) => {
      return addEntityRequestHandler(
        this.tokenService.post(`/api/bakeries/${bakeryId}/carts/${cartState.result.id}/promo_codes`, action.payload),
        bakeryCartSchema,
        Actions.REQUEST_ADD_PROMO_CODE_FOR_BAKERY
      )
    })
    .share();

  @Effect() requestRemovePromoCode$ = this.actions$
    .ofType(Actions.REQUEST_REMOVE_PROMO_CODE_FOR_BAKERY)
    .withLatestFrom(
      this.store.select(selectors.getBakeryMgmtCurrentBakeryId),
      this.store.select(selectors.getEmployeeCartState)
    )
    .switchMap(([action, bakeryId, cartState]: [UnsafeAction, number, CartState]) => {
      return addEntityRequestHandler(
        this.tokenService.delete(`/api/bakeries/${bakeryId}/carts/${cartState.result.id}/promo_codes`),
        bakeryCartSchema,
        Actions.REQUEST_REMOVE_PROMO_CODE_FOR_BAKERY
      )
    })
    .share();

  @Effect() updateBakeryOrder$ = this.actions$
    .ofType(
      Actions.INIT_EMPLOYEE_BAKERY_ORDER,
      Actions.ADD_CUSTOMER_TO_EMPLOYEE_CART_SUCCESS, // in case of wholesaler discounts
      Actions.REQUEST_ADD_PROMO_CODE_FOR_BAKERY_SUCCESS,
      Actions.REQUEST_REMOVE_PROMO_CODE_FOR_BAKERY_SUCCESS
    )
    .withLatestFrom(
      this.store.select(selectors.getEmployeeCart).filter(x => !!x),
      this.store.select(selectors.getEmployeeOrder),
    )
    .switchMap(([_action, cart, employeeOrder]: [UnsafeAction, BakeryCart, BakeryOrder]) => {
      if (!employeeOrder) { return Observable.of({ type: Actions.NO_OP }); }
      const bakeryOrder = {
        ...employeeOrder,
        order_items: cart.order_items,
        total_discount_price: cart.total_discount_price,
        total_price: cart.total_price
        }
      return Observable.of({
        type: Actions.UPDATE_EMPLOYEE_BAKERY_ORDER,
        payload: bakeryOrder,
      });
    })
    .catch(error => {
      return Observable.throw(error);
    })
    .share();
}
