import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

import { AssociationListComponent } from './association-list.component';

@Component({
  selector: 'mobile-association-list',
  template: `
    <ng-container [ngSwitch]="list.length">
      <label>{{ label }}</label>
      <p *ngSwitchCase="0">{{ emptyName }}</p>
      <p *ngSwitchCase="1">{{ list[0][listNameKey] }}</p>
      <p *ngSwitchDefault>{{ list.length}} {{ countLabel }}</p>
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileAssociationListComponent extends AssociationListComponent {
}
