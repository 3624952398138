import {
  Component,
  OnInit,
  OnDestroy,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { Subject } from 'rxjs/Subject';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { BakeryAttribute } from 'app/store/bakery-attribute';
import { BakeryAttributeVariant } from 'app/store/bakery-attribute-variant';
import { UiState } from '../detail.component';
import { SimpleFormState } from 'app/shared/forms/form-states';
import uuidv4 from 'uuid/v4';
import { validateNoWhitespaces } from 'app/shared/forms/no-whitespaces-validator';

@Component({
  selector: 'bakery-mgmt-product-attribute-variant-associations',
  template: require('./variant-associations.component.html')
})
export class BakeryMgmtProductAttributeVariantAssociationsComponent
  implements OnInit, OnDestroy {
  @Input('uiState$') uiState$: Observable<UiState>;
  @Input('success$') success$: Observable<any>;
  @Input('reset$') reset$: Observable<any>;
  @Output('formState') formState$ = new Subject<SimpleFormState>();

  form: FormGroup;
  selectedAssociations$ = new BehaviorSubject<BakeryAttributeVariant[]>([]);

  public isEditingAssociation = false;
  public formSubmit$ = new Subject<any>();
  private filteredProdAtt$: Observable<BakeryAttribute>;
  private formStateSub: Subscription;
  private formSubmitSub: Subscription;
  private selectedAssociationsSub: Subscription;

  constructor() {
    this.form = new FormGroup({
      id: new FormControl(null),
      temp_id: new FormControl(''),
      name: new FormControl('', [Validators.required, validateNoWhitespaces]),
      price: new FormControl('', [Validators.required])
    });
  }

  ngOnInit() {
    this.selectedAssociationsSub = Observable.merge(
      this.uiState$,
      this.reset$.switchMap(() => this.uiState$.take(1))
    )
      .filter((uiState: UiState) => !uiState.isLoading)
      .subscribe((uiState: UiState) => {
        this.selectedAssociations$.next(<BakeryAttributeVariant[]>uiState
          .bakeryAttribute.bakery_attribute_variants);
      });

    this.formSubmitSub = this.formSubmit$
      .withLatestFrom(
        this.form.valueChanges,
        this.uiState$.filter((uiState: UiState) => !uiState.isLoading),
        (_, formValue, uiState) => ({ _, formValue, uiState })
      )
      .subscribe(({ formValue, uiState }) => {
        if (formValue.name === '' || formValue.price === '') return null;
        this.form.reset({ id: '', temp_id: '', name: '', price: null });
        this.isEditingAssociation = false;
        let associations;

        if (!formValue.id && formValue.temp_id === '') {
          const bpa = {
            id: formValue.id,
            temp_id: uuidv4(),
            name: formValue.name,
            price: formValue.price
          };
          associations = <BakeryAttributeVariant[]>[
            ...this.selectedAssociations$.value,
            bpa
          ];
        } else {
          associations = this.selectedAssociations$.value.map(variant => {
            if (
              (variant.id && variant.id === formValue.id) ||
              (formValue.temp_id !== '' &&
                (<any>variant).temp_id === formValue.temp_id)
            ) {
              return {
                ...formValue,
                price: formValue.price
              };
            } else {
              return variant;
            }
          });
        }

        this.selectedAssociations$.next(
          associations.sort((a, b): any => a.name > b.name)
        );
      });

    this.formStateSub = Observable.combineLatest(
      this.selectedAssociations$,
      this.uiState$.filter((uiState: UiState) => !uiState.isLoading),
      (selectedAssociations, uiState) => ({ selectedAssociations, uiState })
    )
      .map(({ selectedAssociations, uiState }) => {
        const existingAssociations = <BakeryAttributeVariant[]>uiState
          .bakeryAttribute.bakery_attribute_variants;

        const newAssociations = selectedAssociations.filter(selectedAssoc => {
          const match = existingAssociations.find(existingAssoc => {
            return (
              existingAssoc.price === selectedAssoc.price &&
              existingAssoc.name === selectedAssoc.name &&
              (<BakeryAttributeVariant>existingAssoc).id ===
                (<BakeryAttributeVariant>selectedAssoc).id
            );
          });

          return match == null;
        });

        const removedAssociations = existingAssociations.filter(
          existingAssoc => {
            const match = selectedAssociations.find(selectedAssoc => {
              return (
                (<BakeryAttributeVariant>existingAssoc).id ===
                (<BakeryAttributeVariant>selectedAssoc).id
              );
            });

            return match == null;
          }
        );

        return { newAssociations, removedAssociations };
      })
      .map(({ newAssociations, removedAssociations }) => {
        const preppedRemovedAssocs = removedAssociations.map(assoc => {
          return Object.assign({}, assoc, { _destroy: true });
        });

        const variantsValueArray = [...newAssociations, ...preppedRemovedAssocs];

        return {
          isValid: true, // this form is always valid
          isDirty: !!newAssociations.length || !!removedAssociations.length,
          value: {
            bakery_attribute_variants_attributes: variantsValueArray
          }
        };
      })
      .subscribe(val => this.formState$.next(val));
  }

  onClickRemoveAssociation(
    bakeryAttributeVariantId: number,
    bakeryAttributeVariantTempId: string
  ) {
    const filterId = bakeryAttributeVariantId || bakeryAttributeVariantTempId;
    const selectedAssociations = this.selectedAssociations$.value;
    this.selectedAssociations$.next(
      selectedAssociations.filter(bpa => {
        return !(
          (<BakeryAttributeVariant>bpa).id === filterId ||
          (<any>bpa).temp_id === filterId
        );
      })
    );
  }

  onClickEditAssociation(bakeryAttributeVariant: any) {
    this.isEditingAssociation = true;
    this.form.patchValue({
      ...bakeryAttributeVariant,
      price: bakeryAttributeVariant.price
    });
  }

  onClickCancelEditAssociation() {
    this.isEditingAssociation = false;
    this.form.reset({ id: '', temp_id: '', name: '', price: '' });
  }

  onClickFormSubmit() {
    this.formSubmit$.next();
  }

  ngOnDestroy() {
    this.formStateSub.unsubscribe();
    this.formSubmitSub.unsubscribe();
    this.selectedAssociationsSub.unsubscribe();
  }
}
