import { Component, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';
import * as Rx from 'rxjs';
import { SimplifiedResourceRequestState } from 'app/store/reducers/request-states';

@Component({
  selector: 'loading-container',
  template: `
    <loading-indicator *ngIf="(isLoading$ | async)"></loading-indicator>
    <ng-content *ngIf="!(isLoading$ | async)"></ng-content>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingContainerComponent implements OnInit {
  @Input('state') state$: Rx.Observable<SimplifiedResourceRequestState<any>>;
  @Input('isLoading')
  set isLoading(isLoading: boolean) {
    this.inputIsLoading$.next(isLoading);
  }

  isLoading$: Rx.Observable<boolean>;

  private inputIsLoading$ = new Rx.Subject<boolean>();

  ngOnInit() {
    // If state input observable is null, then replace state with an empty observable.
    if (this.state$ == null) {
      this.state$ = Rx.Observable.of();
    }

    this.isLoading$ = Rx.Observable
      .merge(
        this.state$
          .map((state: SimplifiedResourceRequestState<any>) => {
            if (state == null) return false;

            return state.isLoading === true;
          }),
        this.inputIsLoading$
      )
      .startWith(true);
  }
}
