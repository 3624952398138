import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { AppState } from 'app/store/app-state';
import { Actions } from 'app/store/actions';
import * as selectors from 'app/store/selectors';

@Component({
  template: require('./dashboard.component.html'),
})
export class DashboardComponent implements OnInit {
  state$ = this.store.pluck('bakeryMgmtUiState', 'dashboardState');

  private safeResult$ = this.store.pluck('bakeryMgmtUiState', 'dashboardState', 'result')
    .filter(val => !!val);

  orderStats$ = this.safeResult$
    .pluck('order_stats')
    .startWith({today_count: 0, tomorrow_count: 0, week_count: 0});

  upcomingOrders$ = this.safeResult$
    .pluck('upcoming_orders')
    .startWith([]);

  storeSetupStatus$ = this.safeResult$
    .pluck('store_setup_status')
    .startWith({});

  constructor(
    private store: Store<AppState>,
  ) { }

  ngOnInit() {
    this.store.dispatch({
      type: Actions.REQUEST_GET_BKRY_MGMT_DASHBOARD,
    });
  }
}
