import { Routes } from '@angular/router';

import { BakeryRouteGuard } from 'app/bakery-cust/bakery-route-guard.service';
import { RoleRouteGuard } from 'app/shared/role-route-guard.service';

import { BakeryCustProductMasterComponent } from 'app/bakery-cust/product/master/master.component';
import { BakeryCustSignInComponent } from 'app/bakery-cust/sign-in/sign-in.component';
import { BakeryCustComponent } from 'app/bakery-cust/bakery-cust.component';

const signInUrl = 'store/sign_in';

const basicBodyClassData = {
  bodyClass: 'application-page--dark--100',
};

export const routes: Routes = [
  {
    path: '',
    component: BakeryCustComponent,
    canActivate: [BakeryRouteGuard],
    data: basicBodyClassData,
    children: [
      // Public routes (guest accessible)
      {
        path: '',
        component: BakeryCustProductMasterComponent,
        data: basicBodyClassData,
      },
    ]
  },
];
