import {
  Component,
  OnInit,
  OnDestroy,
  EventEmitter,
  ViewChild
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { Subject } from 'rxjs/Subject';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Actions as NgRxActions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { AppState } from 'app/store/app-state';
import { AlertService } from 'app/shared/components/alerts/alert.service';
import { Actions } from 'app/store/actions';
import { BakeryMgmtProductsEffects } from 'app/store/effects/bakery-mgmt/bakery-mgmt-products.effects';
import { BakeryMgmtProductTemplatesEffects } from 'app/store/effects/bakery-mgmt/bakery-mgmt-bakery-product-templates.effects';
import { BakeryMgmtProductAttributesEffects } from 'app/store/effects/bakery-mgmt/bakery-mgmt-bakery-product-attributes.effects';
import { BakeryProduct } from 'app/store/bakery-product';
import { BakeryMgmtUiState } from 'app/store/bakery-mgmt-ui-state';
import { EntitiesState } from 'app/store/entities-state';
import { DetailUiState } from 'app/bakery-mgmt/product-mgmt/products/detail-ui-state';
import { denormalize } from 'normalizr';

import {
  SimpleFormState,
  SubmittableFormState
} from 'app/shared/forms/form-states';
import { BakeryProductProductTemplate } from 'app/store/bakery-product-product-template';
import { bakeryProductSchema, bakeryProductTemplateSchema, bakeryProductProductTemplateSchema } from 'app/store/schema/default-schemata';

@Component({
  template: require('./detail.component.html')
})
export class BakeryMgmtProductProductDetailComponent
  implements OnInit, OnDestroy {
  uiState$: Observable<DetailUiState>;
  formState$: Observable<SubmittableFormState>;
  detailsForm: FormGroup;
  updateSuccess$: Observable<any>;
  bakeryProduct$: Observable<BakeryProduct>;
  advanced$ = new BehaviorSubject<any>(undefined);
  private productId$: Observable<number>;
  private productIdSub: Subscription;
  private alertsSub: Subscription;

  constructor(
    private bakeryMgmtProductsEffects: BakeryMgmtProductsEffects,
    private bakeryMgmtProductTemplatesEffects: BakeryMgmtProductTemplatesEffects,
    private bakeryMgmtProductAttributesEffects: BakeryMgmtProductAttributesEffects,
    private route: ActivatedRoute,
    private actions$: NgRxActions,
    private store: Store<AppState>,
    private alertService: AlertService
  ) {
    this.productId$ = this.route.params.map((params): number =>
      parseInt(params['product_id'])
    );

    this.productIdSub = this.productId$.subscribe((id: number) => {
      this.bakeryMgmtProductsEffects.requestGetBakeryProduct(id);
      this.bakeryMgmtProductAttributesEffects.requestGetBakeryAttributes();
      this.bakeryMgmtProductTemplatesEffects.requestGetBakeryProductTemplates();
    });

    this.uiState$ = Observable.combineLatest(
      this.store.select('bakeryMgmtUiState'),
      this.store.select('entitiesState'),
      this.productId$,
      (uiState, entities, productId) => ({ uiState, entities, productId })
    )
      .map(combined => {
        const bakeryMgmtUiState = <BakeryMgmtUiState>combined.uiState;
        const entitiesState = <EntitiesState>combined.entities;
        const productId = <number>combined.productId;

        if (
          bakeryMgmtUiState.productsUiState.isDetailLoading ||
          entitiesState.bakery_products[productId] == null
        ) {
          return {
            isLoading: true
          };
        }

        const bakeryProductTemplates = Object.keys(
          entitiesState.bakery_product_templates
        ).map(key =>
          ({...entitiesState.bakery_product_templates[key]})
        );

        const bakeryAttributes = Object.keys(
          entitiesState.bakery_attributes
        ).map(key => Object.assign({}, entitiesState.bakery_attributes[key]));

        const productCategories = Object.keys(
          entitiesState.product_categories
        ).map(key => Object.assign({}, entitiesState.product_categories[key]));

        const normalizedBakeryProduct = denormalize(productId, bakeryProductSchema, entitiesState)

        const bakeryProduct = {...normalizedBakeryProduct, bakery_product_product_templates: normalizedBakeryProduct.bakery_product_product_templates.map(template => {
          return {...template, bakery_product_template: bakeryProductTemplates.find(temp => temp.id === template.bakery_product_template_id)}
        })};


        this.advanced$.next(bakeryProduct.advanced);

        return {
          isLoading: false,
          bakeryProduct,
          bakeryProductTemplates,
          bakeryAttributes,
          productCategories
        };
      })
      .shareReplay(1);
  }

  submitForm$ = new Subject<any>();
  resetForm$ = new Subject<any>();
  descriptionFormState$ = new Subject<SimpleFormState>();
  editCautionVisible$: Observable<boolean>;

  ngOnInit() {
    this.submitForm$
      .withLatestFrom(this.uiState$, (formValue, uiState) => ({
        formValue,
        uiState
      }))
      .subscribe(({ formValue, uiState }) => {
        this.bakeryMgmtProductsEffects.requestUpdateBakeryProduct(
          uiState.bakeryProduct,
          formValue
        );
      });

    this.alertsSub = this.actions$.subscribe((action: UnsafeAction) => {
      let messageType = null,
        messageContent = null;

      switch (action.type) {
        case Actions.REQUEST_UPDATE_BAKERY_PRODUCT_SUCCESS:
          messageType = 'success';
          messageContent = `The product has been updated.`;
          break;

        case Actions.REQUEST_UPDATE_BAKERY_PRODUCT_ERROR:
          messageType = 'warning';
          messageContent = 'Something went wrong!';
          break;
      }

      if (messageType != null && messageContent != null) {
        this.alertService[messageType](messageContent);
      }
    });
  }

  ngOnDestroy() {
    this.productIdSub.unsubscribe();
    this.alertsSub.unsubscribe();
  }

  onClickFormReset() {
    this.resetForm$.next();
  }

  onSubmitForm(formValue: any) {
    this.submitForm$.next(formValue);
    window.scrollTo(0, 0); //NOTE: Might want to add animation for smoother transition later.
  }
}
