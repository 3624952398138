import { Component, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { Subject } from 'rxjs/Subject';
import { Actions as NgRxActions } from '@ngrx/effects';

import { Actions } from 'app/store/actions';
import { User } from 'app/store/user';
import { Bakery } from 'app/store/bakery';
import { FeatureModule } from 'app/store/feature-module';
import { SubscriptionPlanType } from 'app/store/subscription-plan-type';
import { UsersEffects } from 'app/store/effects/users.effects';
import { BakeryMgmtEffects } from 'app/store/effects/bakery-mgmt.effects';
import { BakeryPricingEffects } from 'app/store/effects/bakery/bakery-pricing.effects';

declare var process: any;

interface UiState {
  isLoading: true;
  bakery?: Bakery;
  featureModules?: FeatureModule;
  subPlanTypes?: SubscriptionPlanType[];
}

@Component({
  selector: 'stripe-card-form',
  template: `
    <div class="row">
      <div class="columns-4__s columns-5__m columns-8__l">
        <label class="label--required">Credit Card Number</label>
        <div id="cardNumberContainer"></div>
      </div>
      <div class="columns-2__s columns-2__m columns-2__l">
        <label class="label--required">Exp. Date</label>
        <div id="cardExpiryContainer"></div>
      </div>
      <div class="columns-2__s columns-2__m columns-2__l">
        <label class="label--required">CVV</label>
        <div id="cardCvcContainer"></div>
      </div>
    </div>
  `,
})
export class StripeCardFormComponent implements OnInit {
  @Output('valid') valid$ = new Subject<boolean>();
  @Output('stripeToken') stripeToken$ = new Subject<any>();
  @Input('createToken$') createToken$: Observable<any>;
  @Input('clear$') clear$: Observable<any>;
  cardStatus$: Observable<any>;

  stripe: any;
  elements: any;
  cardNumberElement: any;
  cardExpiryElement: any;
  cardCvcElement: any;

  private createTokenSub: Subscription;
  private cardStatusSub: Subscription;
  private clearSub: Subscription;

  constructor(
  ) { }

  ngOnInit() {
    this.buildStripe();

    this.createTokenSub = this.createToken$
      .switchMap(() => this.cardStatus$.take(1))
      .switchMap(creditCardDetails => {
        return Observable.fromPromise(this.stripe.createToken(this.cardNumberElement))
      })
      .subscribe(stripeResults => {
        this.stripeToken$.next(stripeResults);
      });

    if (this.clear$ != null) {
      this.clearSub = this.clear$.subscribe(() => {
        this.cardNumberElement.clear();
        this.cardExpiryElement.clear();
        this.cardCvcElement.clear();
      });
    }
  }

  private buildStripe() {
    this.stripe = (<any>window).Stripe(process.env.STRIPE_PUBLISHABLE_KEY);
    this.elements = this.stripe.elements();

    this.cardNumberElement = this.elements.create('cardNumber', {
      placeholder: '4242 4242 4242 4242',
    });
    this.cardExpiryElement = this.elements.create('cardExpiry', {
      placeholder: '08 / 20',
    });
    this.cardCvcElement = this.elements.create('cardCvc', {
      placeholder: '123',
    });
  }

  ngAfterViewInit() {
    this.cardNumberElement.mount('#cardNumberContainer');
    this.cardExpiryElement.mount('#cardExpiryContainer');
    this.cardCvcElement.mount('#cardCvcContainer');

    this.cardStatus$ = Observable
      .combineLatest(
        Observable.fromEvent(this.cardNumberElement, 'change')
          .startWith({complete: false}),
        Observable.fromEvent(this.cardExpiryElement, 'change')
          .startWith({complete: false}),
        Observable.fromEvent(this.cardCvcElement, 'change')
          .startWith({complete: false}),
        (cardNumber, cardExpiry, cardCvc) => {
          const isValid = cardNumber && (<any>cardNumber).complete &&
            cardExpiry && (<any>cardExpiry).complete &&
            cardCvc && (<any>cardCvc).complete;

          return {cardNumber, cardExpiry, cardCvc, isValid};
        }
      )
      .startWith({cardNumber: null, cardExpiry: null, cardCvc: null, isValid: false})
      .shareReplay(1);

    this.cardStatusSub = this.cardStatus$
      .map(cardStatus => cardStatus.isValid)
      .subscribe((isValid: boolean) => this.valid$.next(isValid))
  }

  ngOnDestroy() {
    this.cardNumberElement.unmount();
    this.cardExpiryElement.unmount();
    this.cardCvcElement.unmount();

    this.createTokenSub.unsubscribe();
    this.cardStatusSub.unsubscribe();
    if (this.clearSub) this.clearSub.unsubscribe();
  }
}
