import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

import { BakeryAttributeVariant } from 'app/store/bakery-attribute-variant';
import { BakeryProductAttribute } from 'app/store/bakery-product-attribute';

export const rebuildControls = (
  form: FormGroup,
  formBuilder: FormBuilder,
  field: 'attribute_variants' | 'template_variants',
  bpas: BakeryProductAttribute[],
  values: string[] = null
) => {
  form.removeControl(field);
  const formControls = bpas.map(() => new FormControl(null, [Validators.required]));
  const formArray = formBuilder.array(formControls);
  form.addControl(field, formArray);
  const singles = bpas.map(bpa => {
    const variants = bakeryAttributeVariantsFrom(bpa);
    return variants.length === 1 ? `${bpa.id}-${variants[0].id}` : null;
  });
  form.get(field).patchValue(zipWithOr(values || singles.map(() => null), singles));
}

const bakeryAttributeVariantsFrom = (bpa: BakeryProductAttribute): BakeryAttributeVariant[] => {
  if(!bpa.bakery_attribute) return [];
  if(typeof bpa.bakery_attribute === 'number') return [];
  if(!bpa.bakery_attribute.bakery_attribute_variants) return [];
  if((bpa.bakery_attribute.bakery_attribute_variants as any[]).some(v => typeof v === 'number')) return [];
  return bpa.bakery_attribute.bakery_attribute_variants as BakeryAttributeVariant[];
}

const zip = (xs: any[], ys: any[]) => xs.map((x, i) => [x, ys[i]]);

const zipWithOr = (xs: any[], ys: any[]) => zip(xs, ys).map(([x, y]) => x || y);
