import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import { Actions as NgRxActions } from '@ngrx/effects';

import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { Actions } from 'app/store/actions';
import { User } from 'app/store/user';
import { BakeryMgmtEmployeesEffects } from 'app/store/effects/bakery-mgmt-employees.effects';
import { UserDetailsFormValues } from './edit-employee-details/edit-employee-details.component';
import { AlertService } from 'app/shared/components/alerts/alert.service';
import { UsersEffects } from 'app/store/effects/users.effects';

@Component({
  selector: 'bakery-mgmt-employees-detail',
  template: require('./detail.component.html'),
})
export class BakeryMgmtEmployeesDetailComponent implements OnInit, OnDestroy {
  currentUser$: Observable<User>;
  requestEmployeeDetailsUpdateSuccess$: Observable<any>;
  requestEmployeeDetailsUpdateError$: Observable<string>;
  formValues$ = new Subject<UserDetailsFormValues>();
  archiveActions$ = new Subject<boolean>();
  deleteActions$ = new Subject<any>();

  private updateErrorSub: Subscription;
  private archiveSuccessSub: Subscription;
  private archiveErrorSub: Subscription;
  private deleteSuccessSub: Subscription;
  private alertsSub: Subscription;

  constructor(
    private route: ActivatedRoute,
    private bakeryMgmtEmployeesEffects: BakeryMgmtEmployeesEffects,
    private alertService: AlertService,
    private usersEffects: UsersEffects,
    private router: Router,
    private actions$: NgRxActions,
  ) {
    this.route.params
      .map(params => params['user_id'])
      .forEach((idParam: string) => {
        const id = parseInt(idParam);

        this.bakeryMgmtEmployeesEffects.requestGetEmployee(id);
      });

    // TODO: currentUser is a misleading name for this. Perhaps "employee"
    // or "detailUser" would be better?
    this.currentUser$ = this.uiStates$
      .filter(uiState => !uiState.isLoading)
      .pluck('user');

    this.requestEmployeeDetailsUpdateSuccess$ = this.bakeryMgmtEmployeesEffects.requestEmployeeDetailsUpdateEffects$
      .filter(action => action.type === Actions.REQUEST_EMPLOYEE_DETAILS_UPDATE_SUCCESS);

    this.formValues$
      .withLatestFrom(this.currentUser$, (userDetailsForm, user) => ({userDetailsForm, user}))
      .forEach(combined => {
        this.bakeryMgmtEmployeesEffects.requestEmployeeDetailsUpdate(
          combined.user,
          combined.userDetailsForm,
        );
      });

    this.archiveActions$
      .withLatestFrom(this.currentUser$, (newArchiveStatus, user) => ({newArchiveStatus, user}))
      .forEach(combined => {
        this.usersEffects.requestUserUpdateArchiveStatus(
          combined.user,
          combined.newArchiveStatus
        );
      });

    this.deleteActions$
      .withLatestFrom(this.currentUser$, (_, user) => user)
      .forEach(user => {
        this.usersEffects.requestUserDestroy(user);
      });

    this.deleteSuccessSub = this.usersEffects.requestUserDestroyEffects$
      .filter(action => action.type === Actions.REQUEST_USER_DESTROY_SUCCESS)
      .delay(1500)
      .subscribe(
        () => {
          this.router.navigate(['/bakery_mgmt/employees']);
        }
      );

    this.alertsSub = this.actions$
      .subscribe((action: UnsafeAction) => {
        let messageType = null,
            messageContent = null;

        switch (action.type) {
          case Actions.REQUEST_UPDATE_ARCHIVE_STATUS_SUCCESS:
            messageType = 'success';
            messageContent = `This user's status has been updated.`;
            break;

          case Actions.REQUEST_EMPLOYEE_DETAILS_UPDATE_SUCCESS:
            messageType = 'success';
            messageContent = `The employee's details have been updated.`;
            break;

          case Actions.REQUEST_USER_DESTROY_SUCCESS:
            messageType = 'success';
            messageContent = `This employee has been deleted.`;
            break;

          case Actions.REQUEST_USER_DESTROY_ERROR:
            messageType = 'warning';
            messageContent = `Something went wrong!`;
            break;

          case Actions.REQUEST_EMPLOYEE_DETAILS_UPDATE_ERROR:
            messageType = 'warning';
            messageContent = 'Something went wrong!';

            if (action.payload.error === 'duplicate_email_address') {
              messageContent = 'Another user already has that email address.';
            }
            break;
        }

        if (messageType != null && messageContent != null) {
          this.alertService[messageType](messageContent);
        }
      });
  }

  ngOnInit() { }

  ngOnDestroy() {
    this.deleteSuccessSub.unsubscribe();
    this.alertsSub.unsubscribe();
  }

  get uiStates$() {
    return this.bakeryMgmtEmployeesEffects.detailUiStates$;
  }

  onSubmitEmployeeDetailsForm(userDetailsFormValues: UserDetailsFormValues) {
    this.formValues$.next(userDetailsFormValues)
  }

  onClickArchive() {
    this.archiveActions$.next(true);
  }

  onClickUnarchive() {
    this.archiveActions$.next(false);
  }

  onClickDelete() {
    this.deleteActions$.next();
  }
}
