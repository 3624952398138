import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

import { BakeryOrderInvoice } from 'app/store/bakery-order-invoice';
import { BakeryInvoicePayment } from 'app/store/bakery-invoice-payment';
import { cloneSort, sortByCreatedAtDesc } from 'app/store/entity-utils';

export abstract class InvoicePaymentSummaryHistoryBaseComponent {
  private _invoice: BakeryOrderInvoice;

  payments: BakeryInvoicePayment[] = [];

  @Input()
  set invoice(invoice: BakeryOrderInvoice) {
    this._invoice = invoice;

    this.sortPayments();
  }

  get invoice(): BakeryOrderInvoice {
    return this._invoice;
  }

  protected sortPayments() {
    if (this._invoice != null) {
      this.payments = cloneSort(this._invoice.bakery_invoice_payments, sortByCreatedAtDesc);
    } else {
      this.payments = [];
    }
  }
}

@Component({
  selector: 'invoice-payment-summary-history',
  template: `
    <div class="margin-bottom--bravo print--hide">
      <div gridRow>
        <header aria-labelledby="Invoice payment history">
          <h6 class="margin-reset--bottom">Payment History <span *ngIf="invoice">(Balance: {{ invoice.balance | centsObservable | async }})</span></h6>
        </header>
      </div>
      <ul class="list_items">
        <li
          class="list__item list__item--dark"
          *ngFor="let payment of payments"
        >
          <invoice-payment-summary-text
            [payment]="payment"
          ></invoice-payment-summary-text>
        </li>
        <li
          class="list__item list__item--dark"
          *ngIf="!payments.length"
        >
          There is no invoice history to view at the moment.
        </li>
      </ul>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvoicePaymentSummaryHistoryComponent extends InvoicePaymentSummaryHistoryBaseComponent {
}

@Component({
  selector: 'invoice-payment-summary-history-table',
  template: `
    <list-table-headers>
      <list-table-header gridCol s="1" m="2" l="2">
        Amount
      </list-table-header>
      <list-table-header gridCol s="0" m="3" l="2">
        Date
      </list-table-header>
      <list-table-header gridCol s="0" m="0" l="2">
        Type
      </list-table-header>
      <list-table-header gridCol s="2" m="4" l="6">
        Notes
      </list-table-header>
    </list-table-headers>
    <list-table-items>
      <li class="list__item padding-left--delta padding-right--delta"
        *ngFor="let payment of payments">
        <ul gridRow>
          <li gridCol s="1" m="2" l="2">
            {{ payment.amount_paid | centsObservable | async }}
          </li>
          <li gridCol s="0" m="3" l="2">
            {{ payment.created_at | date:'M/d/y' }}
          </li>
          <li gridCol s="0" m="0" l="2">
            {{ payment.payment_direction | capitalize }}
          </li>
          <li gridCol s="2" m="3" l="6">
            {{ payment.notes }}
          </li>
          <li gridCol s="1" m="1" l="0" class="text--right">
            <popout-container class="popout__container">
              <popout-trigger icon="help"></popout-trigger>
              <popout-items-list popoutInverse="true">
                <popout-list-header title="Payment Details"></popout-list-header>
                <li gridCol s="4" m="0" l="0">
                  <label>Payment Date</label>
                  <p>{{ payment.created_at | date:'M/d/y' }}</p>
                </li>
                <li>
                  <label>Payment Type</label>
                  <p>{{ payment.payment_direction | capitalize }}</p>
                </li>
              </popout-items-list>
            </popout-container>
          </li>
        </ul>
      </li>
      <li
        class="list__item row"
        *ngIf="!payments.length"
      >
        <ul>
          <li>
            There is no invoice history to view at the moment.
          </li>
        </ul>
      </li>
    </list-table-items>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvoicePaymentSummaryHistoryTableComponent extends InvoicePaymentSummaryHistoryBaseComponent {
}
