import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { Actions as NgRxActions } from '@ngrx/effects';

import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { Actions } from 'app/store/actions';
import { AlertService } from 'app/shared/components/alerts/alert.service';
import { passwordMatchValidatorBuilder } from 'app/shared/forms/password-match-validator';
import { UsersEffects } from 'app/store/effects/users.effects';
import { validateNoWhitespaces } from 'app/shared/forms/no-whitespaces-validator';

@Component({
  selector: 'app-reset-password',
  template: require('./reset-password.component.html'),
})
export class ResetPasswordComponent implements OnInit {
  token = '';
  form: FormGroup;

  private alertsSub: Subscription;
  private resetSuccessSub: Subscription;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private usersEffects: UsersEffects,
    private actions$: NgRxActions,
  ) { }

  ngOnInit() {
    this.token = this.route.snapshot.queryParams['reset_password_token'];

    this.form = new FormGroup({
      'email': new FormControl(null, [Validators.required, Validators.email]),
      'password': new FormControl(null, [Validators.required, Validators.minLength(8), validateNoWhitespaces]),
      'confirmation': new FormControl(null, [Validators.required, Validators.minLength(8), validateNoWhitespaces]),
    }, passwordMatchValidatorBuilder('password', 'confirmation'));


    this.alertsSub = this.actions$
      .subscribe((action: UnsafeAction) => {
        let messageType = null,
            messageContent = null;

        switch (action.type) {
          case Actions.REQUEST_USER_RESET_PASSWORD_SUCCESS:
            messageType = 'success';
            messageContent = `Password reset! You will now be taken back to the sign in page to sign in with your updated password.`;
            break;

          case Actions.REQUEST_USER_RESET_PASSWORD_ERROR:
            messageType = 'warning';
            messageContent = 'Something went wrong!';

            switch (action.payload.error) {
              case 'invalid_email_address':
                messageContent = `The e-mail address provided does not match a user in our system. Please check your input and try again.`;
                break;

              case 'invalid_token':
                messageContent = `The password reset token provided is not valid. Please use the Forgot Password? form to generate a new reset password token.`;
                break;

              case 'expired_token':
                messageContent = `The password reset token provided has expired. Please use the Forgot Password? form to generate a new reset password token.`;
                break;
            }
            break;
        }

        if (messageType != null && messageContent != null) {
          this.alertService[messageType](messageContent);
        }
      });

    this.resetSuccessSub = this.usersEffects.requestUserResetPasswordEffects$
      .filter(action => action.type === Actions.REQUEST_USER_RESET_PASSWORD_SUCCESS)
      .delay(5500)
      .subscribe(() => this.router.navigate(['/sign_in']));
  }

  onSubmit() {
    const email: string = this.form.value.email,
          password: string = this.form.value.password,
          confirmation: string = this.form.value.confirmation;

    this.usersEffects.requestUserResetPassword(email, password, this.token);
    this.form.reset();
  }

  ngOnDestroy() {
    this.alertsSub.unsubscribe();
    this.resetSuccessSub.unsubscribe();
  }
}
