import { Component, OnInit, OnDestroy, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as Rx from 'rxjs';

import { User } from 'app/store/user';
import { SimpleFormState } from 'app/shared/forms/form-states';
import { passwordMatchValidatorBuilder } from 'app/shared/forms/password-match-validator';
import { validateNoWhitespaces } from 'app/shared/forms/no-whitespaces-validator';

@Component({
  selector: 'bakery-cust-account-setting-password-detail-edit',
  template: `
    <form [formGroup]="form">
      <titled-card title="Edit Password">
        <ng-container data-card-body>
          <div gridRow>
            <div gridCol s="4" m="9" l="6">
              <label>New Password</label>
              <input
                type="password"
                placeholder="••••••••"
                formControlName="password"
              />
            </div>
            <div gridCol s="4" m="9" l="6">
              <label>Confirm New Password</label>
              <input
                type="password"
                placeholder="••••••••"
                formControlName="confirmation"
              />
            </div>
          </div>
        </ng-container>
      </titled-card>
    </form>
  `
})
export class BakeryCustAccountSettingsUserPasswordEditComponent implements OnInit, OnDestroy {
  @Input('user$') user$: Rx.Observable<User>;
  @Input('reset$') reset$: Rx.Observable<any>;
  @Output('formState') formState$: Rx.Observable<SimpleFormState>;

  form: FormGroup;

  private formValuesSub: Rx.Subscription;
  private resetFormSub: Rx.Subscription;

  constructor(
  ) {
    this.form = new FormGroup(
      {
        password: new FormControl(null, [Validators.minLength(8), validateNoWhitespaces]),
        confirmation: new FormControl(null, [Validators.minLength(8), validateNoWhitespaces]),
      },
      passwordMatchValidatorBuilder('password', 'confirmation')
    );

    this.formState$ = this.form.valueChanges
      .map((): SimpleFormState => {
        return {
          isDirty: this.form.dirty,
          isValid: this.form.valid,
          value: {
            password: this.form.value.password,
          },
        };
      })
      .shareReplay(1);
  }

  ngOnInit() {
    this.formValuesSub = this.user$
      .subscribe((user: User) => {
        this.resetForm(user);
      });

    this.resetFormSub = this.reset$
      .switchMap(() => this.user$.take(1))
      .subscribe((user: User) => {
        this.resetForm(user);
        this.resetForm(user);
      });
  }

  ngOnDestroy() {
    this.formValuesSub.unsubscribe();
    this.resetFormSub.unsubscribe();
  }

  private resetForm(user: User) {
    this.form.reset(this.buildFormValue(user));
  }

  private buildFormValue(user: User) {
    return {
      name: '',
      email: '',
    };
  }
}
