import {
  Component,
  OnInit,
  OnDestroy,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { Subject } from 'rxjs/Subject';

import { BakeryProductTemplate } from 'app/store/bakery-product-template';
import { SimpleFormState } from 'app/shared/forms/form-states';
import { validatePositiveInteger } from 'app/shared/forms/positive-integer-validator';
import { validateNoWhitespaces } from 'app/shared/forms/no-whitespaces-validator';

@Component({
  selector: 'bakery-mgmt-product-template-detail-edit',
  template: require('./template-detail-edit.component.html')
})
export class BakeryMgmtProductTemplateDetailEditComponent implements OnInit, OnDestroy {
  @Input('bakeryProductTemplate$') bakeryProductTemplate$: Observable<BakeryProductTemplate>;
  @Input('success$') success$: Observable<any>;
  @Input('reset$') reset$: Observable<any>;
  @Output('submitForm') submitForm$ = new Subject<any>();
  @Output('formState') formState$: Observable<SimpleFormState>;

  form: FormGroup;

  private filteredProdTem$: Observable<BakeryProductTemplate>;
  private resetFormEvents = new EventEmitter<any>();
  private formValuesSub: Subscription;
  private resetFormSub: Subscription;

  constructor() {
    this.form = new FormGroup({
      name: new FormControl(null, [
        Validators.required,
        validateNoWhitespaces
      ]),
      unit_price: new FormControl(null, [Validators.required]),
      unit_quantity: new FormControl(1, [
        Validators.required,
        Validators.pattern('[0-9]{1,3}'),
        validatePositiveInteger(1)
      ]),
      order_limit: new FormControl(0, [Validators.pattern('[0-9]{1,3}')])
    });

    this.formState$ = this.form.valueChanges.map((): SimpleFormState => {
      return {
        isDirty: this.form.dirty,
        isValid: this.form.valid,
        value: this.form.value
      };
    });
  }

  ngOnInit() {
    this.filteredProdTem$ = this.bakeryProductTemplate$.filter(
      (bakeryProductTemplate: BakeryProductTemplate) =>
        bakeryProductTemplate != null
    );

    this.formValuesSub = this.filteredProdTem$.subscribe(
      (bakeryProductTemplate: BakeryProductTemplate) => {
        this.setFormValues(bakeryProductTemplate);
      }
    );

    this.resetFormSub = this.reset$
      .switchMap(() => this.filteredProdTem$.take(1))
      .subscribe((bakeryProductTemplate: BakeryProductTemplate) =>
        this.resetForm(bakeryProductTemplate)
      );

    this.success$
      .switchMap(() => {
        return this.bakeryProductTemplate$
          .filter(bakeryProductTemplate => bakeryProductTemplate != null)
          .take(1);
      })
      .subscribe((bakeryProductTemplate: BakeryProductTemplate) =>
        this.resetForm(bakeryProductTemplate)
      );
  }

  ngOnDestroy() {
    this.formValuesSub.unsubscribe();
  }

  private resetForm(bakeryProductTemplate: BakeryProductTemplate) {
    this.form.reset(this.buildFormValue(bakeryProductTemplate));
    this.form.reset(this.buildFormValue(bakeryProductTemplate));
  }

  private setFormValues(bakeryProductTemplate: BakeryProductTemplate) {
    this.form.setValue(this.buildFormValue(bakeryProductTemplate));
  }

  private buildFormValue(bakeryProductTemplate: BakeryProductTemplate) {
    return {
      name: bakeryProductTemplate.name,
      unit_price: bakeryProductTemplate.unit_price,
      unit_quantity: bakeryProductTemplate.unit_quantity,
      order_limit: bakeryProductTemplate.order_limit
    };
  }

  onClickResetForm() {
    this.resetFormEvents.next();
  }
}
