import {
  Component,
  Input,
  Output,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
  Injectable
} from '@angular/core';
import { AbstractControl, FormGroup, FormControl, Validators } from '@angular/forms';
import * as Rx from 'rxjs';
import { Store } from '@ngrx/store';
import { Actions as NgRxActions } from '@ngrx/effects';

import { AppState } from 'app/store/app-state';
import { Actions } from 'app/store/actions';
import * as selectors from 'app/store/selectors';
import { AlertService } from 'app/shared/components/alerts/alert.service';
import { HeaderStateService } from 'app/shared/services/header-state.service';

const compSelector = 'bakery-mgmt-wholesalers-group-list-header';

@Component({
  selector: compSelector,
  template: `
    <${compSelector}-prez
      [count]="count$ | async"
      [isExpanded]="isExpanded$ | async"
    ></${compSelector}-prez>
  `,
  providers: [HeaderStateService]
})
export class GroupListHeaderComponent {
  count$ = this.store.select(selectors.bkryMgmtWholesalerGroup.getMasterResultCount);

  constructor(
    private store: Store<AppState>,
    private alertService: AlertService,
    private actions$: NgRxActions,
    private headerStateService: HeaderStateService
  ) {
    this.resetAddFormState();
  }

  get isExpanded$(): Rx.Observable<boolean> {
    return this.headerStateService.isExpanded;
  }

  private resetAddFormState() {
    // TODO: ...
  }
}

@Component({
  selector: compSelector + '-prez',
  template: require('./header.component.html'),
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupListHeaderPrezComponent {
  @Input() count: number;
  @Input() isExpanded = false;

  isAddWholesalerGroupOpen = false;

  changeFilterAction = Actions.CHANGE_BKRY_MGMT_WHOLESALER_GROUPS_FILTERS;

  form: FormGroup;
  filterForm: FormGroup;

  constructor(
    private headerStateService: HeaderStateService
  ) {
    this.filterForm = new FormGroup(
      {
        name: new FormControl(null, []),
      },
    );
  }

  onClickAddWholesalerGroup() {
    this.headerStateService.updateIsExpanded(true);
    // this.isAddWholesalerGroupOpen = true;
  }

  onClickCancel() {
    this.headerStateService.updateIsExpanded(false);
    // this.isAddWholesalerGroupOpen = false;
  }
}
