import {
  Component,
  Input,
  Output,
  ChangeDetectionStrategy,
  EventEmitter
} from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { Actions as NgRxActions } from '@ngrx/effects';

import { AppState } from 'app/store/app-state';
import * as selectors from 'app/store/selectors';
import { BakeryOrderInvoice } from 'app/store/bakery-order-invoice';
import { PaymentCard } from 'app/store/payment-card';
import { HeaderStateService } from 'app/shared/services/header-state.service';
import { PaymentData } from 'app/shared/components/invoices/invoice-add-payment-form.component';
import {
  PostAction,
  RequestSuccessAction,
  PutActionPayload
} from 'app/store/actions/request.actions';
import { AlertAction } from 'app/shared/components/alerts/actions';

import { schema } from 'normalizr';
import { bakeryOrderInvoiceSchema } from 'app/store/schema/default-schemata';

const createOpKey = 'invoicePaymentCreate',
      createOpSchema = new schema.Entity('bakery_invoice_payments', {bakery_order_invoice: bakeryOrderInvoiceSchema});

@Component({
  selector: 'invoice-add-payment-card',
  template: `
    <titled-card
      title="Add Payment"
      *ngIf="isExpanded$ | async"
    >
      <invoice-add-payment-form
        data-card-body
        [isLoading]="createIsLoading$ | async"
        [isManualAllowed]="isManualAllowed"
        [paymentCards]="paymentCards"
        (onAddPayment)="onAddPayment($event)"
        (onCancel)="onFormCancel()"
      ></invoice-add-payment-form>
    </titled-card>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvoiceAddPaymentCardComponent {
  @Input() invoice: BakeryOrderInvoice;
  @Input() paymentCards: PaymentCard[];
  @Input() isManualAllowed = false;

  createIsLoading$: Observable<boolean> = this.store
    .select(selectors.getRequestIsLoading(createOpKey));

  onSuccess$: Observable<undefined> = selectors.onRequestSuccessBuilder(this.actions$, createOpKey);

  constructor(
    private store: Store<AppState>,
    private actions$: NgRxActions,
    private headerStateService: HeaderStateService
  ) { }

  ngOnInit() {
    this.onSuccess$.subscribe(() => this.headerStateService.updateIsExpanded(false));
  }

  get isExpanded$(): Observable<boolean> {
    return this.headerStateService.isExpanded;
  }

  onFormCancel() {
    this.headerStateService.updateIsExpanded(false);
  }

  onAddPayment(paymentData: PaymentData) {
    const formValue = {
      payment_source: {
        type: paymentData.payment_type === 'credit_card' ? 'PaymentCard' : 'ManualPaymentDetail',
        payment_type: paymentData.payment_type,
        id: paymentData.payment_card_selection === 'add_new' ? null : paymentData.payment_card_selection,
        stripe_token: paymentData.payment_card_stripe_token
      },
      amount: paymentData.amount,
      notes: paymentData.notes
    };

    const actionPayload: PutActionPayload = {
      url: `/api/invoices/${this.invoice.id}/payments`,
      opKey: createOpKey,
      schema: createOpSchema,
      body: formValue,
      successChainAction: AlertAction.buildSuccess(`The payment has been added.`)
    }
    this.store.dispatch(new PostAction(actionPayload));
  }
}
