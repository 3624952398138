import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as Rx from 'rxjs';
import { Actions as NgRxActions } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { AppState } from 'app/store/app-state';
import { EntitiesState } from 'app/store/entities-state';
import { Actions } from 'app/store/actions';
import { User } from 'app/store/user';
import { AlertService } from 'app/shared/components/alerts/alert.service';
import { UsersEffects } from 'app/store/effects/users.effects';
import { BakeryMgmtCustomersEffects } from 'app/store/effects/bakery-mgmt/bakery-mgmt-customers.effects';
import { CustomersDetailUiState } from 'app/store/bakery-mgmt-ui-state'

export interface UiState {
  isLoading: boolean;
  user?: User;
}

@Component({
  template: require('./detail.component.html'),
})
export class BakeryMgmtCustomersDetailComponent implements OnInit, OnDestroy {
  uiState$: Rx.Observable<UiState>;
  user$: Rx.Observable<User>;
  userId$: Rx.Observable<number>;
  requestEmployeeDetailsUpdateSuccess$: Rx.Observable<any>;
  requestEmployeeDetailsUpdateError$: Rx.Observable<string>;
  archiveActions$ = new Rx.Subject<boolean>();

  private updateErrorSub: Rx.Subscription;
  private archiveSuccessSub: Rx.Subscription;
  private archiveErrorSub: Rx.Subscription;
  private alertsSub: Rx.Subscription;
  private userIdSub: Rx.Subscription;

  constructor(
    private route: ActivatedRoute,
    private alertService: AlertService,
    private usersEffects: UsersEffects,
    private router: Router,
    private actions$: NgRxActions,
    private bakeryMgmtCustomersEffects: BakeryMgmtCustomersEffects,
    private store: Store<AppState>,
  ) {
    this.userId$ = this.route.params
      .map((params): number => parseInt(params['user_id']));

    this.userIdSub = this.userId$
      .subscribe((id: number) => this.bakeryMgmtCustomersEffects.requestGetCustomer(id))

    this.uiState$ = Rx.Observable
      .combineLatest(
        this.store.select('bakeryMgmtUiState', 'customersUiState', 'detailState'),
        this.store.select('entitiesState'),
        (detailState, entitiesState) => ({detailState, entitiesState})
      )
      .map((combined) => {
        const detailState = <CustomersDetailUiState> combined.detailState;
        const entitiesState = <EntitiesState> combined.entitiesState;

        if (
          detailState.state == null ||
          detailState.state == 'loading' ||
          entitiesState.users[detailState.detailId] == null
        ) {
          return {
            isLoading: true,
          };
        }

        const user = {...entitiesState.users[detailState.detailId]};

        return {
          isLoading: false,
          user: user,
        };
      })
      .startWith({isLoading: true})
      .shareReplay(1);

    this.user$ = this.uiState$
      .filter(uiState => !uiState.isLoading)
      .pluck('user');

    this.archiveActions$
      .withLatestFrom(
        this.uiState$
          .filter(uiState => !uiState.isLoading)
          .map(uiState => uiState.user),
        (newArchiveStatus, user) => ({newArchiveStatus, user})
      )
      .forEach(combined => {
        this.usersEffects.requestUserUpdateArchiveStatus(
          combined.user,
          combined.newArchiveStatus
        );
      });

    this.alertsSub = this.actions$
      .subscribe((action: UnsafeAction) => {
        let messageType = null,
            messageContent = null;

        switch (action.type) {
          case Actions.REQUEST_UPDATE_ARCHIVE_STATUS_SUCCESS:
            messageType = 'success';
            messageContent = `The user's archive status has been updated.`;
            break;

          case Actions.REQUEST_USER_DETAILS_UPDATE_SUCCESS:
            messageType = 'success';
            messageContent = `The customer's details have been updated.`;
            break;

          case Actions.REQUEST_USER_DETAILS_UPDATE_ERROR:
            messageType = 'warning';
            messageContent = 'Something went wrong!';

            if (action.payload.error === 'duplicate_email_address') {
              messageContent = 'Another user already has that email address.';
            }
            break;
        }

        if (messageType != null && messageContent != null) {
          this.alertService[messageType](messageContent);
        }
      });
  }

  ngOnInit() { }

  ngOnDestroy() {
    this.alertsSub.unsubscribe();
    this.userIdSub.unsubscribe();
  }

  onClickArchive() {
    this.archiveActions$.next(true);
  }

  onClickUnarchive() {
    this.archiveActions$.next(false);
  }
}
