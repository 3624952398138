import {
  Component,
  Input,
  Output,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AppState } from 'app/store/app-state';
import * as selectors from 'app/store/selectors';
import { bakeryProductSchema } from 'app/store/schema/default-schemata';
import { WholesalerGroup } from 'app/store/wholesaler-group';
import { BakeryMgmtUiState } from 'app/store/bakery-mgmt-ui-state';
import { GetAction } from 'app/store/actions/request.actions';
import { EntitiesState } from 'app/store/entities-state';
import { PricedProductVariation } from 'app/wholesaler/priced-product-variation';
import { buildPricedProductVariations } from 'app/wholesaler/build-priced-product-variations';

const opKey = 'bakeryProducts',
      opSchema = [bakeryProductSchema];

@Component({
  template: `
    <wholesaler-price-index-table
      [isLoading]="isLoading$|async"
      [pricedProductVariations]="pricedProductVariations$|async"
    ></wholesaler-price-index-table>
  `,
})
export class PriceIndexComponent implements OnInit {
  isLoading$: Observable<boolean> = this.store
    .select(selectors.getRequestIsLoading(opKey));
  wholesalerGroup$: Observable<WholesalerGroup> = this.store
    .select(selectors.bkryMgmtWholesalerGroup.getDetailResultEntity);

  pricedProductVariations$: Observable<PricedProductVariation[]> = this.store
    .select(selectors.getRequestResult(opKey, opSchema, []))
    .switchMap(() => this.store.select('entitiesState'))
    .withLatestFrom(
      this.wholesalerGroup$,
      (entitiesState, wholesalerGroup) => ({entitiesState, wholesalerGroup})
    )
    .map(({entitiesState, wholesalerGroup}: {entitiesState: EntitiesState, wholesalerGroup: WholesalerGroup}): PricedProductVariation[] => {
      return buildPricedProductVariations(entitiesState, wholesalerGroup);
    })

  constructor(
    private store: Store<AppState>,
  ) { }

  ngOnInit() {
    this.store
      .select('bakeryMgmtUiState', 'bakeryId')
      .take(1)
      .subscribe((bakeryId) => {
          const actionPayload = {
            url: `/api/bakeries/${bakeryId}/bakery_products`,
            opKey: opKey,
            schema: opSchema,
            queryParams: {
              archived: false
            }
          };
          this.store.dispatch(new GetAction(actionPayload));
      });
  }
}
