import { ProductCategory } from 'app/store/product-category';
import { BakeryProduct } from 'app/store/bakery-product';
import { EntitiesState } from 'app/store/entities-state';

export function denormalize(productCategory: ProductCategory, entitiesState: EntitiesState): ProductCategory {
  const clonedProdCat = Object.assign({}, productCategory);

  clonedProdCat.bakery_products = (<number[]>productCategory.bakery_products)
    .map((bakeryProductId: number): BakeryProduct => {
      return entitiesState.bakery_products[bakeryProductId];
    });

  return clonedProdCat;
}
