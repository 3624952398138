import { Component, OnInit, OnDestroy } from '@angular/core';
import { BakeryMgmtCustomersEffects } from 'app/store/effects/bakery-mgmt/bakery-mgmt-customers.effects';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';

import { User } from 'app/store/user';
import { EntitiesState } from 'app/store/entities-state';
import { AppState } from 'app/store/app-state';
import { Store } from '@ngrx/store';
import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { BakeryMgmtProductsEffects } from 'app/store/effects/bakery-mgmt';
import { BakeryProduct } from 'app/store/bakery-product';
import { LocalStorageService } from 'ngx-webstorage';
import { Actions } from 'app/store/actions';
import { Actions as NgRxActions } from '@ngrx/effects';
import { OrderItem } from 'app/store/order-item';
import { AlertService } from 'app/shared/components/alerts/alert.service';
import { bakeryProductSchema } from 'app/store/schema/default-schemata';
import { denormalize } from 'normalizr';
import { ActivatedRoute } from '@angular/router';
import * as selectors from 'app/store/selectors';
import * as RemoteData from 'app/remote-data';
import iconClose from 'angular/assets/images/icons/icons-functional/icon-close.svg';
import iconUser from 'angular/assets/images/icons/icons-functional/icon-user.svg';

export interface UiState {
  customers?: User[];
  bakeryProducts?: BakeryProduct[];
  orderItems?: OrderItem[];
  selectedCustomer?: User;
}

const NO_SELECTED_CUSTOMER: any = { id: null, name: null, email: null, phone_number: null };

@Component({
  selector: 'bakery-mgmt-new-order-master',
  template: `
    <bakery-mgmt-new-order-customer-selection
      (changeFilterCustomerText$)="onChangeFilterCustomerText($event)"
      [onChangeCustomer]="onChangeCustomer"
      [selectedCustomer]="(uiState$|async).selectedCustomer"
      [customers]="(customers$|async)"
      [ngClass]="{'bakery-order-page__sidebar--mobile-active': isClassMobileCustomersActive}"
    ></bakery-mgmt-new-order-customer-selection>
    <div class="bakery-order-page__section">
      <bakery-mgmt-page-header [title]="title">
      </bakery-mgmt-page-header>
      <div class="visibility--small-show">
        <button (click)="onClickMobileCustomersActive()" class="bakery-order-page__title-action">
           <i aria-label="Home" [inlineSVG]="iconUser"></i></button>
          <button [ngClass]="{'is-active': showMobileCustomersCloseAction}"
            class="bakery-order-page__title-action--close visibility--small-show" (click)="onClickMobileCustomersActive()">
            <i aria-label="Close" [inlineSVG]="iconClose"></i>
          </button>
      </div>
      <alert-container></alert-container>
      <div *ngIf="!checkout">
        <bakery-mgmt-new-order-product-selection
        (changeFilterProductText$)="onChangeFilterProductText($event)"
        [checkout]="checkout"
        [employee]="true"
        [changeToCheckout]="changeToCheckout"
        [bakeryProducts]="(bakeryProducts$|async)"
        [orderItems]="(uiState$|async).orderItems"
        [selectedCustomer]="(uiState$|async).selectedCustomer"
        [isLoading]="false"
        ></bakery-mgmt-new-order-product-selection>
      </div>
      <div *ngIf="checkout">
        <bakery-mgmt-new-order-checkout
          [changeToCheckout]="changeToCheckout"
          [checkout]="checkout"
          [wholesaler]="wholesaler"
          [selectedCustomer]="(uiState$|async).selectedCustomer"
          [selectedCustomer$]="selectedCustomer$"
          [orderItems]="(uiState$|async).orderItems">
        </bakery-mgmt-new-order-checkout>
      </div>
    </div>
  `
})
export class BakeryMgmtNewOrderMasterComponent implements OnInit, OnDestroy {
  iconClose = iconClose;
  iconUser = iconUser;
  alertSub: Subscription;
  uiStateSub: Subscription;
  uiState$: Observable<any>;
  customers$: Observable<any[]>;
  bakeryProducts$: Observable<RemoteData.RemoteData<any[]>>;
  checkout = false;
  isClassMobileCustomersActive = false;
  showMobileCustomersCloseAction = false;
  wholesaler: boolean;
  private selectedCustomer$ = new BehaviorSubject<User>(null);

  constructor(
    private bakeryMgmtCustomersEffects: BakeryMgmtCustomersEffects,
    private bakeryMgmtProductsEffects: BakeryMgmtProductsEffects,
    private store: Store<AppState>,
    private alertService: AlertService,
    private localStore: LocalStorageService,
    private actions$: NgRxActions,
    private route: ActivatedRoute
  ) {

  }

  ngOnInit() {
    this.wholesaler = this.route.snapshot.data.wholesaler;

    const employeeCartId: string = this.localStore.retrieve('emplCartId');

    this.store.dispatch({
      type: Actions.REQUEST_GET_EMPLOYEE_CART,
      payload: employeeCartId,
    });

    this.store.dispatch({ type: Actions.NEW_ORDER_STARTED });

    this.customers$ = this.store.select(selectors.selectNewOrderCustomers);
    this.bakeryProducts$ = Observable.combineLatest(
      this.store.select(selectors.selectBakeryProducts),
      this.selectedCustomer$
      )
      .map(([bakeryProducts, selectedCustomer]: [RemoteData.RemoteData<any[]>, any]) => {
        const allowedStoreTypes = this.wholesaler ?
          ['internal_only', 'wholesale_only', 'retail_wholesale'] :
          ['internal_only', 'retail_only', 'retail_wholesale'];
        return [
          RemoteData.map(xs => {
            return xs
              .filter(bakeryProduct => !bakeryProduct.archived)
              .filter(bakeryProduct => allowedStoreTypes.includes(bakeryProduct.store_type));
          }, bakeryProducts),
          selectedCustomer
        ];
      })
      .map(([bakeryProducts, selectedCustomer]: [RemoteData.RemoteData<any[]>, any]) => {
        if (!this.wholesaler) { return bakeryProducts; }
        if (!selectedCustomer) { return bakeryProducts; }
        if (!selectedCustomer.hasOwnProperty('wholesaler_group')) { return bakeryProducts; }

        return RemoteData.map(xs => {
          return xs.map(product => {
            const bakery_product_templates = product.bakery_product_templates.map(template => {
              const template_join = product.bakery_product_product_templates.filter(x => (
                x.bakery_product_template_id === template.id)[0]
              );

              if (template_join.wholesaler_product_overrides &&
                template_join.wholesaler_product_overrides.some(override => (
                  override.wholesaler_group_id === selectedCustomer.wholesaler_group.id
                ))) {
                const wholesaler_product_override = template_join.wholesaler_product_overrides.filter(override => (
                  override.wholesaler_group_id === selectedCustomer.wholesaler_group.id)[0]
                );

                return {
                  ...template,
                  order_minimum: wholesaler_product_override.order_minimum,
                  unit_price: wholesaler_product_override.unit_price,
                };
              } else {
                const defaultDiscount = (100 - selectedCustomer.wholesaler_group.default_discount) / 100;
                return { ...template, unit_price: template.unit_price * defaultDiscount };
              }
            });

            return { ...product, bakery_product_templates: bakery_product_templates };
          });
        }, bakeryProducts);
      });

    this.uiState$ = Observable
    .combineLatest(
      this.store.select('bakeryMgmtUiState', 'cartState'),
      this.store.select('entitiesState'),
      this.selectedCustomer$,
      (cartState, entitiesState, selectedCustomer) =>
      ({ cartState, entitiesState, selectedCustomer })
    )
    .debounceTime(0)
    .map(combined => {
      const entitiesState = <EntitiesState>combined.entitiesState;
      const selectedCustomer = <User> combined.selectedCustomer;

      let orderItems = [];
      const emplCartId: string = this.localStore.retrieve('emplCartId');

      if (entitiesState.bakery_carts[emplCartId]) {
        orderItems = entitiesState.bakery_carts[emplCartId].order_items.map(item => {
          return entitiesState.order_items[item];
        });
      }

      return {
        orderItems: orderItems,
        selectedCustomer: selectedCustomer
      };
    })
    .startWith({ orderItems: [], selectedCustomer: NO_SELECTED_CUSTOMER })
    .shareReplay(1);

    this.uiStateSub = this.uiState$.subscribe();

    this.alertSub = this.actions$
      .subscribe((action: UnsafeAction) => {
        let messageType = null,
            messageContent = null;

        switch (action.type) {
          // case Actions.REQUEST_DELETE_EMPLOYEE_CART_SUCCESS:
          //   messageType = 'success';
          //   messageContent = `Got it. We have deleted the current order.`;
          //   break;
          case Actions.REQUEST_UPDATE_EMPLOYEE_CART_SUCCESS:
          case Actions.REQUEST_CREATE_EMPLOYEE_CART_SUCCESS:
            messageType = 'success';
            messageContent = `Got it. We have updated the current order.`;
            break;

          case Actions.ADD_CUSTOMER_TO_EMPLOYEE_CART:
            if (this.wholesaler) {
              messageType = 'success';
              messageContent = 'Got it. Product prices have been updated to reflect any special pricing.';
            }
            break;
        }

        if (messageType != null && messageContent != null) {
          this.alertService[messageType](messageContent);
        }
      });
  }

  onChangeFilterProductText(filterProductText: string) {
    this.bakeryMgmtProductsEffects.requestGetBakeryProducts2(filterProductText);
  }

  changeToCheckout = (checkoutState) => {
    this.checkout = checkoutState;
  }

  onChangeFilterCustomerText(filterCustomerText: string) {
    this.bakeryMgmtCustomersEffects.requestGetCustomers2(this.wholesaler, filterCustomerText);
  }

  get title() {
    return this.wholesaler ? 'New Wholesale Order' : 'New Order';
  }

  onChangeCustomer = (customer: User) => {
    if (customer) {
      this.selectedCustomer$.next(customer);
    } else {
      this.selectedCustomer$.next(NO_SELECTED_CUSTOMER);
    }
    this.store.dispatch({
      type: Actions.ADD_CUSTOMER_TO_EMPLOYEE_CART,
      payload: {
        employee_order: true,
        wholesaler_order: this.wholesaler,
        customer_id: customer ? customer.id : null,
        order_items_attributes: [],
      }
    });
  }

  onClickMobileCustomersActive() {
    this.isClassMobileCustomersActive = !this.isClassMobileCustomersActive;
    this.showMobileCustomersCloseAction = !this.showMobileCustomersCloseAction;
  }

  ngOnDestroy() {
    this.uiStateSub.unsubscribe();
    this.alertSub.unsubscribe();
  }
}
