import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import * as moment from 'moment/moment';

import {
  BakeryOrder,
  orderFulfillmentDateAscSorter
} from 'app/store/bakery-order';
import { cloneSort } from 'app/store/entity-utils';

@Component({
  selector: 'bkry-mgmt-next-order-date-tile',
  template: `
    <dashboard-tile
      bgColor="yellow-base"
      icon="calendar"
      iconAriaLabel="Calendar"
    >
      <div data-title>
        <ng-container *ngIf="hasNextOrder">
          {{ nextOrder.fulfillment_date | date:'M/d/y' }}
        </ng-container>
      </div>
      <div data-subtitle>Next order date for customer</div>
    </dashboard-tile>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NextOrderDateTileComponent {
  nextOrder: BakeryOrder = null;

  private _bakeryOrders: BakeryOrder[] = [];
  @Input()
  set bakeryOrders(bakeryOrders: BakeryOrder[]) {
    // Sort by fulfillment_date, descending
    this._bakeryOrders = cloneSort(bakeryOrders, orderFulfillmentDateAscSorter);

    const nowMoment = moment();
    this.nextOrder = this.bakeryOrders.find(bakeryOrder => {
      const fulfillmentDateMoment = moment(bakeryOrder.fulfillment_date);

      return fulfillmentDateMoment.isAfter(nowMoment);
    });
  }

  get bakeryOrders(): BakeryOrder[] {
    return this._bakeryOrders;
  }

  get hasNextOrder(): boolean {
    return this.nextOrder != null;
  }
}
