import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs/Observable';

@Component({
  selector: 'bakery-mgmt-dashboard-setup-status',
  template: require('./setup-status.component.html'),
})
export class SetupStatusComponent {
  @Input() setupStatus: any;

  onClickAddYourFirstOrder() {
    const button = document.getElementById('newOrderButton');
    if (!button) { return; }
    button.click();
  }
}
