import {
  Component,
  Input,
  Output,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';

import { AppState } from 'app/store/app-state';
import * as selectors from 'app/store/selectors';
import { User } from 'app/store/user';
import { WholesalerOrg } from 'app/store/wholesaler-org';
import { WholesalerGroup } from 'app/store/wholesaler-group';
import {
  wholesalerOrgSchema,
  wholesalerGroupSchema
} from 'app/store/schema/default-schemata';
import {
  GetAction,
  PutAction,
  PutActionPayload
} from 'app/store/actions/request.actions';
import { AlertAction } from 'app/shared/components/alerts/actions';
import { phoneNumberValidator } from 'app/shared/forms/form-number-validator';

const compSelector = 'bkry-cust-wholesaler-detail-settings';
const opKey = 'wholesalerDetailUpdateArchive',
      schema = wholesalerOrgSchema;

@Component({
  template: `
    <${compSelector}
      [wholesalerOrg]="wholesalerOrg$ | async"
      [isWholesalerOrgLoading]="isWholesalerOrgLoading$ | async"
      [isUpdateLoading]="isUpdateLoading$ | async"
      [countryCode$]="countryCode$"
      (onFormSubmit)="onFormSubmit$.next($event)"
    ></${compSelector}>
  `,
})
export class SettingsComponent implements OnInit, OnDestroy {
  countryCode$: Observable<string>;

  onFormSubmit$ = new Subject<any>();

  isUpdateLoading$: Observable<boolean> = this.store
    .select(selectors.getRequestIsLoading(opKey));

  isWholesalerOrgLoading$: Observable<boolean> = this.store
    .select(selectors.getCurrentUser)
    .map((user: User): boolean => user == null);

  wholesalerOrg$: Observable<WholesalerOrg> = this.store
    .select(selectors.getCurrentUser)
    .filter(val => !!val)
    .map((user: User) => user.wholesaler_org);

  private onFormSubmitSub: Subscription;

  constructor(
    private store: Store<AppState>,
  ) { }

  ngOnInit() {
    // Convert form submissions into PUT actions/requests
    this.onFormSubmitSub = this.onFormSubmit$
      .withLatestFrom(
        this.wholesalerOrg$,
        (formValue, wholesalerOrg) => ({formValue, wholesalerOrg})
      )
      .subscribe(({formValue, wholesalerOrg}) => {
        const actionPayload: PutActionPayload = {
          url: `/api/wholesalers/${wholesalerOrg.id}`,
          opKey: opKey,
          schema: schema,
          body: formValue,
          successChainAction: AlertAction.buildSuccess(`Your wholesaler settings have been updated.`)
        };
        this.store.dispatch(new PutAction(actionPayload));
      });

    this.countryCode$ = this.store
      .select(selectors.getCurrentCustBakery)
      .skipWhile(x => !x)
      .pluck('country_code');
  }

  ngOnDestroy() {
    this.onFormSubmitSub.unsubscribe();
  }
}

@Component({
  selector: compSelector,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: require('./settings.component.html'),
})
export class SettingsPrezComponent {
  private _wholesalerOrg: WholesalerOrg;
  @Input() countryCode$: Observable<string>;
  @Input()
  set wholesalerOrg(wholesalerOrg: WholesalerOrg) {
    this._wholesalerOrg = wholesalerOrg;

    if (this._wholesalerOrg != null) {
      this.resetForm();
    }
  }
  get wholesalerOrg(): WholesalerOrg {
    return this._wholesalerOrg;
  }

  @Input() isWholesalerOrgLoading = false;
  @Input() isUpdateLoading = false;

  @Output('onFormSubmit') onFormSubmit$ = new Subject<any>();

  form: FormGroup;

  constructor(
    private fb: FormBuilder
  ) {
    this.form = fb.group({
      avatar: fb.control(null, []),
      orgBasicDetails: fb.control(null, []),
      addresses_attributes: fb.control([], []),
      payment_cards_attributes: fb.control([], []),
    });
  }

  get isSubmittable(): boolean {
    return !this.isUpdateLoading && this.form.valid && this.form.dirty;
  }

  get isCancelable(): boolean {
    return !this.isUpdateLoading && this.form.dirty;
  }

  onFormCancel() {
    this.resetForm();
  }

  onFormSubmit() {
    // Flatten form value.
    const formValue = {
      avatar: this.form.value.avatar,
      ...this.form.value.orgBasicDetails,
      addresses_attributes: this.form.value.addresses_attributes,
      payment_cards_attributes: this.form.value.payment_cards_attributes
    };

    this.onFormSubmit$.next(formValue);
  }

  private resetForm() {
    this.form.reset(
      {
        avatar: this.wholesalerOrg.avatar,
        orgBasicDetails: {
          name: this.wholesalerOrg.name,
          email: this.wholesalerOrg.email,
          phone_number: this.wholesalerOrg.phone_number
        },
        addresses_attributes: [...this.wholesalerOrg.addresses],
        payment_cards_attributes: [...this.wholesalerOrg.payment_cards]
      },
      {emitEvent: false}
    );
  }
}
