import { OrderItem } from 'app/store/order-item';
import { BakeryOrderInvoice } from 'app/store/bakery-order-invoice';
import { TimestampedEntity } from 'app/store/timestamped-entity';
import { stringPropSorter } from 'app/store/entity-utils';

export interface BakeryOrder extends TimestampedEntity {
  id: number;
  order_status: string;
  order_number: number;
  bakery_id: number;
  total_price: number;
  total_discount_price: number;
  bakery_notes?: string;
  customer_notes?: string;
  order_items?: number[]|OrderItem[];
  order_items_count?: number;
  order_customer_id?: number;
  order_customer_email: string;
  order_customer_name: string;
  order_customer_first_name: string;
  order_customer_last_name: string;
  order_customer_phone_number: string;
  order_customer_notes: string;
  order_customer_type: string;

  payment_card_id?: number;

  invoice_id: number;
  invoice?: BakeryOrderInvoice;

  isActive?: boolean;
  isOnHold?: boolean;
  isCanceled?: boolean;
  isCompleted?: boolean;
  isOpen?: boolean;

  fulfillment_date: string;
  fulfillment_at: string;
  fulfillment_on: string;
  fulfillment_type: string;
  fulfillment_location: any;

  parent: any;
  order_schedule_id?: number;
}

export const allOrderStatuses = 'completed,canceled,submitted,hold';
export const orderFulfillmentDateDescSorter = stringPropSorter('fulfillment_date', false);
export const orderFulfillmentDateAscSorter = stringPropSorter('fulfillment_date', true);

const hasStatus = (order: object, status: string) => order['order_status'] === status;
export const isOpen = (order: object) => isActive(order) || isOnHold(order);
export const isActive = (order: object) => hasStatus(order, 'submitted');
export const isOnHold = (order: object) => hasStatus(order, 'hold');
export const isCanceled = (order: object) => hasStatus(order, 'canceled');
export const isCompleted = (order: object) => hasStatus(order, 'completed');
