import {
  Component,
  OnInit,
  OnDestroy,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { Subject } from 'rxjs/Subject';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

import { BakeryProductTemplate } from 'app/store/bakery-product-template';
import { BakeryProduct } from 'app/store/bakery-product';
import { BakeryAttribute } from 'app/store/bakery-attribute';
import { BakeryProductAttribute } from 'app/store/bakery-product-attribute';
import { UiState } from '../detail.component';
import { SimpleFormState } from 'app/shared/forms/form-states';
import { validateNoWhitespaces } from 'app/shared/forms/no-whitespaces-validator';

@Component({
  selector: 'bakery-mgmt-product-template-attribute-associations',
  template: require('./attribute-associations.component.html')
})
export class BakeryMgmtProductTemplateAttributeAssociationsComponent
  implements OnInit, OnDestroy {
  @Input('uiState$') uiState$: Observable<UiState>;
  @Input('success$') success$: Observable<any>;
  @Input('reset$') reset$: Observable<any>;
  @Output('formState') formState$ = new Subject<SimpleFormState>();

  form: FormGroup;
  selectedAssociations$ = new BehaviorSubject<BakeryProductAttribute[]>([]);
  selectableAttributes$: Observable<BakeryAttribute[]>;

  public formSubmit$ = new Subject<any>();
  private filteredProdAtt$: Observable<BakeryAttribute>;
  private formStateSub: Subscription;
  private formSubmitSub: Subscription;
  private selectedAssociationsSub: Subscription;

  constructor() {
    this.form = new FormGroup({
      attribute: new FormControl('', [Validators.required]),
      visible_name: new FormControl('', [Validators.required, validateNoWhitespaces])
    });
  }

  ngOnInit() {
    this.selectedAssociationsSub = Observable.merge(
      this.uiState$,
      this.reset$.switchMap(() => this.uiState$.take(1))
    )
      .filter((uiState: UiState) => !uiState.isLoading)
      .subscribe((uiState: UiState) => {
        this.selectedAssociations$.next(<BakeryProductAttribute[]>uiState
          .productTemplate.bakery_product_attributes);
      });

    this.selectableAttributes$ = this.uiState$
      .filter((uiState) => !uiState.isLoading)
      .map((uiState) => uiState.bakeryAttributes);

    this.formSubmitSub = this.formSubmit$
      .withLatestFrom(
        this.form.valueChanges,
        this.uiState$.filter((uiState: UiState) => !uiState.isLoading),
        (_, formValue, uiState) => ({ _, formValue, uiState })
      )
      .subscribe(({ formValue, uiState }) => {
        if (formValue.visible_name === '' || formValue.attribute === '')
          return null;
        const attributeId = parseInt(formValue.attribute);
        if (!isNaN(attributeId)) {
          this.form.reset({ attribute: '' });
          const bpa = {
            bakery_attribute_id: attributeId,
            visible_name: formValue.visible_name,
            bakery_attribute: uiState.bakeryAttributes.find(
              bkryAtt => bkryAtt.id === attributeId
            )
          };
          const associations = <BakeryProductAttribute[]>[
            ...this.selectedAssociations$.value,
            bpa
          ];
          this.selectedAssociations$.next(associations);
        }
      });

    this.formStateSub = Observable.combineLatest(
      this.selectedAssociations$,
      this.uiState$.filter((uiState: UiState) => !uiState.isLoading),
      (selectedAssociations, uiState) => ({ selectedAssociations, uiState })
    )
      .map(({ selectedAssociations, uiState }) => {
        const existingAssociations = <BakeryProductAttribute[]>uiState
          .productTemplate.bakery_product_attributes;

        const newAssociations = selectedAssociations.filter(selectedAssoc => {
          const match = existingAssociations.find(existingAssoc => {
            return (
              (
                existingAssoc.visible_name === selectedAssoc.visible_name &&
                <BakeryAttribute>existingAssoc.bakery_attribute
              ).id === (<BakeryAttribute>selectedAssoc.bakery_attribute).id
            );
          });

          return match == null;
        });

        const removedAssociations = existingAssociations.filter(
          existingAssoc => {
            const match = selectedAssociations.find(selectedAssoc => {
              return (
                (
                  existingAssoc.visible_name === selectedAssoc.visible_name &&
                  <BakeryAttribute>existingAssoc.bakery_attribute
                ).id === (<BakeryAttribute>selectedAssoc.bakery_attribute).id
              );
            });

            return match == null;
          }
        );

        return { newAssociations, removedAssociations };
      })
      .map(({ newAssociations, removedAssociations }) => {
        const preppedRemovedAssocs = removedAssociations.map(assoc => {
          return Object.assign({}, assoc, { _destroy: true });
        });

        const attributesValueArray = [
          ...newAssociations,
          ...preppedRemovedAssocs
        ];

        return {
          isValid: true, // this form is always valid
          isDirty: !!newAssociations.length || !!removedAssociations.length,
          value: {
            bakery_product_attributes_attributes: attributesValueArray
          }
        };
      })
      .subscribe(val => this.formState$.next(val));
  }

  onClickRemoveAssociation(
    bakeryAttributeId: number
  ) {
    const selectedAssociations = this.selectedAssociations$.value;
    this.selectedAssociations$.next(
      selectedAssociations.filter(bpa => {
        return !(
          (<BakeryAttribute>bpa.bakery_attribute).id === bakeryAttributeId
        );
      })
    );
  }

  onClickFormSubmit() {
    this.formSubmit$.next();
  }

  ngOnDestroy() {
    this.formStateSub.unsubscribe();
    this.formSubmitSub.unsubscribe();
    this.selectedAssociationsSub.unsubscribe();
  }
}
