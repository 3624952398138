import {
  Component,
  Input,
  Output,
  ChangeDetectionStrategy,
  EventEmitter
} from '@angular/core';

@Component({
  selector: 'form-submit-cancel-control',
  template: `
    <div class="row">
      <div class="columns-9__m columns-12__l margin-bottom--delta float--right">
        <button
          (click)="onClickCancel()"
          class="button--secondary button--input margin-right--delta button--disabled"
          [ngClass]="{'button--disabled': !isCancelable}"
          [disabled]="!isCancelable"
        >
          Cancel
        </button>
        <button
          (click)="onClickSubmit()"
          type="submit"
          class="button--primary button--input"
          [ngClass]="{'button--disabled': !isSubmittable}"
          [disabled]="!isSubmittable"
        >
          {{ !isLoading ? submitText : submittingText }}
        </button>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormSubmitCancelControlComponent {
  @Input() isSubmittable = false;
  @Input() isCancelable = false;
  @Input() isLoading = false;
  @Input() submitText = 'Submit';
  @Input() submittingText = 'Submitting';
  @Output('onSubmit') onSubmit$ = new EventEmitter<void>();
  @Output('onCancel') onCancel$ = new EventEmitter<void>();

  onClickSubmit() {
    this.onSubmit$.next();
  }

  onClickCancel() {
    this.onCancel$.next();
  }
}
