import { Component, Input, Output, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';

import { UiState } from '../master.component';
import { validateNoWhitespaces } from 'app/shared/forms/no-whitespaces-validator';

const HELP_DISMISSED = 'productsHelpDismissed';

@Component({
  selector: 'bakery-mgmt-prod-mgmt-products-master-header',
  template: require('./header.component.html'),
})
export class MasterHeaderComponent implements OnInit {
  @Input() uiState$: Observable<UiState>;
  @Output('changeFilterText') changeFilterText$: Observable<string>;

  filterForm: FormGroup;

  private showHelp = new Subject<boolean>();
  showHelp$: Observable<boolean>;

  constructor(
    private router: Router,
  ) {
    this.filterForm = new FormGroup({
      'search': new FormControl(null, [Validators.required, validateNoWhitespaces]),
    });

    this.changeFilterText$ = this.filterForm.get('search').valueChanges
      .map((filterText: string) => filterText.trim())
      .debounce(() => Observable.timer(500));
  }

  onClickAddProduct() {
    this.router.navigate(['/bakery_mgmt/product_mgmt/products/add_product']);
  }

  dismissHelp() {
    this.showHelp.next(false);
  }

  ngOnInit() {
    const key = this.uiState$.map(uiState => `${HELP_DISMISSED}-${uiState.currentUserId}`);

    this.showHelp$ = key
      .map(k => window.localStorage.getItem(k) === null)
      .take(1)
      .concat(this.showHelp.asObservable())
      .scan((acc, show) => acc && show, true)
      .distinctUntilChanged()
      .withLatestFrom(key)
      .do(([show, k]) => show ? null : window.localStorage.setItem(k, 'yes'))
      .map(([show, k]) => show);
  }
}
