import { Component, OnInit, Input, Output, ViewChild, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UiState } from 'app/bakery-mgmt/new-order/master/master.component';
import { BakeryProduct } from 'app/store/bakery-product';
import { Subscription } from 'rxjs/Subscription';
import { Actions } from 'app/store/actions';
import { Actions as NgRxActions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { OrderItem } from 'app/store/order-item';
import { AlertService } from 'app/shared/components/alerts/alert.service';
import { AppState } from 'app/store/app-state';
import { User } from 'app/store/user';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'bakery-mgmt-new-order-order-list-item',
  template: require('./order-list-item.component.html'),

})
export class BakeryMgmtNewOrderOrderListItemComponent {
  private _orderItems: OrderItem[];
  @Input()
  set orderItems(orderItems: OrderItem[]) {
    this._orderItems = orderItems || [];
  }

  get orderItems(): OrderItem[] {
    return this._orderItems;
  }

  @Input() changeToCheckout: Function;
  @Input() selectedCustomer: User;
  @Input() checkout: boolean;
  @Input() employee: boolean;
  @Input() wholesaler: boolean;

  constructor(
    private actions$: NgRxActions,
    private store: Store<AppState>
  ) {
  }

  getAttributes(item) {
    const attributes = item.meta.template_variants.map(variant => (
      `${variant.bakery_product_attribute.visible_name}: <strong>${variant.bakery_attribute_variant.name}</strong><br>`
    )).concat(item.meta.attribute_variants.map(variant => (
      `${variant.bakery_product_attribute.visible_name}: <strong>${variant.bakery_attribute_variant.name}</strong><br>`
    )));

    return attributes.join('');
  }

  onClickRemoveOrderItem(orderItem: OrderItem) {
    this.store.dispatch({
      type: this.employee ? Actions.REQUEST_UPDATE_EMPLOYEE_CART : Actions.REQUEST_UPDATE_WHOLESALE_CART,
      payload: {
        wholesaler_order: this.wholesaler,
        customer_id: this.selectedCustomer && this.wholesaler ? this.selectedCustomer.id : null,
        order_items_attributes: [{
          id: orderItem.id,
          _destroy: true,
        }]
      }
    });
  }
}
