import { Component, OnInit, OnDestroy, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';

import { User } from 'app/store/user';
import { SimpleFormState } from 'app/shared/forms/form-states';
import { phoneNumberValidator } from 'app/shared/forms/form-number-validator';
import { validateNoWhitespaces } from 'app/shared/forms/no-whitespaces-validator';

@Component({
  selector: 'bakery-cust-account-setting-user-detail-edit',
  template: require('./user-detail-edit.component.html'),
})
export class BakeryCustAccountSettingUserDetailEditComponent implements OnInit, OnDestroy {
  @Input() countryCode$: Observable<string>;
  @Input() user$: Observable<User>;
  @Input() reset$: Observable<any>;
  @Output('formState') formState$ = new Subject<SimpleFormState>();

  form: FormGroup;

  private formValuesSub: Subscription;
  private resetFormSub: Subscription;
  private formStateSub: Subscription;

  constructor(
  ) {
    this.form = new FormGroup({
      first_name: new FormControl(null, [Validators.required, validateNoWhitespaces]),
      last_name: new FormControl(null, [Validators.required, validateNoWhitespaces]),
      email: new FormControl(null, [Validators.required, Validators.email]),
      phone_number: new FormControl(null, [phoneNumberValidator]),
      street: new FormControl(null, []),
      unit: new FormControl(null, []),
      city: new FormControl(null, []),
      state: new FormControl(null, []),
      zip: new FormControl(null, []),
    });
  }

  ngOnInit() {
    this.formStateSub = this.form.valueChanges
      .withLatestFrom(
        this.user$,
        (formValue, user) => ({user, formValue})
      )
      .map(({user, formValue}): SimpleFormState => {
        const preparedFormValues = {
          id: user.id,
          first_name: formValue.first_name,
          last_name: formValue.last_name,
          phone_number: formValue.phone_number,
          default_address_attributes: {
            id: user.default_address_id,
            parent_type: 'User',
            parent_id: user.id,
            street: formValue.street,
            unit: formValue.unit,
            city: formValue.city,
            state: formValue.state,
            zip: formValue.zip,
          },
        };

        return {
          isDirty: this.form.dirty,
          isValid: this.form.valid,
          value: preparedFormValues,
        };
      })
      .subscribe(formState => this.formState$.next(formState));

    this.formValuesSub = this.user$
      .subscribe((user: User) => {
        this.resetForm(user);
      });

    this.resetFormSub = this.reset$
      .switchMap(() => this.user$.take(1))
      .subscribe((user: User) => {
        this.resetForm(user);
        this.resetForm(user);
      });
  }

  ngOnDestroy() {
    this.formValuesSub.unsubscribe();
    this.resetFormSub.unsubscribe();
    this.formStateSub.unsubscribe();
  }

  private resetForm(user: User) {
    this.form.reset(this.buildFormValue(user));
  }

  private buildFormValue(user: User) {
    let formValue = null;

    if (user.default_address != null) {
      formValue = {
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        phone_number: user.phone_number,
        street: user.default_address.street,
        unit: user.default_address.unit,
        city: user.default_address.city,
        state: user.default_address.state,
        zip: user.default_address.zip,
      };
    } else {
      formValue = {
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        phone_number: user.phone_number,
      };
    }

    return formValue;
  }
}
