import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import * as Rx from 'rxjs';
import { Actions as NgRxActions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { LocalStorageService } from 'ngx-webstorage';

import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { AppState } from 'app/store/app-state';
import { User } from 'app/store/user';
import { UsersEffects } from 'app/store/effects/users.effects';
import { BakeryCustEffects } from 'app/store/effects/bakery-cust/bakery-cust.effects';
import { Actions } from 'app/store/actions';

@Component({
  template: `
    <bakery-cust-header-nav></bakery-cust-header-nav>
    <section role="region" class="application-page__section application-page__section--small bakery-cust-app-wrap">
      <router-outlet></router-outlet>
    </section>
    <bakery-cust-footer-nav></bakery-cust-footer-nav>
  `
})
export class BakeryCustComponent implements OnInit, OnDestroy {
  private authGetCartSub: Rx.Subscription;

  constructor(
    private usersEffects: UsersEffects,
    private bakeryCustEffects: BakeryCustEffects,
    private actions$: NgRxActions,
    private router: Router,
    private store: Store<AppState>,
    private localStore: LocalStorageService,
  ) {
  }

  get currentBakery$() {
    return this.bakeryCustEffects.currentBakery$;
  }

  get isLoading$(): Rx.Observable<boolean> {
    return this.currentBakery$.map((bakery) => bakery == null);
  }

  ngOnInit() {
    const custCartId: string = this.localStore.retrieve('custCartId');
    this.store.dispatch({
      type: Actions.REQUEST_GET_CUST_CART,
      payload: custCartId,
    });

    this.authGetCartSub = this.actions$
      .ofType(
        Actions.REQUEST_VALIDATE_TOKEN_SUCCESS,
        Actions.REQUEST_USER_SIGN_IN_SUCCESS
      )
      .subscribe((action: UnsafeAction) => {
        this.store.dispatch({
          type: Actions.REQUEST_GET_CUST_CART,
        });
      });
  }

  ngOnDestroy() {
    this.authGetCartSub.unsubscribe();
  }
}
