import { UnsafeAction } from 'app/store/effects/unsafe-action';
import { Actions } from 'app/store/actions';

export type State = any[];

const INITIAL_STATE = [];

export function reducer(state: State = INITIAL_STATE, action: UnsafeAction): State {
  switch (action.type) {
    case Actions.REQUEST_DECORATING_REPORT_SUCCESS:
      return action.payload;

    default:
      return state;
  }
}
