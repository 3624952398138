import { FormGroup } from '@angular/forms';

export function passwordMatchValidatorBuilder(passwordControlName: string, confirmationControlName: string) {
  return (formGroup: FormGroup) => {
    const passwordValue = formGroup.get(passwordControlName).value,
          confirmationValue = formGroup.get(confirmationControlName).value;

    return passwordValue === confirmationValue ? null : {'mismatch': true};
  }
}
