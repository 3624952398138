import { Component, OnInit } from '@angular/core';

import { BakeryMgmtEmployeesEffects } from 'app/store/effects/bakery-mgmt-employees.effects';

@Component({
  template: `
    <router-outlet></router-outlet>
  `
})
export class BakeryMgmtEmployeesComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}
