import { Component, OnInit, OnDestroy, Output, Input } from '@angular/core';

import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import { Store } from '@ngrx/store';
import { Actions as NgRxActions } from '@ngrx/effects';
import { UnsafeAction } from 'app/store/effects/unsafe-action';
import {
  BakeryMgmtPromoCodesEffects,
  UiState
} from 'app/store/effects/bakery-mgmt/bakery-mgmt-promo-codes.effects';
import { Actions } from 'app/store/actions';
import { UsersEffects } from 'app/store/effects/users.effects';
import { AlertService } from 'app/shared/components/alerts/alert.service';
import { PromoCode } from 'app/store/promo-code';
import { AppState } from 'app/store/app-state';
import { EntitiesState } from 'app/store/entities-state';
import { entitiesArray, shallowClone } from 'app/store/entities-state-utils';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'bakery-mgmt-promo-codes-list',
  template: require('./list.component.html'),
})
export class BakeryMgmtPromoCodesListComponent implements OnInit, OnDestroy {
  editablePromoCode$ = new Subject<PromoCode>();
  filters$ = new Subject<boolean>();

  private alertsSub: Subscription;

  constructor(
    private bakeryMgmtPromoCodesEffects: BakeryMgmtPromoCodesEffects,
    private usersEffects: UsersEffects,
    private alertService: AlertService,
    private actions$: NgRxActions,
    private store: Store<AppState>,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    const routeData = this.route.snapshot.data as {status: string};
    this.bakeryMgmtPromoCodesEffects.changeStatusFilter(routeData.status);


    this.alertsSub = this.actions$
      .subscribe((action: UnsafeAction) => {
        let messageType = null,
            messageContent = null;

        switch (action.type) {
          case Actions.REQUEST_DELETE_PROMO_CODE_SUCCESS:
            messageType = 'success';
            messageContent = `Promotional Code has been deleted.`;
            break;

          case Actions.REQUEST_DELETE_PROMO_CODE_ERROR:
            messageType = 'warning';
            messageContent = `Promotional Code deletion failed.`;
            break;
        }

        if (messageType != null && messageContent != null) {
          this.alertService[messageType](messageContent);
        }
      });
  }

  get uiState$() {
    return this.bakeryMgmtPromoCodesEffects.uiState$;
  }

  ngOnDestroy() {
    this.alertsSub.unsubscribe();
  }
}
