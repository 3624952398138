import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';

import { User } from 'app/store/user';
import { UsersEffects } from 'app/store/effects/users.effects';


@Component({
  selector: 'subscription-header-nav',
  template: require('./header-nav.component.html'),
})
export class SubscriptionHeaderNavComponent {
  isClassMobileMenuActive = false;
  isAccountPaused = false;
  constructor(
    private usersEffects: UsersEffects,
    private router: Router,
  ) {
    this.currentUser$.subscribe(user => {
      this.isAccountPaused = user && user.bakery_account_status === 'paused';
    });
  }

  get currentUser$(): Observable<User> {
    return this.usersEffects.currentUser$;
  }


  onClickMobileMenuActive() {
    this.isClassMobileMenuActive = !this.isClassMobileMenuActive
  }

  onClickSignOut() {
    this.usersEffects.requestUserSignOut();
  }

  onClickAccountDashboard() {
    this.router.navigate(['/bakery_mgmt']);
  }

  onClickAccountSettings() {
    this.router.navigate(['/bakery_mgmt/account_settings']);
  }
}
