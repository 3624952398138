import {Pipe, PipeTransform} from '@angular/core';
import { BakeryProduct } from 'app/store/bakery-product';
import { BakeryProductTemplate } from 'app/store/bakery-product-template';
import { OrderItem } from 'app/store/order-item';

const DEFAULT_OPTIONS = {
  quantity: true,
};

@Pipe({name: 'orderItemName'})
export class OrderItemNamePipe implements PipeTransform {
  public transform(orderItem: OrderItem, options: any) {
    const mergedOptions = {
      ...DEFAULT_OPTIONS,
      ...options,
    };

    let result = '???';

    if (
      orderItem == null
      ||
      orderItem.bakery_product == null
      ||
      orderItem.bakery_product_template == null
    ) {
      return result;
    }
    result = `${(orderItem.bakery_product as BakeryProduct).name} - ${(orderItem.bakery_product_template as BakeryProductTemplate).name}`;

    if (mergedOptions.quantity) {
      result = `${orderItem.quantity} x ${result}`;
    }

    return result;
  }
}
