import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  template: `
    <bakery-mgmt-page-header title="Manage Wholesale Customers"></bakery-mgmt-page-header>
    <div class="tabs">
      <ul class="tab__list tab__list--light margin-bottom--charlie">
        <li class="tab__item"
          routerLink="active"
          routerLinkActive="is-active">
          Active
        </li>
        <li class="tab__item"
          routerLink="archived"
          routerLinkActive="is-active">
          Archived
        </li>
        <li class="tab__item"
          routerLink="groups"
          routerLinkActive="is-active">
          Groups
        </li>
      </ul>
    </div>
    <alert-container></alert-container>
    <router-outlet></router-outlet>
  `,
})
export class ListsComponent {
}
