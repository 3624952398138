// tslint:disable:max-line-length
import { NgModule } from '@angular/core';
import {
  RouterModule,
  Routes
} from '@angular/router';

import { PageNotFoundComponent } from 'app/shared/components/page-not-found/page-not-found.component';

import { BakesmartSignInComponent } from './sign-in/sign-in.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AccountConfirmationComponent } from './account-confirmation/account-confirmation.component';
import { BakeryOwnerSelfInviteComponent } from 'app/marketing/bakery-owner-self-invite/bakery-owner-self-invite.component';

import { BakeryMgmtComponent } from 'app/bakery-mgmt/bakery-mgmt.component';
import * as bkryMgmtDashboard from 'app/bakery-mgmt/dashboard';
import { BakeryMgmtStoreMgmtComponent } from 'app/bakery-mgmt/store-mgmt/store-mgmt.component';
import { BakeryMgmtStoreMainComponent } from 'app/bakery-mgmt/store-mgmt/main/main.component';
import { BakeryMgmtStoreMainLocationsComponent } from 'app/bakery-mgmt/store-mgmt/main/locations/locations.component';
import { BakeryMgmtStoreMainPaymentsComponent } from 'app/bakery-mgmt/store-mgmt/main/payments/payments.component';
import { BakeryMgmtStoreMainSettingsComponent } from 'app/bakery-mgmt/store-mgmt/settings/settings.component';
import { BakeryMgmtAccountSettingsComponent } from 'app/bakery-mgmt/account-settings/account-settings.component';
import { BakeryMgmtStoreAddLocationComponent } from 'app/bakery-mgmt/store-mgmt/add-location/add-location.component';
import { BakeryMgmtStoreLocationDetailComponent } from 'app/bakery-mgmt/store-mgmt/location-detail/location-detail.component';

import { BakeryMgmtEmployeesComponent } from 'app/bakery-mgmt/employees/employees.component';
import { BakeryMgmtEmployeesMasterComponent } from 'app/bakery-mgmt/employees/master/master.component';
import { BakeryMgmtEmployeesDetailComponent } from 'app/bakery-mgmt/employees/detail/detail.component';

import { BakeryMgmtNewOrderComponent } from 'app/bakery-mgmt/new-order/new-order.component';
import { BakeryMgmtNewOrderMasterComponent } from 'app/bakery-mgmt/new-order/master/master.component';

import { BakeryMgmtCustomersComponent } from 'app/bakery-mgmt/customers/customers.component';
import { BakeryMgmtCustomersMasterComponent } from 'app/bakery-mgmt/customers/master/master.component';
import { BakeryMgmtCustomersDetailComponent } from 'app/bakery-mgmt/customers/detail/detail.component';
import { BakeryMgmtCustomersDetailOrderHistoryComponent } from 'app/bakery-mgmt/customers/detail/order-history/order-history.component';
import { BakeryMgmtCustomersDetailSettingsComponent } from 'app/bakery-mgmt/customers/detail/settings/settings.component';

import { BakeryMgmtPromoCodesComponent } from 'app/bakery-mgmt/promo-codes/promo-codes.component';
import { BakeryMgmtPromoCodesMasterComponent } from 'app/bakery-mgmt/promo-codes/master/master.component';

import { BakeryMgmtProductMgmtComponent } from 'app/bakery-mgmt/product-mgmt/product-mgmt.component';
import { BakeryMgmtProductMainComponent } from 'app/bakery-mgmt/product-mgmt/main/main.component';
import { BakeryMgmtProductProductsMasterComponent } from 'app/bakery-mgmt/product-mgmt/products/master/master.component';
import { BakeryMgmtProductProductAddComponent } from 'app/bakery-mgmt/product-mgmt/products/add/add.component';
import { BakeryMgmtProductProductDetailComponent } from 'app/bakery-mgmt/product-mgmt/products/detail/detail.component';
import { BakeryMgmtProductCategoriesMasterComponent } from 'app/bakery-mgmt/product-mgmt/categories/master/master.component';
import { BakeryMgmtProductCategoryDetailComponent } from 'app/bakery-mgmt/product-mgmt/categories/detail/detail.component';
import { BakeryMgmtProductTemplatesMasterComponent } from 'app/bakery-mgmt/product-mgmt/templates/master/master.component';
import { BakeryMgmtProductTemplateDetailComponent } from 'app/bakery-mgmt/product-mgmt/templates/detail/detail.component';
import { BakeryMgmtProductTemplateAddComponent } from 'app/bakery-mgmt/product-mgmt/templates/add/add.component';
import { BakeryMgmtProductAttributesMasterComponent } from 'app/bakery-mgmt/product-mgmt/attributes/master/master.component';
import { BakeryMgmtProductAttributeDetailComponent } from 'app/bakery-mgmt/product-mgmt/attributes/detail/detail.component';
import { BakeryMgmtProductAttributeAddComponent } from 'app/bakery-mgmt/product-mgmt/attributes/add/add.component';

import { BakeryMgmtReportingComponent } from 'app/bakery-mgmt/reporting/reporting.component';
import { BakeryMgmtReportingSalesTaxComponent } from 'app/bakery-mgmt/reporting/sales-tax/sales-tax.component';
import { BakeryMgmtReportingProductionCheckListComponent } from 'app/bakery-mgmt/reporting/production-check-list/production-check-list.component';

import * as orderMgmt from 'app/bakery-mgmt/order-mgmt';

import { SubscriptionComponent } from './subscription/subscription.component';
import { SubscriptionInvoiceComponent } from './subscription/invoice/invoice.component';

import { RoleRouteGuard } from 'app/shared/role-route-guard.service';
import { BakeryRouteGuard } from 'app/bakery-cust/bakery-route-guard.service';
import { WholesalerCustomerRouteGuard } from 'app/shared/wholesaler-customer-route-guard.service';

import * as bkryMgmtWholesalers from 'app/bakery-mgmt/wholesalers';

import * as bkryMgmtWholesalersLists from 'app/bakery-mgmt/wholesalers/lists';
import * as bkryMgmtWholesalersDetail from 'app/bakery-mgmt/wholesalers/wholesaler-detail';
import * as bkryMgmtWholesalersGroupDetail from 'app/bakery-mgmt/wholesalers/group-detail';
import * as bkryMgmtWholesalersInvoiceDetail from 'app/bakery-mgmt/wholesalers/invoice-detail';
import * as bkryMgmtWholesalersCreateInvoice from 'app/bakery-mgmt/wholesalers/create-invoice';

import * as bakeryCust from 'app/bakery-cust';
import * as bakeryCustComponentsWholesaler from 'app/bakery-cust/wholesale';

import { BakeryCustComponent } from 'app/bakery-cust/bakery-cust.component';
import { BakeryCustProductDetailComponent } from 'app/bakery-cust/product/detail/detail.component';
import { BakeryCustSignInComponent } from 'app/bakery-cust/sign-in/sign-in.component';
import { BakeryCustForgotPasswordComponent } from 'app/bakery-cust/forgot-password/forgot-password.component';
import { BakeryCustResetPasswordComponent } from 'app/bakery-cust/reset-password/reset-password.component';
import { BakeryCustRegisterComponent } from 'app/bakery-cust/register/register.component';
import { BakeryCustAccountConfirmationComponent } from 'app/bakery-cust/account-confirmation/account-confirmation.component';
import { BakeryCustAccountSettingsComponent } from 'app/bakery-cust/account-settings/account-settings.component';
import { BakeryCustCartComponent } from 'app/bakery-cust/cart/cart.component';
import { BakeryCustCheckoutComponent } from 'app/bakery-cust/checkout/checkout.component';
import { BakeryCustOrderHistoryMasterComponent } from 'app/bakery-cust/order-history/master/master.component';
import { BakeryMgmtReportingDecoratingComponent } from './bakery-mgmt/reporting/decorating/decorating.component';
import { BakeryMgmtReportingItemSummaryComponent } from './bakery-mgmt/reporting/item-summary/item-summary.component';
import { BakeryMgmtReportingAttributeSummaryComponent } from './bakery-mgmt/reporting/attribute-summary/attribute-summary.component';

const pageHeight100 = {
  bodyClass: 'application-page--dark--100'
};

const storeSignInUrl = '/store/sign_in';

const custRouteGuardData = {
  ...pageHeight100,
  allowableRoles: ['bakery_customer'],
  signInUrl: storeSignInUrl,
};

const routes: Routes = [
  { path: '',  component: BakesmartSignInComponent, data: {bodyClass: 'application-page--dark'} },

  { path: 'account_confirmation', component: AccountConfirmationComponent, data: {bodyClass: 'application-page--dark'} },
  { path: 'forgot_password', component: ForgotPasswordComponent, data: {bodyClass: 'application-page--dark'} },
  { path: 'reset_password', component: ResetPasswordComponent, data: {bodyClass: 'application-page--dark'} },
  { path: 'sign_in', component: BakesmartSignInComponent, data: {bodyClass: 'application-page--dark'} },
  { path: 'sign_up', component: BakeryOwnerSelfInviteComponent, data: {bodyClass: 'application-page--dark'} },

  { path: 'bakery_mgmt', component: BakeryMgmtComponent, canActivate: [RoleRouteGuard], data: {allowableRoles: ['bakery_owner', 'bakery_manager', 'bakery_employee']}, children: [{
    path: '',
    canActivateChild: [RoleRouteGuard],
    children: [
      { path: 'dashboard', component: bkryMgmtDashboard.DashboardComponent },
      { path: 'account_settings', component: BakeryMgmtAccountSettingsComponent },
      { path: 'store_mgmt', component: BakeryMgmtStoreMgmtComponent, children: [
        { path: '', redirectTo: 'main', pathMatch: 'full' },
        { path: 'main', component: BakeryMgmtStoreMainComponent, children: [
          { path: '', redirectTo: 'locations', pathMatch: 'full' },
          { path: 'locations', component: BakeryMgmtStoreMainLocationsComponent },
          { path: 'payments', component: BakeryMgmtStoreMainPaymentsComponent, children: [
            // { path: 'stripe_connected', component: BakeryMgmtStoreStripeConnectedComponent }
          ] },
          { path: 'settings', component: BakeryMgmtStoreMainSettingsComponent },
        ]},
        { path: 'add_location', component: BakeryMgmtStoreAddLocationComponent },
        { path: 'locations/:location_id', component: BakeryMgmtStoreLocationDetailComponent },
      ]},
      { path: 'product_mgmt', component: BakeryMgmtProductMgmtComponent, children: [
        { path: '', redirectTo: 'main', pathMatch: 'full' },
        { path: 'main', component: BakeryMgmtProductMainComponent, children: [
          { path: '', redirectTo: 'active_products', pathMatch: 'full' },
          { path: 'active_products', component: BakeryMgmtProductProductsMasterComponent, data: { archive_filter: false } },
          { path: 'archived_products', component: BakeryMgmtProductProductsMasterComponent, data: { archive_filter: true } },
          { path: 'categories', component: BakeryMgmtProductCategoriesMasterComponent },
          { path: 'templates', component: BakeryMgmtProductTemplatesMasterComponent },
          { path: 'attributes', component: BakeryMgmtProductAttributesMasterComponent },
        ]},
        { path: 'categories/:category_id', component: BakeryMgmtProductCategoryDetailComponent },
        { path: 'templates/add_template', component: BakeryMgmtProductTemplateAddComponent },
        { path: 'templates/:template_id', component: BakeryMgmtProductTemplateDetailComponent },
        { path: 'attributes/add_attribute', component: BakeryMgmtProductAttributeAddComponent },
        { path: 'attributes/:attribute_id', component: BakeryMgmtProductAttributeDetailComponent },
        { path: 'products/add_product', component: BakeryMgmtProductProductAddComponent },
        { path: 'products/:product_id', component: BakeryMgmtProductProductDetailComponent },
      ]},
      { path: 'order_mgmt', component: orderMgmt.BakeryMgmtOrderMgmtComponent, children: [
        { path: '', redirectTo: 'orders', pathMatch: 'full' },
        { path: 'orders', component: orderMgmt.BakeryMgmtOrderMasterComponent, children: [
          { path: '', redirectTo: 'active', pathMatch: 'full' },
          { path: 'active', component: orderMgmt.BakeryMgmtOrderMasterListComponent, data: { order_status: 'submitted' } },
          { path: 'on_hold', component: orderMgmt.BakeryMgmtOrderMasterListComponent, data: { order_status: 'hold' } },
          { path: 'finished', component: orderMgmt.BakeryMgmtOrderMasterListComponent, data: { order_status: 'completed,canceled' } },
        ]},
        { path: 'orders/:order_id', component: orderMgmt.OrderDetailComponent, children: [
          { path: '', redirectTo: 'overview', pathMatch: 'full' },
          { path: 'overview', component: orderMgmt.OverviewComponent },
          { path: 'edit', component: orderMgmt.EditComponent },
          { path: 'notes', component: orderMgmt.NotesComponent },
          { path: 'billing', component: orderMgmt.BillingComponent },
        ] },
      ]},
      { path: 'employees', component: BakeryMgmtEmployeesComponent, children: [
        { path: '', component: BakeryMgmtEmployeesMasterComponent },
        { path: ':user_id', component: BakeryMgmtEmployeesDetailComponent },
      ]},
      { path: 'promo_codes', component: BakeryMgmtPromoCodesComponent, children: [
        { path: '', redirectTo: 'active', pathMatch: 'full' },
        { path: 'active', component: BakeryMgmtPromoCodesMasterComponent, data: { status: 'active' } },
        { path: 'pending', component: BakeryMgmtPromoCodesMasterComponent, data: { status: 'pending' } },
        { path: 'expired', component: BakeryMgmtPromoCodesMasterComponent, data: { status: 'expired' } }
      ]},
      { path: 'new_order', component: BakeryMgmtNewOrderComponent, children: [
        { path: '', component: BakeryMgmtNewOrderMasterComponent, data: { wholesaler: false } },
      ]},
      { path: 'new_wholesaler_order', component: BakeryMgmtNewOrderComponent, children: [
        { path: '', component: BakeryMgmtNewOrderMasterComponent, data: { wholesaler: true } },
      ]},
      { path: 'customers', component: BakeryMgmtCustomersComponent, children: [
        { path: '', component: BakeryMgmtCustomersMasterComponent },
        { path: ':user_id', component: BakeryMgmtCustomersDetailComponent, children: [
          {path: '', redirectTo: 'order_history', pathMatch: 'full'},
          {path: 'order_history', component: BakeryMgmtCustomersDetailOrderHistoryComponent},
          {path: 'settings', component: BakeryMgmtCustomersDetailSettingsComponent},
        ]},
      ]},
      { path: 'wholesalers', component: bkryMgmtWholesalers.WholesalersComponent, children: [
        { path: '', redirectTo: 'lists', pathMatch: 'full' },
        { path: 'lists', component: bkryMgmtWholesalersLists.ListsComponent, children: [
          { path: '', redirectTo: 'active', pathMatch: 'full' },
          { path: 'active', component: bkryMgmtWholesalersLists.WholesalerListComponent, data: { archived: false } },
          { path: 'archived', component: bkryMgmtWholesalersLists.WholesalerListComponent, data: { archived: true } },
          { path: 'groups', component: bkryMgmtWholesalersLists.GroupListComponent },
        ]},
        { path: 'wholesaler/:wholesaler_id', component: bkryMgmtWholesalersDetail.DetailComponent, children: [
          { path: '', redirectTo: 'orders', pathMatch: 'full' },
          { path: 'orders', component: bkryMgmtWholesalersDetail.OrdersComponent },
          { path: 'invoices', component: bkryMgmtWholesalersDetail.InvoicesComponent },
          { path: 'users', component: bkryMgmtWholesalersDetail.UsersComponent },
          { path: 'settings', component: bkryMgmtWholesalersDetail.SettingsComponent },
        ] },
        { path: 'group/:group_id', component: bkryMgmtWholesalersGroupDetail.DetailComponent, children: [
          { path: '', redirectTo: 'settings', pathMatch: 'full' },
          { path: 'settings', component: bkryMgmtWholesalersGroupDetail.SettingsComponent },
          { path: 'price_index', component: bkryMgmtWholesalersGroupDetail.PriceIndexComponent },
        ] },
        { path: 'wholesaler/:wholesaler_id/invoice/create', component: bkryMgmtWholesalersCreateInvoice.CreateInvoiceComponent },
        { path: 'invoice/:invoice_id', component: bkryMgmtWholesalersInvoiceDetail.DetailComponent },
      ]},
      { path: 'reporting', component: BakeryMgmtReportingComponent, children: [
        { path: '', redirectTo: 'sales_tax', pathMatch: 'full' },
        { path: 'sales_tax', component: BakeryMgmtReportingSalesTaxComponent },
        { path: 'production_check_list', component: BakeryMgmtReportingProductionCheckListComponent },
        { path: 'decorating', component: BakeryMgmtReportingDecoratingComponent },
        { path: 'item_summary', component: BakeryMgmtReportingItemSummaryComponent },
        { path: 'attribute_summary', component: BakeryMgmtReportingAttributeSummaryComponent },
      ]},
      { path: '**', redirectTo: 'dashboard', pathMatch: 'full' }
    ],
  }]},

  { path: 'subscription', component: SubscriptionComponent, canActivate: [RoleRouteGuard], data: {allowableRoles: ['bakery_owner'], bodyClass: 'application-page--dark'} },
  { path: 'subscription/invoices/:invoice_id', component: SubscriptionInvoiceComponent, canActivate: [RoleRouteGuard], data: {allowableRoles: ['bakery_owner'], bodyClass: 'application-page--dark'} },

  {
    path: 'store',
    component: BakeryCustComponent,
    canActivate: [BakeryRouteGuard],
    data: pageHeight100,
    children: [
      // Public routes (guest accessible)
      {
        path: 'sign_in',
        component: BakeryCustSignInComponent,
        data: pageHeight100,
      },
      {
        path: 'products/:product_id',
        component: BakeryCustProductDetailComponent,
        data: pageHeight100,
      },
      {
        path: 'register',
        component: BakeryCustRegisterComponent,
        data: pageHeight100,
      },
      {
        path: 'forgot_password',
        component: BakeryCustForgotPasswordComponent,
        data: pageHeight100,
      },
      {
        path: 'reset_password',
        component: BakeryCustResetPasswordComponent,
        data: pageHeight100,
      },
      {
        path: 'account_confirmation',
        component: BakeryCustAccountConfirmationComponent,
        data: pageHeight100,
      },
      {
        path: 'cart',
        component: BakeryCustCartComponent,
        data: pageHeight100,
      },
      {
        path: 'checkout',
        component: BakeryCustCheckoutComponent,
        data: pageHeight100,
      },
      // Private routes (only user with role bakery_customer can view)
      /*
        WARN:

        The route role guard declarations are getting redundant. Still, /store
        is only partially protected. Applying a role route guard at /store
        wouldn't work. That could be configurable, but that would require
        modifying (or extending) RoleRouteGuard. For now, these duplicate
        route guards are the simplest solution.

        Reconsider this decision if the mixed-protection requirement becomes
        more widespread.
      */
      {
        path: 'account',
        component: BakeryCustAccountSettingsComponent,
        canActivate: [RoleRouteGuard],
        data: custRouteGuardData
      },
      {
        path: 'order_history',
        component: BakeryCustOrderHistoryMasterComponent,
        canActivate: [RoleRouteGuard],
        data: custRouteGuardData
      },
      {
        path: 'order_history/:order_id',
        component: bakeryCust.BakeryCustOrderHistoryDetailComponent,
        canActivate: [RoleRouteGuard],
        data: custRouteGuardData
      },
      {
        path: 'wholesaler',
        component: bakeryCustComponentsWholesaler.WholesalerComponent,
        canActivate: [RoleRouteGuard, WholesalerCustomerRouteGuard],
        canActivateChild: [RoleRouteGuard, WholesalerCustomerRouteGuard],
        data: {
            ...pageHeight100,
            allowableRoles: ['bakery_wholesaler_owner'],
            signInUrl: storeSignInUrl
        },
        children: [
          { path: '', redirectTo: 'management', pathMatch: 'full', data: pageHeight100 },
          {
            path: 'management',
            component: bakeryCustComponentsWholesaler.ManagementComponent,
            canActivate: [RoleRouteGuard, WholesalerCustomerRouteGuard],
            canActivateChild: [RoleRouteGuard, WholesalerCustomerRouteGuard],
            data: {
                ...pageHeight100,
                allowableRoles: ['bakery_wholesaler_owner'],
                signInUrl: storeSignInUrl
            },
            children: [
              { path: '', redirectTo: 'schedule', pathMatch: 'full' },
              {
                path: 'schedule',
                component: bakeryCustComponentsWholesaler.ScheduleComponent,
                canActivate: [RoleRouteGuard, WholesalerCustomerRouteGuard],
                canActivateChild: [RoleRouteGuard, WholesalerCustomerRouteGuard],
                data: {
                    ...pageHeight100,
                    allowableRoles: ['bakery_wholesaler_owner'],
                    signInUrl: storeSignInUrl
                },
              },
              {
                path: 'invoices',
                component: bakeryCustComponentsWholesaler.InvoicesComponent,
                canActivate: [RoleRouteGuard, WholesalerCustomerRouteGuard],
                canActivateChild: [RoleRouteGuard, WholesalerCustomerRouteGuard],
                data: {
                    ...pageHeight100,
                    allowableRoles: ['bakery_wholesaler_owner'],
                    signInUrl: storeSignInUrl
                },
              },
              {
                path: 'price_index',
                component: bakeryCustComponentsWholesaler.PriceIndexComponent,
                canActivate: [RoleRouteGuard, WholesalerCustomerRouteGuard],
                canActivateChild: [RoleRouteGuard, WholesalerCustomerRouteGuard],
                data: {
                    ...pageHeight100,
                    allowableRoles: ['bakery_wholesaler_owner'],
                    signInUrl: storeSignInUrl
                },
              },
              {
                path: 'settings',
                component: bakeryCustComponentsWholesaler.SettingsComponent,
                canActivate: [RoleRouteGuard, WholesalerCustomerRouteGuard],
                canActivateChild: [RoleRouteGuard, WholesalerCustomerRouteGuard],
                data: {
                    ...pageHeight100,
                    allowableRoles: ['bakery_wholesaler_owner'],
                    signInUrl: storeSignInUrl
                },
              },
            ]
          },
          {
            path: 'create_order',
            component: bakeryCustComponentsWholesaler.CreateOrderComponent,
            canActivate: [RoleRouteGuard, WholesalerCustomerRouteGuard],
            canActivateChild: [RoleRouteGuard, WholesalerCustomerRouteGuard],
            data: {
                ...pageHeight100,
                allowableRoles: ['bakery_wholesaler_owner'],
                signInUrl: storeSignInUrl
            },
          },
          {
            path: 'invoices/:invoice_id',
            component: bakeryCustComponentsWholesaler.InvoiceDetailsComponent,
            canActivate: [RoleRouteGuard, WholesalerCustomerRouteGuard],
            canActivateChild: [RoleRouteGuard, WholesalerCustomerRouteGuard],
            data: {
                ...pageHeight100,
                allowableRoles: ['bakery_wholesaler_owner'],
                signInUrl: storeSignInUrl
            },
          },
          {
            path: 'orders/:order_id',
            component: bakeryCustComponentsWholesaler.BakeryWholesalerCustOrderDetailComponent,
            canActivate: [RoleRouteGuard],
            data: custRouteGuardData
          },
        ]
      },
    ]
  },

  { path: '404', component: PageNotFoundComponent },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
// tslint:enable:max-line-length
