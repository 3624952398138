import { ActionReducerMap } from '@ngrx/store';

import { AppState } from 'app/store/app-state';
import { masterStateReducer } from 'app/store/reducers/master-state-reducer';
import { authStateReducer } from 'app/store/reducers/auth-state-reducer';
import { entitiesStateReducer } from 'app/store/reducers/entities-state-reducer';
import { requestStateReducer } from 'app/store/reducers/request-state-reducer';
import { bakeryMgmtUiStateReducer } from 'app/store/reducers/bakery-mgmt-ui-state-reducer';
import { bakeryCustUiStateReducer } from 'app/store/reducers/bakery-cust-ui-state-reducer';
import * as bakeryOrders from 'app/store/reducers/bakery-orders-reducer';
import * as newOrder from 'app/store/reducers/new-order-reducer';
import * as reportingItemSummary from 'app/store/reducers/reporting-item-summary-reducer';
import * as reportingTaxes from 'app/store/reducers/reporting-taxes-reducer';
import * as reportingProductionChecklist from 'app/store/reducers/reporting-production-checklist-reducer';
import * as customers from 'app/store/reducers/customers-reducer';
import * as reportingDecoration from 'app/store/reducers/reporting-decoration-reducer';
import * as bakeryLocations from 'app/store/reducers/bakery-locations-reducer';
import * as bakeryProducts from 'app/store/reducers/bakery-products-reducer';
import * as reportingAttributeSummary from 'app/store/reducers/reporting-attribute-summary-reducer';
import { bakeryWholesaleUiStateReducer } from 'app/store/reducers/bakery-wholesale-ui-state-reducer';

export const reducers: ActionReducerMap<AppState> = {
  masterState: masterStateReducer,
  authState: authStateReducer,
  entitiesState: entitiesStateReducer,
  bakeryMgmtUiState: bakeryMgmtUiStateReducer,
  bakeryCustUiState: bakeryCustUiStateReducer,
  bakeryWholesaleUiState: bakeryWholesaleUiStateReducer,
  requestState: requestStateReducer,
  bakeryOrders: bakeryOrders.reducer,
  newOrder: newOrder.reducer,
  reportingTaxes: reportingTaxes.reducer,
  reportingProductionChecklist: reportingProductionChecklist.reducer,
  customers: customers.reducer,
  reportingItemSummary: reportingItemSummary.reducer,
  reportingDecoration: reportingDecoration.reducer,
  bakeryLocations: bakeryLocations.reducer,
  bakeryProducts: bakeryProducts.reducer,
  reportingAttributeSummary: reportingAttributeSummary.reducer,
};
