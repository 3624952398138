import { Component, Input } from '@angular/core';

@Component({
  selector: 'dashboard-tile',
  template: `
    <li class="card card--md margin-bottom--charlie">
      <span
        class="square inline-block margin-right--delta padding--delta float--left"
        [ngClass]="bgColorCss"
      >
        <i attr.aria-label="{{ iconAriaLabel }}" [inlineSVG]="iconPath"></i>
      </span>
      <div class="float--left icon-content">
        <h4 class="margin-bottom--echo">
          <ng-content select="[data-title]"></ng-content>
        </h4>
        <p class="margin-reset--bottom text--small">
          <ng-content select="[data-subtitle]"></ng-content>
        </p>
      </div>
    </li>
  `,
})
export class DashboardTileComponent {
  @Input() bgColor = 'grey-base';
  @Input() icon = 'help';
  @Input() iconAriaLabel = 'Unknown';

  get bgColorCss(): string {
    return `icon-background--${this.bgColor}`;
  }

  get iconPath(): string {
    return require(`angular/assets/images/icons/icons-functional/icon-${this.icon}.svg`);
  }
}
