import { Component, OnInit, OnDestroy, Input, Output, ViewChild, ElementRef } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import * as Rx from 'rxjs';

import { BakeryProduct } from 'app/store/bakery-product';
import { BakeryProductTemplate } from 'app/store/bakery-product-template';
import { SimpleFormState } from 'app/shared/forms/form-states';
import { DetailUiState } from 'app/bakery-mgmt/product-mgmt/products/detail-ui-state';
import { validatePositiveInteger } from 'app/shared/forms/positive-integer-validator';
import iconPicture from 'angular/assets/images/icons/icons-functional/icon-picture.svg';

interface ImageUiState {
  imageUrl: string;
  hasImage: boolean;
  isChanged: boolean;
}

const initialImageUiState: ImageUiState = {
  imageUrl: null,
  hasImage: false,
  isChanged: false,
};

@Component({
  selector: 'product-detail-forms-edit-image',
  template: `
    <titled-card title="Edit Product Image">
      <div data-card-body class="text--center padding-bottom--delta">
        <ng-template [ngIf]="!(imageState$|async).hasImage">
          <div class="chip__container chip__center">
            <div class="chip__avatar chip__avatar--xlg float--none margin-reset--right margin-bottom--delta" [chipColor]="(name$|async)">
              <i aria-label="Picture Icon"
                class="chip__avatar-text chip__avatar-icon"
                [inlineSVG]="iconPicture"></i>
            </div>
          </div>
        </ng-template>
        <ng-template [ngIf]="(imageState$|async).hasImage">
          <div class="chip__container chip__center">
            <div class="chip__avatar chip__avatar--xlg float--none margin-reset--right margin-bottom--delta">
              <img [src]="(imageState$|async).imageUrl" />
            </div>
          </div>
        </ng-template>
        <button *ngIf="(imageState$|async).hasImage" (click)="onClickRemoveImage()" class="button--danger button--small margin-bottom--delta clickable">Remove Image</button>
        <label [for]="uploadInputId" class="button--secondary button--small margin-bottom--delta clickable">Upload Image</label>
        <p class="margin-reset--bottom"><em>Note: Square images will display best.</em></p>
        <form #fileForm>
          <input
            style="display: none;"
            [id]="uploadInputId"
            type="file"
            (change)="onChangeFileUpload($event)">
        </form>
      </div>
    </titled-card>
  `
})
export class ProductDetailFormsEditImageComponent implements OnInit, OnDestroy {
  @Input('uiState$') uiState$: Rx.Observable<DetailUiState>;
  @Input('reset$') reset$: Rx.Observable<any>;
  @Output('formState') formState$ = new Rx.Subject<SimpleFormState>();
  @ViewChild('fileForm') fileFormElement: ElementRef;

  name$: Rx.Observable<string>;
  imageState$ = new Rx.BehaviorSubject<ImageUiState>(initialImageUiState);
  iconPicture = iconPicture;

  private resetSub: Rx.Subscription;
  private imageStateSub: Rx.Subscription;
  private formStateSub: Rx.Subscription;
  private tempImageUpdateSub: Rx.Subscription;
  private randomInputId: string;

  constructor(
  ) {
    this.randomInputId = (new Date()).getTime() + '';
  }

  ngOnInit() {
    this.resetSub = this.reset$.subscribe(() => this.reset());

    // TODO: Not sure if this is really necessary.
    this.tempImageUpdateSub = this.uiState$
      .subscribe((uiState: DetailUiState) => this.reset());

    this.name$ = this.uiState$
      .map((uiState: DetailUiState) => {
        if (uiState == null || uiState.bakeryProduct == null) return '???';

        return uiState.bakeryProduct.name;
      });

    this.imageStateSub = this.uiState$
      .subscribe((uiState: DetailUiState) => {
        if (uiState.bakeryProduct != null) {
          this.imageState$.next({
            hasImage: uiState.bakeryProduct.main_photo != null,
            imageUrl: uiState.bakeryProduct.main_photo,
            isChanged: false,
          })
        }
      });

    this.formStateSub = this.imageState$
      .subscribe((imageState: ImageUiState) => {
        const formValue: any = {};

        if (imageState.isChanged) {
          formValue.main_photo = imageState.imageUrl;
        }

        const newFormState = {
          isDirty: imageState.isChanged,
          isValid: true,
          value: formValue,
        };

        // console.log(`[image] newFormState: `, newFormState);

        this.formState$.next(newFormState);
      });
  }

  ngOnDestroy() {
    this.resetSub.unsubscribe();
    this.imageStateSub.unsubscribe();
    this.formStateSub.unsubscribe();
    this.tempImageUpdateSub.unsubscribe();
  }

  get uploadInputId(): string {
    return `photoUpload_id${this.randomInputId}`;
  }

  onChangeFileUpload($event) {
    const file = $event.target.files[0];
    const fileReader = new FileReader();
    let fileBinResults = null;
    fileReader.onload = (readerEvent) => {
      fileBinResults = (<any>readerEvent.target).result;

      this.imageState$.next({
        hasImage: true,
        imageUrl: `data:${file.type};base64,${btoa(fileBinResults)}`,
        isChanged: true,
      });
    };

    fileReader.readAsBinaryString(file);
  }

  onClickRemoveImage() {
    this.imageState$.next({
      hasImage: false,
      imageUrl: null,
      isChanged: true,
    });
  }

  private resetFileForm() {
    this.fileFormElement.nativeElement.reset();
  }

  private reset() {
    this.resetFileForm();
  }
}
