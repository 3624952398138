import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'bakeryRoleName'})
export class BakeryRoleNamePipe implements PipeTransform {
  public transform(bakeryRoleName) {
    let result = '???';

    if (bakeryRoleName == null || typeof bakeryRoleName.trim !== 'function') {
      return result;
    }

    switch (bakeryRoleName) {
      case 'bakery_owner':
        result = 'Owner';
        break;
      case 'bakery_manager':
        result = 'Manager';
        break;
      case 'bakery_employee':
        result = 'Employee';
        break;
    }

    return result;
  }
}
