import { Response } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { normalize } from 'normalizr';

import { Actions } from 'app/store/actions';

export function basicJsonRequestHandler(
  request$: Observable<any>,
  normalizrSchema: any,
): Observable<any> {
  return request$
    .map((response: Response) => response.json())
    .map((data: any[]) => {
      const results = normalize(data, normalizrSchema);

      return results;
    })
    .catch((error) => {
      const errorPayload = typeof error.json === 'function' ? error.json() : error;

      return Observable.throw(errorPayload);
    });
}

export function basicRequestHandler(
  request$: Observable<any>,
  normalizrSchema: any,
): Observable<any> {
  return basicJsonRequestHandler(request$, normalizrSchema)
    .switchMap((entities) => {
      return Observable.from([{
        type: Actions.LOADED_ENTITIES,
        payload: entities,
      }]);
    });
}

export function addEntityRequestHandler(
  request$: Observable<any>,
  normalizrSchema: any,
  rootActionType: string,
  toMerge: { [key: string]: any } = {}
): Observable<any> {
  return basicJsonRequestHandler(request$, normalizrSchema)
    .switchMap((results) => {
      return Observable.from([
        {
          type: Actions.LOADED_ENTITIES,
          payload: results.entities,
        },
        {
          type: `${rootActionType}_SUCCESS`,
          payload: { ...toMerge, ...results.entities },
        }
      ]);
    })
    .catch((error) => {
      return Observable.of({
        type: `${rootActionType}_ERROR`,
        payload: error,
      });
    });
}

export function addResultEntityRequestHandler(
  request$: Observable<any>,
  normalizrSchema: any,
  rootActionType: string,
): Observable<any> {
  return basicJsonRequestHandler(request$, normalizrSchema)
    .switchMap((results) => {
      return Observable.from([
        {
          type: Actions.LOADED_ENTITIES,
          payload: results.entities,
        },
        {
          type: `${rootActionType}_SUCCESS`,
          payload: results.result,
        }
      ]);
    })
    .catch((error) => {
      return Observable.of({
        type: `${rootActionType}_ERROR`,
        payload: error,
      });
    });
}
